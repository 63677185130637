import moment from 'moment';

import firebase from '../firebase';

import { presets } from '../constants/analytics';

const fromMomentToTimestamp = (date) => {
  if (!date) {
    return null;
  }

  if (!moment.isMoment(date)) {
    date = moment(date);
  }

  const jsDate = new Date(date);
  return firebase.timestamp.fromDate(jsDate);
};

const fromMomentToTimestampDay = (date) => {
  if (!date) {
    return null;
  }

  if (!moment.isMoment(date)) {
    date = moment(date);
  }

  const startOfTheDay = date.utc();
  const jsDate = new Date(startOfTheDay);

  return firebase.timestamp.fromDate(jsDate);
};

const nowTimestampUTC = () => {
  const now = moment().utc();
  const jsDate = new Date(now);

  return firebase.timestamp.fromDate(jsDate);
};

const getDiffDays = (date, compareDate = moment()) => {
  if (!moment.isMoment(date)) {
    date = moment(date);
  }

  const diff = date.diff(compareDate, 'days');
  return diff + 1;
};

const formatDate = (date, format = 'DD/MM/YYYY') => {
  if (!date) {
    return null;
  }

  if (!moment.isMoment(date)) {
    date = moment(date);
  }

  return date.local().format(format);
};

const onPresetChange = (value, { onMinDateChange, onMaxDateChange }, yearAgo = false) => {
  switch (value) {
    case presets.THIS_MONTH:
      onMinDateChange(moment().startOf('month'));
      onMaxDateChange(moment().endOf('month'));
      return;
    case presets.THIS_QUARTER:
      onMinDateChange(moment().startOf('quarter'));
      onMaxDateChange(moment().endOf('quarter'));
      return;
    case presets.THIS_YEAR:
      onMinDateChange(moment().startOf('year'));
      onMaxDateChange(moment().endOf('year'));
      return;
    case presets.LAST_MONTH:
      if (yearAgo) {
        onMinDateChange(moment().subtract(1, 'year').subtract(1, 'month').startOf('month'));
        onMaxDateChange(moment().subtract(1, 'year').subtract(1, 'month').endOf('month'));
        return;
      }
      onMinDateChange(moment().subtract(1, 'month').startOf('month'));
      onMaxDateChange(moment().subtract(1, 'month').endOf('month'));
      return;
    case presets.LAST_QUARTER:
      if (yearAgo) {
        onMinDateChange(moment().subtract(1, 'year').subtract(1, 'quarter').startOf('quarter'));
        onMaxDateChange(moment().subtract(1, 'year').subtract(1, 'quarter').endOf('quarter'));
        return;
      }
      onMinDateChange(moment().subtract(1, 'quarter').startOf('quarter'));
      onMaxDateChange(moment().subtract(1, 'quarter').endOf('quarter'));
      return;
    case presets.LAST_YEAR:
      onMinDateChange(moment().subtract(1, 'year').startOf('year'));
      onMaxDateChange(moment().subtract(1, 'year').endOf('year'));
      return;
    default:
      return;
  }
};

export {
  fromMomentToTimestamp,
  fromMomentToTimestampDay,
  nowTimestampUTC,
  getDiffDays,
  formatDate,
  onPresetChange,
};
