import React from 'react';

import FlowIcon from '../../assets/icons/FlowIcon';

const FlowsList = ({ flows, onClick, tenantColor, selectedFlow }) => {
  return (
    <div className='flows-list__container'>
      <div className='flow-list__item' onClick={() => onClick(null)}>
        <FlowIcon />
        <span>Flows</span>
      </div>
      {flows.map((flow) => (
        <div
          key={flow.id}
          className={`flow-list__item${flow.id === selectedFlow?.id ? ' selected' : ''}`}
          onClick={() => onClick(flow)}
        >
          <FlowIcon color={flow.id === selectedFlow?.id ? tenantColor : '#000000'} />
          <span style={{ color: flow.id === selectedFlow?.id ? tenantColor : '#000000' }}>{flow.name}</span>
        </div>
      ))}
    </div>
  );
};

export default FlowsList;
