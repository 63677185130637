import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function DocumentIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none"
          d="M12.3738 11.0838C12.4565 9.76567 12.5 8.39969 12.5 7C12.5 6.43312 12.4929 5.87177 12.4789 5.31688C12.4691 4.92707 12.3426 4.54824 12.1119 4.2339C11.2324 3.03578 10.5318 2.29092 9.3771 1.39977C9.05979 1.15487 8.67041 1.022 8.26969 1.01324C7.86786 1.00446 7.44825 1 7 1C5.64305 1 4.54845 1.04087 3.4157 1.11875C2.45206 1.18501 1.68673 1.95214 1.62622 2.91616C1.54349 4.23433 1.5 5.60031 1.5 7C1.5 8.39969 1.54349 9.76567 1.62622 11.0838C1.68673 12.0479 2.45206 12.815 3.4157 12.8812C4.54845 12.9591 5.64305 13 7 13C8.35695 13 9.45155 12.9591 10.5843 12.8812C11.5479 12.815 12.3133 12.0479 12.3738 11.0838Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M5.09375 7.01562H8.90625" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M5.09375 9.92185H8.8944M5.09375 4H7.3944" />
  </SvgIcon>
}
