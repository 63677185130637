import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { parse } from 'query-string';

import LoginHeader from '../../components/auth/LoginHeader';
import Input from '../../components/shared/Input';
import Button from '../../components/shared/Button';
import TermsAndPolicy from '../../components/auth/Terms&Conditions';

import { resetPassword } from '../../actions/authActions';

import { useAlert, useLoading } from '../../hooks';
import { withFirebase } from '../../firebase';

import appleIcon from '../../assets/apple_app_icon.svg';

import { settings, routes } from '../../constants';

const APP_STORE_LINK = 'https://apps.apple.com/au/app/case-mobile-case-management/id1565512952';
const DEFAULT_PASSWORD_LENGTH = 10;

const SetPasswordPage = ({ history, location, firebase }) => {
  const dispatch = useDispatch();

  const { loading, startLoading, stopLoading } = useLoading();
  const { showAlert } = useAlert();

  const [isComplete, setComplete] = useState(false);
  const [minLength, setMinLength] = useState(0);

  useEffect(() => {
    const code = parse(location.search).oobCode;
    const mode = parse(location.search).mode;

    firebase.getSettingsConfig(settings.PASSWORD_MIN_LENGTH)
      .then((res) => setMinLength(res && res.value ? res.value : DEFAULT_PASSWORD_LENGTH))
      .catch((err) => console.error(err));

    if (!code || !mode) {
      history.push(routes.SIGN_IN);
    }
  }, []);

  const onSubmit = async ({ password, confirmPassword }) => {
    if (password && password.length < minLength) {
      return showAlert('error', `Password must be minimum of ${minLength} characters`);
    }

    if (password !== confirmPassword) {
      showAlert('error', 'Password does not match');
    } else {
      startLoading();

      try {
        const code = parse(location.search).oobCode;
        const tenantId = parse(location.search).tenantId;

        await dispatch(resetPassword(code, password, tenantId));

        showAlert('success', 'You successfully set a new password');
        setTimeout(() => setComplete(true), 1500);
      } catch (err) {
        showAlert('error', err.message);
      } finally {
        stopLoading();
      }
    }
  };

  return (
    <div>
      <LoginHeader />
      <div className='main-body'>
        {isComplete ? (
          <div className='login-form'>
            <div className='logo' />
            <span className='login-text'>Good to go!</span>
            <span className='login-subtext' style={{ marginBottom: 0 }}>
              You're all set, so simply log in now with your new credentials.
            </span>
            <span className='login-subtext' style={{ marginTop: 0 }}>
              If you haven't already you can download our iOS app at the link below.
            </span>

            <div className='d-flex flex-center width-100'>
              <img
                className='apple-icon'
                src={appleIcon}
                alt='apple_icon'
                onClick={() => window.open(APP_STORE_LINK, "_blank")}
              />
            </div>

            <Link to={routes.SIGN_IN} className='width-100'>
              <div className='d-flex flex-center'>
                <span className='forgot-pass-text'>Go back to login page?</span>
              </div>
            </Link>
          </div>
        ) : <Form
          onSubmit={onSubmit}
        >
          {
            ({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className='login-form'>
                <div className='logo' />
                <span className='login-text'>Set your password</span>
                <span className='login-subtext'>
                  Enter a new password below
                </span>

                <Field name='password'>
                  {props => (
                    <Input
                      name={props.input.name}
                      value={props.input.value}
                      placeholder='Password'
                      type='password'
                      onChange={props.input.onChange}
                    />
                  )}
                </Field>

                <Field name='confirmPassword'>
                  {props => (
                    <Input
                      name={props.input.name}
                      value={props.input.value}
                      placeholder='Confirm Password'
                      type='password'
                      onChange={props.input.onChange}
                    />
                  )}
                </Field>

                <div className='btn-group flex-center'>
                  <Button
                    type='submit'
                    text='Set Password'
                    onClick={handleSubmit}
                    disabled={loading}
                    loading={loading}
                  />
                </div>

                <div className='text-group'>
                  <TermsAndPolicy />
                </div>

              </form>
            )}
        </Form>}
      </div>
    </div>
  );
};

const mapStateToProps = () => {
  return {
  };
};

const mapDispatchToProps = () => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withFirebase(withRouter(SetPasswordPage)));
