import React, { useMemo } from 'react';

import Modal from '../shared/modal';
import Input from '../shared/Input';
import Autocomplete from '../shared/autocomplete/Autocomplete';

import '../kits/kits.scss';

const UsageFiltersModal = (props) => {
  const {
    open,
    onClose,
    hideClosed,
    toggleClosed,
    surgeon,
    hospital,
    onSurgeonChange,
    onHospitalChange,
    surgeons = [],
    hospitals = [],
  } = props;

  const handleClose = () => {
    onClose();
  };

  const surgeonOptions = useMemo(() => {
    return [
      { label: 'All', value: '' },
      ...surgeons?.map((surgeon) => ({
        value: surgeon.id,
        label: `${surgeon.title} ${surgeon.firstName} ${surgeon.lastName}`
      }))
    ];
  }, [surgeons]);

  const hospitalOptions = useMemo(() => {
    return [
      { label: 'All', value: '' },
      ...hospitals?.map((hospital) => ({ value: hospital.id, label: hospital.name }))
    ];
  }, [hospitals]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title='Filter Kits'
      fullWidth
    >
      <div className='kits-filters-modal__container'>
        <Input
          type='switch'
          value={hideClosed}
          onChange={toggleClosed}
          placeholder='Hide All Closed'
        />

        <div>
          <Autocomplete
            value={surgeon}
            onChange={onSurgeonChange}
            options={surgeonOptions}
            placeholder='Filter by Surgeon'
          />
        </div>

        <div className='m-t-lg'>
          <Autocomplete
            value={hospital}
            onChange={onHospitalChange}
            options={hospitalOptions}
            placeholder='Filter by Hospital'
          />
        </div>
      </div>
    </Modal>
  );
};

export default UsageFiltersModal;
