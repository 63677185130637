import auth from './auth';
import user from './user';
import tenant from './tenant';
import users from './users';
import groups from './groups';
import procedures from './procedures';
import products from './products';
import hospitals from './hospitals';
import kits from './kits';
import surgeons from './surgeons';
import cases from './cases';
import activeCase from './case';
import drive from './drive';
import tasks from './tasks';
import sets from './sets';
import counts from './counts';
import activeCount from './count';
import notifications from './notifications';
import gtinReference from './gtinReference';
import flows from './flows';
import forms from './forms';
import items from './items';
import repeatingCounts from './repeatingCounts';
import territories from './territories';
import tags from './tags';
import kitPreferences from './kitPreferences';
import shipping from './shipping';
import usage from './usage';

const reducers = {
  auth,
  user,
  tenant,
  users,
  groups,
  procedures,
  products,
  hospitals,
  kits,
  surgeons,
  cases,
  activeCase,
  drive,
  tasks,
  sets,
  counts,
  activeCount,
  notifications,
  gtinReference,
  flows,
  forms,
  items,
  repeatingCounts,
  territories,
  tags,
  kitPreferences,
  shipping,
  usage
};

export default reducers;
