import React from 'react';

import Table from '../shared/table/Table';

const columns = [
  { title: 'name', field: 'name', type: 'default', sort: true },
  { title: 'description', field: 'description', type: 'default', sort: true },
  { title: 'status', field: 'active', type: 'status', sort: false },
];

const TerritoriesTable = (props) => {
  const {
    onSelectTerritory,
    territories,
    selectedTerritoryId,
    handleCheck,
    checkedTerritories,
    handleCheckAll,
  } = props;

  return (
    <div style={{ width: '100%' }}>
      <Table
        rows={territories}
        columns={columns}
        onRowClick={onSelectTerritory}
        selected={selectedTerritoryId}
        handleCheck={handleCheck}
        checkedRows={checkedTerritories}
        handleCheckAll={handleCheckAll}
        pagination
        noCheckAll
      />
    </div>
  );
};

export default TerritoriesTable;
