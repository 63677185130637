import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function BatchControlIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path id="Intersect" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M2.35 12.308c.04.48.415.879.891.814 1.205-.165 2.464-.923 3.759-1.765 1.295.842 2.553 1.6 3.758 1.765.477.065.852-.335.892-.814.134-1.62.314-3.317.314-5.065 0-1.748-.18-3.445-.315-5.065a1.035 1.035 0 0 0-.885-.935C9.556 1.069 8.296.846 7 .846c-1.297 0-2.557.223-3.764.397a1.036 1.036 0 0 0-.886.935c-.134 1.62-.314 3.317-.314 5.065 0 1.748.18 3.445.314 5.065Z"/>
    <path id="Vector" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M4.5 7.125 6.318 9C7.174 6.542 7.886 5.464 9.5 4"/>
  </SvgIcon>
}

