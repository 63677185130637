import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import ExportIcon from '../../../assets/icons/DownloadIcon';
import ItemIcon from '../../../assets/icons/ItemIcon';

import Table from '../../shared/table/Table';
import ActionButton from '../../shared/ActionButton';

import { exportLookupItems } from '../../../actions/setsActions';

import { setAllocationStatuses, setPositionOptions } from '../../../constants/enums';

const columns = [
  { title: 'item', field: 'itemCode', type: 'default', sort: true },
  { title: 'description', field: 'itemDescription', type: 'default', sort: true },
  { title: 'kit id', field: 'kitId', type: 'default', sort: true },
  { title: 'kit name', field: 'kitName', type: 'default', sort: true },
  { title: 'set number', field: 'setNumber', type: 'default', sort: true },
  { title: 'quantity', field: 'quantity', type: 'default', sort: true },
  {
    title: 'status', field: 'status', type: 'custom', sort: true, formatter: (value, row) => {
      return (
        <span style={{ color: setPositionOptions[value]?.color }}>
          {setPositionOptions[value]?.label}
        </span>
      );
    },
  },
];

const BOMLookupTable = (props) => {
  const { data = [] } = props;

  const dispatch = useDispatch();

  const [exportLoading, setExportLoading] = useState(false);

  const onExportClick = async () => {
    const body = data?.map((item) => ({
      ...item,
      status: setPositionOptions[item.status]?.label || setPositionOptions.AVAILABLE.label,
      quantity: item.quantity !== undefined ? `${item.quantity}` : ''
    }));

    setExportLoading(true);
    await dispatch(exportLookupItems(body));
    setExportLoading(false);
  };

  return (
    <div className='cases-table__container'>
      <div className='d-flex m-b-lg space-between align-start'>
        <div className='d-flex'>
          <ItemIcon />
          <div className='font-size-bg font-bold m-l-md'>
            Items
          </div>
        </div>

        <ActionButton
          onClick={onExportClick}
          bgColor={setAllocationStatuses.SHIPPED.color}
          disabled={exportLoading}
          loading={exportLoading}
        >
          <ExportIcon color="#ffffff" />
          Export
        </ActionButton>
      </div>

      <Table
        rows={data}
        columns={columns}
        pagination
        checkboxes={false}
        withoutCheck
        rowColor='#ffffff'
        rowBorderColor='#fafafa'
      />
    </div>
  );
};

export default BOMLookupTable;
