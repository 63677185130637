import React, { useMemo } from 'react';
import moment from 'moment/moment';

import { LineChart } from '../../../charts';

import {
  prepareCasesDataDaily,
  prepareCasesDataMonthly,
  prepareCasesDataQuarterly,
} from '../../../../utils/analytics';

import { presets } from '../../../../constants/analytics';
import { kitVariantTypes } from '../../../../constants/enums';

const getTitle = (id) => {
  switch (id) {
    case 'Day_LOAN':
    case 'Month_LOAN':
    case 'Year_LOAN':
      return 'Loan';
    case 'Day_CONSIGNMENT':
    case 'Month_CONSIGNMENT':
    case 'Year_CONSIGNMENT':
      return 'Consignment';
    case 'Day_COMBINED':
    case 'Month_COMBINED':
    case 'Year_COMBINED':
      return 'Combined';
    default:
      return '';
  }
};

const CustomTooltip = (props) => {
  const { points } = props.slice;
  return (
    <div className="custom-tooltip">
      {points?.map((point) => {
        const { data: { yFormatted }, serieId, color } = point;

        return (
          <div className="d-flex">
            <div style={{ height: 10, width: 10, background: color }} /><div className='font-bold m-r-sm m-l-sm'>{`${getTitle(serieId)}:`}</div><div>{`${yFormatted} case(s)`}</div>
          </div>
        )
      })}
    </div>
  );
};

const TypesLineChart = ({ cases = [], tenantColor, minDate, maxDate, preset }) => {
  const getChartData = (filteredCases, min, max) => {
    if (min.month() === max.month() && min.year() === max.year()) {
      const daysInMonth = preset === presets.LAST_MONTH ? moment().subtract(1, 'month').daysInMonth() : moment().daysInMonth();
      const numberOfDays = filteredCases[0]?.date ? moment(filteredCases[0]?.date).daysInMonth() : daysInMonth;
      return prepareCasesDataDaily(filteredCases, numberOfDays);
    }

    if (min.quarter() === max.quarter() && min.year() === max.year()) {
      const quarter = preset === presets.LAST_QUARTER ? moment().subtract(1, 'quarter').quarter() : moment().quarter();
      const initialQuarter = filteredCases[0]?.date ? quarter : moment(filteredCases[0]?.date).quarter();
      return prepareCasesDataQuarterly(filteredCases, initialQuarter);
    }

    return prepareCasesDataMonthly(filteredCases);
  };

  const data = useMemo(() => {
    return [
      ...getChartData(cases?.filter((item) => item.kitVariant === kitVariantTypes.consignment && !item.additionalKits?.length && !item.additionalItems?.length), minDate, maxDate)?.map((item) => ({
        ...item,
        id: `${item.id}_CONSIGNMENT`,
      })),
      ...getChartData(cases?.filter((item) => item.kitVariant === kitVariantTypes.consignment && (!!item.additionalKits?.length || !!item.additionalItems?.length)), minDate, maxDate)?.map((item) => ({
        ...item,
        id: `${item.id}_COMBINED`,
      })),
      ...getChartData(cases?.filter((item) => item.kitVariant === kitVariantTypes.loan), minDate, maxDate)?.map((item) => ({
        ...item,
        id: `${item.id}_LOAN`,
      })),
    ]
  }, [cases, minDate, maxDate, preset]);

  return (
    <div className="management-reports-chart__container m-l-md" style={{ width: '50%' }}>
      <div className="font-size-bg font-bold">Cases by Period</div>
      <div className="secondary m-t-sm m-b-lg">Cases in period split by type (Loan, Consignment or Combined)</div>
      <LineChart
        data={data}
        margin={{ top: 20, right: 15, bottom: 30, left: 30 }}
        height="300px"
        enableSlices="x"
        CustomTooltip={CustomTooltip}
      />
    </div>
  );
};

export default TypesLineChart;
