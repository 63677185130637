import {
  GET_CASE, SET_CASE, SET_CASE_ACTIVITY,
  SET_CASE_NOTES, SET_CASE_USAGE, SET_CASE_USAGE_NOTES,
  SET_CASE_DOCUMENTS, SET_CASE_SETS_ALLOCATION, SET_CASE_FLOW,
  SET_CASE_CHECKLISTS, CLEAR_CASE, SET_VIEW, SET_STEP, SET_CASE_FORMS,
  SET_CASE_PROFORMA,
} from '../actions/actionTypes';

const initialState = {
  data: {
    id: '',
  },
  notes: [],
  usageNotes: [],
  usage: [],
  documents: [],
  setsAllocation: [],
  activity: [],
  flow: [],
  checklists: [],
  isLoading: false,
  isLoaded: false,
  view: 'OVERVIEW',
  formStep: 0,
  forms: [],
  proforma: []
};

const caseReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CASE:
      return { ...state, isLoading: true };
    case SET_CASE:
      return {
        ...state,
        data: action.activeCase,
        isLoaded: true,
        isLoading: false
      };
    case SET_CASE_NOTES:
      return {
        ...state,
        notes: action.notes,
      };
    case SET_CASE_USAGE_NOTES:
      return {
        ...state,
        usageNotes: action.notes,
      };
    case SET_CASE_USAGE:
      return {
        ...state,
        usage: action.usage,
      };
    case SET_CASE_DOCUMENTS:
      return {
        ...state,
        documents: action.documents,
      };
    case SET_CASE_SETS_ALLOCATION:
      return {
        ...state,
        setsAllocation: action.setsAllocation,
      };
    case SET_CASE_ACTIVITY:
      return {
        ...state,
        activity: action.activity,
      };
    case SET_CASE_FLOW:
      return {
        ...state,
        flow: action.flow,
      };
    case SET_CASE_CHECKLISTS:
      return {
        ...state,
        checklists: action.checklists,
      };
    case CLEAR_CASE:
      return initialState;
    case SET_VIEW:
      return { ...state, view: action.view };
    case SET_STEP:
      return { ...state, formStep: action.step };
    case SET_CASE_FORMS:
      return { ...state, forms: action.forms || [] };
    case SET_CASE_PROFORMA:
      return { ...state, proforma: action.proforma || [] };
    default:
      return state;
  }
};

export default caseReducer;
