import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function DeleteIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M0.75 3.74524H13.25" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M11.4907 3.74518H2.50506C2.36001 6.36772 2.36227 8.97003 2.75318 11.5761C2.8975 12.5382 3.72398 13.25 4.69687 13.25H9.29887C10.2718 13.25 11.0982 12.5382 11.2426 11.5761C11.6335 8.97003 11.6357 6.36772 11.4907 3.74518Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M4.50195 3.74521V3.24601C4.50195 2.58402 4.76492 1.94916 5.23302 1.48106C5.70111 1.01297 6.33598 0.75 6.99796 0.75C7.65994 0.75 8.29481 1.01297 8.7629 1.48106C9.231 1.94916 9.49397 2.58402 9.49397 3.24601V3.74521" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M5.50024 6.41864V10.5565" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M8.49561 6.41864V10.5565" />
  </SvgIcon>
}
