import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import UsersTable from '../../../components/users/UsersTable';
import UsersFilters from '../../../components/users/UsersFilters';
import UserForm from '../../../components/users/UserForm';
import Input from '../../../components/shared/Input';
import Modal from '../../../components/shared/modal/Modal';
import Alert from '../../../components/shared/Alert';

import {
  createUser,
  updateUser,
  subscribeToUsers,
  getConnectedUsers,
  declineAccess,
  removeUserFromTenant,
} from '../../../actions/usersActions';
import { getGroups } from '../../../actions/groupsActions';
import { getSurgeons } from '../../../actions/surgeonsActions';
import { getTenants } from '../../../actions/tenantActions';
import { getTerritories } from '../../../actions/territoriesActions';

import { filterUsers } from '../../../utils/table';
import { withFirebase } from '../../../firebase';

import userRoles, { singleTenantRoles, multiTenantRoles, territoryRoles } from '../../../constants/userRoles';
import { statusOptions } from '../../../constants/enums';
import { settings } from '../../../constants';
import { ConfirmationModal } from '../../../components/shared/modal';

const roleOptions = Object.values(userRoles).map(({ label, name }) => ({ label, value: name }));
const editableRoleOptions = roleOptions.filter(({ value }) => singleTenantRoles.includes(value));
const statusFilterOptions = [
  { label: 'Active', value: statusOptions.active },
  { label: 'Deactivated', value: statusOptions.deactivated }
];

const UsersPage = (props) => {
  const {
    users,
    createUser,
    updateUser,
    subscribeToUsers,
    groups,
    surgeons,
    getGroups,
    getSurgeons,
    companyColor,
    tenantCountry,
    maxUsers,
    firebase,
    getConnectedUsers,
    declineAccess,
    getTenants,
    tenants,
    removeUserFromTenant,
    territories = [],
    getTerritories
  } = props;

  const [isModalOpen, toggleModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [selectedUser, setSelectedUser] = useState(null);
  const [checkedUsers, setCheckedUsers] = useState([]);

  const [connectedUsers, setConnectedUsers] = useState([]);
  const [confirmationModalOpen, toggleConfirmationModal] = useState(false);
  const [removeUserModalOpen, toggleRemoveUserModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [search, setSearch] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  const [minLength, setMinLength] = useState(0);

  useEffect(() => {
    firebase.getSettingsConfig(settings.PASSWORD_MIN_LENGTH)
      .then((res) => setMinLength(res && res.value ? res.value : 10))
      .catch((err) => console.error(err));

    getGroups();
    getSurgeons();
    getTenants();
    getTerritories();
    fetchConnectedUsers().catch(console.error);

    const unsubscribe = subscribeToUsers();

    return () => {
      unsubscribe();
    };
  }, []);

  const fetchConnectedUsers = async () => {
    const res = await getConnectedUsers();
    if (res && res?.length) {
      setConnectedUsers(res);
    }
  };

  // Create user
  const onCreateUser = async (formObj) => {
    setLoading(true);
    const userObj = {
      firstName: formObj.firstName,
      lastName: formObj.lastName,
      email: formObj.email,
      role: formObj.role,
      deletePermission: formObj.deletePermission || false
    };

    if (formObj.password) {
      userObj.password = formObj.password;
    }

    try {
      await createUser(userObj);
      setLoading(false);
      toggleModal(false);
      setSuccessMessage('User has been successfully created');
    } catch (err) {
      setLoading(false);
      console.error(err);
      setErrorMessage(err.message || err);
    }
  };

  // Update user
  const onUpdateUser = async (formObj) => {
    setLoading(true);
    const userObj = {
      uid: formObj.uid,
      active: formObj.active,
      firstName: formObj.firstName,
      lastName: formObj.lastName,
      role: formObj.role,
      caseWriteDisabled: !formObj.caseWriteAllowed,
      deletePermission: formObj.deletePermission || false,
    };

    if (multiTenantRoles.includes(formObj.role) || formObj.role === userRoles.SALES_REP.name) {
      userObj.assignedSurgeons = formObj.assignedSurgeons;
    }

    if (formObj.role === userRoles.SALES_REP.name) {
      userObj.deliveryAddresses = formObj.deliveryAddresses || [];
    }

    if (territoryRoles?.includes(formObj.role)) {
      userObj.territoryVisibility = formObj.territoryVisibility || false;
      userObj.assignedTerritories = formObj.territoryVisibility ? (formObj.assignedTerritories || []) : [];
    }

    if (formObj.role === userRoles.SURGEON.name) {
      const externalTenantSurgeons = selectedUser?.assignedSurgeons?.filter((surgeonId) => !surgeons?.map((s) => s.id)?.includes(surgeonId));
      userObj.assignedSurgeons = [...externalTenantSurgeons, ...formObj.assignedSurgeons];
    }

    try {
      await updateUser(userObj);
      setLoading(false);
      setSuccessMessage('User has been successfully updated');
      setSelectedUser(formObj);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const assignSurgeons = async (formObj) => {
    setLoading(true);

    const externalTenantSurgeons = selectedUser?.assignedSurgeons?.filter((surgeonId) => !surgeons?.map((s) => s.id)?.includes(surgeonId));
    const userObj = {
      uid: formObj.uid,
      assignedSurgeons: [...externalTenantSurgeons, ...formObj.assignedSurgeons],
    };

    try {
      await updateUser(userObj, true);
      await fetchConnectedUsers();
      setLoading(false);
      setSuccessMessage('User has been successfully updated');
      setSelectedUser(formObj);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    // setCheckedUsers([]);
  };

  const userList = useMemo(() => {
    const list = [...users, ...connectedUsers?.map((user) => ({ ...user, connected: true, groups: [] }))];
    return list.filter((user) => filterUsers(user, search, statusFilter, roleFilter));
  }, [users, search, statusFilter, roleFilter, connectedUsers]);

  const handleCheck = (userId) => {
    const checked = checkedUsers.slice();

    if (checked.includes(userId)) {
      const index = checked.indexOf(userId);

      checked.splice(index, 1);
      setCheckedUsers(checked);

      return;
    }

    checked.push(userId);
    setCheckedUsers(checked);
  };

  const handleCheckAll = () => {
    const checked = checkedUsers.length === userList.length;
    const temp = [];

    if (!checked) {
      userList.forEach((user) => {
        temp.push(user.uid);
      });

      setCheckedUsers(temp);
      return;
    }

    setCheckedUsers(temp);
  };

  const onDecline = async () => {
    setIsLoading(true);
    try {
      const userId = selectedUser?.uid;
      await declineAccess(userId);
      setConnectedUsers([...connectedUsers]?.filter((u) => u?.uid !== selectedUser?.uid));
      toggleConfirmationModal(false);
      setSelectedUser(null);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onRemoveUser = async () => {
    setIsLoading(true);
    try {
      const userId = selectedUser?.uid;
      await removeUserFromTenant(userId);
      toggleRemoveUserModal(false);
      setSelectedUser(null);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const groupOptions = useMemo(() => {
    return groups.map(({ id, name }) => ({ label: name, value: id }));
  }, [groups]);

  return (
    <div className='settings-cmp__main'>
      <span className='settings-title'>Users</span>
      <div className='settings-cmp__body'>
        <div className='filters-container'>
          <UsersFilters
            onAddUserClick={() => toggleModal(true)}
            role={roleFilter}
            setRole={setRoleFilter}
            roles={roleOptions}
            status={statusFilter}
            setStatus={setStatusFilter}
            statuses={statusFilterOptions}
            users={users?.filter((u) => !!u.active && singleTenantRoles?.includes(u.role))?.length}
            maxUsers={maxUsers}
          />
          <UsersTable
            onSelectUser={setSelectedUser}
            users={userList}
            selectedUserId={selectedUser ? selectedUser.id : null}
            handleCheck={handleCheck}
            checkedUsers={checkedUsers}
            handleCheckAll={handleCheckAll}
            surgeons={surgeons}
            tenants={tenants}
            territories={territories}
          />
        </div>
        { users && !!users.length && (
          <div className='form-container'>
            <Input
              type='search'
              placeholder='Search Users'
              value={search}
              onChange={handleSearch}
            />
            { selectedUser && (
              <UserForm
                initialValues={{
                  ...selectedUser,
                  caseWriteAllowed: !selectedUser.caseWriteDisabled,
                  assignedTerritories: selectedUser?.assignedTerritories || [],
                }}
                buttonText='Save Changes'
                onSubmit={onUpdateUser}
                roleOptions={editableRoleOptions}
                loading={loading}
                mode='update'
                companyColor={companyColor}
                surgeons={surgeons}
                users={users}
                defaultCountry={tenantCountry}
                onDecline={() => toggleConfirmationModal(true)}
                onRemoveUser={() => toggleRemoveUserModal(true)}
                assignSurgeons={assignSurgeons}
                territories={territories}
              />
            )}
          </div>
        )}
      </div>

      <Modal
        open={isModalOpen}
        onClose={() => toggleModal(false)}
      >
        <UserForm
          buttonText='Add User'
          onSubmit={onCreateUser}
          roleOptions={editableRoleOptions}
          groupOptions={groupOptions}
          loading={loading}
          mode='create'
          onClose={() => toggleModal(false)}
          minPasswordLength={minLength}
          territories={territories}
        />
      </Modal>
      <Alert
        variant='success'
        message={successMessage}
        open={!!successMessage}
        onClose={() => setSuccessMessage(null)}
      />
      <Alert
        variant='error'
        message={errorMessage}
        open={!!errorMessage}
        onClose={() => setErrorMessage(null)}
      />

      <ConfirmationModal
        open={confirmationModalOpen}
        onClose={() => toggleConfirmationModal(false)}
        onSubmit={onDecline}
        title='Are you sure you want to block this user?'
        submitText='Confirm'
        loading={isLoading}
      />

      <ConfirmationModal
        open={removeUserModalOpen}
        onClose={() => toggleRemoveUserModal(false)}
        onSubmit={onRemoveUser}
        title='Are you sure you want to block this user?'
        submitText='Confirm'
        loading={isLoading}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentTenant: state.tenant.currentTenant,
    tenants: state.tenant.list,
    currentUser: state.user.currentUser,
    users: state.users.list,
    groups: state.groups.list,
    surgeons: state.surgeons.list,
    companyColor: state.tenant.currentTenant.colorPrimary,
    tenantCountry: state.tenant.currentTenant.country,
    maxUsers: state.tenant.currentTenant.maxUsers,
    territories: state.territories.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createUser: (user) => dispatch(createUser(user)),
    updateUser: (user, simpleUpdate) => dispatch(updateUser(user, simpleUpdate)),
    subscribeToUsers: () => dispatch(subscribeToUsers()),
    getGroups: () => dispatch(getGroups()),
    getSurgeons: () => dispatch(getSurgeons()),
    getConnectedUsers: () => dispatch(getConnectedUsers()),
    declineAccess: (userId) => dispatch(declineAccess(userId)),
    getTenants: () => dispatch(getTenants()),
    removeUserFromTenant: (userId) => dispatch(removeUserFromTenant(userId)),
    getTerritories: () => dispatch(getTerritories())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withFirebase(UsersPage));
