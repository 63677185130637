import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import AddIcon from '../../assets/icons/AddIcon';
import ExportIcon from '../../assets/icons/DownloadIcon';
import InventoryIcon from '../../assets/icons/CountIcon';
import GtinRefIcon from '../../assets/icons/GtinIcon';

import CountInventoryTable from './CountInventoryTable';
import CountEquipmentTable from './CountEquipmentTable';
import AddScanModal from './AddScanModal';
import Tabs from '../shared/Tabs';
import ActionButton from '../shared/ActionButton';
import ConfirmationModal from '../shared/modal/ConfirmationModal';

import {
  addScanImplants,
  addScanImage,
  updateScanImplants,
  updateScanImage,
  deleteScan
} from '../../actions/countsActions';

import { caseUsageTypes, setAllocationStatuses, countStatuses } from '../../constants/enums';
import UpdateUsageModal from '../cases/modals/UpdateUsageModal';
import UpdateImageModal from '../cases/modals/UpdateImageModal';

const tabs = [
  { label: 'Inventory' },
  { label: 'Equipment' },
];

const CountScansContainer = (props) => {
  const {
    countId,
    status,
    scans,
    gtinReference,
    exportCount,
    loading,
    users,
    submitting,
    onSubmit,
    billOfMaterial,
    onAddClick,
    tags,
  } = props;

  const dispatch = useDispatch();

  const [selectedScan, setSelectedScan] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const [activeTab, setActiveTab] = useState(0);
  const [gtinRefActive, toggleGtinRef] = useState(false);
  const [modalOpen, toggleModal] = useState(false);
  const [addModalOpen, toggleAddModal] = useState(false);
  const [uploading, setUploading] = useState(false);

  const inventory = useMemo(() => scans.filter((scan) => scan.type === caseUsageTypes.implants), [scans, activeTab]);
  const images = useMemo(() => scans.filter((scan) => scan.type === caseUsageTypes.images), [scans, activeTab]);

  const onSubmitClick = async () => {
    await onSubmit();
    toggleModal(false);
  };

  const onAddScans = async (type, formObj, image) => {
    setUploading(true);

    try {
      if (type === caseUsageTypes.images) {
        await dispatch(addScanImage(countId, formObj, image));
      } else {
        await dispatch(addScanImplants(countId, formObj));
      }
    } catch (err) {
      console.error(err);
    } finally {
      setUploading(false);
      toggleAddModal(false);
    }
  };

  const onUpdateImplants = async (formObj) => {
    setUploading(true);

    try {
      await dispatch(updateScanImplants(countId, formObj));
    } catch (err) {
      console.error(err);
    } finally {
      setUploading(false);
      setSelectedScan(null);
    }
  };

  const onUpdateImage = async (formObj) => {
    setUploading(true);

    try {
      await dispatch(updateScanImage(countId, formObj));
    } catch (err) {
      console.error(err);
    } finally {
      setUploading(false);
      setSelectedImage(null);
    }
  };

  const onDelete = async (id) => {
    try {
      await dispatch(deleteScan(countId, id));
    } catch (err) {
      console.error(err);
    }
  };

  const renderTable = () => {
    switch (activeTab) {
      case 0:
        return (
          <CountInventoryTable
            scans={inventory}
            onSelect={(row) => {
              if (status !== countStatuses.COMPLETED.value) {
                setSelectedScan(row);
              }
            }}
            selected={selectedScan}
            gtinRefActive={gtinRefActive}
            gtinReference={gtinReference}
            users={users}
            tags={tags}
          />
        );
      case 1:
        return (
          <CountEquipmentTable
            scans={images}
            onSelect={(row) => {
              if (status !== countStatuses.COMPLETED.value) {
                setSelectedImage(row);
              }
            }}
            selected={selectedImage}
            users={users}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className='count-scans__container'>
      <div className='d-flex m-b-lg space-between align-start'>
        <div className='d-flex'>
          <InventoryIcon />
          <div className='font-size-bg font-bold m-l-md'>
            Inventory
          </div>
        </div>

        <div className='count-scans-actions__container'>
          {status !== countStatuses.COMPLETED.value && (
            <ActionButton
              onClick={() => toggleAddModal(true)}
              bgColor={setAllocationStatuses.RETURNED.color}
            >
              <AddIcon color="#ffffff" />
              Manually
            </ActionButton>
          )}

          {status !== countStatuses.COMPLETED.value && !!billOfMaterial && (
            <ActionButton
              onClick={onAddClick}
              bgColor={setAllocationStatuses.SHIPPED.color}
            >
              <AddIcon color="#ffffff" />
              Via BOM
            </ActionButton>
          )}

          {status !== countStatuses.COMPLETED.value && (
            <ActionButton
              onClick={() => toggleModal(true)}
              bgColor={setAllocationStatuses.RETURNED.color}
              disabled={!scans?.length}
            >
              <InventoryIcon color="#ffffff" />
              Submit
            </ActionButton>
          )}

          <ActionButton
            onClick={() => toggleGtinRef(!gtinRefActive)}
            bgColor={gtinRefActive ? setAllocationStatuses.RETURNED.color : '#DDDDDD'}
          >
            <GtinRefIcon color="#ffffff" />
            GTIN Ref
          </ActionButton>

          <ActionButton
            onClick={exportCount}
            bgColor={setAllocationStatuses.SHIPPED.color}
            disabled={loading}
            loading={loading}
          >
            <ExportIcon color="#ffffff" />
            Export
          </ActionButton>
        </div>
      </div>

      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onTabChange={setActiveTab}
      >
        <div className='m-t-lg'>
          {renderTable()}
        </div>
      </Tabs>

      <ConfirmationModal
        open={modalOpen}
        onClose={() => toggleModal(false)}
        onSubmit={onSubmitClick}
        title='Are you sure you want to submit this count?'
        submitText='Confirm'
        loading={submitting}
      />

      <AddScanModal
        open={addModalOpen}
        onClose={() => toggleAddModal(false)}
        onSubmit={onAddScans}
        loading={uploading}
        title='Add Item'
        tags={tags}
      />

      <UpdateUsageModal
        open={!!selectedScan}
        onClose={() => setSelectedScan(null)}
        usage={selectedScan}
        onSubmit={onUpdateImplants}
        onDelete={onDelete}
        loading={uploading}
        title='Update Item'
        tags={tags}
      />

      <UpdateImageModal
        open={!!selectedImage}
        onClose={() => setSelectedImage(null)}
        usage={selectedImage}
        onSubmit={onUpdateImage}
        onDelete={onDelete}
        loading={uploading}
        title='Update Image'
      />
    </div>
  );
};

export default CountScansContainer;
