import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SubmittedIcon from '@material-ui/icons/Done';
import CompleteIcon from '@material-ui/icons/DoneAll';

import SubmittedFormsContainer from '../../../components/customers/customer-forms/SubmittedFormsContainer';
import LoadScreen from '../../../components/load-screen';
import AddFormModal from '../../../components/cases/forms/AddFormModal';
import ActionButton from '../../../components/shared/ActionButton';
import Select from '../../../components/shared/Select';

import { useAlert } from '../../../hooks';

import { getSubmittedForms, addCustomerForm, exportForms } from '../../../actions/customerFormsActions';
import { getForms } from '../../../actions/formsActions';

import routes from '../../../constants/routes';
import { setAllocationStatuses, formStatuses } from '../../../constants/enums';

const SubmittedFormsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { showAlert } = useAlert();

  const users = useSelector((state) => state?.users?.list);
  const forms = useSelector((state) => state?.forms?.list);
  const tenantColor = useSelector((state) => state?.tenant?.currentTenant?.colorPrimary);

  const [submittedForms, setSubmittedForms] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addModalOpen, toggleAddModal] = useState(false);
  const [originalFormId, setOriginalFormId] = useState('');
  const [checkedForms, setCheckedForms] = useState([]);
  const [exporting, setExporting] = useState(false);

  const [filter, setFilter] = useState({
    submitted: true,
    closed: true,
  });

  useEffect(() => {
    onLoad().catch(console.error);
  }, []);

  const onLoad = async () => {
    setFetching(true);
    try {
      await dispatch(getForms());
      await fetchForms();
    } catch (err) {
      console.error(err);
    } finally {
      setFetching(false);
    }
  };

  const fetchForms = async () => {
    const res = await dispatch(getSubmittedForms());
    const data = res || [];

    setSubmittedForms(data?.map((item) => {
      const form = forms?.find((f) => f.id === item.formId);
      return {
        ...item,
        name: form?.name || '',
        updatedAt: item?.updatedAt || item?.closedAt || item?.submittedAt || item?.createdAt
      }
    }));
  };

  const onSubmit = async (formId) => {
    try {
      setLoading(true);
      const id = await dispatch(addCustomerForm(formId));
      toggleAddModal(false);
      history.push(`${routes.FORMS}/${id}`);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCheck = (formId) => {
    if (!originalFormId) {
      showAlert('warning', 'If you want to export please select a form type first');
      return;
    }

    if (checkedForms?.includes(formId)) {
      setCheckedForms([...checkedForms]?.filter((fId) => fId !== formId));
    } else {
      setCheckedForms([...checkedForms, formId]);
    }
  };

  const handleCheckAll = () => {
    if (!originalFormId) {
      showAlert('warning', 'If you want to export please select a form type first');
      return;
    }

    const allChecked = submittedForms?.filter((f) => f.formId === originalFormId)?.every((f) => checkedForms?.includes(f.id));
    if (allChecked) {
      setCheckedForms([]);
    } else {
      setCheckedForms(submittedForms?.filter((f) => f.formId === originalFormId)?.map((f) => f.id));
    }
  };

  const onExport = async () => {
    try {
      setExporting(true);
      await dispatch(exportForms(checkedForms, originalFormId));
    } catch (err) {
      console.error(err);
    } finally {
      setExporting(false);
    }
  };

  const list = useMemo(() => submittedForms?.filter((form) => {
    let persistence = false;

    if (filter.submitted && form.status === formStatuses.SUBMITTED) {
      persistence = true;
    }

    if (filter.closed && form.status === formStatuses.CLOSED) {
      persistence = true;
    }

    return persistence && (!originalFormId || form.formId === originalFormId);
  }), [submittedForms, filter, originalFormId]);

  const formNames = useMemo(() => {
    const result = [];

    submittedForms?.forEach((form) => {
      if (!result?.map((f) => f.id)?.includes(form.formId)) {
        result.push({ id: form.formId, name: form.name });
      }
    })

    return result;
  }, [submittedForms]);

  return (
    <div className='settings-cmp__main'>
      {fetching && <LoadScreen />}
      <span className='settings-title'>Submitted Forms</span>

      <div className='d-flex space-between'>
        <div className='d-flex m-b-md'>
          <div className='m-r-md'>
            <ActionButton
              onClick={() => setFilter((state) => ({ ...state, submitted: !state.submitted }))}
              bgColor={!!filter.submitted ? setAllocationStatuses.SHIPPED.color : '#dddddd'}
            >
              <SubmittedIcon color="#ffffff" />
              Submitted
            </ActionButton>
          </div>
          <div className='m-l-md'>
            <ActionButton
              onClick={() => setFilter((state) => ({ ...state, closed: !state.closed }))}
              bgColor={!!filter.closed ? setAllocationStatuses.RETURNED.color : '#dddddd'}
            >
              <CompleteIcon color="#ffffff" />
              Closed
            </ActionButton>
          </div>
        </div>

        <div className='d-flex flex-end' style={{ width: '40%' }}>
          <Select
            value={originalFormId}
            onChange={(formId) => {
              setOriginalFormId(formId);
              setCheckedForms([]);
            }}
            options={formNames?.map((f) => ({ label: f.name, value: f.id }))}
            defaultLabel={`All forms (${formNames?.length})`}
            width={300}
          />
        </div>
      </div>

      <SubmittedFormsContainer
        submittedForms={list}
        forms={forms}
        users={users}
        onAddClick={() => toggleAddModal(true)}
        tenantColor={tenantColor}
        onExportClick={onExport}
        exportDisabled={!checkedForms?.length || !originalFormId}
        handleCheck={handleCheck}
        checkedForms={checkedForms}
        handleCheckAll={handleCheckAll}
        exporting={exporting}
      />

      <AddFormModal
        open={addModalOpen}
        onClose={() => toggleAddModal(false)}
        forms={forms?.map((form) => ({ value: form.id, label: form.name }))}
        loading={loading}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default SubmittedFormsPage;
