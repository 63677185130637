import React, { useCallback, useMemo } from 'react';
import { orderBy, sum } from 'lodash';

import BarChart from '../../../charts/BarChart';

import { formatChartPrice } from '../../../../utils/utils';

export const CustomTooltip = (props) => {
  const { data, value, hospitals } = props;
  const hospital = hospitals?.find((u) => u.id === data?.id);

  return (
    <div className="custom-tooltip">
      <div className="tooltip-label">{hospital ? hospital.name : ''}</div>
      <div className="tooltip-content">
        <div>{`$${formatChartPrice(value, 0)}`}</div>
      </div>
    </div>
  );
};

const groupSetsBy = (sets, key) => {
  return sets.reduce((acc, item) => {
    const field = item[key];
    if (!acc[field]) {
      acc[field] = [];
    }
    acc[field].push(item);
    return acc;
  }, {});
};

const calculateFreightCost = (sets) => {
  const shipments = groupSetsBy(sets, 'trackingNumber');
  return sum(Object.keys(shipments).map((trackingNumber) => shipments[trackingNumber][0]?.freightCost || 0));
};


const FreightCostHospitalsBarChart = ({ sets = [], hospitals = [], minDate, maxDate }) => {
  const data = useMemo(() => {
    const allHospitals = hospitals
      .map((hospital) => {
        const hospitalCases = sets.filter((item) => item.hospital === hospital.id && minDate.isBefore(item.shippingDate) && maxDate.isAfter(item.shippingDate));
        const cases = groupSetsBy(hospitalCases, 'caseId');
        const value = sum(Object.keys(cases).map((caseId) => calculateFreightCost(cases[caseId])));

        return {
          id: hospital.id,
          label: hospital.name,
          value,
        };
      })
      .filter((item) => item.value !== 0);

    const sorted = orderBy(allHospitals, 'value', 'desc');
    const top10 = sorted.splice(0, 10);

    return top10;
  }, [sets, hospitals]);

  const getHospitalName = useCallback((hospitalId) => {
    const hospital = hospitals?.find((u) => u.id === hospitalId);
    const name = hospital ? hospital.name : '';
    return name ? `${name?.substring(0, 10)}${name?.length > 10 ? '...' : ''}` : '';
  }, [hospitals]);

  return (
    <div className="management-reports-chart__container m-l-md" style={{ width: '50%' }}>
      <div className="font-size-bg font-bold">Top Hospitals by Freight Cost</div>
      <div className="secondary m-t-sm m-b-lg">Top 10 hospitals by freight cost value</div>
      <BarChart
        data={data || []}
        indexBy="id"
        height="300px"
        layout="horizontal"
        enableLabel={true}
        margin={{ top: 10, right: 25, bottom: 30, left: 85 }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          truncateTickAt: 0,
          format: (id) => getHospitalName(id)
        }}
        labelTextColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              1.6,
            ],
          ],
        }}
        label={(d) => `$${formatChartPrice(d.value, 0)}`}
        CustomTooltip={(props) => <CustomTooltip {...props} hospitals={hospitals} />}
      />
    </div>
  );
};

export default FreightCostHospitalsBarChart;
