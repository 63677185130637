import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function RequestIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinejoin="round"
          d="M3.56577 8.66916C4.66798 8.77641 5.81654 8.90538 6.99675 8.90538C8.21037 8.90538 9.39052 8.76901 10.5212 8.66011C11.0011 8.6139 11.3735 8.23041 11.2365 7.76819C10.8607 6.50078 9.7301 4.8216 9.7301 4.8216C9.7301 4.8216 10.8543 3.22503 11.2333 1.98776C11.3744 1.52679 11.0011 1.14281 10.5212 1.0966C9.39052 0.987704 8.21037 0.851334 6.99675 0.851334C5.81654 0.851334 4.66798 0.9803 3.56577 1.08755C3.04579 1.13814 2.64368 1.57314 2.64368 2.09558V7.66113C2.64368 8.18357 3.04579 8.61857 3.56577 8.66916Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M2.64368 13.1875L2.64367 7.9375" />
  </SvgIcon>
}
