import React from 'react';
import { Field, Form } from 'react-final-form';

import Button from '../../shared/Button';
import Input from '../../shared/Input';

import '../../../styles/shared/form.scss';
import MuiPhoneNumber from 'material-ui-phone-number';

const ContactForm = (props) => {
  const {
    onSubmit = () => {},
    loading,
    onClose,
  } = props;

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Required';
    }
    if (!values.position) {
      errors.position = 'Required';
    }
    if (!values.phone) {
      errors.phone = 'Required';
    }
    if (!values.email) {
      errors.email = 'Required';
    }

    return errors;
  };

  return (
    <div className='form__container'>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        subscription={{ pristine: true, invalid: true }}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <div className='form__subtitle'>
                Add Contact
              </div>

              <div className='combined-fields__container'>
                <Field name='name'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      borderless
                      type='text'
                      onChange={input.onChange}
                      startAdornment='Name'
                    />
                  )}
                </Field>
                <Field name='position'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      borderless
                      type='text'
                      onChange={input.onChange}
                      startAdornment='Position'
                    />
                  )}
                </Field>
              </div>

              <div>
                <div className='field__title'>Phone</div>
                <Field name='phone'>
                  {({ input, meta }) => (
                    <MuiPhoneNumber
                      value={input.value}
                      onChange={input.onChange}
                      fullWidth
                      placeholder='Phone'
                      defaultCountry='au'
                      className='form__phone p-l-md'
                      InputProps={{ disableUnderline: true }}
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-lg'>
                <div className='field__title'>Email</div>
                <Field name='email'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              <div className='form__actions'>
                <Button
                  type='submit'
                  text='Add Contact'
                  disabled={invalid || loading || pristine}
                  loading={loading}
                  onClick={handleSubmit}
                />
                <Button
                  type='cancel'
                  onClick={onClose}
                />
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default ContactForm;
