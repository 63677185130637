import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';

import Button from '../../shared/Button';
import Input from '../../shared/Input';
import FileUpload from '../../shared/FileUpload';

import useAlert from '../../../hooks/useAlert';

import '../../../styles/shared/form.scss';
import Checkbox from '../../shared/Checkbox';

const SectionForm = (props) => {
  const {
    mode = 'create',
    initialValues,
    buttonText,
    onSubmit,
    loading,
    onClose,
    onUpdateClick,
    tenantColor
  } = props;

  const { name } = initialValues || {};

  const { showAlert } = useAlert();

  const [image, setImage] = useState(null);

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Required';
    }

    if (!values.subtitle) {
      errors.subtitle = 'Required';
    }

    return errors;
  };

  const getItemsNumber = () => {
    const length = initialValues?.items?.length || 0;

    if (!length) {
      return '';
    }

    return ` (${length === 1 ? `${length} item` : `${length} items`})`;
  };

  return (
    <div className='form__container'>
      <Form
        onSubmit={(formObj) => onSubmit(formObj, image, mode)}
        validate={validate}
        initialValues={{
          ...initialValues,
        }}
        subscription={{ pristine: true, invalid: true }}
        mutators={{
          removeImage: (args, state, utils) => {
            utils.changeValue(state, 'image', () => null)
          }
        }}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine, form }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <div className='form__subtitle d-flex space-between'>
                <div>
                  {name ? `${name}${getItemsNumber()}` : 'Add Section'}
                </div>
                {mode === 'short' && (
                  <div onClick={onUpdateClick} className='pointer' style={{ color: tenantColor }}>
                    Edit
                  </div>
                )}
              </div>
              {['short', 'update'].includes(mode) && (
                <Field name='active'>
                  {({ input, meta }) => (
                    <Input
                      type='switch'
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      placeholder='Status'
                    />
                  )}
                </Field>
              )}

              {mode !== 'short' && (
                <div className='m-b-sm'>
                  <div className='field__title'>Name</div>
                  <Field name='name'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        placeholder='Name'
                        type='text'
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>
              )}

              {mode !== 'short' && (
                <div className='m-b-sm'>
                  <div className='field__title'>Subtitle</div>
                  <Field name='subtitle'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        placeholder=''
                        type='text'
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>
              )}

              {mode !== 'short' && (
                <div className='m-b-sm'>
                  <div className='field__title'>Description</div>
                  <Field name='description'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        placeholder=''
                        type='text'
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>
              )}

              {mode !== 'short' && (
                <div className='m-b-lg'>
                <div className='field__title'>Image (must be less than 500kb file size)</div>
                <Field name='imageName'>
                  {({ input, meta }) => (
                    <FileUpload
                      id='tenant-logo'
                      file={image}
                      onUpload={(file) => {
                        if (file.size > 512000) {
                          showAlert('warning', 'File size must be less than 500kb')
                        } else {
                          setImage(file);
                          input.onChange(file.name);
                        }
                      }}
                      placeholder={input.value}
                      accept='image'
                      onRemove={() => {
                        setImage(null);
                        input.onChange('');
                        form.mutators.removeImage();
                      }}
                    />
                  )}
                </Field>
              </div>
              )}

              {mode !== 'short' && (
                <div className='m-b-lg'>
                  <Field name='printOnNewPage' type='checkbox'>
                    {({ input }) => (
                      <Checkbox
                        input={input}
                        fontSize='small'
                        label='Print on new page'
                      />
                    )}
                  </Field>
                </div>
              )}

              <div className='form__actions'>
                <Button
                  type='submit'
                  text={buttonText || 'Save Changes'}
                  disabled={invalid || loading || pristine}
                  loading={loading}
                  onClick={handleSubmit}
                />
                { mode === 'create' && (
                  <Button
                    type='cancel'
                    onClick={onClose}
                  />
                )}
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default SectionForm;
