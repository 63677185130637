const defaultStaticColor = '#00aeef';

const palette = (colorPrimary = defaultStaticColor) => ({
  primary: {
    main: colorPrimary,
    light: colorPrimary,
    dark: colorPrimary,
    contrastText: '#ffffff',
    grey: '#eeeeee',
    greyDark: '#c6c8c9',
    danger: '#F53434',
    success: '#4AB026',
    warning: '#F97032',
    green: '#81B214',
    orange: '#F58634'
  },
  secondary: {
    main: '#b1b1b1',
    light: '#f2f2f2',
    dark: '#333333',
    contrastText: '#ffffff',
    grey: '#eeeeee',
    greyDark: '#c6c8c9',
    back: '#F9F9F9',
    danger: '#F53434',
    success: '#4AB026',
    warning: '#F97032',
    green: '#81B214',
    orange: '#F58634'
  }
});

export default palette;
export { defaultStaticColor };
