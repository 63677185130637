import { GET_FLOWS, SET_FLOWS } from './actionTypes';

import firebase, { collections } from '../firebase';

export const setFlows = flows => ({ type: SET_FLOWS, flows });

export const getFlows = (tenant, withReducer) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = tenant || state?.tenant?.currentTenant?.id;
  const path = collections.FLOWS_COLLECTION(tenantId);

  dispatch({ type: GET_FLOWS });

  const snapshot = await firebase.db.collection(path).get();
  const flows = snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));

  if (tenant && !withReducer) {
    return flows;
  }

  return dispatch(setFlows(flows));
};

export const createFlow = (flowData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.FLOWS_COLLECTION(tenantId);

  const doc = {
    ...flowData,
    active: true,
  };

  if (tenantId) {
    await firebase.db.collection(path).add(doc);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateFlow = (flowId, flowData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.FLOWS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(flowId).set(flowData, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteFlow = (flowId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.FLOWS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(flowId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const bulkDeleteFlows = (flows) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.FLOWS_COLLECTION(tenantId);
  const batch = firebase.db.batch();

  if (tenantId) {
    flows.forEach((flowId) => {
      const ref = firebase.db.collection(path).doc(flowId);
      batch.delete(ref);
    });

    await batch.commit();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const uploadFlows = (flows) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.FLOWS_COLLECTION(tenantId);

  if (tenantId) {
    const promises = [];

    flows.forEach((flow) => {
      const name = flow[0] || '';
      const flowId = flow[1] || '';
      const type = flow[2] ? flow[2].toUpperCase() : '';

      const doc = {
        name,
        flowId,
        type,
        allowQuantity: false,
        active: true,
        products: [],
      };

      promises.push(firebase.db.collection(path).add(doc));
    });

    await Promise.all(promises);
  } else {
    throw new Error('Invalid tenant');
  }
};

// Flow steps

export const getSteps = (flowId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.FLOW_STEPS_COLLECTION(tenantId, flowId);

  let query = await firebase.db.collection(path);
  const snapshot = await query.get();
  const steps = snapshot.docs.map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
    };
  });

  return steps;
};

export const createStep = (flowId, stepData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.FLOW_STEPS_COLLECTION(tenantId, flowId);

  const doc = {
    ...stepData,
    active: true,
  };

  if (tenantId) {
    await firebase.db.collection(path).add(doc);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateStep = (flowId, stepId, stepData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.FLOW_STEPS_COLLECTION(tenantId, flowId);

  if (tenantId) {
    await firebase.db.collection(path).doc(stepId).set(stepData, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteStep = (flowId, stepId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.FLOW_STEPS_COLLECTION(tenantId, flowId);

  if (tenantId) {
    await firebase.db.collection(path).doc(stepId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const reorderSteps = (flowId, steps) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.FLOW_STEPS_COLLECTION(tenantId, flowId);

  if (tenantId) {
    await Promise.all(steps.map((step, index) => (
       firebase.db.collection(path).doc(step.id).set({ stepNumber: index + 1 }, { merge: true })
    )));
  } else {
    throw new Error('Invalid tenant');
  }
}

// Subscriptions
export const subscribeToFlows = () => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.FLOWS_COLLECTION(tenantId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let flows = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;

          flows = [...flows, {
            ...documentSnapshot.data(),
            id,
          }];
        });

        return dispatch(setFlows(flows));
      },
    });
};
