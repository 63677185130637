import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import EditIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Check';

import Checkbox from '../shared/Checkbox';

import { updateLabel } from '../../actions/setsActions';

import '../shared/modal-select/modal-select.scss';

const SetLabel = (props) => {
  const {
    checked,
    name,
    onChange,
    id,
  } = props;

  const dispatch = useDispatch();

  const [label, setLabel] = useState(name || '');
  const [editMode, toggleEditMode] = useState(false);

  const onSave = () => {
    dispatch(updateLabel(id, label));
    toggleEditMode(false);
  };

  return (
    <div className='md-user-info'>
      <div>
        {editMode ? (
          <input
            value={label}
            onChange={(e) => setLabel(e.target.value)}
            maxLength={20}
          />
        ) : (
          <Checkbox
            input={{
              onClick: onChange,
              checked,
            }}
            fontSize='small'
            label={name}
          />
        )}
      </div>
      {editMode ? (
        <div className='md-user-info__delete' onClick={onSave}>
          <SaveIcon fontSize='small' color='primary' />
        </div>
      ) : (
        <div className='md-user-info__delete' onClick={() => toggleEditMode(true)}>
          <EditIcon fontSize='small' color='primary' />
        </div>
      )}
    </div>
  );
};

export default SetLabel;
