import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Tooltip } from '@material-ui/core';

import Modal from '../../shared/modal';

import { caseChecklistStatuses } from '../../../constants/enums';

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: '12px',
    padding: '4px 8px',
    borderRadius: 8
  }
}));

const ChecklistSubmitModal = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    defaultChecklist,
    checklist,
    tenantColor,
    loading,
  } = props;

  const classes = useStyles();

  const changedValues = useMemo(() => {
    const result = [];

    defaultChecklist?.sections.forEach((section, sectionIndex) => {
      section?.items?.forEach((item, index) => {
        const updatedSection = checklist?.sections[sectionIndex];
        if (updatedSection) {
          const updatedItem = updatedSection?.items[index];
          if (updatedItem && Number(updatedItem.outbound) !== Number(item.quantity)) {
            result?.push({
              code: item.code,
              quantity: item.quantity,
              outbound: updatedItem.outbound,
              description: item.description,
            });
          }
        }
      });
    });

    return result;
  }, [checklist?.sections, defaultChecklist?.sections]);

  const isIncomplete = changedValues && !!changedValues.some((value) => value.outbound < value.quantity);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Submit Checklist'
      fullWidth
      size='md'
      submitButton='Submit'
      closeButton='Close'
      onSubmit={() => onSubmit({
        ...checklist,
        status: isIncomplete ? caseChecklistStatuses.INCOMPLETE.value : caseChecklistStatuses.COMPLETE.value
      })}
      loading={loading}
      disabled={loading}
    >
      <div className='submit-modal__container'>
        <div className='secondary font-bold'>
          You are about to submit a checklist for the following
        </div>
        <div className='checklist-data__container'>
          <div className='font-bold'>{checklist.kitId}</div>
          <div className='m-t-sm'>{checklist.setNumber}</div>
          <div className='m-t-sm font-bold' style={{ color: isIncomplete ? caseChecklistStatuses.INCOMPLETE.color : caseChecklistStatuses.COMPLETE.color }}>
            {isIncomplete ? caseChecklistStatuses.INCOMPLETE.label : caseChecklistStatuses.COMPLETE.label}
          </div>

          {changedValues && !!changedValues.length && (
            <div className='checklist-updated-table'>
              <div className='m-r-lg'>
                <div className='font-bold secondary uppercase m-b-sm'>Item code</div>
                {changedValues?.map((item) => <div className='font-bold'>{item.code}</div>)}
              </div>
              <div className='m-r-lg flex-1'>
                <div className='font-bold secondary uppercase m-b-sm'>Description</div>
                {changedValues?.map((item) => (
                  item?.description?.length > 50 ? (
                    <Tooltip
                      title={item?.description}
                      aria-label='item-description'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <div
                        className='font-bold'
                        style={{
                          width: 500,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {item.description}
                      </div>
                    </Tooltip>
                  ) : (
                    <div className='font-bold'>
                      {item.description}
                    </div>
                  )
                ))}
              </div>
              <div className='m-r-lg'>
                <div className='font-bold secondary uppercase text-center m-b-sm'>QTY</div>
                {changedValues?.map((item) => <div className='font-bold text-center'>{item.quantity}</div>)}
              </div>
              <div>
                <div className='font-bold secondary uppercase text-center m-b-sm'>OUT</div>
                {changedValues?.map((item) => (
                  <div className='font-bold text-center' style={{ color: isIncomplete ? caseChecklistStatuses.INCOMPLETE.color : tenantColor }}>{item.outbound}</div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ChecklistSubmitModal;
