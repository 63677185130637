import React, { useState } from 'react';

import Modal from '../../shared/modal';
import Button from '../../shared/Button';
import Checkbox from '../../shared/Checkbox';

export const EXPORT_TYPES = {
  DEFAULT_EXCEL: 'DEFAULT_EXCEL',
  DEFAULT_CSV: 'DEFAULT_CSV',
  UNLEASHED_CSV: 'UNLEASHED_CSV',
};

const options = [
  { key: 0, label: 'Detailed Usage Export (inc. case Info & images in .xlsx format)', value: EXPORT_TYPES.DEFAULT_EXCEL },
  { key: 1, label: 'Usage Only Export (single-sheet .csv format)', value: EXPORT_TYPES.DEFAULT_CSV },
  { key: 2, label: 'Unleashed Sales Order Export (single-sheet .csv format)', value: EXPORT_TYPES.UNLEASHED_CSV },
];

const ExportUsageModal = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    loading,
  } = props;

  const [selected, setSelected] = useState(EXPORT_TYPES.DEFAULT_EXCEL);

  const handleSubmit = () => {
    onSubmit(selected);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Export Usage'
      fullWidth
    >
      <div style={{ padding: '0 24px 24px' }}>
        <div className='m-t-lg'>
          <div className='secondary m-b-sm'>Select to Export</div>
          <div className='dispatch-modal-checkboxes__container'>
            {options?.map((opt) => (
              <Checkbox
                key={opt.key}
                label={opt.label}
                input={{
                  checked: selected === opt.value,
                  onChange: (e) => {
                    if (e?.target?.checked) {
                      setSelected(opt.value);
                    } else {
                      setSelected(null);
                    }
                  }
                }}
                fontSize='small'
              />
            ))}
          </div>
        </div>

        <div className='form__actions p-t-lg'>
          <Button
            type='submit'
            text='Export'
            onClick={handleSubmit}
            disabled={loading || !selected}
            loading={loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ExportUsageModal;
