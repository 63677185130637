import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import DocumentsIcon from '../../assets/icons/DocumentIcon';
import FileIcon from '../../assets/icons/FileIcon';
import DeleteOutlineIcon from '../../assets/icons/DeleteIcon';

import DocumentsUploadModal from './modals/DocumentsUploadModal';
import AddButton from '../shared/AddButton';

import { onUploadComplete } from '../../actions/casesActions';

const CaseDocuments = (props) => {
  const { documents = [], preview, onDelete, onPreviewClick, caseId, tenantId, tenantColor, connectedTenantId } = props;
  const dispatch = useDispatch();

  const [isModalOpen, toggleModal] = useState(false);

  const onUpload = (fileName, downloadUrl) => {
    dispatch(onUploadComplete(caseId, { name: fileName, downloadUrl }, connectedTenantId));
  };

  const onFileClick = (url) => {
    window.open(url, '_blank');
  };

  const list = useMemo(() => {
    if (preview) {
      return documents.slice(0, 3);
    }
    return documents;
  }, [documents, preview]);

  return (
    <div className='case-documents__container' style={preview && { marginTop: '16px' }}>
      <div className='d-flex space-between m-b-lg'>
        <div className='d-flex'>
          <DocumentsIcon />
          <div className='font-size-bg font-bold m-l-md'>
            Documents
          </div>
        </div>

        <AddButton onClick={() => toggleModal(true)} />
      </div>

      <div
        className={`case-documents-list__container ${preview ? 'pointer' : ''}`}
        onClick={onPreviewClick}
      >
        { documents && list.map((document) => (
          <div key={document.id} className='case-document'>
            <div
              className='d-flex pointer'
              onClick={() => onFileClick(document.downloadUrl)}
            >
              <FileIcon className='m-r-md' />
              {document.fileName}
            </div>
            { !preview && (
              <IconButton
                onClick={() => onDelete(document.id)}
                size='small'
              >
                <DeleteOutlineIcon />
              </IconButton>
            )}
          </div>
        ))}
      </div>

      <DocumentsUploadModal
        open={isModalOpen}
        onClose={() => toggleModal(false)}
        caseId={caseId}
        tenantId={tenantId}
        tenantColor={tenantColor}
        onUploadComplete={onUpload}
      />
    </div>
  );
};

export default CaseDocuments;
