import React, { useRef, useState } from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';

import DeleteIcon from '../../../assets/icons/DeleteIcon';
import ItemsIcon from '../../../assets/icons/ItemIcon';
import ExportIcon from '../../../assets/icons/DownloadIcon';
import ImportIcon from '../../../assets/icons/UploadIcon';
import UploadIcon from '../../../assets/icons/UploadIcon';
import ItemIcon from '../../../assets/icons/ItemIcon';
import TitleIcon from '../../../assets/icons/TitleIcon';
import DocumentIcon from '../../../assets/icons/DocumentIcon';

import Button from '../../shared/Button';
import DropdownButton from '../../shared/DropdownButton';

import { billOfMaterialItemTypes } from '../../../constants/enums';

const ITEM_HEIGHT = 48;

const StyledMenuItem = withStyles((theme) => ({
  root: {
    paddingTop: '12px',
    paddingBottom: '12px',
  }
}))(MenuItem);

const dropdownOptions = [
  { id: billOfMaterialItemTypes.ITEM.value, label: 'Add Items', icon: <ItemIcon /> },
  { id: billOfMaterialItemTypes.NOTES.value, label: 'Add Notes', icon: <TitleIcon /> },
];

const BillOfMaterialActions = (props) => {
  const {
    onAddClick,
    onDeleteClick,
    selectedSection,
    onUploadClick,
    onBOMUpload,
    uploading,
    sections = [],
    items = [],
    kitId,
    deletePermission,
    onExportPdfClick,
    exporting,
  } = props;

  const inputRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onExport = () => {
    const data = sections?.map((section) => ({
      name: section.name,
      description: section.description,
      orderNumber: section.orderNumber,
      subtitle: section.subtitle,
      items: section?.items?.map((sectionItem) => {
        const item = items?.find((i) => i.id === sectionItem.id);
        return {
          code: item?.code || '',
          quantity: sectionItem.quantity || 0,
          ref: sectionItem?.ref || '',
        };
      })
    }));

    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(data)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = `BOM_${kitId}.json`;
    link.click();
    handleClose();
  };

  const onUpload = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    const fileReader = new FileReader();
    fileReader.readAsText(fileObj, "UTF-8");
    fileReader.onload = async (e) => {
      const data = e.target.result;
      const parsedData = data ? JSON.parse(data) : [];
      onBOMUpload(parsedData);
      handleClose();
    };
  };

  return (
    <div className='settings-cmp__filters--main'>
      <div className='settings-cmp__actions'>
        {selectedSection ? (
          <DropdownButton
            onClick={(option) => onAddClick(option.id)}
            options={dropdownOptions}
          >
            <div className='d-flex m-l-lg'>
              Add
              <ExpandIcon />
            </div>
          </DropdownButton>
        ) : (
          <Button
            text="Add Section"
            type="submit"
            onClick={onAddClick}
          />
        )}

        <Button
          type='icon'
          onClick={onDeleteClick}
          disabled={!deletePermission}
        >
          <DeleteIcon />
        </Button>

        <Button
          type='submit'
          width='60px'
          onClick={handleClick}
          disabled={uploading || exporting}
          loading={uploading || exporting}
        >
          {!uploading && !exporting && <UploadIcon color="#ffffff" />}
        </Button>

        <Menu
          id='long-menu'
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 200,
            },
            elevation: 3,
          }}
        >
          <StyledMenuItem onClick={onUploadClick} disabled={!selectedSection}>
            <ItemsIcon />
            <span className='m-l-md'>Upload Items</span>
          </StyledMenuItem>
          <StyledMenuItem onClick={onExport}>
            <ExportIcon />
            <span className='m-l-md'>Export BOM</span>
          </StyledMenuItem>
          <label htmlFor="json-upload" style={{ width: '100%' }}>
            <StyledMenuItem>
              <ImportIcon />
              <span className='m-l-md'>Import BOM</span>
            </StyledMenuItem>
          </label>
          <StyledMenuItem
            onClick={() => {
              handleClose();
              onExportPdfClick();
            }}
          >
            <DocumentIcon />
            <span className='m-l-md'>Export PDF</span>
          </StyledMenuItem>
          <input
            style={{ display: 'none' }}
            ref={inputRef}
            accept="application/JSON"
            id="json-upload"
            type='file'
            onChange={onUpload}
          />
        </Menu>
      </div>
    </div>
  );
};

export default BillOfMaterialActions;
