import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import ArrowIconUp from '@material-ui/icons/KeyboardArrowUp';
import ArrowIconDown from '@material-ui/icons/KeyboardArrowDown';
import SectionIcon from '../../../assets/icons/DocumentIcon';
import FullScreenIcon from '../../../assets/icons/FullScreenIcon';

import SectionItemRow from './SectionItemRow';
import SectionNotesRow from './SectionNotesRow';
import { ConfirmationModal } from '../../shared/modal';

import { billOfMaterialItemTypes } from '../../../constants/enums';

import './checklists.scss';

const CountSection = (props) => {
  const { name, image, items, onChange, sectionIndex, tenantColor, subtitle } = props;

  const [open, setOpen] = useState(false);
  const [openModal, toggleModal] = useState(false);

  const onValuesChange = (index, count) => {
    let temp = [...items];
    temp[index] = { ...temp[index], count };

    onChange(sectionIndex, temp);
  };

  const onSubmit = () => {
    let temp = [...items];
    temp?.forEach((item) => {
      item.count = 0;
    });
    onChange(sectionIndex, temp);
    toggleModal(false);
  };

  return (
    <div className='checklist-section-item'>
      <div className='d-flex'>
        <SectionIcon />
        <div className='font-size-bg font-bold m-l-md'>
          {name}
        </div>

        <IconButton
          className='m-l-md'
          aria-label='more'
          aria-controls='long-menu'
          aria-haspopup='true'
          onClick={() => setOpen(!open)}
          size='small'
        >
          {open ? <ArrowIconUp fontSize='large' /> : <ArrowIconDown fontSize='large' />}
        </IconButton>
      </div>

      {open && (
        <div>
          <div className='font-bold secondary' style={{ paddingLeft: 30 }}>{subtitle}</div>
          {!!image && !!image.downloadUrl && (
            <div className='section-image__container'>
              <img src={image.downloadUrl} alt='section-preview' />
              <div
                className='full-screen-icon'
                onClick={() => window.open(image?.downloadUrl, '_blank')}
              >
                <FullScreenIcon color='secondary' fontSize='large' />
              </div>
            </div>
          )}

          <div className='flex-1 d-flex flex-end m-t-lg p-r-lg'>
            <div
              className='font-bold pointer font-size-sm m-r-lg'
              style={{ color: tenantColor }}
              onClick={() => toggleModal(true)}
            >
              Clear All
            </div>
          </div>

          <div className={'m-t-md'}>
            {items && !!items.length && (
              <table className='section-items-table'>
                <thead>
                <tr>
                  <th>Ref</th>
                  <th>Item Code</th>
                  <th>Description</th>
                  <th>Type</th>
                  <th>Image</th>
                  <th style={{ textAlign: 'center' }}>QTY</th>
                  <th
                    style={{ textAlign: 'right', paddingRight: '36px' }}
                  >
                    Count
                  </th>
                </tr>
                </thead>
                {
                  <tbody>
                  {items.map((item, index) => (
                    item.type === billOfMaterialItemTypes.NOTES.value ? (
                      <SectionNotesRow
                        key={`${item.id}_${Math.random()}`}
                        item={item}
                      />
                    ) : (
                      <SectionItemRow
                        key={`${item.code}_${Math.random()}`}
                        item={item}
                        onSave={(count) => onValuesChange(index, count)}
                        tenantColor={tenantColor}
                      />
                    )
                  ))}
                  </tbody>
                }
              </table>
            )}
          </div>
        </div>
      )}

      <ConfirmationModal
        open={openModal}
        onClose={() => toggleModal(false)}
        onSubmit={onSubmit}
        title='Set all items to 0 Out'
        text='Are you sure you want to set all items in this section to 0 QTY OUT?'
        submitText='Yes'
      />
    </div>
  );
};

export default CountSection;
