import React, { useCallback } from 'react';

import Table from '../../shared/table/Table';

const columns = [
  { title: 'name', field: 'name', type: 'default', sort: true },
  { title: 'address', field: 'address', type: 'default', sort: true },
  { title: 'territories', field: 'territoryNames', type: 'default', sort: true },
  { title: 'assigned surgeons', field: 'surgeons', type: 'default', sort: true },
];

const getHospitalAddress = (hospital) => {
  const {
    street = '',
    city = '',
    state = '',
    postCode = '',
    country = '',
  } = hospital;
  const arr = [street, city, state, postCode, country].filter((value) => !!value);
  return arr.join(', ');
}

const HospitalsTable = (props) => {
  const {
    onSelectHospital,
    hospitals,
    selectedHospitalId,
    surgeons = [],
    territories = []
  } = props;

  const getSurgeons = useCallback((hospitalId) => {
    const _surgeons = surgeons?.filter((surgeon) => surgeon?.hospitals?.includes(hospitalId));
    return _surgeons?.map((surgeon) => `${surgeon.firstName} ${surgeon.lastName}`)?.join(', ');
  }, [surgeons, hospitals]);

  const getTerritoryNames = (ids) => {
    return ids?.map((id) => {
      const territory = territories.find((item) => item.id === id);
      return territory ? territory.name : '';
    })?.join(', ');
  };

  return (
    <div style={{ width: '100%' }}>
      <Table
        rows={hospitals.map((hospital) => ({
          ...hospital,
          surgeons: getSurgeons(hospital.id),
          territoryNames: getTerritoryNames(hospital.territories),
          address: getHospitalAddress(hospital),
        }))}
        columns={columns}
        onRowClick={onSelectHospital}
        selected={selectedHospitalId}
        pagination
        checkboxes={false}
      />
    </div>
  );
};

export default HospitalsTable;
