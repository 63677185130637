import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function CalendarMarkIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path id="Vector" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M4 6.05h0.5"></path>
    <path id="Vector_2" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M4 9.05h0.5"></path>
    <path id="Vector_3" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M6.75 6.05h0.5"></path>
    <path id="Vector_4" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M9.5 6.05h0.5"></path>
    <path id="Vector_5" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M6.75 9.05h0.5"></path>
    <path id="Intersect" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M1.198 10.525c0.161 1.44 1.331 2.582 2.778 2.652 1 0.048 2.021 0.073 3.13 0.073s2.13 -0.025 3.13 -0.073c1.447 -0.07 2.617 -1.213 2.778 -2.652 0.109 -0.97 0.198 -1.963 0.198 -2.975 0 -1.013 -0.09 -2.006 -0.198 -2.976 -0.161 -1.44 -1.331 -2.582 -2.778 -2.652 -1 -0.048 -2.021 -0.073 -3.13 -0.073s-2.13 0.025 -3.13 0.073c-1.447 0.07 -2.617 1.213 -2.778 2.652C1.089 5.544 1 6.537 1 7.55c0 1.012 0.09 2.006 0.198 2.975Z"></path>
    <path id="Line 3" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="m4.253 0.75 0 2.5"></path>
    <path id="Line 4" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="m9.75 0.75 0 2.5"></path>
  </SvgIcon>
}

