import React, { useMemo } from 'react';
import { orderBy } from 'lodash';

import { PieChart } from '../../../charts';

const KitDistributionPieChart = ({ sets = [], kits = [] }) => {
  const data = useMemo(() => {
    const chartData = kits
      ?.filter((kit) => !!kit.active)
      ?.map((kit) => {
        const kitSets = sets.filter((set) => set.kit === kit.id);
        return {
          id: kit.kitId,
          label: kit.kitId,
          value: kitSets.length,
        };
      })

    return orderBy(chartData?.filter((item) => !!item.value), 'value', 'asc');
  }, [sets, kits]);

  return (
    <div className="management-reports-chart__container m-r-md" style={{ width: '50%' }}>
      <div className="font-size-bg font-bold">Distribution by Kit ID</div>
      <div className="secondary m-t-sm m-b-lg">Total sets shown by kit</div>
      <PieChart
        data={data}
        margin={{ top: 20, bottom: 20 }}
        height="300px"
        enableArcLinkLabels={true}
        enableArcLabels={true}
        entity='set'
        legends={[]}
      />
    </div>
  );
};

export default KitDistributionPieChart;
