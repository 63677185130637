import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import MuiPhoneNumber from 'material-ui-phone-number';

import ProfileIcon from '../../assets/icons/ProfileIcon';
import LockIcon from '../../assets/icons/LockIcon';
import WarningIcon from '@material-ui/icons/ErrorOutline';

import Button from '../shared/Button';
import Input from '../shared/Input';
import FileUpload from '../shared/FileUpload';

import '../../styles/shared/form.scss';

const ProfileForm = (props) => {
  const {
    initialValues,
    onSubmit,
    loading,
    adminPassword,
  } = props;

  const [profileImage, setProfileImage] = useState(null);

  const validate = (values) => {
    const errors = {};

    if (!values.firstName) {
      errors.firstName = 'Required';
    }
    if (!values.lastName) {
      errors.lastName = 'Required';
    }
    if (!values.email) {
      errors.email = 'Required';
    }

    return errors;
  };

  return (
    <div className='form__container m-r-lg' style={{ width: '40%' }}>
      <Form
        onSubmit={(formObj) => onSubmit(formObj, { profileImage })}
        validate={validate}
        initialValues={{
          ...initialValues,
        }}
        subscription={{ pristine: true, invalid: true }}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <div>
                <div className='d-flex m-b-lg'>
                  <ProfileIcon />
                  <div className='font-size-bg font-bold m-l-md'>Info</div>
                </div>
                <div className='field__title'>Name</div>
                <div className='combined-fields__container'>
                  <Field name='firstName'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        borderless
                        startAdornment='First'
                        type='text'
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                  <Field name='lastName'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        borderless
                        startAdornment='Last'
                        type='text'
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>
              </div>

              <div>
                <div className='field__title'>Email</div>
                <Field name='email'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      placeholder='Email'
                      type='text'
                      onChange={input.onChange}
                      disabled
                      endAdornment={<LockIcon style={{ marginRight: 12 }} color='secondary' />}
                    />
                  )}
                </Field>
              </div>

              <div>
                <div className='field__title'>Phone Number</div>
                <Field name='phone'>
                  {({ input, meta }) => (
                    <MuiPhoneNumber
                      value={input.value}
                      onChange={input.onChange}
                      fullWidth
                      placeholder='Phone'
                      defaultCountry='au'
                      className='form__phone p-l-md'
                      InputProps={{ disableUnderline: true }}
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-lg'>
                <div className='field__title'>Profile Image</div>
                <Field name='profilePictureName'>
                  {({ input, meta }) => (
                    <FileUpload
                      id='profile-picture'
                      file={profileImage}
                      onUpload={(file) => {
                        setProfileImage(file);
                        input.onChange(file.name);
                      }}
                      placeholder={input.value}
                      accept='image'
                    />
                  )}
                </Field>
              </div>

              <div className=''>
                <div className='field__title'>Web App Notifications</div>
                <Field name='enableAppNotifications'>
                  {({ input, meta }) => (
                    <Input
                      type='switch'
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      placeholder='Enable Notifications'
                    />
                  )}
                </Field>
              </div>

              <div>
                <div className='d-flex'>
                  <div className='field__title'>Reset Password</div>
                  {adminPassword && (
                    <div style={{ marginLeft: 4, marginBottom: 2 }}>
                      <WarningIcon style={{ color: '#ff9800' }} />
                    </div>
                  )}
                </div>
                <div className='combined-fields__container'>
                  <Field name='currentPassword'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        borderless
                        startAdornment='Current'
                        type='password'
                        onChange={input.onChange}
                      />
                    )}
                  </Field>

                  <Field name='newPassword'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        borderless
                        startAdornment='New'
                        type='password'
                        onChange={input.onChange}
                      />
                    )}
                  </Field>

                  <Field name='confirmPassword'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        borderless
                        startAdornment='Confirm'
                        type='password'
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>
              </div>

              <div className='form__actions' style={{ marginTop: '8px' }}>
                <Button
                  type='submit'
                  text='Save Changes'
                  disabled={invalid || loading}
                  loading={loading}
                  onClick={handleSubmit}
                />
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default ProfileForm;
