import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ItemReportingTable, ItemReportingForm } from '../../../components/reporting';
import LoadScreen from '../../../components/load-screen';

import { useLoading, useAlert } from '../../../hooks';

import { getCounts, getCountReportingData } from '../../../actions/countsActions';
import { getHospitals } from '../../../actions/hospitalsActions';

import { countStatuses } from '../../../constants/enums';

import './reporting-page.scss';

const filteredFields = ['hospitalName', 'location', 'userName'];
const getInitialFilters = () => {
  const initialFilter = {};

  filteredFields?.forEach((field) => {
    initialFilter[field] = [];
  });

  return initialFilter;
};

const CountReportingPage = () => {
  const dispatch = useDispatch();
  const counts = useSelector((state) => state?.counts?.list?.filter((count) => count.status === countStatuses.COMPLETED.value));
  const hospitals = useSelector((state) => state?.hospitals.list);
  const users = useSelector((state) => state?.users.list);

  const { loading, startLoading, stopLoading } = useLoading();
  const { showAlert } = useAlert();

  const [filter, setFilter] = useState(getInitialFilters());
  const [fetching, setFetching] = useState(false);
  const [scans, setScans] = useState([]);

  useEffect(() => {
    onLoad().catch((err) => console.error(err));
  }, []);

  const onLoad = async () => {
    startLoading()
    try {
      await dispatch(getHospitals());
      await dispatch(getCounts());
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const handleFilterChange = (field, values) => {
    setFilter({ ...filter, [field]: values });
  };

  const getFilterOptions = () => {
    const hospitalsOptions = [];
    const locationsOptions = [];
    const usersOptions = [];

    counts?.forEach((item) => {
      const { hospitalId, location, completedBy } = item;

      if (hospitalId && !hospitalsOptions?.map((item) => item.id)?.includes(hospitalId)) {
        const hospitalObj = hospitals?.find((item) => item.id === hospitalId);
        if (hospitalObj) {
          hospitalsOptions?.push(hospitalObj);
        }
      }

      if (location && !locationsOptions?.map((item) => item.name).includes(location)) {
        locationsOptions?.push({ id: location, name: location });
      }

      if (completedBy && !usersOptions?.map((item) => item.uid)?.includes(completedBy)) {
        const userObj = users?.find((user) => user.uid === completedBy);
        if (userObj) {
          usersOptions?.push({ ...userObj, id: userObj.uid });
        }
      }
    });

    return { hospitalName: hospitalsOptions, location: locationsOptions, userName: usersOptions };
  };

  const handleRemoveFilterItem = (key, value) => {
    setFilter({ ...filter, [key]: filter[key].filter((item) => item !== value) });
  };

  const onSubmit = async (data) => {
    setFetching(true);
    try {
      const res = await dispatch(getCountReportingData(data));
      if (res) {
        if (!res?.length) {
          showAlert('warning', 'No completed scans using these filters');
          setScans([]);
        } else {
          setScans(res);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setFetching(false);
    }
  };

  const types = useMemo(() => {
    const result = [];
    return result || [];
  }, [counts]);

  return (
    <div className='page-container reporting-page__container'>
      {loading && <LoadScreen />}
      <div className='page-title'>Items Counted</div>
      <div className='reporting-page__body'>
        <ItemReportingForm
          users={users}
          hospitals={hospitals}
          types={types}
          onSubmit={onSubmit}
          loading={fetching}
        />

        <ItemReportingTable
          scans={scans}
          hospitals={hospitals}
          users={users}
          filter={filter}
          filterOptions={getFilterOptions()}
          onFilterChange={handleFilterChange}
          onRemoveFilter={handleRemoveFilterItem}
        />
      </div>
    </div>
  );
};

export default CountReportingPage;
