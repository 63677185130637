import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';

import { firebaseConfig } from '../config';

import { USERS_COLLECTION, TENANTS_COLLECTION, SETTINGS_COLLECTION } from './collections';

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);

    this.app = app;
    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
    this.analytics = app.analytics();
    this.timestamp = app.firestore.Timestamp;
    this.fieldPath = app.firestore.FieldPath;
    this.fieldValue = app.firestore.FieldValue;
  }

  doCreateUserWithEmailAndPassword = async (email, password, userData) => {
    try {
      const resp = await this.auth.createUserWithEmailAndPassword(email, password);
      const { user } = resp;

      await this.doSendEmailVerification();
      await this.saveUserData({ ...userData, email });
      await this.setUserRole(null);

      if (user) {
        return this.doGetCurrentUser();
      }
    } catch (err) {
      console.error(err);
    }
  };

  doSignInWithEmailAndPassword = async (email, password, checked = true) => {
    try {
      await this.auth.setPersistence(app.auth.Auth.Persistence[checked ? 'LOCAL' : 'NONE']);

      const resp = await this.auth.signInWithEmailAndPassword(email, password);
      const { user } = resp;

      if (user) {
        return this.doGetCurrentUser();
      }
    } catch (err) {
      console.error(err);
    }
  };

  doSignInWithCustomToken = async (token, tenantId) => {
    await this.auth.setPersistence(app.auth.Auth.Persistence.LOCAL);
    this.auth.tenantId = tenantId;

    const resp = await this.auth.signInWithCustomToken(token);
    const { user } = resp;

    if (user) {
      return this.doGetCurrentUser();
    }
  };

  doSignOut = () => this.auth.signOut();

  doSendEmailVerification = () => this.auth.currentUser.sendEmailVerification();

  doResetPassword = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  saveUserData = async (userData) => {
    try {
      const { firstName, lastName } = userData;

      await this.db.collection(USERS_COLLECTION).doc(this.auth.currentUser.uid).set(userData);
      await this.auth.currentUser.updateProfile({ displayName: `${firstName} ${lastName}` })
    } catch (err) {
      console.error(err);
    }
  };

  doGetCurrentUser = () => new Promise((resolve, reject) => {
    const unsubscribe = this.auth.onAuthStateChanged(user => {
      if (user) {
        this.getCurrentUserData().then(res => resolve({ ...user, ...res }));
      } else {
        resolve(user)
      }
      unsubscribe();
    }, reject);
  }).catch(() => null);

  getCurrentUserData = async () => {
    try {
      const doc = await this.db.collection(USERS_COLLECTION).doc(this.auth.currentUser.uid).get();
      const data = doc.data();

      return data;
    } catch (err) {
      console.error(err);
    }
  };

  getCurrentTenant = async () => {
    try {
      if (this.auth.currentUser) {
        const { tenantId } = this.auth.currentUser;
        const doc = await this.db.collection(TENANTS_COLLECTION).doc(tenantId).get();
        const data = doc.data();

        return data;
      }
    } catch (err) {
      console.error(err);
    }
  };

  getDownloadLink = async (fileId) => {
    try {
      const storageRef = this.storage.ref();
      const url = await storageRef.child(fileId).getDownloadURL();

      return url;
    } catch (err) {
      console.error(err);
    }
  };

  getIdToken = async () => {
    const idToken = await this.auth.currentUser.getIdToken();
    return idToken;
  }

  reauthenticate = async (currentPassword) => {
    const user = this.auth.currentUser;
    const cred = app.auth.EmailAuthProvider.credential(user.email, currentPassword || window.location.href);

    return user.reauthenticateWithCredential(cred);
  }

  doConfirmPasswordReset = async (code, newPassword) => {
    return await this.auth.confirmPasswordReset(code, newPassword);
  };

  getSettingsConfig = async (key) => {
    const doc = await this.db.collection(SETTINGS_COLLECTION).doc(key).get();
    return doc.data();
  };

  logEvent = (eventName, params, options) => {
    this.analytics.logEvent(eventName, params, options);
  };
}

const instance = new Firebase();

export default instance;
