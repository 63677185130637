import React, { useMemo } from 'react';
import { orderBy } from 'lodash';

import BarChart from '../../../charts/BarChart';

import colorPalette, { lighterColorPalette } from '../colorPalette';

const generateColors = (keys, isPrevious) => {
  const colors = {};
  keys?.forEach((k, index) => {
    colors[k] = isPrevious ? lighterColorPalette[index] : colorPalette[index];
  });
  return colors;
};

const SurgeonsStackedBarChart = ({ cases = [], surgeons = [], procedures = [], compareCases = [], presetCompare }) => {
  const data = useMemo(() => {
    const allSurgeons = surgeons
      .map((surgeon) => {
        const surgeonCases = cases.filter((item) => item.surgeon === surgeon.id);
        let previousCases = [];

        if (presetCompare) {
          previousCases = compareCases.filter((item) => item.surgeon === surgeon.id);
        }

        return {
          id: surgeon.id,
          firstName: surgeon.firstName,
          lastName: surgeon.lastName,
          numberOfCases: surgeonCases.length,
          cases: surgeonCases,
          previousCases,
        };
      })
      .filter((item) => item.value !== 0);
    const sorted = orderBy(allSurgeons, 'numberOfCases', 'desc');
    const top5 = sorted.splice(0, 5);

    const keys = [];
    const bars = [];

    top5.forEach((surgeon) => {
      if (presetCompare) {
        const resultCompare = { surgeon: `${surgeon.lastName} (vs)` };
        surgeon.previousCases?.forEach((caseItem) => {
          const procedure = procedures?.find((p) => p.id === caseItem.procedure);
          if (procedure && !keys?.includes(procedure.name)) {
            keys?.push(procedure.name);
          }

          if (resultCompare[procedure.name] !== undefined) {
            resultCompare[procedure.name] = resultCompare[procedure.name] + 1;
          } else {
            resultCompare[procedure.name] = 1;
          }
        });

        bars.push(resultCompare);
      }

      const result = { surgeon: surgeon.lastName };
      surgeon.cases?.forEach((caseItem) => {
        const procedure = procedures?.find((p) => p.id === caseItem.procedure);
        if (procedure && !keys?.includes(procedure.name)) {
          keys?.push(procedure.name);
        }

        if (result[procedure.name] !== undefined) {
          result[procedure.name] = result[procedure.name] + 1;
        } else {
          result[procedure.name] = 1;
        }
      });

      bars.push(result);
    });

    return { keys, data: bars };
  }, [cases, surgeons, procedures, presetCompare, compareCases]);

  const colors = useMemo(() => generateColors(data.keys), [data]);
  const previousColors = useMemo(() => generateColors(data.keys, true), [data]);

  return (
    <div className="management-reports-chart__container m-l-md" style={{ width: '50%' }}>
      <div className="font-size-bg font-bold">Top Surgeons by Procedure</div>
      <div className="secondary m-t-sm m-b-lg">Total cases completed by surgeon with procedure type in period</div>
      <BarChart
        data={data?.data || []}
        height="300px"
        layout="horizontal"
        enableLabel={true}
        enableTotals={true}
        keys={data?.keys || []}
        indexBy="surgeon"
        colors={(bar) => {
          if (bar.indexValue?.includes('vs')) {
            return previousColors[bar.id];
          }
          return colors[bar.id];
        }}
        margin={{ top: 0, right: 150, bottom: 30, left: presetCompare ? 115 : 85 }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          truncateTickAt: 0,
          format: e => e,
        }}
        labelTextColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              1.6,
            ],
          ],
        }}
        legends={[
          {
            data: data.keys?.map((key) => ({ id: key, label: `${key?.substring(0, 15)}${key?.length > 15 ? '...' : ''}`, color: colors[key] })),
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 150,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemTextColor: '#ccc',
            itemDirection: 'right-to-left',
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: 'circle',
            formatter: (v) => {
              console.log(v);
              return 'Test'
            },
            effects: [
              {
                on: 'hover',
                style: { itemTextColor: '#000' },
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export default SurgeonsStackedBarChart;
