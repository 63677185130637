import React, { useState, useCallback } from 'react';

import HistoryIcon from '../../assets/icons/TimeIcon';
import ArrowIconUp from '@material-ui/icons/KeyboardArrowUp';
import ArrowIconDown from '@material-ui/icons/KeyboardArrowDown';
import IconButton from '@material-ui/core/IconButton';

import Table from '../shared/table/Table';

const columns = [
  { title: 'changes', field: 'title', type: 'default', sort: true },
  { title: 'date', field: 'createdAt', type: 'date', sort: true },
  { title: 'user', field: 'user', type: 'default', sort: true },
  { title: 'details', field: 'payload', type: 'default', sort: true },
];

const CaseHistory = ({ activity = [], users = [] }) => {
  const [isShown, toggleHistory] = useState(false);

  const getUserName = useCallback((uid) => {
    const user = users.find((u) => u.uid === uid);
    return user ? `${user.firstName} ${user.lastName}` : '';
  }, [activity, users]);

  return (
    <div className='case-history__container'>
      <div className='d-flex'>
        <HistoryIcon />
        <div className='font-size-bg font-bold m-l-md'>
          History
        </div>

        <IconButton
          className='m-l-md'
          aria-label='more'
          aria-controls='long-menu'
          aria-haspopup='true'
          onClick={() => toggleHistory(!isShown)}
          size='small'
        >
          { isShown ? <ArrowIconUp fontSize='large' /> : <ArrowIconDown fontSize='large' />}
        </IconButton>
      </div>

      {isShown && (
        <div className='m-t-lg'>
          {activity && !!activity.length ? (
            <div>
              <Table
                rows={activity.map((item) => ({
                  ...item,
                  user: getUserName(item.userId),
                }))}
                columns={columns}
                rowColor='#ffffff'
                rowBorderColor='#fafafa'
                checkboxes={false}
              />
            </div>
          ) : (
            <div className='case-history__empty'>No activity yet</div>
          )}
        </div>
      )}
    </div>
  );
};

export default CaseHistory;
