import React from 'react';
import { useHistory } from 'react-router-dom';

import MuiSnackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import NotificationsIcon from '../../assets/icons/NotificationsIcon';

import routes from '../../constants/routes';

export default function Snackbar({ open, onClose, notification, tenantColor }) {
  const history = useHistory();

  const onClick = () => {
    if (notification?.arguments?.caseId) {
      history.push(`${routes.CASES}/${notification.arguments.caseId}`);
      onClose();
    }
  };

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      onClose={onClose}
      autoHideDuration={5000}
    >
      <div
        onClick={onClick}
        style={{
          border: '1px solid #eee',
          borderRadius: 12,
          padding: 16,
          boxShadow: '0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a',
          background: '#ffffff',
          display: 'flex',
          minWidth: 300,
          cursor: notification?.arguments?.caseId ? 'pointer' : 'default'
        }}
      >
        <div className='m-t-sm'>
          <NotificationsIcon fontSize='small' color={tenantColor || 'secondary'} />
        </div>
        <div className='m-l-md flex-1'>
          <div className='d-flex m-b-sm space-between'>
            <div className='font-bold'>{notification?.title}</div>
            <IconButton aria-label='close' onClick={onClose} size='small'>
              <CloseIcon fontSize='small' />
            </IconButton>
          </div>
          <div>{notification?.subtitle}</div>
        </div>
      </div>
    </MuiSnackbar>
  );
}