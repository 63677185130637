import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import moment from 'moment';

import Button from '../shared/Button';
import Input from '../shared/Input';
import FileUpload from '../shared/FileUpload';
import ModalSelectInput from '../shared/modal-select/ModalSelectInput';
import ModalSelect from '../shared/modal-select/ModalSelect';
import RolesModal from '../shared/modal-select/RolesModal';
import GroupsModal from '../shared/modal-select/GroupsModal';
import DatePicker from '../shared/date-picker/DatePicker';
import Checkbox from '../shared/Checkbox';

import { driveItemTypes } from '../../constants/drive';

import '../../styles/shared/form.scss';

const DriveFileForm = (props) => {
  const {
    mode = 'create',
    initialValues,
    buttonText,
    onSubmit,
    loading,
    onClose,
    type,
    products,
    kits,
    roleOptions,
    groups,
  } = props;

  const { title } = initialValues || {};

  const [file, setFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [isProductsModalOpen, toggleProductsModal] = useState(false);
  const [isRolesModalOpen, toggleRolesModal] = useState(false);
  const [isGroupsModalOpen, toggleGroupsModal] = useState(false);
  const [isKitsModalOpen, toggleKitsModal] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = 'Required';
    }
    if (!values.fileName) {
      errors.fileName = 'Required';
    }

    return errors;
  };

  const getProductNames = (productIds) => {
    if (!productIds) {
      return [];
    }

    return productIds.map((id) => {
      const product = products.find((item) => item.id === id);
      return product ? product.name : '';
    });
  };

  const getKitNames = (kitIds) => {
    if (!kitIds) {
      return [];
    }

    return kitIds.map((id) => {
      const kit = kits.find((item) => item.id === id);
      return kit ? kit.kitId : '';
    });
  };

  const getRoleNames = (roles) => {
    if (!roles || roles.length === roleOptions.length) {
      return ['All'];
    }

    return roles.map((value) => {
      const role = roleOptions.find((item) => item.value === value);
      return role ? role.label : '';
    })
  };

  const getGroupNames = (groupIds) => {
    if (!groupIds) {
      return [];
    }
    if (groupIds.length === groups.length) {
      return ['All'];
    }

    return groupIds.map((groupId) => {
      const group = groups.find((item) => item.id === groupId);
      return group ? group.name : '';
    })
  };

  const getAcceptType = (type) => {
    switch (type) {
      case driveItemTypes.document:
        return 'document';
      case driveItemTypes.video:
        return 'video';
      case driveItemTypes.image:
        return 'image';
      default:
        return 'document';
    }
  }

  return (
    <div className='form__container'>
      <Form
        onSubmit={(formData) => onSubmit(formData, type, { file, previewImage })}
        validate={validate}
        initialValues={initialValues}
        subscription={{ pristine: true, invalid: true, values: true }}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine, values }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <div className='form__subtitle'>
                {title || buttonText || 'Add File'}
              </div>
              <Field name='active'>
                {({ input, meta }) => (
                  <Input
                    type='switch'
                    name={input.name}
                    value={input.value}
                    onChange={input.onChange}
                    placeholder='Status'
                  />
                )}
              </Field>

              <div className='field__title'>Display Name</div>
              <div className='combined-fields__container'>
                <Field name='title'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      borderless
                      type='text'
                      onChange={input.onChange}
                      startAdornment='Title'
                    />
                  )}
                </Field>
                <Field name='subtitle'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      borderless
                      type='text'
                      onChange={input.onChange}
                      startAdornment='Sub'
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-lg'>
                <div className='field__title'>Publish Date</div>
                <Field name='publishAt'>
                  {({ input, meta }) => (
                    <DatePicker
                      name={input.name}
                      date={input.value || moment()}
                      onDateChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-lg'>
                <div className='field__title'>Expiry Date</div>
                <Field name='expireAt'>
                  {({ input, meta }) => (
                    <DatePicker
                      name={input.name}
                      date={input.value}
                      onDateChange={input.onChange}
                      disablePast
                    />
                  )}
                </Field>
              </div>

              <div className='field__title'>
                {type === driveItemTypes.link ? 'Link' : 'File'}
              </div>
              <Field name='fileName'>
                {({ input, meta }) => (
                  type === driveItemTypes.link ? (
                    <Input
                      name={input.name}
                      value={input.value}
                      type='text'
                      onChange={input.onChange}
                      placeholder='URL'
                    />
                  ) : (
                    <div className='m-b-lg'>
                      <FileUpload
                        id='drive-file'
                        file={file}
                        onUpload={(item) => {
                          setFile(item);
                          input.onChange(item.name);
                        }}
                        placeholder={input.value}
                        accept={getAcceptType(type)}
                      />
                    </div>
                  )
                )}
              </Field>

              {type !== driveItemTypes.image && (
                <div className='m-b-lg'>
                  <div className='field__title'>Thumbnail Image (16:9 Preferred Ratio)</div>
                  <Field name='previewImageName'>
                    {({ input, meta }) => (
                      <FileUpload
                        id='drive-file-preview'
                        file={previewImage}
                        onUpload={(item) => {
                          setPreviewImage(item);
                          input.onChange(item.name);
                        }}
                        placeholder={input.value}
                        accept='image'
                      />
                    )}
                  </Field>
                </div>
              )}

              {mode === 'update' && (
                <>
                  <div className='m-b-lg'>
                    <div className='field__title'>Accessibility</div>
                    <Field name='allowSharing'>
                      {({ input, meta }) => (
                        <Input
                          type='switch'
                          name={input.name}
                          value={input.value}
                          onChange={input.onChange}
                          placeholder='Allow Mobile Sharing'
                        />
                      )}
                    </Field>

                    {type === driveItemTypes.link && (
                      <Field name='openInBrowser'>
                        {({ input, meta }) => (
                          <Input
                            type='switch'
                            name={input.name}
                            value={input.value}
                            onChange={input.onChange}
                            placeholder='Open In Mobile Browser'
                          />
                        )}
                      </Field>
                    )}

                    <Field name='allowedRoles'>
                      {({ input, meta }) => (
                        <>
                          <ModalSelectInput
                            onClick={() => toggleRolesModal(true)}
                            count={input.value.length}
                            values={getRoleNames(input.value)}
                            label='Roles'
                            icon='users'
                          />

                          <RolesModal
                            onChange={input.onChange}
                            selected={input.value}
                            onClose={() => toggleRolesModal(false)}
                            isOpen={isRolesModalOpen}
                            roles={roleOptions}
                          />
                        </>
                      )}
                    </Field>

                    <Field name='allowedGroups'>
                      {({ input, meta }) => (
                        <>
                          <div className='m-t-lg'>
                            <ModalSelectInput
                              onClick={() => toggleGroupsModal(true)}
                              count={input.value.length}
                              values={getGroupNames(input.value)}
                              label='Groups'
                              icon='users'
                            />
                          </div>

                          <GroupsModal
                            onChange={input.onChange}
                            selected={input.value}
                            onClose={() => toggleGroupsModal(false)}
                            isOpen={isGroupsModalOpen}
                            groups={groups}
                          />
                        </>
                      )}
                    </Field>
                  </div>

                  {type === driveItemTypes.document && (
                    <div className=''>
                      <div className='field__title'>Dispatch Document</div>

                      <Field name='dispatchDocument'>
                        {({ input, meta }) => (
                          <Input
                            type='switch'
                            name={input.name}
                            value={input.value}
                            onChange={input.onChange}
                            placeholder='View During Dispatch'
                          />
                        )}
                      </Field>
                    </div>
                  )}

                  <div className='m-b-lg'>
                    <div className='field__title'>Products & Kits</div>

                    <Field name='allProducts' type='checkbox'>
                      {({ input }) => (
                        <div className='m-b-md'>
                          <Checkbox
                            input={input}
                            fontSize='small'
                            label='All brands'
                          />
                        </div>
                      )}
                    </Field>

                    {!values?.allProducts && (
                      <Field name='relatedProducts'>
                        {({ input, meta }) => (
                          <div className='m-b-lg'>
                            <ModalSelectInput
                              onClick={() => toggleProductsModal(true)}
                              count={input.value.length}
                              label='Add Brands'
                              values={getProductNames(input.value)}
                              icon='product'
                            />

                            <ModalSelect
                              title='Add Brands'
                              listTitle='Brands'
                              options={products}
                              onChange={input.onChange}
                              selected={input.value}
                              onClose={() => toggleProductsModal(false)}
                              isOpen={isProductsModalOpen}
                            />
                          </div>
                        )}
                      </Field>
                    )}

                    <Field name='relatedKits'>
                      {({ input, meta }) => (
                        <div className='m-b-lg'>
                          <ModalSelectInput
                            onClick={() => toggleKitsModal(true)}
                            count={input.value.length}
                            label='Add Kit ID'
                            values={getKitNames(input.value)}
                            icon='kit'
                          />

                          <ModalSelect
                            title='Add Kit ID'
                            listTitle='Kits'
                            options={kits}
                            onChange={input.onChange}
                            selected={input.value}
                            onClose={() => toggleKitsModal(false)}
                            isOpen={isKitsModalOpen}
                            field='kitId'
                            extraField='name'
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                </>
              )}

              <div className='form__actions'>
                <Button
                  type='submit'
                  text={buttonText || 'Save Changes'}
                  disabled={invalid || loading || pristine}
                  loading={loading}
                  onClick={handleSubmit}
                />
                {mode === 'create' && (
                  <Button
                    type='cancel'
                    onClick={onClose}
                  />
                )}
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default DriveFileForm;
