import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function BackupIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path id="Subtract" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M8.139857142857144 7.327357142857143c-0.8979285714285714 0.09657142857142857 -1.818142857142857 0.17642857142857143 -2.7550714285714286 0.17642857142857143 -0.936 0 -1.8571428571428572 -0.07985714285714285 -2.7541428571428574 -0.17642857142857143a3.3270714285714287 3.3270714285714287 0 0 1 -1.6862857142857144 -0.6667142857142857"
    ></path>
    <path id="Vector" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="m7.484285714285715 10.376785714285715 1.6881428571428572 1.7410714285714286c0.7948571428571428 -2.2824285714285715 1.4560000000000002 -3.284357142857143 2.9547142857142856 -4.642857142857143"
    ></path>
    <path id="Intersect" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M9.866999999999999 5.774785714285715c0.0037142857142857147 -0.1522857142857143 0.005571428571428572 -0.30642857142857144 0.005571428571428572 -0.45964285714285713 0 -0.7660714285714285 -0.044571428571428574 -1.5219285714285715 -0.10492857142857144 -2.262m-4.832285714285714 7.620785714285716a53.145857142857146 53.145857142857146 0 0 1 -1.418857142857143 -0.15042857142857144 2.858142857142857 2.858142857142857 0 0 1 -2.5071428571428576 -2.5767857142857142A30.13214285714286 30.13214285714286 0 0 1 0.8728571428571429 5.315142857142858c0 -0.7224285714285715 0.039928571428571424 -1.625 0.09471428571428571 -2.3706428571428573"
    ></path>
    <path id="Vector_2" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M5.371785714285714 4.786785714285715c2.822857142857143 0 4.409785714285714 -0.7029285714285715 4.409785714285714 -1.9518571428571427 0 -1.249857142857143 -1.5878571428571429 -1.9527857142857146 -4.409785714285714 -1.9527857142857146 -2.821928571428572 0 -4.408857142857143 0.7029285714285715 -4.408857142857143 1.9527857142857146 0 1.2489285714285714 1.5878571428571429 1.9518571428571427 4.408857142857143 1.9518571428571427Z"
    ></path>
  </SvgIcon>
}
