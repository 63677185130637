const timeZones = [
  { label: '(UTC-12:00) International Date Line West', value: '-1200' },
  { label: '(UTC-11:00) Coordinated Universal Time-11', value: '-1100' },
  { label: '(UTC-10:00) Hawaii', value: '-1000' },
  { label: '(UTC-09:00) Alaska', value: '-0900' },
  { label: '(UTC-08:00) Pacific Time', value: '-0800' },
  { label: '(UTC-07:00) Mountain Time, Chihuahua, La Paz, Mazatlan', value: '-0700' },
  { label: '(UTC-06:00) Central Time, Guadalajara, Mexico City, Monterey', value: '-0600' },
  { label: '(UTC-05:00) Eastern Time, Bogota, Lima, Quito', value: '-0500' },
  { label: '(UTC-04:30) Caracas', value: '-0430' },
  { label: '(UTC-04:00) Atlantic Time, Asuncion, Cuiaba, Santiago', value: '-0400' },
  { label: '(UTC-03:30) Newfoundland', value: '-0330' },
  { label: '(UTC-03:00) Brasilia, Greenland, Buenos Aires, Montevideo', value: '-0300' },
  { label: '(UTC-02:00) Coordinated Universal Time-2', value: '-0200' },
  { label: '(UTC-01:00) Cape Verde, Azores', value: '-0100' },
  { label: '(UTC+00:00) Casablanca, Reykjavik, Lisbon, London', value: '-0000' },
  { label: '(UTC+01:00) Amsterdam, Berlin, Brussels, Rome, Stockholm, Vienna', value: '+0100' },
  { label: '(UTC+02:00) Athens, Istanbul, Kyiv, Jerusalem, Cairo', value: '+0200' },
  { label: '(UTC+03:00) Baghdad, Baghdad, Kuwait', value: '+0300' },
  { label: '(UTC+03:30) Tehran', value: '+0330' },
  { label: '(UTC+04:00) Tbilisi, Yerevan, Abu Dhabi, Baku', value: '+0400' },
  { label: '(UTC+04:30) Kabul', value: '+0430' },
  { label: '(UTC+05:00) Tashkent, Islamabad, Karachi', value: '+0500' },
  { label: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi', value: '+0530' },
  { label: '(UTC+05:45) Kathmandu', value: '+0545' },
  { label: '(UTC+06:00) Astana, Dhaka', value: '+0600' },
  { label: '(UTC+06:30) Yangon', value: '+0630' },
  { label: '(UTC+07:00) Bangkok, Hanoi, Jakarta', value: '+0700' },
  { label: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi', value: '+0800' },
  { label: '(UTC+09:00) Seoul, Osaka, Tokyo', value: '+0900' },
  { label: '(UTC+09:30) Darwin, Adelaide', value: '+0930' },
  { label: '(UTC+10:00) Canberra, Sydney, Melbourne, Hobart, Guam', value: '+1000' },
  { label: '(UTC+11:00) Solomon Islands, New Caledonia', value: '+1100' },
  { label: '(UTC+12:00) Coordinated Universal Time+12, Auckland, Wellington', value: '+1200' },
  { label: '(UTC+13:00) Nuku\'alofa, Samoa', value: '+1300' },
];

export default timeZones;
