import React from 'react';

import MaterialCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { withStyles } from '@material-ui/core/styles';

const StyledCheckbox = withStyles(theme => ({
  root: {
    color: '#A7A9BD'
  },
}))(MaterialCheckbox);

const Checkbox = (props) => {
  const {
    input,
    color = 'primary',
    fontSize,
    label,
    disabled,
    fontWeight = 'bold',
  } = props;

  return (
    <FormControlLabel
      style={{ marginRight: label ? 16 : 0 }}
      control={
        <StyledCheckbox
          { ...input }
          color={color}
          icon={<CheckBoxOutlineBlankIcon fontSize={fontSize} />}
          checkedIcon={<CheckBoxIcon fontSize={fontSize} />}
          disabled={disabled}
        />
      }
      label={
        <span className='checkbox-label' style={{ fontWeight: fontWeight === 'regular' ? 'regular' : 'bold' }}>
          {label}
        </span>
      }
    />
  );
};

export default Checkbox;
