import React, { useState, useMemo, useEffect } from 'react';

import Modal from '../shared/modal';
import Button from '../shared/Button';
import Input from '../shared/Input';
import Select from '../shared/Select';
import Checkbox from '../shared/Checkbox';

import { setPositionOptions } from '../../constants/enums';

const periodOptions = [
  { label: '6', value: 6 },
  { label: '12', value: 12 },
  { label: '18', value: 18 },
  { label: '24', value: 24 },
];

const ConsignmentModal = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    loading,
    hospitals = [],
    isConsigned,
    initialValues,
  } = props;

  const [search, setSearch] = useState(initialValues?.hospital ? hospitals?.find((h) => h.id === initialValues?.hospital)?.name : '');
  const [note, setNote] = useState(initialValues?.note || '');
  const [selected, setSelected] = useState(hospitals?.find((h) => h.id === initialValues?.hospital) || null);
  const [location, setLocation] = useState(initialValues?.location || null);
  const [period, setPeriod] = useState(initialValues?.period || null);

  useEffect(() => {
    if (open && initialValues && (initialValues.hospital || initialValues.period)) {
      setSearch(initialValues?.hospital ? hospitals?.find((h) => h.id === initialValues?.hospital)?.name : '');
      setNote(initialValues?.note || '');
      setSelected(hospitals?.find((h) => h.id === initialValues?.hospital) || null);
      setLocation(initialValues?.location || null);
      setPeriod(initialValues?.period || null);
    }
    if (!open) {
      setSearch('');
      setNote('');
      setSelected('');
      setLocation('');
      setPeriod('');
    }
  }, [open, initialValues]);

  const handleCheck = (itemId) => {
    if (selected && selected.id === itemId) {
      setSelected(null);
    } else {
      setSelected(itemId);
      setLocation(null);
    }
  };

  const handleSubmit = () => {
    onSubmit(selected, location, period, note);
    setSelected(null);
    setLocation(null);
    setPeriod(null);
    setNote('');
  };

  const list = useMemo(() => hospitals?.filter((hospital) => (
    !search || hospital?.name?.toLowerCase()?.includes(search?.toLowerCase())
  )), [hospitals, search]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Consign Set'
      fullWidth
    >
      <div className='quarantine-modal__container'>
        <div className='d-flex align-start'>
          <div className='m-r-md flex-1'>
            <Input
              type='search'
              placeholder='Search'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className='m-b-lg'>
          <div className='secondary m-b-sm'>Select hospital where set is consigned</div>
          <div className='quarantine-items__container'>
            {list?.map((hospital) => (
              <div key={hospital.id} className='d-flex'>
                <div style={{ minWidth: 200 }}>
                  <Checkbox
                    fontSize='small'
                    input={{
                      checked: selected?.id === hospital.id,
                      onClick: () => handleCheck(hospital)
                    }}
                    label={hospital.name}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className='m-t-md'>
          <div className='secondary m-b-md'>Sub-location</div>
          <Select
            value={location}
            onChange={setLocation}
            options={selected && selected.locations ? selected.locations?.map((loc) => ({ label: loc, value: loc })) : []}
          />
        </div>

        <div className='m-t-lg'>
          <div className='secondary m-b-md'>Review Period (months)</div>
          <Select
            value={period}
            onChange={setPeriod}
            options={periodOptions}
          />
        </div>

        <div className='m-t-lg'>
          <div className='secondary m-b-md'>Consignment note</div>
          <Input
            value={note}
            onChange={(e) => setNote(e.target.value)}
            type='textarea'
          />
        </div>


        <div className='form__actions p-t-lg'>
          <Button
            type='submit'
            text={isConsigned ? 'Save Changes' : 'Consign'}
            onClick={handleSubmit}
            disabled={loading || !selected || !period}
            loading={loading}
            background={setPositionOptions.CONSIGNED.color}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConsignmentModal;
