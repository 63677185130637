import React from 'react';

import Modal from './Modal';
import Button from '../Button';

import './modal.scss';

const ConfirmationModal = (props) => {
  const { open, title, text, onSubmit, onClose, submitText, loading } = props;

  return (
    <Modal open={open} onClose={onClose}>
      <div className='confirmation-modal__container'>
        <div>
          <div className='modal-title'>
            { title }
          </div>

          { text && (
            <div className='modal-content'>
              { text }
            </div>
          )}

          <div className='form__actions'>
            <Button
              type='submit'
              text={submitText || 'Submit'}
              onClick={onSubmit}
              disabled={loading}
              loading={loading}
            />
            <Button
              type='cancel'
              text='Cancel'
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
