import { orderBy } from 'lodash';
import moment from 'moment';

import { GET_FORMS, SET_FORMS, SET_NEW_FORMS_NUMBER } from './actionTypes';

import firebase, { collections, storageRefs } from '../firebase';

import { formStatuses } from '../constants/enums';
import userRoles from '../constants/userRoles';

export const setForms = forms => ({ type: SET_FORMS, forms });

export const getForms = (tenant) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = tenant || state?.tenant?.currentTenant?.id;
  const path = collections.FORMS_COLLECTION(tenantId);

  dispatch({ type: GET_FORMS });

  const snapshot = await firebase.db.collection(path).get();
  const forms = snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));

  if (tenant) {
    return forms;
  }

  dispatch(setForms(forms));
  return forms;
};

export const getFormById = (formId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state?.tenant?.currentTenant?.id;
  const path = collections.FORMS_COLLECTION(tenantId);

  const doc = await firebase.db.collection(path).doc(formId).get();
  const steps = await dispatch(getSteps(formId));

  const form = {
    ...doc?.data(),
    id: doc.id,
    steps: orderBy(steps, 'stepNumber', 'asc')
  };

  return form;
};

export const createForm = (formData, logo) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.FORMS_COLLECTION(tenantId);
  const storageRef = firebase.storage.ref();

  const doc = {
    ...formData,
    active: true,
  };

  if (tenantId) {
    const snap = await firebase.db.collection(path).add(doc);
    //
    // if (snap?.id && logo) {
    //   const storagePath = storageRefs.FORM_LOGO_REF(tenantId, snap?.id);
    //   const filePath = `${storagePath}/${logo.name}`;
    //   const ref = storageRef.child(filePath);
    //   const snap = await ref.put(logo);
    //   const downloadUrl = await snap?.ref?.getDownloadURL();
    //   const logoObj = {
    //     fileName: logo.name,
    //     path: filePath,
    //     downloadUrl
    //   };
    //
    //   await firebase.db.collection(path).doc(snap.id).set({ logo: logoObj }, { merge: true });
    // }
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateForm = (formId, formData, logo) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.FORMS_COLLECTION(tenantId);
  const storageRef = firebase.storage.ref();

  if (tenantId) {
    await firebase.db.collection(path).doc(formId).set(formData, { merge: true });

    if (logo) {
      const storagePath = storageRefs.FORM_LOGO_REF(tenantId, formId);
      const filePath = `${storagePath}/${logo.name}`;
      const ref = storageRef.child(filePath);
      const snap = await ref.put(logo);
      const downloadUrl = await snap?.ref?.getDownloadURL();
      const logoObj = {
        fileName: logo.name,
        path: filePath,
        downloadUrl
      };

      await firebase.db.collection(path).doc(formId).set({ logo: logoObj }, { merge: true });
    }
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteForm = (formId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.FORMS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(formId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const bulkDeleteForms = (forms) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.FORMS_COLLECTION(tenantId);
  const batch = firebase.db.batch();

  if (tenantId) {
    forms.forEach((formId) => {
      const ref = firebase.db.collection(path).doc(formId);
      batch.delete(ref);
    });

    await batch.commit();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const uploadForms = (forms) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.FORMS_COLLECTION(tenantId);

  if (tenantId) {
    const promises = [];

    forms.forEach((form) => {
      const name = form[0] || '';
      const formId = form[1] || '';
      const type = form[2] ? form[2].toUpperCase() : '';

      const doc = {
        name,
        formId,
        type,
        allowQuantity: false,
        active: true,
        products: [],
      };

      promises.push(firebase.db.collection(path).add(doc));
    });

    await Promise.all(promises);
  } else {
    throw new Error('Invalid tenant');
  }
};

// Form steps

export const getSteps = (formId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.FORM_STEPS_COLLECTION(tenantId, formId);

  let query = await firebase.db.collection(path);
  const snapshot = await query.get();
  const steps = snapshot.docs.map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
    };
  });

  return steps;
};

export const createStep = (formId, stepData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.FORM_STEPS_COLLECTION(tenantId, formId);

  const doc = {
    ...stepData,
    active: true,
  };

  if (tenantId) {
    await firebase.db.collection(path).add(doc);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateStep = (formId, stepId, stepData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.FORM_STEPS_COLLECTION(tenantId, formId);

  if (tenantId) {
    await firebase.db.collection(path).doc(stepId).set(stepData, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteStep = (formId, stepId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.FORM_STEPS_COLLECTION(tenantId, formId);

  if (tenantId) {
    await firebase.db.collection(path).doc(stepId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const reorderSteps = (formId, steps) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.FORM_STEPS_COLLECTION(tenantId, formId);

  if (tenantId) {
    await Promise.all(steps.map((step, index) => (
      firebase.db.collection(path).doc(step.id).set({ stepNumber: index + 1 }, { merge: true })
    )));
  } else {
    throw new Error('Invalid tenant');
  }
}

export const fetchNewForms = () => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const user = state.user?.currentUser;
  const path = collections.CUSTOMER_FORMS_COLLECTION(tenantId);

  const forms = await dispatch(getForms());

  if (tenantId) {
    const date = moment().subtract(48, 'hours');
    const jsDate = new Date(date);
    const firebaseDate = firebase.timestamp.fromDate(jsDate);

    const query = firebase.db.collection(path)
      .where('submittedAt', '>', firebaseDate)
      .where('status', 'in', [formStatuses.SUBMITTED, formStatuses.CLOSED]);
    const res = await query.get();
    const docs = res.docs?.map((doc) => doc.data());
    const number = docs?.filter((doc) => {
      const form = forms?.find((f) => f.id === doc.formId);
      return form && (form.roles?.includes(user.role) || user.role === userRoles.ADMIN.name);
    })?.length;

    return dispatch({ type: SET_NEW_FORMS_NUMBER, number });
  } else {
    throw new Error('Invalid tenant');
  }
};

// Subscriptions
export const subscribeToForms = () => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.FORMS_COLLECTION(tenantId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let forms = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;

          forms = [...forms, {
            ...documentSnapshot.data(),
            id,
          }];
        });

        return dispatch(setForms(forms));
      },
    });
};
