import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function GetAppIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M1.75769 8.797C1.74254 8.79591 1.72739 8.79482 1.71223 8.79372C1.23054 8.75868 0.848125 8.37636 0.812971 7.89469C0.663255 5.84332 0.663255 3.91341 0.812971 1.86204C0.848125 1.38037 1.2307 0.998035 1.71238 0.963003C3.77561 0.812951 5.70594 0.812951 7.76917 0.963004C8.25085 0.998035 8.63341 1.38024 8.66857 1.86191C8.7218 2.59128 8.75611 3.30528 8.77149 4.01486" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M5.72677 12.8824C5.10995 13.1554 4.42266 13.1645 3.89617 12.7544C3.64211 12.5565 3.41746 12.3319 3.21956 12.0778C2.6277 11.318 2.90902 10.2232 3.59662 9.48873C4.25392 8.78659 4.75888 8.28163 5.46102 7.62433C6.19551 6.93673 7.29027 6.65541 8.0501 7.24727C8.30417 7.44517 8.52881 7.66982 8.72671 7.92388C8.97172 8.23843 9.0671 8.61036 9.04596 8.99038" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M10.0826 5.84889C10.7015 5.56814 11.3872 5.54966 11.9067 5.95152C12.1574 6.14544 12.3784 6.36644 12.5724 6.61715C13.1523 7.36695 12.8568 8.46274 12.1609 9.20473C11.4957 9.91404 10.9851 10.4246 10.2758 11.0898C9.53385 11.7857 8.43806 12.0812 7.68826 11.5013C7.43755 11.3073 7.21655 11.0863 7.02262 10.8356C6.78359 10.5266 6.69328 10.1588 6.71842 9.78122" />
  </SvgIcon>
}
