import React from 'react';

import DeleteIcon from '../../assets/icons/DeleteIcon';
import FileCopyIcon from '../../assets/icons/CopyIcon';
import UploadIcon from '../../assets/icons/UploadIcon';
import TagIcon from '../../assets/icons/TagIcon';

import Button from '../shared/Button';
import Select from '../shared/Select';

const TerritoriesFilters = (props) => {
  const {
    onAddTerritoryClick,
    status,
    setStatus,
    statuses,
    onDelete,
    onUploadClick,
    onDuplicateClick,
    deletePermission,
    selected = [],
    onTagClick,
  } = props;

  return (
    <div className='settings-cmp__filters--main'>
      <div className='settings-cmp__actions'>
        <Button
          text='Add Territory'
          type='submit'
          onClick={onAddTerritoryClick}
        />

        <Button
          type='submit'
          width='60px'
          onClick={onUploadClick}
        >
          <UploadIcon color="#ffffff" />
        </Button>

        <Button
          type='submit'
          width='60px'
          disabled={!selected?.length}
          onClick={onTagClick}
        >
          <TagIcon color={!selected?.length ? "#9e9e9e" : "#ffffff"}/>
        </Button>

        <Button
          type='icon'
          onClick={onDelete}
          disabled={!deletePermission}
        >
          <DeleteIcon style={{ color: '#000000' }} />
        </Button>

        <Button
          type='icon'
          onClick={onDuplicateClick}
        >
          <FileCopyIcon style={{ color: '#000000' }}/>
        </Button>
      </div>

      <div className='settings-cmp__filters'>

        <Select
          value={status}
          onChange={setStatus}
          options={statuses}
          defaultLabel={`All Status (${statuses.length})`}
          width={200}
        />
      </div>
    </div>
  )
};

export default TerritoriesFilters;
