import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function WarningIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinejoin="round"
          d="M1.255 10.4795L5.20393 2.41708C5.93553 0.923392 8.06458 0.923393 8.79618 2.41708L12.7451 10.4795C12.918 10.8325 13.0558 11.2289 12.7035 11.5097C11.0469 12.8301 2.95322 12.8301 1.29661 11.5097C0.9438 11.2285 1.08026 10.8362 1.255 10.4795Z"/>
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M7 5V7.5"/>
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M7 9.75L7 10"/>
  </SvgIcon>
}
