import React, { useState } from 'react';

import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import EditIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Check';
import Checkbox from '../../shared/Checkbox';

import BatchControlIcon from '../../../assets/icons/BatchControlIcon';

const SectionItemRow = (props) => {
  const { item, provided, isDragging, onSave, onCheck, isChecked } = props;

  const [editMode, toggleEditMode] = useState(false);
  const [ref, setRef] = useState(item.ref || '');
  const [quantity, setQuantity] = useState(item.quantity || '');

  const onClick = () => {
    if (editMode) {
      onSave(ref, quantity);
    }

    toggleEditMode(!editMode);
  };

  return (
    <tr
      {...provided.draggableProps}
      ref={provided.innerRef}
      className={isDragging ? 'dragging-row' : ''}
    >
      <td>
        <Checkbox
          input={{
            onClick: onCheck,
            checked: isChecked
          }}
          fontSize='small'
        />
      </td>
      <td>{item.code}</td>
      <td>{item.checklistDescription || item.description}</td>
      <td>{item.type}</td>
      <td>
        {editMode ? (
          <input type='text' value={ref} onChange={(e) => setRef(e.target?.value)} />
        ) : (
          item.ref
        )}
      </td>
      <td>
        {editMode ? (
          <input type='text' value={quantity} onChange={(e) => setQuantity(e.target?.value)} />
        ) : (
          item.quantity
        )}
      </td>
      <td style={{ width: 50 }}>
        {item.batchControl ? <BatchControlIcon color='secondary' /> : null}
      </td>
      <td style={{ width: 50 }}>
        <div onClick={onClick}>
          {editMode ? <SaveIcon color='primary' /> : <EditIcon color='primary' />}
        </div>
      </td>
      <td {...provided.dragHandleProps} style={{ width: 24 }}>
        <DragIndicatorIcon />
      </td>
    </tr>
  );
};

export default SectionItemRow;
