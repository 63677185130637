import React from 'react';

import ArrowIcon from '@material-ui/icons/KeyboardArrowDown';

import './reporting.scss';

const ModalSelectInput = ({ onClick, values = [] }) => {
  return (
    <div
      className='report-modal-select'
      onClick={onClick}
    >
      <div className='modal-select-list'>
        {values?.length ? values?.join(', ') : 'All'}
      </div>
      <div className='modal-select-arrow'>
        <div className='d-flex'>
          <span style={{ color: '#999999', marginRight: '2px' }}>Select</span>
          <ArrowIcon style={{ fill: '#c6c8c9' }} />
        </div>
      </div>
    </div>
  );
};

export default ModalSelectInput;
