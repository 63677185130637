import React from 'react';

import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import './table.scss';

const StyledIconButton = withStyles((theme) => ({
  root: {
    minWidth: '36px',
    width: '36px',
    height: '30px',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.primary.grey}`,
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 'bold',
    background: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}))(MuiButton);

const TableButton = (props) => {
  const {
    onClick = () => {},
    color,
    children,
  } = props;

  return (
    <StyledIconButton
      className='table-button'
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      style={{ backgroundColor: color || '#ffffff' }}
    >
      {children}
    </StyledIconButton>
  )
};

export default TableButton;
