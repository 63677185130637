import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function TagIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M6.79455 12.621C6.07943 13.2688 4.99105 13.2888 4.22921 12.6966C3.69319 12.28 3.15412 11.8417 2.65613 11.3437C2.15814 10.8457 1.71987 10.3067 1.30324 9.77065C0.711083 9.00881 0.731083 7.92043 1.37889 7.20531C2.76454 5.67566 4.20864 4.26158 5.78021 2.89404C6.07196 2.64016 6.43204 2.48003 6.81723 2.44535C7.95247 2.34315 10.3959 2.21428 11.0907 2.90911C11.7856 3.60394 11.6567 6.04739 11.5545 7.18263C11.5198 7.56782 11.3597 7.9279 11.1058 8.21965C9.73828 9.79122 8.32419 11.2353 6.79455 12.621Z"/>
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M13.125 0.875L9.14752 4.85248"/>
  </SvgIcon>
}

