import { GET_USERS, SET_USERS, SET_CONNECTED_USERS } from '../actions/actionTypes';

const initialState = {
  list: [],
  connectedUsers: [],
  isLoading: false,
  isLoaded: false
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS:
      return { ...state, isLoading: true };
    case SET_USERS:
      return {
        ...state,
        list: action.users,
        isLoaded: true,
        isLoading: false
      }
    case SET_CONNECTED_USERS:
      return {
        ...state,
        connectedUsers: action.users,
      }
    default:
      return state;
  }
};

export default usersReducer;
