import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function ShipIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M2.75011 12.5132H2.42127C1.73297 12.5132 1.13682 12.045 1.0334 11.3646C0.809404 9.89067 0.577924 7.20193 1.25011 4.5132C1.25011 4.5132 2.25011 2.0132 4.25011 1.0132L9.75011 1.01318C11.7501 2.01318 12.7501 4.51318 12.7501 4.51318C13.4223 7.20192 13.1908 9.89066 12.9668 11.3645C12.8634 12.045 12.2672 12.5132 11.5789 12.5132H11.2501" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M1.25 4.51318H12.75" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M7 1.01318L7 4.51318" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M7 7.45054L7 13.2279" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M4.94751 11.4467C5.57121 12.2434 5.99601 12.6142 6.62909 13.0992C6.8531 13.2708 7.14697 13.2708 7.37098 13.0992C8.00406 12.6142 8.42886 12.2434 9.05256 11.4467" />
  </SvgIcon>
}
