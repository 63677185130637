import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import UserIcon from '../../../assets/icons/UserIcon';
import GroupsIcon from '../../../assets/icons/GroupsIcon';
import CompanyIcon from '../../../assets/icons/CompanyIcon';
import SurgeonIcon from '../../../assets/icons/SurgeonIcon';
import ProcedureIcon from '../../../assets/icons/ProcedureIcon';
import ProductIcon from '../../../assets/icons/ProductIcon';
import KitIcon from '../../../assets/icons/KitIcon';
import DriveIcon from '../../../assets/icons/DriveIcon';
import NotificationsIcon from '../../../assets/icons/NotificationsIcon';
import SetIcon from '../../../assets/icons/SetIcon';
import ScanIcon from '../../../assets/icons/ScanIcon';
import ItemIcon from '../../../assets/icons/ItemIcon';
import FlowIcon from '../../../assets/icons/FlowIcon';
import FormIcon from '../../../assets/icons/FormIcon';
import LocationIcon from '../../../assets/icons/LocationIcon';
import KitPreferenceIcon from '../../../assets/icons/KitPreferenceIcon';

import RestrictComponent from '../../../components/shared/RestrictComponent';

import { routes } from '../../../constants';
import { roleNames } from '../../../constants/userRoles';

import './settings-page.scss';

const { ADMIN, MARKETING } = roleNames;

const settingsOptions = [
  {
    title: 'Users',
    subTitle: 'Add or manage users',
    icon: <UserIcon className='option-icn' />,
    url: routes.USERS,
    roles: [],
  },
  {
    title: 'Groups',
    subTitle: 'Create and manage groups',
    icon: <GroupsIcon className='option-icn' />,
    url: routes.GROUPS,
    roles: [],
  },
  {
    title: 'Company',
    subTitle: 'Edit company info & Settings',
    icon: <CompanyIcon className='option-icn' />,
    url: routes.COMPANY,
    roles: [],
  },
  {
    title: 'Hospitals/Locations',
    subTitle: 'Add or manage hospitals & locations',
    icon: <LocationIcon className='option-icn' />,
    url: routes.LOCATIONS,
    roles: [],
  },
  {
    title: 'Surgeons',
    subTitle: 'Add or manage surgeons',
    icon: <SurgeonIcon className='option-icn' />,
    url: routes.SURGEONS,
    roles: [],
  },
  {
    title: 'Procedures',
    subTitle: 'Add or manage procedures',
    icon: <ProcedureIcon className='option-icn' />,
    url: routes.PROCEDURES,
    roles: [],
  },
  {
    title: 'Brands',
    subTitle: 'Add or manage brands',
    icon: <ProductIcon className='option-icn' />,
    url: routes.PRODUCTS,
    roles: [],
  },
  {
    title: 'Kits',
    subTitle: 'Add or manage kits',
    icon: <KitIcon className='option-icn' />,
    url: routes.KITS_SETTINGS,
    roles: [],
  },
  {
    title: 'Sets',
    subTitle: 'Add or manage set allocation',
    icon: <SetIcon className='option-icn' />,
    url: routes.SETS_SETTINGS,
    roles: [],
  },
  {
    title: 'Items',
    subTitle: 'Manage kit items',
    icon: <ItemIcon className='option-icn' />,
    url: routes.ITEMS,
    roles: []
  },
  {
    title: 'Kit Preferences',
    subTitle: 'Manage kit preferences',
    icon: <KitPreferenceIcon className='option-icn' />,
    url: routes.KIT_PREFERENCES,
    roles: []
  },
  {
    title: 'Notifications',
    subTitle: 'Manage team notifications',
    icon: <NotificationsIcon className='option-icn' />,
    url: routes.NOTIFICATIONS_SETTINGS,
    roles: [],
  },
  {
    title: 'Drive',
    subTitle: 'Control company documents',
    icon: <DriveIcon className='option-icn' />,
    url: routes.DRIVE_SETTINGS,
    roles: [MARKETING],
  },
  {
    title: 'Scan',
    subTitle: 'Manage scan settings',
    icon: <ScanIcon className='option-icn' />,
    url: routes.SCAN,
    roles: [],
  },
  {
    title: 'Flows',
    subTitle: 'Manage case flows',
    icon: <FlowIcon className='option-icn' />,
    url: routes.FLOWS,
    roles: [],
  },
  {
    title: 'Forms',
    subTitle: 'Manage company forms',
    icon: <FormIcon className='option-icn' />,
    url: routes.FORMS_SETTINGS,
    roles: [],
  },
];

const SettingsPage = ({ currentTenant, userRole }) => {
  return (
    <div className='page-container'>
      <span className='page-title'>Settings</span>

      <div className='settings-body'>
        <Grid container spacing={1}>
          {
            settingsOptions.map((option) => (
              <RestrictComponent key={option.title} roles={[ADMIN, ...option.roles]}>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <Link to={option.url}>
                    <div
                      className='settings-option'
                      key={`${option.title}_${Math.random()}`}
                      style={{ '--colorHover': `${currentTenant.colorPrimary}` }}
                    >
                      <span className='option-title'>{option.title}</span>
                      <span className='option-sub-t'>{option.subTitle}</span>
                      <div className='option-icn-cnt'>
                        <span>{option.icon}</span>
                      </div>
                    </div>
                  </Link>
                </Grid>
              </RestrictComponent>
            ))
          }
        </Grid>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentTenant: state.tenant.currentTenant,
    userRole: state.user.currentUser.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

SettingsPage.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
