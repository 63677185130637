import React from 'react';
import moment from 'moment';

import FolderIcon from '../../assets/icons/FolderIcon';
import TimeIcon from '../../assets/icons/TimeIcon';

const DriveFolder = (props) => {
  const {
    id,
    name,
    createdAt = new Date(),
    onClick,
  } = props;

  return (
    <div className='drive-folder__container' onClick={() => onClick(id)}>
      <div className='drive-folder-icon'>
        <FolderIcon fontSize='large' />
      </div>
      <div className='drive-folder-name__container'>
        <div className='font-size-bg font-bold'>
          {name}
        </div>
        <div className='d-flex secondary font-size-sm'>
          <TimeIcon color="#c6c8c9" />
          <span className='m-l-sm'>{moment(createdAt).fromNow()}</span>
        </div>
      </div>
    </div>
  );
};

export default DriveFolder;
