import React from 'react';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';

import Button from '../../shared/Button';
import Input from '../../shared/Input';
import Checkbox from '../../shared/Checkbox';
import CountrySelect from '../../shared/CountrySelect';

import { routes, countries } from '../../../constants';

const StepOne = ({ step, onStepNext, initialValues }) => {
  const validate = (values) => {
    const errors = {};

    if (!values.businessName) {
      errors.businessName = 'Required';
    }
    if (!values.country) {
      errors.country = 'Required';
    }

    return errors;
  };

  return (
    <div className='step__container'>
      <span className='first-step__title'>Lets get Started</span>
      <div className='step__body'>
        <Form
          onSubmit={(formObj) => onStepNext(step, formObj)}
          initialValues={initialValues}
          validate={validate}
        >
          {
            ({ handleSubmit, submitting , invalid }) => (
              <form
                onSubmit={handleSubmit}
                className='first-step__form'
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    handleSubmit();
                  }
                }}
              >


                <Field name='businessName'>
                  {({ input, meta }) => (
                    <>
                      <Input
                        name={input.name}
                        value={input.value}
                        placeholder='Business Name'
                        type='text'
                        onChange={input.onChange}
                      />
                    </>
                  )}
                </Field>

                <div className='form__employees'>
                  <span className='form__employee-title'>Number of employees, including you</span>

                  <Field name='maxEmployee' type='radio' value='1'>
                    {({ input }) => (
                      <Checkbox
                        input={input}
                        fontSize='small'
                        label='Just you'
                      />
                    )}
                  </Field>

                  <Field name='maxEmployee' type='radio' value='2-9'>
                    {({ input }) => (
                      <Checkbox
                        input={input}
                        fontSize='small'
                        label='2-9'
                      />
                    )}
                  </Field>

                  <Field name='maxEmployee' type='radio' value='10-49'>
                    {({ input }) => (
                      <Checkbox
                        input={input}
                        fontSize='small'
                        label='10-49'
                      />
                    )}
                  </Field>

                  <Field name='maxEmployee' type='radio' value='50-100'>
                    {({ input }) => (
                      <Checkbox
                        input={input}
                        fontSize='small'
                        label='50-100'
                      />
                    )}
                  </Field>

                  <Field name='maxEmployee' type='radio' value='100+'>
                    {({ input }) => (
                      <Checkbox
                        input={input}
                        fontSize='small'
                        label='100+'
                      />
                    )}
                  </Field>
                </div>

                <Field name='country'>
                  {({ input, meta }) => (
                    <>
                      <span className='step_subtitle'>
                        Country {meta.error && meta.touched &&
                        <span className='form__error'>{meta.error}</span>}
                      </span>
                      <CountrySelect
                        countries={countries}
                        initialValues={initialValues}
                        input={input}
                      />
                    </>
                  )}
                </Field>

                <div className='form__next'>
                  <Link to={routes.SIGN_IN} style={{ display: 'flex' }}>
                    <Button
                      type='secondary'
                      text='Back'
                    />
                  </Link>
                  <Button
                    type='submit'
                    text='Next'
                    disabled={submitting || invalid}
                    onClick={handleSubmit}
                  />
                </div>
              </form>
            )}
        </Form>
      </div>
    </div>
  );
};

export default StepOne;
