import React, { useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';

import InfoIcon from '@material-ui/icons/InfoOutlined';

import Modal from '../../shared/modal';
import Button from '../../shared/Button';
import Input from '../../shared/Input';
import Dropzone from '../../shared/dropzone';

import { userRoles } from '../../../constants';

const CompleteStepModal = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    loading,
    title = '',
    step = {},
    tenantColor = '',
    tenantId = '',
    caseId = '',
    stepId = '',
    userRole,
  } = props;

  const { config } = step;

  const documentsPath = useMemo(() => `${tenantId}/cases/${caseId}/flow/${stepId}/documents`, [tenantId, caseId, stepId]);
  const imagesPath = useMemo(() => `${tenantId}/cases/${caseId}/flow/${stepId}/images`, [tenantId, caseId, stepId]);

  const [documents, setDocuments] = useState([]);
  const [images, setImages] = useState([]);

  // Documents

  const onUploadDocuments = (newFiles) => {
    setDocuments([
      ...documents,
      ...newFiles.filter((file) => !documents.map((f) => f.name).includes(file.name))
    ]);
  };

  const onRemoveDocument = (fileName) => {
    setDocuments([...documents].filter((item) => item.name !== fileName));
  };

  const onUploadDocumentsComplete = (fileName, downloadUrl) => {
    const files = [...documents];
    const file = files.find((item) => item.name === fileName);
    if (file) {
      file.downloadUrl = downloadUrl;
      setDocuments(files);
    }
  };

  // Images

  const onUploadImages = (newFiles) => {
    setImages([
      ...images,
      ...newFiles.filter((file) => !images.map((f) => f.name).includes(file.name))
    ]);
  };

  const onRemoveImage = (fileName) => {
    setImages([...images].filter((item) => item.name !== fileName));
  };

  const onUploadImagesComplete = (fileName, downloadUrl) => {
    const files = [...images];
    const file = files.find((item) => item.name === fileName);
    if (file) {
      file.downloadUrl = downloadUrl;
      setImages(files);
    }
  };

  const handleClose = () => {
    setDocuments([]);
    setImages([]);
    onClose();
  };

  const validate = (values) => {
    const errors = {};

    if (config?.documentUploadRequired && (!documents || !documents.length)) {
      errors.documentUpload = 'Required';
    }

    if (config?.imageCaptureRequired && (!images || !images.length)) {
      errors.imageCapture = 'Required';
    }

    if (config?.notesFieldRequired && !values.notes) {
      errors.notes = 'Required';
    }

    if (config?.urlLinkRequired && !values.link) {
      errors.link = 'Required';
    }

    return errors;
  };

  const onSubmitClick = async (formObj) => {
    await onSubmit({ ...formObj, id: stepId }, { documents, images });
    setDocuments([]);
    setImages([]);
    onClose();
  };

  const roleDisabled = useMemo(() => (
    userRole !== userRoles.ADMIN.name && config?.roles && !config?.roles.includes(userRole)
  ), [userRole, config]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={title}
      fullWidth
    >
      <div className='case-sets-modal__container'>
        <Form
          onSubmit={onSubmitClick}
          validate={validate}
          subscription={{ invalid: true }}
        >
          {
            ({ handleSubmit, submitting, invalid, pristine }) => (
              <form onSubmit={handleSubmit} className='form__body'>
                {step && step.description && (
                  <div className='d-flex align-start m-b-lg'>
                    <InfoIcon className='m-r-sm' color='primary' />
                    <div className='secondary'>{step.description}</div>
                  </div>
                )}

                {config?.notesField && (
                  <div className='m-b-sm'>
                    <div className='field__title'>
                      <span>Notes</span>
                      <span style={{ color: tenantColor }} className='m-l-sm'>
                        {!!config?.notesFieldRequired && '*'}
                      </span>
                    </div>
                    <Field name='notes'>
                      {({ input, meta }) => (
                        <Input
                          name={input.name}
                          value={input.value}
                          placeholder='Notes'
                          type='textarea'
                          onChange={input.onChange}
                        />
                      )}
                    </Field>
                  </div>
                )}

                {config?.documentUpload && (
                  <div className='m-b-lg'>
                    <div className='field__title'>
                      <span>Documents</span>
                      <span style={{ color: tenantColor }} className='m-l-sm'>
                        {!!config?.documentUploadRequired && '*'}
                      </span>
                    </div>
                    <Field name='documentUpload'>
                      {({ input, meta }) => (
                        <Dropzone
                          path={documentsPath}
                          files={documents}
                          onUpload={onUploadDocuments}
                          onRemove={onRemoveDocument}
                          onUploadComplete={onUploadDocumentsComplete}
                          tenantColor={tenantColor}
                        />
                      )}
                    </Field>
                  </div>
                )}

                {config?.imageCapture && (
                  <div className='m-b-lg'>
                    <div className='field__title'>
                      <span>Images</span>
                      <span style={{ color: tenantColor }} className='m-l-sm'>
                        {!!config?.imageCaptureRequired && '*'}
                      </span>
                    </div>
                    <Field name='imageCapture'>
                      {({ input, meta }) => (
                        <Dropzone
                          path={imagesPath}
                          files={images}
                          onUpload={onUploadImages}
                          onRemove={onRemoveImage}
                          onUploadComplete={onUploadImagesComplete}
                          tenantColor={tenantColor}
                        />
                      )}
                    </Field>
                  </div>
                )}

                {config?.urlLink && (
                  <div className='m-b-sm'>
                    <div className='field__title'>
                      <span>Link</span>
                      <span style={{ color: tenantColor }} className='m-l-sm'>
                        {!!config?.urlLinkRequired && '*'}
                      </span>
                    </div>
                    <Field name='link'>
                      {({ input, meta }) => (
                        <Input
                          name={input.name}
                          value={input.value}
                          placeholder='Paste URL here'
                          type='text'
                          onChange={input.onChange}
                        />
                      )}
                    </Field>
                  </div>
                )}

                {Object.keys(userRoles).length > config?.roles?.length && (
                  <div className='m-b-lg'>
                    <div className='secondary font-bold'>Approval</div>
                    <div className='secondary font-bold'>This step can only be completed by the following roles</div>
                    <div className='font-bold' style={{ color: tenantColor }}>
                      {config?.roles?.map((role) => userRoles[role]?.label).join(', ')}
                    </div>
                  </div>
                )}

                <div className='form__actions'>
                  <Button
                    type='submit'
                    text='Complete'
                    disabled={invalid || loading || pristine || roleDisabled}
                    loading={loading}
                    onClick={handleSubmit}
                  />

                  <Button
                    type='cancel'
                    text='Close'
                    onClick={handleClose}
                  />
                </div>
              </form>
            )}
        </Form>
      </div>
    </Modal>
  );
};

export default CompleteStepModal;
