import React from 'react';

import Checkbox from '../../shared/Checkbox';
import AddButton from '../../shared/AddButton';

import { caseStatusOptions, setAllocationStatuses } from '../../../constants/enums';

const KitList = (props) => {
  const { kitType, kitsList, status, noKitsChecked, input} = props;
  const value = input.value || [];

  return (
    <div key={kitType.value} className='m-t-sm m-b-sm'>
      <div className='font-bold m-b-sm'>{kitType.label}</div>
      <div>
        {kitsList.filter((kit) => kit.type === kitType.value).map((kit) => {
          const updatedKit = value.find((item) => item.id === kit.id);
          return (
            <div className='md-user-info' key={kit.id}>
              <Checkbox
                disabled={noKitsChecked || (
                  status && (status === caseStatusOptions.completed || status === caseStatusOptions.overdue) && (
                    updatedKit?.status !== setAllocationStatuses.AVAILABLE.value || !updatedKit
                  )
                )}
                input={{
                  ...input,
                  checked: !!value.find((item) => item.id === kit.id),
                  onChange: (e) => {
                    if (e.target.checked) {
                      input.onChange([
                        ...value,
                        { id: kit.id, quantity: updatedKit && updatedKit.quantity ? updatedKit.quantity : 1 }
                      ]);
                    } else {
                      if (updatedKit.status && updatedKit.status !== setAllocationStatuses.AVAILABLE.value) {
                        return;
                      }
                      input.onChange(value.filter((item) => item.id !== kit.id));
                    }
                  }
                }}
                fontSize='small'
                label={kit.name}
                fontWeight='regular'
              />

              {!!value.find((item) => item.id === kit.id) && kit.allowQuantity && (
                <div className='d-flex'>
                  <AddButton
                    width={24}
                    height={24}
                    variant='remove'
                    disabled={updatedKit?.status && updatedKit?.status !== setAllocationStatuses.AVAILABLE.value}
                    onClick={() => {
                      const kits = [...value];
                      const updatedKit = kits.find((item) => item.id === kit.id);
                      if (updatedKit && updatedKit.quantity > 1) {
                        updatedKit.quantity = updatedKit.quantity - 1;
                        input.onChange(kits);
                      }
                    }}
                  />
                  <div
                    style={{ width: 16 }}
                    className='m-l-md m-r-md text-center'
                  >
                    {value?.find((item) => item.id === kit.id)?.quantity || 1}
                  </div>
                  <AddButton
                    width={24}
                    height={24}
                    disabled={updatedKit?.status && updatedKit?.status !== setAllocationStatuses.AVAILABLE.value}
                    onClick={() => {
                      const kits = [...value];
                      const updatedKit = kits.find((item) => item.id === kit.id);
                      updatedKit.quantity = updatedKit && updatedKit.quantity ? updatedKit.quantity + 1 : 2;
                      input.onChange(kits);
                    }}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default KitList;