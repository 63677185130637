import { GET_KIT_PREFERENCES, SET_KIT_PREFERENCES } from './actionTypes';

import firebase, { collections } from '../firebase';
import { sortBy } from 'lodash';

export const setKitPreferences = kitPreferences => ({ type: SET_KIT_PREFERENCES, kitPreferences });

export const getKitPreferences = (tenant, withReducer) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = tenant || state?.tenant?.currentTenant?.id;
  const path = collections.KIT_PREFERENCES_COLLECTION(tenantId);

  dispatch({ type: GET_KIT_PREFERENCES });

  const snapshot = await firebase.db.collection(path).get();
  const kitPreferences = snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));

  if (tenant && !withReducer) {
    return kitPreferences;
  }

  return dispatch(setKitPreferences(kitPreferences));
};

export const createKitPreference = (kitPreferenceData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.KIT_PREFERENCES_COLLECTION(tenantId);

  const doc = {
    ...kitPreferenceData,
    active: true,
  };

  if (tenantId) {
    await firebase.db.collection(path).add(doc);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateKitPreference = (kitPreferenceId, kitPreferenceData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.KIT_PREFERENCES_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(kitPreferenceId).set(kitPreferenceData, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteKitPreference = (kitPreferenceId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.KIT_PREFERENCES_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(kitPreferenceId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const bulkDeleteKitPreferences = (kitPreferences) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.KIT_PREFERENCES_COLLECTION(tenantId);
  const batch = firebase.db.batch();

  if (tenantId) {
    kitPreferences.forEach((kitPreferenceId) => {
      const ref = firebase.db.collection(path).doc(kitPreferenceId);
      batch.delete(ref);
    });

    await batch.commit();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const uploadKitPreferences = (kitPreferences) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.KIT_PREFERENCES_COLLECTION(tenantId);

  if (tenantId) {
    const promises = [];

    kitPreferences.forEach((kitPreference) => {
      const name = kitPreference[0] || '';
      const kitPreferenceId = kitPreference[1] || '';
      const type = kitPreference[2] ? kitPreference[2].toUpperCase() : '';

      const doc = {
        name,
        kitPreferenceId,
        type,
        allowQuantity: false,
        active: true,
        products: [],
      };

      promises.push(firebase.db.collection(path).add(doc));
    });

    await Promise.all(promises);
  } else {
    throw new Error('Invalid tenant');
  }
};

// Kit Preference items

export const getItems = (kitPreferenceId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.KIT_PREFERENCE_ITEMS_COLLECTION(tenantId, kitPreferenceId);

  let query = await firebase.db.collection(path);
  const snapshot = await query.get();
  const items = snapshot.docs.map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
    };
  });

  return items || [];
};

export const createItem = (kitPreferenceId, itemData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.KIT_PREFERENCE_ITEMS_COLLECTION(tenantId, kitPreferenceId);

  const doc = {
    ...itemData,
    quantity: 1,
    active: true,
  };

  if (tenantId) {
    await firebase.db.collection(path).add(doc);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateItem = (kitPreferenceId, stepId, data) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.KIT_PREFERENCE_ITEMS_COLLECTION(tenantId, kitPreferenceId);

  if (tenantId) {
    await firebase.db.collection(path).doc(stepId).set(data, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteItem = (kitPreferenceId, stepId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.KIT_PREFERENCE_ITEMS_COLLECTION(tenantId, kitPreferenceId);

  if (tenantId) {
    await firebase.db.collection(path).doc(stepId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const reorderItems = (kitPreferenceId, steps) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.KIT_PREFERENCE_ITEMS_COLLECTION(tenantId, kitPreferenceId);

  if (tenantId) {
    await Promise.all(steps.map((step, index) => (
      firebase.db.collection(path).doc(step.id).set({ orderNumber: index + 1 }, { merge: true })
    )));
  } else {
    throw new Error('Invalid tenant');
  }
}

export const getKitPreferenceByParameters = ({ surgeonId, procedureId, hospitalId }) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.KIT_PREFERENCES_COLLECTION(tenantId);

  if (tenantId) {
    const snapshot = await firebase.db.collection(path)
      .where('surgeon', '==', surgeonId)
      .where('hospitals', 'array-contains', hospitalId)
      .get();

    const kitPreferences = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

    if (kitPreferences) {
      const kitPreference = kitPreferences?.find((item) => item.procedures?.includes(procedureId) || !!item.allProcedures);
      if (kitPreference) {
        const items = await dispatch(getItems(kitPreference.id));
        return {
          ...kitPreference,
          items: sortBy(items, ['orderNumber'])
        }
      } else {
        return null;
      }
    }

    return null;
  } else {
    throw new Error('Invalid tenant');
  }
};

// Subscriptions
export const subscribeToKitPreferences = () => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.KIT_PREFERENCES_COLLECTION(tenantId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let kitPreferences = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;

          kitPreferences = [...kitPreferences, {
            ...documentSnapshot.data(),
            id,
          }];
        });

        return dispatch(setKitPreferences(kitPreferences));
      },
    });
};
