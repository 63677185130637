import React, { useState } from 'react';

import { UsageImagesForm, UsageImplantsForm } from '../cases/modals/form';
import Tabs from '../shared/Tabs';
import Modal from '../shared/modal';

import { caseUsageTypes } from '../../constants/enums';

const usageTabs = [
  { label: 'Inventory' },
  { label: 'Image' },
];

const CaseUsageModal = (props) => {
  const {
    open,
    onClose,
    loading,
    onSubmit,
    title = 'Add Usage',
    tags
  } = props;

  const [activeTab, setActiveTab] = useState(0);

  const renderForm = () => {
    switch (activeTab) {
      case 0:
        return (
          <UsageImplantsForm
            onSubmit={(formObj) => onSubmit(caseUsageTypes.implants, formObj)}
            loading={loading}
            onClose={onClose}
            initialValues={{ quantity: 1 }}
            tags={tags}
          />
        );
      case 1:
        return (
          <UsageImagesForm
            onSubmit={(formObj, image) => onSubmit(caseUsageTypes.images, formObj, image)}
            loading={loading}
            onClose={onClose}
            initialValues={{ quantity: 1 }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      fullWidth
    >
      <div className='count-scan-modal__container'>
        <Tabs
          tabs={usageTabs}
          activeTab={activeTab}
          onTabChange={setActiveTab}
        >
          <div className='m-t-lg'>
            {renderForm()}
          </div>
        </Tabs>
      </div>
    </Modal>
  );
};

export default CaseUsageModal;
