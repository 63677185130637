import { isMoment } from 'moment';

import firebase, { collections, storageRefs } from '../firebase';

import userRoles from '../constants/userRoles';
import { formStatuses } from '../constants/enums';

import { nowTimestampUTC } from '../utils/date';
import { getFileWithParameters } from '../utils/api';
import urls from '../constants/urls';

export const getCustomerForms = () => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state?.tenant?.currentTenant?.id;
  const user = state?.user?.currentUser;
  const path = collections.CUSTOMER_FORMS_COLLECTION(tenantId);

  // let query;
  //
  // if (user.role === userRoles.ADMIN.name) {
  //   query = firebase.db.collection(path);
  // } else {
  //   query = firebase.db.collection(path).where('createdBy', '==', user.uid);
  // }

  const query = firebase.db.collection(path).where('createdBy', '==', user.uid);
  const snapshot = await query.get();
  const forms = snapshot?.docs?.map(doc => {
    const data = doc.data();

    return {
      id: doc.id,
      ...data,
      createdAt: data.createdAt ? data.createdAt.toDate() : '',
      submittedAt: data.submittedAt ? data.submittedAt.toDate() : '',
      closedAt: data.closedAt ? data.closedAt.toDate() : '',
      updatedAt: data.updatedAt ? data.updatedAt.toDate() : '',
    }
  });

  return forms;
};

export const getSubmittedForms = () => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state?.tenant?.currentTenant?.id;
  const user = state?.user?.currentUser;
  const allForms = state?.forms?.list || [];
  const path = collections.CUSTOMER_FORMS_COLLECTION(tenantId);

  const query = firebase.db.collection(path).where('status', 'in', [formStatuses.SUBMITTED, formStatuses.CLOSED]);

  // if (user.role === userRoles.ADMIN.name) {
  //   query = firebase.db.collection(path).where('status', 'in', [formStatuses.SUBMITTED, formStatuses.CLOSED]);
  // } else {
  //   query = firebase.db.collection(path)
  //     .where('roles', 'array-contains', user.role)
  //     .where('status', 'in', [formStatuses.SUBMITTED, formStatuses.CLOSED]);
  // }

  const snapshot = await query.get();
  const forms = snapshot?.docs?.map(doc => {
    const data = doc.data();

    return {
      id: doc.id,
      ...data,
      createdAt: data.createdAt ? data.createdAt.toDate() : '',
      submittedAt: data.submittedAt ? data.submittedAt.toDate() : '',
      closedAt: data.closedAt ? data.closedAt.toDate() : '',
      updatedAt: data.updatedAt ? data.updatedAt.toDate() : '',
    }
  });

  return forms?.filter((item) => {
    const form = allForms?.find((f) => item.formId === f.id);
    return form && (user.role === userRoles.ADMIN.name || form?.roles?.includes(user.role));
  });
};

export const addCustomerForm = (formId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const userId = state.user?.currentUser?.uid || '';
  const path = collections.CUSTOMER_FORMS_COLLECTION(tenantId);

  const doc = {
    active: true,
    status: formStatuses.PENDING,
    formId,
    values: {},
    createdAt: nowTimestampUTC(),
    createdBy: userId,
    caseId: null,
  };

  const snap = await firebase.db.collection(path).add(doc);
  return snap.id;
};

export const getCustomerForm = (formId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state?.tenant?.currentTenant?.id;
  const user = state?.user?.currentUser;
  const forms = state?.forms?.list || [];
  const path = collections.CUSTOMER_FORMS_COLLECTION(tenantId);

  const doc = await firebase.db.collection(path).doc(formId).get();
  const data = doc?.data();
  const form = {
    ...data,
    id: doc.id,
    createdAt: data.createdAt ? data.createdAt.toDate() : '',
    closedAt: data.closedAt ? data.closedAt.toDate() : '',
    submittedAt: data.submittedAt ? data.submittedAt.toDate() : '',
    updatedAt: data.updatedAt ? data.updatedAt.toDate() : '',
  };
  const templateForm = forms?.find((f) => f.id === form.formId);

  if (user.role !== userRoles.ADMIN.name && !templateForm.roles?.includes(user.role) && form.createdBy !== user.uid) {
    return null;
  }

  return form;
};

export const updateCustomerForm = (formId, data, files) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.CUSTOMER_FORMS_COLLECTION(tenantId);

  const { values } = data;

  for (const key of Object.keys(values)) {
    if (isMoment(values[key])) {
      values[key] = values[key]?.format('L');
    }
  }

  if (files && Object.keys(files)?.length) {
    for (const key of Object.keys(files)) {
      const fileObj = await uploadFormFile(tenantId, formId, files[key]);
      values[key] = fileObj;
    }
  }

  await firebase.db.collection(path).doc(formId).set({ ...data, updatedAt: nowTimestampUTC() }, { merge: true });
};

export const deleteCustomerForm = (formId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.CUSTOMER_FORMS_COLLECTION(tenantId);

  await firebase.db.collection(path).doc(formId).delete();
};

export const closeCustomerForm = (formId, notes) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const userId = state.user?.currentUser?.uid || '';
  const path = collections.CUSTOMER_FORMS_COLLECTION(tenantId);

  await firebase.db.collection(path).doc(formId).set({
    status: formStatuses.CLOSED,
    notes: notes || '',
    closedAt: nowTimestampUTC(),
    closedBy: userId,
  }, { merge: true });
};

const uploadFormFile = async (tenantId, formId, file) => {
  const storageRef = firebase.storage.ref();
  const storagePath = storageRefs.CUSTOMER_FORMS_REF(tenantId, formId);
  const filePath = `${storagePath}/${file.name}`;
  const ref = storageRef.child(filePath);
  const snap = await ref.put(file);
  const downloadUrl = await snap.ref.getDownloadURL();
  const fileObj = {
    active: true,
    fileName: file.name,
    path: filePath,
    downloadUrl,
    createdAt: nowTimestampUTC(),
  };
  return fileObj;
};

export const exportForms = (formIds, originalFormId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const forms = state.forms?.list;
  const idToken = await firebase.auth.currentUser.getIdToken();

  const formName = forms?.find((f) => f.id === originalFormId)?.name || '';

  try {
    await getFileWithParameters(urls.forms.exportForms(tenantId), idToken, `Forms - ${formName}`, 'xlsx', { formIds, originalFormId });
  } catch (err) {
    throw new Error(err);
  }
};
