import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

import { caseStatusOptions } from '../../constants/enums';

import './cases.scss';

const getClassName = (status) => {
  switch (status) {
    case caseStatusOptions.request:
      return 'calendar-view-event__request';
    case caseStatusOptions.booked:
      return 'calendar-view-event__booked';
    case caseStatusOptions.overdue:
      return 'calendar-view-event__overdue';
    case caseStatusOptions.completed:
      return 'calendar-view-event__completed';
    default:
      return '';
  }
};

const CasesCalendarView = (props) => {
  const {
    onSelectCase,
    cases,
  } = props;

  return (
    <div style={{ width: '100%', marginTop: 16 }}>
      <FullCalendar
        plugins={[dayGridPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: ''
        }}
        initialView='dayGridMonth'
        // weekends={false}
        events={cases?.map((item) => ({
          id: item.id,
          title: item?.patientReference || item?.id,
          start: item.date,
          allDay: true,
          className: getClassName(item.status),
        }))}
        eventContent={renderEventContent}
        eventClick={(event) => onSelectCase(event?.event?.id)}
      />
    </div>
  );
};

const renderEventContent = (event) => {
  return (
    <>
      <b>{event?.event?.title}</b>
    </>
  )
}

export default CasesCalendarView;
