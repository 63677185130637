import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function ItemIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M13.1393 1.20535H9.03394" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M13.1393 5.06839H9.03394" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M13.1393 8.93155H9.03394" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M13.1393 12.7946H9.03394" />
    <path stroke={color || defaultColor} fill="none"
          d="M0.947473 11.9931C1.00199 12.4729 1.3889 12.856 1.86892 12.9086C2.30413 12.9563 2.75235 13 3.21011 13C3.66788 13 4.1161 12.9563 4.55131 12.9086C5.03133 12.856 5.41823 12.4729 5.47275 11.9931C5.51882 11.5877 5.55939 11.1707 5.55939 10.7451C5.55939 10.3194 5.51882 9.90236 5.47275 9.497C5.41823 9.01719 5.03133 8.63413 4.55131 8.58153C4.1161 8.53385 3.66788 8.49014 3.21011 8.49014C2.75235 8.49014 2.30413 8.53385 1.86892 8.58153C1.3889 8.63413 1.00199 9.01719 0.947473 9.497C0.901411 9.90236 0.86084 10.3194 0.860839 10.745C0.860839 11.1707 0.90141 11.5877 0.947473 11.9931Z" />
    <path stroke={color || defaultColor} fill="none"
          d="M0.947473 4.50299C1.00199 4.9828 1.3889 5.36586 1.86892 5.41846C2.30413 5.46614 2.75235 5.50985 3.21011 5.50985C3.66788 5.50985 4.1161 5.46614 4.55131 5.41846C5.03133 5.36586 5.41823 4.9828 5.47275 4.50299C5.51882 4.09763 5.55939 3.6806 5.55939 3.25494C5.55939 2.82928 5.51882 2.41225 5.47275 2.00689C5.41823 1.52708 5.03133 1.14401 4.55131 1.09142C4.1161 1.04374 3.66788 1.00003 3.21011 1.00003C2.75235 1.00003 2.30413 1.04374 1.86892 1.09142C1.3889 1.14401 1.00199 1.52708 0.947473 2.00688C0.901411 2.41225 0.86084 2.82928 0.860839 3.25494C0.860839 3.6806 0.90141 4.09762 0.947473 4.50299Z" />
  </SvgIcon>
}
