import React from 'react';

import WarningIcon from '../../assets/icons/WarningIcon';

const CaseBookingNote = ({ bookingNote = '', createdAt, authorUid }) => {
  return (
    <div className='case-booking-note__container'>
      <WarningIcon color="#F97032FF" />
      <div className='m-l-md'>
        <span className='font-bold' style={{ color: '#F97032FF' }}>Booking Note</span>
        <span className='m-l-md'>{bookingNote}</span>
      </div>
    </div>
  );
};

// const CaseBookingNote = ({ bookingNote = '', createdAt, authorUid }) => {
//   return (
//     <div className='case-notes__container'>
//       <div className='d-flex m-b-lg space-between'>
//         <div className='d-flex'>
//           <NotesIcon />
//           <div className='font-size-bg font-bold m-l-md'>
//             Booking Note
//           </div>
//         </div>
//       </div>
//
//       <div className='case-notes-list__container'>
//         <div className='case-note'>
//           {/*<div className='d-flex space-between'>*/}
//           {/*  <div className='font-bold'>{authorUid}</div>*/}
//           {/*  <div className='secondary'>{moment(createdAt).fromNow()}</div>*/}
//           {/*</div>*/}
//           <div className=''>
//             {bookingNote}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

export default CaseBookingNote;
