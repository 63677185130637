import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { orderBy } from 'lodash';

import DeleteIcon from '../../../assets/icons/DeleteIcon';

import { InventoryTable } from '../../../components/customers';
import CountModal from '../../../components/counts/count-modal/CountModal';
import Button from '../../../components/shared/Button';
import Input from '../../../components/shared/Input';
import LoadScreen from '../../../components/load-screen';
import FilterButton from '../../../components/counts/FilterButton';

import { createCount, subscribeToCounts, deleteCount, getCounts } from '../../../actions/countsActions';
import { getHospitals } from '../../../actions/hospitalsActions';
import { getUsers } from '../../../actions/usersActions';
import { getSurgeons } from '../../../actions/surgeonsActions';
import { getKits } from '../../../actions/kitsActions';
import { getSets } from '../../../actions/setsActions';
import { getGroups } from '../../../actions/groupsActions';

import { useLoading, useAlert } from '../../../hooks';

import { alertTypes, routes } from '../../../constants';
import { countStatuses } from '../../../constants/enums';
import { ConfirmationModal } from '../../../components/shared/modal';

const CustomerInventoryPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const counts = useSelector((state) => state?.counts.list);
  const hospitals = useSelector((state) => state?.hospitals.list);
  const users = useSelector((state) => state?.users.list);
  const surgeons = useSelector((state) => state?.surgeons.list);
  const kits = useSelector((state) => state?.kits.list);
  const sets = useSelector((state) => state?.sets.list?.filter((set) => !!set.active));
  const groups = useSelector((state) => state?.groups.list);

  const { loading, startLoading, stopLoading } = useLoading();
  const { showAlert } = useAlert();

  const [isModalOpen, toggleModal] = useState(false);
  const [deleteModalOpen, toggleDeleteModal] = useState(false);
  const [search, setSearch] = useState('');
  const [checked, setChecked] = useState([]);
  const [deleting, setDeleting] = useState(false);

  const [statusFilter, setStatusFilter] = useState({
    [countStatuses.PENDING.value]: true,
    [countStatuses.REPEATING.value]: true,
    [countStatuses.OVERDUE.value]: true,
    [countStatuses.COMPLETED.value]: false,
  });

  useEffect(() => {
    onLoad().catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    const unsubscribe = dispatch(subscribeToCounts());
    return () => {
      unsubscribe();
    };
  }, [hospitals])

  const onLoad = async () => {
    startLoading()
    try {
      await Promise.all([
        dispatch(getHospitals()),
        dispatch(getUsers()),
        dispatch(getSurgeons()),
        dispatch(getKits()),
        dispatch(getSets()),
        dispatch(getGroups()),
      ]);
      dispatch(getCounts());
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onSelect = (count) => {
    history.push(`${routes.INVENTORY}/${count.id}`);
  };

  const onSubmit = async (formObj) => {
    const hospital = hospitals?.find((hospital) => hospital.id === formObj.hospitalId);
    const locations = hospital ? hospital.locations : [];
    const location = formObj.location && locations?.length ? formObj.location : null;

    const setsList = sets?.filter((set) => !!set.consigned && set?.consignment?.hospital === formObj.hospitalId);
    const list = kits?.filter((kit) => setsList?.map((s) => s.kit)?.includes(kit.id)) || [];
    const kit = formObj.kit && list?.length > 0 ? formObj.kit : null;

    try {
      const countObj = {
        hospitalId: formObj.hospitalId || '',
        dueDate: formObj.dueDate || new Date(),
        taggedUsers: formObj.taggedUsers || [],
        name: formObj.name || '',
        location,
        kit,
        groups: formObj.groups || [],
      };

      const countId = await dispatch(createCount(countObj));

      toggleModal(false);
      showAlert(alertTypes.SUCCESS, 'Count has been successfully created');
      if (countId) {
        history.push(`${routes.INVENTORY}/${countId}`);
      }
    } catch (err) {
      stopLoading();
      console.error(err);
    }
  };

  const onCloseModal = () => {
    toggleModal(false);
  };

  const onDelete = async () => {
    setDeleting(true);

    try {
      await Promise.all(checked.map((countId) => dispatch(deleteCount(countId))));
      toggleDeleteModal(false);
      showAlert(alertTypes.SUCCESS, 'Counts have been successfully deleted');
      setChecked([]);
    } catch (err) {
      console.error(err);
    } finally {
      setDeleting(false);
    }
  };

  const getHospitalName = useCallback((hospitalId) => {
    const countHospital = hospitals.find((hospital) => hospital.id === hospitalId);
    return countHospital && countHospital.name ? countHospital.name : '';
  }, [hospitals]);

  const mappedCounts = useMemo(() => {
    return counts.map((count) => ({ ...count, hospitalName: getHospitalName(count.hospitalId) }))
  }, [counts, hospitals]);

  const list = useMemo(() => {
    const result = mappedCounts?.filter((count) => {
      let persistence = true;

      if (!statusFilter.REPEATING && count.repeating) {
        persistence = false;
      }

      if (count.status === countStatuses.COMPLETED.value && !!statusFilter.COMPLETED) {
        persistence = true;
      }

      return persistence && !!statusFilter[count.status];
    });

    return !!statusFilter.COMPLETED ? orderBy(result, 'completedAt', 'desc') : result;
  }, [mappedCounts, statusFilter]);

  const useSearch = useCallback((count) => {
    if (!search) {
      return true;
    }

    const query = search.toLowerCase();
    return count.hospitalName.toLowerCase().includes(query) || count.id.toLowerCase().includes(query);
  }, [search, list]);

  const handleCheckAll = () => {
    const isChecked = checked.length === list?.filter(useSearch)?.length;
    const temp = [];

    if (!isChecked) {
      list?.filter(useSearch)?.forEach((item) => {
        temp.push(item.id);
      });

      setChecked(temp);
      return;
    }

    setChecked(temp);
  };

  const handleCheck = (countId) => {
    const checkedCounts = checked.slice();

    if (checkedCounts.includes(countId)) {
      const index = checkedCounts.indexOf(countId);

      checkedCounts.splice(index, 1);
      setChecked(checkedCounts);

      return;
    }

    checkedCounts.push(countId);
    setChecked(checkedCounts);
  };

  const onStatusFilterChange = (key) => {
    setStatusFilter({ ...statusFilter, [key]: !statusFilter[key], [countStatuses.COMPLETED.value]: false });
  };

  const onCompleteClick = () => {
    setStatusFilter({
      [countStatuses.PENDING.value]: false,
      [countStatuses.REPEATING.value]: false,
      [countStatuses.OVERDUE.value]: false,
      [countStatuses.COMPLETED.value]: true,
    });
  };

  return (
    <div className='customers-inventory-page'>
      { loading && <LoadScreen /> }

      <div className='d-flex space-between'>
        <div className='d-flex'>
          <div className='page-title' style={{ marginBottom: 8 }}>
            Counts
          </div>
        </div>
      </div>

      <div className='d-flex space-between'>
        <div className='d-flex'>
          <Button
            text='Add Count'
            type='submit'
            onClick={() => toggleModal(true)}
          />

          <div className='m-l-lg'>
            <Button
              type='icon'
              onClick={() => toggleDeleteModal(true)}
              loading={deleting}
              disabled={deleting || !!statusFilter.COMPLETED}
            >
              <DeleteIcon color={(deleting || !!statusFilter.COMPLETED) ? 'secondary' : '#000000'} />
            </Button>
          </div>
        </div>

        <div className='d-flex'>
          <div className='counts-page-filters'>
            <FilterButton
              status={countStatuses.PENDING.value}
              value={statusFilter[countStatuses.PENDING.value]}
              onClick={onStatusFilterChange}
            />
            <FilterButton
              status={countStatuses.REPEATING.value}
              value={statusFilter[countStatuses.REPEATING.value]}
              onClick={onStatusFilterChange}
            />
            <FilterButton
              status={countStatuses.OVERDUE.value}
              value={statusFilter[countStatuses.OVERDUE.value]}
              onClick={onStatusFilterChange}
            />
            <FilterButton
              status={countStatuses.COMPLETED.value}
              value={statusFilter[countStatuses.COMPLETED.value]}
              onClick={onCompleteClick}
            />
          </div>

          <div className='input-container'>
            <Input
              type='search'
              placeholder='Search'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </div>

      <InventoryTable
        counts={list.filter(useSearch)}
        users={users}
        onSelect={onSelect}
        handleCheck={handleCheck}
        checkedRows={checked}
        handleCheckAll={handleCheckAll}
        kits={kits}
        isCompleted={!!statusFilter[countStatuses.COMPLETED.value]}
      />

      <CountModal
        open={isModalOpen}
        onClose={onCloseModal}
        onSubmit={onSubmit}
        users={users}
        surgeons={surgeons}
        hospitals={hospitals}
        kits={kits}
        sets={sets}
        groups={groups}
      />

      <ConfirmationModal
        open={deleteModalOpen}
        onClose={() => toggleDeleteModal(false)}
        onSubmit={onDelete}
        title='Are you sure you want to delete these counts?'
        submitText='Delete'
        loading={deleting}
      />
    </div>
  );
};

export default CustomerInventoryPage;
