import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadScreen from '../../../components/load-screen';
import BOMLookupForm from '../../../components/reporting/bom-lookup/BOMLookupForm';
import BOMLookupTable from '../../../components/reporting/bom-lookup/BOMLookupTable';

import { getBOMItemLookupReport, getKits } from '../../../actions/kitsActions';
import { getSets } from '../../../actions/setsActions';

import useLoading from '../../../hooks/useLoading';

const BOMLookupReportingPage = () => {
  const dispatch = useDispatch();
  const items = useSelector((state) => state?.items?.list);
  const itemsLoaded = useSelector((state) => state.items.isLoaded);

  const { loading: fetching, startLoading, stopLoading } = useLoading(false);

  const [code, setCode] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    onLoad().catch((err) => console.error(err));
  }, []);

  const onLoad = async () => {
    startLoading()
    try {
      await Promise.all([
        dispatch(getKits()),
        dispatch(getSets()),
      ]);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const item = selectedItem ? items?.find((i) => i.id === selectedItem) : items?.find((i) => i.code === code);
      if (item) {
        const data = await dispatch(getBOMItemLookupReport(item));
        setData(data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='page-container reporting-page__container'>
      {(fetching || !itemsLoaded) && <LoadScreen />}
      <div className='page-title'>BOM Item Lookup</div>
      <div className='reporting-page__body'>
        <BOMLookupForm
          items={items}
          onSubmit={onSubmit}
          loading={loading}
          itemsLoading={!itemsLoaded}
          selectedItem={selectedItem}
          onSelect={setSelectedItem}
          code={code}
          setCode={setCode}
        />

        {data && !!data.length && (
          <BOMLookupTable
            data={data}
          />
        )}
      </div>
    </div>
  );
};

export default BOMLookupReportingPage;
