import { GET_GTIN_REFERENCE, SET_GTIN_REFERENCE } from './actionTypes';

import firebase, { collections } from '../firebase';

export const setGtins = gtins => ({ type: SET_GTIN_REFERENCE, gtins });

export const getGtins = () => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.GTIN_REFERENCE_COLLECTION(tenantId);

  dispatch({ type: GET_GTIN_REFERENCE });

  const snapshot = await firebase.db.collection(path).get();
  const gtins = snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));

  return dispatch(setGtins(gtins));
};

export const createGtin = (gtinData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.GTIN_REFERENCE_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).add({ ...gtinData, active: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateGtin = (gtinId, gtinData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.GTIN_REFERENCE_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(gtinId).set(gtinData, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteGtin = (gtinId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.GTIN_REFERENCE_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(gtinId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const bulkDeleteGtins = (gtins) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.GTIN_REFERENCE_COLLECTION(tenantId);
  const batch = firebase.db.batch();

  if (tenantId) {
    gtins.forEach((gtinId) => {
      const ref = firebase.db.collection(path).doc(gtinId);
      batch.delete(ref);
    });

    await batch.commit();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const uploadGtins = (gtins) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.GTIN_REFERENCE_COLLECTION(tenantId);

  if (tenantId) {
    const promises = [];

    gtins.forEach((item) => {
      const gtin = item[0] || '';
      const reference = item[1] || '';
      const description = item[2] || '';

      const doc = {
        gtin,
        reference,
        description,
        active: true,
      };

      promises.push(firebase.db.collection(path).add(doc));
    });

    await Promise.all(promises);
  } else {
    throw new Error('Invalid tenant');
  }
};

// Subscriptions
export const subscribeToGtins = (connectedTenantId) => (dispatch, getState) => {
  const state = getState();
  const tenant = state?.tenant?.currentTenant;
  const tenantId = connectedTenantId || tenant?.id;
  const path = collections.GTIN_REFERENCE_COLLECTION(tenantId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let gtins = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          gtins = [...gtins, {
            ...documentSnapshot.data(),
            id,
          }];
        });

        return dispatch(setGtins(gtins));
      },
    });
};
