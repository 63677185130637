import React, { useEffect, useMemo, useState } from 'react';

import Modal from '../../shared/modal';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

import { countTypes } from '../../../constants/enums';
import { singleTenantRoles } from '../../../constants/userRoles';

import { isAssigned } from '../../../utils/users';

const CountModal = (props) => {
  const {
    open,
    onClose,
    activeCount,
    hospitals = [],
    users = [],
    surgeons = [],
    kits = [],
    sets = [],
    groups = [],
    mode = 'create',
    onSubmit,
  } = props;

  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [type, setType] = useState(countTypes.FREE);
  const [loading, setLoading] = useState(false);

  const usersList = useMemo(() => (
    users?.filter((user) => {
      if (singleTenantRoles?.includes(user.role)) {
        return true;
      }
      return isAssigned(user, surgeons);
    })
  ), [users, surgeons]);

  useEffect(() => {
    if (mode === 'update' && activeCount) {
      setFormData(activeCount);
    }
  }, [activeCount, mode]);

  const handleClose = () => {
    onClose();
    setStep(0);

    if (mode === 'create') {
      setFormData({});
    }
  };

  const handleSubmit = async (data) => {
    setLoading(true);

    try {
      await onSubmit({ ...formData, ...data, });
      handleClose();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <Step1
            initialValues={formData}
            onClickNext={(formObj) => {
              setFormData({ ...formData, ...formObj });
              setStep(1);
            }}
            onClose={handleClose}
            hospitals={hospitals}
            sets={sets}
            type={type}
            setType={setType}
            mode={mode}
          />
        );
      case 1:
        return (
          <Step2
            initialValues={formData}
            onClickNext={(formObj) => {
              setFormData({ ...formData, ...formObj });
              setStep(2);
            }}
            onClickBack={() => setStep(0)}
            onClose={handleClose}
            hospitals={hospitals}
            sets={sets}
            kits={kits}
            type={type}
            mode={mode}
          />
        );
      case 2:
        return (
          <Step3
            initialValues={formData}
            onSubmit={handleSubmit}
            onClickBack={() => setStep(0)}
            onClose={handleClose}
            users={usersList}
            surgeons={surgeons}
            mode={mode}
            loading={loading}
            groups={groups}
            hospitals={hospitals}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={mode === 'update' ? 'Edit Count' : 'Create Count'}
      subtitle={activeCount?.id}
      fullWidth
    >
      <div className='case-modal__container'>
        {renderStep()}
      </div>
    </Modal>
  );
};

export default CountModal;
