import React, { useCallback, useMemo } from 'react';

import RepeatingCountIcon from '../../assets/icons/RepeatingCountIcon';

import Table from '../shared/table/Table';

import { countStatuses } from '../../constants/enums';

const InventoryTable = (props) => {
  const {
    counts = [],
    checkedRows = [],
    users = [],
    onSelect,
    selected,
    handleCheck = () => {},
    handleCheckAll = () => {},
    kits = [],
    isCompleted,
  } = props;

  const columns = useMemo(() => {
    const arr = [
      { title: '', field: 'status', type: 'countStatus', sort: false },
      { title: 'location', field: 'hospitalName', type: 'default', sort: true },
      { title: 'sub-location/consignment', field: 'location', type: 'default', sort: true },
      { title: 'count name', field: 'name', type: 'default', sort: true },
      { title: 'due date', field: 'dueDate', type: 'date', sort: true },
      { title: 'count id', field: 'id', type: 'default', sort: true },
      { title: 'completed by', field: 'userName', type: 'default', sort: true },
      {
        title: '',
        field: 'repeating',
        type: 'custom',
        sort: false,
        formatter: (value) => (
          <div style={{ textAlign: 'right' }}>
            {value && <RepeatingCountIcon color={countStatuses.REPEATING.color} />}
          </div>
        )
      },
    ];

    if (isCompleted) {
      arr.splice(1, 0, { title: 'submitted date', field: 'completedAt', type: 'date', sort: true })
    }

    return arr;
  }, [isCompleted]);

  const getUserName = useCallback((userId) => {
    const user = users?.find((u) => u.uid === userId);
    return user ? `${user.firstName} ${user.lastName}` : '';
  }, [counts, users]);

  const getKitName = useCallback((kitId) => {
    const caseKit = kits.find((kit) => kit.id === kitId);
    return caseKit && caseKit.name ? caseKit.name : '';
  }, [counts, kits]);

  return (
    <div style={{ width: '100%' }}>
      <Table
        rows={counts?.map((item) => ({
          ...item,
          userName: getUserName(item.completedBy),
          location: item.kit ? getKitName(item.kit) : item.location
        }))}
        columns={columns}
        onRowClick={onSelect}
        selected={selected}
        handleCheck={handleCheck}
        checkedRows={checkedRows}
        handleCheckAll={handleCheckAll}
        pagination
        noCheckAll
      />
    </div>
  );
};

export default InventoryTable;
