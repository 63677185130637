import React, { useState } from 'react';
import readXlsxFile from 'read-excel-file';

import Modal from '../shared/modal/Modal';
import Button from '../shared/Button';
import Dropzone from '../shared/dropzone';

const UploadHospitalsModal = (props) => {
  const {
    open,
    onSubmit,
    onClose,
    loading,
    tenantColor,
  } = props;

  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);

  const onRemove = () => {
    setFile(null);
  };

  const onFileChange = async (files) => {
    if (files && files.length) {
      setFile(files[0]);
    }
    const rows = await readXlsxFile(files[0]);
    if (rows && rows.length) {
      setData(rows);
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Upload Hospitals"
      fullWidth
    >
      <div className='upload-modal__container'>
        <div className='m-b-lg'>
          Please provide *.XLSX file for upload
        </div>
        <div>
          <div className='secondary m-b-md'>Format</div>
          <div className='modal-select align-center m-b-lg font-bold'>
            {['Name', 'Country', 'Street', 'City', 'State', 'Post code', 'Account No.', 'Business No.'].join(', ')}
          </div>
        </div>

        <Dropzone
          files={file ? [file] : []}
          onUpload={onFileChange}
          onRemove={onRemove}
          tenantColor={tenantColor}
          multiple={false}
          withoutProgress
        />

        <div className='upload-modal__actions'>
          <Button
            type='cancel'
            onClick={onClose}
          />
          <Button
            type='submit'
            text='Upload'
            disabled={loading || !data.length}
            loading={loading}
            onClick={() => onSubmit(data)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UploadHospitalsModal;
