import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function CompletedIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none"
          d="M4.09188 2.37195C3.7392 2.41586 3.39042 2.46155 3.04594 2.50387C2.56686 2.56273 2.18824 2.94674 2.15435 3.42823C1.94855 6.35157 1.94855 9.11344 2.15435 12.0368C2.18825 12.5183 2.56695 12.9035 3.04794 12.9438C5.69568 13.1658 8.11682 13.1658 10.7646 12.9438C11.2456 12.9035 11.6243 12.5183 11.6582 12.0368C11.8639 9.11344 11.8639 6.35158 11.6582 3.42823C11.6243 2.94674 11.2456 2.56273 10.7666 2.50387C10.4221 2.46155 10.0733 2.41586 9.72062 2.37195" />
    <rect width={5.656} height={2.969} x={4.078} y={0.75} stroke={color || defaultColor} fill="none"
          strokeLinejoin="round" rx={1.484} />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M5 8.92212L6.45455 10.4221C7.139 8.45597 7.70902 7.59321 9 6.42212" />
  </SvgIcon>
}
