import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import TagsTable from '../../../../components/tags/TagsTable';
import TagsFilters from '../../../../components/tags/TagsFilters';
import TagForm from '../../../../components/tags/TagForm';
import Input from '../../../../components/shared/Input';
import Modal, { ConfirmationModal, UploadModal } from '../../../../components/shared/modal';
import Alert from '../../../../components/shared/Alert';

import {
  getTags,
  createTag,
  updateTag,
  subscribeToTags,
  bulkDeleteTags,
  uploadTags,
} from '../../../../actions/tagsActions';

import { filterTags } from '../../../../utils/table';

import { statusOptions } from '../../../../constants/enums';

const statusFilterOptions = [
  { label: 'Active', value: statusOptions.active },
  { label: 'Deactivated', value: statusOptions.deactivated }
];

const TagsPage = (props) => {
  const {
    tags,
    procedures,
    createTag,
    updateTag,
    subscribeToTags,
    bulkDeleteTags,
    uploadTags,
    tenantColor,
    deletePermission,
  } = props;

  const [isModalCreateOpen, toggleModalCreate] = useState(false);
  const [isModalDeleteOpen, toggleModalDelete] = useState(false);
  const [isUploadModalOpen, toggleUploadModal] = useState(false);
  const [isModalDuplicateOpen, toggleModalDuplicate] = useState(false);
  const [loading, setLoading] = useState(false);

  const [successMessage, setSuccessMessage] = useState(null);

  const [selectedTag, setSelectedTag] = useState(null);
  const [checkedTags, setCheckedTags] = useState([]);

  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  useEffect(() => {
    const unsubscribe = subscribeToTags();

    onLoad().catch((err) => console.error(err));

    return () => {
      unsubscribe();
    };
  }, []);

  const onLoad = async () => {
  };

  const onSelectTag = (tag) => {
    setSelectedTag(tag);
  };

  // Create tag
  const onCreateTag = async (formObj) => {
    setLoading(true);
    const tagObj = {
      name: formObj.name || '',
      description: formObj.description || '',
      color: formObj.color || '',
    };

    try {
      await createTag(tagObj);
      setLoading(false);
      toggleModalCreate(false);
      setSuccessMessage('Tag has been successfully created');
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Update tag
  const onUpdateTag = async (formObj) => {
    setLoading(true);
    const tagObj = {
      active: formObj.active,
      name: formObj.name || '',
      description: formObj.description || '',
      color: formObj.color || '',
    };

    try {
      await updateTag(formObj.id, tagObj);
      setLoading(false);
      setSuccessMessage('Tag has been successfully updated');
      setSelectedTag(formObj);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Delete checked tags
  const onDeleteTags = async () => {
    await bulkDeleteTags(checkedTags);

    toggleModalDelete(false);
    setCheckedTags([]);
    setSelectedTag(null);
  };

  const handleDeleteClick = () => {
    if (checkedTags.length) {
      toggleModalDelete(true);
    }
  };

  // Upload tags
  const onUpload = async (tags) => {
    setLoading(true);

    try {
      await uploadTags(tags);
      setLoading(false);
      setSuccessMessage('Tags have been successfully uploaded');
      toggleUploadModal(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Duplicate tag
  const onDuplicateClick = async () => {
    if (!checkedTags || checkedTags.length !== 1) {
      return;
    }

    const tagId = checkedTags[0];
    const tag = tags.find((p) => p.id === tagId);
    setSelectedTag(tag);
    toggleModalDuplicate(true);
  };

  const onDuplicateTag = async (formObj) => {
    setLoading(true);
    const tagObj = {
      name: formObj.name || '',
      description: formObj.description || '',
      color: formObj.description || '',
    };

    try {
      await createTag(tagObj);
      setLoading(false);
      toggleModalDuplicate(false);
      setSuccessMessage('Tag has been successfully duplicated');
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    // setCheckedTags([]);
  };

  const tagList = useMemo(() => {
    return tags.filter((tag) => filterTags(tag, search, statusFilter));
  }, [tags, search, statusFilter]);

  const handleCheck = (tagId) => {
    const checked = checkedTags.slice();

    if (checked.includes(tagId)) {
      const index = checked.indexOf(tagId);

      checked.splice(index, 1);
      setCheckedTags(checked);

      return;
    }

    checked.push(tagId);
    setCheckedTags(checked);
  }

  const handleCheckAll = () => {
    const checked = checkedTags.length === tagList.length;
    const temp = [];

    if (!checked) {
      tagList.forEach((tag) => {
        temp.push(tag.id);
      });

      setCheckedTags(temp);
      return;
    }

    setCheckedTags(temp);
  };

  return (
    <div className='settings-cmp__main'>
      <span className='settings-title'>Item Tags</span>
      <div className='settings-cmp__body'>
        <div className='filters-container'>
          <TagsFilters
            onAddTagClick={() => toggleModalCreate(true)}
            onDelete={handleDeleteClick}
            status={statusFilter}
            setStatus={setStatusFilter}
            statuses={statusFilterOptions}
            onUploadClick={() => toggleUploadModal(true)}
            onDuplicateClick={onDuplicateClick}
            deletePermission={deletePermission}
          />
          <TagsTable
            onSelectTag={onSelectTag}
            tags={tagList}
            selectedTagId={selectedTag ? selectedTag.id : null}
            handleCheck={handleCheck}
            checkedTags={checkedTags}
            handleCheckAll={handleCheckAll}
            procedures={procedures}
          />
        </div>
        { tags && !!tags.length && (
          <div className='form-container'>
            <Input
              type='search'
              placeholder='Search Tags'
              value={search}
              onChange={handleSearch}
            />
            { selectedTag && (
              <TagForm
                initialValues={selectedTag}
                buttonText='Save Changes'
                onSubmit={onUpdateTag}
                loading={loading}
                mode='update'
                procedures={procedures}
              />
            )}
          </div>
        )}
      </div>

      <ConfirmationModal
        open={isModalDeleteOpen}
        onClose={() => toggleModalDelete(false)}
        onSubmit={onDeleteTags}
        title='Are you sure you want to delete these tags?'
        submitText='Delete'
      />

      <Modal
        open={isModalCreateOpen}
        onClose={() => toggleModalCreate(false)}
      >
        <TagForm
          buttonText='Add Tag'
          onSubmit={onCreateTag}
          loading={loading}
          mode='create'
          onClose={() => toggleModalCreate(false)}
        />
      </Modal>

      <Modal
        open={isModalDuplicateOpen}
        onClose={() => toggleModalDuplicate(false)}
      >
        <TagForm
          initialValues={selectedTag}
          buttonText='Duplicate'
          onSubmit={onDuplicateTag}
          loading={loading}
          mode='create'
          onClose={() => toggleModalDuplicate(false)}
        />
      </Modal>

      <UploadModal
        open={isUploadModalOpen}
        onClose={() => toggleUploadModal(false)}
        title='Upload Tags'
        tenantColor={tenantColor}
        onSubmit={onUpload}
        loading={loading}
        fields={['Name', 'Description']}
      />

      <Alert
        variant='success'
        message={successMessage}
        open={!!successMessage}
        onClose={() => setSuccessMessage(null)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tags: state.tags.list,
    procedures: state.procedures.list,
    tenantColor: state.tenant.currentTenant.colorPrimary,
    deletePermission: state.user.currentUser?.deletePermission,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTags: () => dispatch(getTags()),
    createTag: (tag) => dispatch(createTag(tag)),
    updateTag: (id, tagData) => dispatch(updateTag(id, tagData)),
    subscribeToTags: () => dispatch(subscribeToTags()),
    bulkDeleteTags: (tagIds) => dispatch(bulkDeleteTags(tagIds)),
    uploadTags: (tags) => dispatch(uploadTags(tags)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TagsPage);
