import React, { memo } from 'react';
import PropTypes from 'prop-types';

import MuiButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const StyledButton = withStyles((theme) => ({
  root: {
    minWidth: (props) => props?.width ? props.width : '200px',
    height: (props) => props?.height ? props.height : '60px',
    borderRadius: '16px',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 'bold',
    background: (props) => props?.background ? props.background : theme.palette.primary.main,
    color: (props) => props?.color ? props.color : theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: (props) => props?.background ? props.background : theme.palette.primary.main,
    },
    '&:active': {
      backgroundColor: (props) => props?.background ? props.background : theme.palette.primary.main,
    },
    '&:focus': {
      backgroundColor: (props) => props?.background ? props.background : theme.palette.primary.main,
    },
    '&:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      borderColor: 'rgba(0, 0, 0, 0.12)',
      color: 'rgba(0, 0, 0, 0.26)'
    }
  }
}))(MuiButton);

const StyledIconButton = withStyles((theme) => ({
  root: {
    width: '60px',
    height: '60px',
    borderRadius: '16px',
    border: `1px solid ${theme.palette.primary.grey}`,
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 'bold',
    background: '#ffffff',
    color: theme.palette.primary.contrastText,
    '&:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      // borderColor: 'rgba(0, 0, 0, 0.12)',
      color: 'rgba(0, 0, 0, 0.26)'
    }
  }
}))(MuiButton);

const StyledSecondaryButton = withStyles(theme => ({
  root: {
    minWidth: (props) => props?.width ? props.width : '200px',
    height: '60px',
    borderRadius: '16px',
    border: `1px solid ${theme.palette.primary.grey}`,
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 'bold',
    background: '#ffffff',
    color: '#a7a7a7',
    '&:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      borderColor: 'rgba(0, 0, 0, 0.12)',
      color: 'rgba(0, 0, 0, 0.26)'
    },
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  }
}))(MuiButton);

const StyledOutlinedButton = withStyles(theme => ({
  root: {
    minWidth: (props) => props?.width ? props.width : '200px',
    height: (props) => props?.height ? props.height : '60px',
    width: (props) => props?.height ? props.height : '60px',
    borderRadius: '16px',
    border: `2px solid ${theme.palette.primary.main}`,
    borderColor: (props) => props?.borderColor ? props.borderColor : theme.palette.primary.main,
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 'bold',
    background: '#ffffff',
    color:  (props) => props?.color ? props.color : theme.palette.primary.main,
    '&:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      borderColor: 'rgba(0, 0, 0, 0.12)',
      color: 'rgba(0, 0, 0, 0.26)'
    },
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  }
}))(MuiButton);

const StyledCancelButton = withStyles(theme => ({
  root: {
    height: '60px',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 'bold',
    background: 'transparent',
    color: '#a7a7a7',
    padding: '18px',
  }
}))(MuiButton);

const Button = (props) => {
  const {
    type = 'submit',
    text,
    background,
    color,
    borderColor,
    onClick,
    disabled,
    loading,
    outlined,
    children,
    width,
    height,
  } = props;

  switch (type) {
    case 'submit':
      return <StyledButton
        onClick={onClick}
        background={background}
        disabled={disabled}
        width={width}
        color={color}
        height={height}
      >
        { loading ? <CircularProgress size={24} /> : text}
        { children }
      </StyledButton>;
    case 'cancel':
      return <StyledCancelButton onClick={onClick}>
        { text || 'Cancel' }
      </StyledCancelButton>;
    case 'secondary':
      return <StyledSecondaryButton
        onClick={onClick}
        color={color || 'primary'}
        background={background}
        disabled={disabled}
      >
        { loading ? <CircularProgress size={24} /> : text}
      </StyledSecondaryButton>;
    case 'outlined':
      return <StyledOutlinedButton
        onClick={onClick}
        color={color}
        borderColor={borderColor}
        background={background}
        disabled={disabled}
        width={width}
        height={height}
      >
        { loading ? <CircularProgress size={24} /> : text}
        { children }
      </StyledOutlinedButton>;
    case 'icon':
      return <StyledIconButton
        onClick={onClick}
        disabled={disabled}
      >
        { loading ? <CircularProgress size={24} /> : children}
      </StyledIconButton>;
    default:
      return <StyledButton
        onClick={onClick}
        color={color || 'primary'}
        variant={outlined ? 'outlined' : 'text'}
        disabled={disabled}
        loading={loading}
        height={height}
      >
        { loading ? <CircularProgress size={24} /> : text}
      </StyledButton>;
  }
};

Button.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  outlined: PropTypes.bool,
  type: PropTypes.oneOf(['submit', 'cancel', 'secondary', 'icon', 'outlined'])
};

export default memo(Button);
