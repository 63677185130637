import React from 'react';
import { useHistory } from 'react-router-dom';

import FormIcon from '../../../assets/icons/FormIcon';
import AddIcon from '../../../assets/icons/AddIcon';

import CaseFormsTable from '../../cases/forms/CaseFormsTable';
import ActionButton from '../../shared/ActionButton';

import { setAllocationStatuses } from '../../../constants/enums';
import routes from '../../../constants/routes';

const CustomerFormsContainer = (props) => {
  const {
    customerForms = [],
    forms = [],
    users = [],
    onAddClick = () => {},
    tenantColor,
  } = props;

  const history = useHistory();

  const onRowClick = (form) => {
    if (form.caseId) {
      history.push(`${routes.CASES}/${form.caseId}${routes.FORMS}/${form.id}`);
    } else {
      history.push(`${routes.FORMS}/${form.id}`);
    }
  };

  return (
    <div className='case-kits__container'>
      <div className='d-flex align-start space-between'>
        <div className='d-flex'>
          <FormIcon />
          <div className='font-size-bg font-bold m-l-md'>
            Forms
          </div>
        </div>

        <ActionButton
          onClick={onAddClick}
          bgColor={setAllocationStatuses.RETURNED.color}
        >
          <AddIcon color="#ffffff" />
          Add
        </ActionButton>
      </div>

      {customerForms?.length ? (
        <CaseFormsTable
          rows={customerForms?.filter((form) => forms?.map((f) => f.id)?.includes(form.formId))}
          onRowClick={onRowClick}
          users={users}
          forms={forms}
          withCaseId
          tenantColor={tenantColor}
        />
      ) : (
        <div className='d-flex flex-center secondary p-lg'>
          No Forms
        </div>
      )}
    </div>
  );
};

export default CustomerFormsContainer;
