import React, { useEffect, useState, useMemo } from 'react';

import CaseUsageItemRow from './CaseUsageItemRow';
import CaseUsageSectionRow from './CaseUsageSectionRow';
import Button from '../../shared/Button';
import Input from '../../shared/Input';
import Select from '../../shared/Select';

import { itemTypeOptions, setAllocationTypes } from '../../../constants/enums';

const AddUsageModal = (props) => {
  const {
    onClose,
    loading,
    onSubmit,
    defaultList = [],
    tenantColor,
    open,
  } = props;

  const [selected, setSelected] = useState(defaultList[0] || null);
  const [search, setSearch] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [list, setList] = useState([]);

  useEffect(() => {
    if (defaultList && open) {
      setList([...defaultList] || []);
    }
    if (!open) {
      setList([]);
    }
  }, [defaultList, open]);

  const onChangeItem = (label, value) => {
    const temp = [...list];
    const selectedItem = temp?.find((i) => i?.id === selected?.id);

    if (selectedItem && selectedItem?.consumables?.length) {
      const consumable = selectedItem?.consumables?.find((c) => c?.label === label);
      if (consumable) {
        consumable.qty = value;
        setList(temp);
        setSelected(selectedItem);
      }
    }
  };

  const onChangeSetItem = (sectionName, itemCode, value) => {
    const temp = [...list];
    const selectedItem = temp?.find((i) => i?.id === selected?.id);

    if (selectedItem && selectedItem?.sections?.length) {
      const section = selectedItem?.sections?.find((s) => s.name === sectionName);
      if (section && section?.items?.length) {
        const item = section?.items?.find((i) => i.code === itemCode);
        if (item) {
          item.qty = value;
          setList(temp);
          setSelected(selectedItem);
        }
      }
    }
  };

  const handleSubmit = async () => {
    const usage = [];

    list?.forEach((item) => {
      if (item?.allocationType === setAllocationTypes.SET) {
        item?.sections?.forEach((section) => {
          if (section?.items?.filter((c) => c?.qty > 0)?.length) {
            section?.items?.forEach((item) => {
              if (item?.qty > 0) {
                usage?.push({
                  ref: item.code || '',
                  desc: item.description || '',
                  quantity: item.qty || 1,
                  scanType: 'USAGE',
                });
              }
            });
          }
        });
      }

      if (item?.allocationType === setAllocationTypes.ITEM) {
        if (item?.consumables?.filter((c) => c?.qty > 0)?.length) {
          item?.consumables?.forEach((consumable) => {
            if (consumable?.qty > 0) {
              usage?.push({
                lot: consumable.label || '',
                ref: item.code || '',
                desc: item.checklistDescription || item.description || '',
                quantity: consumable.qty || 1,
                scanType: 'USAGE',
              });
            }
          });
        }
      }
    });

    if (usage?.length) {
      await onSubmit(usage);
      setTimeout(setToDefaultValues, 500);
    }
  };

  const setToDefaultValues = () => {
    const temp = [...list];
    temp?.forEach((item) => {
      if (item?.allocationType === setAllocationTypes.SET) {
        item?.sections?.forEach((section) => {
          if (section?.items?.filter((c) => c?.qty > 0)?.length) {
            section?.items?.forEach((item) => {
              if (item?.qty > 0) {
                item.qty = 0;
              }
            });
          }
        });
      }

      if (item?.allocationType === setAllocationTypes.ITEM) {
        if (item?.consumables?.filter((c) => c?.qty > 0)?.length) {
          item?.consumables?.forEach((consumable) => {
            if (consumable?.qty > 0) {
              consumable.qty = 0
            }
          });
        }
      }
    });
    setList(temp);
  };

  const consumables = useMemo(() => {
    if (selected?.allocationType === setAllocationTypes.ITEM && selected?.consumables?.length) {
      let result = selected?.consumables;
      if (search) {
        result = result?.filter((cons) => (
          cons?.label?.toLowerCase()?.includes(search?.toLowerCase()) ||
          selected?.description?.toLowerCase()?.includes(search?.toLowerCase()) ||
          selected?.checklistDescription?.toLowerCase()?.includes(search?.toLowerCase()) ||
          selected?.code?.toLowerCase()?.includes(search?.toLowerCase())
        ));
      }
      if (selectedType) {
        result = result?.filter(() => selected?.type === selectedType);
      }

      return result;
    }
    return [];
  }, [selected, search, selectedType]);

  return (
    <div className='add-usage-modal__container'>
      <div className='add-usage-modal__content'>
        <div className='add-usage-modal__list'>
          {list?.map((item) => (
            <div
              key={item.id}
              className={selected?.id === item.id ? ' selected' : ''}
              onClick={() => setSelected(item)}
            >
              <div
                style={{ color: tenantColor }}
                className='font-bold m-b-sm'
              >
                {item.allocationType === setAllocationTypes.SET ? 'Set' : 'Item'}
              </div>
              <div className='font-bold m-b-sm'>
                {item.code || item.kitId}
              </div>
              <div className='font-size-sm'>
                {item.checklistDescription || item.description || item.kitName}
              </div>
            </div>
          ))}
        </div>
        <div className='add-usage-modal__main'>
          <div className='add-usage-modal__search'>
            <Input
              type='search'
              placeholder='Search'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Select
              value={selectedType}
              onChange={setSelectedType}
              options={[{ label: 'All', value: '' }, ...Object.values(itemTypeOptions)]}
              endAdornment='Select Type'
            />
          </div>
          <div className='m-b-sm secondary font-bold'>
            Select items to add to usage
          </div>
          <div className='add-usage-modal__items-list'>
            {selected && (
              selected?.allocationType === setAllocationTypes.SET ? (
                selected?.sections?.map((section) => (
                  <CaseUsageSectionRow
                    key={section.name}
                    section={section}
                    tenantColor={tenantColor}
                    onChange={(code, value) => onChangeSetItem(section.name, code, value)}
                    search={search}
                    selectedType={selectedType}
                  />
                ))
              ) : (
                <>
                  <div className='d-flex m-b-md align-start'>
                    <div
                      style={{ flex: 0.15 }}
                      className='font-bold uppercase font-size-sm secondary'
                    >
                      Item code
                    </div>
                    <div
                      style={{ flex: 0.15 }}
                      className='font-bold uppercase font-size-sm secondary'
                    >
                      Lot/Serial
                    </div>
                    <div
                      style={{ flex: 0.4, paddingLeft: 4 }}
                      className='font-bold uppercase font-size-sm secondary'
                    >
                      Description
                    </div>
                    <div
                      style={{ flex: 0.15, paddingLeft: 4 }}
                      className='font-bold uppercase font-size-sm secondary'
                    >
                      Type
                    </div>
                    <div
                      style={{ flex: 0.15 }}
                      className='font-bold d-flex flex-end'
                    />
                  </div>
                  {consumables?.map((consumable) => (
                    <CaseUsageItemRow
                      key={Math.random()}
                      {...selected}
                      {...consumable}
                      tenantColor={tenantColor}
                      onChange={(value) => onChangeItem(consumable.label, value)}
                      search={search}
                      selectedType={selectedType}
                    />
                  ))}
                </>
              )
            )}
          </div>
        </div>
      </div>
      <div className='add-usage-modal__actions'>
        <Button
          type='cancel'
          text='Close'
          onClick={onClose}
        />

        <div className='d-flex'>
          <Button
            type='submit'
            text='Add Usage'
            onClick={handleSubmit}
            loading={loading}
            disabled={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default AddUsageModal;
