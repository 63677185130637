import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';

import AvailableIcon from '../../assets/icons/AvailableSetIcon';
import AllocatedIcon from '../../assets/icons/AllocatedSetIcon';
import QuarantineIcon from '../../assets/icons/QuarantinedSetIcon';
import ConsignedIcon from '../../assets/icons/ConsignedSetIcon';

import { setPositionOptions } from '../../constants/enums';

const StyledButton = withStyles((theme) => ({
  root: {
    height: '60px',
    width: '80px',
    borderRadius: '16px',
  }
}))(MuiButton);

const getStatusIcon = (status) => {
  switch (status) {
    case setPositionOptions.AVAILABLE.value:
      return <AvailableIcon color="#ffffff" />;
    case setPositionOptions.ALLOCATED.value:
      return <AllocatedIcon color="#ffffff" />;
    case setPositionOptions.QUARANTINED.value:
      return <QuarantineIcon color="#ffffff" />;
    case setPositionOptions.CONSIGNED.value:
      return <ConsignedIcon color="#ffffff" />;
    default:
      return null;
  }
};

const FilterButton = ({
  status,
  value,
  onClick,
}) => {
  return (
    <StyledButton
      style={{
        background: value ? setPositionOptions[status]?.color : '#dddddd',
      }}
      value={value}
      onClick={() => onClick(status)}
    >
      <div className='d-flex direction-column space-between'>
        {getStatusIcon(status)}
        <div className='filter-button__label'>
          {setPositionOptions[status]?.label}
        </div>
      </div>
    </StyledButton>
  )
};

export default FilterButton;
