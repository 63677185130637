import React, { useState } from 'react';
import WarningIcon from '@material-ui/icons/Error';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Modal from './Modal';
import Button from '../Button';
import CSVUpload from '../CSVUpload';

import { setAllocationStatuses, itemTypeOptions } from '../../../constants/enums';

import './modal.scss';

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: '14px',
    padding: '4px 8px',
    borderRadius: 6
  }
}));

const UploadModal = (props) => {
  const {
    open,
    title,
    fields = [],
    onSubmit,
    onClose,
    loading,
    tenantColor,
    warningText,
    types = false,
  } = props;

  const [data, setData] = useState([]);

  const classes = useStyles();

  const handleOnDrop = (file) => {
    setData(
      file
        .filter((item) => item.data && item.data.length && !!item.data[0])
        .map((item) => item.data.map((i) => i.trim()))
    );
  }

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err)
  }

  const handleOnRemove = () => {
    setData([]);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title || 'Upload'}
      fullWidth
    >
      <div className='upload-modal__container'>
        <div className='m-b-lg'>
          Please provide a CSV UTF-8 (Comma Delimited) (*.CSV) file for upload
        </div>
        <div>
          <div className='secondary m-b-md'>Format</div>
          <div className='modal-select align-center m-b-lg font-bold'>
            {fields?.join(', ')}
          </div>
        </div>

        {warningText && (
          <div className='d-flex m-b-lg'>
            <WarningIcon style={{ color: setAllocationStatuses.ASSIGNED.color, marginRight: 4 }} />
            <span className='font-bold' style={{ color: setAllocationStatuses.ASSIGNED.color }}>
              {warningText}
            </span>
          </div>
        )}

        {types && (
          <div className='d-flex m-b-lg'>
            <WarningIcon style={{ color: setAllocationStatuses.ASSIGNED.color, marginRight: 4 }} />
            <span className='font-bold' style={{ color: setAllocationStatuses.ASSIGNED.color }}>
              Item type must be one of the following values  -
              <Tooltip
                title={Object.values(itemTypeOptions)?.map((i) => i?.label)?.join(', ')}
                aria-label='types-tooltip'
                classes={{ tooltip: classes.tooltip }}
              >
                <span className='font-bold m-l-sm' style={{ fontSize: 14, textDecoration: 'underline' }}>
                  view here
                </span>
              </Tooltip>
            </span>
          </div>
        )}

        <CSVUpload
          onDrop={handleOnDrop}
          onError={handleOnError}
          onRemoveFile={handleOnRemove}
          tenantColor={tenantColor}
        />

        <div className='upload-modal__actions'>
          <Button
            type='cancel'
            onClick={onClose}
          />
          <Button
            type='submit'
            text='Upload'
            disabled={loading || !data.length}
            loading={loading}
            onClick={() => onSubmit(data)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UploadModal;
