import React, { useState, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import moment from 'moment';
import { orderBy } from 'lodash';

import Button from '../shared/Button';
import Input from '../shared/Input';
import Select from '../shared/Select';
import DatePicker from '../shared/date-picker/DatePicker';
import ModalSelectInput from '../shared/modal-select/ModalSelectInput';
import GroupsModal from '../shared/modal-select/GroupsModal';
import UsersModal from '../shared/modal-select/UsersModal';

import '../../styles/shared/form.scss';

const frequencyOptions = [
  { label: '1 month', value: 1 },
  { label: '2 months', value: 2 },
  { label: '3 months', value: 3 },
  { label: '4 months', value: 4 },
  { label: '5 months', value: 5 },
  { label: '6 months', value: 6 },
  { label: '7 months', value: 7 },
  { label: '8 months', value: 8 },
  { label: '9 months', value: 9 },
  { label: '10 months', value: 10 },
  { label: '11 months', value: 11 },
  { label: '12 months', value: 12 },
];

const RepeatingCountForm = (props) => {
  const {
    mode = 'create',
    initialValues,
    buttonText,
    onSubmit,
    loading,
    onClose,
    hospitals = [],
    groups = [],
    users = [],
    kits = [],
  } = props;

  const { name } = initialValues || {};

  const [selectedHospital, selectHospital] = useState(mode === 'update' ? initialValues.hospitalId : null);
  const [isGroupsModalOpen, toggleGroupsModal] = useState(false);
  const [isUsersModalOpen, toggleUsersModal] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Required';
    }
    if (!values.hospitalId) {
      errors.hospitalId = 'Required';
    }
    if (!values.startDate) {
      errors.startDate = 'Required';
    }
    if (!values.frequency) {
      errors.frequency = 'Required';
    }
    if (!values.dueDays) {
      errors.dueDays = 'Required';
    }

    return errors;
  };

  const subLocationsList = useMemo(() => {
    if (selectedHospital) {
      const hospital = hospitals?.find((h) => h.id === selectedHospital);
      if (hospital && hospital?.locations && hospital?.locations.length) {
        return hospital?.locations?.map((item) => ({ label: item, value: item }));
      }
      return [];
    }
    return [];
  }, [selectedHospital]);

  const getGroupNames = (groupIds) => {
    if (!groupIds) {
      return [];
    }
    if (groupIds.length === groups.length) {
      return ['All'];
    }

    return groupIds.map((groupId) => {
      const group = groups.find((item) => item.id === groupId);
      return group ? group.name : '';
    })
  };

  const getMemberNames = (userIds) => {
    if (!userIds) {
      return [];
    }
    return userIds.map((uid) => {
      const member = users.find((user) => user.uid === uid);
      return member ? member.displayName : '';
    })
  };

  return (
    <div className='form__container'>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{
          ...initialValues,
        }}
        subscription={{ pristine: true, invalid: true }}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <div className='form__subtitle'>
                { name ? name : 'Add Count' }
              </div>
              { mode === 'update' && (
                <Field name='active'>
                  {({ input, meta }) => (
                    <Input
                      type='switch'
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      placeholder='Status'
                    />
                  )}
                </Field>
              )}

              <div className='m-b-sm'>
                <div className='field__title'>Name</div>
                <Field name='name'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              <div>
                <div className='field__title'>Location</div>
                <Field name='hospitalId'>
                  {({ input, meta }) => (
                    <div className='m-b-lg'>
                      <Select
                        name={input.name}
                        value={input.value}
                        onChange={(value) => {
                          input.onChange(value);
                          selectHospital(value)
                        }}
                        options={hospitals?.map((hospital) => ({ label: hospital.name, value: hospital.id }))}
                      />
                    </div>
                  )}
                </Field>
              </div>

              <div>
                <div className='field__title'>Sub-location</div>
                <Field name='location'>
                  {({ input, meta }) => (
                    <div className='m-b-lg'>
                      <Select
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                        options={subLocationsList}
                      />
                    </div>
                  )}
                </Field>
              </div>

              {initialValues.kit && (
                <div>
                  <div className='field__title'>Kit</div>
                  <Field name='kit'>
                    {({ input, meta }) => (
                      <div className='m-b-lg'>
                        <Select
                          name={input.name}
                          value={input.value}
                          onChange={(value) => {
                            input.onChange(value);
                            selectHospital(value)
                          }}
                          options={kits?.map((kit) => ({ label: kit.name, value: kit.id }))}
                        />
                      </div>
                    )}
                  </Field>
                </div>
              )}

              <div>
                <div className='field__title'>Repeating Frequency (months)</div>
                <Field name='frequency'>
                  {({ input, meta }) => (
                    <div className='m-b-lg'>
                      <Select
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                        options={frequencyOptions}
                      />
                    </div>
                  )}
                </Field>
              </div>

              <div>
                <div className='field__title'>Start Date</div>
                <Field name='startDate'>
                  {({ input, meta }) => (
                    <div className='m-b-lg'>
                      <DatePicker
                        name={input.name}
                        date={input.value}
                        onDateChange={input.onChange}
                        minDate={moment()}
                      />
                    </div>
                  )}
                </Field>
              </div>

              <div>
                <div className='field__title'>Time Frame (due days)</div>
                <Field name='dueDays'>
                  {({ input, meta }) => (
                    <div>
                      <Input
                        name={input.name}
                        value={input.value}
                        type='text'
                        numbersOnly
                        onChange={(e) => {
                          const value = e.target.value
                          if (value > -1 && value <= 30) {
                            input.onChange(value);
                          }
                        }}
                      />
                    </div>
                  )}
                </Field>
              </div>

              {mode === 'update' && (
                <Field name='groups'>
                  {({ input, meta }) => (
                    <div className='m-b-lg'>
                      <div className='field__title'>Groups</div>
                      <ModalSelectInput
                        onClick={() => toggleGroupsModal(true)}
                        count={input.value.length}
                        values={getGroupNames(input.value)}
                        label='Edit Groups'
                        icon='users'
                      />

                      <GroupsModal
                        onChange={input.onChange}
                        selected={input.value}
                        onClose={() => toggleGroupsModal(false)}
                        isOpen={isGroupsModalOpen}
                        groups={groups}
                      />
                    </div>
                  )}
                </Field>
              )}

              { mode === 'update' && (
                <Field name='users'>
                  {({ input, meta }) => (
                    <div className='m-b-lg'>
                      <div className='field__title'>Users</div>
                      <ModalSelectInput
                        onClick={() => toggleUsersModal(true)}
                        count={input.value.length}
                        values={getMemberNames(input.value)}
                        label='Edit Users'
                        icon='users'
                      />

                      <UsersModal
                        title={initialValues?.name}
                        onChange={input.onChange}
                        selected={input.value}
                        onClose={() => toggleUsersModal(false)}
                        isOpen={isUsersModalOpen}
                        listTitle='Users'
                        users={orderBy(users, 'lastName', 'asc')}
                      />
                    </div>
                  )}
                </Field>
              )}

              <div className='form__actions'>
                <Button
                  type='submit'
                  text={buttonText || 'Save Changes'}
                  disabled={invalid || loading || pristine}
                  loading={loading}
                  onClick={handleSubmit}
                />
                { mode === 'create' && (
                  <Button
                    type='cancel'
                    onClick={onClose}
                  />
                )}
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default RepeatingCountForm;
