import React from 'react';
import { ResponsivePie } from '@nivo/pie';

import { PieTooltip } from './chart-tooltips/PieTooltip';
import { formatChartPrice } from '../../utils/utils';

const CenteredMetric = ({ dataWithArc, centerX, centerY, isCurrency }) => {
  let total = 0
  dataWithArc.forEach(datum => {
    total += datum.value
  });

  return (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        color:"#FFFFFF",
        fontSize: '24px',
      }}
    >
      {isCurrency ? `$${formatChartPrice(total, 0)}` : total}
    </text>
  )
}

const PieChart = ({
  data,
  margin,
  innerRadius,
  padAngle,
  cornerRadius,
  sortByValue,
  enableArcLabels,
  arcLabel,
  arcLabelsSkipAngle,
  enableArcLinkLabels,
  arcLinkLabel,
  arcLinkLabelsSkipAngle,
  arcLinkLabelsThickness,
  arcLinkLabelsDiagonalLength,
  arcLinkLabelsStraightLength,
  activeOuterRadiusOffset,
  arcLinkLabelsOffset,
  arcLabelsTextColor,
  arcLinkLabelsTextColor,
  arcLinkLabelsColor,
  borderWidth,
  borderColor,
  defs,
  fill,
  legends,
  animate,
  colors,
  theme,
  isInteractive,
  height,
  width,
  entity = 'case',
  CustomTooltip,
  isCurrency,
}) => (
  <div style={{ height, width, position: 'relative' }}>
    <ResponsivePie
      data={data}
      margin={margin}
      borderWidth={borderWidth}
      borderColor={borderColor}
      innerRadius={innerRadius}
      padAngle={padAngle}
      cornerRadius={cornerRadius}
      activeOuterRadiusOffset={activeOuterRadiusOffset}
      enableArcLabels={enableArcLabels}
      arcLabel={arcLabel}
      enableArcLinkLabels={enableArcLinkLabels}
      arcLabelsSkipAngle={arcLabelsSkipAngle}
      arcLabelsTextColor={arcLabelsTextColor}
      arcLinkLabel={arcLinkLabel}
      arcLinkLabelsSkipAngle={arcLinkLabelsSkipAngle}
      arcLinkLabelsOffset={arcLinkLabelsOffset}
      arcLinkLabelsTextColor={arcLinkLabelsTextColor}
      arcLinkLabelsThickness={arcLinkLabelsThickness}
      arcLinkLabelsColor={arcLinkLabelsColor}
      arcLinkLabelsDiagonalLength={arcLinkLabelsDiagonalLength}
      arcLinkLabelsStraightLength={arcLinkLabelsStraightLength}
      sortByValue={sortByValue}
      theme={theme}
      tooltip={({ datum: { id, formattedValue, color } }) => {
        if (CustomTooltip) {
          return <CustomTooltip datum={{ id, formattedValue, color }} entity={entity} />
        }
        return <PieTooltip datum={{ id, formattedValue, color }} entity={entity} />;
      }}
      animate={animate}
      colors={colors}
      isInteractive={isInteractive}
      defs={defs}
      fill={fill}
      legends={legends}
      layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', (props) => <CenteredMetric {...props} isCurrency={isCurrency} />]}
    />
  </div>
);

PieChart.defaultProps = {
  height: '100%',
  width: '100%',
  animate: true,
  colors: { scheme: 'nivo' },
  enableArcLabels: false,
  enableArcLinkLabels: false,
  sortByValue: false,
  arcLabel: 'value',
  arcLinkLabel: 'id',
  arcLabelsSkipAngle: 0,
  arcLinkLabelsSkipAngle: 10,
  arcLinkLabelsThickness: 1,
  arcLinkLabelsDiagonalLength: 16,
  arcLinkLabelsStraightLength: 24,
  arcLinkLabelsOffset: 0,
  arcLinkLabelsTextColor: '#333333',
  arcLabelsTextColor: { from: 'color', modifiers: [['darker', 2]] },
  arcLinkLabelsColor: { from: 'color' },
  margin: { top: 40, right: 80, bottom: 80, left: 80 },
  borderColor: { from: 'color', modifiers: [['darker', 0.2]] },
  innerRadius: 0.5,
  padAngle: 1.5,
  cornerRadius: 3,
  activeOuterRadiusOffset: 8,
  borderWidth: 1,
  isInteractive: true,
  theme: {
    fontSize: '12px',
    fontFamily: 'Inter',
  },
  legends: [
    {
      anchor: 'right',
      direction: 'column',
      justify: false,
      translateX: 50,
      translateY: 0,
      itemsSpacing: 4,
      itemWidth: 100,
      itemHeight: 24,
      itemTextColor: '#ccc',
      itemDirection: 'right-to-left',
      itemOpacity: 1,
      symbolSize: 18,
      symbolShape: 'circle',
      effects: [
        {
          on: 'hover',
          style: { itemTextColor: '#000' },
        },
      ],
    },
  ],
};

export default PieChart;
