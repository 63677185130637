import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import LoginHeader from '../../components/auth/LoginHeader';
import LoginForm from '../../components/auth/LoginForm';

import { signIn, resetForm, microsoftSignIn } from '../../actions/authActions';

import useAlert from '../../hooks/useAlert';

import { routes } from '../../constants';

import './auth-page.scss';

const LoginPage = ({ history, signIn, resetForm, microsoftSignIn }) => {
  const [loading, setLoading] = useState(false);
  const [microsoftLoading, setMicrosoftLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const { showAlert } = useAlert();

  const onSignIn = async (formObj) => {
    const { email, password } = formObj;

    if (!email || !password) {
      setLoading(false);
      setErrorText('Login error. Please provide email and password.');
      setError(true);
      return;
    }

    setLoading(true);

    try {
      await signIn(email, password);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setErrorText(err && err.message ? err.message : 'Login error. Please, try again.');
      setError(true);
    }
  };

  const onMicrosoftSignIn = async () => {
    try {
      setMicrosoftLoading(true);
      await microsoftSignIn();
    } catch (err) {
      showAlert('error', 'Invalid Credentials');
    } finally {
      setMicrosoftLoading(false);
    }
  };

  const onHeaderClick = () => {
    history.push(routes.SIGN_IN);
    resetForm();
  };

  return (
    <div>
      <LoginHeader onHeaderClick={onHeaderClick} />
      <div className='main-body'>
        <LoginForm
          onSignIn={onSignIn}
          loading={loading}
          error={error}
          setError={setError}
          errorText={errorText}
          onMicrosoftSignIn={onMicrosoftSignIn}
          microsoftLoading={microsoftLoading}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (email, password) => dispatch(signIn(email, password)),
    resetForm: () => dispatch(resetForm()),
    microsoftSignIn: () => dispatch(microsoftSignIn())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginPage));
