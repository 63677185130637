import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';

import MuiPhoneNumber from 'material-ui-phone-number';

import DeleteIcon from '../../assets/icons/DeleteIcon';

import Input from '../shared/Input';
import Button from '../shared/Button';
import Modal from '../shared/modal/Modal';
import Checkbox from '../shared/Checkbox';
import CSVUpload from '../shared/CSVUpload';
import Tabs from '../shared/Tabs';
import AddressAutocomplete from '../shared/autocomplete/AddressAutocomplete';

import { get } from '../../utils/api';
import { formatAddressFromPlaces } from '../../utils/addresses';

import { useLoading } from '../../hooks';

import { countryAlphaCodes } from '../../constants/countries';
import urls from '../../constants/urls';

import './hospitals.scss';

const tabs = [
  { label: 'Manual' },
  { label: 'Upload' }
];

const HospitalAddressesModal = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    initialValues = [],
    defaultCountry,
  } = props;

  const { loading, startLoading, stopLoading } = useLoading(false);

  const [addresses, setAddresses] = useState(initialValues || []);
  const [activeTab, setActiveTab] = useState(0);

  const [label, setLabel] = useState('');
  const [address, setAddress] = useState(null);
  const [apartment, setApartment] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const [attn, setAttn] = useState('');
  const [phone, setPhone] = useState('');
  const [department, setDepartment] = useState('');
  const [neighborhood , setNeighborhood] = useState('');

  useEffect(() => {
    setAddresses(initialValues);
  }, [initialValues]);

  const handleAdd = async () => {
    if (!address) {
      return;
    }

    try {
      startLoading();

      const res = await get(urls.googleServices.getPlaceDetails(address.place_id));
      const formattedAddress = formatAddressFromPlaces(res?.placeDetails?.address_components);

      const newAddress = {
        id: uuid(),
        label,
        country: formattedAddress.country,
        postCode: formattedAddress.postCode,
        state: formattedAddress.state,
        city: formattedAddress.city,
        street: formattedAddress.street,
        apartment,
        buildingName,
        attn,
        phone,
        department,
        neighborhood,
        default: !addresses.length,
        placeId: address && address.place_id ? address.place_id : '',
        formattedAddress: address && address.description ? address.description : '',
      };

      setAddresses([...addresses, newAddress]);

      setLabel('');
      setAddress(null);
      setApartment('');
      setBuildingName('');
      setAttn('');
      setPhone('');
      setDepartment('');
      setNeighborhood('');
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const handleDelete = (address) => {
    const list = [...addresses].filter((item) => item.id !== address.id);

    if (address.default && list.length) {
      list[0].default = true;
    }

    setAddresses(list);
  };

  const handleCheck = (addressId) => {
    const list = [...addresses];
    const address = list.find((item) => item.id === addressId);

    if (address) {
      address.default = true;
      list.filter((item) => item.id !== addressId).forEach((item) => item.default = false);
      setAddresses(list);
    }
  };

  const handleOnDrop = (file) => {
    const rows = file
        .filter((item) => item.data && item.data.length && !!item.data[0])
        .map((item) => item.data.map((i) => i.trim()));

    const newAddresses = rows.map((row, index) => {
      const newAddress = {
        id: uuid(),
        label: row[0] || '',
        street: row[1] || '',
        city: row[2] || '',
        state: row[3] || '',
        postCode: row[4] || '',
        apartment: row[5] || '',
        buildingName: row[6] || '',
        neighborhood: row[7] || '',
        department: row[8] || '',
        attn: row[9] || '',
        phone: row[10] || '',
        default: index === 0 && !addresses.length
      };

      return newAddress;
    });

    setAddresses([...addresses, ...newAddresses]);
  }

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err)
  }

  const renderAddress = (value) => {
    const {
      street = '',
      city = '',
      state = '',
      postCode = '',
    } = value;
    const arr = [street, city, state, postCode].filter((value) => !!value.trim());
    return `(${arr.join(', ')})`;
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <div className='form__container hospital-addresses__modal'>
        <div className='form__body'>

          <div className='form__subtitle'>
            Add Delivery Address
          </div>

          <Tabs
            tabs={tabs}
            activeTab={activeTab}
            onTabChange={setActiveTab}
          >
            {activeTab === 0 ? (
              <div className='m-t-lg m-b-md'>
                <AddressAutocomplete value={address} onChange={setAddress} defaultCountry={defaultCountry} />

                <div className='d-flex'>
                  <div className='m-r-sm flex-1'>
                    <Input
                      type='text'
                      placeholder='Apartment (optional)'
                      value={apartment}
                      onChange={(e) => setApartment(e.target.value)}
                    />
                  </div>
                  <div className='m-l-sm flex-1'>
                    <Input
                      type='text'
                      placeholder='Building name (optional)'
                      value={buildingName}
                      onChange={(e) => setBuildingName(e.target.value)}
                    />
                  </div>
                </div>

                <div className='d-flex'>
                  <div className='m-r-sm flex-1'>
                    <Input
                      type='text'
                      placeholder='Neighborhood (optional)'
                      value={neighborhood}
                      onChange={(e) => setNeighborhood(e.target.value)}
                    />
                  </div>
                  <div className='m-l-sm flex-1'>
                    <Input
                      type='text'
                      placeholder='Department (optional)'
                      value={department}
                      onChange={(e) => setDepartment(e.target.value)}
                    />
                  </div>
                </div>

                <div className='d-flex'>
                  <div className='m-r-sm flex-1'>
                    <Input
                      type='text'
                      placeholder='Attention to (optional)'
                      value={attn}
                      onChange={(e) => setAttn(e.target.value)}
                    />
                  </div>
                  <div className='m-l-sm flex-1'>
                    <MuiPhoneNumber
                      value={phone}
                      onChange={setPhone}
                      fullWidth
                      defaultCountry={countryAlphaCodes[defaultCountry]}
                      placeholder='Phone (optional)'
                      className='form__phone p-l-md'
                      InputProps={{ disableUnderline: true }}
                    />
                  </div>
                </div>

                <div className='d-flex align-start'>
                  <div className='flex-1'>
                    <Input
                      type='text'
                      placeholder='Label'
                      value={label}
                      onChange={(e) => setLabel(e.target.value)}
                    />
                  </div>
                  <div className='flex-1 d-flex flex-end align-start'>
                    <Button
                      type='submit'
                      text='Add'
                      onClick={handleAdd}
                      width={120}
                      disabled={!address || !label || loading}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className='m-t-lg m-b-md'>
                <div className='m-b-lg'>
                  Please provide a csv file with comma separated rows
                </div>
                <div>
                  <div className='secondary m-b-md'>Format</div>
                  <div className='modal-select align-center m-b-lg font-bold'>
                    Label, Street, City, State, Post code, Apartment, Building name, Neighborhood, Department, Attn, Phone number
                  </div>
                </div>

                <CSVUpload
                  onDrop={handleOnDrop}
                  onError={handleOnError}
                />
              </div>
            )}
          </Tabs>

          <div className='field__title'>Addresses</div>
          <div className='m-b-lg form__select-users'>
            {addresses.length ? (
              <>
                <div className='field__title'>Use checkboxes below to select the default user address</div>
                {addresses.map((address) => (
                  <div className='md-user-info m-b-sm' key={address.id}>
                    <div>
                      <Checkbox
                        input={{
                          onClick: () => handleCheck(address.id),
                          checked: address.default
                        }}
                        fontSize='small'
                        label={address.label}
                      />
                      <span style={{ fontWeight: 400, marginLeft: 8, marginRight: 8 }}>
                      {renderAddress(address)}
                    </span>
                    </div>
                    {address.id !== 'default_address' && (
                      <div className='md-user-info__delete' onClick={() => handleDelete(address)}>
                        <DeleteIcon fontSize='small' color='#d32f2f' />
                      </div>
                    )}
                  </div>
                ))}
              </>
            ) : (
              <div className='text-center secondary p-md'>
                No Addresses
              </div>
            )}
          </div>

          <div className='form__actions m-t-md'>
            <Button
              type='submit'
              text='Submit'
              onClick={() => {
                onSubmit(addresses);
                onClose();
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default HospitalAddressesModal;
