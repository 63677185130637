import React, { useState, useMemo } from 'react';
import { Field, Form } from 'react-final-form';

import Button from '../shared/Button';
import Input from '../shared/Input';
import ModalSelectInput from '../shared/modal-select/ModalSelectInput';
import ProcedureOptionsModal from '../shared/modal-select/ProcedureOptionsModal';
import Select from '../shared/Select';

import '../../styles/shared/form.scss';

const ProcedureForm = (props) => {
  const {
    mode = 'create',
    initialValues,
    buttonText,
    onSubmit,
    loading,
    procedureOptions,
    onClose,
    procedures = [],
    flows = []
  } = props;

  const [isModalOpen, toggleModal] = useState(false);

  const { name } = initialValues || {};

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Required';
    }

    if (procedures?.map((p) => p.name)?.includes(values?.name?.trim()) && mode === 'create') {
      errors.name = 'Duplicate';
    }

    return errors;
  };

  const getOptionNames = (optionIds) => {
    return optionIds.map((id) => {
      const option = procedureOptions.find((item) => item.id === id);
      return option ? option.name : '';
    })
  };

  const flowsOptions = useMemo(() => ([
    { label: 'No Mandatory Flow', value: null },
    ...flows?.map((f) => ({ label: f.name, value: f.id }))
  ]), [flows, initialValues]);

  return (
    <div className='form__container'>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
        subscription={{ invalid: true }}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <div className='form__subtitle'>
                { name ? name : 'Add Procedure' }
              </div>
              { mode === 'update' && (
                <Field name='active'>
                  {({ input, meta }) => (
                    <Input
                      type='switch'
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      placeholder='Status'
                    />
                  )}
                </Field>
              )}

              <div>
                <div className='field__title'>Name</div>
                <Field name='name'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      placeholder='Name'
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              <div>
                <div className='field__title'>Description</div>
                <Field name='description'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      placeholder='Description'
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              { mode === 'update' && (
                <Field name='options'>
                  {({ input, meta }) => (
                    <div className='m-b-lg'>
                      <div className='field__title'>Options</div>
                      <ModalSelectInput
                        onClick={() => toggleModal(true)}
                        count={input.value.length}
                        label='Edit Options'
                        values={getOptionNames(input.value)}
                        icon='option'
                      />
                      <ProcedureOptionsModal
                        title='Procedure Options'
                        onChange={input.onChange}
                        selected={input.value}
                        onClose={() => toggleModal(false)}
                        isOpen={isModalOpen}
                      />
                    </div>
                  )}
                </Field>
              )}

              { mode === 'update' && (
                <Field name='averageValue'>
                  {({ input, meta }) => (
                    <div className=''>
                      <div className='field__title'>Average Value ($)</div>
                      <Input
                        type='text'
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                        placeholder=''
                        numbersOnly
                      />
                    </div>
                  )}
                </Field>
              )}

              { mode === 'update' && (
                <Field name='mandatoryFlow'>
                  {({ input, meta }) => (
                    <div className='m-b-lg'>
                      <div className='field__title'>Mandatory Flow</div>
                      <Select
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                        options={flowsOptions}
                      />
                    </div>
                  )}
                </Field>
              )}

              <div className='form__actions'>
                <Button
                  type='submit'
                  text={buttonText || 'Save Changes'}
                  disabled={invalid || loading || pristine}
                  loading={loading}
                  onClick={handleSubmit}
                />
                { mode === 'create' && (
                  <Button
                    type='cancel'
                    onClick={onClose}
                  />
                )}
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default ProcedureForm;
