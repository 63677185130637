import React, { useState } from 'react';

import Button from '@material-ui/core/Button';

const VALUES = ['GTIN', 'REF', 'LOT', 'EXP'];

const NewUsageItem = (props) => {
  const { onSubmit } = props;

  const [data, setData] = useState({
    GTIN: '',
    REF: '',
    LOT: '',
    EXP: '',
    quantity: '1',
  });

  const onSaveClick = () => {
    onSubmit({
      gtin: data.GTIN || '',
      ref: data.REF || '',
      lot: data.LOT || '',
      exp: data.EXP || '',
      quantity: data.quantity || '',
    });
    setData({
      GTIN: '',
      REF: '',
      LOT: '',
      EXP: '',
      quantity: '',
    });
  };

  return (
    <div className='d-flex space-between'>
      <div>
        {VALUES?.map((label) => (
          <div className='m-b-sm'>
            <div className='secondary font-bold'>
              {label}
            </div>
            <div className='font-bold'>
              <input
                className='minimized-input'
                type='text'
                value={data[label]}
                onChange={(e) => setData({ ...data, [label]: e.target?.value })}
              />
            </div>
          </div>
        ))}
      </div>
      <div className='d-flex direction-column space-between align-end height-100'>
        <div className='pointer'>
          <Button
            onClick={onSaveClick}
            variant='contained'
            color='primary'
            style={{ borderRadius: '12px' }}
            disabled={!data.quantity || (!data.REF && !data.LOT && !data.GTIN)}
          >
            Save
          </Button>
        </div>
        <div className='text-right'>
          <div className='text-right m-b-sm'>
            <span className='font-bold secondary m-r-md'>QTY</span>
            <input
              className='minimized-input'
              type='number'
              value={data.quantity}
              onChange={(e) => setData({ ...data, quantity: e.target?.value })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewUsageItem;
