import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import EditIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Check';
import DeleteIcon from '../../assets/icons/DeleteIcon';

import Checkbox from '../shared/Checkbox';

import { updateQualification, deleteQualification } from '../../actions/surgeonsActions';

import '../shared/modal-select/modal-select.scss';

const QualificationRow = (props) => {
  const {
    checked,
    name,
    onChange,
    id,
  } = props;

  const dispatch = useDispatch();

  const [label, setLabel] = useState(name || '');
  const [editMode, toggleEditMode] = useState(false);

  const onSave = () => {
    dispatch(updateQualification(id, label));
    toggleEditMode(false);
  };

  return (
    <div className='md-user-info'>
      <div>
        {editMode ? (
          <input
            value={label}
            onChange={(e) => setLabel(e.target.value)}
            maxLength={20}
          />
        ) : (
          <Checkbox
            input={{
              onClick: onChange,
              checked,
            }}
            fontSize='small'
            label={name}
          />
        )}
      </div>
      {editMode ? (
        <div className='md-user-info__delete' onClick={onSave}>
          <SaveIcon fontSize='small' color='primary' />
        </div>
      ) : (
        <div className='d-flex'>
          <div className='md-user-info__delete' onClick={() => toggleEditMode(true)}>
            <EditIcon fontSize='small' color='primary' />
          </div>
          <div className='md-user-info__delete m-l-sm' onClick={() => dispatch(deleteQualification(id))}>
            <DeleteIcon fontSize="small" color="#d32f2f" />
          </div>
        </div>
      )}
    </div>
  );
};

export default QualificationRow;
