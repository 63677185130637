import React, { useState, useMemo, useEffect } from 'react';
import { orderBy as sortBy } from 'lodash';
import { Column, Table, SortDirection } from 'react-virtualized';
import 'react-virtualized/styles.css';

import Checkbox from '../../shared/Checkbox';
import Button from '../../shared/Button';
import Modal from '../../shared/modal/Modal';
import Input from '../../shared/Input';
import Select from '../../shared/Select';

import { itemTypeOptions } from '../../../constants/enums';

import '../../shared/modal-select/modal-select.scss';

const SectionItemsModal = (props) => {
  const {
    listTitle = 'items',
    options,
    onSubmit,
    selected = [],
    onClose,
    isOpen,
  } = props;

  const [items, setItems] = useState(selected);
  const [search, setSearch] = useState('');
  const [type, setType] = useState('');

  useEffect(() => {
    setItems(selected);
  }, [selected]);

  const handleCheck = (itemId) => {
    let temp = items.slice();

    if (temp?.map((i) => i.id)?.includes(itemId)) {
      temp = temp.filter((i) => i.id !== itemId);
    } else {
      temp = [...temp, { id: itemId, quantity: 1, ref: '' }];
    }

    setItems(temp);
  };

  const list = useMemo(() => (
    sortBy(
      options.filter((item) => {
        let filter = true;

        if (!!type) {
          filter = item?.type === itemTypeOptions[type]?.value;
        }

        return item?.code?.toLowerCase().includes(search?.toLowerCase()) && filter;
      }),
      'code',
    )
  ), [options, search, type]);

  const handleCheckAll = (value) => {
    let temp = items.slice();

    if (value) {
      list.forEach((item) => {
        if (!items?.map((i) => i.id).includes(item.id)) {
          temp.push({ id: item.id, quantity: 1, ref: '' });
        }
      });
    } else {
      temp = items.filter((item) => !list?.map((i) => i.id).includes(item.id));
    }
    setItems(temp);
  };


  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      size="lg"
    >
      <div className="form__container section-items-modal__container">
        <div className="form__body">
          <div className="form__subtitle">
            Add Items
          </div>

          <div className="section-items-modal__filters">
            <div className="m-r-md">
              <Input
                type="search"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>

            <div className="m-l-md">
              <Select
                value={type}
                onChange={setType}
                options={[{ label: 'All', value: '' }, ...Object.values(itemTypeOptions)]}
              />
            </div>
          </div>

          <div className="secondary font-size-sm m-b-sm">Items</div>
          <div className="m-b-lg form__select-users" style={{ minWidth: 860, minHeight: 540 }}>
            {list?.length ? (
              <VirtualizedTable
                data={list?.map((i) => ({ ...i, description: i.checklistDescription || i.description || '' }))}
                onCheckAll={handleCheckAll}
                checked={items?.map((i) => i.id)}
                onCheck={handleCheck}
              />
            ) : (
              <div className="text-center secondary p-md">
                {`No ${listTitle}`}
              </div>
            )}
          </div>

          <div className="form__actions">
            <Button
              type="submit"
              text="Submit"
              onClick={() => {
                onSubmit(items);
                onClose();
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const VirtualizedTable = ({ data, checked, onCheck, onCheckAll }) => {
  const [sortBy, setSortBy] = useState(''); // State to track the currently sorted column
  const [sortDirection, setSortDirection] = useState(SortDirection.ASC); // State to track the sort direction

  const sort = ({ sortBy, sortDirection }) => {
    return data.slice().sort((a, b) => {
      const valueA = a[sortBy] || '';
      const valueB = b[sortBy] || '';

      if (sortDirection === SortDirection.ASC) {
        return valueA?.localeCompare(valueB);
      } else {
        return valueB?.localeCompare(valueA);
      }
    });
  };

  const sortedData = sort({ sortBy, sortDirection });

  const handleSort = ({ sortBy, sortDirection }) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection);
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Table
        width={860} // Width of the table
        height={500} // Height of the table
        headerHeight={36} // Height of the table header
        rowHeight={36} // Height of each table row
        rowCount={sortedData.length} // Total number of rows
        rowGetter={({ index }) => sortedData[index]} // Function to get the data for each row
        sort={handleSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
      >
        <Column
          label=""
          disableSort
          dataKey="id"
          width={50}
          headerRenderer={({ label, sortBy, sortDirection }) => (
            <div>
              <Checkbox
                input={{
                  onClick: (e) => onCheckAll(e.target.checked),
                  checked: data.every((item) => checked?.includes(item.id)),
                }}
                fontSize="small"
              />
            </div>
          )}
          cellRenderer={({ rowIndex }) => {
            return (
              <div>
                <Checkbox
                  input={{
                    onClick: () => onCheck(sortedData[rowIndex].id),
                    checked: !!checked.includes(sortedData[rowIndex].id),
                  }}
                  index={rowIndex}
                  fontSize="small"
                />
              </div>
            )
          }}
        />
        <Column
          label="Item Code"
          dataKey="code"
          width={200}
          headerRenderer={({ label, sortBy, sortDirection }) => (
            <div>
              {label}
              {sortBy === 'code' && (
                <SortCaret direction={sortDirection}/>
              )}
            </div>
          )}
        />
        <Column
          label="Description"
          dataKey="description"
          width={410}
          headerRenderer={({ label, sortBy, sortDirection }) => (
            <div>
              {label}
              {sortBy === 'description' && (
                <SortCaret direction={sortDirection}/>
              )}
            </div>
          )}
        />
        <Column
          label="Type"
          dataKey="type"
          width={200}
          headerRenderer={({ label, sortBy, sortDirection }) => (
            <div>
              {label}
              {sortBy === 'type' && (
                <SortCaret direction={sortDirection}/>
              )}
            </div>
          )}
        />
      </Table>
    </div>
  );
};

const SortCaret = ({ direction }) => {
  return (
    <span style={{ marginLeft: '5px' }}>
      {direction === SortDirection.ASC ? '▲' : '▼'}
    </span>
  );
};

export default SectionItemsModal;
