import React, { useState, useMemo } from 'react';
import { orderBy } from 'lodash';

import Input from '../shared/Input';
import Checkbox from '../shared/Checkbox';
import Button from '../shared/Button';
import Modal from '../shared/modal/Modal';
import Select from '../shared/Select';

import { countries } from '../../constants';

const countryFilterOptions = countries.map(({ label }) => ({ label, value: label }));

const TagHospitalsModal = (props) => {
  const {
    open,
    onClose,
    territories = [],
    selectedTerritories = [],
    hospitals = [],
    onSubmit,
    loading,
  } = props;

  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');

  const handleCheck = (id) => {
    if (selected?.includes(id)) {
      setSelected([...selected]?.filter((hospitalId) => hospitalId !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const list = useMemo(() => {
    let arr = hospitals;

    if (country) {
      arr = arr?.filter((h) => h.country === country);
    }

    if (state) {
      arr = arr?.filter((h) => h.state === state);
    }

    return orderBy(arr, 'name', 'asc').filter((item) => item.name?.toLowerCase().includes(search.toLowerCase()));
  }, [hospitals, country, search, state]);

  const handleCheckAll = () => {
    const isChecked = selected?.length && list?.every((item) => selected?.includes(item.id));

    if (isChecked) {
      setSelected([]);
    } else {
      setSelected(list?.map((item) => item.id));
    }
  };

  const getTerritoryNames = (ids) => {
    return ids?.map((id) => {
      const territory = territories.find((item) => item.id === id);
      return territory ? territory.name : '';
    })?.join(', ');
  };

  const stateOptions = useMemo(() => {
    const result = [];
    const arr = country ? hospitals?.filter((h) => h.country === country) : hospitals;
    arr?.forEach((hospital) => {
      if (hospital.state && !result.includes(hospital.state)) {
        result.push(hospital.state);
      }
    });
    return result?.map((state) => ({ label: state, value: state }));
  }, [hospitals, country]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      size='lg'
      fullWidth
    >
      <div className='form__container'>
        <div className='form__body'>

          <div className='form__subtitle'>
            Tag Territory to Hospitals
          </div>

          <div>
            Please select the hospitals you wish to tag with the following territories:
          </div>

          <div className='m-t-md font-bold m-b-lg'>
            {getTerritoryNames(selectedTerritories)}
          </div>

          <div className='d-flex'>
            <div className='m-r-md flex-1'>
              <Input
                type='search'
                placeholder='Search'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className='m-l-md flex-1 m-b-lg m-r-md'>
              <Select
                value={country}
                onChange={(value) => {
                  setState('');
                  setCountry(value);
                }}
                options={countryFilterOptions}
                defaultLabel={`All countries (${countries?.length})`}
              />
            </div>
            <div className='m-l-md flex-1 m-b-lg'>
              <Select
                value={state}
                onChange={setState}
                options={stateOptions}
                defaultLabel={`All states (${stateOptions?.length})`}
              />
            </div>
          </div>

          <div className='field__title'>Hospitals</div>
          <div className='m-b-lg form__select-users'>
            {list.length ? (
              <table>
                <thead>
                  <tr className='font-bold' style={{ borderBottom: '1px solid #eee' }}>
                    <th>
                      <Checkbox
                        input={{
                          onClick: () => handleCheckAll(),
                          checked: selected?.length && list?.every((item) => selected?.includes(item.id)),
                        }}
                        fontSize='small'
                      />
                    </th>
                    <th>Hospital Name</th>
                    <th>State</th>
                    <th>Postcode</th>
                    <th>Account No.</th>
                    <th>Country</th>
                    <th>Territories</th>
                  </tr>
                </thead>
                <tbody>
                {list.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <Checkbox
                        input={{
                          onClick: () => handleCheck(item.id),
                          checked: selected.indexOf(item.id) !== -1
                        }}
                        fontSize='small'
                      />
                    </td>
                    <td>{item.name}</td>
                    <td>{item.state}</td>
                    <td>{item.postCode}</td>
                    <td>{item.accountNumber}</td>
                    <td>{item.country}</td>
                    <td>{getTerritoryNames(item.territories)}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            ) : (
              <div className='text-center secondary p-md'>
                No Hospitals
              </div>
            )}
          </div>

          <div className='form__actions'>
            <Button
              type='submit'
              text='Submit'
              loading={loading}
              disabled={loading || !selected?.length}
              onClick={() => onSubmit(selected)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TagHospitalsModal;
