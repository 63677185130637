import React, { useState, useCallback } from 'react';

const AlertContext = React.createContext({
  isOpen: false,
  variant: 'success',
  message: '',
  showAlert: () => {},
  closeAlert: () => {},
});

const AlertProvider = ({ children }) => {
  const [isOpen, toggleAlert] = useState(false);
  const [variant, setVariant] = useState('success');
  const [message, setMessage] = useState('');

  const showAlert = (variant, message) => {
    setVariant(variant);
    setMessage(message);
    toggleAlert(true);
  };

  const closeAlert = () => {
    toggleAlert(false);
    setMessage('');
    setTimeout(() => setVariant('success'), 500);
  };

  const contextValue = {
    isOpen,
    variant,
    message,
    showAlert: useCallback((variant, message) => showAlert(variant, message), []),
    closeAlert: useCallback(() => closeAlert(), [])
  };

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
export { AlertContext };
