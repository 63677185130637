import { GET_KIT_PREFERENCES, SET_KIT_PREFERENCES } from '../actions/actionTypes';

const initialState = {
  list: [],
  isLoading: false,
  isLoaded: false
};

const kitPreferencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_KIT_PREFERENCES:
      return { ...state, isLoading: true };
    case SET_KIT_PREFERENCES:
      return {
        ...state,
        list: action.kitPreferences,
        isLoaded: true,
        isLoading: false
      };
    default:
      return state;
  }
};

export default kitPreferencesReducer;
