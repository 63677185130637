import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function PrintIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none"  strokeLinecap="round" strokeLinejoin="round"
          d="M3.61964 10.72H3C1.89543 10.72 1 9.82456 1 8.71999V7.83999C1 6.39999 1.48 4.95999 3.4 4.95999H10.6C12.52 4.95999 13 6.39999 13 7.83999V8.71999C13 9.82456 12.1046 10.72 11 10.72H10.3897"/>
    <path stroke={color || defaultColor} fill="none"  strokeLinecap="round" strokeLinejoin="round"
          d="M3.15997 2.12V4.96H10.84V2.12C10.84 1.56771 10.3923 1.12 9.83997 1.12H4.15997C3.60769 1.12 3.15997 1.56771 3.15997 2.12Z"/>
    <path stroke={color || defaultColor} fill="none"  strokeLinecap="round" strokeLinejoin="round"
          d="M9.77666 9.51999C10.2353 9.87295 10.4499 10.8898 10.5303 11.8811C10.5749 12.4316 10.1223 12.88 9.56999 12.88H4.42999C3.87771 12.88 3.42509 12.4316 3.46971 11.8811C3.55006 10.8898 3.76469 9.87295 4.22333 9.51999H9.77666Z"/>
  </SvgIcon>
}
