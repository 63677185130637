import React from 'react';

import LocationIcon from '../../assets/icons/LocationIcon';

import './hospitals.scss';

const HospitalAddressesInput = ({ onClick, addresses = [] }) => {
  return (
    <div
      className='addresses-input__container'
      style={{ alignItems: `${addresses && addresses.length > 1 ? 'flex-start' : 'center'}` }}
      onClick={onClick}
    >
      <div className='addresses-input__list'>
        {addresses.map((address) => (
          <div key={address.id} className={`m-t-sm m-b-sm${!!address.default ? ' font-bold' : ''}`}>
            {address.label}
          </div>
        ))}
      </div>
      <div
        className='addresses-input__label'
        style={{ marginTop: `${addresses && addresses.length > 1 ? '3px' : '0px'}` }}
      >
        <span>Edit</span>
        <LocationIcon color='secondary' />
      </div>
    </div>
  );
};

export default HospitalAddressesInput;
