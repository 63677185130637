import React from 'react';
import MomentUtils from '@date-io/moment';

import { MuiPickersUtilsProvider, DatePicker as MuiDatePicker, KeyboardDatePicker as MuiKeyboardDatePicker } from '@material-ui/pickers';
import CalendarIcon from '../../../assets/icons/CalendarIcon';

import './date-picker.scss';

const DatePicker = (props) => {
  const {
    date,
    onDateChange,
    disableFuture = false,
    disablePast = false,
    placeholder = 'Add Date',
    disabled = false,
    minDate = new Date('1990-01-01'),
    maxDate = new Date('2100-01-01'),
    withoutStyles = false,
    format= 'DD/MM/yyyy',
    keyboard = false,
  } = props;

  return (
   keyboard ? (
     <div className={`keyboard_date-picker__container${withoutStyles ? ' date-picker__without-styles' : ''}`}>
       <MuiPickersUtilsProvider utils={MomentUtils}>
         <MuiKeyboardDatePicker
           clearable
           value={date || null}
           placeholder={placeholder || 'Add date'}
           onChange={onDateChange}
           minDate={minDate}
           maxDate={maxDate}
           minDateMessage={null}
           format={format}
           disableFuture={disableFuture}
           disablePast={disablePast}
           emptyLabel={placeholder}
           disabled={disabled}
           KeyboardButtonProps={{
             'aria-label': 'change date',
           }}
           keyboardIcon={<CalendarIcon color="secondary" />}
           // error={null}
           helperText={null}
         />
       </MuiPickersUtilsProvider>
     </div>
   ) : (
     <div className={`date-picker__container${withoutStyles ? ' date-picker__without-styles' : ''}`}>
       <MuiPickersUtilsProvider utils={MomentUtils}>
         <MuiDatePicker
           clearable
           value={date || null}
           placeholder={placeholder || 'Add date'}
           onChange={onDateChange}
           minDate={minDate}
           maxDate={maxDate}
           minDateMessage={null}
           format={format}
           disableFuture={disableFuture}
           disablePast={disablePast}
           emptyLabel={placeholder}
           disabled={disabled}
         />
       </MuiPickersUtilsProvider>
       { !disabled && (
         <>
           <span>Change</span>
           <CalendarIcon color='secondary' />
         </>
       )}
     </div>
   )
  );
};

export default DatePicker;
