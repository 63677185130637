import React, { useMemo } from 'react';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import KitPreferenceIcon from '../../assets/icons/KitPreferenceIcon';

const KitPreferencesBreadcrumbs = ({ selectedPreference, selectedItem }) => {
  const breadcrumbs = useMemo(() => {
    const initial = { name: 'Kit Preferences' }

    // if (selectedItem) {
    //   return [initial, selectedPreference, selectedItem];
    // }

    if (selectedPreference) {
      return [initial, selectedPreference];
    }

    return [initial];
  }, [selectedPreference, selectedItem]);

  return (
    <div className='drive-breadcrumbs__container'>
      <KitPreferenceIcon className='m-r-md' style={{ fontSize: '24px' }} />
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize='small' />}
        aria-label='breadcrumb'
        maxItems={4}
      >
        {breadcrumbs.map((item) => (
          <div
            key={Math.random()}
            className='drive-breadcrumbs__item'
            style={{ cursor: 'default' }}
          >
            {item?.name || item?.title || item?.code || item?.kitId}
          </div>
        ))}
      </Breadcrumbs>
    </div>
  );
};

export default KitPreferencesBreadcrumbs;
