import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function ProductIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none"
          d="M3.2854 3.21802C3.33 2.76578 3.40876 2.28694 3.53654 1.80296C3.61689 1.49863 3.85407 1.26145 4.1584 1.1811C5.32917 0.872003 6.46983 0.849742 7.27659 0.903993C7.70555 0.932838 8.11852 1.06729 8.48077 1.29883C9.05271 1.66439 10.0107 2.35197 11.1039 3.44516C11.7725 4.11372 12.2893 4.73171 12.6736 5.24175C13.3119 6.08906 13.3474 7.18463 12.7102 8.03283C12.409 8.43384 12.0435 8.86948 11.6342 9.27879C11.159 9.75403 10.7436 10.1161 10.3882 10.392" />
    <path stroke={color || defaultColor} fill="none"
          d="M3.50251 11.0465C2.40931 9.95333 1.72173 8.99534 1.35617 8.4234C1.12463 8.06115 0.990181 7.64818 0.961335 7.21922C0.907084 6.41246 0.929345 5.27179 1.23845 4.10103C1.31879 3.7967 1.55598 3.55952 1.86031 3.47917C3.03107 3.17007 4.17174 3.14781 4.9785 3.20206C5.40746 3.2309 5.82042 3.36536 6.18268 3.59689C6.75462 3.96246 7.71261 4.65003 8.80581 5.74323C9.47436 6.41178 9.99121 7.02977 10.3755 7.53982C11.0138 8.38712 11.0493 9.4827 10.4121 10.3309C10.1109 10.7319 9.74545 11.1675 9.33614 11.5769C8.86664 12.0464 8.4556 12.4054 8.103 12.68C7.26597 13.3318 6.1464 13.2546 5.29909 12.6162C4.78905 12.2319 4.17106 11.7151 3.50251 11.0465Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M3.6438 5.88464L3.81239 6.05323" />
  </SvgIcon>
}
