import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import EditIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Check';
import DeleteIcon from '../../assets/icons/DeleteIcon';

import Checkbox from '../shared/Checkbox';

import { updateManufacturer, deleteManufacturer } from '../../actions/kitsActions';

const ManufacturerListItem = (props) => {
  const {
    checked,
    name,
    onChange,
    id,
  } = props;

  const dispatch = useDispatch();

  const [manufacturer, setManufacturer] = useState(name || '');
  const [editMode, toggleEditMode] = useState(false);

  const onSave = () => {
    dispatch(updateManufacturer(id, manufacturer));
    toggleEditMode(false);
  };

  return (
    <div className='md-user-info'>
      <div>
        {editMode ? (
          <input
            value={manufacturer}
            onChange={(e) => setManufacturer(e.target.value)}
            maxLength={20}
          />
        ) : (
          <Checkbox
            input={{
              onClick: onChange,
              checked,
            }}
            fontSize='small'
            label={name}
          />
        )}
      </div>
      <div className='d-flex'>
        {editMode ? (
          <div className='md-user-info__delete' onClick={onSave}>
            <SaveIcon fontSize='small' color='primary' />
          </div>
        ) : (
          <div className='md-user-info__delete' onClick={() => toggleEditMode(true)}>
            <EditIcon fontSize='small' color='primary' />
          </div>
        )}
        <div
          className='m-l-md md-user-info__delete'
          onClick={() => dispatch(deleteManufacturer(id))}
        >
          <DeleteIcon fontSize='small' color='#d32f2f' />
        </div>
      </div>
    </div>
  );
};

export default ManufacturerListItem;
