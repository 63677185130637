import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import GtinsTable from '../../../../components/scan/GtinTable';
import GtinFilters from '../../../../components/scan/GtinFilters';
import GtinForm from '../../../../components/scan/GtinForm';
import ConfirmUploadGtinsModal from '../../../../components/scan/ConfirmUploadGtinsModal';
import Input from '../../../../components/shared/Input';
import Modal, { ConfirmationModal, UploadModal } from '../../../../components/shared/modal';
import Alert from '../../../../components/shared/Alert';

import {
  getGtins,
  createGtin,
  updateGtin,
  subscribeToGtins,
  bulkDeleteGtins,
  uploadGtins,
} from '../../../../actions/gtinReferenceActions';

import { filterGtins } from '../../../../utils/table';

import { statusOptions } from '../../../../constants/enums';

const statusFilterOptions = [
  { label: 'Active', value: statusOptions.active },
  { label: 'Deactivated', value: statusOptions.deactivated }
];

const GtinsPage = (props) => {
  const {
    gtins,
    products,
    tenantColor,
    createGtin,
    updateGtin,
    subscribeToGtins,
    bulkDeleteGtins,
    uploadGtins,
    deletePermission,
  } = props;

  const [isModalCreateOpen, toggleModalCreate] = useState(false);
  const [isModalDeleteOpen, toggleModalDelete] = useState(false);
  const [isUploadModalOpen, toggleUploadModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [successMessage, setSuccessMessage] = useState(null);

  const [selectedGtin, setSelectedGtin] = useState(null);
  const [checkedGtins, setCheckedGtins] = useState([]);

  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [classificationFilter, setClassificationFilter] = useState('');

  const [confirmModalOpen, toggleConfirmModal] = useState(false);
  const [confirmModalItems, setConfirmModalItems] = useState([]);
  const [failedModalItems, setFailedModalItems] = useState([]);

  useEffect(() => {
    const unsubscribe = subscribeToGtins();

    return () => {
      unsubscribe();
    };
  }, []);

  const onSelectGtin = (gtin) => {
    setSelectedGtin(gtin);
  };

  // Create gtin
  const onCreateGtin = async (formObj) => {
    setLoading(true);
    const gtinObj = {
      gtin: formObj.gtin,
      reference: formObj.reference,
      description: formObj.description,
      classification: formObj.classification || '',
    };

    try {
      await createGtin(gtinObj);
      setLoading(false);
      toggleModalCreate(false);
      setSuccessMessage('GTIN reference has been successfully added');
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Update gtin
  const onUpdateGtin = async (formObj) => {
    setLoading(true);
    const gtinObj = {
      active: formObj.active,
      gtin: formObj.gtin,
      reference: formObj.reference,
      description: formObj.description,
    };

    try {
      await updateGtin(formObj.id, gtinObj);
      setLoading(false);
      setSuccessMessage('GTIN reference has been successfully updated');
      setSelectedGtin(formObj);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Delete checked gtins
  const onDeleteGtins = async () => {
    await bulkDeleteGtins(checkedGtins);

    toggleModalDelete(false);
    setCheckedGtins([]);
    setSelectedGtin(null);
  };

  const handleDeleteClick = () => {
    if (checkedGtins.length) {
      toggleModalDelete(true);
    }
  };

  // Upload items
  const onUpload = async (newGtins) => {
    setUploading(true);

    try {
      const successful = [];
      const failed = [];

      newGtins.forEach((item) => {
        if (gtins?.find((i) => item && item[0] === i.gtin)) {
          failed?.push(item);
        } else {
          successful?.push(item);
        }
      });

      if (!!failed?.length) {
        setUploading(false);
        toggleUploadModal(false);
        toggleConfirmModal(true);
        setConfirmModalItems(successful);
        setFailedModalItems(failed);
        return;
      }

      uploadGtins(successful);
      setUploading(false);
      toggleUploadModal(false);
      setSuccessMessage('GTIN reference has been successfully uploaded');
    } catch (err) {
      setUploading(false);
      console.error(err);
    }
  };

  const finishUpload = async () => {
    const length = confirmModalItems?.length;

    if (length > 0) {
      setUploading(true);

      try {
        await uploadGtins(confirmModalItems);
        setConfirmModalItems([]);
        setFailedModalItems([]);
        setUploading(false);
        toggleConfirmModal(false);
        setSuccessMessage('GTIN reference has been successfully uploaded');
      } catch (err) {
        setUploading(false);
        console.error(err);
      }
    } else {
      toggleConfirmModal(false);
      setConfirmModalItems([]);
      setFailedModalItems([]);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    // setCheckedGtins([]);
  };

  const gtinList = useMemo(() => {
    return gtins.filter((gtin) => filterGtins(gtin, search, statusFilter, classificationFilter));
  }, [gtins, search, statusFilter, classificationFilter]);

  const handleCheck = (gtinId) => {
    const checked = checkedGtins.slice();

    if (checked.includes(gtinId)) {
      const index = checked.indexOf(gtinId);

      checked.splice(index, 1);
      setCheckedGtins(checked);

      return;
    }

    checked.push(gtinId);
    setCheckedGtins(checked);
  };

  const handleCheckAll = () => {
    const checked = checkedGtins.length === gtinList.length;
    const temp = [];

    if (!checked) {
      gtinList.forEach((gtin) => {
        temp.push(gtin.id);
      });

      setCheckedGtins(temp);
      return;
    }

    setCheckedGtins(temp);
  };

  const classificationOptions = useMemo(() => {
    const uniqClassifications = [];
    gtins.forEach((gtin) => {
      if (!uniqClassifications.includes(gtin.classification) && gtin.classification) {
        uniqClassifications.push(gtin.classification);
      }
    });
    return uniqClassifications.map((item) => ({ label: item, value: item }));
  }, [gtins]);

  return (
    <div className='settings-cmp__main'>
      <span className='settings-title'>GTIN Reference</span>
      <div className='settings-cmp__body'>
        <div className='filters-container'>
          <GtinFilters
            onAddGtinClick={() => toggleModalCreate(true)}
            onDelete={handleDeleteClick}
            status={statusFilter}
            setStatus={setStatusFilter}
            statuses={statusFilterOptions}
            onUploadClick={() => toggleUploadModal(true)}
            classificationOptions={classificationOptions}
            classification={classificationFilter}
            setClassification={setClassificationFilter}
            deletePermission={deletePermission}
          />
          <GtinsTable
            onSelectGtin={onSelectGtin}
            gtins={gtinList}
            selectedGtinId={selectedGtin ? selectedGtin.id : null}
            handleCheck={handleCheck}
            checkedGtins={checkedGtins}
            handleCheckAll={handleCheckAll}
            products={products}
          />
        </div>
        { gtins && !!gtins.length && (
          <div className='form-container'>
            <Input
              type='search'
              placeholder='Search Gtins'
              value={search}
              onChange={handleSearch}
            />
            { selectedGtin && (
              <GtinForm
                gtins={gtins}
                initialValues={selectedGtin}
                buttonText='Save Changes'
                onSubmit={onUpdateGtin}
                loading={loading}
                mode='update'
                products={products}
              />
            )}
          </div>
        )}
      </div>

      <ConfirmationModal
        open={isModalDeleteOpen}
        onClose={() => toggleModalDelete(false)}
        onSubmit={onDeleteGtins}
        title='Are you sure you want to delete these GTINs?'
        submitText='Delete'
      />

      <Modal
        open={isModalCreateOpen}
        onClose={() => toggleModalCreate(false)}
      >
        <GtinForm
          gtins={gtins}
          buttonText='Add Gtin'
          onSubmit={onCreateGtin}
          loading={loading}
          mode='create'
          onClose={() => toggleModalCreate(false)}
        />
      </Modal>

      <UploadModal
        open={isUploadModalOpen}
        onClose={() => toggleUploadModal(false)}
        title='Upload GTIN Reference'
        tenantColor={tenantColor}
        onSubmit={onUpload}
        loading={loading}
        fields={['GTIN', 'Reference', 'Description']}
      />

      <ConfirmUploadGtinsModal
        open={!!confirmModalOpen}
        onClose={() => {
          toggleConfirmModal(false);
          setConfirmModalItems([]);
          setFailedModalItems([]);
        }}
        onSubmit={finishUpload}
        loading={uploading}
        failedItems={failedModalItems}
        gtins={gtins}
      />

      <Alert
        variant='success'
        message={successMessage}
        open={!!successMessage}
        onClose={() => setSuccessMessage(null)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    gtins: state.gtinReference.list,
    products: state.products.list,
    tenantColor: state.tenant.currentTenant.colorPrimary,
    deletePermission: state.user.currentUser?.deletePermission,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGtins: () => dispatch(getGtins()),
    createGtin: (gtin) => dispatch(createGtin(gtin)),
    updateGtin: (id, gtinData) => dispatch(updateGtin(id, gtinData)),
    subscribeToGtins: () => dispatch(subscribeToGtins()),
    bulkDeleteGtins: (gtinIds) => dispatch(bulkDeleteGtins(gtinIds)),
    uploadGtins: (gtins) => dispatch(uploadGtins(gtins)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GtinsPage);
