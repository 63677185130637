import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { updateTenant } from '../../../../actions/tenantActions';

import { CustomQRDecoding } from '../../../../components/scan';
import Alert from '../../../../components/shared/Alert';

import { useLoading, useAlert } from '../../../../hooks';

import { alertTypes } from '../../../../constants';

const CustomQRPage = () => {
  const dispatch = useDispatch();
  const tenant = useSelector((state) => state.tenant.currentTenant);

  const { loading, startLoading, stopLoading } = useLoading(false);
  const { showAlert } = useAlert();

  const [successMessage, setSuccessMessage] = useState(null);

  const onSubmit = async (config) => {
    if (!config) {
      return;
    }

    startLoading()

    try {
      await dispatch(updateTenant({ customDecodingConfig: config }));
      showAlert(alertTypes.SUCCESS, 'Custom QR config has been updated');
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  return (
    <div className='settings-cmp__main height-100'>
      <span className='settings-title'>Custom QR</span>
      <CustomQRDecoding
        initialConfig={tenant.customDecodingConfig}
        onSubmit={onSubmit}
        loading={loading}
      />
      <Alert
        variant='success'
        message={successMessage}
        open={!!successMessage}
        onClose={() => setSuccessMessage(null)}
      />
    </div>
  );
};

export default CustomQRPage;
