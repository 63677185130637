import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function DriveIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none"
          d="M2.78604 6.06741C2.77887 4.76387 2.83175 3.47464 2.94466 1.96008C2.9768 1.5289 3.28544 1.16611 3.71022 1.08547C4.76773 0.884695 5.83324 0.800049 7.15504 0.800049C8.47683 0.800049 9.54235 0.884695 10.5999 1.08546C11.0246 1.16611 11.3333 1.52891 11.3654 1.96008C11.5269 4.12667 11.5656 5.83215 11.4814 7.77802" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M6.7522 3.27917H9.04548" />
    <path stroke={color || defaultColor} fill="none"
          d="M0.82361 7.07775C0.946797 9.43694 1.57129 11.4951 2.03743 12.6366C2.18063 12.9872 2.5288 13.1999 2.90757 13.1999L11.5294 13.1999C11.9383 13.1999 12.3096 12.9521 12.4372 12.5636C12.8703 11.2445 13.074 9.83917 13.1543 8.87893C13.1966 8.37301 12.8181 7.94495 12.3131 7.89251C10.6408 7.71886 8.68925 7.57115 7.35332 7.58134C6.95045 7.58442 6.57943 7.36417 6.36389 7.02379C6.20674 6.77561 6.06401 6.5927 5.94933 6.46041C5.8306 6.32346 5.66828 6.23305 5.4904 6.19822C4.28635 5.9625 2.66753 6.02833 1.61256 6.14198C1.13863 6.19304 0.798754 6.60172 0.82361 7.07775Z" />
  </SvgIcon>
}
