import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import GroupsTable from '../../../components/groups/GroupsTable';
import GroupsFilters from '../../../components/groups/GroupsFilters';
import GroupForm from '../../../components/groups/GroupForm';
import Input from '../../../components/shared/Input';
import Modal, { ConfirmationModal } from '../../../components/shared/modal';
import Alert from '../../../components/shared/Alert';

import {
  getGroups,
  createGroup,
  updateGroup,
  subscribeToGroups,
  bulkDeleteGroups,
} from '../../../actions/groupsActions';

import { filterGroups } from '../../../utils/table';

import { statusOptions } from '../../../constants/enums';

const statusFilterOptions = [
  { label: 'Active', value: statusOptions.active },
  { label: 'Deactivated', value: statusOptions.deactivated }
];

const GroupsPage = (props) => {
  const {
    users,
    groups,
    createGroup,
    updateGroup,
    subscribeToGroups,
    bulkDeleteGroups,
    currentUser,
  } = props;

  const [isModalCreateOpen, toggleModalCreate] = useState(false);
  const [isModalDeleteOpen, toggleModalDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const [successMessage, setSuccessMessage] = useState(null);

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [checkedGroups, setCheckedGroups] = useState([]);

  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  useEffect(() => {
    const unsubscribe = subscribeToGroups();

    return () => {
      unsubscribe();
    };
  }, []);

  // Create group
  const onCreateGroup = async (formObj) => {
    setLoading(true);
    const groupObj = {
      name: formObj.name,
      description: formObj.description,
      groupOwner: formObj.groupOwner,
      groupManager: formObj.groupManager || null,
    };

    try {
      await createGroup(groupObj);
      setLoading(false);
      toggleModalCreate(false);
      setSuccessMessage('Group has been successfully created');
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Update group
  const onUpdateGroup = async (formObj) => {
    setLoading(true);
    const groupObj = {
      active: formObj.active,
      name: formObj.name,
      description: formObj.description,
      groupOwner: formObj.groupOwner,
      groupManager: formObj.groupManager || null,
      members: formObj.members || []
    };

    try {
      await updateGroup(formObj.id, groupObj);
      setLoading(false);
      setSuccessMessage('Group has been successfully updated');
      setSelectedGroup(formObj);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Delete checked groups
  const onDeleteGroups = async () => {
    await bulkDeleteGroups(checkedGroups);

    toggleModalDelete(false);
    setCheckedGroups([]);
    setSelectedGroup(null);
  };

  const handleDeleteClick = () => {
    if (checkedGroups.length) {
      toggleModalDelete(true);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    // setCheckedGroups([]);
  };

  const groupList = useMemo(() => {
    return groups.filter((group) => filterGroups(group, search, statusFilter));
  }, [groups, search, statusFilter]);

  const handleCheck = (groupId) => {
    const checked = checkedGroups.slice();

    if (checked.includes(groupId)) {
      const index = checked.indexOf(groupId);

      checked.splice(index, 1);
      setCheckedGroups(checked);

      return;
    }

    checked.push(groupId);
    setCheckedGroups(checked);
  };

  const handleCheckAll = () => {
    const checked = checkedGroups.length === groupList.length;
    const temp = [];

    if (!checked) {
      groupList.forEach((group) => {
        temp.push(group.id);
      });

      setCheckedGroups(temp);
      return;
    }

    setCheckedGroups(temp);
  };

  return (
    <div className='settings-cmp__main'>
      <span className='settings-title'>Groups</span>
      <div className='settings-cmp__body'>
        <div className='filters-container'>
          <GroupsFilters
            onAddGroupClick={() => toggleModalCreate(true)}
            onDelete={handleDeleteClick}
            status={statusFilter}
            setStatus={setStatusFilter}
            statuses={statusFilterOptions}
            deletePermission={currentUser?.deletePermission}
          />
          <GroupsTable
            onSelectGroup={setSelectedGroup}
            groups={groupList}
            selectedGroupId={selectedGroup ? selectedGroup.id : null}
            handleCheck={handleCheck}
            checkedGroups={checkedGroups}
            handleCheckAll={handleCheckAll}
          />
        </div>
        { groups && !!groups.length && (
          <div className='form-container'>
            <Input
              type='search'
              placeholder='Search Groups'
              value={search}
              onChange={handleSearch}
            />
            { selectedGroup && (
              <GroupForm
                initialValues={selectedGroup}
                buttonText='Save Changes'
                onSubmit={onUpdateGroup}
                users={users}
                loading={loading}
                mode='update'
              />
            )}
          </div>
        )}
      </div>

      <ConfirmationModal
        open={isModalDeleteOpen}
        onClose={() => toggleModalDelete(false)}
        onSubmit={onDeleteGroups}
        title="Are you sure you want to delete these groups?"
        submitText="Delete"
      />

      <Modal
        open={isModalCreateOpen}
        onClose={() => toggleModalCreate(false)}
      >
        <GroupForm
          buttonText='Add Group'
          onSubmit={onCreateGroup}
          loading={loading}
          users={users}
          mode='create'
          onClose={() => toggleModalCreate(false)}
          currentUser={currentUser}
        />
      </Modal>

      <Alert
        variant='success'
        message={successMessage}
        open={!!successMessage}
        onClose={() => setSuccessMessage(null)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentTenant: state.tenant.currentTenant,
    currentUser: state.user.currentUser,
    users: state.users.list,
    groups: state.groups.list
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGroups: () => dispatch(getGroups()),
    createGroup: (group) => dispatch(createGroup(group)),
    updateGroup: (id, groupData, members) => dispatch(updateGroup(id, groupData, members)),
    subscribeToGroups: () => dispatch(subscribeToGroups()),
    bulkDeleteGroups: (groupIds) => dispatch(bulkDeleteGroups(groupIds)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupsPage);
