import React, { useMemo } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import CircularProgress from '@material-ui/core/CircularProgress';

import Checkbox from '../../shared/Checkbox';
import SectionItemRow from './SectionItemRow';
import SectionNotesRow from './SectionNotesRow';

import { billOfMaterialItemTypes, itemTypeOptions } from '../../../constants/enums';

const SectionItemsList = (props) => {
  const { loading, items, onReorder, itemsList, onChange, onCheck, onCheckAll, checked, search, onEditNotesClick } = props;

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = [...items];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    onReorder(newItems);
  }

  const list = useMemo(() => {
    return items
      ?.filter((item) => item.type === billOfMaterialItemTypes.NOTES.value || itemsList?.find((i) => i.id === item.id))
      ?.map((item) => {
        if (item.type === billOfMaterialItemTypes.NOTES.value) {
          return item;
        } else {
          const _item = itemsList?.find((i) => i.id === item.id);
          return {
            ...item,
            code: _item?.code || '',
            description: _item?.checklistDescription || _item?.description || '',
            type: _item?.type ? itemTypeOptions[_item?.type]?.label : '',
            batchControl: !!_item.batchControl,
          };
        }
      })
      ?.filter((item) => item.type === billOfMaterialItemTypes.NOTES.value || !search.length || item?.code?.toLowerCase()?.includes(search?.toLowerCase()));
  }, [items, itemsList, search]);

  const onValuesChange = (index, ref, quantity) => {
    let temp = [...list];
    temp[index] = { ...temp[index], ref, quantity };

    onChange(temp);
  };

  return (
    <div className='drive-files-list__container'>
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        !!list?.length && (
          <table className='bom-items-table'>
            <thead>
            <tr>
              <th>
                <Checkbox
                  input={{
                    onClick: onCheckAll,
                    checked: items?.length === checked?.length
                  }}
                  fontSize='small'
                />
              </th>
              <th>Item Code</th>
              <th>Description</th>
              <th>Type</th>
              <th>Ref</th>
              <th>QTY</th>
              <th style={{ width: 50 }} />
              <th style={{ width: 50 }} />
              <th style={{ width: 24 }} />
            </tr>
            </thead>
            {
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='droppable'>
                  {(provided) => (
                    <tbody
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                    {list.map((item, index) => (
                      <Draggable key={`${item.id}_${Math.random() * Math.random()}`} draggableId={item.id} index={index}>
                        {(provided, snapshot) => (
                          item.type === billOfMaterialItemTypes.NOTES.value ? (
                            <SectionNotesRow
                              item={item}
                              provided={provided}
                              isDragging={snapshot?.isDragging}
                              onClick={() => onEditNotesClick(item)}
                            />
                          ) : (
                            <SectionItemRow
                              item={item}
                              provided={provided}
                              isDragging={snapshot?.isDragging}
                              onSave={(ref, quantity) => onValuesChange(index, ref, quantity)}
                              onCheck={() => onCheck(item.id)}
                              isChecked={checked?.includes(item.id)}
                            />
                          )
                        )}
                      </Draggable>
                    ))}
                    </tbody>)}
                </Droppable>
              </DragDropContext>
            }
          </table>
        )
      )}
    </div>
  );
};

export default SectionItemsList;
