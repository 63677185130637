import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function KitPreferenceIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path id="vector 2078" stroke={color || defaultColor} fill="none" stroke-linecap="round" stroke-linejoin="round"
          d="M6.732142857142858 3.801571428571429H3.224" stroke-width="1"></path>
    <path id="vector 2079" stroke={color || defaultColor} fill="none" stroke-linecap="round" stroke-linejoin="round"
          d="M9.866071428571429 6.5H3.224" stroke-width="1"></path>
    <path id="vector 2080" stroke={color || defaultColor} fill="none" stroke-linecap="round" stroke-linejoin="round"
          d="M6.732142857142858 9.3145H3.224" stroke-width="1"></path>
    <path id="Intersect" stroke={color || defaultColor} fill="none" stroke-linecap="round" stroke-linejoin="round"
          d="M0.8914285714285715 9.534571428571429a2.9082857142857144 2.9082857142857144 0 0 0 2.556357142857143 2.5628571428571427c0.9935714285714287 0.11142857142857143 2.0122142857142857 0.20614285714285716 3.0522142857142858 0.20614285714285716s2.0595714285714286 -0.09471428571428571 3.0522142857142858 -0.20614285714285716a2.9082857142857144 2.9082857142857144 0 0 0 2.556357142857143 -2.5628571428571427c0.10585714285714286 -0.9870714285714286 0.195 -2.0010714285714286 0.195 -3.0345714285714287s-0.08914285714285715 -2.0475000000000003 -0.195 -3.0355000000000003a2.9082857142857144 2.9082857142857144 0 0 0 -2.556357142857143 -2.5628571428571427C8.558642857142859 0.7920714285714285 7.539999999999999 0.6964285714285714 6.5 0.6964285714285714S4.440428571428572 0.7911428571428571 3.4477857142857147 0.9025714285714286A2.9082857142857144 2.9082857142857144 0 0 0 0.8914285714285715 3.4654285714285717C0.7855714285714286 4.4515714285714285 0.6964285714285714 5.465571428571429 0.6964285714285714 6.5s0.08914285714285715 2.0475000000000003 0.195 3.0345714285714287Z"
          stroke-width="1"></path>
  </SvgIcon>
}


