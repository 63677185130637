import React from 'react';

import AddUsageModal from './AddUsageModal';
import Modal from '../../shared/modal';

import './modal.scss';

const CaseUsageModal = (props) => {
  const {
    open,
    onClose,
    loading,
    onSubmit,
    defaultList,
    tenantColor,
  } = props;

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Add Usage'
      fullWidth
      size='lg'
    >
      <AddUsageModal
        onClose={onClose}
        onSubmit={onSubmit}
        loading={loading}
        defaultList={defaultList}
        open={open}
        tenantColor={tenantColor}
      />
    </Modal>
  );
};

export default CaseUsageModal;
