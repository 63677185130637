import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import ExportIcon from '../../../assets/icons/DownloadIcon';
import ItemIcon from '../../../assets/icons/ItemIcon';

import Table from '../../shared/table/Table';
import ActionButton from '../../shared/ActionButton';

import { exportExpiringItems } from '../../../actions/setsActions';

import { dateUtils } from '../../../utils';

import { setAllocationStatuses, setPositionOptions } from '../../../constants/enums';

const columns = [
  { title: 'item code', field: 'code', type: 'default', sort: true },
  { title: 'item description', field: 'description', type: 'default', sort: true },
  { title: 'lot/serial #', field: 'lot', type: 'default', sort: true },
  { title: 'expiry date', field: 'expDate', type: 'date', sort: true },
  { title: 'kit name', field: 'kitName', type: 'default', sort: true },
  { title: 'set number', field: 'setNumber', type: 'default', sort: true },
  {
    title: 'set status',
    field: 'setStatus',
    type: 'custom',
    sort: true,
    formatter: (value) => {
      if (value) {
        return <span style={{ color: setPositionOptions[value]?.color || '#000000' }}>{setPositionOptions[value]?.label}</span>
      }
    }
  },
];

const ExpiringItemsTable = (props) => {
  const { data = [] } = props;

  const dispatch = useDispatch();

  const [exportLoading, setExportLoading] = useState(false);

  const onExportClick = async () => {
    const body = data?.map((item) => ({
      ...item,
      setStatus: setPositionOptions[item?.setStatus]?.label || '',
      expDate: item?.expDate ? dateUtils.formatDate(item.expDate) : ''
    }));

    setExportLoading(true);
    await dispatch(exportExpiringItems(body));
    setExportLoading(false);
  };

  console.log(data);

  return (
    <div className='cases-table__container'>
      <div className='d-flex m-b-lg space-between align-start'>
        <div className='d-flex'>
          <ItemIcon />
          <div className='font-size-bg font-bold m-l-md'>
            Items
          </div>
        </div>

        <ActionButton
          onClick={onExportClick}
          bgColor={setAllocationStatuses.SHIPPED.color}
          disabled={exportLoading}
          loading={exportLoading}
        >
          <ExportIcon color="#ffffff" />
          Export
        </ActionButton>
      </div>

      <Table
        rows={data}
        columns={columns}
        pagination
        checkboxes={false}
        withoutCheck
        rowColor='#ffffff'
        rowBorderColor='#fafafa'
      />
    </div>
  );
};

export default ExpiringItemsTable;
