import React, { useState, useEffect, useMemo } from 'react';

import Checkbox from '../Checkbox';
import Button from '../Button';
import Modal from '../modal/Modal';

import userRoles from '../../../constants/userRoles';

import './modal-select.scss';

const RolesModal = (props) => {
  const {
    roles,
    onChange,
    selected,
    onClose,
    isOpen,
    singleSelect = false,
  } = props;

  const [selectedRoles, setSelectedRoles] = useState(selected || []);

  useEffect(() => {
    setSelectedRoles(selected || []);
  }, [selected]);

  const handleClick = (role) => {
    if (singleSelect) {
      if (selectedRoles.includes(role)) {
        return setSelectedRoles([]);
      }
      return setSelectedRoles([role]);
    }

    const temp = selectedRoles.slice();
    if (temp.includes(role)) {
      const index = temp.indexOf(role);

      temp.splice(index, 1);
      setSelectedRoles(temp);
      return;
    }

    temp.push(role);
    setSelectedRoles(temp);
  };

  const list = useMemo(() => {
    return roles;
  }, [roles]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <div className='form__container'>
        <div className='form__body'>

          <div className='form__subtitle'>
            Allowed Roles
          </div>

          <div className='m-b-lg form__select-users'>
            {
              list.map((role) => (
                <div className='md-user-info' key={role.value}>
                  <Checkbox
                    input={{
                      onClick: () => handleClick(role.value),
                      checked: selectedRoles.indexOf(role.value) !== -1,
                    }}
                    fontSize='small'
                    label={role.label}
                    disabled={role.value === userRoles.ADMIN.name}
                  />
                </div>
              ))
            }
          </div>

          <div className='form__actions'>
            <Button
              type='submit'
              text='Submit'
              onClick={() => {
                onChange(selectedRoles);
                onClose();
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RolesModal;
