import React, { useState, useMemo } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import NotesIcon from '../../assets/icons/NotesIcon';
import EditIcon from '../../assets/icons/EditIcon';
import DeleteIcon from '../../assets/icons/DeleteIcon';

import { useLoading } from '../../hooks';

import AddButton from '../shared/AddButton';
import Modal from '../shared/modal';
import { ConfirmationModal } from '../shared/modal';
import NoteForm from '../customers/forms/NoteForm';

import { createCaseNote, updateCaseNote, deleteCaseNote } from '../../actions/casesActions';
import userRoles from '../../constants/userRoles';

const CaseNotes = (props) => {
  const {
    notes = [],
    preview,
    onPreviewClick,
    caseId,
    connectedTenantId,
    tenantColor,
    userId,
    userRole,
  } = props;

  const dispatch = useDispatch();

  const [isModalOpen, toggleModal] = useState(false);
  const [isEditModalOpen, toggleEditModal] = useState(false);
  const [isDeleteModalOpen, toggleDeleteModal] = useState(false);

  const [selectedNote, setSelectedNote] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const { loading, startLoading, stopLoading } = useLoading(false);

  const onSubmit = (formObj) => {
    const noteData = {
      note: formObj.note,
    };

    startLoading();

    try {
      dispatch(createCaseNote(caseId, noteData, connectedTenantId));
      toggleModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onEditClick = (note) => {
    setSelectedNote(note);
    toggleEditModal(true);
  }

  const onUpdate = async (formObj) => {
    if (selectedNote && selectedNote.id) {
      const noteData = {
        note: formObj.note,
      };

      startLoading();

      try {
        dispatch(updateCaseNote(caseId, selectedNote.id, noteData, connectedTenantId));
        toggleEditModal(false);
      } catch (err) {
        console.error(err);
      } finally {
        stopLoading();
      }
    }
  };

  const onDeleteClick = (note) => {
    setSelectedNote(note);
    toggleDeleteModal(true);
  }

  const onDelete = async () => {
    if (selectedNote && selectedNote.id) {
      try {
        setDeleting(true);
        await dispatch(deleteCaseNote(caseId, selectedNote.id, connectedTenantId));
        toggleDeleteModal(false);
      } catch (err) {
        console.error(err);
      } finally {
        setDeleting(false);
      }
    }
  };

  const lastNote = useMemo(() => notes[0], [notes]);

  return (
    <div className='case-notes__container'>
      <div className='d-flex space-between'>
        <div className='d-flex'>
          <NotesIcon />
          <div className='font-size-bg font-bold m-l-md'>
            Notes
          </div>
        </div>

        <AddButton onClick={() => toggleModal(true)} />
      </div>

      { preview ? (
        <div className='d-flex direction-column space-between height-100' style={{ alignItems: 'normal' }}>
          <div className='m-t-md'>
            <>
              <div
                className='case-notes-list__container pointer m-t-lg'
                onClick={onPreviewClick}
              >
                <div className='case-note' style={{ minHeight: 50 }}>
                  {lastNote && (
                    <>
                      <div className='d-flex space-between'>
                        <div className='font-bold'>{lastNote?.authorFullName}</div>
                        <div className='secondary'>{moment(lastNote?.createdAt)?.fromNow()}</div>
                      </div>
                      <div className='m-t-md'>
                        {lastNote?.note}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          </div>
          <div
            style={{ color: tenantColor, textDecoration: 'underline' }}
            className="pointer font-bold font-size-sm"
            onClick={onPreviewClick}
          >
            View All Notes
          </div>
        </div>
      ) : (
        <div className='case-notes-list__container m-t-lg'>
          {notes && notes.map((note) => (
            <div key={note.id} className='case-note'>
              <div className='d-flex space-between'>
                <div className='font-bold'>{note.authorFullName}</div>
                <div className='d-flex'>
                  <div className='secondary'>{moment(note.createdAt).fromNow()}</div>
                  {(userRole === userRoles.ADMIN.name || userId === note.authorId) && (
                    <div className='case-note-actions'>
                      <div className='pointer' onClick={() => onEditClick(note)}>
                        <EditIcon color={tenantColor} />
                      </div>
                      <div className='pointer m-l-md' onClick={() => onDeleteClick(note)}>
                        <DeleteIcon color="#F53434" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='m-t-md'>
                {note.note}
              </div>
            </div>
          ))}
        </div>
      )}

      <Modal
        open={isModalOpen}
        onClose={() => toggleModal(false)}
      >
        <NoteForm
          loading={loading}
          onSubmit={onSubmit}
          onClose={() => toggleModal(false)}
        />
      </Modal>

      {!!selectedNote && (
        <Modal
          open={isEditModalOpen}
          onClose={() => {
            toggleEditModal(false);
            setSelectedNote(null);
          }}
        >
          <NoteForm
            mode='update'
            initialValues={selectedNote}
            loading={loading}
            onSubmit={onUpdate}
            onClose={() => toggleEditModal(false)}
          />
        </Modal>
      )}

      <ConfirmationModal
        open={isDeleteModalOpen}
        onClose={() => {
          toggleDeleteModal(false);
          setSelectedNote(null);
        }}
        onSubmit={onDelete}
        title='Are you sure you want to delete this note?'
        submitText='Delete'
        loading={deleting}
      />
    </div>
  );
};

export default CaseNotes;
