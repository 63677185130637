import React from 'react';
import moment from 'moment';

import CircularProgress from '@material-ui/core/CircularProgress';

import Modal from '../../shared/modal';
import LinkIcon from '../../../assets/icons/LinkIcon';

const CasePreferencesModal = ({ open, onClose, preferences = [], fetching }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Surgeon Preference'
      fullWidth
    >
      <div className='case-preferences-modal__container'>
        { fetching ? (
          <div className='d-flex flex-center p-b-lg'>
            <CircularProgress />
          </div>
        ) : (
          <div className='case-preferences-list__container'>
            { preferences.map((preference) => (
              <div key={preference.id} className='case-preference'>
                <div className='d-flex space-between'>
                  <div className='font-bold'>{preference.authorFullName}</div>
                  <div className='secondary'>{moment(preference.createdAt).fromNow()}</div>
                </div>
                <div className='m-t-sm'>
                  {preference.preference}
                </div>
                <div className='d-flex'>
                  {preference.images && !!preference.images.length && (
                    <div className='d-flex m-t-md'>
                      {preference.images?.map((image) => (
                        <div
                          key={`${preference.id}_${image.fileName}`}
                          style={{ width: 50, height: 50, borderRadius: 8 }}
                          className='m-r-lg pointer'
                          onClick={() => window.open(image.downloadUrl, '_blank')}
                        >
                          <img
                            src={image.downloadUrl}
                            alt='preference-preview'
                            style={{ width: 50, height: 50, borderRadius: 8 }}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                  {preference.links && !!preference.links.length && (
                    <div className='d-flex m-t-md'>
                      {preference.links?.map((url) => (
                        <div
                          key={`${preference.id}_${url}`}
                          style={{ width: 50, height: 50, borderRadius: 8, border: '1px solid #eee' }}
                          className='m-r-lg pointer d-flex flex-center'
                          onClick={(e) => {
                            e.stopPropagation();
                            window.open(url, '_blank');
                          }}
                        >
                          <LinkIcon />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CasePreferencesModal;
