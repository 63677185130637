import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import CaseShippingInfoModal from './CaseShippingInfoModal';

import { getCase, simpleUpdateCase } from '../../../actions/casesActions';

import InfoIcon from '../../../assets/icons/InfoIcon';
import CheckIcon from '../../../assets/icons/CheckIcon';
import ModalSelectInput from '../../shared/modal-select/ModalSelectInput';

import { fromMomentToTimestampDay } from '../../../utils/date';
import Input from '../../shared/Input';

const CaseShippingInfo = ({ activeCase, tenantColor, editable }) => {
  const dispatch = useDispatch();
  const { collectionDate, collectionNote = '', id } = activeCase;

  const [open, toggleModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (date, notes) => {
    if (date) {
      try {
        setLoading(true);
        const doc = { collectionDate: fromMomentToTimestampDay(date), collectionNote: notes || '' };
        await dispatch(simpleUpdateCase(id, doc));
        await dispatch(getCase(id));
        toggleModal(false);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className='case-notes__container'>
      <div className='d-flex m-b-lg space-between'>
        <div className='d-flex'>
          <InfoIcon />
          <div className='font-size-bg font-bold m-l-md'>
            Shipping Info
          </div>
        </div>

        {!!collectionDate && (
          <div className='d-flex'>
            <CheckIcon color="#4AB026" />
            <div className='font-size-bg font-bold m-l-md' style={{ color: "#4AB026" }}>
              Collection Scheduled
            </div>
          </div>
        )}
      </div>

      {collectionDate ? (
        <div>
          <ModalSelectInput
            onClick={() => {
              if (editable) {
                toggleModal(true)
              }
            }}
            label='Edit Date'
            icon='date'
            values={[`Collection on ${moment(collectionDate).format('MMM Do YYYY')}`]}
          />
        </div>
      ) : (
        <div className='modal-select align-center'>
          <div className='font-bold'>Collection Date</div>
          {editable && (
            <div className='font-bold pointer' style={{ color: tenantColor }} onClick={() => toggleModal(true)}>Confirm Date</div>
          )}
        </div>
      )}

      {!!collectionNote && (
        <div className='m-t-lg'>
          <div className='field__title'>Collection Note</div>
          <Input
            value={collectionNote}
            type='textarea'
            disabled
          />
        </div>
      )}

      <CaseShippingInfoModal
        open={open}
        onClose={() => toggleModal(false)}
        onSubmit={onSubmit}
        loading={loading}
        initialValues={collectionDate ? { collectionDate, collectionNote } : null}
      />
    </div>
  );
};

export default CaseShippingInfo;
