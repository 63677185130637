import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function ProcedureIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none"
          d="M9.57154 6.97534C9.11377 6.93389 8.78455 6.53665 8.77069 6.07721C8.76217 5.79463 8.75526 5.51449 8.74996 5.23585C8.46793 5.23053 8.18437 5.22356 7.89831 5.21493C7.4389 5.20107 7.04166 4.87188 7.0002 4.41413C6.9673 4.05084 6.9673 3.69535 7.0002 3.33206C7.04166 2.87431 7.4389 2.54512 7.89831 2.53126C8.18437 2.52263 8.46793 2.51566 8.74995 2.51034C8.75525 2.23151 8.76216 1.95117 8.77069 1.66839C8.78455 1.20896 9.11377 0.811713 9.57154 0.77027C9.9348 0.737384 10.2903 0.737384 10.6535 0.770271C11.1113 0.811713 11.4405 1.20896 11.4544 1.66839C11.4629 1.95128 11.4698 2.23174 11.4751 2.51067C11.7513 2.51595 12.029 2.52281 12.3091 2.53126C12.7685 2.54512 13.1657 2.87431 13.2072 3.33206C13.2401 3.69535 13.2401 4.05084 13.2072 4.41413C13.1657 4.87188 12.7685 5.20107 12.3091 5.21493C12.029 5.22338 11.7513 5.23024 11.4751 5.23552C11.4698 5.51426 11.4629 5.79452 11.4544 6.07721C11.4405 6.53665 11.1113 6.93389 10.6535 6.97534C10.2903 7.00822 9.9348 7.00822 9.57154 6.97534Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M1.06623 6.36564L1.76468 6.32242C2.58747 6.27151 3.41318 6.34808 4.21258 6.54942L5.37863 6.8431C6.01684 7.00384 6.4403 7.60871 6.37296 8.2634V8.2634C6.29412 9.0298 5.58046 9.56754 4.82203 9.43203L3.71353 9.23397L7.62192 9.93485L11.6702 8.97403C12.325 8.81861 12.9845 9.21319 13.157 9.86371V9.86371C13.3174 10.4679 13.0035 11.0972 12.4243 11.3325L9.18612 12.6481C7.61094 13.2881 5.85573 13.3282 4.25298 12.7608L0.895691 11.5722" />
  </SvgIcon>
}
