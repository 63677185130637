import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function OverviewIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M0.75 4L13.25 4" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M4.41406 4L4.41406 13.1238" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M4.41406 8.5H13.25" />
    <path stroke={color || defaultColor} fill="none" strokeLinejoin="round"
          d="M0.75 9.99565C0.75 11.5558 1.94389 12.875 3.49591 13.034C4.6365 13.1508 5.80699 13.25 7 13.25C8.19301 13.25 9.3635 13.1508 10.5041 13.034C12.0561 12.875 13.25 11.5558 13.25 9.99565V4.00435C13.25 2.44421 12.0561 1.12498 10.5041 0.966028C9.3635 0.849214 8.19301 0.75 7 0.75C5.80699 0.75 4.6365 0.849214 3.49591 0.966028C1.94389 1.12498 0.75 2.44421 0.75 4.00435L0.75 9.99565Z" />
  </SvgIcon>
}
