import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import ProductsTable from '../../../components/products/ProductsTable';
import ProductsFilters from '../../../components/products/ProductsFilters';
import ProductForm from '../../../components/products/ProductForm';
import Input from '../../../components/shared/Input';
import Modal, { ConfirmationModal, UploadModal } from '../../../components/shared/modal';
import Alert from '../../../components/shared/Alert';

import {
  getProducts,
  createProduct,
  updateProduct,
  subscribeToProducts,
  bulkDeleteProducts,
  uploadProducts,
} from '../../../actions/productsActions';
import { getProcedures } from '../../../actions/proceduresActions';

import { filterProducts } from '../../../utils/table';

import { statusOptions } from '../../../constants/enums';

const statusFilterOptions = [
  { label: 'Active', value: statusOptions.active },
  { label: 'Deactivated', value: statusOptions.deactivated }
];

const ProductsPage = (props) => {
  const {
    products,
    procedures,
    getProcedures,
    createProduct,
    updateProduct,
    subscribeToProducts,
    bulkDeleteProducts,
    uploadProducts,
    tenantColor,
    deletePermission,
  } = props;

  const [isModalCreateOpen, toggleModalCreate] = useState(false);
  const [isModalDeleteOpen, toggleModalDelete] = useState(false);
  const [isUploadModalOpen, toggleUploadModal] = useState(false);
  const [isModalDuplicateOpen, toggleModalDuplicate] = useState(false);
  const [loading, setLoading] = useState(false);

  const [successMessage, setSuccessMessage] = useState(null);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [checkedProducts, setCheckedProducts] = useState([]);

  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  useEffect(() => {
    const unsubscribe = subscribeToProducts();

    onLoad().catch((err) => console.error(err));

    return () => {
      unsubscribe();
    };
  }, []);

  const onLoad = async () => {
    await getProcedures();
  };

  const onSelectProduct = (product) => {
    setSelectedProduct(product);
  };

  // Create product
  const onCreateProduct = async (formObj) => {
    setLoading(true);
    const productObj = {
      name: formObj.name,
      description: formObj.description,
      procedures: formObj.procedures || [],
    };

    try {
      await createProduct(productObj);
      setLoading(false);
      toggleModalCreate(false);
      setSuccessMessage('Product has been successfully created');
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Update product
  const onUpdateProduct = async (formObj) => {
    setLoading(true);
    const productObj = {
      active: formObj.active,
      name: formObj.name,
      description: formObj.description,
      procedures: formObj.procedures || [],
    };

    try {
      await updateProduct(formObj.id, productObj);
      setLoading(false);
      setSuccessMessage('Product has been successfully updated');
      setSelectedProduct(formObj);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Delete checked products
  const onDeleteProducts = async () => {
    await bulkDeleteProducts(checkedProducts);

    toggleModalDelete(false);
    setCheckedProducts([]);
    setSelectedProduct(null);
  };

  const handleDeleteClick = () => {
    if (checkedProducts.length) {
      toggleModalDelete(true);
    }
  };

  // Upload products
  const onUpload = async (products) => {
    setLoading(true);

    try {
      await uploadProducts(products);
      setLoading(false);
      setSuccessMessage('Products have been successfully uploaded');
      toggleUploadModal(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Duplicate product
  const onDuplicateClick = async () => {
    if (!checkedProducts || checkedProducts.length !== 1) {
      return;
    }

    const productId = checkedProducts[0];
    const product = products.find((p) => p.id === productId);
    setSelectedProduct(product);
    toggleModalDuplicate(true);
  };

  const onDuplicateProduct = async (formObj) => {
    setLoading(true);
    const productObj = {
      name: formObj.name,
      description: formObj.description,
      procedures: formObj.procedures || [],
    };

    try {
      await createProduct(productObj);
      setLoading(false);
      toggleModalDuplicate(false);
      setSuccessMessage('Product has been successfully duplicated');
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    // setCheckedProducts([]);
  };

  const productList = useMemo(() => {
    return products.filter((product) => filterProducts(product, search, statusFilter));
  }, [products, search, statusFilter]);

  const handleCheck = (productId) => {
    const checked = checkedProducts.slice();

    if (checked.includes(productId)) {
      const index = checked.indexOf(productId);

      checked.splice(index, 1);
      setCheckedProducts(checked);

      return;
    }

    checked.push(productId);
    setCheckedProducts(checked);
  }

  const handleCheckAll = () => {
    const checked = checkedProducts.length === productList.length;
    const temp = [];

    if (!checked) {
      productList.forEach((product) => {
        temp.push(product.id);
      });

      setCheckedProducts(temp);
      return;
    }

    setCheckedProducts(temp);
  };

  return (
    <div className='settings-cmp__main'>
      <span className='settings-title'>Brands</span>
      <div className='settings-cmp__body'>
        <div className='filters-container'>
          <ProductsFilters
            onAddProductClick={() => toggleModalCreate(true)}
            onDelete={handleDeleteClick}
            status={statusFilter}
            setStatus={setStatusFilter}
            statuses={statusFilterOptions}
            onUploadClick={() => toggleUploadModal(true)}
            onDuplicateClick={onDuplicateClick}
            deletePermission={deletePermission}
          />
          <ProductsTable
            onSelectProduct={onSelectProduct}
            products={productList}
            selectedProductId={selectedProduct ? selectedProduct.id : null}
            handleCheck={handleCheck}
            checkedProducts={checkedProducts}
            handleCheckAll={handleCheckAll}
            procedures={procedures}
          />
        </div>
        { products && !!products.length && (
          <div className='form-container'>
            <Input
              type='search'
              placeholder='Search Brands'
              value={search}
              onChange={handleSearch}
            />
            { selectedProduct && (
              <ProductForm
                initialValues={selectedProduct}
                buttonText='Save Changes'
                onSubmit={onUpdateProduct}
                loading={loading}
                mode='update'
                procedures={procedures}
              />
            )}
          </div>
        )}
      </div>

      <ConfirmationModal
        open={isModalDeleteOpen}
        onClose={() => toggleModalDelete(false)}
        onSubmit={onDeleteProducts}
        title='Are you sure you want to delete these products?'
        submitText='Delete'
      />

      <Modal
        open={isModalCreateOpen}
        onClose={() => toggleModalCreate(false)}
      >
        <ProductForm
          buttonText='Add Brand'
          onSubmit={onCreateProduct}
          loading={loading}
          mode='create'
          onClose={() => toggleModalCreate(false)}
        />
      </Modal>

      <Modal
        open={isModalDuplicateOpen}
        onClose={() => toggleModalDuplicate(false)}
      >
        <ProductForm
          initialValues={selectedProduct}
          buttonText='Duplicate'
          onSubmit={onDuplicateProduct}
          loading={loading}
          mode='create'
          onClose={() => toggleModalDuplicate(false)}
        />
      </Modal>

      <UploadModal
        open={isUploadModalOpen}
        onClose={() => toggleUploadModal(false)}
        title='Upload Brands'
        tenantColor={tenantColor}
        onSubmit={onUpload}
        loading={loading}
        fields={['Name', 'Description']}
      />

      <Alert
        variant='success'
        message={successMessage}
        open={!!successMessage}
        onClose={() => setSuccessMessage(null)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    products: state.products.list,
    procedures: state.procedures.list,
    tenantColor: state.tenant.currentTenant.colorPrimary,
    deletePermission: state.user.currentUser?.deletePermission,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProducts: () => dispatch(getProducts()),
    getProcedures: () => dispatch(getProcedures()),
    createProduct: (product) => dispatch(createProduct(product)),
    updateProduct: (id, productData) => dispatch(updateProduct(id, productData)),
    subscribeToProducts: () => dispatch(subscribeToProducts()),
    bulkDeleteProducts: (productIds) => dispatch(bulkDeleteProducts(productIds)),
    uploadProducts: (products) => dispatch(uploadProducts(products)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPage);
