import React from 'react';

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    background: '#ffffff',
    border: '1px solid #EEEEEE',
    borderRadius: '16px',
    outline: 'none',
    minWidth: 120,
    height: '60px',
    '&:active': {
      outline: 'none',
      borderRadius: '16px',
    },
    '&:focus': {
      outline: 'none !important',
      borderRadius: '16px',
    },
    '&:before': {
      outline: 'none',
      borderRadius: '16px',
    }
  },
  input: {
    width: '100%',
    height: '100%',
    outline: 'none',
    fontWeight: 'bold',
    fontFamily: 'Inter, Montserrat, sans-serif',
    '&:active': {
      outline: 'none',
      //borderRadius: '16px',
    },
    '&:focus': {
      outline: 'none !important',
      //borderRadius: '16px',
    },
    '&:before': {
      outline: 'none',
      //borderRadius: '16px',
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const CustomInput = withStyles((theme) => ({
  // root: {
  //   'label + &': {
  //     marginTop: theme.spacing(3),
  //   },
  // },
  input: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    height: '60px',
    borderRadius: 4,
    position: 'relative',
    top: -5,
    paddingLeft: 15,
    outline: 'none',
    //backgroundColor: theme.palette.background.paper,
    border: 'none',
    // padding: '10px 26px 10px 12px',
    //transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Inter',
      'Montserrat',
      'sans-serif',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      boxShadow: 'none',
      outline: 'none'
    },
    '&:active': {
      outline: 'none',
      borderRadius: '16px',
      boxShadow: 'none',
    },
    '&:before': {
      borderRadius: '16px',
      boxShadow: 'none',
      outline: 'none'
    },
    '&.MuiSelect-selectMenu': {
      whiteSpace: 'normal !important'
    }
  },
}))(InputBase);

const StyledSelect = withStyles((theme) => ({
  root: {
    // border: '1px solid #eeeeee',
    paddingLeft: '16px !important',
  },
  icon: {
    color: '#c6c8c9',
    top: 'calc(50% - 18px)',
    right: '7px'
  },
  filled: {
    fontWeight: 'bold !important',
    padding: '20px 12px',
    transition: 'none !important',
    background: 'none !important',
    outline: 'none',
    '&:active': {
      outline: 'none',
      borderRadius: '16px',
    },
    '&:focus': {
      outline: 'none !important',
      borderRadius: '16px',
    },
    '&:before': {
      outline: 'none',
      borderRadius: '16px',
    }
  },
  selectMenu: {
    borderRadius: '16px'
  },
}))(Select);

const StyledMenuItem = withStyles((theme) => ({
  root: {
    paddingTop: '12px',
    paddingBottom: '12px',
    width: props => props?.width ? props.width : '500px'
  }
}))(MenuItem);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiSelect = ({ value, onChange, options, name, placeholder = '', disabled }) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <StyledSelect
        labelId='demo-mutiple-chip-label'
        id='demo-mutiple-chip'
        name={name}
        multiple
        value={value}
        onChange={(e) => onChange(e.target.value)}
        displayEmpty
        disabled={disabled}
        input={<CustomInput id='select-multiple-chip' />}
        renderValue={(selected) => {
          const labels = selected.map((value) => {
            const option = options.find((option) => option.value === value);
            return option ? option.label : '';
          })?.filter((value) => !!value);

          if (selected.length === 0) {
            return <span style={{ fontWeight: 'normal', color: '#999999' }}>{placeholder}</span>;
          }

          if (selected.length === options.length) {
            return 'All';
          }

          return labels.join(', ');
        }}
        MenuProps={MenuProps}
        IconComponent={ArrowIcon}
      >
        {options.map(({ label, value }) => (
          <StyledMenuItem key={value} value={value}>
            {label}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default MultiSelect;
