import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';

import Button from '../shared/Button';
import Input from '../shared/Input';
import ModalSelectInput from '../shared/modal-select/ModalSelectInput';
import ModalSelect from '../shared/modal-select/ModalSelect';
import FileUpload from '../shared/FileUpload';
import Checkbox from '../shared/Checkbox';
import MultiSelect from '../shared/MultiSelect';
import userRoles from '../../constants/userRoles';

import '../../styles/shared/form.scss';
import { validateEmail } from '../../utils/validators';
import DeleteIcon from '../../assets/icons/DeleteIcon';

const roleOptions = Object.values(userRoles)
  ?.filter((role) => ![userRoles.ADMIN.name, userRoles.SALES_REP.name, userRoles.SURGEON.name, userRoles.EXTERNAL_SALES_REP.name, userRoles.PRACTICE_MANAGER.name]?.includes(role.name))
  ?.map(({ label, name }) => ({ label, value: name }));

const FormForm = (props) => {
  const {
    mode = 'create',
    initialValues,
    buttonText,
    onSubmit,
    loading,
    onClose,
    products = [],
  } = props;

  const { name } = initialValues || {};

  const [isModalOpen, toggleModal] = useState(false);
  const [logo, setLogo] = useState(null);

  const [newEmail, setNewEmail] = useState('');
  const [emails, setEmails] = useState([]);
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    if (initialValues && initialValues.notificationEmails) {
      setEmails(initialValues.notificationEmails);
    }
  }, [initialValues]);

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Required';
    }

    return errors;
  };

  const onDeleteEmail = (email) => {
    const filterEmails = emails.filter((item) => item !== email);
    setEmails(filterEmails);
  }

  const onAddNewEmail = () => {
    const value = newEmail?.trim();
    if (value && !emails.includes(value) && validateEmail(value)) {
      setEmails([...emails, value]);
      setNewEmail('');
    } else {
      setEmailError(true);
    }
  };

  const getProductNames = (productIds) => {
    return productIds.map((id) => {
      const product = products.find((item) => item.id === id);
      return product ? product.name : '';
    })
  };

  return (
    <div className='form__container'>
      <Form
        onSubmit={(formObj) => onSubmit(formObj, mode, logo, emails)}
        validate={validate}
        initialValues={{
          ...initialValues,
          products: initialValues?.products || []
        }}
        subscription={{ invalid: true, values: true }}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine, values }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <div className='form__subtitle'>
                { name ? name : 'Add Form' }
              </div>
              { mode === 'update' && (
                <Field name='active'>
                  {({ input, meta }) => (
                    <Input
                      type='switch'
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      placeholder='Status'
                    />
                  )}
                </Field>
              )}

              <div className='m-b-sm'>
                <div className='field__title'>Name</div>
                <Field name='name'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      placeholder='Name'
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              <div>
                <div className='field__title'>Subtitle</div>
                <Field name='subtitle'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      placeholder=''
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              {mode === 'update' && (
                <Field name='allProducts' type='checkbox'>
                  {({ input }) => (
                    <div className='m-b-md'>
                      <Checkbox
                        input={input}
                        fontSize='small'
                        label='All brands'
                      />
                    </div>
                  )}
                </Field>
              )}

              { mode === 'update' && !values?.allProducts && (
                <div>
                  <div className='field__title'>Brands</div>
                  <Field name='products'>
                    {({ input, meta }) => (
                      <div className='m-b-lg'>
                        <ModalSelectInput
                          onClick={() => toggleModal(true)}
                          count={input.value.length}
                          label='Edit Brands'
                          icon='product'
                          values={getProductNames(input.value)}
                        />

                        <ModalSelect
                          title='Add Brand'
                          listTitle='Brands'
                          options={products}
                          onChange={input.onChange}
                          selected={input.value}
                          onClose={() => toggleModal(false)}
                          isOpen={isModalOpen}
                        />
                      </div>
                    )}
                  </Field>
                </div>
              )}

              {mode === 'update' && (
                <div className='m-t-sm m-b-lg'>
                  <div className='field__title'>Logo Upload</div>
                  <Field name='logoName'>
                    {({ input, meta }) => (
                      <FileUpload
                        id='form-logo'
                        file={logo}
                        onUpload={(file) => {
                          setLogo(file);
                          input.onChange(file.name);
                        }}
                        placeholder={input.value}
                        accept='image'
                      />
                    )}
                  </Field>
                </div>
              )}

              {mode === 'update' && (
                <div className='m-t-sm m-b-lg'>
                  <div className='field__title'>Submitted Form Visibility (User Roles)</div>
                  <Field name='roles'>
                    {({ input, meta }) => (
                      <MultiSelect
                        name={input.name}
                        value={input.value || []}
                        onChange={input.onChange}
                        input={input}
                        options={roleOptions}
                      />
                    )}
                  </Field>
                </div>
              )}

              {mode === 'update' && (
                <div className=''>
                  <div className='field__title'>Submitted Email Notification</div>
                  <div>
                    <div className='d-flex'>
                      <Input
                        type='email'
                        error={emailError}
                        placeholder='Email'
                        value={newEmail}
                        onChange={(e) => {
                          setNewEmail(e.target.value);
                          setEmailError(false);
                        }}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            onAddNewEmail();
                          }
                        }}
                      />
                      <div className='m-b-lg m-l-md'>
                        <Button
                          type='submit'
                          text='Add'
                          onClick={onAddNewEmail}
                          width={100}
                          disabled={!newEmail.length}
                        />
                      </div>
                    </div>

                    <div className='m-b-lg form__select-users' style={{ minWidth: 'auto' }}>
                      {emails.length ? (
                        emails.map((email) => (
                          <div className='md-user-info' key={email}>
                            <div>
                              {email}
                            </div>
                            <div
                              className='md-user-info__delete'
                              onClick={() => onDeleteEmail(email)}
                            >
                              <DeleteIcon fontSize='small' color='#d32f2f' />
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className='text-center secondary p-md'>
                          No Emails
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className='form__actions m-t-md'>
                <Button
                  type='submit'
                  text={buttonText || 'Save Changes'}
                  disabled={invalid || loading || pristine}
                  loading={loading}
                  onClick={handleSubmit}
                />
                {mode === 'create' && (
                  <Button
                    type='cancel'
                    onClick={onClose}
                  />
                )}
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default FormForm;
