import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const StyledButton = withStyles((theme) => ({
  root: {
    height: '60px',
    width: '80px',
    borderRadius: '16px',
    color: '#ffffff',
    textTransform: 'capitalize',
    fontSize: '10px'
  }
}))(MuiButton);

const ActionButton = ({
  onClick,
  disabled,
  bgColor,
  loading,
  children,
}) => {
  return (
    <StyledButton
      style={{
        background: disabled ? '#DDDDDD' : bgColor,
      }}
      className={`action-button${disabled ? '--disabled' : ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div className='d-flex direction-column space-between'>
        { loading ? <CircularProgress size={24} /> : children }
      </div>
    </StyledButton>
  )
};

export default ActionButton;
