import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { DashboardChart, CasesNumberBlock, DashboardCasesTable, NotificationsBlock } from '../../../components/home';
import LoadScreen from '../../../components/load-screen';

import { getSurgeons } from '../../../actions/surgeonsActions';
import { getProcedures } from '../../../actions/proceduresActions';

import { useLoading } from '../../../hooks';

import { prepareCasesDataWeekly, getTenantColors } from '../../../utils/analytics';

import './home-page.scss';

const today = moment().local().format('MMM Do YYYY');

const HomePage = () => {
  const dispatch = useDispatch();
  const tenantColor = useSelector((state) => state.tenant.currentTenant.colorPrimary);
  const cases = useSelector((state) => state.cases.list?.filter((item) => !!item.active));
  const completedCases = useSelector((state) => state.cases.completedCases);
  const completedLoaded = useSelector((state) => state.cases.completedLoaded);
  const surgeons = useSelector((state) => state.surgeons.list);
  const hospitals = useSelector((state) => state.hospitals.list);
  const procedures = useSelector((state) => state.procedures.list);
  const notifications = useSelector((state) => state.notifications.list);

  const { loading } = useLoading();

  useEffect(() => {
    onLoad().catch((err) => console.error(err));
  }, []);

  const onLoad = async () => {
    if (!surgeons.length || !procedures.length) {
      try {
        await Promise.all([
          dispatch(getSurgeons()),
          dispatch(getProcedures()),
        ]);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const chartData = useMemo(() => prepareCasesDataWeekly(cases), [cases]);

  return (
    <div className="page-container home-page__container">
      {loading && <LoadScreen />}
      <div className="page-title">
        Dashboard
        <span className="page-subtitle">{today}</span>
      </div>
      <div className="home-page__body">
        <div>
          <div className="home-page__row-container">
            <DashboardChart data={chartData} tenantColor={getTenantColors(chartData, tenantColor)} />

            <CasesNumberBlock cases={cases} completedCases={completedCases} completedLoaded={completedLoaded} />
          </div>

          <DashboardCasesTable
            cases={cases}
            surgeons={surgeons}
            hospitals={hospitals}
            procedures={procedures}
          />
        </div>

        <NotificationsBlock
          notifications={notifications}
          tenantColor={tenantColor}
        />
      </div>
    </div>
  );
};

export default HomePage;
