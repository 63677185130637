import { orderBy } from 'lodash';

import { GET_PROCEDURES, SET_PROCEDURES, SET_PROCEDURE_OPTIONS } from './actionTypes';

import firebase, { collections } from '../firebase';

export const setProcedures = procedures => ({ type: SET_PROCEDURES, procedures });

export const getProcedures = (tenant, withReducer) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = tenant || state?.tenant?.currentTenant?.id;
  const path = collections.PROCEDURES_COLLECTION(tenantId);

  dispatch({ type: GET_PROCEDURES });

  const snapshot = await firebase.db.collection(path).get();
  const procedures = snapshot.docs?.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));

  if (tenant && !withReducer) {
    return orderBy(procedures, 'name', 'asc');
  }

  return dispatch(setProcedures(orderBy(procedures, 'name', 'asc')));
};

export const createProcedure = (procedureData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.PROCEDURES_COLLECTION(tenantId);

  const doc = {
    ...procedureData,
    options: procedureData.options || [],
    active: true,
  }

  if (tenantId) {
    await firebase.db.collection(path).add(doc);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateProcedure = (procedureId, procedureData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.PROCEDURES_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(procedureId).set(procedureData, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteProcedure = (procedureId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.PROCEDURES_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(procedureId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const bulkDeleteProcedures = (procedures) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.PROCEDURES_COLLECTION(tenantId);
  const batch = firebase.db.batch();

  if (tenantId) {
    procedures.forEach((procedureId) => {
      const ref = firebase.db.collection(path).doc(procedureId);
      batch.delete(ref);
    });

    await batch.commit();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const setProcedureOptions = options => ({ type: SET_PROCEDURE_OPTIONS, options });

export const getProcedureOptions = (tenant, withReducer) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = tenant || state?.tenant?.currentTenant?.id;
  const path = collections.PROCEDURE_OPTIONS_COLLECTION(tenantId);

  if (tenantId) {
    const snapshot = await firebase.db.collection(path).get();
    const options = snapshot.docs?.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

    if (tenant && !withReducer) {
      return orderBy(options, 'name', 'asc');
    }

    return dispatch(setProcedureOptions(orderBy(options, 'name', 'asc')));
  }
};

export const addProcedureOption = (option) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.PROCEDURE_OPTIONS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).add({ name: option });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteProcedureOption = (optionId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.PROCEDURE_OPTIONS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(optionId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const uploadProcedures = (procedures) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.PROCEDURES_COLLECTION(tenantId);

  if (tenantId) {
    const promises = [];

    procedures.forEach((procedure) => {
      const name = procedure[0] || '';
      const description = procedure[1] || '';

      const doc = {
        name,
        description,
        active: true,
        options: [],
      };

      promises.push(firebase.db.collection(path).add(doc));
    });

    await Promise.all(promises);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const uploadProcedureOptions = (options) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.PROCEDURE_OPTIONS_COLLECTION(tenantId);

  if (tenantId) {
    const promises = [];

    options.forEach((opt) => {
      const name = opt[0] || '';
      const doc = { name };

      promises.push(firebase.db.collection(path).add(doc));
    });

    await Promise.all(promises);
  } else {
    throw new Error('Invalid tenant');
  }
};

// Subscriptions
export const subscribeToProcedures = () => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.PROCEDURES_COLLECTION(tenantId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let procedures = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          procedures = [...procedures, {
            ...documentSnapshot.data(),
            id,
          }];
        });

        return dispatch(setProcedures(orderBy(procedures, 'name', 'asc')));
      }
    });
};

export const subscribeToProcedureOptions = () => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.PROCEDURE_OPTIONS_COLLECTION(tenantId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let options = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          options = [...options, {
            ...documentSnapshot.data(),
            id,
          }];
        });

        return dispatch(setProcedureOptions(orderBy(options, 'name', 'asc')));
      }
    });
};
