/* eslint-disable no-unused-vars */

export const PieTooltip = ({ datum, entity = 'case' }) => {
  const { color, id, formattedValue } = datum;

  return (
    <div className="custom-tooltip">
      <div className="tooltip-label">{id}</div>
      <div className="tooltip-content">
        <div>{`${formattedValue} ${entity}(s)`}</div>
      </div>
    </div>
  );
};
