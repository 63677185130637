import React, { useState, useEffect } from 'react';

import TextField from '@material-ui/core/TextField';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import ArrowIcon from '@material-ui/icons/KeyboardArrowDown';

import './autocomplete.scss';

const icon = <ArrowIcon style={{ color: '#c5c8c9' }} />

const Autocomplete = (props) => {
  const {
    options = [],
    value,
    onChange,
    name,
    disabled = false,
    placeholder,
  } = props;

  const [inputValue, setInputValue] = useState(null);

  useEffect(() => {
    if (!inputValue && value && options?.length) {
      setInputValue(options?.find((o) => o.value === value));
    }
  }, [value, options]);

  return <MuiAutocomplete
    id="autocomplete"
    name={name}
    options={options}
    getOptionLabel={(option) => option.label}
    // defaultValue={value ? options?.find((o) => o.value === value) : null}
    value={inputValue}
    onChange={(event, option) => {
      setInputValue(option);
      onChange(option.value);
    }}
    popupIcon={icon}
    disableClearable
    disabled={disabled}
    style={{ paddingLeft: '16px !important' }}
    renderOption={(option) => (
      <div style={{ paddingTop: '8px', paddingBottom: '8px' }}>
        {option.label}
      </div>
    )}
    renderInput={(params) => (
      <TextField
        {...params}
        className='autocomplete__input'
        style={{
          color: value ? '#0b0b0b' : '#a7a7a7 !important',
        }}
        placeholder={placeholder}
      />
    )}
  />
}

export default Autocomplete;
