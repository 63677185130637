import React, { useState } from 'react';

import Modal from '../shared/modal';
import Input from '../shared/Input';

import './kits.scss';

const TargetModal = (props) => {
  const {
    open,
    onClose,
    initialValues,
    onSubmit,
  } = props;

  const [name, setName] = useState(initialValues?.name || '');
  const [value, setValue] = useState(initialValues?.value || 0);
  const [note, setNote] = useState(initialValues?.note || '');

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Turns Target'
      fullWidth
      closeButton='Cancel'
      onSubmit={() => {
        onSubmit({ name, value, note });
        onClose();
      }}
      submitButton='Submit'
      disabled={!name || !value}
    >
      <div className='kits-filters-modal__container' style={{ paddingTop: 0, paddingBottom: 0 }}>
        <div className='m-b-sm'>
          <div className='field__title'>Target Name</div>
          <Input
            value={name}
            placeholder='Type a name for this target'
            type='text'
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className='m-b-sm'>
          <div className='field__title'>Target No. of Turns</div>
          <Input
            value={value}
            numbersOnly
            type='text'
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <div className='m-b-sm'>
          <div className='field__title'>Turns Target Note</div>
          <Input
            value={note}
            placeholder='Type a description for this target'
            type='textarea'
            onChange={(e) => setNote(e.target.value)}
          />
        </div>
        <div className='font-bold' style={{ color: '#F97032' }}>
          NOTE: This target will be applied to all sets linked to this KIT ID
        </div>
      </div>
    </Modal>
  );
};

export default TargetModal;
