import React from 'react';
import { useSelector } from 'react-redux';

import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

import EditIcon from '../../../assets/icons/EditIcon';

const SectionNotesRow = (props) => {
  const tenantColor = useSelector((state) => state.tenant.currentTenant.colorPrimary);

  const { item, provided, isDragging, onClick } = props;

  return (
    <tr
      {...provided.draggableProps}
      ref={provided.innerRef}
      className={isDragging ? 'dragging-row' : ''}
    >
      <td />
      <td colSpan={6} style={{ color: item.color || '#F58634' }}>
        <span className='font-bold m-r-md'>{item.title}</span>
        <span style={{ fontWeight: 400 }}>{item.note}</span>
      </td>
      <td onClick={onClick} className='pointer'>
        <EditIcon color={tenantColor} />
      </td>
      <td {...provided.dragHandleProps} style={{ width: 24 }}>
        <DragIndicatorIcon />
      </td>
    </tr>
  );
};

export default SectionNotesRow;
