import React, { useMemo } from 'react';
import { orderBy } from 'lodash';

import { PieChart } from '../../../charts';

const StatesPieChart = ({ cases = [], hospitals = [], tenantColor }) => {
  const data = useMemo(() => {
    const states = [];
    hospitals?.forEach((hospital) => {
      if (!states?.includes(hospital.state)) {
        states.push(hospital.state);
      }
    });
    const chartData = states
      .map((state) => {
        const stateCases = cases.filter((item) => {
          const hospital = hospitals?.find((h) => item.hospital === h.id);
          return hospital.state === state;
        });
        return {
          id: state,
          label: state,
          value: stateCases.length,
        };
      })

    return orderBy(chartData?.filter((item) => !!item.value), 'value', 'asc');
  }, [cases, hospitals]);

  return (
    <div className="management-reports-chart__container m-r-md" style={{ width: '50%' }}>
      <div className="font-size-bg font-bold">Cases by State</div>
      <div className="secondary m-t-sm m-b-lg">Total cases shown by state</div>
      <PieChart
        data={data}
        // colors={getTenantColors(data, tenantColor)}
        // colors={colorPalette}
        margin={{ top: 20, right: 60, bottom: 20, left: -60 }}
        height="300px"
        enableArcLinkLabels={true}
        enableArcLabels={true}
      />
    </div>
  );
};

export default StatesPieChart;
