import React from 'react';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import Button from '../../shared/Button';

const AddButton = ({
  variant,
  disabled,
  onClick,
  tenantColor
}) => (
  <Button
    type='outlined'
    height={25}
    width={25}
    disabled={disabled}
    onClick={onClick}
  >
    {variant === 'minus' ? (
      <RemoveIcon fontSize='small' style={{ fill: disabled ? '#cccccc' : tenantColor }} />
    ) : (
      <AddIcon fontSize='small' style={{ fill: disabled ? '#cccccc' : tenantColor }} />
    )}
  </Button>
);

const SectionItemRow = (props) => {
  const { item, onSave, tenantColor } = props;

  const onClick = (value) => {
    if (Number(value) >= 0) {
      onSave(Number(value));
    }
  };

  const onImageClick = (e, row) => {
    if (row && row.image && row.image.downloadUrl) {
      window.open(row.image.downloadUrl, '_blank');
    }

    if (row && row.imageUrl) {
      window.open(row.imageUrl, '_blank');
    }

    e.stopPropagation();
  };

  const imageSrc = item?.image?.downloadUrl || item.imageUrl;

  return (
    <tr>
      <td>{item.ref}</td>
      <td>{item.code}</td>
      <td>{item.description}</td>
      <td>{item.type}</td>
      <td>
        {imageSrc && (
          <div className='item-image-preview' onClick={(e) => onImageClick(e, item)}>
            <img src={imageSrc} alt='preview' />
          </div>
        )}
      </td>
      <td style={{ textAlign: 'center' }}>{item.quantity}</td>
      <td>
        <div className='d-flex flex-end'>
          <AddButton
            variant='minus'
            onClick={() => {
              if (item.count > 0) {
                onClick(item.count - 1);
              }
            }}
            disabled={item.count === 0}
          />
          <div
            style={{
              color: tenantColor,
              textAlign: 'center',
              marginLeft: 4,
              marginRight: 4,
              width: 25
            }}
          >
            {item.count}
          </div>
          <AddButton variant='plus' onClick={() => onClick(item.count + 1)} />
        </div>
      </td>
    </tr>
  );
};

export default SectionItemRow;
