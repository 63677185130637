import React, { useState, useMemo, useEffect } from 'react';

import Input from '../Input';
import Checkbox from '../Checkbox';
import Button from '../Button';
import Modal from '../modal/Modal';

import './modal-select.scss';

const UsersModal = (props) => {
  const {
    title,
    listTitle = 'Users',
    users,
    onChange,
    selected,
    onClose,
    isOpen,
    singleSelect = false,
    idField = 'uid',
    searchPlaceholder = 'Search Users',
  } = props;

  const [userIds, onChangeIds] = useState(selected || []);
  const [search, setSearch] = useState('');

  useEffect(() => {
    onChangeIds(selected || []);
  }, [selected]);

  const handleClick = (userId) => {
    if (singleSelect) {
      if (userIds.includes(userId)) {
        return onChangeIds([]);
      }
      return onChangeIds([userId]);
    }

    const temp = userIds.slice();
    if (temp.includes(userId)) {
      const index = temp.indexOf(userId);

      temp.splice(index, 1);
      onChangeIds(temp);
      return;
    }

    temp.push(userId);
    onChangeIds(temp);
  };

  const list = useMemo(() => {
    return users.filter((item) => {
      const name = item.displayName || item.lastName;

      return name.toLowerCase().includes(search.toLowerCase())
    })
  }, [search, users]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <div className='form__container'>
        <div className='form__body'>

          <div className='form__subtitle'>
            {title}
          </div>

          <Input
            type='search'
            placeholder={searchPlaceholder}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <div className='field__title'>{listTitle}</div>
          <div className='m-b-lg form__select-users'>
            {list.length ? (
              list.map((user) => (
                <div className='md-user-info' key={user[idField]}>
                  <Checkbox
                    input={{
                      onClick: () => handleClick(user[idField]),
                      checked: userIds.indexOf(user[idField]) !== -1
                    }}
                    fontSize='small'
                    label={`${user.title ? `${user.title} ` : ''}${user.firstName} ${user.lastName}`}
                  />
                  <span>{user.email}</span>
                </div>
              ))
            ) : (
              <div className='text-center secondary p-md'>
                No {listTitle}
              </div>
            )}
          </div>

          <div className='form__actions'>
            <Button
              type='submit'
              text='Submit'
              onClick={() => {
                onChange(userIds);
                onClose();
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UsersModal;
