import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function CloudUploadIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M11.4489 9.32139C11.7983 9.14388 12.1115 8.93817 12.3776 8.7037C12.8565 8.28186 13.1609 7.70252 13.2333 7.07539C13.3057 6.44826 13.141 5.81687 12.7705 5.30078C12.3999 4.78469 11.8492 4.41973 11.2226 4.275C11.1793 4.26233 11.1406 4.2378 11.1111 4.20429C11.0816 4.17078 11.0625 4.12969 11.056 4.08586C10.9785 3.25359 10.6124 2.47251 10.0186 1.87287C9.42483 1.27323 8.63932 0.891279 7.79307 0.790715C6.94682 0.690151 6.09098 0.87705 5.36827 1.32025C4.64557 1.76344 4.09968 2.43614 3.82164 3.22617C3.80325 3.26301 3.77448 3.29391 3.73874 3.31524C3.703 3.33657 3.66179 3.34742 3.61996 3.34653C3.21692 3.37306 2.82315 3.47716 2.46115 3.65288C2.09914 3.8286 1.77598 4.07251 1.51013 4.37068C0.973201 4.97285 0.702263 5.75947 0.756916 6.55749C0.811569 7.35551 1.18734 8.09956 1.80155 8.62595C2.06195 8.84912 2.36849 9.04905 2.71079 9.2252" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M3.47656 3.34912C4.66577 3.34912 5.72932 4.13297 6.06482 4.84909" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M7 13.2359L7 7.8584" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M9.05249 9.63965C8.42879 8.84287 8.00399 8.47209 7.37091 7.98714C7.1469 7.81555 6.85303 7.81555 6.62902 7.98714C5.99594 8.47209 5.57114 8.84287 4.94744 9.63965" />
  </SvgIcon>
}
