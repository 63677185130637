import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

import ArrowIcon from '@material-ui/icons/KeyboardArrowDown';

import SignOutIcon from '../../assets/icons/SignOutIcon';
import ProfileIcon from '../../assets/icons/ProfileIcon';

import { routes, userRoles } from '../../constants';

const ITEM_HEIGHT = 48;

const StyledMenuItem = withStyles((theme) => ({
  root: {
    paddingTop: '12px',
    paddingBottom: '12px',
  }
}))(MenuItem);

const UserInfo = ({ userName, avatar, signOut, userRole, tenantColor }) => {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    history.push(routes.PROFILE);
    handleClose();
  };

  return (
    <div className='user-info'>
      {avatar && (
        <div className='user-avatar'>
          <img src={avatar} alt='user-avatar' />
        </div>
      )}

      <div>
        <div className='user-name'>{userName}</div>
        <div className='role secondary'>
          {userRoles[userRole]?.label}
        </div>
      </div>
      <IconButton
        className='m-l-md'
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClick}
        size='small'
      >
        <ArrowIcon fontSize='large' />
      </IconButton>
      <Menu
        id='long-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 200,
          },
          elevation: 3,
        }}
      >
        <StyledMenuItem onClick={handleProfileClick}>
          <ProfileIcon />
          <span className='m-l-md'>Profile</span>
        </StyledMenuItem>
        <StyledMenuItem onClick={signOut}>
          <SignOutIcon />
          <span className='m-l-md'>Sign Out</span>
        </StyledMenuItem>
      </Menu>
    </div>
  );
}

export default UserInfo;
