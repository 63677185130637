import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import DatePicker from '../shared/date-picker/DatePicker';
import Select from '../shared/Select';
import Button from '../shared/Button';
import ModalSelect from '../shared/modal-select/ModalSelect';
import ModalSelectInput from './ModalSelectInput';

import { onPresetChange } from '../../utils/date';

import { presets } from '../../constants/analytics';
import { implantScanTypes } from '../../constants/enums';

const presetOptions = [
  { label: 'This month', value: presets.THIS_MONTH },
  { label: 'This quarter', value: presets.THIS_QUARTER },
  { label: 'This year', value: presets.THIS_YEAR },
  { label: 'Last month', value: presets.LAST_MONTH },
  { label: 'Last quarter', value: presets.LAST_QUARTER },
  { label: 'Last year', value: presets.LAST_YEAR },
];

const typeOptions = [
  { label: 'All', value: '' },
  { label: implantScanTypes.text, value: implantScanTypes.text },
  { label: implantScanTypes.qr, value: implantScanTypes.qr },
  { label: implantScanTypes.datamatrix, value: implantScanTypes.datamatrix },
  { label: implantScanTypes.datamatrix2, value: implantScanTypes.datamatrix2 },
  { label: implantScanTypes.hibcc, value: implantScanTypes.hibcc },
  { label: implantScanTypes.hibcc2, value: implantScanTypes.hibcc2 },
];

const ItemReportingForm = (props) => {
  const {
    users = [],
    hospitals = [],
    onSubmit,
    loading,
  } = props;

  const [preset, setPreset] = useState(presets.THIS_MONTH);
  const [minDate, setMinDate] = useState(moment().startOf('month'));
  const [maxDate, setMaxDate] = useState(moment().endOf('month'));

  const [user, setUser] = useState('');
  const [location, setLocation] = useState('');
  const [subLocation, setSubLocation] = useState('');
  const [type, setType] = useState('');

  const [hospitalModalOpen, toggleHospitalModal] = useState(false);

  useEffect(() => {
    onPresetChange(preset, { onMinDateChange: setMinDate, onMaxDateChange: setMaxDate });
  }, [preset]);

  useEffect(() => {
    setSubLocation('');
  }, [location]);

  const handleSubmit = () => {
    const data = {
      minDate,
      maxDate,
      user,
      location,
      subLocation,
      type,
    };
    onSubmit(data);
  }

  const subLocations = useMemo(() => {
    if (location && hospitals) {
      const hospital = hospitals?.find((h) => h.id === location);
      return hospital.locations || [];
    }
    return [];
  }, [location, hospitals]);

  const getHospitalName = (hospitalId) => {
    const hospital = hospitals.find((item) => item.id === hospitalId);
    return hospital ? hospital.name : '';
  };

  return (
    <div className='item-reporting__container'>
      <div className='item-reporting-form'>
        <div>
          <div className='m-b-lg'>
            <div className='field__title'>Location</div>
            <ModalSelectInput
              onClick={() => toggleHospitalModal(true)}
              values={location ? [getHospitalName(location)] : []}
            />
            <ModalSelect
              title='Filter by Location'
              listTitle='Hospitals'
              options={hospitals}
              onChange={(v) => setLocation(v ? v[0] : '')}
              selected={[location]}
              onClose={() => toggleHospitalModal(false)}
              singleSelect
              isOpen={hospitalModalOpen}
              extraField='state'
            />
          </div>
          <div>
            <div className='field__title'>Added by (user)</div>
            <Select
              value={user}
              onChange={setUser}
              options={[
                { label: 'All', value: '' },
                ...users?.map((u) => ({ label: `${u.firstName} ${u.lastName}`, value: u.uid }))
              ]}
              endAdornment='Select'
              placeholder='Filter by User'
            />
          </div>
        </div>
        <div>
          <div className='m-b-lg'>
            <div className='field__title'>Sub-location</div>
            <Select
              value={subLocation}
              onChange={setSubLocation}
              options={
                subLocations && subLocations.length ? (
                  [{ label: 'All', value: '' }, ...subLocations?.map((label) => ({ label, value: label }))]
                ) : []
              }
              endAdornment='Select'
              placeholder='Filter by Sub-location'
            />
          </div>
          <div>
            <div className='field__title'>Type</div>
            <Select
              value={type}
              onChange={setType}
              options={typeOptions}
              endAdornment='Select'
              placeholder='Filter by Count Type'
            />
          </div>
        </div>
        <div>
          <div className='field__title'>Date Range</div>
          <div className='case-date-range'>
            <div className='d-flex'>
              <div className='secondary' style={{ width: 40 }}>From</div>
              <DatePicker
                date={minDate}
                onDateChange={(date) => {
                  setMinDate(date);
                  setPreset(null);
                }}
                maxDate={maxDate}
                withoutStyles
              />
            </div>

            <div className='d-flex'>
              <div className='secondary' style={{ width: 40 }}>To</div>
              <DatePicker
                date={maxDate}
                onDateChange={(date) => {
                  setMaxDate(date);
                  setPreset(null);
                }}
                minDate={minDate}
                withoutStyles
              />
            </div>
          </div>

          <div className='m-t-lg'>
            <Select
              value={preset}
              onChange={setPreset}
              options={presetOptions}
            />
          </div>
        </div>
      </div>
      <div className='item-reporting-actions'>
        <Button
          type='submit'
          text='Run Report'
          onClick={handleSubmit}
          disabled={loading}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default ItemReportingForm;
