import React, { useCallback, useMemo } from 'react';
import { orderBy } from 'lodash';

import BarChart from '../../../charts/BarChart';

import colorPalette, { lighterColorPalette } from '../colorPalette';

export const CustomTooltip = (props) => {
  const { data, value, users } = props;
  const uid = data?.id?.includes('compare') ? data?.id?.substring(0, data?.id.indexOf('_compare')) : data?.id;
  const user = users?.find((u) => u.uid === uid);
  const name = `${user.firstName} ${user.lastName}`;

  return (
    <div className="custom-tooltip">
      <div className="tooltip-label">{user ? (data?.id?.includes('compare') ? `${name} (previous period)` : name) : ''}</div>
      <div className="tooltip-content">
        <div>{`${value} case(s)`}</div>
      </div>
    </div>
  );
};

const generateColors = (keys, isPrevious) => {
  const colors = {};
  keys?.forEach((k, index) => {
    colors[k] = isPrevious ? lighterColorPalette[index] : colorPalette[index];
  });
  return colors;
};

const UsersBarChart = ({ cases = [], users = [], compareCases = [], presetCompare }) => {
  const data = useMemo(() => {
    const allUsers = users
      .map((user) => {
        const userCases = cases.filter((item) => item.createdBy === user.uid);
        return {
          id: user.uid,
          label: user.lastName,
          value: userCases.length,
        };
      })
      .filter((item) => item.value !== 0);

    const sorted = orderBy(allUsers, 'value', 'desc');
    const top5 = sorted.splice(0, 5);

    let result = [];

    if (presetCompare) {
      top5.forEach((chartItem) => {
        const userCases = compareCases.filter((item) => item.createdBy === chartItem.id);
        const user = users?.find((u) => u.uid === chartItem.id);
        result.push({
          id: `${user?.uid}_compare`,
          label: user?.lastName ? `${user?.lastName} (vs)` : '',
          value: userCases.length,
        });
        result.push(chartItem);
      });
    } else {
      result = top5;
    }

    return result;
  }, [cases, users, presetCompare, compareCases]);

  const getUserName = useCallback((userId) => {
    const uid = userId?.includes('compare') ? userId?.substring(0, userId.indexOf('_compare')) : userId;
    const user = users?.find((u) => u.uid === uid);
    return user ? (userId?.includes('compare') ? `${user.lastName} (vs)` : user.lastName) : '';
  }, [users]);

  const colors = useMemo(() => generateColors(data?.filter((item) => !item?.id?.includes('_compare'))?.map((item) => item.id)), [data]);
  const previousColors = useMemo(() => generateColors(data?.filter((item) => item?.id?.includes('_compare'))?.map((item) => item.id), true), [data]);

  return (
    <div className="management-reports-chart__container m-r-md" style={{ width: '50%' }}>
      <div className="font-size-bg font-bold">Top Users by Bookings</div>
      <div className="secondary m-t-sm m-b-lg">Total cases booked by top 10 users</div>
      <BarChart
        data={data || []}
        indexBy="id"
        height="300px"
        layout="horizontal"
        enableLabel={true}
        margin={{ top: 10, right: 25, bottom: 30, left: presetCompare ? 90 : 85 }}
        colors={(bar) => {
          if (bar.indexValue?.includes('_compare')) {
            return previousColors[bar.indexValue];
          }
          return colors[bar.indexValue];
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          truncateTickAt: 0,
          format: uid => getUserName(uid),
        }}
        labelTextColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              1.6,
            ],
          ],
        }}
        CustomTooltip={(props) => <CustomTooltip {...props} users={users} />}
      />
    </div>
  );
};

export default UsersBarChart;
