import React from 'react';

import AddButton from '../../shared/AddButton';

import { itemTypeOptions } from '../../../constants/enums';

const CaseUsageItemRow = (props) => {
  const { label, checklistDescription, description, type, qty, onChange, code } = props;

  return (
    <div className='d-flex m-b-sm align-start'>
      <div style={{ flex: 0.15 }} className='font-bold'>{code}</div>
      <div style={{ flex: 0.15 }} className='font-bold'>{label}</div>
      <div style={{ flex: 0.4, paddingLeft: 4 }} className='font-bold'>{checklistDescription || description}</div>
      <div style={{ flex: 0.15, paddingLeft: 4 }} className='font-bold'>{itemTypeOptions[type]?.label}</div>
      <div
        style={{ flex: 0.15 }}
        className='font-bold d-flex flex-end'
      >
        <AddButton
          variant='remove'
          disabled={qty === 0}
          onClick={() => onChange(qty - 1)}
        />
        <div className='m-l-md m-r-md text-center' style={{ width: 12 }}>{qty}</div>
        <AddButton onClick={() => onChange(qty + 1)} />
      </div>
    </div>
  );
};

export default CaseUsageItemRow;
