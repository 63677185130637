import React, { useMemo } from 'react';
import {
  Document,
  Page,
  View,
  Text,
  Font,
  StyleSheet,
  Image
} from '@react-pdf/renderer';
import moment from 'moment/moment';

import InterRegular from '../../../assets/fonts/Inter-Regular.ttf';
import InterBold from '../../../assets/fonts/Inter-Bold.ttf';

import { billOfMaterialItemTypes, caseChecklistStatuses } from '../../../constants/enums';

const styles = StyleSheet.create({
  container: {
    padding: 36,
    fontFamily: 'Inter',
    fontWeight: 'normal',
    fontSize: 7,
    color: '#202124',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  titleBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  bold: {
    fontWeight: 'bold'
  },
  logo: {
    height: 20,
    width: 'auto',
  },
  kitLogo: {
    height: 20,
    width: 'auto'
  },
  sectionImage: {
    // width: 400,
    // height: 'auto',
    width: 'auto',
    maxWidth: 400,
    maxHeight: 200
  },
  title: {
    fontSize: 18,
    marginBottom: 4,
    fontWeight: 'bold',
    fontFamily: 'Inter'
  },
  table: {
    marginTop: 16,
    paddingBottom: 8,
    // borderBottom: '1px solid #000000',
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    fontFamily: 'Inter',
    paddingTop: 4,
    paddingBottom: 4,
    borderTop: '0.5px solid #000000',
    borderBottom: '0.5px solid #000000',
    fontSize: 9
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '0.5px solid #DDDDDD',
  },
  notesTableRow: {
    borderBottom: '0.5px solid #DDDDDD',
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  notesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '4px',
    fontFamily: 'Inter',
    fontWeight: 'normal',
  },
  tableCell: {
    padding: 2,
    justifyContent: 'center',
    fontFamily: 'Inter',
  },
  borderCell: {
    border: '0.5px solid #969696',
    borderRadius: '4px',
    height: '13px',
    width: '13px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tableHeaderCell: {
    padding: 2,
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontFamily: 'Inter',
  },
  sectionTable: {
    marginTop: 8,
    paddingBottom: 8,
  },
  sectionTableHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    fontFamily: 'Inter',
    paddingTop: 4,
    paddingBottom: 4,
    borderTop: '0.5px solid #000000',
    borderBottom: '0.5px solid #000000',
  },
  sectionTableRow: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  activityBlock: {
    marginTop: 8,
    paddingBottom: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '0.5px solid #000000'
  }
});

Font.register({
  family: 'Inter',
  fonts: [
    {
      src: InterRegular,
      fontWeight: 'normal'
    },
    {
      src: InterBold,
      fontWeight: 'bold',
    },
  ],
});

const ChecklistsPDF = (props) => {
  const {
    activeCase,
    tenant,
    hospital,
    surgeon,
    kits = [],
    checklists = [],
    caseActivity = [],
    users = [],
    sets = [],
    items = [],
    withItems = false,
  } = props;

  const getKitLogo = (kitId) => {
    const kit = kits?.find((k) => k.kitId === kitId);
    return kit?.logo?.downloadUrl || '';
  };

  const getKitVersion = (kitId) => {
    const kit = kits?.find((k) => k.kitId === kitId);
    return kit?.version || '';
  };

  const formattedId = useMemo(() => {
    if (!activeCase) {
      return '';
    }
    const arr = activeCase.id.split('-');
    const formattedId = arr && arr.length > 1 ? `${arr[0]}-${arr[1]}` : activeCase.id;
    return formattedId;
  }, [activeCase]);

  const renderActivityView = (checklist) => {
    if (!activeCase) {
      return null;
    }

    const activity = caseActivity.find((item) => item.arguments && item.arguments.checklistId === checklist.id);
    if (!activity) {
      return null;
    }
    const user = users?.find((u) => u.uid === activity.userId);

    return (
      <View style={styles.activityBlock}>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text style={{ fontWeight: 'bold' }}>INSPECTION PASSED</Text>
          <View style={{ marginLeft: 8, display: 'flex', flexDirection: 'row'  }}>
            <Text>
              {`${moment(activity.createdAt).format('Do MMMM YYYY')}  |  ${user.firstName} ${user.lastName}`}
            </Text>
          </View>
        </View>
        <Text>{formattedId}</Text>
      </View>
    );
  };

  const getBatchValues = (checklist, item) => {
    if (item.batchOutbound && item.batchOutbound.length) {
      return item.batchOutbound.map((i) => i.code)?.join(', ');
    }
    const set = sets?.find((s) => s.number === checklist.setNumber);
    const batchItems = set?.batchItems || {};
    const values = batchItems[item?.id] || null;
    if (values) {
      return values?.map((i) => i.code)?.join(', ');
    }
    return '';
  };

  return (
    <Document>
      {checklists?.map((checklist) => (
        <Page key={`${checklist.id}_${Math.random()}`} size="A4" style={styles.container}>
          <View>
            <View style={styles.titleBlock}>
              <View>
                {tenant.documentLogo && (
                  <Image
                    src={tenant?.documentLogo?.downloadUrl}
                    style={styles.logo}
                  />
                )}
              </View>
              <View>
                {!!getKitLogo(checklist.kitId) && (
                  <Image
                    src={getKitLogo(checklist.kitId)}
                    style={styles.kitLogo}
                  />
                )}
              </View>
            </View>

            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={{ ...styles.tableCell, width: '10%', fontWeight: 'bold' }}><Text>SURGERY</Text></View>
                <View style={{ ...styles.tableCell, width: '40%' }}><Text>{activeCase ? moment(activeCase.date).format('Do MMMM YYYY') : ''}</Text></View>
                <View style={{ ...styles.tableCell, width: '8%', fontWeight: 'bold' }}><Text>KIT ID</Text></View>
                <View style={{ ...styles.tableCell, width: '42%' }}><Text>{checklist?.kitId || ''}</Text></View>
              </View>
              <View style={styles.tableRow}>
                <View style={{ ...styles.tableCell, width: '10%', fontWeight: 'bold' }}><Text>SURGEON</Text></View>
                <View style={{ ...styles.tableCell, width: '40%' }}><Text>{surgeon ? `${surgeon?.title} ${surgeon?.firstName} ${surgeon?.lastName}` : ''}</Text></View>
                <View style={{ ...styles.tableCell, width: '8%', fontWeight: 'bold' }}><Text>SET #</Text></View>
                <View style={{ ...styles.tableCell, width: '42%' }}><Text>{checklist?.setNumber || ''}</Text></View>
              </View>
              <View style={styles.tableRow}>
                <View style={{ ...styles.tableCell, width: '10%', fontWeight: 'bold' }}><Text>HOSPITAL</Text></View>
                <View style={{ ...styles.tableCell, width: '40%' }}><Text>{hospital?.name || ''}</Text></View>
                <View style={{ ...styles.tableCell, width: '8%', fontWeight: 'bold' }}><Text>SIZE</Text></View>
                <View style={{ ...styles.tableCell, width: '42%' }}><Text>{checklist?.containerSize || ''}</Text></View>
              </View>
              <View style={{ ...styles.tableRow, border: 'none' }}>
                <View style={{ ...styles.tableCell, width: '10%', fontWeight: 'bold' }}><Text>PATIENT ID</Text></View>
                <View style={{ ...styles.tableCell, width: '40%' }}><Text>{activeCase ? activeCase.patientReference : ''}</Text></View>
                <View style={{ ...styles.tableCell, width: '8%', fontWeight: 'bold' }}><Text>STATUS</Text></View>
                <View style={{ ...styles.tableCell, width: '42%', fontWeight: 'bold' }}>
                  {checklist?.status ? (
                    <Text
                      style={{
                        color: checklist.status === caseChecklistStatuses.COMPLETE.value ? caseChecklistStatuses.COMPLETE.color : caseChecklistStatuses.INSPECT.color
                      }}
                    >
                      {caseChecklistStatuses[checklist.status]?.label || ''}
                    </Text>
                  ) : null}
                </View>
              </View>
            </View>

            {checklist.sections?.map((section) => (
              <View key={section.id || Math.random()} style={{ marginTop: 16 }} break={!!section.printOnNewPage}>
                {!!section.image && (
                  <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 16 }}>
                    <Image style={styles.sectionImage} src={section.image.downloadUrl} />
                  </View>
                )}

                <View>
                  <Text style={{ fontWeight: 'bold', fontSize: 9 }}>{section.name}</Text>
                  <Text style={{ color: '#969696' }}>{section.subtitle}</Text>

                  <View style={styles.sectionTable}>
                    <View style={styles.sectionTableHeader}>
                      <View style={{ ...styles.tableHeaderCell, width: '5%' }}><Text></Text></View>
                      <View style={{ ...styles.tableHeaderCell, width: '18%' }}><Text>ITEM #</Text></View>
                      <View style={{ ...styles.tableHeaderCell, width: section?.items?.some((item) => getBatchValues(checklist, item)) ? '38%' : '58%' }}>
                        <Text>DESCRIPTION</Text>
                      </View>
                      {section?.items?.some((item) => getBatchValues(checklist, item)) && (
                        <View style={{ ...styles.tableHeaderCell, width: '18%' }}><Text>LOT # / SERIAL</Text></View>
                      )}
                      <View style={{ ...styles.tableHeaderCell, width: '5%' }}><Text style={{ textAlign: 'center' }}>KIT QTY</Text></View>
                      <View style={{ ...styles.tableHeaderCell, width: '4%' }}><Text style={{ textAlign: 'center' }}>CO. OUT</Text></View>
                      <View style={{ ...styles.tableHeaderCell, width: '4%' }}><Text style={{ textAlign: 'center' }}>H.   IN</Text></View>
                      <View style={{ ...styles.tableHeaderCell, width: '4%' }}><Text style={{ textAlign: 'center' }}>H. OUT</Text></View>
                      <View style={{ ...styles.tableHeaderCell, width: '4%' }}><Text style={{ textAlign: 'center' }}>CO. IN</Text></View>
                    </View>

                    {section.items?.map((item) => (
                      item.type === billOfMaterialItemTypes.NOTES.value ? (
                        <View key={`${item.id}_${Math.random()}`} style={styles.notesTableRow}>
                          <View style={{ ...styles.notesContainer, border: `1px solid ${item.color || '#F58634'}` }}>
                            <View style={{ width: '5%' }}><Text></Text></View>
                            <View style={{ width: '90%' }}>

                              <Text style={{ color: `${item.color || '#F58634'}` }}>
                                <Text style={{ textTransform: 'uppercase' }}>
                                  {`${item.title}  `}
                                </Text>
                                <Text style={{ fontWeight: 'normal', fontFamily: 'Inter' }}>{item.note}</Text>
                              </Text>
                            </View>
                            <View style={{ width: '5%' }}><Text></Text></View>
                          </View>
                        </View>
                      ) : (
                        <View key={item.id} style={styles.tableRow} wrap={false}>
                          <View style={{ ...styles.tableCell, width: '5%' }}><Text>{item.ref}</Text></View>
                          <View style={{ ...styles.tableCell, width: '18%', flexShrink: 1, overflow: 'hidden' }}><Text numberOfLines={1} ellipsizeMode="tail">{`${item.code}`}</Text></View>
                          <View
                            style={{ ...styles.tableCell, width: section?.items?.some((item) => getBatchValues(checklist, item)) ? '38%' : '58%' }}
                          >
                            <Text>{`${item.checklistDescription || item.description}`}</Text>
                          </View>
                          {section?.items?.some((item) => getBatchValues(checklist, item)) && (
                            <View style={{ ...styles.tableCell, width: '18%' }}>
                              <Text>{getBatchValues(checklist, item)}</Text>
                            </View>
                          )}
                          <View style={{ ...styles.tableCell, width: '5%' }}><Text style={{ textAlign: 'center', color: '#969696' }}>{item.quantity}</Text></View>
                          <View style={{ ...styles.tableCell, alignItems: 'center', width: '4%' }}>
                            <View style={styles.borderCell}>
                              <Text
                                style={{
                                  textAlign: 'center',
                                  color: item.outbound < item.quantity ? '#F58634' : '#202124'
                                }}
                              >
                                {item.outbound}
                              </Text>
                            </View>
                          </View>
                          <View style={{ ...styles.tableCell, alignItems: 'center', width: '4%' }}>
                            <View style={styles.borderCell}>
                              <Text style={{ textAlign: 'center' }}></Text>
                            </View>
                          </View>
                          <View style={{ ...styles.tableCell, alignItems: 'center', width: '4%' }}>
                            <View style={styles.borderCell}>
                              <Text style={{ textAlign: 'center' }}></Text>
                            </View>
                          </View>
                          <View style={{ ...styles.tableCell, alignItems: 'center', width: '4%' }}>
                            <View style={styles.borderCell}>
                              <Text style={{ textAlign: 'center' }}></Text>
                            </View>
                          </View>
                        </View>
                      )
                    ))}
                  </View>
                </View>
              </View>
            ))}
          </View>

          <View>
            {renderActivityView(checklist)}
          </View>

          <Text
            render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
            fixed
            style={{ position: 'absolute', bottom: 16, left: 36 }}
          />
          {!!getKitVersion(checklist.kitId) && (
            <Text
              render={() => getKitVersion(checklist.kitId)}
              fixed
              style={{ position: 'absolute', bottom: 16, left: '50%', right: '50%' }}
            />
          )}
        </Page>
      ))}
      {withItems && !!items?.length && (
        <Page size="A4" style={styles.container}>
          <View>
            <Text style={{ fontWeight: 'bold', fontSize: 9 }}>Items</Text>
            <Text style={{ color: '#969696' }}>Additional Items Inspected</Text>
            <View style={styles.sectionTable}>
              <View style={styles.sectionTableHeader}>
                <View style={{ ...styles.tableHeaderCell, width: '15%' }}><Text>ITEM #</Text></View>
                <View style={{ ...styles.tableHeaderCell, width: '45%' }}><Text>DESCRIPTION</Text></View>
                <View style={{ ...styles.tableHeaderCell, width: '20%' }}><Text>LOT # / SERIAL</Text></View>
                <View style={{ ...styles.tableHeaderCell, width: '4%' }}><Text style={{ textAlign: 'center' }}>KIT QTY</Text></View>
                <View style={{ ...styles.tableHeaderCell, width: '4%' }}><Text style={{ textAlign: 'center' }}>CO. OUT</Text></View>
                <View style={{ ...styles.tableHeaderCell, width: '4%' }}><Text style={{ textAlign: 'center' }}>H.   IN</Text></View>
                <View style={{ ...styles.tableHeaderCell, width: '4%' }}><Text style={{ textAlign: 'center' }}>H. OUT</Text></View>
                <View style={{ ...styles.tableHeaderCell, width: '4%' }}><Text style={{ textAlign: 'center' }}>CO. IN</Text></View>
              </View>

              {items?.map((item) => (
                <View key={`${item.id}_${Math.random()}`} style={styles.tableRow}>
                  <View style={{ ...styles.tableCell, width: '15%' }}><Text>{item.name}</Text></View>
                  <View style={{ ...styles.tableCell, width: '45%' }}><Text>{item.description}</Text></View>
                  <View style={{ ...styles.tableCell, width: '20%' }}>
                    <Text>
                      {item?.consumables && !!item?.consumables?.length ? (
                        typeof item.consumables[0] === 'string' ? (
                          item?.consumables?.join(', ')
                        ) : (
                          item?.consumables?.map((c) => c?.label)?.join(', ')
                        )
                      ) : ''}
                    </Text>
                  </View>
                  <View style={{ ...styles.tableCell, width: '4%' }}><Text style={{ textAlign: 'center', color: '#969696' }}>{item.quantity}</Text></View>
                  <View style={{ ...styles.tableCell, alignItems: 'center', width: '4%' }}>
                    <View style={styles.borderCell}>
                      <Text style={{ textAlign: 'center' }}>{item.quantity}</Text>
                    </View>
                  </View>
                  <View style={{ ...styles.tableCell, alignItems: 'center', width: '4%' }}>
                    <View style={styles.borderCell}>
                      <Text style={{ textAlign: 'center' }}></Text>
                    </View>
                  </View>
                  <View style={{ ...styles.tableCell, alignItems: 'center', width: '4%' }}>
                    <View style={styles.borderCell}>
                      <Text style={{ textAlign: 'center' }}></Text>
                    </View>
                  </View>
                  <View style={{ ...styles.tableCell, alignItems: 'center', width: '4%' }}>
                    <View style={styles.borderCell}>
                      <Text style={{ textAlign: 'center' }}></Text>
                    </View>
                  </View>
                </View>
              ))}
            </View>
          </View>
          <View style={styles.activityBlock}>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={{ fontWeight: 'bold' }}>INSPECTION PASSED</Text>
            </View>
            <Text>{formattedId}</Text>
          </View>
          <Text
            render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
            fixed
            style={{ position: 'absolute', bottom: 16, left: 36 }}
          />
        </Page>
      )}
    </Document>
  );
};

export default ChecklistsPDF;