import React, { useMemo, useState, useEffect } from 'react';
import { Field, Form } from 'react-final-form';

import Button from '../../shared/Button';
import Select from '../../shared/Select';
import Checkbox from '../../shared/Checkbox';
import Input from '../../shared/Input';

import { countTypes } from '../../../constants/enums';

import '../../../styles/shared/form.scss';

const Step1 = (props) => {
  const {
    onClickNext = () => {},
    loading,
    onClose,
    initialValues = {},
    hospitals = [],
    sets = [],
    type,
    setType,
  } = props;

  const [selectedHospital, setSelectedHospital] = useState(null);
  const [search, setSearch] = useState('');

  const validate = (values) => {
    const errors = {};

    if (!values.hospitalId) {
      errors.hospitalId = 'Required';
    }

    return errors;
  };

  useEffect(() => {
    if (initialValues && initialValues.hospitalId) {
      setSelectedHospital(initialValues.hospitalId);

      if (initialValues.kit) {
        setType(countTypes.CONSIGNMENT);
      }
    }
  }, [initialValues]);

  const list = useMemo(() => {
    let result = hospitals;

    if (type === countTypes.CONSIGNMENT) {
      result = hospitals?.filter((hospital) => {
        return sets?.some((set) => !!set.consigned && set?.consignment?.hospital === hospital.id);
      });
    }

    return result.filter((hospital) => hospital?.name?.toLowerCase()?.includes(search?.toLowerCase()))
  }, [type, search]);

  const locationOptions = useMemo(() => {
    const hospital = hospitals.find((item) => {
      if (selectedHospital) {
        return item.id === selectedHospital;
      }
      if (initialValues && initialValues.hospitalId) {
        return item.id === initialValues.hospitalId;
      }
      return false;
    });

    if (!hospital || !hospital.locations) {
      return [];
    }
    return hospital.locations.map((location) => ({ label: location, value: location }));
  }, [selectedHospital, initialValues]);

  return (
    <div>
      <Form
        onSubmit={onClickNext}
        validate={validate}
        subscription={{ pristine: true, invalid: true }}
        initialValues={initialValues}
        mutators={{
          resetHospital: (args, state, utils) => {
            utils.changeValue(state, 'hospitalId', () => null)
          },
          resetLocation: (args, state, utils) => {
            utils.changeValue(state, 'location', () => null)
          },
          // resetUsers: (args, state, utils) => {
          //   utils.changeValue(state, 'taggedUsers', () => [])
          // },
        }}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine, form }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <div className='m-b-lg'>
                <div className='field__title'>Count Type</div>
                <Select
                  value={type}
                  onChange={(value) => {
                    setType(value);
                    form.mutators?.resetLocation();
                    form.mutators?.resetHospital();
                  }}
                  options={[
                    { label: 'Free Count', value: countTypes.FREE },
                    { label: 'Consignment Count', value: countTypes.CONSIGNMENT }
                  ]}
                />
              </div>

              <div>
                <Input
                  type='search'
                  placeholder='Search Locations'
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>

              <div className=''>
                <Field name='hospitalId'>
                  {({ input, meta }) => (
                    <>
                      <div className='field__title'>Location</div>
                      <div className='m-b-lg form__select-users' style={{ maxHeight: 250 }}>
                        {list?.length ? (
                          list?.map((hospital) => (
                            <div className='md-user-info' key={hospital.id}>
                              <Checkbox
                                input={{
                                  onClick: () => {
                                    input.onChange(hospital.id);
                                    setSelectedHospital(hospital.id);
                                    form.mutators.resetLocation();
                                  },
                                  checked: hospital.id === input.value
                                }}
                                fontSize='small'
                                label={hospital.name}
                              />
                            </div>
                          ))
                        ) : (
                          <div className='text-center secondary p-md'>
                            No locations
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </Field>
              </div>

              <div className='m-b-lg'>
                <div className='field__title'>Sub-location</div>
                <Field name='location'>
                  {({ input, meta }) => (
                    <Select
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      options={locationOptions}
                    />
                  )}
                </Field>
              </div>

              <div className='form__actions'>
                <Button
                  type='submit'
                  text='Continue'
                  disabled={invalid || loading}
                  loading={loading}
                  onClick={handleSubmit}
                />
                <Button
                  type='cancel'
                  onClick={onClose}
                />
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default Step1;
