import React from 'react';

import Select from '../../shared/Select';

const SurgeonsFilters = (props) => {
  const {
    procedure,
    procedures = [],
    setProcedure
  } = props;

  return (
    <div className='settings-cmp__filters--main'>
      <div className='settings-cmp__actions'>
      </div>

      <div className='settings-cmp__filters'>
        <Select
          value={procedure}
          onChange={setProcedure}
          options={procedures?.map((p) => ({ label: p.name, value: p.id }))}
          defaultLabel={`All procedures (${procedures?.length})`}
          width={300}
        />
      </div>
    </div>
  )
};

export default SurgeonsFilters;
