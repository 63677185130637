import {
  GET_COUNT, SET_COUNT,
  SET_COUNT_NOTES, SET_COUNT_SCANS,
  CLEAR_COUNT,
} from '../actions/actionTypes';

const initialState = {
  data: {
    id: '',
    taggedUsers: [],
  },
  notes: [],
  scans: [],
  isLoading: false,
  isLoaded: false
};

const countReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNT:
      return { ...state, isLoading: true };
    case SET_COUNT:
      return {
        ...state,
        data: action.count,
        isLoaded: true,
        isLoading: false
      };
    case SET_COUNT_NOTES:
      return {
        ...state,
        notes: action.notes,
      };
    case SET_COUNT_SCANS:
      return {
        ...state,
        scans: action.scans,
      };
    case CLEAR_COUNT:
      return initialState;
    default:
      return state;
  }
};

export default countReducer;
