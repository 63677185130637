import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function CheckIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M12.5726 1.88053C13.2419 2.57778 13.2192 3.68559 12.522 4.35489C11.8697 4.98106 11.073 5.95613 9.76254 7.58337C8.44296 9.22192 7.24855 10.8432 6.73827 11.7593C6.44729 12.2816 5.90974 12.6193 5.31283 12.6546C4.71592 12.69 4.14229 12.4181 3.79173 11.9336C3.62656 11.7054 3.23537 11.259 2.75705 10.7632C2.28475 10.2736 1.8341 9.84617 1.58615 9.64461C0.836174 9.03497 0.722413 7.93279 1.33205 7.18282C1.94169 6.43285 3.04388 6.31908 3.79385 6.92872C4.1327 7.20417 4.57376 7.62134 4.99723 8.04822C5.61575 7.18926 6.33356 6.26107 7.03662 5.38807C8.3623 3.74196 9.27527 2.61991 10.0982 1.82993C10.7955 1.16063 11.9033 1.18328 12.5726 1.88053Z"
          clipRule="evenodd" />
  </SvgIcon>
}
