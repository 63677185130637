import React, { useState, useEffect } from 'react';

import DeleteIcon from '../../../assets/icons/DeleteIcon';

import Input from '../Input';
import Button from '../Button';
import Modal from '../modal/Modal';

import './modal-select.scss';

const HospitalLocationsModal = (props) => {
  const {
    isOpen,
    onClose,
    onSubmit,
    initialValues,
  } = props;

  const [locations, setLocations] = useState(initialValues || []);
  const [newLocation, setNewLocation] = useState('');

  useEffect(() => {
    setLocations(initialValues);
  }, [initialValues]);

  const handleAdd = () => {
    setLocations([...locations, newLocation]);
    setNewLocation('');
  };

  const handleDelete = (location) => {
    setLocations([...locations].filter((item) => item !== location));
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <div className='form__container'>
        <div className='form__body'>

          <div className='form__subtitle'>
            Add Locations
          </div>

          <div className='d-flex'>
            <Input
              type='text'
              placeholder='New Location'
              value={newLocation}
              onChange={(e) => setNewLocation(e.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  handleAdd();
                }
              }}
            />
            <div className='m-b-lg m-l-md'>
              <Button
                type='submit'
                text='Add'
                onClick={handleAdd}
                width={100}
                disabled={!newLocation.length || locations.includes(newLocation)}
              />
            </div>
          </div>

          <div className='field__title'>Locations</div>
          <div className='m-b-lg form__select-users'>
            {locations.length ? (
              locations.map((location) => (
                <div className='md-user-info m-b-sm' key={location}>
                  <span>
                    {location}
                  </span>
                  <div className='md-user-info__delete' onClick={() => handleDelete(location)}>
                    <DeleteIcon fontSize='small' color="#d32f2f" />
                  </div>
                </div>
              ))
            ) : (
              <div className='text-center secondary p-md'>
                No Locations
              </div>
            )}
          </div>

          <div className='form__actions'>
            <Button
              type='submit'
              text='Submit'
              onClick={() => {
                onSubmit(locations);
                onClose();
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default HospitalLocationsModal;
