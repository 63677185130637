import React from 'react';

import ChecklistIcon from '../../../assets/icons/ChecklistIcon';

import { caseChecklistStatuses } from '../../../constants/enums';

import './checklists.scss';

const ChecklistInfo = (props) => {
  const { kitId, kitName, type, setNumber, status, quantity, containerSize } = props;

  return (
    <div className='case-checklists-info__container flex-1 m-r-lg'>
      <div className='d-flex space-between'>
        <div className='d-flex'>
          <ChecklistIcon color={caseChecklistStatuses[status]?.color} />
          <span className='font-bold font-size-bg m-l-sm'>{setNumber}</span>
        </div>
        <div className='font-bold font-size-bg' style={{ color: caseChecklistStatuses[status]?.color }}>
          {caseChecklistStatuses[status]?.label}
        </div>
      </div>
      <div className='d-flex space-between'>
        <div>
          <span className='font-bold m-r-sm'>{kitId}</span>
          <span>{`${kitName} – ${type}`}</span>
        </div>
        <div>QTY {quantity}</div>
      </div>
      <div>
        Container size {containerSize}
      </div>
    </div>
  );
};

export default ChecklistInfo;
