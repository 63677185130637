import React, { useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { orderBy } from 'lodash';

import Button from '../shared/Button';
import Input from '../shared/Input';
import Select from '../shared/Select';
import ModalSelectInput from '../shared/modal-select/ModalSelectInput';
import UsersModal from '../shared/modal-select/UsersModal';

import { roleNames } from '../../constants/userRoles';

import '../../styles/shared/form.scss';

const { ADMIN, OPERATIONS, CUSTOMER_SERVICE, LOGISTICS, SALES_MANAGER, MARKETING, FINANCE } = roleNames;

const GroupForm = (props) => {
  const {
    mode = 'create',
    initialValues,
    buttonText,
    onSubmit,
    loading,
    users,
    onClose,
    currentUser,
  } = props;

  const { name } = initialValues || {};

  const [isModalOpen, toggleModal] = useState(false);

  const userList = useMemo(() => {
    return users.map((user) => ({ value: user.uid, label: user.displayName, role: user.role }));
  }, [users]);

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Required';
    }
    if (!values.description) {
      errors.description = 'Required';
    }
    if (!values.groupOwner) {
      errors.groupOwner = 'Required';
    }

    return errors;
  };

  const getMemberNames = (userIds) => {
    return userIds.map((uid) => {
      const member = users.find((user) => user.uid === uid);
      return member ? member.displayName : '';
    })
  };

  return (
    <div className='form__container'>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{
          ...initialValues,
          members: initialValues?.members || [],
          groupOwner: initialValues?.groupOwner || currentUser.uid,
        }}
        subscription={{ pristine: true, invalid: true }}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <div className='form__subtitle'>
                { name ? name : 'Add Group' }
              </div>
              { mode === 'update' && (
                <Field name='active'>
                  {({ input, meta }) => (
                    <Input
                      type='switch'
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      placeholder='Status'
                    />
                  )}
                </Field>
              )}
              <div className='combined-fields__container'>
                <Field name='name'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      borderless
                      startAdornment='Name'
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
                <Field name='description'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      startAdornment='Description'
                      borderless
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              { mode === 'update' && (
                <Field name='members'>
                  {({ input, meta }) => (
                    <div className='m-b-lg'>
                      <div className='field__title'>Members</div>
                      <ModalSelectInput
                        onClick={() => toggleModal(true)}
                        count={input.value.length}
                        values={getMemberNames(input.value)}
                        label='Edit Members'
                        icon='users'
                      />

                      <UsersModal
                        title={initialValues?.name}
                        onChange={input.onChange}
                        selected={input.value}
                        onClose={() => toggleModal(false)}
                        isOpen={isModalOpen}
                        listTitle='Members'
                        users={orderBy(users, 'lastName', 'asc')}
                      />
                    </div>
                  )}
                </Field>
              )}

              <div className='m-b-lg'>
                <div className='field__title'>Group Owner</div>
                <Field name='groupOwner'>
                  {({ input, meta }) => (
                    <Select
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      options={userList.filter((user) => user.role === ADMIN)}
                      endAdornment='Change'
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-lg'>
                <div className='field__title'>Group Manager</div>
                <Field name='groupManager'>
                  {({ input, meta }) => (
                    <Select
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      options={userList.filter((user) => [OPERATIONS, CUSTOMER_SERVICE, LOGISTICS, SALES_MANAGER, MARKETING, FINANCE].includes(user.role))}
                      defaultLabel='No Manager'
                      endAdornment='Add Manager'
                    />
                  )}
                </Field>
              </div>

              <div className='form__actions'>
                <Button
                  type='submit'
                  text={buttonText || 'Save Changes'}
                  disabled={invalid || pristine || loading}
                  loading={loading}
                  onClick={handleSubmit}
                />
                { mode === 'create' && (
                  <Button
                    type='cancel'
                    onClick={onClose}
                  />
                )}
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default GroupForm;
