import React from 'react';

import DeleteIcon from '../../assets/icons/DeleteIcon';

import Button from '../shared/Button';
import Select from '../shared/Select';

const ProductTriggersFilters = (props) => {
  const {
    onAddClick,
    status,
    setStatus,
    statuses,
    onDelete,
  } = props;

  return (
    <div className='settings-cmp__filters--main'>
      <div className='settings-cmp__actions'>
        <Button
          text='Add Trigger'
          type='submit'
          onClick={onAddClick}
        />

        <Button
          type='icon'
          onClick={onDelete}
        >
          <DeleteIcon style={{ color: '#000000' }}/>
        </Button>
      </div>

      <div className='settings-cmp__filters'>
        <Select
          value={status}
          onChange={setStatus}
          options={statuses}
          defaultLabel={`All Status (${statuses.length})`}
          width={200}
        />
      </div>
    </div>
  )
};

export default ProductTriggersFilters;
