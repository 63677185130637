import React, { useState } from 'react';

import DispatchIcon from '../../../assets/icons/DocumentIcon';

import Modal from '../../shared/modal';
import Button from '../../shared/Button';
import Checkbox from '../../shared/Checkbox';

const DispatchModal = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    loading,
    dispatchDocuments = []
  } = props;

  const [document, setDocument] = useState(true);
  const [checklist, setChecklist] = useState(true);
  const [checklistAdditional, setChecklistAdditional] = useState(true);
  const [selected, setSelected] = useState([]);

  const handleSubmit = () => {
    onSubmit(document, checklist, checklistAdditional, selected);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Dispatch Documents'
      fullWidth
    >
      <div style={{ padding: '0 24px 24px' }}>
        <div className='d-flex secondary'>
          <DispatchIcon />
          <div className='font-bold m-l-sm'>Dispatch documents available for export</div>
        </div>

        <div className='m-t-lg'>
          <div className='secondary m-b-sm'>Select to Export</div>
          <div className='dispatch-modal-checkboxes__container'>
            <Checkbox
              label='Dispatch document (shipped items)'
              input={{
                checked: document,
                onChange: (e) => setDocument(e.target.checked)
              }}
              fontSize='small'
            />
            <Checkbox
              label='Checklists (submitted status)'
              input={{
                checked: checklist,
                onChange: (e) => setChecklist(e.target.checked)
              }}
              fontSize='small'
            />
            <Checkbox
              label='Checklists (inc. additional items)'
              input={{
                checked: checklistAdditional,
                onChange: (e) => setChecklistAdditional(e.target.checked)
              }}
              fontSize='small'
            />
            {dispatchDocuments?.map((doc) => (
              <Checkbox
                key={doc.path}
                label={doc.title}
                input={{
                  checked: selected?.map((item) => item.id)?.includes(doc.id),
                  onChange: () => {
                    if (selected?.map((item) => item.id)?.includes(doc.id)) {
                      setSelected([...selected]?.filter((item) => item.id !== doc.id))
                    } else {
                      setSelected([...selected, doc]);
                    }
                  }
                }}
                fontSize='small'
              />
            ))}
          </div>
        </div>

        <div className='form__actions p-t-lg'>
          <Button
            type='submit'
            text='Export'
            onClick={handleSubmit}
            disabled={loading || (!document && !checklist && !checklistAdditional && !selected?.length)}
            loading={loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DispatchModal;
