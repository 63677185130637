import React from 'react';
import {
  Document,
  Page,
  View,
  Text,
  Font,
  StyleSheet,
  Image
} from '@react-pdf/renderer';
import moment from 'moment/moment';

import InterRegular from '../../../assets/fonts/Inter-Regular.ttf';
import InterBold from '../../../assets/fonts/Inter-Bold.ttf';

const styles = StyleSheet.create({
  container: {
    padding: 36,
    fontFamily: 'Inter',
    fontSize: 10,
    fontWeight: 'normal',
    color: '#202124',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  titleBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  bold: {
    fontFamily: 'Inter',
    fontWeight: 'bold'
  },
  logo: {
    width: 150,
    height: 'auto',
  },
  title: {
    fontSize: 18,
    marginBottom: 4,
    fontFamily: 'Inter',
    fontWeight: 'bold'
  },
  row: {
    marginTop: 2,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  table: {
    marginTop: 16,
    paddingBottom: 8,
    borderBottom: '1px solid #000000',
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    fontFamily: 'Inter',
    paddingTop: 4,
    paddingBottom: 4,
    borderTop: '1px solid #000000',
    borderBottom: '1px solid #000000',
    fontSize: 9
  },
  tableRow: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tableCell: {
    padding: 3,
    fontSize: 8,
    fontFamily: 'Inter'
  },
  contactContainer: {
    marginTop: 32,
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 16,
    borderTop: '1px solid #CCCCCC',
  },
  contactRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 2
  }
});

Font.register({
  family: 'Inter',
  fonts: [
    {
      src: InterRegular,
    },
    {
      src: InterBold,
      fontWeight: 'bold',
    },
  ],
});

const getFormattedId = (caseId) => {
  if (!caseId) {
    return ''
  }
  const arr = caseId?.split('-');
  return arr?.length > 1 ? `${arr[0]}-${arr[1]}` : caseId;
};

const DispatchPDF = (props) => {
  const {
    activeCase,
    tenant,
    hospital,
    deliveryAddress,
    surgeon,
    procedure,
    procedureOption,
    setsAllocation = [],
    kits = [],
    items = [],
    sets = [],
  } = props;

  const uniqueTrackingNumbers = setsAllocation.filter((setItem, idx, arr) => arr.findIndex((item) => (item.trackingNumber === setItem.trackingNumber)) === idx);
  const allocationsData = setsAllocation.map((allocation) => {
    let kitId = '';
    let kitName = ''

    if (allocation.kit) {
      const kit = kits?.find((k) => k.id === allocation.kit);
      kitId = kit ? kit.kitId : '';
      kitName = kit ? kit.name : '';
    } else if (allocation.itemId) {
      const item = items?.find((i) => i.id === allocation.itemId);
      kitId = item ? item.code : '';
      kitName = item ? (item.checklistDescription || item.description) : '';
    }

    const setList = allocation && allocation.sets ? allocation.sets?.map((setId) => sets?.find((s) => s.id === setId)) : [];
    const setNumbers = setList
      .filter((set) => !!set && !!set.number)
      .map((set) => set.number)
      .join(', ');

    const quantity = allocation.quantity || 1;
    const consumables = allocation.consumables && allocation.consumables.length ? (
      allocation.consumables[0]?.id ? allocation.consumables?.map((c) => c.label).join(', ') : allocation.consumables.join(', ')
    ) : '';

    return {
      trackingNumber: allocation.trackingNumber,
      kitId,
      kitName,
      setNumbers: setNumbers && setNumbers?.length ? setNumbers : consumables,
      quantity
    };
  });

  const billingAccountName = tenant?.financialDetails?.billingAccountName || tenant?.businessName || '';

  return (
    <Document>
      <Page size="A4" style={styles.container}>
        <View>
          <View style={styles.titleBlock}>
            <View>
              <Image
                src={tenant?.documentLogo?.downloadUrl}
                style={styles.logo}
              />
              <Text style={{ marginTop: 2 }}>{billingAccountName}</Text>
              <Text style={{ marginTop: 2 }}>{`${tenant?.address1}, ${tenant?.address2}`}</Text>
              <Text style={{ marginTop: 2 }}>{`${tenant?.city}${tenant?.state ? `, ${tenant?.state}` : ''}${tenant?.postCode ? `, ${tenant?.postCode}` : ''}`}</Text>
              <Text style={{ marginTop: 2 }}>{tenant?.businessNumber}</Text>
            </View>
            <View>
              <Text style={styles.title}>
                DISPATCH DOCUMENT
              </Text>
              <View style={styles.row}>
                <Text>Date: </Text><Text style={styles.bold}>{moment()?.local()?.format('Do MMMM YYYY')}</Text>
              </View>
              <View style={styles.row}>
                <Text>Case #: </Text><Text style={styles.bold}>{getFormattedId(activeCase.id)}</Text>
              </View>
              {hospital?.accountNumber && (
                <View style={styles.row}>
                  <Text>Account #: </Text><Text style={styles.bold}>{hospital?.accountNumber}</Text>
                </View>
              )}
              {hospital?.businessNumber && (
                <View style={styles.row}>
                  <Text>Business #: </Text><Text style={styles.bold}>{hospital?.businessNumber}</Text>
                </View>
              )}
            </View>
          </View>

          <View style={{ marginTop: 32, display: 'flex', flexDirection: 'row' }}>
            <View style={{ flexGrow: 1 }}>
              <Text style={styles.bold}>Shipping To:</Text>
              <Text style={{ marginTop: 4 }}>{hospital?.name}</Text>
              {deliveryAddress?.label && <Text style={{ marginTop: 2 }}>{deliveryAddress?.label}</Text>}
              {(deliveryAddress?.apartment || deliveryAddress?.buildingName) && (
                <Text style={{ marginTop: 2 }}>
                  {`${deliveryAddress?.apartment || ''}${deliveryAddress?.apartment && deliveryAddress?.buildingName ? ' | ' : ''}${deliveryAddress?.buildingName || ''}`}
                </Text>
              )}
              <Text style={{ marginTop: 2 }}>{deliveryAddress?.street}</Text>
              <Text style={{ marginTop: 2 }}>{`${deliveryAddress?.city}${deliveryAddress?.state ? `, ${deliveryAddress?.state}` : ''}${deliveryAddress?.postCode ? `, ${deliveryAddress?.postCode}` : ''}`}</Text>
              {(deliveryAddress?.neighborhood || deliveryAddress?.department) && (
                <Text style={{ marginTop: 2 }}>
                  {`${deliveryAddress?.neighborhood || ''}${deliveryAddress?.neighborhood && deliveryAddress?.department ? ' | ' : ''}${deliveryAddress?.department || ''}`}
                </Text>
              )}
              {(deliveryAddress?.attn || deliveryAddress?.phone) && (
                <Text style={{ marginTop: 2 }}>
                  {`${deliveryAddress?.attn || ''}${deliveryAddress?.attn && deliveryAddress?.phone ? ' | ' : ''}${deliveryAddress?.phone || ''}`}
                </Text>
              )}
            </View>
            <View style={{ flexGrow: 1 }}>
              <Text style={styles.bold}>Case Information:</Text>
              <View style={{ marginTop: 4, flexDirection: 'row' }}>
                <Text>Surgery Date: </Text><Text style={styles.bold}>{activeCase?.date ? moment(activeCase?.date).format('Do MMMM YYYY') : ''}</Text>
              </View>
              <View style={{ marginTop: 2, flexDirection: 'row' }}>
                <Text>Surgeon: </Text><Text style={styles.bold}>{`${surgeon?.title} ${surgeon?.firstName} ${surgeon?.lastName}`}</Text>
              </View>
              <View style={{ marginTop: 2, flexDirection: 'row' }}>
                <Text>Patient ID: </Text><Text style={styles.bold}>{activeCase.patientReference}</Text>
              </View>
              <View style={{ marginTop: 2, flexDirection: 'row' }}>
                <Text>Procedure: </Text><Text style={styles.bold}>{`${procedure?.name}${procedureOption?.name ? `, ${procedureOption?.name}` : ''}`}</Text>
              </View>
            </View>
          </View>

          {uniqueTrackingNumbers?.filter((item) => !!item.trackingNumber)?.map((item) => (
            <View key={item.trackingNumber} style={{ marginTop: 32 }}>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View style={{ flexGrow: 1 }}>
                  <View style={{ flexDirection: 'row' }}>
                    <Text>Shipping Date: </Text><Text style={styles.bold}>{moment(item.shippingDate).local().format('Do MMMM YYYY')}</Text>
                  </View>
                </View>
                <View style={{ flexGrow: 1 }}>
                  <View style={{ flexDirection: 'row' }}>
                    <Text>Tracking #: </Text><Text style={styles.bold}>{item.trackingNumber || ''}</Text>
                  </View>
                </View>
              </View>

              <View style={styles.table}>
                <View style={styles.tableHeader}>
                  <View style={{ ...styles.tableCell, width: '65%' }}><Text>KIT / ITEM NAME</Text></View>
                  <View style={{ ...styles.tableCell, width: '10%' }}><Text style={{ textAlign: 'center' }}>QTY</Text></View>
                  <View style={{ ...styles.tableCell, width: '25%' }}><Text>SET/SERIAL #</Text></View>
                </View>

                {allocationsData?.filter((allocation) => allocation.trackingNumber === item.trackingNumber)?.map((allocation) => (
                  <View key={Math.random()} style={styles.tableRow}>
                    <View style={{ ...styles.tableCell, width: '65%' }}><Text>{allocation.kitName}</Text></View>
                    <View style={{ ...styles.tableCell, width: '10%' }}><Text style={{ textAlign: 'center' }}>{allocation.quantity}</Text></View>
                    <View style={{ ...styles.tableCell, width: '25%' }}><Text>{allocation.setNumbers}</Text></View>
                  </View>
                ))}
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default DispatchPDF;