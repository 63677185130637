import React, { useState, useEffect } from 'react';

import DeleteIcon from '../../assets/icons/DeleteIcon';

import Input from '../shared/Input';
import Button from '../shared/Button';
import Modal from '../shared/modal/Modal';

import { validateEmail } from '../../utils/validators';

const EmailsModal = (props) => {
  const {
    title,
    onSubmit,
    onClose,
    isOpen,
    initialList,
  } = props;

  const [emails, setEmails] = useState(initialList);
  const [newEmail, setNewEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    setEmails(initialList);
  }, [initialList]);

  const onAddNewEmail = () => {
    const value = newEmail?.trim();
    if (value && !emails.includes(value) && validateEmail(value)) {
      setEmails([...emails, value]);
      setNewEmail('');
    } else {
      setEmailError(true);
    }
  };

  const onDeleteEmail = (email) => {
    setEmails(emails.filter((e) => e !== email));
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <div className='form__container'>
        <div className='form__body'>

          <div className='form__subtitle'>
            {title}
          </div>

          <div className='d-flex'>
            <Input
              type='email'
              error={emailError}
              placeholder='New Email'
              value={newEmail}
              onChange={(e) => {
                setNewEmail(e.target.value);
                setEmailError(false);
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  onAddNewEmail();
                }
              }}
            />
            <div className="m-b-lg m-l-md">
              <Button
                type='submit'
                text='Add'
                onClick={onAddNewEmail}
                width={100}
                disabled={!newEmail.length}
              />
            </div>
          </div>

          <div className='field__title'>Emails</div>
          <div className='m-b-lg form__select-users'>
            {emails.length ? (
              emails.map((email) => (
                <div className='md-user-info p-t-md p-b-md' key={`${email}_${Math.random()}`}>
                  <div>
                    {email}
                  </div>
                  <div className="md-user-info__delete" onClick={() => onDeleteEmail(email)}>
                    <DeleteIcon fontSize="small" color='#d32f2f' />
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center secondary p-md">
                No Emails
              </div>
            )}
          </div>

          <div className='form__actions'>
            <Button
              type='submit'
              text='Save Changes'
              onClick={() => {
                onSubmit(emails);
                onClose();
              }}
            />
            <Button
              type='cancel'
              text='Close'
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EmailsModal;
