import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function BookedIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none"
          d="M4.18522 2.37195C3.83268 2.41584 3.48403 2.46152 3.13969 2.50382C2.66061 2.56268 2.28199 2.94669 2.2481 3.42818C2.0423 6.35152 2.0423 9.11339 2.2481 12.0367C2.282 12.5182 2.6607 12.9034 3.14169 12.9437C5.78943 13.1657 8.21057 13.1657 10.8583 12.9437C11.3393 12.9034 11.718 12.5182 11.7519 12.0367C11.9577 9.11339 11.9577 6.35152 11.7519 3.42818C11.718 2.94669 11.3394 2.56268 10.8603 2.50382C10.516 2.46152 10.1673 2.41584 9.81478 2.37195" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M5.09375 6.51562H8.90625" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M5.09375 9.42188H7.3944" />
    <rect width={5.656} height={2.969} x={4.172} y={0.75} stroke={color || defaultColor} fill="none"
          strokeLinejoin="round" rx={1.484} />
  </SvgIcon>
}
