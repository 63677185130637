import { GET_PROCEDURES, SET_PROCEDURES, SET_PROCEDURE_OPTIONS } from '../actions/actionTypes';

const initialState = {
  list: [],
  procedureOptions: [],
  isLoading: false,
  isLoaded: false
};

const proceduresReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROCEDURES:
      return { ...state, isLoading: true };
    case SET_PROCEDURES:
      return {
        ...state,
        list: action.procedures,
        isLoaded: true,
        isLoading: false
      };
    case SET_PROCEDURE_OPTIONS:
      return {
        ...state,
        procedureOptions: action.options,
      };
    default:
      return state;
  }
};

export default proceduresReducer;
