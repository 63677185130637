import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function ApprovedUsageIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M1 11.125L2.81818 13C3.67375 10.5423 4.38628 9.46386 6 8" />
    <path stroke={color || defaultColor} fill="none" strokeLinejoin="round"
          d="M8.06567 0.79541V4.0008C8.06567 4.55309 8.51274 4.9789 9.06479 4.99485C10.4535 5.03496 12.4966 5.40942 12.4966 7.90387" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M1.5 7.04004C1.5 5.64034 1.54349 4.27437 1.62622 2.9562C1.68673 1.99218 2.45206 1.22505 3.4157 1.15879C4.54845 1.08091 5.64305 1.04004 7 1.04004C7.44825 1.04004 7.86786 1.0445 8.26969 1.05328C8.67041 1.06204 9.05979 1.19491 9.3771 1.43981C10.3705 2.2065 11.0279 2.86491 11.7522 3.79683C12.2307 4.41261 12.4786 5.17471 12.4912 5.95449C12.4971 6.31389 12.5 6.67582 12.5 7.04004C12.5 8.43973 12.4565 9.80571 12.3738 11.1239C12.3133 12.0879 11.5479 12.855 10.5843 12.9213C9.45155 12.9992 8.35695 13.04 7 13.04" />
  </SvgIcon>
}
