import React from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowIcon from '@material-ui/icons/KeyboardArrowDown';

const icon = <ArrowIcon style={{ color: '#c5c8c9' }} />

const CountrySelect = ({ countries, initialValues, input }) => {
  const getCountryFlag = (countryCode) => {
    return typeof String.fromCodePoint !== 'undefined'
      ? countryCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : countryCode;
  };

  return (
    <Autocomplete
      id='country-select-demo'
      style={{ paddingLeft: '16px !important' }}
      options={countries}
      defaultValue={initialValues && initialValues.country}
      popupIcon={icon}
      onInputChange={(event, option) => {
        input.onChange(option);
      }}
      disableClearable
      getOptionLabel={option => typeof option.label === 'string' ? option.label : option}
      renderOption={option => (
        <div style={{ paddingTop: '12px', paddingBottom: '12px' }}>
          <span className='m-r-md'>{getCountryFlag(option.code)}</span>
          {option.label}
        </div>
      )}
      renderInput={params => (
        <TextField
          {...params}
          className='form__phone p-l-lg'
          style={{
            paddingLeft: '16px !important',
            paddingRight: '8px !important',
            color: input.value ? '#0b0b0b' : '#a7a7a7 !important'
          }}
          type='text'
          placeholder='E.g. Australia'
          value={input.value}
          name={input.name}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
            'aria-label': 'naked'
          }}
        />
      )}
    />
  )
}

export default CountrySelect;
