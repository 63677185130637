import React, { useMemo } from 'react';
import { Field, Form } from 'react-final-form';

import Button from '../../shared/Button';
import Input from '../../shared/Input';
import Select from '../../shared/Select';

import { roleNames } from '../../../constants/userRoles';
import { caseStatusOptions, kitVariantTypes } from '../../../constants/enums';

const Step4 = (props) => {
  const {
    initialValues,
    hospitals,
    userRole,
    userAddresses = [],
    onClickNext,
    onClickBack,
    onClose,
    status,
  } = props;

  const validate = (values) => {
    const errors = {};
    return errors;
  };

  const options = useMemo(() => {
    let addresses = [];
    const hospital = hospitals.find((item) => item.id === initialValues.hospital);

    if (!hospital || !hospital.deliveryAddresses) {
      addresses = [];
    } else {
      addresses = hospital.deliveryAddresses.map((address) => ({
        label: `${address.label} (${address.street}${address.city ? `, ${address.city}` : ''})`,
        value: address.id,
      }));
    }

    if (userRole === roleNames.SALES_REP) {
      userAddresses.forEach((address) => {
        addresses.push({ label: address.label, value: address.id });
      });
    }

    return addresses;
  }, [hospitals, userRole, userAddresses]);

  const initialFormValues = useMemo(() => {
    if (!initialValues) {
      return {};
    }
    if (initialValues.deliveryAddress) {
      return initialValues;
    }
    if (options && options.length) {
      const hospital = hospitals.find((item) => item.id === initialValues.hospital);
      const defaultAddress = hospital?.deliveryAddresses?.find((address) => !!address.default);
      const deliveryAddress = defaultAddress ? options?.find((o) => o.value === defaultAddress.id)?.value : options[0].value;

      return { ...initialValues, deliveryAddress };
    }
    return initialValues;
  }, [options, initialValues]);

  return (
    <div>
      <Form
        onSubmit={onClickNext}
        validate={validate}
        initialValues={initialFormValues}
        subscription={{ pristine: true, invalid: true }}
      >
        {
          ({ handleSubmit, invalid}) => (
            <form onSubmit={handleSubmit} className='form__body'>

              <div>
                <div className='field__title'>Booking Note</div>
                <div>
                  <Field name='bookingNote'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        type='textarea'
                        onChange={input.onChange}
                        disabled={status && status === caseStatusOptions.completed}
                      />
                    )}
                  </Field>
                </div>
              </div>

              <div className='m-b-lg'>
                <div className='field__title'>Delivery Address</div>
                <div className='m-b-lg'>
                  <Field name='deliveryAddress'>
                    {({ input, meta }) => (
                      <Select
                        disabled={(initialValues?.kitVariant === kitVariantTypes.consignment) || (status && (status === caseStatusOptions.completed || status === caseStatusOptions.overdue))}
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                        options={options}
                      />
                    )}
                  </Field>
                </div>
              </div>

              <div className='form__actions'>
                <div className='d-flex'>
                  <div className='m-r-md'>
                    <Button
                      type='cancel'
                      text='Back'
                      onClick={onClickBack}
                    />
                  </div>
                  <Button
                    type='submit'
                    text='Next'
                    disabled={invalid}
                    onClick={handleSubmit}
                  />
                </div>
                <Button
                  type='cancel'
                  onClick={onClose}
                />
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default Step4;
