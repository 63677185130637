import React, { useState, useCallback } from 'react';

import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

import MoreIcon from '@material-ui/icons/MoreVert';
import TimeIcon from '../../assets/icons/TimeIcon';

import { getDiffDays } from '../../utils/date';

import palette from '../../styles/theme/palette';
import './customers.scss';

const colors = palette().primary;

const ITEM_HEIGHT = 48;

const StyledMenuItem = withStyles(() => ({
  root: {
    paddingTop: '12px',
    paddingBottom: '12px',
  }
}))(MenuItem);

const TaskItem = (props) => {
  const {
    task = {},
    onEditClick = () => {},
    onCompleteClick = () => {},
    surgeons,
    hospitals,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getSurgeonName = useCallback((surgeonId) => {
    const taskSurgeon = surgeons.find((surgeon) => surgeon.id === surgeonId);
    return taskSurgeon ? `${taskSurgeon.title} ${taskSurgeon.firstName} ${taskSurgeon.lastName}` : '';
  }, [task, surgeons]);

  const getHospitalName = useCallback((hospitalId) => {
    const taskHospital = hospitals.find((hospital) => hospital.id === hospitalId);
    return taskHospital && taskHospital.name ? taskHospital.name : '';
  }, [task, hospitals]);

  const getDueDateColor = (date) => {
    const diff = getDiffDays(date);

    if (diff < 1) {
      return colors.danger;
    } else if (diff >= 1 && diff < 6) {
      return colors.warning;
    } else {
      return colors.success;
    }
  };

  const getDueDateLabel = (date) => {
    const diff = getDiffDays(date);

    if (diff > 1) {
      return `Due in ${diff} days`;
    } else if (diff === 1) {
      return 'Due in 1 day';
    } else if (diff === 0) {
      return 'Due today';
    } else {
      return 'Overdue';
    }
  };

  return (
    <div className='list-item'>
      <div className='d-flex space-between m-b-md'>
        <div className='d-flex' style={{ color: getDueDateColor(task.dueDate) }}>
          <TimeIcon className='m-r-sm' />
          {task.dueDate && getDueDateLabel(task.dueDate)}
        </div>
        <IconButton
          className='m-l-md'
          aria-label='more'
          aria-controls='long-menu'
          aria-haspopup='true'
          onClick={handleClick}
          size='small'
        >
          <MoreIcon />
        </IconButton>
        <Menu
          id='long-menu'
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 200,
            },
            elevation: 3,
          }}
        >
          <StyledMenuItem
            onClick={() => {
              onCompleteClick();
              handleClose();
            }}
          >
            <span>Complete</span>
          </StyledMenuItem>
          <StyledMenuItem
            onClick={() => {
              onEditClick();
              handleClose();
            }}
          >
            <span>Edit</span>
          </StyledMenuItem>
        </Menu>
      </div>
      <div className='font-bold'>
        {task.title}
      </div>
      <div className='m-t-md'>
        {task.description}
      </div>
      {!!task.surgeonId && (
        <div className='m-t-md secondary'>
          {getSurgeonName(task.surgeonId)}
        </div>
      )}
      {!!task.hospitalId && (
        <div className='m-t-md secondary'>
          {getHospitalName(task.hospitalId)}
        </div>
      )}
    </div>
  );
};

export default TaskItem;
