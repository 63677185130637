import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function EditIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M7.173 9.804L12.4323 4.82456C13.3065 3.99687 13.2389 2.5317 12.2832 1.59218V1.59218C11.35 0.674773 9.92671 0.611732 9.1041 1.45136L3.9882 6.67319C3.9882 6.67319 5.12148 7.12406 5.91792 7.907C6.71435 8.68993 7.173 9.804 7.173 9.804Z"
          clipRule="evenodd" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M3.74424 10.69L7.17008 9.80817C7.17008 9.80817 6.71246 8.69368 5.91675 7.91002C5.12104 7.12637 3.98817 6.67447 3.98817 6.67447L3.08822 10.0282C2.987 10.4055 3.36565 10.7874 3.74424 10.69Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M12.9375 13H1.22529" />
  </SvgIcon>
}


