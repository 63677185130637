import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import HospitalIcon from '../../../../assets/icons/HospitalIcon';
import TerritoryIcon from '../../../../assets/icons/TerritoryIcon';

import { routes } from '../../../../constants';

const options = [
  {
    title: 'Hospitals',
    subTitle: 'Add or manage hospitals',
    icon: <HospitalIcon className='option-icn' />,
    url: routes.HOSPITALS,
    roles: [],
  },
  {
    title: 'Territories',
    subTitle: 'Add or manage territories',
    icon: <TerritoryIcon className='option-icn' />,
    url: routes.TERRITORIES,
    roles: [],
  },
];

const HospitalsLocationsPage = () => {
  const tenantColor = useSelector((state) => state.tenant.currentTenant.colorPrimary);

  return (
    <div className='page-container flex-1'>
      <div className='page-title'>
        Hospitals, Locations & Territories
      </div>

      <div className='customers-page__body'>
        <Grid container spacing={1}>
          {
            options.map(option => (
              <Grid key={option.title} item xs={12} sm={12} md={4}>
                <Link to={option.url}>
                  <div
                    className='customers-page-option'
                    key={`${option.title}_${Math.random()}`}
                    style={{ '--colorHover': `${tenantColor}` }}
                  >
                    <span className='option-title'>{option.title}</span>
                    <span className='option-sub-t'>{option.subTitle}</span>
                    <div className='option-icn-cnt'>
                      <span>{option.icon}</span>
                    </div>
                  </div>
                </Link>
              </Grid>
            ))
          }
        </Grid>
      </div>
    </div>
  );
};

export default HospitalsLocationsPage;
