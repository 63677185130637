import React, { useCallback, useMemo } from 'react';
import { orderBy, sum } from 'lodash';

import BarChart from '../../../charts/BarChart';

import colorPalette, { lighterColorPalette } from '../colorPalette';
import { formatChartPrice } from '../../../../utils/utils';

const generateColors = (keys, isPrevious) => {
  const colors = {};
  keys?.forEach((k, index) => {
    colors[k] = isPrevious ? lighterColorPalette[index] : colorPalette[index];
  });
  return colors;
};

export const CustomTooltip = (props) => {
  const { data, value, surgeons } = props;
  const id = data?.id?.includes('compare') ? data?.id?.substring(0, data?.id.indexOf('_compare')) : data?.id;
  const surgeon = surgeons?.find((u) => u.id === id);
  const name = `${surgeon.firstName} ${surgeon.lastName}`;

  return (
    <div className="custom-tooltip">
      <div className="tooltip-label">{surgeon ? (data?.id?.includes('compare') ? `${name} (previous period)` : name) : ''}</div>
      <div className="tooltip-content">
        <div>{`$${formatChartPrice(value, 0)}`}</div>
      </div>
    </div>
  );
};

const SurgeonsProcedureStackedBarChart = ({ cases = [], surgeons = [], procedures = [] }) => {
  const data = useMemo(() => {
    const allSurgeons = surgeons
      .map((surgeon) => {
        const surgeonCases = cases.filter((item) => item.surgeon === surgeon.id);
        let previousCases = [];

        return {
          id: surgeon.id,
          firstName: surgeon.firstName,
          lastName: surgeon.lastName,
          value: sum(surgeonCases?.map((caseItem) => caseItem.proformaValue || 0)),
          cases: surgeonCases,
          previousCases,
        };
      })
      .filter((item) => item.value !== 0);
    const sorted = orderBy(allSurgeons, 'value', 'desc');
    const top10 = sorted.splice(0, 10);

    const keys = [];
    const bars = [];

    top10.forEach((surgeon) => {
      const result = { id: surgeon.id };
      surgeon.cases?.forEach((caseItem) => {
        const procedure = procedures?.find((p) => p.id === caseItem.procedure);
        if (procedure && !keys?.includes(procedure.name)) {
          keys?.push(procedure.name);
        }

        const proformaValue = caseItem.proformaValue || 0;

        if (result[procedure.name] !== undefined) {
          result[procedure.name] = result[procedure.name] + proformaValue;
        } else {
          result[procedure.name] = +proformaValue;
        }
      });

      bars.push(result);
    });

    return { keys, data: bars };
  }, [cases, surgeons, procedures]);

  const colors = useMemo(() => generateColors(data.keys), [data]);

  const getSurgeonName = useCallback((surgeonId) => {
    const id = surgeonId?.includes('compare') ? surgeonId?.substring(0, surgeonId.indexOf('_compare')) : surgeonId;
    const surgeon = surgeons?.find((u) => u.id === id);
    return surgeon ? (surgeonId?.includes('compare') ? `${surgeon.lastName} (vs)` : surgeon.lastName) : '';
  }, [surgeons]);

  return (
    <div className="management-reports-chart__container m-l-md" style={{ width: '50%' }}>
      <div className="font-size-bg font-bold">Top Surgeons by Procedure Value</div>
      <div className="secondary m-t-sm m-b-lg">Top 10 surgeons by procedure value</div>
      <BarChart
        data={data?.data || []}
        isCurrency
        height="300px"
        layout="horizontal"
        enableLabel={true}
        enableTotals={true}
        keys={data?.keys || []}
        indexBy="id"
        colors={(bar) => colors[bar.id]}
        margin={{ top: 0, right: 150, bottom: 30, left: 85 }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          truncateTickAt: 0,
          format: id => getSurgeonName(id),
        }}
        labelTextColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              1.6,
            ],
          ],
        }}
        label={(d) => `$${formatChartPrice(d.value, 0)}`}
        CustomTooltip={(props) => <CustomTooltip {...props} surgeons={surgeons} />}
        legends={[
          {
            data: data.keys?.map((key) => ({ id: key, label: `${key?.substring(0, 15)}${key?.length > 15 ? '...' : ''}`, color: colors[key] })),
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 150,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemTextColor: '#ccc',
            itemDirection: 'right-to-left',
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: 'circle',
            effects: [
                {
                  on: 'hover',
                  style: { itemTextColor: '#000' },
                },
              ],
            },
          ]
        }
      />
    </div>
  );
};

export default SurgeonsProcedureStackedBarChart;
