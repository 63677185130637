import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import TaskIcon from '../../../assets/icons/TaskIcon';
import FormIcon from '../../../assets/icons/FormIcon';
import SurgeonIcon from '../../../assets/icons/SurgeonIcon';
import HospitalIcon from '../../../assets/icons/HospitalIcon';

import { routes } from '../../../constants';

import './customers-page.scss';

const customersOptions = [
  {
    title: 'Surgeons',
    subTitle: 'Manage surgeon preferences',
    icon: <SurgeonIcon className='option-icn' />,
    url: routes.TOOLS_SURGEONS
  },
  {
    title: 'Hospitals',
    subTitle: 'Manage hospital contacts & notes',
    icon: <HospitalIcon className='option-icn' />,
    url: routes.TOOLS_HOSPITALS
  },
  {
    title: 'Tasks',
    subTitle: 'Create and manage your tasks',
    icon: <TaskIcon className='option-icn' />,
    url: routes.TOOLS_TASKS
  },
  {
    title: 'Forms',
    subTitle: 'Add and manage your forms',
    icon: <FormIcon className='option-icn' />,
    url: routes.TOOLS_FORMS
  },
];

const CustomersPage = () => {
  const tenantColor = useSelector((state) => state.tenant.currentTenant.colorPrimary);

  return (
    <div className='page-container flex-1'>
      <div className='page-title'>
        Tools
      </div>

      <div className='customers-page__body'>
        <Grid container spacing={1}>
          {
            customersOptions.map(option => (
              <Grid key={option.title} item xs={12} sm={12} md={4}>
                <Link to={option.url}>
                  <div
                    className='customers-page-option'
                    key={`${option.title}_${Math.random()}`}
                    style={{ '--colorHover': `${tenantColor}` }}
                  >
                    <span className='option-title'>{option.title}</span>
                    <span className='option-sub-t'>{option.subTitle}</span>
                    <div className='option-icn-cnt'>
                      <span>{option.icon}</span>
                    </div>
                  </div>
                </Link>
              </Grid>
            ))
          }
        </Grid>
      </div>
    </div>
  );
};

export default CustomersPage;
