const countries = [
  { label: 'Australia', code: 'AU' },
  { label: 'France', code: 'FR' },
  { label: 'Germany', code: 'DE' },
  { label: 'Japan', code: 'JP' },
  { label: 'New Zealand', code: 'NZ' },
  { label: 'Singapore', code: 'SG' },
  { label: 'United Kingdom', code: 'GB' },
  { label: 'United States', code: 'US' },
];

const countryAlphaCodes = {
  'Australia': 'au',
  'New Zealand': 'nz',
  'United States': 'us',
  'Singapore': 'sg',
  'Japan': 'jp',
  'United Kingdom': 'gb',
  'Germany': 'de',
  'France': 'fr'
};

export default countries;
export { countryAlphaCodes };
