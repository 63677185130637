import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import CircularProgress from '@material-ui/core/CircularProgress';

import ApproveUsageIcon from '../../../assets/icons/ApproveUsageIcon';
import ApprovedUsageIcon from '../../../assets/icons/ApprovedUsageIcon';
import ClosedUsageIcon from '../../../assets/icons/CheckIcon';
import RepeatIcon from '../../../assets/icons/RepeatIcon';

import ActionButton from '../../../components/shared/ActionButton';
import Input from '../../../components/shared/Input';
import LoadScreen from '../../../components/load-screen';
import { UsageTable, UsageFiltersModal, UsageFiltersList } from '../../../components/usage';
import Button from '../../../components/shared/Button';

import { setCaseView, setUsageFilters, toggleClosed } from '../../../actions/casesActions';
import { getSurgeons } from '../../../actions/surgeonsActions';
import { getHospitals } from '../../../actions/hospitalsActions';
import {
  setOrder,
  setOrderBy,
  setPage,
  setRowsPerPage,
  setSearch,
  setActiveStatuses,
  getCases,
  getCompletedCases,
} from '../../../actions/usageActions';
import { getUsageList } from '../../../reducers/selectors';

import { useLoading } from '../../../hooks';
import { getDiffDays } from '../../../utils/date';

import { caseStatusOptions, caseUsageTypes, setAllocationStatuses } from '../../../constants/enums';
import routes from '../../../constants/routes';

import './usage-page.scss';

const UsagePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const surgeons = useSelector((state) => state.surgeons.list);
  const hospitals = useSelector((state) => state.hospitals.list);
  const hideClosed = useSelector((state) => state.cases.hideClosed);
  const filters = useSelector((state) => state.cases.usageFilters);

  const cases = useSelector(getUsageList);
  const completedLoaded = useSelector((state) => state.usage.completedLoaded);
  const isLoaded = useSelector((state) => state.usage.isLoaded);
  const order = useSelector((state) => state.usage.order);
  const orderBy = useSelector((state) => state.usage.orderBy);
  const page = useSelector((state) => state.usage.page);
  const rowsPerPage = useSelector((state) => state.usage.rowsPerPage);
  const search = useSelector((state) => state.usage.search);
  const filter = useSelector((state) => state.usage.activeStatuses);

  const [isOpen, toggleModal] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);

  const { loading, startLoading, stopLoading } = useLoading();

  useEffect(() => {
    onLoad().catch((err) => console.error(err));
  }, []);

  const onLoad = async () => {
    if (!isLoaded) {
      startLoading();
    }
    try {
      await Promise.all([
        // dispatch(getCases()),
        dispatch(getSurgeons()),
        dispatch(getHospitals()),
        dispatch(getCompletedCases()),
      ]);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onRefresh = async () => {
    try {
      setRefreshLoading(true);
      await dispatch(getCompletedCases());
    } catch (err) {
      console.error(err);
    } finally {
      setRefreshLoading(false);
    }
  };

  const onRowClick = (caseId) => {
    dispatch(setCaseView('USAGE'));
    history.push(`${routes.CASES}/${caseId}?from=usage`);
  };

  const onClosedClick = async () => {
    dispatch(setActiveStatuses({ approve: false, approved: false, closed: true }));
  };

  const list = useMemo(() => {
    let result = cases.filter((item) => item.status === caseStatusOptions.completed && (item?.patientReference?.toLowerCase()?.includes(search.toLowerCase()) || item?.id?.toLowerCase()?.includes(search.toLowerCase())));

    result = result.filter((item) => {
      const implants = item.usage ? item.usage.filter((item) => item.type === caseUsageTypes.implants) : [];
      const total = implants.length;
      const approved = implants.filter((usage) => !!usage.approved).length;

      if (filter.closed) {
        return item.orderClosed;
      } else {
        if (filter.approve && filter.approved && !item.orderClosed) {
          return true;
        }

        if (!filter.approve && !filter.approved) {
          return false;
        }
      }

      return ((!filter.approve && total === approved) || (!filter.approved && total > approved)) && !item.orderClosed;
    });

    if (filters.surgeon) {
      result = result.filter((item) => filters.surgeon === item.surgeon);
    }

    if (filters.hospital) {
      result = result.filter((item) => filters.hospital === item.hospital);
    }

    if (hideClosed) {
      result = result.filter((item) => !item.orderClosed);
    }

    return result.filter((item) => {
      if (!item.orderClosedAt) {
        return true;
      }

      const diff = getDiffDays(moment(), moment(item.orderClosedAt))
      return diff < 60;
    });
  }, [search, cases, filter, hideClosed, filters]);

  const getSurgeonName = useCallback((surgeonId) => {
    const surgeon = surgeons.find((surgeon) => surgeon.id === surgeonId);
    return surgeon ? `${surgeon.title} ${surgeon.firstName} ${surgeon.lastName}` : '';
  }, [surgeons, filters]);

  const getHospitalName = useCallback((hospitalId) => {
    const hospital = hospitals.find((hospital) => hospital.id === hospitalId);
    return hospital ? hospital.name : '';
  }, [hospitals, filters]);

  const onRemoveFilter = (filter) => {
    if (filter === 'closed') {
      dispatch(toggleClosed());
    }
    if (filter === 'surgeon') {
      dispatch(setUsageFilters({ surgeon: '' }));
    }
    if (filter === 'hospital') {
      dispatch(setUsageFilters({ hospital: '' }));
    }
  };

  return (
    <div className='page-container kits-page'>
      { loading && <LoadScreen /> }

      <div className='d-flex space-between'>
        <div className='d-flex'>
          <div className='page-title'>
            Usage
          </div>
        </div>
      </div>

      <div className='kits-page__body'>
        <>
          <div className='d-flex space-between'>
            <div className='d-flex'>
              <div className='m-r-md'>
                <ActionButton
                  onClick={() => dispatch(setActiveStatuses({ ...filter, approve: !filter.approve, closed: false }))}
                  bgColor={!!filter.approve ? setAllocationStatuses.TRANSFERRED.color : '#dddddd'}
                >
                  <ApproveUsageIcon color="#ffffff" />
                  Approve
                </ActionButton>
              </div>
              <div className='m-l-md m-r-md'>
                <ActionButton
                  onClick={() => dispatch(setActiveStatuses({ ...filter, approved: !filter.approved, closed: false }))}
                  bgColor={!!filter.approved ? setAllocationStatuses.SHIPPED.color : '#dddddd'}
                >
                  <ApprovedUsageIcon color="#ffffff" />
                  Approved
                </ActionButton>
              </div>
              <div className='m-l-md'>
                <ActionButton
                  onClick={onClosedClick}
                  bgColor={!!filter.closed ? setAllocationStatuses.RETURNED.color : '#dddddd'}
                >
                  <ClosedUsageIcon color="#ffffff" />
                  Closed
                </ActionButton>
              </div>
            </div>

            <div className='d-flex flex-end' style={{ width: '40%' }}>
              <div className='m-b-lg m-r-lg'>
                <Button
                  type='icon'
                  onClick={onRefresh}
                  disabled={refreshLoading}
                  loading={refreshLoading}
                >
                  <RepeatIcon />
                </Button>
              </div>
              <div className='flex-1' style={{ maxWidth: 500 }}>
                <Input
                  type='search'
                  placeholder='Search Cases (Patient ref or Case #)'
                  value={search}
                  onChange={(e) => dispatch(setSearch(e.target.value))}
                />
              </div>
            </div>
          </div>

          <UsageFiltersList
            onClick={() => toggleModal(true)}
            hideClosed={hideClosed}
            surgeon={getSurgeonName(filters?.surgeon)}
            hospital={getHospitalName(filters?.hospital)}
            onDelete={onRemoveFilter}
          />

          {!!filter.closed && !completedLoaded ? (
            <div className='d-flex flex-center p-b-lg'>
              <CircularProgress />
            </div>
          ) : (
            <UsageTable
              cases={list}
              onRowClick={(item) => onRowClick(item.id)}
              surgeons={surgeons}
              hospitals={hospitals}
              order={order}
              setOrder={(value) => dispatch(setOrder(value))}
              orderBy={orderBy}
              setOrderBy={(value) => dispatch(setOrderBy(value))}
              page={page}
              setPage={(value) => dispatch(setPage(value))}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={(value) => dispatch(setRowsPerPage(value))}
            />
          )}
        </>
      </div>

      <UsageFiltersModal
        open={isOpen}
        onClose={() => toggleModal(false)}
        hideClosed={hideClosed}
        toggleClosed={() => dispatch(toggleClosed())}
        surgeon={filters.surgeon}
        hospital={filters.hospital}
        onSurgeonChange={(value) => dispatch(setUsageFilters({ surgeon: value }))}
        onHospitalChange={(value) => dispatch(setUsageFilters({ hospital: value }))}
        surgeons={surgeons}
        hospitals={hospitals}
      />
    </div>
  );
};

export default UsagePage;
