/* eslint-disable no-unused-vars */

const firebaseConfigProd = {
  apiKey: "AIzaSyArunXUzGXPCOkHmxCcybnKN03YmowFbuU",
  authDomain: "case-opologic.firebaseapp.com",
  projectId: "case-opologic",
  storageBucket: "case-opologic.appspot.com",
  messagingSenderId: "450089492469",
  appId: "1:450089492469:web:08d145171a100f617d0abc",
  measurementId: "G-MMMNC2GQGZ",
  clientId: "450089492469-djorptvig7ce59eikm5bugq8qgmcaf7a.apps.googleusercontent.com",
  url: "https://case.opologic.com",
};

const firebaseConfigStage = {
  apiKey: "AIzaSyDQxRWKcrjs2mtwd_-St7RLCWJJAq2IU2c",
  authDomain: "casetest-opologic.firebaseapp.com",
  projectId: "casetest-opologic",
  storageBucket: "casetest-opologic.appspot.com",
  messagingSenderId: "136608777526",
  appId: "1:136608777526:web:862515a91203547d6b9f97",
  measurementId: "G-91SJNCXH6J",
  clientId: "436545162806-nuj7m5futd6l5j5irevvoo8fietinfl6.apps.googleusercontent.com",
  url: "https://casetest-opologic.web.app",
};

const firebaseConfigDev = {
  apiKey: "AIzaSyAEh5HtgPw5bumckG2crkbrmkwx1Djzlj0",
  authDomain: "casedev-opologic.firebaseapp.com",
  projectId: "casedev-opologic",
  storageBucket: "casedev-opologic.appspot.com",
  messagingSenderId: "436545162806",
  appId: "1:436545162806:web:5f018662749d4835099297",
  measurementId: "G-YDSNTNS41Z",
  clientId: "436545162806-nuj7m5futd6l5j5irevvoo8fietinfl6.apps.googleusercontent.com",
  url: "https://casedev-opologic.web.app",
};

export default firebaseConfigProd;
