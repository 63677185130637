import React, { useMemo, useCallback } from 'react';

import Table from '../shared/table/Table';

import './counts.scss';

const CountEquipmentTable = (props) => {
  const {
    scans,
    selected,
    onSelect,
  } = props;

  const columns = useMemo(() => (
    [
      { title: 'description', field: 'description', type: 'default', sort: true },
      { title: 'quantity', field: 'quantity', type: 'default', sort: true },
      { title: 'added by', field: 'counterName', type: 'default', sort: true },
      { title: 'date', field: 'createdAt', type: 'date', sort: true },
      {
        title: '',
        field: 'preview',
        type: 'custom',
        formatter: (value, row) => {
          if (value) {
            return (
              <div className='count-image-preview' onClick={(e) => onRowClick(e, row)}>
                <img src={value} alt='preview' />
              </div>
            );
          }
        }
      }
    ]
  ), [scans]);

  const getDescription = useCallback((scan) => {
    const description = scan.values.find((item) => item.label === 'DESC');
    return description.value;
  }, [scans]);

  const getCounterName = useCallback((scan) => {
    const user = scan.values.find((item) => item.label === 'USER');
    return user.value;
  }, [scans]);

  const onRowClick = (e, row) => {
    if (row && row.image && row.image.downloadUrl) {
      window.open(row.image.downloadUrl, '_blank');
    }
    e.stopPropagation();
  };

  return (
    <div style={{ width: '100%' }}>
      <Table
        rows={scans.map((item) => ({
          ...item,
          description: getDescription(item),
          counterName: getCounterName(item),
          preview: item.image ? item.image.downloadUrl : null,
        }))}
        columns={columns}
        onRowClick={onSelect}
        selected={selected}
        rowColor='#ffffff'
        rowBorderColor='#fafafa'
        checkboxes={false}
        pagination
      />
    </div>
  );
};

export default CountEquipmentTable;
