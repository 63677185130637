import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { EmailTriggersContainer, EmailToggleContainer, EmailCaseBackupTriggersContainer } from '../../../../components/emails';

import { withFirebase, collections } from '../../../../firebase';

import { emailTriggers } from '../../../../constants';

const emailLists = [emailTriggers.COUNT_COMPLETED];
const emailToggles = [emailTriggers.CASE_UPDATED];

const EmailTriggersPage = (props) => {
  const { firebase } = props;

  const tenantId = useSelector((state) => state.tenant.currentTenant.id);

  const [emails, setEmails] = useState({});
  const [toggles, setToggles] = useState({});
  const [casesBackupConfig, setCasesBackupConfig] = useState({});

  useEffect(() => {
    const unsubscribe = subscribeToEmailTriggers();

    return () => {
      unsubscribe();
    };
  }, []);

  const subscribeToEmailTriggers = () => (
    firebase
      .db
      .collection(collections.EMAILS_COLLECTION(tenantId))
      .onSnapshot({
        error: (e) => console.error(e),
        next: (querySnapshot) => {
          let list = [];
          querySnapshot.forEach((documentSnapshot) => {
            const id = documentSnapshot.id;
            list = [...list, {
              ...documentSnapshot.data(),
              id,
            }];
          });
          setState(list);
        },
      })
  );

  const setState = (list) => {
    const stateEmails = {};
    const stateToggles = {};

    emailLists.forEach((key) => {
      const type = list.find((item) => item.type === key);
      stateEmails[key] = type && type.list ? type.list : [];
    });

    emailToggles.forEach((key) => {
      const type = list.find((item) => item.type === key);
      stateToggles[key] = type ? !!type.active : false;
    });

    setEmails(stateEmails);
    setToggles(stateToggles);

    const casesBackupRes = list.find((item) => item.type === emailTriggers.CASES_BACKUP);
    setCasesBackupConfig({
      active: casesBackupRes ? !!casesBackupRes?.active : false,
      list: casesBackupRes?.list || [],
      type: emailTriggers.CASES_BACKUP
    });
  };

  const onSubmit = async (list, type) => {
    const doc = {
      active: true,
      type,
      list
    };
    await firebase
      .db
      .collection(collections.EMAILS_COLLECTION(tenantId))
      .doc(type)
      .set(doc, { merge: true });
  };

  const onChange = async (value, type) => {
    const doc = {
      active: value,
      type,
    };

    await firebase
      .db
      .collection(collections.EMAILS_COLLECTION(tenantId))
      .doc(type)
      .set(doc, { merge: true });
  };

  return (
    <div className='settings-cmp__main'>
      <span className='settings-title'>Email Triggers</span>

      <div className='email-triggers-page__content'>
        {emailLists.map((key) => (
          <EmailTriggersContainer
            key={key}
            emails={emails[key] || []}
            type={key}
            onSubmit={onSubmit}
          />
        ))}
        {emailToggles.map((key) => (
          <EmailToggleContainer
            key={key}
            value={toggles[key] || false}
            type={key}
            onChange={onChange}
          />
        ))}
        <EmailCaseBackupTriggersContainer config={casesBackupConfig} onChange={onChange} onSubmit={onSubmit} />
      </div>
    </div>
  );
};

export default withFirebase(EmailTriggersPage);
