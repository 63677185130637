import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function RepeatIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M12.4962 8.37494C12.0891 9.52862 11.3496 10.536 10.3708 11.27C9.39211 12.0041 8.21799 12.432 6.99646 12.4998C5.86727 12.5 4.76539 12.1526 3.84048 11.5048C2.91557 10.8571 2.21246 9.94032 1.82666 8.87909" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M1.49658 5.62508C2.26655 3.51683 4.63146 1.50024 6.99637 1.50024C8.13105 1.50343 9.23698 1.85751 10.1625 2.51392C11.0881 3.17034 11.7879 4.09697 12.1662 5.16677" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M13.1377 10.3956C12.9621 9.56534 12.8218 9.12322 12.4974 8.37883C11.7022 8.54324 11.2601 8.68364 10.4806 9.01914" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M1.01489 3.57285C1.12858 4.41387 1.2358 4.86516 1.50421 5.63151C2.30936 5.5264 2.76063 5.41909 3.56287 5.14219" />
  </SvgIcon>
}

