import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import NumberFormat from 'react-number-format';

import Button from '../shared/Button';
import Input from '../shared/Input';
import Select from '../shared/Select';
import FileUpload from '../shared/FileUpload';
import ModalSelectInput from '../shared/modal-select/ModalSelectInput';
import ModalSelect from '../shared/modal-select/ModalSelect';

import { itemTypeOptions } from '../../constants/enums';

import '../../styles/shared/form.scss';

const ItemForm = (props) => {
  const {
    mode = 'create',
    initialValues,
    buttonText,
    onSubmit,
    loading,
    onClose,
    products = [],
    items = []
  } = props;

  const { code } = initialValues || {};

  const [image, setImage] = useState(null);
  const [isModalOpen, toggleModal] = useState(false);

  useEffect(() => {
    setImage(null);
  }, [initialValues?.id]);

  const validate = (values) => {
    const errors = {};

    if (!values.code) {
      errors.code = 'Required';
    }
    if (items?.map((i) => i.code)?.includes(values?.code?.trim()) && mode === 'create') {
      errors.code = 'Duplicate';
    }

    if (!values.description) {
      errors.description = 'Required';
    }
    if (!values.type) {
      errors.type = 'Required';
    }

    return errors;
  };

  const getProductNames = (productIds) => {
    return productIds.map((id) => {
      const product = products.find((item) => item.id === id);
      return product ? product.name : '';
    })
  };

  return (
    <div className='form__container'>
      <Form
        onSubmit={(formObj) => onSubmit(formObj, image)}
        validate={validate}
        initialValues={{
          ...initialValues,
          // products: initialValues?.products || [],
        }}
        subscription={{ pristine: true, invalid: true }}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <div className='form__subtitle'>
                {code ? code : 'Add Item'}
              </div>
              { mode === 'update' && (
                <Field name='active'>
                  {({ input, meta }) => (
                    <Input
                      type='switch'
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      placeholder='Status'
                    />
                  )}
                </Field>
              )}

              <div className='m-b-sm'>
                <div className='field__title'>Item Code</div>
                <Field name='code'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      placeholder='Code'
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-sm'>
                <div className='field__title'>Description</div>
                <Field name='description'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      placeholder='Description'
                      type='textarea'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-lg'>
                <div className='field__title'>Type</div>
                <Field name='type'>
                  {({ input, meta }) => (
                    <Select
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      options={Object.values(itemTypeOptions)}
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-sm'>
                <div className='field__title'>Rebate Code</div>
                <Field name='rebateCode'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      placeholder='Rebate Code'
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              <div>
                <div className='field__title'>Value ($)</div>
                <Field name='value'>
                  {({ input, meta }) => (
                    <NumberFormat
                      value={input.value}
                      onValueChange={(v) => input.onChange(v.floatValue)}
                      thousandSeparator={true}
                      prefix={'$'}
                      customInput={Input}
                      fixedDecimalScale={2}
                      decimalScale={2}
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-sm'>
                <div className='field__title'>GTIN</div>
                <Field name='gtin'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-sm'>
                <div className='field__title'>Checklist Description</div>
                <Field name='checklistDescription'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-sm'>
                <Field name='batchControl'>
                  {({ input, meta }) => (
                    <Input
                      type='switch'
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      placeholder='Batch Control'
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-lg'>
                <div className='field__title'>Image Upload</div>
                <Field name='imageName'>
                  {({ input, meta }) => (
                    <FileUpload
                      id='tenant-logo'
                      file={image}
                      onUpload={(file) => {
                        setImage(file);
                        input.onChange(file.name);
                      }}
                      placeholder={input.value}
                      accept='image'
                      preview={initialValues?.image?.downloadUrl}
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-sm'>
                <div className='field__title'>Image URL (optional)</div>
                <Field name='imageUrl'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      placeholder='URL'
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              { mode === 'update' && (
                <div className=''>
                  <div className='field__title'>Products</div>
                  <Field name='products'>
                    {({ input, meta }) => (
                      <div className='m-b-lg'>
                        <ModalSelectInput
                          onClick={() => toggleModal(true)}
                          count={input.value.length}
                          label='Edit Brands'
                          values={getProductNames(input.value)}
                          icon='product'
                        />

                        <ModalSelect
                          title='Add Brands'
                          listTitle='Brands'
                          options={products}
                          onChange={input.onChange}
                          selected={input.value}
                          onClose={() => toggleModal(false)}
                          isOpen={isModalOpen}
                        />
                      </div>
                    )}
                  </Field>
                </div>
              )}

              <div className='form__actions'>
                <Button
                  type='submit'
                  text={buttonText || 'Save Changes'}
                  disabled={invalid || loading || pristine}
                  loading={loading}
                  onClick={handleSubmit}
                />
                { mode === 'create' && (
                  <Button
                    type='cancel'
                    onClick={onClose}
                  />
                )}
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default ItemForm;
