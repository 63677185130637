import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function PreferenceIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none"
          d="M3.33081 3.37384C3.35199 3.00135 3.37733 2.61779 3.40683 2.2188C3.44065 1.76145 3.78514 1.38739 4.2386 1.31891C5.62659 1.10929 6.77352 1 8.15614 1C9.53875 1 10.6857 1.10932 12.0737 1.31894C12.5271 1.38743 12.8717 1.76224 12.9055 2.21959C13.1134 5.03171 13.1147 7.07747 12.9094 9.88003C12.8741 10.3614 12.4956 10.7472 12.0146 10.7875C11.4975 10.8309 10.989 10.8657 10.4858 10.8922" />
    <path stroke={color || defaultColor} fill="none"
          d="M5.65625 3.25C4.27364 3.25 3.1267 3.35929 1.73871 3.56891C1.28526 3.63739 0.940702 4.0122 0.906893 4.46955C0.699007 7.28167 0.697711 9.32743 0.903003 12.13C0.938266 12.6114 1.31681 12.9972 1.7978 13.0375C3.12172 13.1485 4.38899 13.204 5.65625 13.204C6.92352 13.204 8.19078 13.1485 9.5147 13.0375C9.99569 12.9972 10.3742 12.6114 10.4095 12.13C10.6148 9.32747 10.6135 7.28171 10.4056 4.46959C10.3718 4.01224 10.0272 3.63743 9.57379 3.56894C8.1858 3.35932 7.03886 3.25 5.65625 3.25Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M3.75 6.75H7.5625" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M3.75 9.51562H6.05065" />
  </SvgIcon>
}
