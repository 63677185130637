import React from 'react';

import DeleteIcon from '../../assets/icons/DeleteIcon';
import FileCopyIcon from '../../assets/icons/CopyIcon';
import UploadIcon from '../../assets/icons/UploadIcon';

import Button from '../shared/Button';
import Select from '../shared/Select';

const SetsFilters = (props) => {
  const {
    onAddSetClick,
    status,
    setStatus,
    statuses,
    onDelete,
    onUploadClick,
    onDuplicateClick,
    deletePermission
  } = props;

  return (
    <div className='settings-cmp__filters--main'>
      <div className='settings-cmp__actions'>
        <Button
          text='Add Set'
          type='submit'
          onClick={onAddSetClick}
        />

        <Button
          type='submit'
          width='60px'
          onClick={onUploadClick}
        >
          <UploadIcon color="#ffffff" />
        </Button>

        <Button
          type='icon'
          onClick={onDelete}
          disabled={!deletePermission}
        >
          <DeleteIcon />
        </Button>

        <Button
         type='icon'
         onClick={onDuplicateClick}
        >
          <FileCopyIcon />
        </Button>
      </div>

      <div className='settings-cmp__filters'>

        <Select
          value={status}
          onChange={setStatus}
          options={statuses}
          defaultLabel={`All Status (${statuses.length})`}
          width={200}
        />
      </div>
    </div>
  )
};

export default SetsFilters;
