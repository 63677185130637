import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import ArrowIconUp from '@material-ui/icons/KeyboardArrowUp';
import ArrowIconDown from '@material-ui/icons/KeyboardArrowDown';

import SectionIcon from '../../../assets/icons/DocumentIcon';
import FullScreenIcon from '../../../assets/icons/FullScreenIcon';

import SectionItemRow from './SectionItemRow';
import SectionNotesRow from './SectionNotesRow';
import BatchInboundModal from './BatchInboundModal';

import { billOfMaterialItemTypes, setAllocationStatuses } from '../../../constants/enums';

import '../checklists/checklists.scss';

const ReturnSetSection = (props) => {
  const {
    name,
    image,
    items,
    onChange,
    sectionIndex,
    checklistId,
    tenantColor,
    allocationStatus,
    itemsList = [],
    batchItems = {},
    onBatchInboundItemsChange
  } = props;

  const [open, setOpen] = useState(false);
  const [batchModalOpen, toggleBatchModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const onValuesChange = (index, inbound) => {
    let temp = [...items];
    temp[index] = { ...temp[index], inbound };

    onChange(checklistId, sectionIndex, temp);
  };

  return (
    <div className='checklist-section-item'>
      <div className='d-flex'>
        <SectionIcon />
        <div className='font-size-bg font-bold m-l-md'>
          {name}
        </div>

        <IconButton
          className='m-l-md'
          aria-label='more'
          aria-controls='long-menu'
          aria-haspopup='true'
          onClick={() => setOpen(!open)}
          size='small'
        >
          {open ? <ArrowIconUp fontSize='large' /> : <ArrowIconDown fontSize='large' />}
        </IconButton>
      </div>

      {open && (
        <div>
          <div className='section-image__container'>
            {!!image && !!image.downloadUrl && (
              <>
                <img src={image.downloadUrl} alt='section-preview' />
                <div
                  className='full-screen-icon'
                  onClick={() => window.open(image?.downloadUrl, '_blank')}
                >
                  <FullScreenIcon color='secondary' fontSize='large' />
                </div>
              </>
            )}
          </div>

          <div className='m-t-lg'>
            {items && !!items.length && (
              <table className='section-items-table'>
                <thead>
                <tr>
                  <th>Ref</th>
                  <th>Item Code</th>
                  <th>Description</th>
                  <th>Type</th>
                  <th>Image</th>
                  <th />
                  <th style={{ textAlign: 'center' }}>QTY</th>
                  <th style={{ textAlign: 'center' }}>Out</th>
                  <th
                    style={allocationStatus === setAllocationStatuses.SHIPPED.value || allocationStatus === setAllocationStatuses.CONFIRMED.value ?
                      { textAlign: 'right', paddingRight: '52px' } : { textAlign: 'center' } }
                  >
                    In
                  </th>
                </tr>
                </thead>
                {
                  <tbody>
                  {items.map((item, index) => (
                    item.type === billOfMaterialItemTypes.NOTES.value ? (
                      <SectionNotesRow
                        key={`${item.id}_${Math.random()}`}
                        item={item}
                      />
                    ) : (
                      <SectionItemRow
                        key={`${item.code}_${Math.random()}`}
                        item={item}
                        batchControl={!!itemsList?.find((i) => i.id === item.id)?.batchControl}
                        batchItems={batchItems[item.id] || []}
                        batchInbound={item?.batchInbound || []}
                        onSave={(inbound) => onValuesChange(index, inbound)}
                        tenantColor={tenantColor}
                        allocationStatus={allocationStatus}
                        onEditBatchClick={() => {
                          setSelectedItem(item);
                          toggleBatchModal(true);
                        }}
                        onBatchInboundItemsChange={(inboundItems) => onBatchInboundItemsChange(checklistId, sectionIndex, item.id, inboundItems)}
                      />
                    )
                  ))}
                  </tbody>
                }
              </table>
            )}
          </div>
        </div>
      )}

      {!!selectedItem && (
        <BatchInboundModal
          open={batchModalOpen}
          onClose={() => {
            toggleBatchModal(false);
            setSelectedItem(null)
          }}
          item={selectedItem}
          batchItems={batchItems[selectedItem.id]}
          batchInbound={selectedItem?.batchInbound}
          onSubmit={(inboundItems) => onBatchInboundItemsChange(checklistId, sectionIndex, selectedItem.id, inboundItems)}
          tenantColor={tenantColor}
        />
      )}
    </div>
  );
};

export default ReturnSetSection;
