import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function PatientIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M6.09387 11.0389L6.22284 11.7792C6.33615 12.4827 6.9433 12.9998 7.65582 12.9998C8.36833 12.9998 8.97548 12.4827 9.08879 11.7792L9.48962 9.29075H10.0461C10.5236 9.29075 10.9368 8.95006 11.0097 8.47817C11.033 8.32739 11.053 8.18499 11.0697 8.04846C11.297 6.193 9.50973 4.83984 7.6404 4.83984C6.26207 4.83984 4.93564 5.57634 4.4353 6.71026" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M7.65755 4.84C8.71794 4.84 9.57755 3.98039 9.57755 2.92C9.57755 1.85961 8.71794 1 7.65755 1C6.59716 1 5.73755 1.85961 5.73755 2.92C5.73755 3.98039 6.59716 4.84 7.65755 4.84Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M5.13672 9.17236L5.13672 8.66743" />
  </SvgIcon>
}
