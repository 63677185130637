import React from 'react';

const SectionNotesRow = ({ item }) => (
  <tr>
    <td colSpan={5} style={{ color: item.color || '#F58634' }}>
      <span className='font-bold m-r-md'>{item.title}</span>
      <span style={{ fontWeight: 400 }}>{item.note}</span>
    </td>
    <td />
  </tr>
);

export default SectionNotesRow;
