import React from 'react';

import { setPositionOptions, setAllocationStatuses } from '../constants/enums';

import theme from '../styles/theme/palette';

const colors = theme().primary;

const getPositionLabel = (position) => {
  const { AVAILABLE, ALLOCATED, QUARANTINED, CONSIGNED, INCOMPLETE } = setPositionOptions;

  switch (position) {
    case AVAILABLE.value:
      return <span style={{ color: AVAILABLE.color }}>{AVAILABLE.label}</span>;
    case ALLOCATED.value:
      return <span style={{ color: ALLOCATED.color }}>{ALLOCATED.label}</span>;
    case QUARANTINED.value:
      return <span style={{ color: QUARANTINED.color }}>{QUARANTINED.label}</span>;
    case CONSIGNED.value:
      return <span style={{ color: CONSIGNED.color }}>{CONSIGNED.label}</span>;
    case INCOMPLETE.value:
      return <span style={{ color: INCOMPLETE.color }}>{INCOMPLETE.label}</span>;
    default:
      return '';
  }
};

const getSetAllocationStatus = (status) => {
  const { AVAILABLE, ASSIGNED, TRANSFERRED, SHIPPED, RETURNED, DISABLED, USAGE, CONFIRMED } = setAllocationStatuses;

  switch (status) {
    case AVAILABLE.value:
      return <span style={{ color: RETURNED.color }}>Assign</span>;
    case ASSIGNED.value:
      return <span style={{ color: ASSIGNED.color }}>{ASSIGNED.label}</span>;
    case SHIPPED.value:
      return <span style={{ color: SHIPPED.color }}>{SHIPPED.label}</span>;
    case RETURNED.value:
      return <span style={{ color: RETURNED.color }}>{RETURNED.label}</span>;
    case TRANSFERRED.value:
      return <span style={{ color: TRANSFERRED.color }}>{TRANSFERRED.label}</span>;
    case DISABLED.value:
      return <span style={{ color: colors.greyDark }}>{DISABLED.label}</span>;
    case USAGE.value:
      return <span style={{ color: USAGE.color }}>{USAGE.label}</span>;
    case CONFIRMED.value:
      return <span style={{ color: CONFIRMED.color }}>{CONFIRMED.label}</span>;
    default:
      return '';
  }
};

export {
  getPositionLabel,
  getSetAllocationStatus,
};
