import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '../shared/modal';
import Button from '../shared/Button';
import Input from '../shared/Input';
import Dropzone from '../shared/dropzone';

import { onUploadComplete } from '../../actions/setsActions';

import { SET_DOCUMENTS_REF } from '../../firebase/storage';

const ResetTargetModal = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    loading,
    setId,
    tenantId,
    tenantColor,
  } = props;

  const dispatch = useDispatch();

  const [note, setNote] = useState('');
  const [files, setFiles] = useState([]);

  const handleSubmit = () => {
    onSubmit(note);
  };

  const path = useMemo(() => SET_DOCUMENTS_REF(tenantId, setId), [tenantId, setId]);

  const onUpload = (newFiles) => {
    setFiles([
      ...files,
      ...newFiles.filter((file) => !files.map((f) => f.name).includes(file.name)),
    ]);
  };

  const onRemove = (fileName) => {
    setFiles([...files].filter((item) => item.name !== fileName));
  };

  const handleUploadComplete = async (name, downloadUrl) => {
    await dispatch(onUploadComplete(setId, { name, downloadUrl }));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Reset Target'
      fullWidth
    >
      <div className='quarantine-modal__container' style={{ paddingTop: 0 }}>
        <div className='secondary font-bold m-b-lg'>Reset target to 0% and add set back into circulation</div>

        <div className='m-b-lg'>
          <div className='secondary m-b-md'>Supporting Document</div>
          <Dropzone
            path={path}
            files={files}
            onUpload={onUpload}
            onRemove={onRemove}
            onUploadComplete={handleUploadComplete}
            tenantColor={tenantColor}
            multiple={false}
          />
        </div>

        <div className='m-t-sm'>
          <div className='secondary m-b-md'>Reset Note</div>
          <Input
            value={note}
            onChange={(e) => setNote(e.target.value)}
            type='textarea'
          />
        </div>

        <div className='form__actions p-t-lg'>
          <Button
            type='submit'
            text='Reset'
            onClick={handleSubmit}
            disabled={loading}
            loading={loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ResetTargetModal;
