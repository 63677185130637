import React, { useState } from 'react';

import { UsageImplantsForm, UsageImagesForm } from './form';
import Tabs from '../../shared/Tabs';

import { caseUsageTypes } from '../../../constants/enums';

const usageTabs = [
  { label: 'Inventory' },
  { label: 'Image' },
];

const AddUsageManualModal = ({ onClose, onSubmit, loading, tags = [] }) => {
  const [activeTab, setActiveTab] = useState(0);

  const renderForm = () => {
    switch (activeTab) {
      case 0:
        return (
          <UsageImplantsForm
            onSubmit={(formObj) => onSubmit(caseUsageTypes.implants, formObj)}
            loading={loading}
            onClose={onClose}
            initialValues={{ quantity: 1 }}
            tags={tags}
          />
        );
      case 1:
        return (
          <UsageImagesForm
            onSubmit={(formObj, image) => onSubmit(caseUsageTypes.images, formObj, image)}
            loading={loading}
            onClose={onClose}
            initialValues={{ quantity: 1 }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className='case-usage-modal__container'>
      <Tabs
        tabs={usageTabs}
        activeTab={activeTab}
        onTabChange={setActiveTab}
      >
        <div className='m-t-lg'>
          {renderForm()}
        </div>
      </Tabs>
    </div>
  );
};

export default AddUsageManualModal;
