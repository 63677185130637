import React, { useState, useMemo } from 'react';

import Modal from '../../shared/modal';
import Dropzone from '../../shared/dropzone';
import Button from '../../shared/Button';

const DocumentsUploadModal = ({ open, onClose, caseId, tenantColor, tenantId, onUploadComplete }) => {
  const [files, setFiles] = useState([]);

  const onUpload = (newFiles) => {
    setFiles([
      ...files,
      ...newFiles.filter((file) => !files.map((f) => f.name).includes(file.name)),
    ]);
  };

  const onRemove = (fileName) => {
    setFiles([...files].filter((item) => item.name !== fileName));
  };

  const handleClose = () => {
    setFiles([]);
    onClose();
  };

  const path = useMemo(() => `${tenantId}/cases/${caseId}/documents`, [tenantId, caseId]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title='Upload Documents'
      fullWidth
    >
      <div className='documents-upload-modal__container'>
        <Dropzone
          path={path}
          files={files}
          onUpload={onUpload}
          onRemove={onRemove}
          onUploadComplete={onUploadComplete}
          tenantColor={tenantColor}
        />

        <div className='p-t-lg d-flex space-between'>
          <Button
            type='cancel'
            onClick={handleClose}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DocumentsUploadModal;
