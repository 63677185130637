import { GET_KITS, SET_KITS, SET_MANUFACTURERS } from '../actions/actionTypes';

const initialState = {
  list: [],
  isLoading: false,
  isLoaded: false,
  manufacturers: [],
};

const kitsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_KITS:
      return { ...state, isLoading: true };
    case SET_KITS:
      return {
        ...state,
        list: action.kits,
        isLoaded: true,
        isLoading: false
      };
    case SET_MANUFACTURERS:
      return {
        ...state,
        manufacturers: action.manufacturers || []
      };
    default:
      return state;
  }
};

export default kitsReducer;
