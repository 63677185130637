import { orderBy } from 'lodash';

import { GET_SURGEONS, SET_SURGEONS, SET_QUALIFICATIONS } from './actionTypes';

import firebase, { collections, storageRefs } from '../firebase';

import { getFileWithParameters, remove } from '../utils/api';
import { nowTimestampUTC } from '../utils/date';
import { chunkArray } from '../utils/utils';

import urls from '../constants/urls';
import { roleNames } from '../constants/userRoles';

const { ADMIN, OPERATIONS, CUSTOMER_SERVICE, LOGISTICS, SALES_MANAGER, MARKETING, FINANCE } = roleNames;

export const setSurgeons = (surgeons) => (dispatch) => {
  return dispatch({
    type: SET_SURGEONS,
    surgeons,
  });
};

export const getSurgeons = (tenant, withReducer) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = tenant || state?.tenant?.currentTenant?.id;
  const currentUser = state.user.currentUser;
  const path = collections.SURGEONS_COLLECTION(tenantId);

  let query;
  let docs = [];

  if ([ADMIN, OPERATIONS, CUSTOMER_SERVICE, LOGISTICS, SALES_MANAGER, MARKETING, FINANCE].includes(currentUser.role)) {
    query = firebase
      .db
      .collection(path)

    const snapshot = await query.get();
    docs = snapshot?.docs;
  } else {
    if (!currentUser.assignedSurgeons || !currentUser.assignedSurgeons.length) {
      return dispatch(setSurgeons([]));
    }

    const assignedSurgeons = currentUser.assignedSurgeons || ['empty'];
    const chunks = chunkArray(assignedSurgeons);
    const snapshots = [];

    for await (const snap of chunks?.map(
      async (chunk) =>
        await firebase
          .db
          .collection(path)
          .where(firebase.fieldPath.documentId(), 'in', chunk)
          .get()
    )) {
      snapshots?.push(snap);
    }

    let temp = [];
    snapshots?.forEach((snap) => {
      temp = [...temp, ...snap?.docs];
    });
    docs = temp;
  }

  dispatch({ type: GET_SURGEONS });

  const surgeons = docs?.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  if (tenant && !withReducer) {
    return orderBy(surgeons, 'lastName', 'asc');
  }

  return dispatch(setSurgeons(orderBy(surgeons, 'lastName', 'asc')));
};

export const getCompanySurgeons = (tenantId) => async () => {
  const path = collections.SURGEONS_COLLECTION(tenantId);
  const snapshot = await firebase.db.collection(path).get();
  const docs = snapshot?.docs;
  const surgeons = docs?.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return surgeons;
};

export const createSurgeon = (surgeonData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.SURGEONS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).add({ ...surgeonData, active: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateSurgeon = (surgeonId, surgeonData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.SURGEONS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(surgeonId).set(surgeonData, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteSurgeon = (surgeonId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();

  if (tenantId) {
    await remove(urls.surgeons.delete(surgeonId, tenantId), idToken);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const bulkDeleteSurgeons = (surgeons) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();

  if (tenantId) {
    const promises = [];
    surgeons.forEach((surgeonId) => {
      promises.push(remove(urls.surgeons.delete(surgeonId, tenantId), idToken));
    });

    await Promise.all(promises);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const getSurgeonPreferences = (surgeonId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.SURGEON_PREFERENCES_COLLECTION(tenantId, surgeonId);

  const snapshot = await firebase.db.collection(path).get();
  const preferences = snapshot.docs.map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      createdAt: data.createdAt ? data.createdAt.toDate() : '',
    };
  });

  return orderBy(preferences, 'createdAt', 'desc');
};

export const createPreference = (surgeonId, preferenceData, image) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const currentUser = state.user.currentUser;
  const path = collections.SURGEON_PREFERENCES_COLLECTION(tenantId, surgeonId);
  const storageRef = firebase.storage.ref();

  if (tenantId) {
    const doc = {
      ...preferenceData,
      active: true,
      createdAt: nowTimestampUTC(),
      authorFullName: `${currentUser.firstName} ${currentUser.lastName}`,
      authorId: currentUser.uid,
    };
    const docRef = await firebase.db.collection(path).add(doc);

    const preferenceId = docRef.id;

    if (docRef && image) {
      const storagePath = storageRefs.SURGEON_PREFERENCES_REF(tenantId, surgeonId);
      const filePath = `${storagePath}/${image.name}`;
      const ref = storageRef.child(filePath);
      const snap = await ref.put(image);
      const downloadUrl = await snap?.ref?.getDownloadURL();
      const imageObj = {
        fileName: image.name,
        path: filePath,
        downloadUrl
      };

      await firebase.db.collection(path).doc(preferenceId).set({ images: [imageObj] }, { merge: true });
    }

    return docRef;
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deletePreference = (surgeonId, preferenceId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.SURGEON_PREFERENCES_COLLECTION(tenantId, surgeonId);

  if (tenantId) {
    await firebase.db.collection(path).doc(preferenceId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updatePreference = (surgeonId, preferenceId, preferenceData, image) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const currentUser = state.user.currentUser;
  const path = collections.SURGEON_PREFERENCES_COLLECTION(tenantId, surgeonId);
  const storageRef = firebase.storage.ref();

  if (tenantId) {
    const doc = {
      ...preferenceData,
      updatedAt: nowTimestampUTC(),
      updatedBy: currentUser.uid,
    };
    await firebase.db.collection(path).doc(preferenceId).set(doc, { merge: true });


    if (image) {
      const storagePath = storageRefs.SURGEON_PREFERENCES_REF(tenantId, surgeonId);
      const filePath = `${storagePath}/${image.name}`;
      const ref = storageRef.child(filePath);
      const snap = await ref.put(image);
      const downloadUrl = await snap?.ref?.getDownloadURL();
      const imageObj = {
        fileName: image.name,
        path: filePath,
        downloadUrl
      };

      await firebase.db.collection(path).doc(preferenceId).set({ images: [imageObj] }, { merge: true });
    }
  } else {
    throw new Error('Invalid tenant');
  }
};

export const getSurgeonNotes = (surgeonId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.SURGEON_NOTES_COLLECTION(tenantId, surgeonId);

  const snapshot = await firebase.db.collection(path).get();
  const notes = snapshot.docs.map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      createdAt: data.createdAt ? data.createdAt.toDate() : '',
    };
  });

  return orderBy(notes, 'createdAt', 'desc');
};

export const createNote = (surgeonId, noteData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const currentUser = state.user.currentUser;
  const path = collections.SURGEON_NOTES_COLLECTION(tenantId, surgeonId);

  if (tenantId) {
    await firebase.db.collection(path).add({
      ...noteData,
      active: true,
      createdAt: nowTimestampUTC(),
      authorFullName: `${currentUser.firstName} ${currentUser.lastName}`,
      authorId: currentUser.uid,
    });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const getSurgeonTasks = (surgeonId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.TASKS_COLLECTION(tenantId, surgeonId);

  const snapshot = await firebase.db.collection(path).where('surgeonId', '==', surgeonId).get();
  const notes = snapshot.docs.map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      createdAt: data.createdAt ? data.createdAt.toDate() : '',
    };
  });

  return orderBy(notes, 'createdAt', 'desc');
};

export const uploadSurgeons = (surgeons) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.SURGEONS_COLLECTION(tenantId);

  if (tenantId) {
    const promises = [];

    surgeons.forEach((surgeon) => {
      const title = surgeon[0] || '';
      const firstName = surgeon[1] || '';
      const lastName = surgeon[2] || '';

      const doc = {
        title,
        firstName,
        lastName,
        active: true,
        hospitals: [],
        procedures: [],
      };

      promises.push(firebase.db.collection(path).add(doc));
    });

    await Promise.all(promises);
  } else {
    throw new Error('Invalid tenant');
  }
};

// Qualifications

export const setQualifications = (qualifications) => ({ type: SET_QUALIFICATIONS, qualifications });

export const getQualifications = (tenant, withReducer) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = tenant || state?.tenant?.currentTenant?.id;
  const path = collections.QUALIFICATIONS_COLLECTION(tenantId);

  const snapshot = await firebase.db.collection(path).get();
  const qualifications = snapshot.docs?.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));

  return dispatch(setQualifications(qualifications));
};

export const addQualification = (qualification) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.QUALIFICATIONS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).add({ name: qualification });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateQualification = (qualificationId, qualification) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.QUALIFICATIONS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(qualificationId).set({ name: qualification }, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteQualification = (qualificationId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.QUALIFICATIONS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(qualificationId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const uploadQualifications = (options) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.QUALIFICATIONS_COLLECTION(tenantId);

  if (tenantId) {
    const promises = [];

    options.forEach((opt) => {
      const name = opt[0] || '';
      const doc = { name };

      promises.push(firebase.db.collection(path).add(doc));
    });

    await Promise.all(promises);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const exportSurgeonsData = (surgeons) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();

  try {
    await getFileWithParameters(urls.surgeons.export(tenantId), idToken, 'Surgeons', 'xlsx', { surgeons });
  } catch (err) {
    throw new Error(err);
  }
};

// Subscriptions
export const subscribeToSurgeons = () => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const currentUser = state.user.currentUser;
  const path = collections.SURGEONS_COLLECTION(tenantId);

  let query = firebase.db.collection(path);

  return query
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let surgeons = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          const item = {
            ...documentSnapshot.data(),
            id,
          };

          if ([ADMIN, OPERATIONS, CUSTOMER_SERVICE, LOGISTICS, SALES_MANAGER, MARKETING, FINANCE].includes(currentUser.role)) {
            surgeons?.push(item);
          } else {
            const assignedSurgeons = currentUser.assignedSurgeons && currentUser.assignedSurgeons.length ? currentUser.assignedSurgeons : ['empty'];
            if (assignedSurgeons?.includes(id)) {
              surgeons?.push(item);
            }
          }
        });

        return dispatch(setSurgeons(orderBy(surgeons, 'lastName', 'asc')));
      },
    });
};

export const subscribeToQualifications = () => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.QUALIFICATIONS_COLLECTION(tenantId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let qualifications = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          qualifications = [...qualifications, {
            ...documentSnapshot.data(),
            id,
          }];
        });

        return dispatch(setQualifications(orderBy(qualifications, 'name', 'asc')));
      }
    });
};
