import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function RepeatCountIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <circle cx={7} cy={7} r={6} stroke={color || defaultColor} fill="none" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M4.33684 5.61882H9.66316L7.88251 3.83817" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M9.66316 8.38117H4.33684L6.11749 10.1618" />
  </SvgIcon>
}

