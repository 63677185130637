import React from 'react';
import CaseIcon from '../../assets/icons/CaseIcon';

import { BarChart } from '../charts';

import './home.scss';

const DashboardChart = ({ tenantColor, data }) => {
  return (
    <div className="chart__container">
      <div className="d-flex font-size-bg font-bold">
        <CaseIcon />
        <span className="m-l-md">Cases this week</span>
      </div>

      <BarChart
        data={data}
        colors={tenantColor}
        margin={{ top: 20, right: 15, bottom: 50, left: 20 }}
      />
    </div>
  );
};

export default DashboardChart;
