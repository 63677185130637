import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function TableIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path id="Vector 259" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M4.983 5.5v7.656"
          stroke-width="1"></path>
    <path id="Vector 260" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M0.839 9h12.323"
          stroke-width="1"></path>
    <path id="Intersect" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M0.96 10.268a3.132 3.132 0 0 0 2.753 2.76c1.07 0.12 2.167 0.222 3.287 0.222s2.218 -0.102 3.287 -0.222a3.132 3.132 0 0 0 2.753 -2.76c0.114 -1.063 0.21 -2.155 0.21 -3.268s-0.096 -2.205 -0.21 -3.269a3.132 3.132 0 0 0 -2.753 -2.76C9.217 0.853 8.12 0.75 7 0.75S4.782 0.852 3.713 0.972A3.132 3.132 0 0 0 0.96 3.732C0.846 4.794 0.75 5.886 0.75 7s0.096 2.205 0.21 3.268Z"
          stroke-width="1"></path>
    <path id="Vector" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M0.802 5.5h12.396a42.53 42.53 0 0 0 -0.158 -1.769 3.132 3.132 0 0 0 -2.753 -2.76C9.217 0.853 8.12 0.75 7 0.75S4.782 0.852 3.713 0.972A3.132 3.132 0 0 0 0.96 3.732C0.898 4.311 0.84 4.901 0.802 5.5Z"
          stroke-width="1"></path>
  </SvgIcon>
}
