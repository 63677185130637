import { orderBy } from 'lodash';

import {
  GET_SHIPPING_CASES,
  SET_SHIPPING_CASES,
  SET_SHIPPING_ORDER,
  SET_SHIPPING_ORDER_BY,
  SET_SHIPPING_PAGE,
  SET_SHIPPING_ROWS_PER_PAGE,
  SET_SHIPPING_SEARCH,
  SET_SHIPPING_ACTIVE_TAB,
} from './actionTypes';

import { getHospitals } from './hospitalsActions';
import { getSetsAllocation } from './casesActions';

import firebase, { collections } from '../firebase';

import { chunkArray } from '../utils/utils';

import { territoryRoles, roleNames } from '../constants/userRoles';

const { ADMIN, OPERATIONS, CUSTOMER_SERVICE, LOGISTICS, SALES_MANAGER, MARKETING, FINANCE } = roleNames;

export const getCases = () => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const currentUser = state.user.currentUser;
  const path = collections.CASES_COLLECTION(tenantId);

  dispatch({ type: GET_SHIPPING_CASES });

  let query;
  let docs = [];

  if ([ADMIN, OPERATIONS, CUSTOMER_SERVICE, LOGISTICS, SALES_MANAGER, MARKETING, FINANCE].includes(currentUser.role)) {
    query = firebase
      .db
      .collection(path)

    const snapshot = await query.get();
    docs = snapshot?.docs;
  } else {
    if (!currentUser.assignedSurgeons || !currentUser.assignedSurgeons.length) {
      return dispatch(setCases([]));
    }

    const assignedSurgeons = currentUser.assignedSurgeons || ['empty'];
    const chunks = chunkArray(assignedSurgeons);
    const snapshots = [];

    for await (const snap of chunks?.map(
      async (chunk) =>
        await firebase
          .db
          .collection(path)
          .where('surgeon', 'in', chunk)
          .get()
    )) {
      snapshots?.push(snap);
    }

    let temp = [];
    snapshots?.forEach((snap) => {
      temp = [...temp, ...snap?.docs];
    });
    docs = temp;
  }

  if (territoryRoles?.includes(currentUser.role) && !!currentUser.territoryVisibility) {
    const hospitals = state.hospitals?.list?.length ? state.hospitals?.list : await dispatch(getHospitals(tenantId));
    const userHospitals = hospitals?.filter((h) => h.territories?.some((territory) => currentUser?.assignedTerritories?.includes(territory)));
    docs = docs?.filter((item) => userHospitals?.map((h) => h.id)?.includes(item?.data()?.hospital));
  }

  const promises = docs.map(async (doc) => {
    const data = doc.data();

    const setsAllocation = await dispatch(getSetsAllocation(doc.id, true));

    return {
      ...data,
      id: doc.id,
      createdAt: data && data.createdAt ? data.createdAt.toDate() : '',
      updatedAt: data && data.updatedAt ? data.updatedAt.toDate() : '',
      date: data && data.date ? data.date.toDate() : '',
      setsAllocation: setsAllocation || [],
      proformaSentAt: data && data.proformaSentAt ? data.proformaSentAt.toDate() : '',
      proformaDate: data && data.proformaDate ? data.proformaDate.toDate() : '',
      collectionDate: data && data.collectionDate ? data.collectionDate.toDate() : '',
    };
  });

  const cases = await Promise.all(promises);

  return dispatch(setCases(cases ? orderBy(cases.filter((item) => !!item.setsAllocation.length), 'date', 'asc') : []));
};

export const setCases = (cases) => ({ type: SET_SHIPPING_CASES, cases });

export const setOrder = (value) => ({ type: SET_SHIPPING_ORDER, value });

export const setOrderBy = (value) => ({ type: SET_SHIPPING_ORDER_BY, value });

export const setPage = (value) => ({ type: SET_SHIPPING_PAGE, value });

export const setRowsPerPage = (value) => ({ type: SET_SHIPPING_ROWS_PER_PAGE, value });

export const setSearch = (value) => ({ type: SET_SHIPPING_SEARCH, value });

export const setActiveTab = (value) => ({ type: SET_SHIPPING_ACTIVE_TAB, value });