import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CircularProgress from '@material-ui/core/CircularProgress';

import CasesFilters from '../../../components/cases/CasesFilters';
import CasesTable from '../../../components/cases/CasesTable';
import ConnectedCasesTable from '../../../components/cases/ConnectedCasesTable';
import CaseModal from '../../../components/cases/form/CaseModal';
import AvailableKitsModal from '../../../components/cases/modals/AvailableKitsModal';
import CasesCalendarView from '../../../components/cases/CasesCalendarView';
import Input from '../../../components/shared/Input';
import LoadScreen from '../../../components/load-screen';
import { ConfirmationModal } from '../../../components/shared/modal';
import Button from '../../../components/shared/Button';
import Tabs from '../../../components/shared/Tabs';
import KitFiltersList from '../../../components/kits/KitFiltersList';
import KitsFiltersModal from '../../../components/kits/KitsFiltersModal';

import {
  bulkDeleteCases, createCase, toggleCanceled, setStatusFilter, setFormStep,
  getConnectedCases, toggleConsignment, toggleLoan, toggleShipped, setFilters, toggleReturned,
  setOrder, setOrderBy, setPage, setRowsPerPage, setSearch, getCompletedCases,
} from '../../../actions/casesActions';
import { getSurgeons } from '../../../actions/surgeonsActions';
import { getProcedures, getProcedureOptions } from '../../../actions/proceduresActions';
import { getProducts } from '../../../actions/productsActions';
import { getKits } from '../../../actions/kitsActions';
import { getFlows } from '../../../actions/flowsActions';
import { getSets } from '../../../actions/setsActions';
import { getTenants } from '../../../actions/tenantActions';
import { getCasesList } from '../../../reducers/selectors';

import { withFirebase } from '../../../firebase';

import { useAlert, useLoading } from '../../../hooks';

import { filterCases } from '../../../utils/cases';

import { caseStatusOptions, kitVariantTypes } from '../../../constants/enums';
import userRoles from '../../../constants/userRoles';
import routes from '../../../constants/routes';
import alertTypes from '../../../constants/alertTypes';

import './cases-page.scss';

moment.updateLocale('en', {
  week: {
    dow: 1
  }
});

const tabs = [
  { label: 'My Company' },
  { label: 'Connections' }
];

const views = {
  TABLE: 'TABLE',
  CALENDAR: 'CALENDAR',
};

const CasesPage = ({ firebase }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { loading, startLoading, stopLoading } = useLoading();
  const { showAlert } = useAlert();

  const userRole = useSelector((state) => state.user.currentUser.role);
  const userAddresses = useSelector((state) => state.user.currentUser.deliveryAddresses);
  const connectedTenants = useSelector((state) => state?.user?.currentUser?.connectedTenants) || [];
  const caseWriteDisabled = useSelector((state) => state.user.currentUser.caseWriteDisabled);
  const cases = useSelector(getCasesList);
  const completedLoaded = useSelector((state) => state.cases.completedLoaded);
  const isLoaded = useSelector((state) => state.cases.isLoaded);
  const surgeons = useSelector((state) => state.surgeons.list);
  const hospitals = useSelector((state) => state.hospitals.list);
  const procedures = useSelector((state) => state.procedures.list);
  const procedureOptions = useSelector((state) => state.procedures.procedureOptions);
  const products = useSelector((state) => state.products.list);
  const kits = useSelector((state) => state.kits.list);
  const items = useSelector((state) => state.items.list);
  const showCanceled = useSelector((state) => state.cases.showCanceled);
  const flows = useSelector((state) => state.flows.list);
  const sets = useSelector((state) => state.sets.list?.filter((set) => !!set.active));
  const statusFilter = useSelector((state) => state.cases.statusFilter);
  const currentTenant = useSelector((state) => state.tenant.currentTenant);
  const tenants = useSelector((state) => state.tenant.list);

  const hideShipped = useSelector((state) => state.cases.hideShipped);
  const hideReturned = useSelector((state) => state.cases.hideReturned);
  const hideConsignment = useSelector((state) => state.cases.hideConsignment);
  const hideLoan = useSelector((state) => state.cases.hideLoan);
  const filters = useSelector((state) => state.cases.filters);
  const order = useSelector((state) => state.cases.order);
  const orderBy = useSelector((state) => state.cases.orderBy);
  const page = useSelector((state) => state.cases.page);
  const rowsPerPage = useSelector((state) => state.cases.rowsPerPage);
  const search = useSelector((state) => state.cases.search);

  const [checkedCases, setCheckedCases] = useState([]);
  const [date, setDate] = useState(null);
  const [isDeleting, toggleDeleting] = useState(false);

  const [caseModalOpen, toggleCaseModal] = useState(false);
  const [deleteModalOpen, toggleDeleteModal] = useState(false);

  const [availableModalData, setAvailableModalData] = useState(null);

  const [activeTab, setActiveTab] = useState(0);
  const [connectedCases, setConnectedCases] = useState([]);
  const [fetching, setFetching] = useState(false);

  const [isOpen, toggleModal] = useState(false);
  const [view, setView] = useState(views.TABLE);

  useEffect(() => {
    onLoad().catch((err) => console.error(err));

    if (userRole === userRoles.SALES_REP.name && !!connectedTenants?.length) {
      dispatch(setFormStep('company'));
    }
  }, [userRole, connectedTenants]);

  const onLoad = async () => {
    startLoading()
    try {
      await Promise.all([
        dispatch(getSurgeons()),
        dispatch(getProcedures()),
        dispatch(getProcedureOptions()),
        dispatch(getProducts()),
        dispatch(getKits()),
        dispatch(getFlows()),
        // dispatch(getHospitals()),
        // dispatch(getItems()),
        dispatch(getSets()),
        dispatch(getTenants()),
      ]);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const fetchConnectedCases = async (withoutLoading = false) => {
    try {
      if (!connectedCases?.length && !withoutLoading) {
        setFetching(true);
      }
      const list = await dispatch(getConnectedCases());
      console.log(list);
      if (list && list?.length) {
        setConnectedCases(list);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setFetching(false);
    }
  };

  const handleSearch = (e) => {
    dispatch(setSearch(e.target.value));
  };

  const onSelectCase = (item, connected) => {
    history.push(`${routes.CASES}/${item.id}${connected ? `?tenantId=${item.tenantId}`: ''}`);
  };

  const onAddCaseClick = () => {
    toggleCaseModal(true);
  };

  const onAddCase = async (formData, isConnected) => {
    try {
      if (isConnected) {
        await dispatch(createCase(formData, isConnected));
        await fetchConnectedCases(true);
        showAlert(alertTypes.SUCCESS, 'Case has been successfully created');
        toggleCaseModal(false);
      } else {
        const caseId = await dispatch(createCase(formData));
        firebase.logEvent('case_created');
        showAlert(alertTypes.SUCCESS, 'Case has been successfully created');

        const warningKits = await checkKitAvailability(formData);

        if (warningKits?.length) {
          setAvailableModalData({ id: caseId, kits: warningKits });
        } else {
          if (caseId) {
            history.push(`${routes.CASES}/${caseId}`);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const checkKitAvailability = async (data) => {
    let list = cases?.filter((item) =>
      item?.status === caseStatusOptions.booked
      && moment(item?.date)?.isSame(moment(data?.date), 'day')
      && item?.kits?.some((kitId) => data?.kits?.map((kit) => kit.id)?.includes(kitId))
    );
    const result = [];

    if (data?.kitVariant === kitVariantTypes.consignment) {
      list = list?.filter((item) => item.kitVariant === kitVariantTypes.consignment);
    } else {
      list = list?.filter((item) => item.kitVariant === kitVariantTypes.loan);
    }

    data?.kits?.forEach((kit) => {
      let count = 1;
      list?.forEach((item) => {
        if (item?.kits?.includes(kit.id)) {
          count++;
        }
      });

      let kitSets = [];
      if (data?.kitVariant === kitVariantTypes.consignment) {
        kitSets = sets?.filter((set) => set?.kit === kit.id && set.consigned && set?.consignment?.hospital === data?.hospital);
      } else {
        kitSets = sets?.filter((set) => set?.kit === kit.id && !set?.consigned);
      }

      if (!!kitSets.length && count > kitSets?.length && !result?.includes(kit.id)) {
        result?.push(kit.id);
      }
    });

    return result;
  };

  const onContinueClick = (caseId) => {
    history.push(`${routes.CASES}/${caseId}`);
  };

  // const handleDeleteClick = () => {
  //   if (checkedCases.length) {
  //     toggleDeleteModal(true);
  //   }
  // };

  const onDeleteCases = async () => {
    toggleDeleting(true);

    try {
      await dispatch(bulkDeleteCases(checkedCases));

      toggleDeleteModal(false);
      setCheckedCases([]);
    } catch (err) {
      console.error(err);
    } finally {
      toggleDeleting(false);
    }
  };

  const onStatusFilterChange = async (key) => {
    if (key === 'completed') {
      dispatch(setStatusFilter({ completed: !statusFilter.completed }));
      await dispatch(getCompletedCases());
    } else {
      dispatch(setStatusFilter({ [key]: !statusFilter[key] }))
    }
  };

  const onToggle = (value) => {
    dispatch(toggleCanceled(value))
  };

  const getSurgeonName = useCallback((surgeonId) => {
    const surgeon = surgeons.find((surgeon) => surgeon.id === surgeonId);
    return surgeon ? `${surgeon.title} ${surgeon.firstName} ${surgeon.lastName}` : '';
  }, [surgeons, filters]);

  const getHospitalName = useCallback((hospitalId) => {
    const hospital = hospitals.find((hospital) => hospital.id === hospitalId);
    return hospital ? hospital.name : '';
  }, [hospitals, filters]);

  const casesList = useMemo(() => {
    const list = cases.filter((item) => filterCases(item, statusFilter, search, date));

    let result = [...list];

    if (hideConsignment) {
      result = result.filter((item) => item.kitVariant !== kitVariantTypes.consignment);
    }

    if (hideLoan) {
      result = result.filter((item) => item.kitVariant !== kitVariantTypes.loan);
    }

    if (filters.surgeon) {
      result = result.filter((item) => filters.surgeon === item.surgeon);
    }

    if (filters.hospital) {
      result = result.filter((item) => filters.hospital === item.hospital);
    }

    if (showCanceled) {
      return result;
    }

    return result.filter((item) => !!item.active);
  }, [cases, statusFilter, search, date, showCanceled, search, cases, hideConsignment, hideLoan, filters]);

  const connectedCasesList = useMemo(() => {
    const list = connectedCases.filter((item) => filterCases(item, statusFilter, search, date));

    if (showCanceled) {
      return list;
    }

    return list.filter((item) => !!item.active);
  }, [connectedCases, statusFilter, search, date, showCanceled]);

  const handleCheck = (caseId) => {
    const checked = checkedCases.slice();

    if (checked.includes(caseId)) {
      const index = checked.indexOf(caseId);

      checked.splice(index, 1);
      setCheckedCases(checked);

      return;
    }

    checked.push(caseId);
    setCheckedCases(checked);
  };

  const handleCheckAll = () => {
    const checked = checkedCases.length === casesList.length;
    const temp = [];

    if (!checked) {
      casesList.forEach((item) => {
        temp.push(item.id);
      });

      setCheckedCases(temp);
      return;
    }

    setCheckedCases(temp);
  };

  const onTabChange = async (tab) => {
    if (tab === 0) {
      setActiveTab(0);
    }
    if (tab === 1) {
      setActiveTab(1);
      await fetchConnectedCases();
    }
    setCheckedCases([]);
  };

  const renderDay = (day, selectedDate, isInCurrentMonth, dayComponent) => {
    const className = getClassName(day);

    return <div className={`MuiDatepickerDay-container ${className}`}>
      {dayComponent}
    </div>;
  };

  const onRemoveFilter = (filter) => {
    if (filter === 'consignment') {
      dispatch(toggleConsignment());
    }
    if (filter === 'loan') {
      dispatch(toggleLoan());
    }
    if (filter === 'shipped') {
      dispatch(toggleShipped());
    }
    if (filter === 'surgeon') {
      dispatch(setFilters({ surgeon: '' }));
    }
    if (filter === 'hospital') {
      dispatch(setFilters({ hospital: '' }));
    }
  };

  const getClassName = (day) => {
    const casesList = activeTab === 1 ? connectedCases : cases;
    const list = showCanceled ? casesList : casesList.filter((item) => !!item.active);

    if (list.find((item) => moment(item.date).isSame(day, 'day') && item.status === caseStatusOptions.overdue)) {
      return 'overdue';
    }
    if (list.find((item) => moment(item.date).isSame(day, 'day') && item.status === caseStatusOptions.request)) {
      return 'request';
    }
    if (list.find((item) => moment(item.date).isSame(day, 'day') && item.status === caseStatusOptions.booked)) {
      return 'booked';
    }
    if (list.find((item) => moment(item.date).isSame(day, 'day') && item.status === caseStatusOptions.completed)) {
      return 'completed';
    }
    return '';
  };

  const isDisabled = useMemo(() => {
    if (checkedCases && checkedCases?.length) {
      const _cases = checkedCases?.map((caseId) => {
        const data = cases?.find((c) => c.id === caseId);
        return data || {};
      });
      return !!_cases?.some((item) => item?.status === caseStatusOptions.completed);
    }
    return false;
  }, [cases, checkedCases]);

  const renderTable = () => {
    switch (activeTab) {
      case 0:
        return (
          <CasesTable
            cases={casesList}
            surgeons={surgeons}
            hospitals={hospitals}
            procedures={procedures}
            onSelectCase={onSelectCase}
            handleCheck={handleCheck}
            checkedCases={checkedCases}
            handleCheckAll={handleCheckAll}
            pagination
            checkboxes
            order={order}
            setOrder={(value) => dispatch(setOrder(value))}
            orderBy={orderBy}
            setOrderBy={(value) => dispatch(setOrderBy(value))}
            page={page}
            setPage={(value) => dispatch(setPage(value))}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={(value) => dispatch(setRowsPerPage(value))}
          />
        );
      case 1:
        return (
          <ConnectedCasesTable
            cases={connectedCasesList}
            onSelectCase={(item) => onSelectCase(item, true)}
            handleCheck={handleCheck}
            checkedCases={checkedCases}
            handleCheckAll={handleCheckAll}
            pagination
            checkboxes
            tenants={tenants}
          />
        );
      default:
        return (
          <CasesTable
            cases={casesList}
            surgeons={surgeons}
            hospitals={hospitals}
            procedures={procedures}
            onSelectCase={onSelectCase}
            handleCheck={handleCheck}
            checkedCases={checkedCases}
            handleCheckAll={handleCheckAll}
            pagination
            checkboxes
            order={order}
            setOrder={(value) => dispatch(setOrder(value))}
            orderBy={orderBy}
            setOrderBy={(value) => dispatch(setOrderBy(value))}
            page={page}
            setPage={(value) => dispatch(setPage(value))}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={(value) => dispatch(setRowsPerPage(value))}
          />
        );
    }
  };

  return (
    <div className='page-container'>
      { ((loading && !isLoaded) || fetching) && <LoadScreen /> }
      <span className='page-title'>Cases</span>
      <div className='cases-page__body'>
        <div className='filters-container'>
          <CasesFilters
            onAddCaseClick={onAddCaseClick}
            view={view}
            setView={setView}
            statusFilter={statusFilter}
            onStatusFilterChange={onStatusFilterChange}
            caseWriteDisabled={caseWriteDisabled}
            showCanceled={showCanceled}
            onToggle={onToggle}
            disabled={isDisabled}
          />

          <KitFiltersList
            onClick={() => toggleModal(true)}
            hideShipped={hideShipped}
            hideReturned={hideReturned}
            hideConsignment={hideConsignment}
            hideLoan={hideLoan}
            activeTab={activeTab}
            surgeon={getSurgeonName(filters?.surgeon)}
            hospital={getHospitalName(filters?.hospital)}
            onDelete={onRemoveFilter}
          />

          {!!statusFilter.completed && !completedLoaded && (
            <div className='d-flex flex-center p-b-lg'>
              <CircularProgress />
            </div>
          )}

          {view === views.CALENDAR ? (
            <CasesCalendarView
              cases={casesList}
              onSelectCase={(id) => history.push(`${routes.CASES}/${id}`)}
            />
          ) : (
            userRole === userRoles.SALES_REP.name ? (
              <Tabs
                tabs={tabs}
                activeTab={activeTab}
                onTabChange={onTabChange}
              >
                <div className='m-t-lg'>
                  {renderTable()}
                </div>
              </Tabs>
            ) : (
              <CasesTable
                cases={casesList}
                surgeons={surgeons}
                hospitals={hospitals}
                procedures={procedures}
                onSelectCase={onSelectCase}
                handleCheck={handleCheck}
                checkedCases={checkedCases}
                handleCheckAll={handleCheckAll}
                pagination
                checkboxes
                order={order}
                setOrder={(value) => dispatch(setOrder(value))}
                orderBy={orderBy}
                setOrderBy={(value) => dispatch(setOrderBy(value))}
                page={page}
                setPage={(value) => dispatch(setPage(value))}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={(value) => dispatch(setRowsPerPage(value))}
              />
            )
          )}
        </div>
        { cases && view === views.TABLE && !!cases.length && (
          <div className='cases-form__container'>
            <Input
              type='search'
              placeholder='Search Cases (Patient ref or Case #)'
              value={search}
              onChange={handleSearch}
            />
            <div className='calendar__container'>
              <div className='font-bold m-l-lg m-t-sm m-b-sm'>
                Filter Cases
              </div>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  autoOk
                  variant='static'
                  value={date}
                  onChange={setDate}
                  renderDay={renderDay}
                />
              </MuiPickersUtilsProvider>
              <div className='clear-button__container'>
                <Button
                  type='submit'
                  text='Clear date'
                  background='#0B0B0B'
                  onClick={() => setDate(null)}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <CaseModal
        open={caseModalOpen}
        onClose={() => toggleCaseModal(false)}
        mode='create'
        hospitals={hospitals}
        surgeons={surgeons}
        procedures={procedures}
        procedureOptions={procedureOptions}
        products={products}
        kits={kits}
        onSubmit={onAddCase}
        userRole={userRole}
        userAddresses={userAddresses}
        flows={flows}
        items={items}
        sets={sets}
        currentTenant={currentTenant}
      />

      <ConfirmationModal
        open={deleteModalOpen}
        onClose={() => toggleDeleteModal(false)}
        onSubmit={onDeleteCases}
        title='Are you sure you want to delete these cases?'
        submitText='Delete'
        loading={isDeleting}
      />

      <AvailableKitsModal
        open={!!availableModalData}
        onClose={() => setAvailableModalData(null)}
        kitsList={kits}
        kits={availableModalData?.kits || []}
        onContinueClick={() => onContinueClick(availableModalData?.id)}
      />

      <KitsFiltersModal
        title='Filter Cases'
        open={isOpen}
        onClose={() => toggleModal(false)}
        activeTab={activeTab}
        hideShipped={hideShipped}
        hideReturned={hideReturned}
        hideConsignment={hideConsignment}
        hideLoan={hideLoan}
        toggleShipped={() => dispatch(toggleShipped())}
        toggleReturned={() => dispatch(toggleReturned())}
        toggleConsignment={() => dispatch(toggleConsignment())}
        toggleLoan={() => dispatch(toggleLoan())}
        surgeon={filters.surgeon}
        hospital={filters.hospital}
        onSurgeonChange={(value) => dispatch(setFilters({ surgeon: value }))}
        onHospitalChange={(value) => dispatch(setFilters({ hospital: value }))}
        surgeons={surgeons}
        hospitals={hospitals}
      />
    </div>
  );
};

export default withFirebase(CasesPage);
