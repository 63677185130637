import React from 'react';

import FileIcon from '../../../assets/icons/DocumentIcon';
import ImageIcon from '../../../assets/icons/ImageIcon';

import Modal from '../../shared/modal';

const CaseFlowFilesModal = (props) => {
  const {
    open,
    onClose,
    files = [],
    type = 'documents'
  } = props;

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={type === 'images' ? 'Images' : 'Documents'}
      fullWidth
    >
      <div className='case-sets-modal__container'>
        <div className='d-flex direction-column align-start'>
          {files?.map((file) => (
            <a
              key={`${file.fileName}_${Math.random()}`}
              href={file.downloadUrl}
              target='_blank'
              rel='noreferrer'
              className='default-styled-link case-flow-file__item'
            >
              {type === 'images' ? <ImageIcon /> : <FileIcon />}
              {file.fileName}
            </a>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default CaseFlowFilesModal;
