import React, { useMemo } from 'react';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import DocumentIcon from '../../assets/icons/DocumentIcon';

const BillOfMaterialBreadcrumbs = ({ kitId = '', selectedSection }) => {
  const breadcrumbs = useMemo(() => {
    const initial = { name: kitId }
    return [initial, selectedSection];
  }, [kitId, selectedSection]);

  return (
    <div className='drive-breadcrumbs__container'>
      <DocumentIcon className='m-r-md' style={{ fontSize: '24px' }} />
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize='small' />}
        aria-label='breadcrumb'
        maxItems={4}
      >
        {breadcrumbs.map((item) => (
          <div
            key={Math.random()}
            className='drive-breadcrumbs__item'
            style={{ cursor: 'default' }}
          >
            {item?.name || item?.title}
          </div>
        ))}
      </Breadcrumbs>
    </div>
  );
};

export default BillOfMaterialBreadcrumbs;
