import React, { useState, useMemo, useCallback } from 'react';
import moment from 'moment';

import DocumentsIcon from '../../../assets/icons/DocumentIcon';
import NotesIcon from '../../../assets/icons/NotesIcon';
import ImagesIcon from '../../../assets/icons/ImageIcon';
import LinkIcon from '../../../assets/icons/LinkIcon';

import Table from '../../shared/table/Table';
import CaseFlowFilesModal from './CaseFlowFilesModal';
import ShipNotesModal from '../modals/ShipNotesModal';

import { flowStepStatuses, setAllocationStatuses } from '../../../constants/enums';

const { SHIPPED, TRANSFERRED, DISABLED } = setAllocationStatuses;

const getColor = (status) => {
  switch (status) {
    case flowStepStatuses.PENDING.value:
      return TRANSFERRED.color;
    case flowStepStatuses.COMPLETED.value:
      return SHIPPED.color;
    default:
      return DISABLED.color;
  }
};

const CaseFlowTable = (props) => {
  const {
    rows,
    onRowClick = () => {},
    caseDate,
    activeStep,
    users = [],
    userRole,
    tenantColor,
  } = props;

  const [documentsModalOpen, toggleDocumentsModal] = useState(false);
  const [imagesModalOpen, toggleImagesModal] = useState(false);
  const [notesModalOpen, toggleNotesModal] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [images, setImages] = useState([]);
  const [note, setNote] = useState(null);

  const columns = useMemo(() => (
    [
      {
        title: '',
        field: 'stepNumber',
        type: 'custom',
        formatter: (value, row) => {
          return (
            <div
              className='case-flow__active-step'
              style={{
                width: 20,
                height: 20,
                background: getColor(row.stepNumber - activeStep > 0 ? null : row.status)
              }}
            >
              {value}
            </div>
          );
        },
      },
      { title: 'title', field: 'title', type: 'default' },
      {
        title: 'status',
        field: 'status',
        type: 'custom',
        formatter: (value, row) => {
          return (
            <span
              style={{
                color: row.status === flowStepStatuses.COMPLETED.value ? SHIPPED.color : TRANSFERRED.color
              }}
            >
              {row.stepNumber - activeStep > 0 ? '' : flowStepStatuses[value]?.label}
            </span>
          );
        },
      },
      { title: 'due by', field: 'dueBy', type: 'date' },
      { title: 'user', field: 'user', type: 'default' },
      { title: 'date', field: 'completedAt', type: 'date' },
      { title: 'documents', field: 'documents', type: 'action-primary' },
      { title: 'images', field: 'images', type: 'action-primary' },
      { title: 'link', field: 'link', type: 'action-primary' },
      { title: 'notes', field: 'notes', type: 'action-primary' },
    ]
  ), [rows]);

  const getUserName = (userId) => {
    const user = users?.find((user) => user.uid === userId);
    return user ? `${user.firstName} ${user.lastName}` : '';
  };

  const onFilesClick = (files, type) => {
    if (type === 'documents') {
      setDocuments(files);
      toggleDocumentsModal(true);
    }

    if (type === 'images') {
      setImages(files);
      toggleImagesModal(true);
    }
  }

  const onNotesClick = (note, userId, date) => {
    setNote({
      text: note,
      author: getUserName(userId),
      date,
    });
    toggleNotesModal(true);
  }

  const onLinkClick = (url) => {
    window.open(url);
  };

  const hasAccess = useCallback((row) => row?.config?.readAccess?.includes(userRole), [rows, userRole]);

  return (
    <div style={{ width: '100%' }}>
      <Table
        rows={rows?.map((row) => ({
          ...row,
          dueBy: moment(caseDate).subtract(Number(row?.config?.dueDate), 'days'),
          user: getUserName(row?.userId),
          documents: {
            onClick: hasAccess(row) && row?.documents && !!row?.documents?.length ? () => onFilesClick(row.documents, 'documents') : null,
            icon: <DocumentsIcon color="#ffffff" />,
          },
          images: {
            onClick: hasAccess(row) && row?.images && !!row?.images?.length ? () => onFilesClick(row.images, 'images') : null,
            icon: <ImagesIcon color="#ffffff"/>,
          },
          link: {
            onClick: hasAccess(row) && row?.link ? () => onLinkClick(row.link) : null,
            icon: <LinkIcon color="#ffffff"/>,
          },
          notes: {
            onClick: hasAccess(row) && row.notes ? () => onNotesClick(row.notes, row.userId, row.completedAt) : null,
            icon: <NotesIcon color="#ffffff"/>,
          },
        }))}
        additionalData={{ tenantColor }}
        columns={columns}
        onRowClick={onRowClick}
        checkboxes={false}
        rowColor='#ffffff'
        rowBorderColor='#fafafa'
      />

      <CaseFlowFilesModal
        open={documentsModalOpen}
        onClose={() => {
          toggleDocumentsModal(false);
          setDocuments([]);
        }}
        type='documents'
        files={documents}
      />

      <CaseFlowFilesModal
        open={imagesModalOpen}
        onClose={() => {
          toggleImagesModal(false);
          setImages([]);
        }}
        type='images'
        files={images}
      />


      <ShipNotesModal
        open={notesModalOpen}
        onClose={() => {
          toggleNotesModal(false);
          setNote(null);
        }}
        title='Notes'
        note={note?.text}
        author={note?.author}
        date={note?.date}
      />
    </div>
  );
};

export default CaseFlowTable;
