import React, { useCallback } from 'react';

import Table from '../shared/table/Table';

import { itemTypeOptions } from '../../constants/enums';

import './items.scss';
import { getCommaSeparatedNames } from '../../utils/table';

const ItemsTable = (props) => {
  const {
    onSelectItem,
    items,
    selectedItemId,
    handleCheck,
    checkedItems,
    handleCheckAll,
    products,
  } = props;

  const onImageClick = (e, row) => {
    if (row && row.image && row.image.downloadUrl) {
      window.open(row.image.downloadUrl, '_blank');
    } else if (row && row.imageUrl) {
      window.open(row.imageUrl, '_blank');
    }

    e.stopPropagation();
  };

  const columns = [
    { title: 'item code', field: 'code', type: 'default', sort: true },
    { title: 'description', field: 'description', type: 'default', sort: true },
    { title: 'type', field: 'typeName', type: 'default', sort: true },
    { title: 'rebate code', field: 'rebateCode', type: 'default', sort: true },
    { title: 'gtin', field: 'gtin', type: 'default', sort: true },
    {
      title: 'image',
      field: 'preview',
      type: 'custom',
      formatter: (value, row) => {
        const image = value || row?.imageUrl;

        if (image) {
          return (
            <div className='item-image-preview' onClick={(e) => onImageClick(e, row)}>
              <img src={image} alt='preview' />
            </div>
          );
        }
      }
    },
    { title: 'products', field: 'productNames', type: 'default', sort: true },
    { title: 'status', field: 'active', type: 'status' },
  ];

  const getProductNames = useCallback((ids) => getCommaSeparatedNames(products, ids), [items, products]);

  return (
    <div style={{ width: '100%' }}>
      <Table
        rows={items.map((item) => ({
          ...item,
          typeName: itemTypeOptions[item.type] ? itemTypeOptions[item.type].label : '',
          preview: item.image ? item.image.downloadUrl : null,
          productNames: getProductNames(item.products),
        }))}
        columns={columns}
        onRowClick={onSelectItem}
        selected={selectedItemId}
        handleCheck={handleCheck}
        checkedRows={checkedItems}
        handleCheckAll={handleCheckAll}
        pagination
        noCheckAll
      />
    </div>
  );
};

export default ItemsTable;
