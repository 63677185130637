import React, { useState } from 'react';
import readXlsxFile from 'read-excel-file';
import WarningIcon from '@material-ui/icons/Error';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Modal from '../shared/modal/Modal';
import Button from '../shared/Button';
import Dropzone from '../shared/dropzone';
import { itemTypeOptions, setAllocationStatuses } from '../../constants/enums';

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: '12px',
    padding: '4px 8px',
    borderRadius: 8
  }
}));

const UploadItemsModal = (props) => {
  const {
    open,
    onSubmit,
    onClose,
    loading,
    tenantColor,
  } = props;

  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);

  const classes = useStyles();

  const onRemove = () => {
    setFile(null);
  };

  const onFileChange = async (files) => {
    if (files && files.length) {
      setFile(files[0]);
    }
    const rows = await readXlsxFile(files[0]);
    if (rows && rows.length) {
      setData(rows);
    }
  }

  const handleSubmit = () => {
    const items = [...data];
    items.shift();
    onSubmit(items);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Upload Items"
      fullWidth
    >
      <div className='upload-modal__container'>
        <div className='m-b-lg'>
          Please provide *.XLSX file for upload
        </div>
        <div>
          <div className='secondary m-b-md'>Format</div>
          <div className='modal-select align-center m-b-lg font-bold'>
            {['Item Code', 'Description', 'Type', 'Rebate Code', 'Value', 'GTIN', 'Checklist Description', 'Batch Control (YES or NO)', 'Image URL'].join(', ')}
          </div>
        </div>

        <div className='d-flex m-b-lg'>
          <WarningIcon style={{ color: setAllocationStatuses.ASSIGNED.color, marginRight: 4 }} />
          <span className='font-bold' style={{ color: setAllocationStatuses.ASSIGNED.color }}>
              Item type must be one of the following values  -
              <Tooltip
                title={Object.values(itemTypeOptions)?.map((i) => i?.label)?.join(', ')}
                aria-label='types-tooltip'
                classes={{ tooltip: classes.tooltip }}
              >
                <span className='font-bold m-l-sm' style={{ fontSize: 14, textDecoration: 'underline' }}>
                  view here
                </span>
              </Tooltip>
            </span>
        </div>

        <Dropzone
          files={file ? [file] : []}
          onUpload={onFileChange}
          onRemove={onRemove}
          tenantColor={tenantColor}
          multiple={false}
          withoutProgress
        />

        {data?.length > 1000 && (
          <div className='d-flex m-t-lg'>
            <WarningIcon style={{ color: setAllocationStatuses.TRANSFERRED.color, marginRight: 4 }} />
              <span className='font-bold' style={{ color: setAllocationStatuses.TRANSFERRED.color }}>
                Uploading process may take several minutes. To avoid any data loss, do not close this window until upload has completed.
              </span>
          </div>
        )}

        <div className='upload-modal__actions'>
          <Button
            type='cancel'
            onClick={onClose}
          />
          <Button
            type='submit'
            text='Upload'
            disabled={loading || !data.length}
            loading={loading}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UploadItemsModal;
