const formatRoles = (roles) => {
  return roles.map(role => typeof role === 'object' && role.name ? role.name : role);
};

const isAccessPermitted = ({ roles, excludeRoles, userRole, isGlobalAdmin, emailVerified }) => {
  if ((!roles || !roles.length) && !userRole) {
    return false;
  }

  const ROLES = roles && formatRoles(roles);
  const EXCLUDE_ROLES = excludeRoles && formatRoles(excludeRoles);

  if (ROLES) {
    return Array.isArray(ROLES) ? ROLES.lastIndexOf(userRole) !== -1 : ROLES === userRole;
  } else if (EXCLUDE_ROLES) {
    return Array.isArray(EXCLUDE_ROLES) ? EXCLUDE_ROLES.indexOf(userRole) === -1 : EXCLUDE_ROLES !== userRole;
  }

  return !ROLES && !EXCLUDE_ROLES;
};

export default isAccessPermitted;
