import React from 'react';
import { Field, Form } from 'react-final-form';

import Button from '../../shared/Button';
import Input from '../../shared/Input';

import { validators } from '../../../utils';

const StepTwo = ({ step, onStepNext, onStepBack, initialValues }) => {
  const validate = (values) => {
    const errors = {};

    if (!values.firstName || !values.lastName) {
      errors.firstName = 'Required';
    }
    if (!values.businessEmail) {
      errors.businessEmail = 'Required';
    } else if (!validators.emailValidator.test(values.businessEmail)) {
      errors.businessEmail = 'Not valid';
    }

    return errors;
  };

  return(
    <div className='step__container'>
      <span className='second-step__title'>What's your contact info?</span>
      <span className='second-step__subtitle'>You'll be the Case account admin since you're creating the account.</span>

      <div className='step__body'>
        <Form
          onSubmit={(formObj) => onStepNext(step, formObj)}
          initialValues={initialValues}
          validate={validate}
        >
          {
            ({ handleSubmit, submitting , invalid}) => (
              <form
                onSubmit={handleSubmit}
                className='first-step__form'
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    handleSubmit();
                  }
                }}
              >
                <span className='form__subtitle'>Your Name</span>
                <div className='full-name__container'>
                  <Field name='firstName'>
                      {({ input, meta }) => (
                        <>
                          <Input
                            name={input.name}
                            value={input.value}
                            borderless
                            startAdornment={'First Name'}
                            type='text'
                            onChange={input.onChange}
                          />
                        </>
                      )}
                  </Field>
                  <Field name='lastName'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        startAdornment={'Second Name'}
                        borderless
                        type='text'
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>

                <Field name='businessEmail'>
                  {({ input, meta }) => (
                    <>
                        {/*<span className='step_subtitle'>Current email address*/}
                        {/*  {meta.error && meta.touched &&*/}
                        {/*  <span className='form__error'>{meta.error}</span>}*/}
                        {/*</span>*/}
                      <Input
                        name={input.name}
                        value={input.value}
                        placeholder='Email'
                        type='text'
                        onChange={input.onChange}
                      />
                    </>
                  )}
                </Field>

                <div className='form__next'>
                  <Button
                    type='secondary'
                    text='Back'
                    onClick={() => onStepBack(step)}
                  />
                  <Button
                    type='submit'
                    text='Next'
                    disabled={submitting || invalid}
                    onClick={handleSubmit}
                  />
                </div>
              </form>
            )}
        </Form>
      </div>
    </div>
  );
};

export default StepTwo;
