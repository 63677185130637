import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function CalendarIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M4.75342 0.75L4.75342 3.28918" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M9.23975 0.75L9.23975 3.28918" />
    <path stroke={color || defaultColor} fill="none"
          d="M1.09183 10.525C1.25317 11.9644 2.42311 13.1072 3.86983 13.1769C4.86978 13.225 5.89127 13.2501 6.99991 13.2501C8.10855 13.2501 9.13003 13.225 10.13 13.1769C11.5767 13.1072 12.7466 11.9644 12.908 10.525C13.0166 9.55566 13.1058 8.56188 13.1058 7.54953C13.1058 6.53719 13.0166 5.54341 12.908 4.5741C12.7466 3.13471 11.5767 1.99187 10.13 1.92219C9.13003 1.87403 8.10855 1.849 6.99991 1.849C5.89127 1.849 4.86978 1.87403 3.86983 1.92219C2.42311 1.99187 1.25317 3.13471 1.09183 4.5741C0.983182 5.54341 0.894043 6.53719 0.894043 7.54953C0.894043 8.56188 0.983182 9.55566 1.09183 10.525Z" />
    <path stroke={color || defaultColor} fill="none" d="M1.18628 5.5H12.8738" />
  </SvgIcon>
}
