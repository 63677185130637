import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function UserIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M7.00001 8.24428C8.20773 8.24428 9.18678 7.26523 9.18678 6.0575C9.18678 4.84978 8.20773 3.87073 7.00001 3.87073C5.79228 3.87073 4.81323 4.84978 4.81323 6.0575C4.81323 7.26523 5.79228 8.24428 7.00001 8.24428Z" />
    <path stroke={color || defaultColor} fill="none"
          d="M0.959867 10.2685C1.114 11.7092 2.2727 12.8679 3.71266 13.0284C4.78221 13.1476 5.88037 13.25 7 13.25C8.11963 13.25 9.21779 13.1476 10.2873 13.0284C11.7273 12.8679 12.886 11.7092 13.0401 10.2685C13.1539 9.20502 13.25 8.11315 13.25 7C13.25 5.88684 13.1539 4.79498 13.0401 3.73147C12.886 2.29082 11.7273 1.13211 10.2873 0.971609C9.21779 0.852392 8.11963 0.75 7 0.75C5.88037 0.75 4.78221 0.852392 3.71266 0.971609C2.2727 1.13211 1.114 2.29082 0.959867 3.73147C0.846083 4.79498 0.75 5.88684 0.75 7C0.75 8.11315 0.846084 9.20502 0.959867 10.2685Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinejoin="round"
          d="M10.7429 12.9424C10.7477 12.9263 10.7521 12.9101 10.756 12.8937C10.5801 12.0273 10.11 11.2484 9.42552 10.6889C8.74099 10.1294 7.88408 9.82373 6.99999 9.82373C6.1159 9.82373 5.259 10.1294 4.57447 10.6889C3.89944 11.2406 3.40686 12.0795 3.22461 12.9335C3.38274 12.9778 3.54578 13.0098 3.71258 13.0284C4.78213 13.1476 5.88029 13.25 6.99993 13.25C8.11956 13.25 9.21772 13.1476 10.2873 13.0284C10.4427 13.0111 10.5949 12.9821 10.7429 12.9424Z" />
  </SvgIcon>
}

