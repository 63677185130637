import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import ManufacturerListItem from './ManufacturerListItem';
import Input from '../shared/Input';
import Button from '../shared/Button';
import Modal from '../shared/modal/Modal';

import { addManufacturer } from '../../actions/kitsActions';

import '../shared/modal-select/modal-select.scss';

const ManufacturersModal = (props) => {
  const {
    title,
    manufacturers,
    onChange,
    selected,
    onClose,
    isOpen,
  } = props;

  const dispatch = useDispatch();

  const [manufacturerIds, setManufacturerIds] = useState(selected);
  const [newManufacturer, setNewManufacturer] = useState('');
  const [search] = useState('');

  useEffect(() => {
    setManufacturerIds(selected);
  }, [selected]);

  const handleNewManufacturers = (manufacturerId) => {
    const temp = manufacturerIds.slice();
    if (temp.includes(manufacturerId)) {
      const index = temp.indexOf(manufacturerId);

      temp.splice(index, 1);
      setManufacturerIds(temp);
      return;
    }

    temp.push(manufacturerId);
    setManufacturerIds(temp);
  };

  const handleAdd = () => {
    dispatch(addManufacturer(newManufacturer));
    setNewManufacturer('');
  };

  const list = useMemo(() => {
    return manufacturers?.filter((item) => item?.name.toLowerCase().includes(search.toLowerCase())) || []
  }, [search, manufacturers]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <div className='form__container'>
        <div className='form__body'>

          <div className='form__subtitle'>
            {title}
          </div>

          <div className='d-flex'>
            <Input
              type='text'
              placeholder='New Manufacturer'
              value={newManufacturer}
              onChange={(e) => setNewManufacturer(e.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  handleAdd();
                }
              }}
              maxLength={20}
            />
            <div className='m-b-lg m-l-md'>
              <Button
                type='submit'
                text='Add'
                onClick={handleAdd}
                width={100}
                disabled={!newManufacturer.length}
              />
            </div>
          </div>

          <div className='field__title'>Manufacturers</div>
          <div className='m-b-lg form__select-users'>
            {list.length ? (
              list.map((manufacturer) => (
                <ManufacturerListItem
                  key={manufacturer.id}
                  checked={manufacturerIds.indexOf(manufacturer.id) !== -1}
                  onChange={() => handleNewManufacturers(manufacturer.id)}
                  id={manufacturer.id}
                  name={manufacturer.name}
                />
              ))
            ) : (
              <div className='text-center secondary p-md'>
                No Manufacturers
              </div>
            )}
          </div>

          <div className='form__actions'>
            <Button
              type='submit'
              text='Submit'
              onClick={() => {
                onChange(manufacturerIds);
                onClose();
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ManufacturersModal;
