import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function UsageIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M12.3308 4.73059C12.3308 4.02782 12.2434 3.24267 12.1392 2.48506C12.0025 1.49118 11.14 0.75 10.1367 0.75H2.9441C1.94086 0.75 1.07838 1.49118 0.941674 2.48505C0.837469 3.24266 0.75 4.02782 0.75 4.73059C0.75 5.43336 0.837469 6.21852 0.941675 6.97613C1.07838 7.97 1.94086 8.71118 2.9441 8.71118H8.52782" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M8 11.375L9.81818 13.25C10.6737 10.7923 11.3863 9.71386 13 8.25" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M3.78662 4.60254L3.78662 4.85864" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M6.54053 4.60254L6.54053 4.85864" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M9.29419 4.60254L9.29419 4.85864" />
  </SvgIcon>
}

