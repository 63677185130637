import React from 'react';

import './profile.scss';

const Company = (props) => {
  const { title, logo, color, selected, onClick = () => {}, selectable, inReview } = props;

  return (
    <div
      className={`profile__company-container${selected ? ' selected' : ''}${selectable ? ' pointer' : ''}`}
      style={selected ? { borderColor: color } : {}}
      onClick={onClick}
    >
      <div className='company-title-container'>
        <div className='company-title'>{title}</div>
        {inReview && (<div className='company-review-label'>In Review</div>)}
      </div>
      <div className='company-image' style={{ borderColor: color }}>
        {logo ? (
          <img src={logo} alt='company-logo' />
        ) : (
          <div className='company-letter' style={{ color: color }}>{title ? title[0] : ''}</div>
        )}
      </div>
    </div>
  );
};

export default Company;
