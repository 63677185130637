import React, { useMemo } from 'react';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import Button from '../../shared/Button';

import { setAllocationStatuses, setPositionOptions } from '../../../constants/enums';
import BatchControlIcon from '../../../assets/icons/BatchControlIcon';

const AddButton = ({
  variant,
  disabled,
  onClick,
  color
}) => (
  <Button
    type='outlined'
    height={25}
    width={25}
    disabled={disabled}
    onClick={onClick}
    borderColor={color}
  >
    {variant === 'minus' ? (
      <RemoveIcon fontSize='small' style={{ fill: disabled ? '#cccccc' : color }} />
    ) : (
      <AddIcon fontSize='small' style={{ fill: disabled ? '#cccccc' : color }} />
    )}
  </Button>
);

const SectionItemRow = (props) => {
  const { item, allocationStatus, onSave, tenantColor, batchControl, batchItems, onEditBatchClick, batchInbound = [], onBatchInboundItemsChange } = props;

  const onMinusClick = (value) => {
    if (batchControl && batchItems?.length === 1) {
      onBatchInboundItemsChange([...batchInbound, batchItems[0].id]);
    } else {
      const inbound = value === 0 ? 0 : (value || item.outbound || item.quantity);
      const number = Number(inbound);
      if (number >= 1) {
        onSave(number - 1);
      }
    }
  };

  const onPlusClick = (value) => {
    if (batchControl && batchItems?.length === 1) {
      onBatchInboundItemsChange([...batchInbound]?.filter((id) => id !== batchItems[0].id));
    } else {
      const inbound = value === 0 ? 0 : (value || item.outbound || item.quantity);
      const number = Number(inbound);
      onSave(number + 1);
    }
  };

  const onImageClick = (e, row) => {
    if (row && row.image && row.image.downloadUrl) {
      window.open(row.image.downloadUrl, '_blank');
    }

    if (row && row.imageUrl) {
      window.open(row.imageUrl, '_blank');
    }

    e.stopPropagation();
  };

  const imageSrc = item?.image?.downloadUrl || item.imageUrl;
  const isReturned = allocationStatus !== setAllocationStatuses.SHIPPED.value && allocationStatus !== setAllocationStatuses.CONFIRMED.value;

  const color = useMemo(() => (
    allocationStatus === setAllocationStatuses.SHIPPED.value || allocationStatus === setAllocationStatuses.CONFIRMED.value ? (
      batchControl ? (
        batchInbound?.length ? setPositionOptions.ALLOCATED.color : setPositionOptions.CONSIGNED.color
      ) : (
        item?.inbound < item?.outbound ? setPositionOptions.ALLOCATED.color : setPositionOptions.CONSIGNED.color
      )
    )
    : setPositionOptions.CONSIGNED.color
  ), [allocationStatus, batchControl, batchInbound]);

  return (
    <tr>
      <td>{item.ref}</td>
      <td>{item.code}</td>
      <td>{item.description}</td>
      <td>{item.type}</td>
      <td>
        {imageSrc && (
          <div className='item-image-preview' onClick={(e) => onImageClick(e, item)}>
            <img src={imageSrc} alt='preview' />
          </div>
        )}
      </td>
      <td style={{ width: 50 }}>
        {batchControl ? <BatchControlIcon color={color} /> : null}
      </td>
      <td style={{ textAlign: 'center' }}>{item.quantity}</td>
      <td style={{ textAlign: 'center' }}>{batchControl && !isReturned ? (batchItems?.length || 0) : item.outbound}</td>
      <td style={{ textAlign: 'end' }}>
        {allocationStatus === setAllocationStatuses.SHIPPED.value || allocationStatus === setAllocationStatuses.CONFIRMED.value ? (
          !!batchControl && batchItems?.length > 1 ? (
            <Button
              type='outlined'
              height={25}
              width={80}
              onClick={onEditBatchClick}
              color={color}
              borderColor={color}
            >
              {batchItems?.length ? batchItems.length - batchInbound?.length : 0}
            </Button>
          ) : (
            <div className='d-flex flex-end'>
              <AddButton
                variant='minus'
                onClick={() => onMinusClick(item.inbound)}
                disabled={(batchControl ? (!batchItems.length || batchItems.length - batchInbound?.length === 0 || item.outbound === 0) : item.inbound === 0) || item.outbound === 0}
                color={color}
              />
              <div
                style={{
                  color: color,
                  textAlign: 'center',
                  marginLeft: 4,
                  marginRight: 4,
                  width: 25
                }}
              >
                {batchControl ? (
                  batchItems?.length ? batchItems.length - batchInbound?.length : 0
                ) : (
                  item.inbound === undefined ? item.outbound : item.inbound
                )}
              </div>
              <AddButton
                variant='plus'
                onClick={() => onPlusClick(item.inbound)}
                disabled={(batchControl ? (!batchItems.length || batchInbound?.length === 0) : (item.inbound === item.outbound || item.inbound === undefined)) || item.outbound === 0}
                color={color}
              />
            </div>
          )
        ) : (
          <div style={{ color: tenantColor, textAlign: 'center' }}>
            {item.inbound === undefined ? item.outbound : item.inbound}
          </div>
        )}
      </td>
    </tr>
  );
};

export default SectionItemRow;
