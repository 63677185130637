import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import LoadScreen from '../../../components/load-screen';
import CaseFormForm from '../../../components/cases/forms/CaseFormForm';

import { useLoading } from '../../../hooks';
import { getCustomerForm, updateCustomerForm, closeCustomerForm, deleteCustomerForm } from '../../../actions/customerFormsActions';
import { getForms, getFormById } from '../../../actions/formsActions';
import { getSurgeons } from '../../../actions/surgeonsActions';
import { getHospitals } from '../../../actions/hospitalsActions';
import { getProducts } from '../../../actions/productsActions';
import { getProcedures } from '../../../actions/proceduresActions';
import { getKits } from '../../../actions/kitsActions';

import { nowTimestampUTC } from '../../../utils/date';

import routes from '../../../constants/routes';
import { formStatuses, setPositionOptions } from '../../../constants/enums';
import userRoles from '../../../constants/userRoles';

const FormPage = () => {
  const history = useHistory();
  const params = useParams();

  const formId = params?.id?.replace(':', '');

  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user?.currentUser?.uid);
  const userRole = useSelector((state) => state.user?.currentUser?.role);
  const surgeons = useSelector((state) => state.surgeons.list);
  const hospitals = useSelector((state) => state.hospitals.list);
  const products = useSelector((state) => state.products.list);
  const procedures = useSelector((state) => state.procedures.list);
  const kits = useSelector((state) => state.kits.list);
  const users = useSelector((state) => state.users.list);

  const [form, setForm] = useState({});
  const [values, setValues] = useState({});
  const [formData, setFormData] = useState({});

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const { loading: fetching, startLoading, stopLoading } = useLoading(false);

  useEffect(() => {
    onLoad().catch((err) => console.error(err));
    return () => {
    };
  }, []);

  const onLoad = async () => {
    startLoading()
    try {
      await dispatch(getForms());
      const res = await dispatch(getCustomerForm(formId));
      if (res && res.formId && res.values) {
        setValues(res.values);
        setFormData(res);

        const resForm = await dispatch(getFormById(res.formId));
        if (resForm) {
          setForm(resForm);
        }
      }

      await Promise.all([
        dispatch(getSurgeons()),
        dispatch(getHospitals()),
        dispatch(getProducts()),
        dispatch(getProcedures()),
        dispatch(getKits()),
      ]);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onSubmit = async (files) => {
    try {
      setLoading(true);
      const doc = {
        values,
        status: formStatuses.SUBMITTED,
        submittedAt: nowTimestampUTC(),
        submittedBy: userId,
      };
      await dispatch(updateCustomerForm(formId, doc, files));
      history.push(routes.TOOLS_FORMS);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onSave = async (files) => {
    try {
      setSaving(true);
      await dispatch(updateCustomerForm(formId, { values }, files));
      history.push(routes.TOOLS_FORMS);
    } catch (err) {
      console.error(err);
    } finally {
      setSaving(false);
    }
  };

  const onClose = async (notes) => {
    try {
      setLoading(true);
      await dispatch(closeCustomerForm(formId, notes));
      history.push(routes.SUBMITTED_FORMS);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onBackClick = () => {
    history.push(routes.SUBMITTED_FORMS);
  };

  const onDelete = async () => {
    try {
      setDeleting(true);
      await dispatch(deleteCustomerForm(formId));
      history.push(routes.TOOLS_FORMS);
    } catch (err) {
      console.error(err);
    } finally {
      setDeleting(false);
    }
  };

  return (
    <div className='page-container case-checklists-page__container'>
      {fetching && <LoadScreen />}
      <>
        <div className='d-flex space-between width-100'>
          <div className='page-title'>
          </div>
          {formData?.status === formStatuses.SUBMITTED && (
            <div
              style={{
                color: setPositionOptions.AVAILABLE.color,
                fontWeight: 600,
                border: `2px solid ${setPositionOptions.AVAILABLE.color}`,
                borderRadius: 8,
                padding: '2px 12px',
                background: '#ffffff'
              }}
            >
              SUBMITTED
            </div>
          )}
          {formData?.status === formStatuses.CLOSED && (
            <div
              style={{
                color: setPositionOptions.CONSIGNED.color,
                fontWeight: 600,
                border: `2px solid ${setPositionOptions.CONSIGNED.color}`,
                borderRadius: 8,
                padding: '2px 12px',
                background: '#ffffff'
              }}
            >
              CLOSED
            </div>
          )}
        </div>
        {form && !!form?.steps?.length && (
          <CaseFormForm
            form={form}
            values={values}
            setValue={(key, value) => setValues((prevState) => ({ ...prevState, [key]: value }))}
            onSubmit={onSubmit}
            onSave={onSave}
            loading={loading}
            saving={saving}
            surgeons={surgeons}
            hospitals={hospitals}
            procedures={procedures}
            products={products}
            kits={kits}
            status={formData?.status}
            access={form?.roles?.includes(userRole) || userRole === userRoles.ADMIN.name}
            onClose={onClose}
            onBackClick={onBackClick}
            onDelete={onDelete}
            deleting={deleting}
            formData={formData}
            users={users}
          />
        )}
      </>
    </div>
  );
};

export default FormPage;
