import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SetsTable from '../../../components/sets/SetsTable';
import SetsFilters from '../../../components/sets/SetsFilters';
import SetForm from '../../../components/sets/SetForm';
import Input from '../../../components/shared/Input';
import Modal, { ConfirmationModal, UploadModal } from '../../../components/shared/modal';
import Alert from '../../../components/shared/Alert';

import {
  getSets,
  createSet,
  updateSet,
  subscribeToSets,
  bulkDeleteSets,
  uploadSets,
  subscribeToSetLabels,
} from '../../../actions/setsActions';
import { getKits } from '../../../actions/kitsActions';

import { filterSets } from '../../../utils/table';

import { statusOptions, setPositionOptions } from '../../../constants/enums';
import routes from '../../../constants/routes';

const statusFilterOptions = [
  { label: 'Active', value: statusOptions.active },
  { label: 'Deactivated', value: statusOptions.deactivated }
];

const SetsPage = (props) => {
  const {
    sets,
    kits,
    labels,
    getKits,
    createSet,
    updateSet,
    subscribeToSets,
    subscribeToSetLabels,
    bulkDeleteSets,
    uploadSets,
    tenantColor,
    deletePermission,
  } = props;

  const history = useHistory();

  const [isModalCreateOpen, toggleModalCreate] = useState(false);
  const [isModalDeleteOpen, toggleModalDelete] = useState(false);
  const [isUploadModalOpen, toggleUploadModal] = useState(false);
  const [isModalDuplicateOpen, toggleModalDuplicate] = useState(false);
  const [loading, setLoading] = useState(false);

  const [successMessage, setSuccessMessage] = useState(null);

  const [selectedSet, setSelectedSet] = useState(null);
  const [checkedSets, setCheckedSets] = useState([]);

  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  useEffect(() => {
    const unsubscribe = subscribeToSets();
    const unsubscribeToLabels = subscribeToSetLabels();

    onLoad().catch((err) => console.error(err));

    return () => {
      unsubscribe();
      unsubscribeToLabels();
    };
  }, []);

  const onLoad = async () => {
    await getKits();
  };

  const onSelectSet = (set) => {
    setSelectedSet(set);
  };

  // Create set
  const onCreateSet = async (formObj) => {
    setLoading(true);
    const setObj = {
      number: formObj.number || '',
      containerSize: formObj.containerSize || 0,
      kit: null,
      caseAllocation: null,
    };

    try {
      await createSet(setObj);
      setLoading(false);
      toggleModalCreate(false);
      setSuccessMessage('Set has been successfully created');
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Update set
  const onUpdateSet = async (formObj) => {
    setLoading(true);
    const setObj = {
      active: formObj.active,
      number: formObj.number || '',
      containerSize: formObj.containerSize || 0,
      kit: formObj.kit && formObj.kit.length ? formObj.kit[0] : null,
    };

    try {
      await updateSet(formObj.id, setObj);
      setLoading(false);
      setSuccessMessage('Set has been successfully updated');
      setSelectedSet(formObj);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Delete checked sets
  const onDeleteSets = async () => {
    await bulkDeleteSets(checkedSets);

    toggleModalDelete(false);
    setCheckedSets([]);
    setSelectedSet(null);
  };

  const handleDeleteClick = () => {
    if (checkedSets.length) {
      toggleModalDelete(true);
    }
  };

  // Upload sets
  const onUpload = async (sets) => {
    setLoading(true);

    try {
      await uploadSets(sets);
      setLoading(false);
      setSuccessMessage('Sets have been successfully uploaded');
      toggleUploadModal(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const onCaseIdClick = (caseId) => {
    history.push(`${routes.CASES}/${caseId}`);
  };

  // Duplicate set
  const onDuplicateClick = async () => {
    if (!checkedSets || checkedSets.length !== 1) {
      return;
    }

    const setId = checkedSets[0];
    const set = sets.find((s) => s.id === setId);
    setSelectedSet(set);
    toggleModalDuplicate(true);
  };

  const onDuplicateSet = async (formObj) => {
    setLoading(true);
    const setObj = {
      number: formObj.number || '',
      containerSize: formObj.containerSize || 0,
      kit: formObj.kit || null,
      caseAllocation: null,
      status: setPositionOptions.AVAILABLE.value,
    };

    try {
      await createSet(setObj);
      setLoading(false);
      toggleModalDuplicate(false);
      setSuccessMessage('Set has been successfully duplicated');
      setCheckedSets([]);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    // setCheckedSets([]);
  };

  const setList = useMemo(() => {
    return sets.filter((set) => filterSets(set, search, statusFilter));
  }, [sets, search, statusFilter]);

  const handleCheck = (setId) => {
    const checked = checkedSets.slice();

    if (checked.includes(setId)) {
      const index = checked.indexOf(setId);

      checked.splice(index, 1);
      setCheckedSets(checked);

      return;
    }

    checked.push(setId);
    setCheckedSets(checked);
  };

  const handleCheckAll = () => {
    const checked = checkedSets.length === setList.length;
    const temp = [];

    if (!checked) {
      setList.forEach((set) => {
        temp.push(set.id);
      });

      setCheckedSets(temp);
      return;
    }

    setCheckedSets(temp);
  };

  const getKitId = useCallback(() => {
    if (!selectedSet || !selectedSet.kit) {
      return [];
    }

    if (typeof selectedSet.kit === 'string') {
      return [selectedSet.kit];
    }

    return selectedSet.kit;
  }, [selectedSet]);

  return (
    <div className='settings-cmp__main'>
      <span className='settings-title'>Sets</span>
      <div className='settings-cmp__body'>
        <div className='filters-container'>
          <SetsFilters
            onAddSetClick={() => toggleModalCreate(true)}
            onDelete={handleDeleteClick}
            status={statusFilter}
            setStatus={setStatusFilter}
            statuses={statusFilterOptions}
            onUploadClick={() => toggleUploadModal(true)}
            onDuplicateClick={onDuplicateClick}
            deletePermission={deletePermission}
          />
          <SetsTable
            onSelectSet={onSelectSet}
            sets={setList}
            selectedSetId={selectedSet ? selectedSet.id : null}
            handleCheck={handleCheck}
            checkedSets={checkedSets}
            handleCheckAll={handleCheckAll}
            kits={kits}
            tenantColor={tenantColor}
            onCaseIdClick={onCaseIdClick}
            search={search}
            checkboxes={true}
            labels={labels}
          />
        </div>
        { sets && !!sets.length && (
          <div className='form-container'>
            <Input
              type='search'
              placeholder='Search Sets'
              value={search}
              onChange={handleSearch}
            />
            { selectedSet && (
              <SetForm
                initialValues={{ ...selectedSet, kit: getKitId() }}
                buttonText='Save Changes'
                onSubmit={onUpdateSet}
                loading={loading}
                mode='update'
                kits={kits}
                searchField='kitId'
                sets={sets}
              />
            )}
          </div>
        )}
      </div>

      <ConfirmationModal
        open={isModalDeleteOpen}
        onClose={() => toggleModalDelete(false)}
        onSubmit={onDeleteSets}
        title='Are you sure you want to delete these sets?'
        submitText='Delete'
      />

      <Modal
        open={isModalCreateOpen}
        onClose={() => toggleModalCreate(false)}
      >
        <SetForm
          buttonText='Add Set'
          onSubmit={onCreateSet}
          loading={loading}
          mode='create'
          onClose={() => toggleModalCreate(false)}
          sets={sets}
        />
      </Modal>

      <Modal
        open={isModalDuplicateOpen}
        onClose={() => toggleModalDuplicate(false)}
      >
        <SetForm
          initialValues={selectedSet}
          buttonText='Duplicate'
          onSubmit={onDuplicateSet}
          loading={loading}
          mode='create'
          onClose={() => toggleModalDuplicate(false)}
          sets={sets}
        />
      </Modal>

      <UploadModal
        open={isUploadModalOpen}
        onClose={() => toggleUploadModal(false)}
        title='Upload Sets'
        tenantColor={tenantColor}
        onSubmit={onUpload}
        loading={loading}
        fields={['Set number', 'Container size']}
      />

      <Alert
        variant='success'
        message={successMessage}
        open={!!successMessage}
        onClose={() => setSuccessMessage(null)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sets: state.sets.list,
    kits: state.kits.list,
    labels: state.sets.labels,
    tenantColor: state.tenant.currentTenant.colorPrimary,
    deletePermission: state.user.currentUser?.deletePermission,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSets: () => dispatch(getSets()),
    getKits: () => dispatch(getKits()),
    createSet: (set) => dispatch(createSet(set)),
    updateSet: (id, setData) => dispatch(updateSet(id, setData)),
    subscribeToSets: () => dispatch(subscribeToSets()),
    subscribeToSetLabels: () => dispatch(subscribeToSetLabels()),
    bulkDeleteSets: (setIds) => dispatch(bulkDeleteSets(setIds)),
    uploadSets: (sets) => dispatch(uploadSets(sets)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetsPage);
