import React from 'react';

import Checkbox from '../../shared/Checkbox';
import AddButton from '../../shared/AddButton';

import { caseStatusOptions, setAllocationStatuses } from '../../../constants/enums';

const ItemsList = (props) => {
  const { itemsList, input, noKitsChecked, status } = props;
  const value = input.value || [];

  return (
    <div>
      {itemsList.map((item) => {
        const updatedItem = value.find((i) => i.id === item.id);
        return (
          <div className='md-user-info' key={item.id}>
            <Checkbox
              disabled={noKitsChecked || (
                status && (status === caseStatusOptions.completed || status === caseStatusOptions.overdue) && (
                  updatedItem?.status !== setAllocationStatuses.AVAILABLE.value || !updatedItem
                )
              )}
              input={{
                ...input,
                checked: !!value.find((i) => i.id === item.id),
                onChange: (e) => {
                  if (e.target.checked) {
                    input.onChange([
                      ...value,
                      { id: item.id, quantity: 1 }
                    ]);
                  } else {
                    if (updatedItem.status && updatedItem.status !== setAllocationStatuses.AVAILABLE.value) {
                      return;
                    }
                    input.onChange(value.filter((i) => i.id !== item.id));
                  }
                }
              }}
              fontSize='small'
              label={`${item.checklistDescription || item.description} – ${item.code}`}
              fontWeight='regular'
            />

            {!!value.find((i) => i.id === item.id) && (
              <div className='d-flex'>
                <AddButton
                  width={24}
                  height={24}
                  variant='remove'
                  disabled={updatedItem?.status && updatedItem?.status !== setAllocationStatuses.AVAILABLE.value}
                  onClick={() => {
                    const kits = [...value];
                    const updatedItem = kits.find((i) => i.id === item.id);
                    if (updatedItem && updatedItem.quantity > 1) {
                      updatedItem.quantity = updatedItem.quantity - 1;
                      input.onChange(kits);
                    }
                  }}
                />
                <div
                  style={{ width: 16 }}
                  className='m-l-md m-r-md text-center'
                >
                  {value?.find((i) => i.id === item.id)?.quantity || 1}
                </div>
                <AddButton
                  width={24}
                  height={24}
                  disabled={updatedItem?.status && updatedItem?.status !== setAllocationStatuses.AVAILABLE.value}
                  onClick={() => {
                    const items = [...value];
                    const updatedItem = items.find((i) => i.id === item.id);
                    updatedItem.quantity = updatedItem ? updatedItem.quantity + 1 : 2;
                    input.onChange(items);
                  }}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ItemsList;
