import {
  GET_DRIVE_FOLDERS,
  SET_DRIVE_FOLDERS,
} from '../actions/actionTypes';

const initialState = {
  folders: [],
  isLoading: false,
  isLoaded: false
};

const driveReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DRIVE_FOLDERS:
      return { ...state, isLoading: true };
    case SET_DRIVE_FOLDERS:
      return {
        ...state,
        folders: action.folders || [],
        isLoaded: true,
        isLoading: false
      };
    default:
      return state;
  }
};

export default driveReducer;
