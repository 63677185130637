import React from 'react';
import { Field, Form } from 'react-final-form';

import Button from '../../shared/Button';
import Checkbox from '../../shared/Checkbox';
import Input from '../../shared/Input';
import Alert from "../../shared/Alert";

import { validators } from '../../../utils';

const StepFour = (props) => {
  const {
    step,
    onSignUp,
    onStepBack,
    initialValues,
    loading,
    error,
    setError,
    errorText,
    minLength,
  } = props;

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Required';
    } else if (!validators.emailValidator.test(values.email)) {
      errors.email = 'Not valid';
    }
    if (!values.password) {
      errors.password = 'Required';
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = 'Required'
    }
    else if (values.confirmPassword !== values.password) {
      errors.confirmPassword = 'Must match';
      errors.password = 'Must match';
    }
    if (!values.notARobot) {
      errors.notARobot = 'Required';
    }
    if (values.password && values.password.length < minLength) {
      errors.password = `Password must be minimum of ${minLength} characters`;
    }
    return errors;
  };

  return (
    <div className='step__container'>
      <span className='second-step__title'>How you'll sign in</span>
      <span
        className='second-step__subtitle'>You'll need to create a password to sign in to the Case Admin panel.</span>
      <div className='step__body'>
        <Form
          onSubmit={onSignUp}
          initialValues={initialValues}
          validate={validate}
        >
          {
            ({ handleSubmit, submitting , invalid }) => (
              <form
                onSubmit={handleSubmit}
                className='first-step__form'
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    handleSubmit();
                  }
                }}
              >
                <Field name='email'>
                  {({ input, meta }) => (
                    <>
                      {/*<span className='step_subtitle'>Your email address*/}
                      {/*  {meta.error && meta.touched &&*/}
                      {/*  <span className='form__error'>{meta.error}</span>}*/}
                      {/*</span>*/}
                      <Input
                        name={input.name}
                        value={input.value}
                        placeholder='Email'
                        type='text'
                        onChange={input.onChange}
                      />
                    </>
                  )}
                </Field>

                <Field name='password'>
                  {({ input, meta }) => (
                    <>
                      {/*<span className='step_subtitle'>Password*/}
                      {/*  {meta.error && meta.touched &&*/}
                      {/*  <span className='form__error'>{meta.error}</span>}*/}
                      {/*</span>*/}
                      <Input
                        name={input.name}
                        value={input.value}
                        placeholder='Password'
                        type='password'
                        onChange={input.onChange}
                      />
                    </>
                  )}
                </Field>

                <Field name='confirmPassword'>
                  {({ input, meta }) => (
                    <>
                      {/*<span className='step_subtitle'>Confirm password*/}
                      {/*  {meta.error && meta.touched &&*/}
                      {/*  <span className='form__error'>{meta.error}</span>}*/}
                      {/*</span>*/}
                      <Input
                        name={input.name}
                        value={input.value}
                        placeholder='Confirm password'
                        type='password'
                        onChange={input.onChange}
                      />
                    </>
                  )}
                </Field>

                <span className='step_subtitle'>*must be at least {minLength} characters long</span>

                <div className='not-robot'>
                  <Field name='notARobot' type='checkbox'>
                    {({ input }) => (
                      <Checkbox
                        input={input}
                        fontSize='small'
                        label='I am not a robot'
                      />
                    )}
                  </Field>
                </div>

                <span className='agree-text'>
                  By clicking Continue you agree to the Opologic
                  <span className='agree__terms'> Terms and conditions</span>
                  , of Scan.
                </span>

                <div className='form__next'>
                  <Button
                    type='secondary'
                    text='Back'
                    onClick={() => onStepBack(step)}
                  />
                  <Button
                    type='submit'
                    text='Confirm'
                    disabled={submitting || loading || invalid} // ToDo: Use submitting from Form instead of loading prop
                    onClick={handleSubmit}
                    loading={loading}
                  />
                </div>
              </form>
            )}
        </Form>
      </div>
      <Alert
        variant="error"
        message={errorText}
        open={error}
        onClose={() => setError(false)}
      />
    </div>
  );
};

export default StepFour;
