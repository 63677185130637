import React, { useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';

import Button from '../../shared/Button';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './data-grid.scss';

const defaultRows = [{ code: '', description: '', quantity: '' }];

const MyGrid = ({ onUpload, loading }) => {
  const gridRef = useRef(null);

  const [rowData, setRowData] = useState([{ code: '', description: '', quantity: '' }]);

  const columnDefs = [
    { headerName: 'Ref', field: 'ref', editable: true, width: 250 },
    { headerName: 'Code', field: 'code', editable: true, width: 250 },
    { headerName: 'Description', field: 'description', editable: true, width: 250 },
    { headerName: 'Quantity', field: 'quantity', editable: true, width: 100 }
  ];

  const onGridReady = (params) => {
    gridRef.current = params.api;
  };

  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pasteData = clipboardData.getData('text');
    const rows = pasteData.split('\n');
    const newData = rows.map((row) => row.split('\t'));
    const focusedCell = gridRef.current.getFocusedCell();
    const colId = focusedCell?.column?.colId || null;

    setRowData((prevRowData) => {
      // const updatedRowData = [];
      const updatedRowData = prevRowData.slice()?.filter((row) => !!row?.code); // Create a copy of the previous row data

      newData.forEach((row) => {
        if (colId === 'code') {
          updatedRowData.push({
            code: row[0] || '',
            description: row[1] || '',
            quantity: row[2] || ''
          });
        } else if (colId === 'description') {
          updatedRowData.push({
            description: row[0] || '',
            quantity: row[1] || ''
          });
        } else if (colId === 'quantity') {
          updatedRowData.push({
            quantity: row[0] || ''
          });
        } else {
          updatedRowData.push({
            ref: row[0] || '',
            code: row[1] || '',
            description: row[2] || '',
            quantity: row[3] || ''
          });
        }
      });
      return updatedRowData;
    });
  };

  const onSubmit = async () => {
    try {
      await onUpload(rowData?.filter((item) => !!item.code));
      setRowData(defaultRows);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div style={{ flex: 1, width: '860px', display: 'flex', flexDirection: 'column', marginTop: 16 }}>
      <div style={{ height: '100%', minHeight: 'calc(100% - 76px)', flex: 1 }} className='ag-theme-alpine ag-theme-custom' onPaste={handlePaste}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          onGridReady={onGridReady}
        />
      </div>
      <div className='d-flex space-between m-t-lg align-start' style={{ marginLeft: 0 }}>
        <Button disabled={rowData?.length < 2} text="Reset" onClick={() => setRowData(defaultRows)} type="outlined" />
        <Button disabled={loading} loading={loading} text="Upload" onClick={onSubmit} />
      </div>
    </div>
  );
};

export default MyGrid;
