import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function OptionIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" d="M4.16968 4.18566C4.2004 3.74761 4.24389 3.31512 4.29033 2.88908C4.395 1.92886 5.16567 1.15832 6.12543 1.04952C6.93725 0.957496 7.77243 0.875055 8.6249 0.875055C9.47736 0.875055 10.3125 0.957495 11.1244 1.04952C12.0841 1.15832 12.8548 1.92885 12.9595 2.88907C13.0475 3.69657 13.1249 4.52724 13.1249 5.37506C13.1249 6.22288 13.0475 7.05354 12.9595 7.86104C12.8548 8.82126 12.0841 9.59179 11.1244 9.70059C10.6978 9.74895 10.2647 9.79466 9.82613 9.82715" /><path stroke={color || defaultColor} fill="none" d="M1.0261 6.27166C1.13708 5.23439 1.97135 4.40012 3.00811 4.28456C3.77819 4.19872 4.56886 4.125 5.375 4.125C6.18114 4.125 6.97181 4.19872 7.74189 4.28456C8.77865 4.40012 9.61292 5.23439 9.7239 6.27166C9.80582 7.03739 9.875 7.82353 9.875 8.625C9.875 9.42647 9.80582 10.2126 9.7239 10.9783C9.61292 12.0156 8.77865 12.8499 7.74189 12.9654C6.97181 13.0513 6.18114 13.125 5.375 13.125C4.56886 13.125 3.77819 13.0513 3.00811 12.9654C1.97135 12.8499 1.13708 12.0156 1.0261 10.9783C0.94418 10.2126 0.875 9.42647 0.875 8.625C0.875 7.82353 0.94418 7.03739 1.0261 6.27166Z" /><path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M5.375 6.375L5.375 10.875" /><path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M3.125 8.625L7.625 8.625" />
  </SvgIcon>
}
