import React from 'react';

import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

import FolderIcon from '../../../assets/icons/FolderIcon';
import KitIcon from '../../../assets/icons/KitIcon';

import './tree.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    borderRadius: 8,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: theme.spacing(2),
    '& $content': {
      paddingLeft: theme.spacing(1),
    },
  },
  label: {
    backgroundColor: 'transparent !important',
    color: 'var(--tree-view-color)',
    fontWeight: '600',
    padding: '8px 0px'
  },
}));

const TreeViewContainer = (props) => {
  const { colorPrimary, onClick, data, active } = props;
  const classes = useStyles();

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={
        <div className='d-flex'>
          {nodes.id === 'kits' ? (
            <KitIcon
              styles={{ fontSize: 24 }}
              color={active === nodes.id ? colorPrimary : ''}
            />
          ) : (
            <FolderIcon
              styles={{ fontSize: 24 }}
              color={active === nodes.id ? colorPrimary : ''}
            />
          )}
          <div
            className='m-l-md'
            style={{ color: active === nodes.id ? colorPrimary : 'inherit' }}
          >
            {nodes.name}
          </div>
        </div>
      }
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      onLabelClick={() => onClick(nodes.id)}
      onIconClick={() => onClick(nodes.id)}
    >
      {Array.isArray(nodes.children) ? nodes.children.map(renderTree) : null}
    </TreeItem>
  );

  return (
    <div className='tree-view__container'>
      <TreeView
        defaultExpanded={['root']}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {renderTree(data)}
      </TreeView>
    </div>
  );
};

export default TreeViewContainer;
