import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import QualificationRow from './QualificationRow';
import Input from '../shared/Input';
import Button from '../shared/Button';
import Modal from '../shared/modal/Modal';
import CSVUpload from '../shared/CSVUpload';

import { addQualification, uploadQualifications } from '../../actions/surgeonsActions';

import '../shared/modal-select/modal-select.scss';

const QualificationsModal = (props) => {
  const {
    title,
    qualifications,
    onChange,
    selected,
    onClose,
    isOpen,
  } = props;

  const dispatch = useDispatch();
  const tenantColor = useSelector((state) => state.tenant.currentTenant.colorPrimary);

  const [qualificationIds, setQualificationIds] = useState(selected);
  const [newQualification, setNewQualification] = useState('');
  const [search] = useState('');

  useEffect(() => {
    setQualificationIds(selected);
  }, [selected]);

  const handleNewQualification = (qId) => {
    const temp = qualificationIds.slice();
    if (temp.includes(qId)) {
      const index = temp.indexOf(qId);

      temp.splice(index, 1);
      setQualificationIds(temp);
      return;
    }

    temp.push(qId);
    setQualificationIds(temp);
  };

  const handleAdd = () => {
    dispatch(addQualification(newQualification));
    setNewQualification('');
  };

  const handleOnDrop = (file) => {
    const rows = file
      .filter((item) => item.data && item.data.length && !!item.data[0])
      .map((item) => item.data.map((i) => i.trim()));

    if (rows && rows?.length) {
      dispatch(uploadQualifications(rows)).catch(console.error);
    }
  }

  const handleOnError = (err) => {
    console.error(err)
  }

  const handleOnRemove = () => {
  };

  const list = useMemo(() => {
    return qualifications?.filter((item) => item?.name?.toLowerCase().includes(search?.toLowerCase())) || []
  }, [search, qualifications]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <div className='form__container'>
        <div className='form__body'>

          <div className='form__subtitle'>
            {title}
          </div>

          <div className='d-flex'>
            <Input
              type='text'
              placeholder='New Qualification'
              value={newQualification}
              onChange={(e) => setNewQualification(e.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  handleAdd();
                }
              }}
              maxLength={20}
            />
            <div className='m-b-lg m-l-md'>
              <Button
                type='submit'
                text='Add'
                onClick={handleAdd}
                width={100}
                disabled={!newQualification.length}
              />
            </div>
          </div>

          <CSVUpload
            onDrop={handleOnDrop}
            onError={handleOnError}
            onRemoveFile={handleOnRemove}
            tenantColor={tenantColor}
          />

          <div className='field__title'>Qualifications</div>
          <div className='m-b-lg form__select-users'>
            {list.length ? (
              list.map((q) => (
                <QualificationRow
                  key={q.id}
                  checked={qualificationIds.indexOf(q.id) !== -1}
                  onChange={() => handleNewQualification(q.id)}
                  id={q.id}
                  name={q.name}
                />
              ))
            ) : (
              <div className='text-center secondary p-md'>
                No Qualifications
              </div>
            )}
          </div>

          <div className='form__actions'>
            <Button
              type='submit'
              text='Submit'
              onClick={() => {
                onChange(qualificationIds);
                onClose();
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default QualificationsModal;
