import React, { useState, useMemo, useEffect } from 'react';
import { orderBy } from 'lodash';

import Input from '../Input';
import Checkbox from '../Checkbox';
import Button from '../Button';
import Modal from '../modal/Modal';

import './modal-select.scss';

const ModalSelect = (props) => {
  const {
    title,
    listTitle,
    options,
    onChange,
    selected = [],
    onClose,
    isOpen,
    field = 'name',
    singleSelect = false,
    extraField = '',
    selectAll = false,
  } = props;

  const [itemIds, setItemIds] = useState(selected);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setItemIds(selected);
  }, [selected]);

  const handleCheck = (id) => {
    if (singleSelect) {
      if (itemIds.includes(id)) {
        return setItemIds([]);
      }
      return setItemIds([id]);
    }

    const temp = itemIds.slice();
    if (temp.includes(id)) {
      const index = temp.indexOf(id);

      temp.splice(index, 1);
      setItemIds(temp);
      return;
    }

    temp.push(id);
    setItemIds(temp);
  };

  const list = useMemo(() => {
    return orderBy(options, field, 'asc').filter((item) => item[field]?.toLowerCase().includes(search.toLowerCase()));
  }, [options, search]);

  const onSelectAll = () => {
    const checked = itemIds?.length === options?.length;
    const temp = [];

    if (!checked) {
      options.forEach((opt) => {
        temp.push(opt.id);
      });

      setItemIds(temp);
      return;
    }

    setItemIds([]);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <div className='form__container'>
        <div className='form__body'>

          <div className='form__subtitle'>
            {title}
          </div>

          <Input
            type='search'
            placeholder='Search'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <div className='field__title'>{listTitle}</div>
          <div className='m-b-lg form__select-users'>
            {selectAll && (
              <div className='d-flex border-bottom'>
                <Checkbox
                  input={{
                    onClick: onSelectAll,
                    checked: itemIds.length === options.length
                  }}
                  fontSize='small'
                  label='Select All'
                />
              </div>
            )}
            {list.length ? (
              list.map((item) => (
                <div className='md-user-info' key={item.id}>
                  <Checkbox
                    input={{
                      onClick: () => handleCheck(item.id),
                      checked: itemIds.indexOf(item.id) !== -1
                    }}
                    fontSize='small'
                    label={item[field]}
                  />
                  {extraField && !!item[extraField] && (
                    <div>
                      {item[extraField]}
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className='text-center secondary p-md'>
                {`No ${listTitle}`}
              </div>
            )}
          </div>

          <div className='form__actions'>
            <Button
              type='submit'
              text='Submit'
              onClick={() => {
                onChange(itemIds);
                onClose();
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalSelect;
