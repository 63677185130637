/* eslint-disable no-unused-vars */

export const SetsTooltip = ({ datum }) => {
  const { color, id, formattedValue } = datum;

  return (
    <div className="custom-tooltip">
      <div className="tooltip-content">
        <div className="tooltip-label">
          {id}: {' '}
          <span className="tooltip-value">{formattedValue}</span>
        </div>
      </div>
    </div>
  );
};
