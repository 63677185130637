import React, { useMemo } from 'react';
import { orderBy } from 'lodash';

import BarChart from '../../../charts/BarChart';

const ForecastingHospitalsBarChart = ({ cases = [], hospitals = [] }) => {
  const data = useMemo(() => {
    const allHospitals = hospitals
      .map((hospital) => {
        const hospitalCases = cases.filter((item) => item.hospital === hospital.id);
        return {
          id: hospital.name,
          label: `${hospital?.name?.substring(0, 10)}${hospital?.name?.length > 10 ? '...' : ''}`,
          value: hospitalCases.length,
        };
      })
      .filter((hospital) => hospital.value !== 0);

    const sortedHospitals = orderBy(allHospitals, 'value', 'desc');
    const top10 = sortedHospitals.splice(0, 10);

    return top10;
  }, [cases, hospitals]);

  return (
    <div className="management-reports-chart__container m-l-md" style={{ width: '50%' }}>
      <div className="font-size-bg font-bold">Booked Cases Top Hospitals</div>
      <div className="secondary m-t-sm m-b-lg">Total cases by top 10 hospitals over the next 90 days</div>
      <BarChart
        data={data || []}
        height="300px"
        layout="horizontal"
        indexBy="id"
        enableLabel={true}
        margin={{ top: 10, right: 30, bottom: 30, left: 90 }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          truncateTickAt: 0,
          format: name => `${name?.substring(0, 10)}${name?.length > 10 ? '...' : ''}`,
        }}
        labelTextColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              1.6,
            ],
          ],
        }}
      />
    </div>
  );
};

export default ForecastingHospitalsBarChart;
