import React from 'react';

import ExportIcon from '../../assets/icons/DownloadIcon';

import Button from '../shared/Button';

const BillOfMaterialActions = (props) => {
  const {
    onBackClick,
    onExportClick,
    loading,
    isDrive,
  } = props;

  return (
    <div className='settings-cmp__filters--main'>
      <div className='settings-cmp__actions'>
        <Button
          type='outlined'
          text={isDrive ? 'Back to Drive' : 'Back to Sets'}
          onClick={onBackClick}
        />
        <Button
          onClick={onExportClick}
          disabled={loading}
          loading={loading}
          width={60}
        >
          {!loading && <ExportIcon color="#ffffff" />}
        </Button>
      </div>
    </div>
  );
};

export default BillOfMaterialActions;
