import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import ProfileIcon from '../../../assets/icons/ProfileIcon';
import EditIcon from '../../../assets/icons/EditIcon';

import ProformaAddressModal from './ProformaAddressModal';
import Tabs from '../../shared/Tabs';

import { getCase, simpleUpdateCase } from '../../../actions/casesActions';

import { useLoading } from '../../../hooks';

const ProformaCustomerContainer = (props) => {
  const {
    caseId,
    deliveryAddress,
    billingAddress,
    tenantColor,
  } = props;

  const dispatch = useDispatch();

  const { loading, startLoading, stopLoading } = useLoading(false);

  const [activeTab, setActiveTab] = useState(0);
  const [modalOpen, toggleModal] = useState(false);

  const onSubmit = (address, tab) => {
    try {
      startLoading();
      if (tab === 0) {
        dispatch(simpleUpdateCase(caseId, { billingAddress: address }));
      } else {
        dispatch(simpleUpdateCase(caseId, { deliveryAddress: address }));
      }
      dispatch(getCase(caseId));
      toggleModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 0:
        if (!billingAddress) {
          return <div className='height-100 d-flex flex-center secondary'>No Address</div>;
        }
        return (
          <AddressBlock address={billingAddress} />
        );
      case 1:
        if (!deliveryAddress) {
          return <div className='height-100 d-flex flex-center secondary'>No Address</div>;
        }
        return (
          <AddressBlock address={deliveryAddress} />
        );
      default:
        return null;
    }
  };

  return (
    <div className='case-usage__container'>
      <>
        <div className='d-flex space-between'>
          <div className='d-flex'>
            <ProfileIcon />
            <div className='font-size-bg font-bold m-l-md'>
              Customer
            </div>
          </div>
          <div className='pointer' onClick={() => toggleModal(true)}><EditIcon color={tenantColor} /></div>
        </div>
        <div className='case-usage-tabs__container'>
          <Tabs
            tabs={[{ label: 'Billing Address' }, { label: 'Shipping Address' }]}
            activeTab={activeTab}
            onTabChange={setActiveTab}
          >
            <div className='case-notes-list__container m-t-lg' style={{ height: '180px', overflow: 'auto' }}>
              {renderContent()}
            </div>
          </Tabs>
        </div>
      </>

      <ProformaAddressModal
        open={modalOpen}
        onClose={() => toggleModal(false)}
        billingAddress={billingAddress}
        deliveryAddress={deliveryAddress}
        onSubmit={onSubmit}
        loading={loading}
      />
    </div>
  );
};

const AddressBlock = ({ address }) => (<div>
  <div className='font-bold'>{address.label || 'Address'}</div>
  {(address.apartment || address.buildingName) && (
    <div className='m-t-sm'>{`${address.apartment || ''}${address?.apartment && address?.buildingName ? ' | ' : ''}${address.buildingName || ''}`}</div>
  )}
  <div className='m-t-sm'>{address.street || ''}</div>
  <div className='m-t-sm'>{`${address.city || ''}, ${address.state || ''}, ${address.postCode || ''}`}</div>
  {(address.neighborhood || address.department) && (
    <div className='m-t-sm'>{`${address.neighborhood || ''}${address?.neighborhood && address?.department ? ' | ' : ''}${address.department || ''}`}</div>
  )}
  {(address.attn) && (
    <div className='m-t-sm'>{`Att: ${address.attn || ''}`}</div>
  )}
</div>);

export default ProformaCustomerContainer;
