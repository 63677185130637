export const USERS_COLLECTION = 'users';
export const TENANTS_COLLECTION = 'tenants';
export const SETTINGS_COLLECTION = 'settings';
export const APPROVED_EMAILS = 'approvedEmails';

export const GROUPS_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/groups`;

export const PROCEDURES_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/procedures`;
export const PROCEDURE_OPTIONS_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/procedureOptions`;

export const PRODUCTS_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/products`;

export const HOSPITALS_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/hospitals`;
export const HOSPITAL_CONTACTS_COLLECTION = (tenantId, hospitalId) => `${TENANTS_COLLECTION}/${tenantId}/hospitals/${hospitalId}/contacts`;
export const HOSPITAL_NOTES_COLLECTION = (tenantId, hospitalId) => `${TENANTS_COLLECTION}/${tenantId}/hospitals/${hospitalId}/notes`;

export const KITS_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/kits`;
export const BILL_OF_MATERIAL_COLLECTION = (tenantId, kitId) => `${TENANTS_COLLECTION}/${tenantId}/kits/${kitId}/billOfMaterial`;
export const SECTION_ITEMS_COLLECTION = (tenantId, kitId, sectionId) => `${TENANTS_COLLECTION}/${tenantId}/kits/${kitId}/billOfMaterial/${sectionId}/items`;
export const MANUFACTURERS_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/manufacturers`;

export const SURGEONS_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/surgeons`;
export const SURGEON_PREFERENCES_COLLECTION = (tenantId, surgeonId) => `${TENANTS_COLLECTION}/${tenantId}/surgeons/${surgeonId}/preferences`;
export const SURGEON_NOTES_COLLECTION = (tenantId, surgeonId) => `${TENANTS_COLLECTION}/${tenantId}/surgeons/${surgeonId}/notes`;
export const QUALIFICATIONS_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/qualifications`;

export const CASES_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/cases`;
export const CASE_NOTES_COLLECTION = (tenantId, caseId) => `${TENANTS_COLLECTION}/${tenantId}/cases/${caseId}/notes`;
export const CASE_USAGE_NOTES_COLLECTION = (tenantId, caseId) => `${TENANTS_COLLECTION}/${tenantId}/cases/${caseId}/usageNotes`;
export const CASE_USAGE_COLLECTION = (tenantId, caseId) => `${TENANTS_COLLECTION}/${tenantId}/cases/${caseId}/usage`;
export const CASE_DOCUMENTS_COLLECTION = (tenantId, caseId) => `${TENANTS_COLLECTION}/${tenantId}/cases/${caseId}/documents`;
export const CASE_SETS_ALLOCATION_COLLECTION = (tenantId, caseId) => `${TENANTS_COLLECTION}/${tenantId}/cases/${caseId}/setsAllocation`;
export const CASE_ACTIVITY = (tenantId, caseId) => `${TENANTS_COLLECTION}/${tenantId}/cases/${caseId}/activity`;
export const CASE_FLOW = (tenantId, caseId) => `${TENANTS_COLLECTION}/${tenantId}/cases/${caseId}/flow`;
export const CASE_CHECKLISTS = (tenantId, caseId) => `${TENANTS_COLLECTION}/${tenantId}/cases/${caseId}/checklists`;
export const CASE_FORMS = (tenantId, caseId) => `${TENANTS_COLLECTION}/${tenantId}/cases/${caseId}/forms`;
export const CASE_PROFORMA = (tenantId, caseId) => `${TENANTS_COLLECTION}/${tenantId}/cases/${caseId}/proforma`;

export const DRIVE_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/drive`;
export const DRIVE_FILES_COLLECTION = (tenantId, folderId) => `${DRIVE_COLLECTION(tenantId)}/${folderId}/files`;

export const TASKS_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/tasks`;

export const SETS_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/sets`;
export const SET_DOCUMENTS_COLLECTION = (tenantId, setId) => `${TENANTS_COLLECTION}/${tenantId}/sets/${setId}/documents`;
export const SET_LABELS_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/setLabels`;
export const SET_LOGS_COLLECTION = (tenantId, setId) => `${TENANTS_COLLECTION}/${tenantId}/sets/${setId}/logs`;

export const COUNTS_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/counts`;
export const COUNT_NOTES_COLLECTION = (tenantId, countId) => `${TENANTS_COLLECTION}/${tenantId}/counts/${countId}/notes`;
export const COUNT_SCANS_COLLECTION = (tenantId, countId) => `${TENANTS_COLLECTION}/${tenantId}/counts/${countId}/scans`;

export const NOTIFICATIONS_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/notifications`;

export const GTIN_REFERENCE_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/gtinReference`;

export const REPEATING_COUNTS_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/repeatingCounts`;

export const EMAILS_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/emails`;
export const PRODUCT_TRIGGERS_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/productTriggers`;
export const USAGE_TRIGGERS_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/usageTriggers`;
export const RESUPPLY_TRIGGERS_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/resupplyTriggers`;
export const OVERDUE_SHIPPING_TRIGGERS_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/overdueShippingTriggers`;

export const FLOWS_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/flows`;
export const FLOW_STEPS_COLLECTION = (tenantId, flowId) => `${TENANTS_COLLECTION}/${tenantId}/flows/${flowId}/steps`;

export const FORMS_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/forms`;
export const FORM_STEPS_COLLECTION = (tenantId, formId) => `${TENANTS_COLLECTION}/${tenantId}/forms/${formId}/steps`;
export const CUSTOMER_FORMS_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/customerForms`;

export const ITEMS_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/items`;

export const TERRITORIES_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/territories`;

export const TAGS_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/tags`;

export const KIT_PREFERENCES_COLLECTION = (tenantId) => `${TENANTS_COLLECTION}/${tenantId}/kitPreferences`;
export const KIT_PREFERENCE_ITEMS_COLLECTION = (tenantId, preferenceId) => `${TENANTS_COLLECTION}/${tenantId}/kitPreferences/${preferenceId}/items`;


