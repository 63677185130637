import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function HospitalIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M0.75 7.70642L6.24259 1.33502C6.64151 0.87227 7.35849 0.872269 7.75741 1.33502L13.25 7.70642" />
    <path stroke={color || defaultColor} fill="none" strokeLinejoin="round"
          d="M2.67309 5.49747L6.24121 1.34047C6.64026 0.875551 7.35977 0.87555 7.75882 1.34047L11.3269 5.49747L11.5873 7.76141C11.7336 9.03293 11.709 10.3183 11.5142 11.5833V11.5833C11.4065 12.2829 10.8045 12.7993 10.0966 12.7993H3.90341C3.19557 12.7993 2.59357 12.2829 2.48584 11.5833V11.5833C2.29104 10.3183 2.26644 9.03293 2.41269 7.76141L2.67309 5.49747Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M5.5 8H8.5" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M7 6.5V9.5" />
  </SvgIcon>
}
