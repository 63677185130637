import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function CompanyIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M1.75265 2.42268C1.22125 5.39955 1.08188 9.65375 1.38588 12.4119C1.43944 12.8978 1.8583 13.25 2.34714 13.25H11.6529C12.1417 13.25 12.5606 12.8978 12.6141 12.4119C12.9182 9.65375 12.7788 5.39955 12.2474 2.42268C12.1176 1.69555 11.5503 1.14515 10.819 1.04162C9.78364 0.895044 8.31931 0.75 7.00002 0.75C5.68073 0.75 4.21639 0.895044 3.18108 1.04162C2.44975 1.14515 1.88244 1.69555 1.75265 2.42268Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M8.5 7.5H9.5" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M5.5 11.5V13.25H8.5V11.5C8.5 10.6716 7.82843 10 7 10C6.17157 10 5.5 10.6716 5.5 11.5Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M4.5 4.5H5.5" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M8.5 4.5H9.5" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M4.5 7.5H5.5" />
  </SvgIcon>
}
