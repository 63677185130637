import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import WarningIcon from '@material-ui/icons/Error';
import KitsIcon from '../../../assets/icons/KitIcon';

import CircularProgress from '@material-ui/core/CircularProgress';

import Modal from '../../shared/modal';
import Checkbox from '../../shared/Checkbox';
import Button from '../../shared/Button';
import Input from '../../shared/Input';

import { setsUtils } from '../../../utils';

import {
  setAllocationStatuses,
  setPositionOptions,
  kitTypeOptions,
  kitVariantTypes,
  consumableStatuses,
} from '../../../constants/enums';
import { v4 as uuid } from 'uuid';

const CaseSetsModal = (props) => {
  const {
    open,
    onUnassignClick,
    onClose,
    sets = [],
    selected = [],
    onSubmit,
    loading,
    kitId,
    kits,
    status,
    initialConsumables,
    datePassed,
    quantity,
    labels = [],
    kitVariant,
    caseHospital,
  } = props;

  const itemsLoaded = useSelector((state) => state.items.isLoaded);

  const [setIds, onChangeIds] = useState(selected || []);
  const [enabled, setEnabled] = useState(true);
  const [search, setSearch] = useState('');
  const [consumables, setConsumables] = useState(initialConsumables || []);

  useEffect(() => {
    onChangeIds(open ? (selected || []) : []);
  }, [open]);

  useEffect(() => {
    if (initialConsumables && initialConsumables.length) {
      setConsumables(initialConsumables);
    } else {
      const arr = Array(quantity).fill({
        label: '',
        status: consumableStatuses.RETURNED.value,
      });
      setConsumables(arr?.map((item) => ({ ...item, id: uuid() })));
    }
  }, [open, initialConsumables, quantity]);

  useEffect(() => {
    const initial = initialConsumables && initialConsumables.length ? initialConsumables?.filter((item) => !!item) : [];
    setEnabled(status !== setAllocationStatuses.DISABLED.value && (!initial || !initial.length));
  }, [status, initialConsumables]);

  useEffect(() => {
    if (kitId && kits) {
      const selectedKit = kits.find((item) => item.id === kitId);
      if (selectedKit && selectedKit.type === kitTypeOptions.CONSUMABLES.value && !selected.length) {
        setEnabled(false);
      }
    }
  }, [kitId, kits, open, selected]);

  const handleClick = (userId) => {
    const temp = setIds.slice();
    if (temp.includes(userId)) {
      const index = temp.indexOf(userId);

      temp.splice(index, 1);
      onChangeIds(temp);
      return;
    }

    temp.push(userId);
    onChangeIds(temp);
  };

  const handleSubmit = () => {
    const status = enabled ? setAllocationStatuses.ASSIGNED.value : setAllocationStatuses.DISABLED.value;
    onSubmit(status, setIds, consumables.filter((c) => !!c?.label));
    handleClose();
  };

  const handleClose = () => {
    onChangeIds([]);
    setEnabled(true);
    setSearch('');
    onClose();
  };

  const onChangeConsumable = (id, value) => {
    const temp = [...consumables];
    const updatedConsumable = temp?.find((c) => c.id === id);
    if (updatedConsumable) {
      updatedConsumable.label = value;
      setConsumables(temp);
    }
  };

  const getLabelNames = (labelIds = []) => (
    labelIds.map((id) => {
      const label = labels.find((item) => item.id === id);
      return label ? label.name : '';
    })?.join(', ')
  );

  const kit = useMemo(() => {
    const allocationKit = kits.find((item) => item.id === kitId);
    return allocationKit || {};
  }, [kitId, kits]);

  const list = useMemo(() => {
    const _sets = kitVariant === kitVariantTypes.consignment ? (
      sets?.filter((set) => !!set.consigned && set?.consignment?.hospital === caseHospital)
    ) : sets;
    return _sets?.filter((set) => set?.number?.toLowerCase().includes(search?.toLowerCase()));
  }, [search, sets, kitVariant]);

  const getLabel = useCallback((set) => {
    if (set?.targetTurns && kit?.target?.value && set?.targetTurns >= kit?.target?.value) {
      return <span className='font-bold' style={{ color: setPositionOptions.QUARANTINED.color }}>Turn Limit</span>;
    }

    return setsUtils.getPositionLabel(set.status);
  }, [kit]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title='Set Allocation'
      fullWidth
    >
      <div className='case-sets-modal__container'>
        <div className='d-flex m-b-lg'>
          <KitsIcon fontSize='small' />
          <span className='font-bold m-l-md'>{kit.name}</span>
          <span className='secondary m-l-md font-bold'>{kit.kitId}</span>
        </div>

        {datePassed && (
          <div className='d-flex align-start m-b-lg'>
            <WarningIcon style={{ color: setAllocationStatuses.TRANSFERRED.color, marginRight: 4 }} />
            <div>
              <div className='font-bold' style={{ color: setAllocationStatuses.TRANSFERRED.color }}>
                Update case to future date to assign sets!
              </div>
            </div>
          </div>
        )}

        <Input
          type='switch'
          name='enabled'
          value={enabled}
          onChange={(value) => {
            setEnabled(value);
            onChangeIds([]);
          }}
          placeholder='Assign sets'
        />

        {!enabled && (
          <div className='m-b-sm'>
            <div className='field__title m-b-md'>Consumables</div>
            {consumables?.map((consumable, index) => (
              <Input
                key={consumable?.id || index}
                value={consumable.label}
                type='text'
                onChange={(e) => onChangeConsumable(consumable?.id || index, e.target.value)}
                placeholder={`${index + 1}. Add LOT or Serial No.`}
              />
            ))}
          </div>
        )}

        {enabled && (
          <>
            <Input
              type='search'
              placeholder='Search'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />

            <div className='m-b-md secondary'>Sets</div>
            <div className='m-b-lg form__select-users'>
              {itemsLoaded ? (
                list.map((set) => (
                  <div className='md-user-info' key={set.id}>
                    <Checkbox
                      input={{
                        onClick: () => handleClick(set.id),
                        checked: setIds.indexOf(set.id) !== -1
                      }}
                      fontSize='small'
                      label={`${set.number}${set.labels && !!set.labels.length ? ` (${getLabelNames(set.labels)})`: ''}`}
                      disabled={
                        (kitVariant !== kitVariantTypes.consignment && set.caseAllocation) || !enabled
                        || (!selected?.includes(set.id) && datePassed) || set.quarantined
                        || (set.consigned && kitVariant !== kitVariantTypes.consignment)
                        || (set?.targetTurns && kit?.target?.value && set?.targetTurns >= kit?.target?.value)
                        || selected?.length
                      }
                    />
                    <div className='font-size-sm'>
                      {getLabel(set)}
                    </div>
                  </div>
                ))
              ) : (
                <div className='d-flex flex-center p-t-lg p-b-lg'>
                  <CircularProgress size={48} />
                </div>
              )}
            </div>
          </>
        )}

        <div className='form__actions p-t-lg'>
          <Button
            type='submit'
            text='Confirm'
            onClick={handleSubmit}
            disabled={loading || (!enabled && consumables?.some((c) => !c?.label)) || setIds?.length > quantity}
            loading={loading}
          />

          <Button
            type='outlined'
            text='Unassign'
            onClick={onUnassignClick}
            disabled={loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CaseSetsModal;
