import React, { useState } from 'react';

import ProformaAddressForm from './ProformaAddressForm';
import Modal from '../../shared/modal/Modal';
import Tabs from '../../shared/Tabs';

const BillingAddressModal = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    loading,
    deliveryAddress,
    billingAddress,
  } = props;

  const [activeTab, setActiveTab] = useState(0);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Update Address"
    >
      <div className='hospital-addresses__modal' style={{ paddingLeft: '24px', paddingRight: '24px', paddingBottom: '24px' }}>
        <div className='form__body'>

          <Tabs
            tabs={[{ label: 'Billing Address' }, { label: 'Shipping Address' }]}
            activeTab={activeTab}
            onTabChange={setActiveTab}
          >
            <ProformaAddressForm
              initialValues={activeTab === 0 ? billingAddress : deliveryAddress}
              onSubmit={(address) => onSubmit(address, activeTab)}
              loading={loading}
              open={open}
            />
          </Tabs>
        </div>
      </div>
    </Modal>
  );
};

export default BillingAddressModal;
