import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function DashboardIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path
      stroke={color || defaultColor}
      fill="none"
      d="M0.891052 6.40213C0.952687 7.24929 1.41604 7.93066 1.99185 8.02505C2.41955 8.09515 2.85869 8.15536 3.30641 8.15536C3.75414 8.15536 4.19327 8.09515 4.62097 8.02505C5.19679 7.93066 5.66013 7.24929 5.72177 6.40213C5.76727 5.77674 5.80569 5.13467 5.80569 4.48009C5.80569 3.8255 5.76727 3.18344 5.72177 2.55805C5.66013 1.71088 5.19679 1.02951 4.62097 0.935125C4.19327 0.865021 3.75414 0.80481 3.30641 0.80481C2.85869 0.80481 2.41955 0.865021 1.99185 0.935125C1.41604 1.02951 0.952687 1.71088 0.891051 2.55805C0.845551 3.18344 0.807129 3.8255 0.807129 4.48009C0.807129 5.13467 0.845551 5.77674 0.891052 6.40213Z" />
    <path
      stroke={color || defaultColor}
      fill="none"
      d="M13.1307 7.63841C13.069 6.79179 12.6046 6.11086 12.0275 6.01653C11.5988 5.94647 11.1587 5.8863 10.71 5.8863C10.2613 5.8863 9.82114 5.94647 9.39249 6.01653C8.81539 6.11086 8.35101 6.79179 8.28924 7.63841C8.24364 8.2634 8.20513 8.90505 8.20513 9.55921C8.20513 10.2134 8.24364 10.855 8.28924 11.48C8.35101 12.3266 8.81539 13.0076 9.39249 13.1019C9.82114 13.1719 10.2613 13.2321 10.71 13.2321C11.1587 13.2321 11.5988 13.1719 12.0275 13.1019C12.6046 13.0076 13.069 12.3266 13.1307 11.48C13.1763 10.855 13.2148 10.2134 13.2148 9.55921C13.2148 8.90505 13.1763 8.2634 13.1307 7.63841Z" />
    <path
      stroke={color || defaultColor}
      fill="none"
      d="M0.891052 12.5986C0.952687 12.9047 1.41604 13.1509 1.99185 13.185C2.41955 13.2104 2.85869 13.2321 3.30641 13.2321C3.75414 13.2321 4.19327 13.2104 4.62097 13.185C5.19679 13.1509 5.66013 12.9047 5.72177 12.5986C5.76727 12.3726 5.80569 12.1405 5.80569 11.904C5.80569 11.6674 5.76727 11.4354 5.72177 11.2094C5.66013 10.9033 5.19679 10.6571 4.62097 10.623C4.19327 10.5976 3.75414 10.5759 3.30641 10.5759C2.85869 10.5759 2.41955 10.5976 1.99185 10.623C1.41604 10.6571 0.952687 10.9033 0.891051 11.2094C0.845551 11.4354 0.807129 11.6674 0.807129 11.904C0.807129 12.1405 0.845551 12.3726 0.891052 12.5986Z" />
    <path
      stroke={color || defaultColor}
      fill="none"
      d="M13.1307 1.43837C13.069 1.13223 12.6046 0.886009 12.0275 0.851902C11.5988 0.826568 11.1587 0.80481 10.71 0.80481C10.2613 0.80481 9.82114 0.826568 9.39249 0.851901C8.81539 0.886008 8.35101 1.13223 8.28924 1.43837C8.24364 1.66437 8.20513 1.89639 8.20513 2.13293C8.20513 2.36948 8.24364 2.6015 8.28924 2.8275C8.35101 3.13363 8.81539 3.37986 9.39249 3.41397C9.82114 3.4393 10.2613 3.46106 10.71 3.46106C11.1587 3.46106 11.5988 3.4393 12.0275 3.41397C12.6046 3.37986 13.069 3.13363 13.1307 2.8275C13.1763 2.6015 13.2148 2.36948 13.2148 2.13293C13.2148 1.89639 13.1763 1.66437 13.1307 1.43837Z" />
  </SvgIcon>
}
