import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function ReturnIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M2.75011 12.3035H2.42127C1.73297 12.3035 1.13682 11.8353 1.0334 11.1548C0.809404 9.68095 0.577924 6.99221 1.25011 4.30348C1.25011 4.30348 2.25011 1.80348 4.25011 0.80348L9.75011 0.803467C11.7501 1.80347 12.7501 4.30347 12.7501 4.30347C13.4223 6.99221 13.1908 9.68094 12.9668 11.1548C12.8634 11.8353 12.2672 12.3035 11.5789 12.3035H11.2501" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M1.25 4.30347H12.75" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M7 0.803467L7 4.30347" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M7 13.2094L7 7.30347" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M9.05249 9.08472C8.42879 8.28794 8.00399 7.91715 7.37091 7.43221C7.1469 7.26062 6.85303 7.26062 6.62902 7.43221C5.99594 7.91715 5.57114 8.28794 4.94744 9.08472" />
  </SvgIcon>
}
