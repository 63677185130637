export const TENANT_FILES_REF = (tenantId) => `${tenantId}/tenantFiles`;

export const USER_FILES_REF = (userId) => `users/${userId}`;

export const TENANT_DRIVE_REF = (tenantId) => `${tenantId}/root`;

export const CASE_USAGE_IMAGES_REF = (tenantId, caseId) => `${tenantId}/cases/${caseId}/usage/images`;
export const CASE_DOCUMENTS_REF = (tenantId, caseId) => `${tenantId}/cases/${caseId}/documents`;
export const CASE_FORMS_REF = (tenantId, caseId, formId) => `${tenantId}/cases/${caseId}/forms/${formId}`;

export const ITEM_IMAGES = (tenantId, itemId) => `${tenantId}/items/${itemId}`;

export const KITS_REF = (tenantId, kitId) => `${tenantId}/kits/${kitId}`
export const BILL_OF_MATERIAL_REF = (tenantId, kitId, sectionId) => `${tenantId}/kits/${kitId}/${sectionId}`;

export const SURGEON_PREFERENCES_REF = (tenantId, surgeonId) => `${tenantId}/surgeons/${surgeonId}/preferences/images`;

export const COUNT_SCANS_IMAGES_REF = (tenantId, countId) => `${tenantId}/counts/${countId}/scans/images`;

export const SET_DOCUMENTS_REF = (tenantId, setId) => `${tenantId}/sets/${setId}/documents`;

export const FORM_LOGO_REF = (tenantId, formId) => `${tenantId}/forms/${formId}`;

export const CUSTOMER_FORMS_REF = (tenantId, formId) => `${tenantId}/customerForms/${formId}`;
