import React from 'react';
import { CSVLink } from 'react-csv';

import DeleteIcon from '../../assets/icons/DeleteIcon';
import ExportIcon from '../../assets/icons/DownloadIcon';
import UploadIcon from '../../assets/icons/UploadIcon';

import Button from '../shared/Button';
import Select from '../shared/Select';

const SurgeonsFilters = (props) => {
  const {
    onAddSurgeonClick,
    status,
    setStatus,
    statuses,
    onDelete,
    onUploadClick,
    deletePermission,
    onExportClick,
    exportData,
    loading,
  } = props;

  return (
    <div className='settings-cmp__filters--main'>
      <div className='settings-cmp__actions'>
        <Button
          text='Add Surgeon'
          type='submit'
          onClick={onAddSurgeonClick}
        />

        <Button
          type='submit'
          width='60px'
          onClick={onUploadClick}
        >
          <UploadIcon color="#ffffff" />
        </Button>


        <Button
          type='icon'
          onClick={onDelete}
          disabled={!deletePermission}
        >
          <DeleteIcon style={{ color: '#000000' }}/>
        </Button>

          <Button
            type='icon'
            onClick={onExportClick}
            disabled={loading}
            loading={loading}
          >
            <ExportIcon  />
          </Button>

        {/*<CSVLink data={exportData || []} filename='exported-surgeons-csv'>*/}
        {/*  <Button*/}
        {/*    type='icon'*/}
        {/*    onClick={() => {}}*/}
        {/*  >*/}
        {/*    <ExportIcon  />*/}
        {/*  </Button>*/}
        {/*</CSVLink>*/}
      </div>

      <div className='settings-cmp__filters'>
        <Select
          value={status}
          onChange={setStatus}
          options={statuses}
          defaultLabel={`All Status (${statuses.length})`}
          width={200}
        />
      </div>
    </div>
  )
};

export default SurgeonsFilters;
