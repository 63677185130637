import React from 'react';

import Button from '../shared/Button';
import Select from '../shared/Select';

import './users.scss';

const getColor = (length, max) => {
  if (!length || !max) {
    return '#00AEEF';
  }

  const value = length / max;
  if (value > 0 && value < 0.6) {
    return '#81B214';
  }
  if (value >= 0.6 && value < 0.8) {
    return '#F58634';
  }
  if (value >= 0.8) {
    return '#F53434';
  }

  return '#00AEEF'
};

const UsersFilters = (props) => {
  const { onAddUserClick, role, setRole, roles, status, setStatus, statuses, users, maxUsers } = props;

  return (
    <div className='settings-cmp__filters--main'>
      <div className='settings-cmp__actions'>
        <Button
          text='Add User'
          type='submit'
          onClick={onAddUserClick}
          disabled={users >= maxUsers}
        />
        <div className='users-page__max-users' style={{ borderColor: getColor(users, maxUsers) }}>
          <div style={{ color: getColor(users, maxUsers) }}>
            {`${users}/${maxUsers}`}
          </div>
          <div style={{ color: getColor(users, maxUsers) }}>
            Active Users
          </div>
        </div>
      </div>
      <div className='settings-cmp__filters'>
        <Select
          value={role}
          onChange={setRole}
          options={roles}
          defaultLabel={`All Roles (${roles.length})`}
          width={200}
        />

        <Select
          value={status}
          onChange={setStatus}
          options={statuses}
          defaultLabel={`All Status (${statuses.length})`}
          width={200}
        />
      </div>
    </div>
  )
};

export default UsersFilters;
