import React from 'react';

import { UsageImagesForm } from './form';
import Modal from '../../shared/modal';

const UpdateImageModal = ({ open, onClose, onSubmit, loading, usage, onDelete, title = 'Update Usage' }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      fullWidth
    >
      <div className='case-usage-modal__container'>
        <UsageImagesForm
          initialValues={usage}
          onSubmit={onSubmit}
          loading={loading}
          onClose={onClose}
          mode='update'
          onDelete={() => {
            onDelete(usage.id);
            onClose();
          }}
        />
      </div>
    </Modal>
  );
};

export default UpdateImageModal;
