import React, { useState, useCallback, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import moment from 'moment';

import Modal from '../../shared/modal';
import Button from '../../shared/Button';
import Input from '../../shared/Input';
import Checkbox from '../../shared/Checkbox';
import DatePicker from '../../shared/date-picker/DatePicker';

import { getCommaSeparatedNames } from '../../../utils/table';

import { kitVariantTypes } from '../../../constants/enums';
import NumberFormat from 'react-number-format';

const ShipSetsModal = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    loading,
    availableSets,
    checkedSets,
    sets,
    kits = [],
    items = [],
    kitVariant,
  } = props;

  const [checked, setChecked] = useState([]);

  useEffect(() => {
    if (!!open) {
      setChecked(checkedSets);
    }
  }, [open, checkedSets]);

  const validate = (values) => {
    const errors = {};

    if (!values.trackingNumber && kitVariant === kitVariantTypes.loan) {
      errors.trackingNumber = 'Required';
    }

    return errors;
  };

  const handleClick = (id) => {
    const temp = checked.slice();
    if (temp.includes(id)) {
      const index = temp.indexOf(id);

      temp.splice(index, 1);
      setChecked(temp);
      return;
    }

    temp.push(id);
    setChecked(temp);
  };

  const checkAll = (e) => {
    if (e.target.checked) {
      setChecked(availableSets.map((allocation) => allocation.id));
    } else {
      setChecked([]);
    }
  };

  const getKitId = useCallback((id) => {
    const kit = kits.find((item) => item.id === id);
    return kit ? kit.kitId : '';
  }, [availableSets, kits]);

  const getItemCode = useCallback((id) => {
    const item = items.find((i) => i.id === id);
    return item ? item.code : '';
  }, [availableSets, items]);

  const getSetNumbers = useCallback((ids) => {
    return getCommaSeparatedNames(sets.map((item) => ({ ...item, name: item.number })), ids);
  }, [availableSets, sets]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={kitVariant === kitVariantTypes.consignment ? 'Confirm Sets' : 'Ship Sets'}
      fullWidth
    >
      <div className='case-sets-modal__container'>
        <Form
          onSubmit={(formObj) => onSubmit(formObj, checked)}
          validate={validate}
          subscription={{ invalid: true }}
        >
          {
            ({ handleSubmit, submitting, invalid, pristine }) => (
              <form onSubmit={handleSubmit} className='form__body'>
                <div className='m-b-lg'>
                  <div className='field__title'>
                    {kitVariant === kitVariantTypes.consignment ? 'Confirmation Date' : 'Shipping Date'}
                  </div>
                  <Field name='shippingDate'>
                    {({ input, meta }) => (
                      <DatePicker
                        name={input.name}
                        date={input.value || moment()}
                        onDateChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>

                <div className=''>
                  <div className='field__title'>
                    {kitVariant === kitVariantTypes.consignment ? 'Confirmation Reference' : 'Shipping Reference'}
                  </div>
                  <Field name='trackingNumber'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        placeholder={kitVariant === kitVariantTypes.consignment ? 'Add reference number' : 'Add shipping reference number'}
                        type='text'
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>

                <Field name='freightCost'>
                  {({ input, meta }) => (
                    <>
                      <div className='secondary m-b-sm'>Freight Cost</div>
                      <NumberFormat
                        value={input.value}
                        onValueChange={(v) => input.onChange(v.floatValue)}
                        thousandSeparator={true}
                        prefix={'$'}
                        customInput={Input}
                        fixedDecimalScale={2}
                        decimalScale={2}
                        placeholder="Add freight cost of shipment"
                      />
                    </>
                  )}
                </Field>

                <div className='m-b-sm'>
                  <div className='field__title'>
                    {kitVariant === kitVariantTypes.consignment ? 'Note' : 'Shipping Note'}
                  </div>
                  <Field name='shippingNote'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        type='textarea'
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>

                <div className='m-b-lg form__select-users'>
                  {availableSets.length ? (
                    <div>
                      <div className='d-flex m-b-sm' style={{ borderBottom: '1px solid #eee' }}>
                        <Checkbox
                          input={{
                            onClick: checkAll,
                            checked: checked.length === availableSets.length
                          }}
                          fontSize='small'
                        />
                        <div className='d-flex flex-1 space-between'>
                          <div className='font-bold secondary uppercase'>
                            Kit id / Item code
                          </div>
                          <div className='font-bold secondary uppercase'>
                            Set #
                          </div>
                        </div>
                      </div>
                      {availableSets.map((allocation) => (
                        <div className='md-user-info' key={allocation.id}>
                          <Checkbox
                            input={{
                              onClick: () => handleClick(allocation.id),
                              checked: checked.indexOf(allocation.id) !== -1
                            }}
                            fontSize='small'
                            label={allocation.itemId ? getItemCode(allocation.itemId) : getKitId(allocation.kit)}
                          />
                          <span>{getSetNumbers(allocation.sets)}</span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className='text-center secondary p-md'>
                      No assigned sets
                    </div>
                  )}
                </div>

                <div className='form__actions'>
                  <Button
                    type='submit'
                    text={kitVariant === kitVariantTypes.consignment ? 'Confirm' : 'Ship'}
                    disabled={invalid || loading || pristine || !checked.length}
                    loading={loading}
                    onClick={handleSubmit}
                  />
                </div>
              </form>
            )}
        </Form>
      </div>
    </Modal>
  );
};

export default ShipSetsModal;
