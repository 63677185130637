import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';

import Button from '../shared/Button';
import Input from '../shared/Input';
import Select from '../shared/Select';
import ModalSelectInput from '../shared/modal-select/ModalSelectInput';
import AddOptionsModal from './AddOptionsModal';

import { formSectionTypes, formDropdownOptions } from '../../constants/enums';

import '../../styles/shared/form.scss';

const sectionTypeOptions = Object.values(formSectionTypes);
const dropdownOptions = Object.values(formDropdownOptions);

const { CHECKBOXES, RADIO, CUSTOM } = formSectionTypes;

const FormStepForm = (props) => {
  const {
    mode = 'create',
    initialValues,
    buttonText,
    onSubmit,
    loading,
    onClose,
  } = props;

  const { title } = initialValues || {};

  const [isModalOpen, toggleModal] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = 'Required';
    }

    if (values.type !== formSectionTypes.DESCRIPTION.value && !values.subtitle) {
      errors.subtitle = 'Required';
    }

    if (!values.type) {
      errors.type = 'Required';
    }

    if (values.type === formSectionTypes.DROPDOWN.value && !values.dropdownValues) {
      errors.dropdownValues = 'Required';
    }

    return errors;
  };

  return (
    <div className='form__container'>
      <Form
        onSubmit={(formObj) => onSubmit(formObj, mode)}
        validate={validate}
        initialValues={{
          ...initialValues,
          options: initialValues?.options || []
        }}
        subscription={{ pristine: true, invalid: true, values: true }}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine, values }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <div className='form__subtitle'>
                {title ? title : 'Add Field'}
              </div>
              {mode === 'update' && (
                <Field name='active'>
                  {({ input, meta }) => (
                    <Input
                      type='switch'
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      placeholder='Status'
                    />
                  )}
                </Field>
              )}

              <div className='field__title'>Display Name</div>
              <div className='combined-fields__container'>
                <Field name='title'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      borderless
                      type='text'
                      onChange={input.onChange}
                      startAdornment='Title'
                    />
                  )}
                </Field>
                {values?.type !== formSectionTypes.DESCRIPTION.value && (
                  <Field name='subtitle'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        borderless
                        type='text'
                        onChange={input.onChange}
                        startAdornment='Sub'
                      />
                    )}
                  </Field>
                )}
              </div>

              <div className='m-b-sm'>
                <div className='m-b-md'>
                  <div className='field__title'>Field Type</div>
                  <Field name='type'>
                    {({ input, meta }) => (
                      <Select
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                        options={sectionTypeOptions}
                      />
                    )}
                  </Field>
                </div>

                {values.type === formSectionTypes.DROPDOWN?.value && (
                  <div className='m-t-lg'>
                    <Field name='isMulti'>
                      {({ input, meta }) => (
                        <Input
                          type='switch'
                          name={input.name}
                          value={input.value}
                          onChange={input.onChange}
                          placeholder='Multi-select'
                        />
                      )}
                    </Field>
                  </div>
                )}

                {values.type === formSectionTypes.DROPDOWN?.value && (
                  <div className='m-b-md'>
                    <div className='field__title'>Values</div>
                    <Field name='dropdownValues'>
                      {({ input, meta }) => (
                        <Select
                          name={input.name}
                          value={input.value}
                          onChange={input.onChange}
                          options={dropdownOptions}
                        />
                      )}
                    </Field>
                  </div>
                )}

                {mode === 'update' && [CHECKBOXES.value, RADIO.value, CUSTOM.value].includes(values.type) && (
                  <Field name='options'>
                    {({ input, meta }) => (
                      <div className='m-b-lg'>
                        <div className='field__title'>Options</div>
                        <ModalSelectInput
                          onClick={() => toggleModal(true)}
                          count={input.value.length}
                          label='Edit Options'
                          values={input.value || []}
                          icon='option'
                        />
                        <AddOptionsModal
                          onChange={input.onChange}
                          initialValues={input.value}
                          onClose={() => toggleModal(false)}
                          isOpen={isModalOpen}
                        />
                      </div>
                    )}
                  </Field>
                )}

                {values.type === formSectionTypes.DESCRIPTION?.value && (
                  <div className='m-t-lg'>
                    <Field name='description'>
                      {({ input, meta }) => (
                        <Input
                          name={input.name}
                          value={input.value}
                          placeholder='Type description here'
                          type='textarea'
                          onChange={input.onChange}
                        />
                      )}
                    </Field>
                  </div>
                )}

                {values.type !== formSectionTypes.DESCRIPTION?.value && (
                  <div className='m-t-lg'>
                    <Field name='isRequired'>
                      {({ input, meta }) => (
                        <Input
                          type='switch'
                          name={input.name}
                          value={input.value}
                          onChange={input.onChange}
                          placeholder='Mandatory Input'
                        />
                      )}
                    </Field>
                  </div>
                )}
              </div>

              <div className='form__actions'>
                <Button
                  type='submit'
                  text={buttonText || 'Save Changes'}
                  disabled={invalid || loading || pristine}
                  loading={loading}
                  onClick={handleSubmit}
                />
                {mode === 'create' && (
                  <Button
                    type='cancel'
                    onClick={onClose}
                  />
                )}
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default FormStepForm;
