import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';

import UndoIcon from '@material-ui/icons/Undo';

import Modal from '../../shared/modal';
import Input from '../../shared/Input';
import Button from '../../shared/Button';
import userRoles from '../../../constants/userRoles';

const ProformaModal = ({
  open,
  onClose,
  onSubmit,
  orderClosed,
  onRevert,
  loading,
  mode = 'create',
  initialValue = 0,
  initialFreightCost = 0,
  initialReferenceNumber = '',
  totalValue = 0,
  totalFreightCost = 0,
  userRole
}) => {
  const [value, setValue] = useState(0);
  const [freightCost, setFreightCost] = useState(0);
  const [referenceNumber, setReferenceNumber] = useState('');

  useEffect(() => {
    if (mode === 'create' && open) {
      setValue(totalValue);
      setFreightCost(totalFreightCost);
    }
    if (mode === 'update' && open) {
      setValue(initialValue);
      setFreightCost(initialFreightCost);
      setReferenceNumber(initialReferenceNumber);
    }
  }, [open, mode, initialValue, totalValue]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Proforma"
      fullWidth
    >
      <div className="close-order-modal__container">
        <div className="p-b-md">
          Provide a total value for this invoice and we'll use it to report on your billing and calculate outstanding
          payments
        </div>

        <div className="m-t-lg">
          <div className="secondary m-b-md">Reference Number (optional)</div>
          <Input
            value={referenceNumber}
            onChange={(e) => setReferenceNumber(e.target.value)}
            type="text"
          />
          <div className="secondary m-b-md">Total Proforma Value</div>
          <NumberFormat
            value={value}
            onValueChange={(v) => setValue(v.floatValue)}
            thousandSeparator={true}
            prefix={'$'}
            customInput={Input}
            fixedDecimalScale={2}
            decimalScale={2}
          />
          <div className="secondary m-b-sm">Freight Costs</div>
          <NumberFormat
            value={freightCost}
            onValueChange={(v) => setFreightCost(v.floatValue)}
            thousandSeparator={true}
            prefix={'$'}
            customInput={Input}
            fixedDecimalScale={2}
            decimalScale={2}
            placeholder="Add freight cost of shipment"
          />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '24px', width: '100%' }}>
        <Button type="cancel" text="Cancel" onClick={onClose} />
        <div className="d-flex">
          {mode === 'update' && [userRoles.ADMIN.name, userRoles.CUSTOMER_SERVICE.name, userRoles.OPERATIONS.name, userRoles.LOGISTICS.name, userRoles.SALES_MANAGER.name].includes(userRole) && (
            <div className="m-r-lg">
              <Button
                type="icon"
                onClick={onRevert}
              >
                <UndoIcon color='action' />
              </Button>
            </div>
          )}
          <Button
            text={mode === 'update' ? 'Save Changes' : 'Confirm Sent'}
            onClick={() => onSubmit(value, freightCost, referenceNumber)}
            disabled={loading}
            loading={loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ProformaModal;
