import React, { useMemo } from 'react';
import { Field, Form } from 'react-final-form';

import Button from '../../shared/Button';
import Input from '../../shared/Input';
import Select from '../../shared/Select';
import DatePicker from '../../shared/date-picker/DatePicker';

import '../../../styles/shared/form.scss';

const TaskForm = (props) => {
  const {
    onSubmit = () => {},
    loading,
    onClose,
    initialValues = {},
    surgeons = [],
    hospitals = [],
    mode = 'create',
    onComplete,
  } = props;

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = 'Required';
    }

    return errors;
  };

  const surgeonOptions = useMemo(() => {
    return [
      { label: 'None', value: '' },
      ...surgeons.map((surgeon) => ({ value: surgeon.id, label: `${surgeon.title} ${surgeon.firstName} ${surgeon.lastName}` }))
    ]
  }, []);

  const hospitalOptions = useMemo(() => {
    return [
      { label: 'None', value: '' },
      ...hospitals.map((hospital) => ({ value: hospital.id, label: hospital.name }))
    ]
  }, []);

  return (
    <div className='form__container'>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        subscription={{ pristine: true, invalid: true }}
        initialValues={initialValues}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <div className='form__subtitle'>
                {initialValues && initialValues.id ? 'Edit Task' : 'Add Task'}
              </div>

              <div>
                <div className='field__title'>Title</div>
                <Field name='title'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              <div>
                <div className='field__title'>Description</div>
                <Field name='description'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      type='text'
                      onChange={input.onChange}
                      multiline
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-lg'>
                <div className='field__title'>Due Date</div>
                <Field name='dueDate'>
                  {({ input, meta }) => (
                    <DatePicker
                      name={input.name}
                      date={input.value}
                      onDateChange={input.onChange}
                      disablePast
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-lg'>
                <div className='field__title'>Tag Surgeon</div>
                <Field name='surgeonId'>
                  {({ input, meta }) => (
                    <Select
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      options={surgeonOptions}
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-lg'>
                <div className='field__title'>Tag Hospital</div>
                <Field name='hospitalId'>
                  {({ input, meta }) => (
                    <Select
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      options={hospitalOptions}
                    />
                  )}
                </Field>
              </div>

              <div className='form__actions'>
                <Button
                  type='submit'
                  text={initialValues && initialValues.id ? 'Save Changes' : 'Add Task'}
                  disabled={invalid || loading || pristine}
                  loading={loading}
                  onClick={handleSubmit}
                />
                {mode === 'update' ? (
                  <Button
                    type='outlined'
                    text='Complete'
                    disabled={invalid || loading}
                    loading={loading}
                    onClick={onComplete}
                  />
                ) : (
                  <Button
                    type='cancel'
                    onClick={onClose}
                  />
                )}
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default TaskForm;
