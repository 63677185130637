import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import ProceduresTable from '../../../components/procedures/ProceduresTable';
import ProceduresFilters from '../../../components/procedures/ProceduresFilters';
import ProcedureForm from '../../../components/procedures/ProcedureForm';
import Input from '../../../components/shared/Input';
import Modal, { ConfirmationModal, UploadModal } from '../../../components/shared/modal';
import Alert from '../../../components/shared/Alert';

import {
  getProcedures,
  createProcedure,
  updateProcedure,
  subscribeToProcedures,
  bulkDeleteProcedures,
  subscribeToProcedureOptions,
  uploadProcedures,
} from '../../../actions/proceduresActions';
import { getFlows } from '../../../actions/flowsActions';

import { filterProcedures } from '../../../utils/table';

import { statusOptions } from '../../../constants/enums';

const statusFilterOptions = [
  { label: 'Active', value: statusOptions.active },
  { label: 'Deactivated', value: statusOptions.deactivated }
];

const ProceduresPage = (props) => {
  const {
    procedures,
    createProcedure,
    updateProcedure,
    subscribeToProcedures,
    subscribeToProcedureOptions,
    bulkDeleteProcedures,
    uploadProcedures,
    procedureOptions,
    tenantColor,
    deletePermission,
    getFlows,
    flows
  } = props;

  const [isModalCreateOpen, toggleModalCreate] = useState(false);
  const [isModalDeleteOpen, toggleModalDelete] = useState(false);
  const [isUploadModalOpen, toggleUploadModal] = useState(false);
  const [isModalDuplicateOpen, toggleModalDuplicate] = useState(false);
  const [loading, setLoading] = useState(false);

  const [successMessage, setSuccessMessage] = useState(null);

  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [checkedProcedures, setCheckedProcedures] = useState([]);

  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [optionFilter, setOptionFilter] = useState('');

  useEffect(() => {
    const unsubscribe = subscribeToProcedures();
    const optionsUnsubscribe = subscribeToProcedureOptions();
    getFlows().catch();

    return () => {
      unsubscribe();
      optionsUnsubscribe();
    };
  }, []);

  const onSelectProcedure = (procedure) => {
    setSelectedProcedure(procedure);
  };

  // Create procedure
  const onCreateProcedure = async (formObj) => {
    setLoading(true);
    const procedureObj = {
      name: formObj.name || '',
      description: formObj.description || '',
    };

    try {
      await createProcedure(procedureObj);
      setLoading(false);
      toggleModalCreate(false);
      setSuccessMessage('Procedure has been successfully created');
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Update procedure
  const onUpdateProcedure = async (formObj) => {
    setLoading(true);
    const procedureObj = {
      active: formObj.active,
      name: formObj.name || '',
      description: formObj.description || '',
      options: formObj.options || [],
      averageValue: Number(formObj.averageValue) || 0,
      mandatoryFlow: formObj.mandatoryFlow || null,
    };

    try {
      await updateProcedure(formObj.id, procedureObj);
      setLoading(false);
      setSuccessMessage('Procedure has been successfully updated');
      setSelectedProcedure(formObj);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Delete checked procedures
  const onDeleteProcedures = async () => {
    await bulkDeleteProcedures(checkedProcedures);

    toggleModalDelete(false);
    setCheckedProcedures([]);
    setSelectedProcedure(null);
  };

  const handleDeleteClick = () => {
    if (checkedProcedures.length) {
      toggleModalDelete(true);
    }
  };

  // Upload procedures
  const onUpload = async (procedures) => {
    setLoading(true);

    try {
      await uploadProcedures(procedures);
      setLoading(false);
      setSuccessMessage('Procedures have been successfully uploaded');
      toggleUploadModal(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Duplicate procedure
  const onDuplicateClick = async () => {
    if (!checkedProcedures || checkedProcedures.length !== 1) {
      return;
    }

    const procedureId = checkedProcedures[0];
    const procedure = procedures.find((procedure) => procedure.id === procedureId);
    setSelectedProcedure(procedure);
    toggleModalDuplicate(true);
  };

  const onDuplicateProcedure = async (formObj) => {
    setLoading(true);
    const procedureObj = {
      name: formObj.name,
      description: formObj.description,
      options: formObj.options,
    };

    try {
      await createProcedure(procedureObj);
      setLoading(false);
      toggleModalDuplicate(false);
      setSuccessMessage('Procedure has been successfully duplicated');
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    // setCheckedProcedures([]);
  };

  const procedureList = useMemo(() => {
    return procedures.filter((procedure) => filterProcedures(procedure, search, statusFilter, optionFilter));
  }, [procedures, search, statusFilter, optionFilter]);

  const handleCheck = (procedureId) => {
    const checked = checkedProcedures.slice();

    if (checked.includes(procedureId)) {
      const index = checked.indexOf(procedureId);

      checked.splice(index, 1);
      setCheckedProcedures(checked);

      return;
    }

    checked.push(procedureId);
    setCheckedProcedures(checked);
  };

  const handleCheckAll = () => {
    const checked = checkedProcedures.length === procedureList.length;
    const temp = [];

    if (!checked) {
      procedureList.forEach((procedure) => {
        temp.push(procedure.id);
      });

      setCheckedProcedures(temp);
      return;
    }

    setCheckedProcedures(temp);
  };

  return (
    <div className='settings-cmp__main'>
      <span className='settings-title'>Procedures</span>
      <div className='settings-cmp__body'>
        <div className='filters-container'>
          <ProceduresFilters
            onAddProcedureClick={() => toggleModalCreate(true)}
            onDelete={handleDeleteClick}
            status={statusFilter}
            setStatus={setStatusFilter}
            statuses={statusFilterOptions}
            option={optionFilter}
            setOption={setOptionFilter}
            onUploadClick={() => toggleUploadModal(true)}
            onDuplicateClick={onDuplicateClick}
            deletePermission={deletePermission}
          />
          <ProceduresTable
            onSelectProcedure={onSelectProcedure}
            procedures={procedureList}
            selectedProcedureId={selectedProcedure ? selectedProcedure.id : null}
            handleCheck={handleCheck}
            checkedProcedures={checkedProcedures}
            handleCheckAll={handleCheckAll}
            procedureOptions={procedureOptions}
            flows={flows}
          />
        </div>
        { procedures && !!procedures.length && (
          <div className='form-container'>
            <Input
              type='search'
              placeholder='Search Procedures'
              value={search}
              onChange={handleSearch}
            />
            { selectedProcedure && (
              <ProcedureForm
                initialValues={selectedProcedure}
                buttonText='Save Changes'
                onSubmit={onUpdateProcedure}
                loading={loading}
                mode='update'
                procedureOptions={procedureOptions}
                procedures={procedures}
                flows={flows?.filter((f) => f.procedures?.includes(selectedProcedure.id))}
              />
            )}
          </div>
        )}
      </div>

      <ConfirmationModal
        open={isModalDeleteOpen}
        onClose={() => toggleModalDelete(false)}
        onSubmit={onDeleteProcedures}
        title='Are you sure you want to delete these procedures?'
        submitText='Delete'
      />

      <Modal
        open={isModalCreateOpen}
        onClose={() => toggleModalCreate(false)}
      >
        <ProcedureForm
          buttonText='Add Procedure'
          onSubmit={onCreateProcedure}
          loading={loading}
          mode='create'
          onClose={() => toggleModalCreate(false)}
          procedures={procedures}
        />
      </Modal>

      <Modal
        open={isModalDuplicateOpen}
        onClose={() => toggleModalDuplicate(false)}
      >
        <ProcedureForm
          initialValues={selectedProcedure}
          buttonText='Duplicate'
          onSubmit={onDuplicateProcedure}
          loading={loading}
          mode='create'
          onClose={() => toggleModalDuplicate(false)}
          procedures={procedures}
        />
      </Modal>

      <UploadModal
        open={isUploadModalOpen}
        onClose={() => toggleUploadModal(false)}
        title='Upload Procedures'
        tenantColor={tenantColor}
        onSubmit={onUpload}
        loading={loading}
        fields={['Name', 'Description']}
      />

      <Alert
        variant='success'
        message={successMessage}
        open={!!successMessage}
        onClose={() => setSuccessMessage(null)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    procedures: state.procedures.list,
    flows: state.flows.list,
    procedureOptions: state.procedures.procedureOptions,
    tenantColor: state.tenant.currentTenant.colorPrimary,
    deletePermission: state.user.currentUser?.deletePermission,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProcedures: () => dispatch(getProcedures()),
    getFlows: () => dispatch(getFlows()),
    createProcedure: (procedure) => dispatch(createProcedure(procedure)),
    updateProcedure: (id, procedureData) => dispatch(updateProcedure(id, procedureData)),
    subscribeToProcedures: () => dispatch(subscribeToProcedures()),
    subscribeToProcedureOptions: () => dispatch(subscribeToProcedureOptions()),
    bulkDeleteProcedures: (procedureIds) => dispatch(bulkDeleteProcedures(procedureIds)),
    uploadProcedures: (procedures) => dispatch(uploadProcedures(procedures)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProceduresPage);
