import React, { useCallback, useMemo } from 'react';
import { orderBy, sum } from 'lodash';

import BarChart from '../../../charts/BarChart';

import { formatChartPrice } from '../../../../utils/utils';

export const CustomTooltip = (props) => {
  const { data, value, hospitals } = props;
  const hospital = hospitals?.find((u) => u.id === data?.id);

  return (
    <div className="custom-tooltip">
      <div className="tooltip-label">{hospital ? hospital.name : ''}</div>
      <div className="tooltip-content">
        <div>{`$${formatChartPrice(value, 0)}`}</div>
      </div>
    </div>
  );
};

const BillingHospitalsProformaBarChart = ({ cases = [], hospitals = [] }) => {
  const data = useMemo(() => {
    const allHospitals = hospitals
      .map((hospital) => {
        const hospitalCases = cases.filter((item) => item.hospital === hospital.id);
        const value = sum(hospitalCases?.map((caseItem) => caseItem.proformaValue || 0));
        return {
          id: hospital.id,
          label: hospital.name,
          value,
        };
      })
      .filter((item) => item.value !== 0);

    const sorted = orderBy(allHospitals, 'value', 'desc');
    const top10 = sorted.splice(0, 10);

    return top10;
  }, [cases, hospitals]);

  const getHospitalName = useCallback((hospitalId) => {
    const hospital = hospitals?.find((u) => u.id === hospitalId);
    const name = hospital ? hospital.name : '';
    return name ? `${name?.substring(0, 10)}${name?.length > 10 ? '...' : ''}` : '';
  }, [hospitals]);

  return (
    <div className="management-reports-chart__container m-l-md" style={{ width: '50%' }}>
      <div className="font-size-bg font-bold">Top Hospitals by Total Value</div>
      <div className="secondary m-t-sm m-b-lg">Top 10 hospitals by proforma value for completed cases</div>
      <BarChart
        data={data || []}
        indexBy="id"
        height="300px"
        layout="horizontal"
        enableLabel={true}
        margin={{ top: 10, right: 25, bottom: 30, left: 85 }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          truncateTickAt: 0,
          format: (id) => getHospitalName(id)
        }}
        labelTextColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              1.6,
            ],
          ],
        }}
        label={(d) => `$${formatChartPrice(d.value, 0)}`}
        CustomTooltip={(props) => <CustomTooltip {...props} hospitals={hospitals} />}
      />
    </div>
  );
};

export default BillingHospitalsProformaBarChart;
