import moment from 'moment';

export const LineTooltip = ({ point, entity, monthlyCompared, ...other }) => {
  const { data: { xFormatted, yFormatted } } = point;

  return (
    <div className='custom-tooltip'>
      {monthlyCompared ? (
        <div className='tooltip-label'>{`${xFormatted} ${point.id?.includes('previousYear') ? moment().subtract(1, 'year').year() : moment().year()}`}</div>
      ) : (
        <div className='tooltip-label'>{xFormatted}</div>
      )}
      <div className='tooltip-content'>
        <div>{!!entity ? `${yFormatted} ${entity}(s)` : yFormatted}</div>
      </div>
    </div>
  );
};
