import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core';

import ArrowDropUpIcon from '@material-ui/icons/ExpandLess';
import ArrowDropDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowsIcon from '@material-ui/icons/UnfoldMore';
import FilterIcon from '@material-ui/icons/FilterList';

import FilterModal from './FilterModal';

import './table.scss';

const StyledTableCell = withStyles(theme => ({
  root: {
    backgroundColor: 'transparent',
    color: '#A7A9BD',
    borderTop: 'none',
    borderBottom: 'none',
    paddingTop: '4px',
    paddingBottom: '0px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  }
}))(TableCell);

const HeaderCell = (props) => {
  const {
    field, title, orderBy, order, onSort, sort = false,
    filterOptions = [], selectedValues = [], onFilterChange, filterTitle
  } = props;

  const [isModalOpen, toggleModal] = useState(false);

  const handleSort = () => {
    if (sort) {
      onSort(field);
    }
  };

  const renderSortIcon = () => {
    if (orderBy === field) {
      return (
        <span className='m-t-sm'>
            {order === 'desc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </span>
      );
    }
    return <span className='m-t-sm'><ArrowsIcon /></span>;
  }

  return (
    <StyledTableCell
      sortDirection={orderBy === field ? order : false}
      style={{
        cursor: sort ? 'pointer' : 'default',
        width: field === 'status' && title === '' ? '20px' : 'auto',
      }}
    >
      <div className='d-flex'>
        <div className='table-header-cell' onClick={handleSort}>
          {title}

          {sort && renderSortIcon()}
        </div>
        {filterOptions && !!filterOptions?.length && (
          <>
            <div className='filter-button'>
              <IconButton
                aria-controls='filter'
                aria-haspopup='true'
                size='small'
                color={selectedValues?.length > 0 ? 'primary' : 'secondary'}
                className='filter-button'
                onClick={() => toggleModal(true)}
              >
                <FilterIcon />
              </IconButton>
            </div>

            <FilterModal
              open={isModalOpen}
              onClose={() => toggleModal(false)}
              title={filterTitle}
              options={filterOptions}
              onSubmit={onFilterChange}
              selectedValues={selectedValues}
              field={field}
            />
          </>
        )}
      </div>
    </StyledTableCell>
  );
};

HeaderCell.propTypes = {
  title: PropTypes.string,
};

export default HeaderCell;
