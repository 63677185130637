import React, { useMemo, useCallback } from 'react';
import { orderBy } from 'lodash';

import Tooltip from '@material-ui/core/Tooltip';
import WarningIcon from '@material-ui/icons/Error';
import BatchControlIcon from '../../assets/icons/BatchControlIcon';

import Table from '../shared/table/Table';

import { caseUsageTypes, setAllocationStatuses } from '../../constants/enums';

import './counts.scss';

const { RETURNED, SHIPPED } = setAllocationStatuses;

const findValue = (usage, key) => {
  if (!usage || !usage.values) {
    return '';
  }

  const value = usage.values.find((v) => v.label === key);
  return value ? value.value : '';
};

const CountInventoryTable = (props) => {
  const {
    scans,
    onSelect,
    selected,
    gtinRefActive,
    gtinReference,
    users,
    onApproveClick,
    orderClosed,
    proformaSent,
    proformaGenerated,
    isSubmitted,
    tags = []
  } = props;

  const columns = useMemo(() => {
    const arr = [
      { title: 'qty', field: 'quantity', type: 'default', sort: true },
      { title: 'added by', field: 'counterName', type: 'default', sort: true },
      { title: 'date', field: 'createdAt', type: 'date', sort: true },
      { title: 'type', field: 'scanType', type: 'type', sort: true },
      { title: '', field: 'batchControl', type: 'custom', formatter: (value) => !!value && <BatchControlIcon color={RETURNED.color} />}
    ]

    if (onApproveClick && isSubmitted) {
      arr.push({
        title: 'Approve',
        field: 'approved',
        type: 'custom',
        formatter: (value, row) => {
          const refValue = findValue(row, 'REF');
          return !!refValue ? (
            <div
              onClick={(e) => {
                e.stopPropagation();
                if (!orderClosed && !proformaSent && !proformaGenerated) {
                  onApproveClick(row.id, !value);
                }
              }}
            >
              {value ? (
                <div style={{ color: SHIPPED.color, paddingTop: 10, paddingBottom: 10 }}>Approved</div>
              ) : (
                <div style={{ color: RETURNED.color, paddingTop: 10, paddingBottom: 10 }}>Approve</div>
              )}
            </div>
          ) : (
            <Tooltip
              title='Item code data is mandatory'
              aria-label='warning-icon'
            >
              <WarningIcon style={{color: setAllocationStatuses.ASSIGNED.color }} />
            </Tooltip>
          )
        },
      });
    }

    if (gtinRefActive) {
      return [
        { title: 'item', field: 'values', type: 'scans', sort: false, },
        { title: 'gtin ref', field: 'gtinRef', type: 'default', sort: true },
        ...arr,
      ];
    } else {
      return [
        { title: 'item', field: 'values', type: 'scans', sort: false, },
        ...arr,
      ]
    }
  }, [scans, gtinRefActive, orderClosed, isSubmitted, proformaSent, onApproveClick]);

  const findGtinReference = useCallback((scan) => {
    if (scan.type !== caseUsageTypes.implants) {
      return '';
    }

    const gtin = scan.values.find((item) => item.label.toLowerCase() === 'gtin');
    const gtinRef = gtin ? gtinReference.find((item) => item.gtin === gtin.value) : null;

    return gtinRef ? gtinRef.reference : '';
  }, [scans, gtinReference]);

  const getCounterName = useCallback((scan) => {
    const user = users.find((user) => user.uid === scan.userId);
    return user ? `${user.firstName} ${user.lastName}` : '';
  }, [scans, users]);

  return (
    <div style={{ width: '100%' }}>
      <Table
        rows={orderBy(
          scans.map((item) => ({
            ...item,
            gtinRef: findGtinReference(item),
            counterName: getCounterName(item),
          })),
          'counterName'
        )}
        columns={columns}
        onRowClick={onSelect}
        selected={selected}
        rowColor='#ffffff'
        rowBorderColor='#fafafa'
        checkboxes={false}
        pagination
        additionalData={{ tags }}
      />
    </div>
  );
};

export default CountInventoryTable;
