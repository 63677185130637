import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';

import DeleteIcon from '../../../../assets/icons/DeleteIcon';

import Button from '../../../shared/Button';
import Input from '../../../shared/Input';
import FileUpload from '../../../shared/FileUpload';
import { ConfirmationModal } from '../../../shared/modal';

import '../../../../styles/shared/form.scss';

const UsageImagesForm = (props) => {
  const {
    onSubmit = () => {},
    loading,
    onClose,
    mode = 'create',
    initialValues = {},
    onDelete,
  } = props;

  const [deleteModalOpen, toggleDeleteModal] = useState(false);
  const [image, setImage] = useState(null);

  const validate = (values) => {
    const errors = {};

    if (!values.image) {
      errors.image = 'Required';
    }

    if (!values.quantity) {
      errors.quantity = 'Required';
    }

    return errors;
  };

  const handleSubmit = (formObj) => {
    onSubmit(formObj, image);
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        validate={validate}
        subscription={{ pristine: true, invalid: true }}
        initialValues={initialValues}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              {mode === 'create' && (
                <div className='m-b-lg'>
                  <div className='field__title'>Image</div>
                  <Field name='image'>
                    {({ input, meta }) => (
                      <FileUpload
                        id='usage-image'
                        file={image}
                        onUpload={(file) => {
                          setImage(file);
                          input.onChange(file.name);
                        }}
                        placeholder={input.value}
                        accept='image'
                      />
                    )}
                  </Field>
                </div>
              )}

              <div>
                <div className='field__title'>Description</div>
                <Field name='description'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-lg'>
                <div className='field__title'>Quantity</div>
                <Field name='quantity'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      type='text'
                      onChange={input.onChange}
                      numbersOnly
                    />
                  )}
                </Field>
              </div>

              <div className='form__actions'>
                <div className='d-flex'>
                  {initialValues && Object.keys(initialValues).length > 1 && (
                    <div className='m-r-lg'>
                      <Button
                        type='icon'
                        onClick={() => toggleDeleteModal(true)}
                      >
                        <DeleteIcon color="#000000" />
                      </Button>
                    </div>
                  )}
                  <Button
                    type='submit'
                    text={mode === 'update' ? 'Update' : 'Add Image'}
                    disabled={invalid || loading || pristine}
                    loading={loading}
                    onClick={handleSubmit}
                  />
                </div>
                <Button
                  type='cancel'
                  onClick={onClose}
                />
              </div>
            </form>
          )}
      </Form>
      <ConfirmationModal
        open={!!deleteModalOpen}
        onClose={() => toggleDeleteModal(false)}
        onSubmit={() => {
          toggleDeleteModal(false);
          onDelete();
        }}
        title='Are you sure you want to delete this usage? This action cannot be reversed.'
        submitText='Delete'
      />
    </>
  );
};

export default UsageImagesForm;
