import React, { useMemo } from 'react';
import moment from 'moment/moment';

import { LineChart } from '../../../charts';

import { prepareForecastCases } from '../../../../utils/analytics';

const CustomTooltip = ({ point }) => {
  const { index, data } = point;

  const day= moment().add(index + 1, 'days');

  return (
    <div className="custom-tooltip">
      <div className="tooltip-label">{day.format('Do MMMM')}</div>
      <div className="tooltip-content">
        <div>{`${data?.y} case(s)`}</div>
      </div>
    </div>
  );
};

const ForecastingCasesLineChart = ({ cases = [], tenantColor, presetCompare }) => {
  const chartData = useMemo(() => {
    const start = moment().add(1, 'day').startOf('day');
    const end = moment().add(90, 'day').endOf('day');
    const data = prepareForecastCases(cases, false);

    if (presetCompare) {
      const startCompare = start.subtract(1, 'year');
      const endCompare = end.subtract(1, 'year');

      const comparedCases = cases.filter((item) => startCompare.isBefore(item.date) && endCompare.isAfter(item.date));
      const compareData = prepareForecastCases(comparedCases, true);

      return [...compareData?.map((item) => ({ ...item, id: `${item.id}_compare` })), ...data];
    }

    return data;
  }, [cases, presetCompare]);

  return (
    <div className="management-reports-chart__container">
      <div className="font-size-bg font-bold">Booked Cases Total</div>
      <div className="secondary m-t-sm m-b-lg">Total cases booked over the next 90 days</div>
      <LineChart
        height='300px'
        data={chartData}
        colors={chartData.length > 1 ? ['#cccccc', tenantColor] : tenantColor}
        margin={{ top: 20, right: 15, bottom: 30, left: 30 }}
        entity='case'
        CustomTooltip={CustomTooltip}
      />
    </div>
  );
};

export default ForecastingCasesLineChart;
