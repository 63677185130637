import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function ReceiptIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M11.0938 0.902008H2.88275C1.83616 0.902008 0.963976 1.7088 0.907912 2.75389C0.733854 5.99849 0.742121 9.25193 0.950491 12.4961V12.4961C0.979678 12.9505 1.53598 13.1524 1.84981 12.8225L3.13182 11.4746L4.51204 12.9257C4.90621 13.3401 5.56702 13.3401 5.9612 12.9257L7.34142 11.4746L8.62343 12.8225C8.93726 13.1524 9.49356 12.9505 9.52275 12.4961V12.4961C9.67717 10.0918 9.77506 7.21539 9.71691 4.80826"/>
    <path stroke={color || defaultColor} fill="none"
          d="M9.74451 4.62595C9.75779 5.16177 10.1978 5.58425 10.7338 5.58425L12.1283 5.58425C12.6643 5.58425 13.1043 5.16177 13.1176 4.62595C13.1304 4.10918 13.1416 3.43978 13.138 2.76279C13.1327 1.79426 12.3996 0.902008 11.4311 0.902008V0.902008V0.902008C10.4625 0.902008 9.7294 1.79426 9.72415 2.76279C9.72049 3.43978 9.7317 4.10918 9.74451 4.62595Z"/>
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M6.63509 4.84849C6.63509 4.84849 6.12097 4.27539 5.28712 4.34701C4.45326 4.41863 4.05132 4.93093 4.05132 5.44463C4.05132 6.94291 6.63509 5.90459 6.63509 7.44102C6.63509 8.23312 4.92117 8.79224 3.90002 7.86037"/>
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M5.38928 3.33951L5.38928 4.34083"/>
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M5.38928 8.3382L5.38928 9.33952"/>
  </SvgIcon>
}

