import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';

import QualificationsModal from './QualificationsModal';
import Button from '../shared/Button';
import Input from '../shared/Input';
import Select from '../shared/Select';
import ModalSelectInput from '../shared/modal-select/ModalSelectInput';
import ModalSelect from '../shared/modal-select/ModalSelect';

import '../../styles/shared/form.scss';

const titleOptions = [
  { label: 'Dr', value: 'Dr' },
  { label: 'Mr', value: 'Mr' },
  { label: 'Ms', value: 'Ms' },
  { label: 'Mrs', value: 'Mrs' },
  { label: 'Prof.', value: 'Prof.' },
  { label: 'Assc Prof.', value: 'Assc Prof.' },
];

const SurgeonForm = (props) => {
  const {
    mode = 'create',
    initialValues,
    buttonText,
    onSubmit,
    loading,
    procedures,
    hospitals,
    onClose,
    qualifications = [],
  } = props;

  const { name } = initialValues || {};

  const [isProcedureModalOpen, toggleProcedureModal] = useState(false);
  const [isHospitalModalOpen, toggleHospitalModal] = useState(false);
  const [isModalOpen, toggleModal] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.firstName) {
      errors.firstName = 'Required';
    }
    if (!values.lastName) {
      errors.lastName = 'Required';
    }

    return errors;
  };

  const getProcedureNames = (procedureIds) => {
    return procedureIds.map((id) => {
      const procedure = procedures.find((item) => item.id === id);
      return procedure ? procedure.name : '';
    })
  };

  const getHospitalNames = (hospitalIds) => {
    return hospitalIds.map((id) => {
      const hospital = hospitals.find((item) => item.id === id);
      return hospital ? hospital.name : '';
    })
  };

  const getQualificationNames = (ids = []) => {
    return ids.map((id) => {
      const q = qualifications?.find((item) => item.id === id);
      return q ? q.name : '';
    })
  };

  return (
    <div className='form__container'>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{
          ...initialValues,
          procedures: initialValues?.procedures || [],
          hospitals: initialValues?.hospitals || [],
          // assignedManagers: initialValues?.assignedManagers || [],
        }}
        subscription={{ pristine: true, invalid: true }}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine}) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <div className='form__subtitle'>
                { name ? name : 'Add Surgeon' }
              </div>
              { mode === 'update' && (
                <Field name='active'>
                  {({ input, meta }) => (
                    <Input
                      type='switch'
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      placeholder='Status'
                    />
                  )}
                </Field>
              )}

              <div className='combined-fields__container'>
                <Field name='firstName'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      startAdornment='First'
                      borderless
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
                <Field name='lastName'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      startAdornment='Last'
                      borderless
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-lg'>
                <div className='field__title'>Title</div>
                <Field name='title'>
                  {({ input, meta }) => (
                    <Select
                      name={input.name}
                      value={input.value}
                      placeholder='Title'
                      onChange={input.onChange}
                      options={titleOptions}
                    />
                  )}
                </Field>
              </div>

              { mode === 'update' && (
                <>
                  <div>
                    <div className='field__title'>Procedures</div>
                    <Field name='procedures'>
                      {({ input, meta }) => (
                        <div className='m-b-lg'>
                          <ModalSelectInput
                            onClick={() => toggleProcedureModal(true)}
                            count={input.value.length}
                            values={getProcedureNames(input.value)}
                            label='Edit Procedures'
                            icon='procedure'
                          />

                          <ModalSelect
                            title='Add Procedures'
                            listTitle='Procedures'
                            options={procedures}
                            onChange={input.onChange}
                            selected={input.value}
                            onClose={() => toggleProcedureModal(false)}
                            isOpen={isProcedureModalOpen}
                            selectAll
                          />
                        </div>
                      )}
                    </Field>
                  </div>

                  <div>
                    <div className='field__title'>Hospitals</div>
                    <Field name='hospitals'>
                      {({ input, meta }) => (
                        <div className='m-b-lg'>
                          <ModalSelectInput
                            onClick={() => toggleHospitalModal(true)}
                            count={input.value.length}
                            values={getHospitalNames(input.value)}
                            label='Edit Hospitals'
                            icon='hospital'
                          />

                          <ModalSelect
                            title='Add Hospitals'
                            listTitle='Hospitals'
                            options={hospitals}
                            onChange={input.onChange}
                            selected={input.value}
                            onClose={() => toggleHospitalModal(false)}
                            isOpen={isHospitalModalOpen}
                            extraField='state'
                          />
                        </div>
                      )}
                    </Field>
                  </div>

                  <Field name='qualifications'>
                    {({ input, meta }) => (
                      <div className='m-b-lg'>
                        <div className='field__title'>Qualifications</div>
                        <ModalSelectInput
                          onClick={() => toggleModal(true)}
                          count={input.value.length}
                          label='Edit Qualifications'
                          values={getQualificationNames(input.value || [])}
                          icon='option'
                        />
                        <QualificationsModal
                          qualifications={qualifications}
                          title='Qualifications'
                          onChange={input.onChange}
                          selected={input.value || []}
                          onClose={() => toggleModal(false)}
                          isOpen={isModalOpen}
                        />
                      </div>
                    )}
                  </Field>
                </>
              )}

              <div className='form__actions'>
                <Button
                  type='submit'
                  text={buttonText || 'Save Changes'}
                  disabled={invalid || loading || pristine}
                  loading={loading}
                  onClick={handleSubmit}
                />
                { mode === 'create' && (
                  <Button
                    type='cancel'
                    onClick={onClose}
                  />
                )}
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default SurgeonForm;
