import React, { useState, useMemo } from 'react';
import { orderBy as sortBy } from 'lodash';
import { Column, Table, SortDirection } from 'react-virtualized';
import 'react-virtualized/styles.css';

import Checkbox from '../../shared/Checkbox';
import Button from '../../shared/Button';
import Modal from '../../shared/modal/Modal';
import Input from '../../shared/Input';
import Select from '../../shared/Select';

import { itemTypeOptions } from '../../../constants/enums';

const AddItemsModal = (props) => {
  const {
    options,
    onSubmit,
    onClose,
    open,
    loading,
    title,
  } = props;

  const [selected, setSelected] = useState(null);
  const [search, setSearch] = useState('');
  const [type, setType] = useState('');

  const handleCheck = (itemId) => {
    setSelected(selected !== itemId ? itemId : null);
  };

  const list = useMemo(() => (
    sortBy(
      options?.filter((item) => {
        let filter = true;

        if (!!type) {
          filter = item?.type === itemTypeOptions[type]?.value;
        }

        return item?.code?.toLowerCase().includes(search?.toLowerCase()) && filter;
      }),
      'code',
    )
  ), [options, search, type]);

  const handleClose = () => {
    onClose();
    setSelected(null);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      size="lg"
    >
      <div className="form__container section-items-modal__container">
        <div className="form__body">
          <div className="form__subtitle">
            {title || 'Add Items'}
          </div>

          <div className="section-items-modal__filters">
            <div className="m-r-md">
              <Input
                type="search"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>

            <div className="m-l-md">
              <Select
                value={type}
                onChange={setType}
                options={[{ label: 'All', value: '' }, ...Object.values(itemTypeOptions)]}
              />
            </div>
          </div>

          <div className="secondary font-size-sm m-b-sm">Items</div>
          <div className="m-b-lg form__select-users" style={{ minWidth: 860, minHeight: 540 }}>
            {list?.length ? (
              <VirtualizedTable
                data={list?.map((item) => ({
                  ...item,
                  description: item.checklistDescription || item.description || '',
                  type: itemTypeOptions[item.type]?.label
                }))}
                checked={selected}
                onCheck={handleCheck}
              />
            ) : (
              <div className="text-center secondary p-md">
                No Items
              </div>
            )}
          </div>

          <div className="form__actions">
            <div className='d-flex'>
              <Button
                type="submit"
                text={title || "Add Items"}
                loading={loading}
                disabled={!selected || loading}
                onClick={() => {
                  onSubmit(selected);
                  handleClose();
                  setSelected(null);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const VirtualizedTable = ({ data, checked, onCheck, onMinusClick, onPlusClick }) => {
  const [sortBy, setSortBy] = useState(''); // State to track the currently sorted column
  const [sortDirection, setSortDirection] = useState(SortDirection.ASC); // State to track the sort direction

  const sort = ({ sortBy, sortDirection }) => {
    return data.slice().sort((a, b) => {
      const valueA = a[sortBy] || '';
      const valueB = b[sortBy] || '';

      if (sortDirection === SortDirection.ASC) {
        return valueA?.localeCompare(valueB);
      } else {
        return valueB?.localeCompare(valueA);
      }
    });
  };

  const sortedData = sort({ sortBy, sortDirection });

  const handleSort = ({ sortBy, sortDirection }) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection);
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Table
        width={860} // Width of the table
        height={500} // Height of the table
        headerHeight={36} // Height of the table header
        rowHeight={36} // Height of each table row
        rowCount={sortedData.length} // Total number of rows
        rowGetter={({ index }) => sortedData[index]} // Function to get the data for each row
        sort={handleSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
      >
        <Column
          label=""
          disableSort
          dataKey="id"
          width={50}
          headerRenderer={({ label, sortBy, sortDirection }) => (
            <div>
              {/*<Checkbox*/}
              {/*  input={{*/}
              {/*    onClick: (e) => onCheckAll(e.target.checked),*/}
              {/*    checked: data.every((item) => checked?.includes(item.id)),*/}
              {/*  }}*/}
              {/*  fontSize="small"*/}
              {/*/>*/}
            </div>
          )}
          cellRenderer={({ rowIndex, rowData }) => {
            return (
              <div className='p-l-md'>
                <Checkbox
                  input={{
                    onClick: () => onCheck(rowData.id),
                    checked: checked === rowData.id,
                  }}
                  index={rowIndex}
                  fontSize="small"
                />
              </div>
            )
          }}
        />
        <Column
          label="Code"
          dataKey="code"
          width={200}
          headerRenderer={({ label, sortBy, sortDirection }) => (
            <div>
              {label}
              {sortBy === 'code' && (
                <SortCaret direction={sortDirection}/>
              )}
            </div>
          )}
        />
        <Column
          label="Description"
          dataKey="description"
          width={500}
          headerRenderer={({ label, sortBy, sortDirection }) => (
            <div>
              {label}
              {sortBy === 'description' && (
                <SortCaret direction={sortDirection}/>
              )}
            </div>
          )}
        />
        <Column
          label="Type"
          dataKey="type"
          width={150}
          headerRenderer={({ label, sortBy, sortDirection }) => (
            <div>
              {label}
              {sortBy === 'type' && (
                <SortCaret direction={sortDirection}/>
              )}
            </div>
          )}
        />
      </Table>
    </div>
  );
};

const SortCaret = ({ direction }) => {
  return (
    <span style={{ marginLeft: '5px' }}>
      {direction === SortDirection.ASC ? '▲' : '▼'}
    </span>
  );
};

export default AddItemsModal;
