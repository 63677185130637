import React, { useState, useMemo } from 'react';

import IconButton from '@material-ui/core/IconButton';
import ArrowIconUp from '@material-ui/icons/KeyboardArrowUp';
import ArrowIconDown from '@material-ui/icons/KeyboardArrowDown';

import CaseUsageSetRow from './CaseUsageSetRow';
import CaseUsageNotesRow from './CaseUsageNotesRow';

import { billOfMaterialItemTypes, itemTypeOptions } from '../../../constants/enums';

const CaseUsageSectionRow = (props) => {
  const { section, onChange, tenantColor, search, selectedType } = props;

  const [open, setOpen] = useState(true);

  const items = useMemo(() => {
    if (section?.items?.length) {
      let result = section?.items;
      if (search) {
        result = result?.filter((item) => (
          item?.code?.toLowerCase()?.includes(search?.toLowerCase()) ||
          item?.description?.toLowerCase()?.includes(search?.toLowerCase())
        ));
      }
      if (selectedType) {
        result = result?.filter((item) => item?.type === itemTypeOptions[selectedType]?.label);
      }

      return result;
    }
    return [];
  }, [section, search, selectedType]);

  return (
    <div className='add-usage-modal__section'>
      <div className='d-flex m-b-md space-between'>
        <div className='font-bold font-size-bg'>{section?.name}</div>
        <IconButton
          className='m-l-md'
          aria-label='more'
          aria-controls='long-menu'
          aria-haspopup='true'
          onClick={() => setOpen(!open)}
          size='small'
        >
          {open ? <ArrowIconUp fontSize='large' /> : <ArrowIconDown fontSize='large' />}
        </IconButton>
      </div>

      {open && (
        <div className='m-t-md m-b-md'>
          <div className='d-flex m-b-sm align-start'>
            <div
              style={{ flex: 0.2 }}
              className='font-bold uppercase font-size-sm secondary'
            >
              Item Code
            </div>
            <div
              style={{ flex: 0.45 }}
              className='font-bold uppercase font-size-sm secondary'
            >
              Description
            </div>
            <div
              style={{ flex: 0.1, paddingLeft: 4 }}
              className='font-bold uppercase font-size-sm secondary'
            >
              Type
            </div>
            <div
              style={{ flex: 0.1 }}
              className='font-bold text-center uppercase font-size-sm secondary'
            >
              Out
            </div>
            <div
              style={{ flex: 0.15 }}
              className='font-bold d-flex flex-end'
            />
          </div>
          {items?.map((item) => (
            item.type === billOfMaterialItemTypes.NOTES.value ? (
              <CaseUsageNotesRow
                key={`${Math.random()}_${item.code}`}
                item={item}
              />
            ) : (
              <CaseUsageSetRow
                key={`${Math.random()}_${item.code}`}
                code={item.code}
                description={item.description}
                outbound={item.outbound}
                type={item.type}
                onChange={(value) => onChange(item.code, value)}
                qty={item.qty || 0}
                tenantColor={tenantColor}
              />
            )
          ))}
        </div>
      )}
    </div>
  );
};

export default CaseUsageSectionRow;
