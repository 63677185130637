import React from 'react';
import { Field, Form } from 'react-final-form';

import WarningIcon from '@material-ui/icons/ErrorOutline';

import Button from '../../shared/Button';
import Input from '../../shared/Input';

import '../../../styles/shared/form.scss';

const NoteForm = (props) => {
  const {
    mode = 'create',
    initialValues,
    onSubmit = () => {},
    loading,
    onClose,
    withoutText,
    count = false,
  } = props;

  const validate = (values) => {
    const errors = {};

    if (!values.note) {
      errors.note = 'Required';
    }

    return errors;
  };

  return (
    <div className='form__container'>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        subscription={{ pristine: true, invalid: true }}
        initialValues={initialValues}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <div className='form__subtitle'>
                {mode === 'update' ? 'Edit Note' : 'Add Note'}
              </div>

              {!withoutText && (
                <div className='form__warning-text m-b-lg'>
                  <WarningIcon />
                  <span className='m-l-sm'>{count ? 'Count' : 'Case'} notes are shared with all users</span>
                </div>
              )}

              <div className='m-b-lg'>
                <div className='field__title'>Note</div>
                <Field name='note'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      type='textarea'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              <div className='form__actions'>
                <Button
                  type='submit'
                  text={mode === 'update' ? 'Save Changes' : 'Add Note'}
                  disabled={invalid || loading || pristine}
                  loading={loading}
                  onClick={handleSubmit}
                />
                <Button
                  type='cancel'
                  onClick={onClose}
                />
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default NoteForm;
