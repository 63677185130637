import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function EmailTriggerIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M13.25 8.59881C13.25 9.77435 13.1878 10.6251 13.0534 11.4474C12.9201 12.2627 12.2343 12.8623 11.4135 12.9558C9.99287 13.1176 8.51686 13.2389 7 13.2389C5.48315 13.2389 4.00713 13.1176 2.58647 12.9558C1.76573 12.8623 1.07992 12.2627 0.946642 11.4474C0.812206 10.6251 0.75 9.77435 0.75 8.59881C0.75 7.42329 0.812204 6.57254 0.946638 5.75028C1.07992 4.93505 1.76575 4.33538 2.58649 4.2419C3.20188 4.17182 3.82765 4.10934 4.46263 4.06104" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M1.27319 4.98241L5.76205 8.51331C6.48828 9.08455 7.51129 9.08396 8.23686 8.51187L8.35144 8.42153" />
    <circle cx={10.25} cy={3.75} r={3} stroke={color || defaultColor} fill="none" />
  </SvgIcon>
}
