import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function VisibilityIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinejoin="round"
          d="M13.25 7C13.25 8.0501 10.4518 11.75 7 11.75C3.54822 11.75 0.75 8.0501 0.75 7C0.75 5.9499 3.54822 2.25 7 2.25C10.4518 2.25 13.25 5.9499 13.25 7Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M7 9C8.10457 9 9 8.10457 9 7C9 5.89543 8.10457 5 7 5C5.89543 5 5 5.89543 5 7C5 8.10457 5.89543 9 7 9Z" />
  </SvgIcon>
}
