import React, { useState, useMemo } from 'react';

import IconButton from '@material-ui/core/IconButton';
import ArrowIconUp from '@material-ui/icons/KeyboardArrowUp';
import ArrowIconDown from '@material-ui/icons/KeyboardArrowDown';
import SectionIcon from '../../../assets/icons/DocumentIcon';
import FullScreenIcon from '../../../assets/icons/FullScreenIcon';

import BatchSectionItemRow from './BatchSectionItemRow';
import BatchControlModal from './BatchControlModal';
import SectionNotesRow from '../../cases/return-sets/SectionNotesRow';

import { billOfMaterialItemTypes, caseChecklistStatuses, setPositionOptions } from '../../../constants/enums';

const BatchSection = (props) => {
  const { name, status, image, items, onChange, onQuantityChange, loading, batchItems, itemsQuantity = {}, checklistPage } = props;

  const [open, setOpen] = useState(true);

  const [modalIsOpen, toggleModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const onSubmit = async (values) => {
    await onChange(selectedItem, values);
    setSelectedItem(null);
  };

  const incomplete = useMemo(() => (
    items?.some((item) => item.batchControl ? (batchItems[item.id] === undefined || batchItems[item.id]?.length < item?.quantity) : (itemsQuantity[item.id] !== undefined && itemsQuantity[item.id] < item?.quantity))
  ), [items, batchItems, itemsQuantity]);

  return (
    <div className='checklist-section-item'>
      <div className='d-flex space-between'>
        <div className='d-flex'>
          <SectionIcon />
          <div className='font-size-bg font-bold m-l-md'>
            {name}
          </div>

          <IconButton
            className='m-l-md'
            aria-label='more'
            aria-controls='long-menu'
            aria-haspopup='true'
            onClick={() => setOpen(!open)}
            size='small'
          >
            {open ? <ArrowIconUp fontSize='large' /> : <ArrowIconDown fontSize='large' />}
          </IconButton>
        </div>

        {incomplete && !checklistPage && (
          <div className='font-bold font-size-bg' style={{ color: setPositionOptions.ALLOCATED.color }}>Incomplete</div>
        )}
      </div>

      {open && (
        <div>
          {!!image && !!image.downloadUrl && (
            <div className='section-image__container'>
              <img src={image.downloadUrl} alt='section-preview' />
              <div
                className='full-screen-icon'
                onClick={() => window.open(image?.downloadUrl, '_blank')}
              >
                <FullScreenIcon color='secondary' fontSize='large' />
              </div>
            </div>
          )}

          <div className={status === caseChecklistStatuses.INSPECT.value ? 'm-t-md' : 'm-t-lg'}>
            {items && !!items.length && (
              <table className='section-items-table'>
                <thead>
                <tr>
                  <th>Ref</th>
                  <th>Item Code</th>
                  <th>Description</th>
                  <th>Type</th>
                  <th>Image</th>
                  <th>Batch</th>
                  <th />
                  <th style={{ textAlign: 'center' }}>QTY</th>
                  <th style={{ textAlign: 'center' }}>{checklistPage ? 'Out' : 'Allocated'}</th>
                </tr>
                </thead>
                {
                  <tbody>
                  {items.map((item) => (
                    item.type === billOfMaterialItemTypes.NOTES.value ? (
                      <SectionNotesRow
                        key={`${item.id}_${Math.random()}`}
                        item={item}
                      />
                    ) : (
                      <BatchSectionItemRow
                        key={`${item.code}_${Math.random()}`}
                        item={item}
                        status={status}
                        onEditClick={() => {
                          setSelectedItem(item);
                          toggleModal(true);
                        }}
                        onQuantityChange={(value) => onQuantityChange(item, value)}
                        batchItems={batchItems ? batchItems[item?.id] : []}
                        quantity={itemsQuantity[item?.id]}
                      />
                    )
                  ))}
                  </tbody>
                }
              </table>
            )}
          </div>
        </div>
      )}

      {selectedItem && (
        <BatchControlModal
          open={modalIsOpen}
          onClose={() => {
            toggleModal(false);
            setSelectedItem(null);
          }}
          item={selectedItem}
          loading={loading}
          onSubmit={onSubmit}
          batchItems={batchItems ? batchItems[selectedItem.id] : []}
        />
      )}
    </div>
  );
};

export default BatchSection;
