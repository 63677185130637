import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';

import ShippingIcon from '../../../assets/icons/ShippingIcon';
import TransferIcon from '../../../assets/icons/TransferIcon';
import UsageIcon from '../../../assets/icons/UsageIcon';
import DescriptionOutlinedIcon from '../../../assets/icons/DocumentIcon';
import ConsignmentIcon from '../../../assets/icons/ConsignedSetIcon';
import ReturnIcon from '../../../assets/icons/ReturnIcon';

import { setAllocationStatuses } from '../../../constants/enums';

const StyledButton = withStyles((theme) => ({
  root: {
    height: '60px',
    width: '80px',
    borderRadius: '16px',
  }
}))(MuiButton);

const getStatusIcon = (status) => {
  switch (status) {
    case setAllocationStatuses.SHIPPED.value:
      return <ShippingIcon color="#ffffff" />;
    case setAllocationStatuses.TRANSFERRED.value:
      return <TransferIcon color="#ffffff" />;
    case setAllocationStatuses.RETURNED.value:
      return <ReturnIcon color="#ffffff" />;
    case setAllocationStatuses.DISPATCH.value:
      return <DescriptionOutlinedIcon color="#ffffff" />;
    case setAllocationStatuses.USAGE.value:
      return <UsageIcon color="#ffffff" />;
    case setAllocationStatuses.CONFIRMED.value:
      return <ConsignmentIcon color="#ffffff" />;
    default:
      return null;
  }
};

const getButtonLabel = (status) => {
  switch (status) {
    case setAllocationStatuses.SHIPPED.value:
      return 'Ship';
    case setAllocationStatuses.RETURNED.value:
      return 'Return';
    case setAllocationStatuses.TRANSFERRED.value:
      return 'Transfer';
    case setAllocationStatuses.DISPATCH.value:
      return 'Documents';
    case setAllocationStatuses.USAGE.value:
      return 'Usage';
    case setAllocationStatuses.CONFIRMED.value:
      return 'Confirm';
    default:
      return null;
  }
};

const ActionButton = ({
  status = setAllocationStatuses.AVAILABLE.value,
  onClick,
  disabled,
  loading,
}) => {
  return (
    <StyledButton
      style={{
        background: disabled ? '#DDDDDD' : setAllocationStatuses[status].color,
      }}
      onClick={() => onClick(status)}
      disabled={disabled}
    >
      {loading && <CircularProgress size={24} />}
      {!loading && (
        <div className='d-flex direction-column space-between'>
          {getStatusIcon(status)}
          <div className='filter-button__label'>
            {getButtonLabel(status)}
          </div>
        </div>
      )}

    </StyledButton>
  )
};

export default ActionButton;
