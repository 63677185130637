import React, { useMemo } from 'react';
import { orderBy } from 'lodash';

import { PieChart } from '../../../charts';
import moment from 'moment';

const ExpiringItemsPieChart = ({ sets = [], kits = [] }) => {
  const data = useMemo(() => {
    const start = moment().add(1, 'day').startOf('day');
    const end = moment().add(90, 'day').endOf('day');

    const chartData = kits
      ?.filter((kit) => !!kit.active)
      ?.map((kit) => {
        const kitSets = sets.filter((set) => set.kit === kit.id);
        let value = 0;
        kitSets?.forEach((set) => {
          if (set.batchItems) {
            Object.values(set.batchItems).forEach((arr) => {
              arr.forEach((item) => {
                if (start.isBefore(moment(item.expDate)) && end.isAfter(moment(item.expDate))) {
                  value++;
                }
              });
            });
          }
        });
        return {
          id: kit.kitId,
          label: kit.kitId,
          value: value,
        };
      })

    return orderBy(chartData?.filter((item) => !!item.value), 'value', 'asc');
  }, [sets, kits]);

  return (
    <div className="management-reports-chart__container m-l-md" style={{ width: '50%' }}>
      <div className="font-size-bg font-bold">Expiring Items</div>
      <div className="secondary m-t-sm m-b-lg">Total expiring items over the next 90 days</div>
      <PieChart
        data={data}
        margin={{ top: 20, bottom: 20 }}
        height="300px"
        enableArcLinkLabels={true}
        enableArcLabels={true}
        entity='item'
        legends={[]}
      />
    </div>
  );
};

export default ExpiringItemsPieChart;
