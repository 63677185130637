import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function TerritoryIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M7.57777 12.7762C8.85308 11.6711 11.7972 8.77076 11.7972 5.5471C11.7972 2.89777 9.64949 0.750061 7.00016 0.750061C4.35083 0.750061 2.20312 2.89777 2.20312 5.5471C2.20312 8.77076 5.14724 11.6711 6.42255 12.7762C6.75771 13.0666 7.24261 13.0666 7.57777 12.7762Z"/>
    <path stroke={color || defaultColor} fill="none"
          d="M5.00226 8.49997C4.98985 7.42523 5.02633 5.22853 5.16595 3.93C5.20609 3.55671 5.47939 3.26005 5.84039 3.15691L5.90332 3.13893C6.62153 2.93372 7.38286 2.93371 8.10108 3.13891L8.16412 3.15692C8.52513 3.26006 8.79844 3.55671 8.83857 3.93001C8.97818 5.22855 9.01461 7.42524 9.0022 8.49997"/>
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M4.5 8.5H9.5"/>
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M6.75 6H7.25"/>
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M6.75 4.5H7.25"/>
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M7 8.5V7.5"/>
  </SvgIcon>
}

