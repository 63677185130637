const formatAddressFromPlaces = (addressComponents) => {
  if (!addressComponents) {
    return {};
  }

  const country = addressComponents.find((item) => item?.types?.includes('country'));
  const postCode = addressComponents.find((item) => item?.types?.includes('postal_code'));
  const state = addressComponents.find((item) => item?.types?.includes('administrative_area_level_1'));
  const city = addressComponents.find((item) => item?.types?.includes('locality'));
  const streetName = addressComponents.find((item) => item?.types?.includes('route'));
  const streetNumber = addressComponents.find((item) => item?.types?.includes('street_number'));

  const formattedAddress = {
    country: country ? country.long_name : '',
    postCode: postCode ? postCode.long_name : '',
    state: state ? state.long_name : '',
    city: city ? city.long_name : '',
    streetName: streetName ? streetName.long_name : '',
    streetNumber: streetNumber ? streetNumber.long_name : '',
  };

  const street = `${formattedAddress.streetNumber ? `${formattedAddress.streetNumber} ` : ''}${formattedAddress.streetName}`;
  formattedAddress.street = street;

  return formattedAddress;
};

export {
  formatAddressFromPlaces,
}
