import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function SignOutIcon(props) {
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={props.color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M10.0273 9.96143C11.2871 8.92929 11.9166 8.29995 12.8986 7.09016C11.9166 5.88038 11.2871 5.25103 10.0273 4.2189" />
    <path stroke={props.color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M4.00399 7.09033L12.8667 7.09033" />
    <path stroke={props.color || defaultColor} fill="none" strokeLinecap="round"
          d="M9.83767 1.37878C9.51445 1.08087 9.07877 0.884672 8.58695 0.849664C7.66706 0.784187 6.71957 0.75 5.75183 0.75C4.78409 0.75 3.83659 0.784187 2.9167 0.849665C1.90446 0.921717 1.13009 1.67654 1.08437 2.59563C1.01375 4.01513 0.976562 5.48869 0.976562 7C0.976562 8.5113 1.01375 9.98487 1.08437 11.4044C1.13009 12.3235 1.90446 13.0783 2.9167 13.1503C3.83659 13.2158 4.78409 13.25 5.75183 13.25C6.71957 13.25 7.66706 13.2158 8.58695 13.1503C9.07877 13.1153 9.51445 12.9191 9.83767 12.6212" />
  </SvgIcon>
}
