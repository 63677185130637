import config from '../config/firebase';

const useGoogleCalendar = (event) => {
  const gapi = window.gapi;
  const CLIENT_ID = config.clientId;
  const API_KEY = config.apiKey;
  const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest';
  const SCOPE = 'https://www.googleapis.com/auth/calendar';

  const createEvent = async (event) => {
    await gapi.load('client:auth2', async () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: [DISCOVERY_DOC],
        scope: SCOPE,
      });
      gapi.client.load('calendar', 'v3', console.log);
      await gapi.auth2.getAuthInstance().signIn();
      await addCaseEvent(event);
    });
  };

  const addCaseEvent = async (event) => {
    const request = gapi.client.calendar.events.insert({
      'calendarId': 'primary',
      'resource': event
    });

    request.execute((e) => {
      if (e && e.htmlLink) {
        window.open(e.htmlLink);
      }
    });
  };

  return {
    createEvent,
  };
};

export default useGoogleCalendar;
