import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function ImageIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none"
          d="M0.959867 10.2685C1.114 11.7092 2.2727 12.8679 3.71266 13.0284C4.78221 13.1476 5.88037 13.25 7 13.25C8.11963 13.25 9.21779 13.1476 10.2873 13.0284C11.7273 12.8679 12.886 11.7092 13.0401 10.2685C13.1539 9.20502 13.25 8.11315 13.25 7C13.25 5.88684 13.1539 4.79498 13.0401 3.73147C12.886 2.29082 11.7273 1.13211 10.2873 0.971609C9.21779 0.852392 8.11963 0.75 7 0.75C5.88037 0.75 4.78221 0.852392 3.71266 0.971609C2.2727 1.13211 1.114 2.29082 0.959867 3.73147C0.846083 4.79498 0.75 5.88684 0.75 7C0.75 8.11315 0.846084 9.20502 0.959867 10.2685Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M8.75 6.5C9.7165 6.5 10.5 5.7165 10.5 4.75C10.5 3.7835 9.7165 3 8.75 3C7.7835 3 7 3.7835 7 4.75C7 5.7165 7.7835 6.5 8.75 6.5Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M3.71266 13.0284C2.27271 12.8679 1.11401 11.7092 0.959873 10.2685C0.86638 9.39467 0.784838 8.50167 0.758728 7.59345C1.16594 7.53016 1.57762 7.49889 1.99 7.49999C3.73452 7.46054 5.43631 8.0422 6.79173 9.14119C8.04879 10.1604 8.93208 11.5631 9.31051 13.1295C8.55242 13.2001 7.78144 13.25 7.00001 13.25C5.88037 13.25 4.78221 13.1476 3.71266 13.0284Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M9.31051 13.1295C9.6386 13.0989 9.96428 13.0644 10.2873 13.0284C11.7273 12.8679 12.886 11.7092 13.0401 10.2685C13.0572 10.1086 13.074 9.948 13.09 9.7868C12.4069 9.59628 11.7004 9.49962 10.99 9.50002C9.91393 9.49768 8.84876 9.71548 7.85999 10.14L7.85999 10.2064C8.55311 11.0562 9.05074 12.0543 9.31051 13.1295Z" />
  </SvgIcon>
}
