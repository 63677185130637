import React from 'react';

import DeleteIcon from '../../assets/icons/DeleteIcon';
import FileCopyIcon from '../../assets/icons/CopyIcon';

import Button from '../shared/Button';

const FormsActions = ({ onAddClick, onDeleteClick, selectedForm, deletePermission }) => {
  return (
    <div className='settings-cmp__filters--main'>
      <div className='settings-cmp__actions'>
        <Button
          text={selectedForm ? 'Add Field' : 'Add Form'}
          type='submit'
          onClick={onAddClick}
        />

        <Button
          type='icon'
          onClick={onDeleteClick}
          disabled={!deletePermission}
        >
          <DeleteIcon style={{ color: '#000000' }}/>
        </Button>

        <Button
          type='icon'
        >
          <FileCopyIcon style={{ color: '#000000' }}/>
        </Button>
      </div>
    </div>
  );
};

export default FormsActions;
