import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import SaveIcon from '../../assets/icons/CheckIcon';
import DeleteIcon from '../../assets/icons/DeleteIcon';
import EditIcon from '../../assets/icons/EditIcon';

import Input from '../shared/Input';
import Button from '../shared/Button';

const CustomDecodingItem = (props) => {
  const {
    initialField,
    initialIndex,
    edit,
    onSave,
    onDelete,
    onEdit,
    config,
  } = props;

  const tenantColor = useSelector((state) => state?.tenant?.currentTenant.colorPrimary);

  const [index, setIndex] = useState(initialIndex || '');
  const [field, setField] = useState(initialField || '');

  const handleSave = () => {
    if (!index || !field || config.find((item) => item.index === Number(index) && !item.edit)) {
      return;
    }

    onSave(Number(index), field);
    setIndex('');
    setField('');
  };

  return (
    <div className='custom-decoding__item'>
      {edit ? (
        <div className='d-flex'>
          <div className='index-input__container'>
            <Input
              value={index}
              onChange={(e) => setIndex(e.target.value.replace(/[^0-9]/g, ''))}
              placeholder='Index'
            />
          </div>
          <div className='field-input__container'>
            <Input
              value={field}
              onChange={(e) => setField(e.target.value)}
              placeholder='Field name'
            />
          </div>
        </div>
      ) : (
        <div className='d-flex custom-decoding-values'>
          <div>{index}</div>
          <div>{field}</div>
        </div>
      )}
      <div className='custom-decoding__actions d-flex'>
        {edit ? (
          <Button
            type='icon'
            onClick={handleSave}
          >
            <SaveIcon color={tenantColor || ''} />
          </Button>
        ) : (
          <Button
            type='icon'
            onClick={onEdit}
          >
            <EditIcon color={tenantColor || ''} />
          </Button>
        )}
        <Button
          type='icon'
          onClick={onDelete}
        >
          <DeleteIcon color="#d32f2f" />
        </Button>
      </div>
    </div>
  );
};

export default CustomDecodingItem;
