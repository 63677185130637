import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import CustomQRIcon from '../../../../assets/icons/QRIcon';
import GtinReferenceIcon from '../../../../assets/icons/GtinIcon';
import RepeatIcon from '../../../../assets/icons/RepeatIcon';
import TagIcon from '../../../../assets/icons/TagIcon';

import { routes } from '../../../../constants';

const options = [
  {
    title: 'Custom QR',
    subTitle: 'Configure customer QR decoding',
    icon: <CustomQRIcon className='option-icn' />,
    url: routes.SCAN_QR
  },
  {
    title: 'GTIN Reference',
    subTitle: 'Configure GTIN reference',
    icon: <GtinReferenceIcon className='option-icn' />,
    url: routes.SCAN_GTIN
  },
  {
    title: 'Repeating Counts',
    subTitle: 'Configure repeating counts',
    icon: <RepeatIcon className='option-icn' />,
    url: routes.REPEATING_COUNTS
  },
  {
    title: 'Tags',
    subTitle: 'Manage item tags',
    icon: <TagIcon className='option-icn' />,
    url: routes.TAGS
  },
];

const ScanPage = () => {
  const tenantColor = useSelector((state) => state.tenant.currentTenant.colorPrimary);

  return (
    <div className='page-container flex-1'>
      <div className='page-title'>
        Scan
      </div>

      <div className='customers-page__body'>
        <Grid container spacing={1}>
          {
            options.map(option => (
              <Grid key={option.title} item xs={12} sm={12} md={4}>
                <Link to={option.url}>
                  <div
                    className='customers-page-option'
                    key={`${option.title}_${Math.random()}`}
                    style={{ '--colorHover': `${tenantColor}` }}
                  >
                    <span className='option-title'>{option.title}</span>
                    <span className='option-sub-t'>{option.subTitle}</span>
                    <div className='option-icn-cnt'>
                      <span>{option.icon}</span>
                    </div>
                  </div>
                </Link>
              </Grid>
            ))
          }
        </Grid>
      </div>
    </div>
  );
};

export default ScanPage;
