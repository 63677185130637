import React from 'react';
import { v4 as uuid } from 'uuid';

import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';

import Button from './Button';

const textColor = '#0b0b0b';
const placeholderColor = '#a7a7a7';

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    width: '100%',
    background: '#ffffff',
    border: '1px solid #EEEEEE',
    borderRadius: '16px',
    outline: 'none',
    marginBottom: '16px',
  },
  inputColor: {
    width: '100%',
    background: '#ffffff',
    height: 60,
    border: '1px solid #EEEEEE',
    borderRadius: '16px',
    outline: 'none',
    marginBottom: '16px',
    '&:active': {
      outline: 'none',
      borderRadius: '16px',
    },
    '&:focus': {
      outline: 'none !important',
      borderRadius: '16px',
    },
    '&:before': {
      outline: 'none',
      borderRadius: '16px',
    }
  },
  inputContainerBorderless: {
    width: '100%',
    background: '#ffffff',
    borderRadius: '16px',
    border: '0',
    outline: 'none',
    height: '45px'
  },
  input: {
    width: '100%',
    height: '60px',
    // height: '58px',
    outline: 'none',
    paddingLeft: '16px',
  },
  search: {
    width: '240px',
    height: '60px',
    outline: 'none',
    border: '1.5px solid #EEEEEE',
    borderRadius: '16px',
    paddingLeft: '20px',
    paddingRight: '20px',
    fontWeight: 'normal !important',
  },
  startAdornment: {
    color: placeholderColor,
  },
  endAdornment: {
    color: placeholderColor,
  }
}));

const Search = (props) => {
  const {
    value = '',
    disabled = false,
    borderless = false,
    placeholder = 'Search',
    onChange = () => {},
    onSearchClick = () => {},
    loading = false,
  } = props;

  const classes = useStyles();

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSearchClick();
    }
  };

  return (
    <FormControl
      className={borderless ? classes.inputContainerBorderless : classes.inputContainer}
    >
      <InputBase
        id={`search${uuid()}`}
        type='text'
        className={classes.input}
        style={{ color: value ? textColor : placeholderColor }}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        onKeyDown={onKeyDown}
        endAdornment={
          <InputAdornment position='end' style={{ padding: 4 }}>
            <Button
              text='Search'
              background='#000000'
              width={140}
              height={52}
              disabled={!value || loading}
              loading={loading}
              onClick={onSearchClick}
            />
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default React.memo(Search);
