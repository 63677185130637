import React from 'react';
import SwipeableViews from 'react-swipeable-views';

import Tabs from '@material-ui/core/Tabs';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          {children}
        </Box>
      )}
    </div>
  );
}

const StyledTabs = withStyles((theme) => ({
  root: {
    borderBottom: '1px solid #e8e8e8',
    backgroundColor: 'transparent !important'
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Tabs);

const StyledTab = withStyles((theme) => ({
  root: {
    backgroundColor: 'transparent !important',
    textTransform: 'none',
    textAlign: 'left',
    minWidth: 72,
    fontWeight: 600,
    marginRight: theme.spacing(4),
    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    '&:focus': {
      color: theme.palette.primary.main,
    },
  },
  selected: {
    color: theme.palette.primary.main,
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
}));

export default function CustomizedTabs({ tabs, activeTab, onTabChange, children }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <StyledTabs
          value={activeTab}
          onChange={(e, value) => onTabChange(value)}
          aria-label='ant example'
        >
          {tabs.map((tab, index) => (
            <StyledTab
              key={index}
              label={typeof tab === 'object' ? tab.label : tab}
              onChange={(e, value) => onTabChange(value)}
              disabled={tab.disabled}
            />
          ))}
        </StyledTabs>
      </div>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeTab}
        onChangeIndex={onTabChange}
      >
        { tabs.map((tab, index) => (
          <TabPanel
            key={`tab-${index}`}
            value={activeTab}
            index={index}
            dir={theme.direction}
          >
            { children }
          </TabPanel>
        ))}
      </SwipeableViews>
    </div>
  );
}
