import React, { useCallback } from 'react';

import Table from '../shared/table/Table';
import { getCommaSeparatedNames } from '../../utils/table';

const columns = [
  { title: 'name', field: 'name', type: 'default', sort: true },
  { title: 'description', field: 'description', type: 'default', sort: true },
  { title: 'value', field: 'formattedValue', type: 'default', sort: true },
  { title: 'options', field: 'optionNames', type: 'default', sort: true },
  { title: 'flow', field: 'flows', type: 'default', sort: false },
  { title: 'status', field: 'active', type: 'status', sort: false }
];

const ProceduresTable = (props) => {
  const {
    onSelectProcedure,
    procedures,
    selectedProcedureId,
    handleCheck,
    checkedProcedures,
    handleCheckAll,
    procedureOptions,
    flows = [],
  } = props;

  const getOptionNames = useCallback((ids) => getCommaSeparatedNames(procedureOptions, ids), [procedures, procedureOptions]);

  const formatPrice = useCallback((value) => {
    if (!value || !Number(value)) {
      return '';
    }

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return formatter.format(value);
  }, [procedures]);

  const getFlowsList = useCallback((procedureId) => (
    flows?.filter((flow) => flow?.procedures?.includes(procedureId))?.map((flow) => flow.name)?.join(', ')
  ), [procedures, flows]);

  return (
    <div style={{ width: '100%' }}>
      <Table
        rows={procedures.map((procedure) => ({
          ...procedure,
          optionNames: getOptionNames(procedure.options),
          formattedValue: formatPrice(procedure.averageValue),
          flows: getFlowsList(procedure.id)
        }))}
        columns={columns}
        onRowClick={onSelectProcedure}
        selected={selectedProcedureId}
        handleCheck={handleCheck}
        checkedRows={checkedProcedures}
        handleCheckAll={handleCheckAll}
        pagination
        noCheckAll
      />
    </div>
  );
};

export default ProceduresTable;
