import React, { useCallback } from 'react';

import WarningIcon from '@material-ui/icons/Error';

import Modal from '../../shared/modal';

import { setAllocationStatuses } from '../../../constants/enums';

const AvailableKitsModal = (props) => {
  const { open, onClose, kits = [], kitsList = [], onContinueClick } = props;

  const getKitName = useCallback((kitId) => {
    const caseKit = kitsList?.find((kit) => kit.id === kitId);
    return caseKit && caseKit.name ? caseKit.name : '';
  }, [kits, kitsList]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Kit Availability Warning'
      fullWidth
      submitButton='Continue'
      closeButton='Close'
      onSubmit={onContinueClick}
    >
      <div className='ship-notes-modal__container' style={{ paddingTop: '8px' }}>
        <div className='d-flex align-start'>
          <WarningIcon style={{ color: setAllocationStatuses.TRANSFERRED.color, marginRight: 4 }} />
          <div className='font-bold' style={{ color: setAllocationStatuses.TRANSFERRED.color }}>
            Due to multiple bookings on this date, the following kits might not be available for this booking
          </div>
        </div>
        <div className='ship-note m-t-lg'>
          {kits?.map((kitId) => (
            <div key={kitId} className='m-b-sm font-bold'>{getKitName(kitId)}</div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default AvailableKitsModal;
