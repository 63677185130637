import React from 'react';

import WarningIcon from '@material-ui/icons/Error';

import Modal from '../shared/modal';
import Button from '../shared/Button';

import { setAllocationStatuses } from '../../constants/enums';

import '../shared/modal/modal.scss';

const ConfirmUploadModal = (props) => {
  const { open, onSubmit, onClose, loading, duplicates = [] } = props;

  return (
    <Modal open={open} onClose={onClose} size="lg">
      <div className="confirmation-modal__container">
        <div>
          <div className="modal-title">
            Upload issues have been found
          </div>

          {!!duplicates.length && (
            <>
              <div className="modal-content" style={{marginBottom: 16}}>
                <div className="d-flex">
                  <WarningIcon style={{color: setAllocationStatuses.ASSIGNED.color, marginRight: 4}} />
                  <div style={{color: setAllocationStatuses.ASSIGNED.color, fontWeight: 'bold'}}>
                    You are attempting to add hospitals that already exist
                  </div>
                </div>
                <div>Do you want to proceed with the upload of only non-duplicates or cancel the upload?</div>
              </div>

              <div className="m-t-lg m-b-lg">
                <div className="font-bold">Duplicate Hospitals</div>
                <div className="m-t-md">
                  {duplicates?.map((item) => (
                    <div key={Math.random()} className="m-t-sm">
                      {item[0]}
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

          <div className="form__actions">
            <Button
              type="submit"
              text="Upload"
              onClick={onSubmit}
              disabled={loading}
              loading={loading}
            />
            <Button
              type="cancel"
              text="Cancel"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmUploadModal;
