import React, { useState, useMemo } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import NotesIcon from '../../assets/icons/NotesIcon';

import AddButton from '../shared/AddButton';
import Modal from '../shared/modal';
import NoteForm from '../customers/forms/NoteForm';

import { createCountNote } from '../../actions/countsActions';

import { useLoading } from '../../hooks';

const CountNotes = ({ notes = [], countId, isConsigned }) => {
  const [isModalOpen, toggleModal] = useState(false);
  const { loading, startLoading, stopLoading } = useLoading(false);

  const dispatch = useDispatch();

  const onSubmit = (formObj) => {
    const noteData = {
      note: formObj.note,
    };

    startLoading();

    try {
      dispatch(createCountNote(countId, noteData));
      toggleModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const lastNote = useMemo(() => notes[0], [notes]);

  return (
    <div className='case-notes__container'>
      <div className='d-flex m-b-lg space-between'>
        <div className='d-flex'>
          <NotesIcon />
          <div className='font-size-bg font-bold m-l-md'>
            Notes
          </div>
        </div>

        <AddButton onClick={() => toggleModal(true)} />
      </div>

      <div
        className='case-notes-list__container count-notes-list__container'
        style={{ maxHeight: isConsigned ? '188px' : '156px' }}
      >
        { notes && notes.map((note) => (
          <div key={note.id} className='case-note'>
            <div className='d-flex space-between'>
              <div className='font-bold'>{note.authorFullName}</div>
              <div className='secondary'>{moment(note.createdAt).fromNow()}</div>
            </div>
            <div className='m-t-md'>
              {note.note}
            </div>
          </div>
        ))}
      </div>

      <Modal
        open={isModalOpen}
        onClose={() => toggleModal(false)}
      >
        <NoteForm
          loading={loading}
          onSubmit={onSubmit}
          onClose={() => toggleModal(false)}
          count
        />
      </Modal>
    </div>
  );
};

export default CountNotes;
