import React from 'react';

import Button from '../../shared/Button';

const ContactUs = ({ onClose }) => {
  return (
    <div className='contact-us__page'>
      <h1 className='font-bold m-b-lg'>Contact Us</h1>
      <div className='font-bold'>
        Please get in touch with us to complete on-boarding
      </div>

      <div className='contact-us__container'>
        <div>
          <div className='font-bold m-b-lg'>
            The case platform currently only available to pre-approved users!
          </div>
          <div className='font-bold m-b-lg'>
            Please reach out our team for a full demo, and to start on-boarding with the support from our experienced team.
          </div>

          <a
            href='https://opologic.com/contact-us'
            target='_blank'
            className='primary font-bold'
            rel='noreferrer'
          >
            opologic.com/contact-us
          </a>

          <div className='m-t-lg'>Email us:</div>
          <div className='font-bold'>hello@opologic.com</div>

          <div className='m-t-lg'>Call us:</div>
          <div className='font-bold'>+61 (0)2 8040 3698</div>
        </div>

        <div className='actions-container'>
          <Button
            type='submit'
            text='Close'
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
