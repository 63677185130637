import moment from 'moment';
import { orderBy } from 'lodash';
import { shadowColorAmounts } from '../constants';
import { lightenDarkenColor } from './utils';

const quarters = {
  1: ['Jan', 'Feb', 'Mar'],
  2: ['Apr', 'May', 'Jun'],
  3: ['Jul', 'Aug', 'Sep'],
  4: ['Oct', 'Nov', 'Dec']
};

const getCasesByMonth = (cases, index) => {
  const month = index < 9 ? `0${index + 1}` : `${index + 1}`;
  return cases.filter((item) => moment(item.date).isSame(`${moment(item.date).year()}-${month}`, 'month')).length;
};

const getCasesByDay = (cases, day) => {
  return cases.filter((item) => moment(item.date).isSame(day, 'day')).length;
};

const getCountsByMonth = (counts, index) => {
  const month = index < 9 ? `0${index + 1}` : `${index + 1}`;
  return counts.filter((item) => moment(item.completedAt).isSame(`${moment(item.completedAt).year()}-${month}`, 'month')).length;
};

const getCountsByDay = (counts, day) => {
  return counts.filter((item) => moment(item.completedAt).isSame(day, 'day')).length;
};

const getProformaValueByMonth = (cases, index) => {
  const month = index < 9 ? `0${index + 1}` : `${index + 1}`;
  return cases
    ?.filter((item) => moment(item.date).isSame(`${moment(item.date).year()}-${month}`, 'month') && !!item.orderClosed)
    ?.map((item) => item.proformaValue || 0)
    ?.reduce((a, b) => a + b, 0);
};

const getProformaValueByDay = (cases, day) => {
  return cases
    ?.filter((item) => moment(item.date).isSame(day, 'day') && !!item.orderClosed)
    ?.map((item) => item.proformaValue || 0)
    ?.reduce((a, b) => a + b, 0);
};

const prepareCasesDataMonthly = (cases) => {
  // const data = [];
  // const quarter = moment().quarter();
  // const filteredCases = cases.filter((item) => moment(item.date).utc().quarter() === quarter);

  const months = moment.months();
  const data = months.map((month, index) => {
    return {
      x: month,
      y: getCasesByMonth(cases, index),
    };
  });

  return [{ id: 'Month', data }];
};

const prepareCasesDataWeekly = (cases) => {
  const weekStart = moment().clone().startOf('isoWeek');
  const days = [];
  const weekCases = cases.filter((item) => moment(item.date).isSame(moment(), 'isoWeek'));

  for (let i = 0; i <= 6; i++) {
    days.push(moment(weekStart).add(i, 'days'));
  }

  const data = days.map((day) => ({
    id: day.format('dddd'),
    label: day.format('dddd').substring(0, 3),
    value: weekCases.filter((item) => moment(item.date).isSame(day, 'day')).length,
  }));

  return data;
};

const prepareCasesDataDaily = (cases, numberOfDays) => {
  const length = numberOfDays || moment(cases[0]?.date || new Date()).daysInMonth();
  const arr = [];
  for (let i = 0; i < length; i++) {
    arr?.push(i);
  }

  const data = arr?.map((day, index) => {
    return {
      x: `${day + 1}`,
      y: getCasesByDay(cases, moment(cases[0]?.date).startOf('month').add(index, 'days')),
    };
  });

  return [{ id: 'Day', data }];
};

const prepareForecastCases = (cases, isCompare, numberOfDays = 90) => {
  const arr = [];
  for (let i = 0; i < numberOfDays; i++) {
    arr?.push(i);
  }

  const data = arr?.map((day, index) => {
    const yearAgo = moment().subtract(1, 'year');
    return {
      x: `${day + 1}`,
      y: getCasesByDay(cases, (isCompare ? yearAgo : moment()).add(index + 1, 'days')),
    };
  });

  return [{ id: 'Day', data }];
}

const prepareCasesDataQuarterly = (cases, initialQuarter) => {
  const quarter = initialQuarter || moment(cases[0]?.date).quarter();
  const data = quarters[quarter]?.map((month, index) => {
    return {
      x: month,
      y: getCasesByMonth(cases, (quarter - 1) * 3 + index),
    };
  });

  return [{ id: 'Month', data }];
};

const prepareSurgeonsData = (cases, surgeons) => {
  const allSurgeons = surgeons
    .map((surgeon) => {
      const surgeonCases = cases.filter((item) => item.surgeon === surgeon.id);
      return {
        label: surgeon.lastName,
        id: `${surgeon.title} ${surgeon.firstName} ${surgeon.lastName}`,
        value: surgeonCases.length,
      };
    })
    .filter((surgeon) => surgeon.value !== 0);

  const sortedSurgeons = orderBy(allSurgeons, 'value', 'desc');
  const top10 = sortedSurgeons.splice(0, 10);

  return orderBy(top10, 'name', 'asc');
};

const prepareHospitalsData = (cases, hospitals) => {
  const allHospitals = hospitals
    .map((hospital) => {
      const hospitalCases = cases.filter((item) => item.hospital === hospital.id);

      const label = hospital.name
        .split(' ')
        .map((str) => str.substring(0, 1))
        .join('')
        .toUpperCase();

      return {
        id: hospital.name,
        label: label,
        value: hospitalCases.length,
      };
    })
    .filter((hospital) => hospital.value !== 0);

  const sortedHospitals = orderBy(allHospitals, 'value', 'desc');
  const top10 = sortedHospitals.splice(0, 10);

  return orderBy(top10, 'name', 'asc');
};

const prepareProceduresData = (cases, procedures) => {
  const allProcedures = procedures
    .map((procedure) => {
      const procedureCases = cases.filter((item) => item.procedure === procedure.id);
      return {
        id: procedure.name,
        label: procedure.name,
        value: procedureCases.length,
      };
    })
    .filter((procedure) => procedure.value !== 0);
  const sortedProcedures = orderBy(allProcedures, 'value', 'desc');
  const top10 = sortedProcedures.splice(0, 10);

  return orderBy(top10, 'name', 'asc');
};

const getTenantColors = (data, tenantColor) => {
  if (data.length <= 1) {
    return shadowColorAmounts['1'].map((amt) => lightenDarkenColor(tenantColor, amt));
  }

  if (data.length <= 3) {
    return shadowColorAmounts['3'].map((amt) => lightenDarkenColor(tenantColor, amt));
  }

  if (data.length <= 5) {
    return shadowColorAmounts['5'].map((amt) => lightenDarkenColor(tenantColor, amt));
  }

  if (data.length <= 7) {
    return shadowColorAmounts['7'].map((amt) => lightenDarkenColor(tenantColor, amt));
  }

  if (data.length <= 10) {
    return shadowColorAmounts['10'].map((amt) => lightenDarkenColor(tenantColor, amt));
  }
}

// Case Proforma Value

const prepareCasesProformaValueDaily = (cases, numberOfDays) => {
  const length = numberOfDays || moment(cases[0]?.date || new Date()).daysInMonth();
  const arr = [];
  for (let i = 0; i < length; i++) {
    arr?.push(i);
  }

  const data = arr?.map((day, index) => {
    return {
      x: `${day + 1}`,
      y: getProformaValueByDay(cases, moment(cases[0]?.date).startOf('month').add(index, 'days')),
    };
  });

  return [{ id: 'Day', data }];
};

const prepareCasesProformaValueMonthly = (cases) => {
  // const data = [];
  // const quarter = moment().quarter();
  // const filteredCases = cases.filter((item) => moment(item.date).utc().quarter() === quarter);

  const months = moment.months();
  const data = months.map((month, index) => {
    return {
      x: month,
      y: getProformaValueByMonth(cases, index),
    };
  });

  return [{ id: 'Month', data }];
};

const prepareCasesProformaValueWeekly = (cases) => {
  const weekStart = moment().clone().startOf('isoWeek');
  const days = [];
  const weekCases = cases.filter((item) => moment(item.date).isSame(moment(), 'isoWeek'));

  for (let i = 0; i <= 6; i++) {
    days.push(moment(weekStart).add(i, 'days'));
  }

  const data = days.map((day) => ({
    id: day.format('dddd'),
    label: day.format('dddd').substring(0, 3),
    value: weekCases
      ?.filter((item) => moment(item.date).isSame(day, 'day') && !!item.orderClosed)
      ?.map((item) => item.proformaValue || 0)
      ?.reduce((a, b) => a + b, 0)
  }));

  return data;
};

const prepareCasesProformaValueQuarterly = (cases, initialQuarter) => {
  const quarter = initialQuarter || moment(cases[0]?.date).quarter();
  const data = quarters[quarter]?.map((month, index) => {
    return {
      x: month,
      y: getProformaValueByMonth(cases, (quarter - 1) * 3 + index),
    };
  });

  return [{ id: 'Month', data }];
};

// Counts

const prepareCountsDataMonthly = (counts) => {
  const months = moment.months();
  const data = months.map((month, index) => {
    return {
      x: month,
      y: getCountsByMonth(counts, index),
    };
  });

  return [{ id: 'Month', data }];
};

const prepareCountsDataWeekly = (counts) => {
  const weekStart = moment().clone().startOf('isoWeek');
  const days = [];
  const weekCases = counts.filter((item) => moment(item.completedAt).isSame(moment(), 'isoWeek'));

  for (let i = 0; i <= 6; i++) {
    days.push(moment(weekStart).add(i, 'days'));
  }

  const data = days.map((day) => ({
    id: day.format('dddd'),
    label: day.format('dddd').substring(0, 3),
    value: weekCases.filter((item) => moment(item.completedAt).isSame(day, 'day')).length,
  }));

  return data;
};

const prepareCountsDataDaily = (counts, numberOfDays) => {
  const length = numberOfDays || moment(counts[0]?.completedAt || new Date()).daysInMonth();
  const arr = [];
  for (let i = 0; i < length; i++) {
    arr?.push(i);
  }

  const data = arr?.map((day, index) => {
    return {
      x: `${day + 1}`,
      y: getCountsByDay(counts, moment(counts[0]?.completedAt).startOf('month').add(index, 'days')),
    };
  });

  return [{ id: 'Day', data }];
};

const prepareCountsDataQuarterly = (counts, initialQuarter) => {
  const quarter = initialQuarter || moment(counts[0]?.completedAt).quarter();
  const data = quarters[quarter]?.map((month, index) => {
    return {
      x: month,
      y: getCountsByMonth(counts, (quarter - 1) * 3 + index),
    };
  });

  return [{ id: 'Month', data }];
};

export {
  prepareCasesDataMonthly,
  prepareCasesDataWeekly,
  prepareCasesDataDaily,
  prepareCasesDataQuarterly,
  prepareSurgeonsData,
  prepareHospitalsData,
  prepareProceduresData,
  prepareCasesProformaValueDaily,
  prepareCasesProformaValueMonthly,
  prepareCasesProformaValueWeekly,
  prepareCasesProformaValueQuarterly,
  getTenantColors,
  prepareCountsDataWeekly,
  prepareCountsDataDaily,
  prepareCountsDataMonthly,
  prepareCountsDataQuarterly,
  prepareForecastCases,
};
