import React, { useEffect, useState } from 'react';
import moment from 'moment';

import Switch from '@material-ui/core/Switch';

import DatePicker from '../shared/date-picker/DatePicker';
import Select from '../shared/Select';
import Button from '../shared/Button';
import Input from '../shared/Input';
import ModalSelectInput from './ModalSelectInput';
import ModalSelect from '../shared/modal-select/ModalSelect';
import UsersModal from '../shared/modal-select/UsersModal';

import { onPresetChange } from '../../utils/date';

import { presets } from '../../constants/analytics';

const presetOptions = [
  { label: 'This month', value: presets.THIS_MONTH },
  { label: 'This quarter', value: presets.THIS_QUARTER },
  { label: 'This year', value: presets.THIS_YEAR },
  { label: 'Last month', value: presets.LAST_MONTH },
  { label: 'Last quarter', value: presets.LAST_QUARTER },
  { label: 'Last year', value: presets.LAST_YEAR },
];

const ItemReportingForm = (props) => {
  const {
    surgeonsList = [],
    hospitalsList = [],
    onSubmit,
    loading,
  } = props;

  const [preset, setPreset] = useState(presets.THIS_MONTH);
  const [minDate, setMinDate] = useState(moment().startOf('month'));
  const [maxDate, setMaxDate] = useState(moment().endOf('month'));

  const [hospitals, setHospitals] = useState([]);
  const [surgeons, setSurgeons] = useState([]);
  const [ref, setRef] = useState('');
  const [lot, setLot] = useState('');

  const [hospitalsModalOpen, toggleHospitalsModal] = useState(false);
  const [surgeonsModalOpen, toggleSurgeonsModal] = useState(false);
  const [submittedDate, setSubmittedDate] = useState(false);

  useEffect(() => {
    onPresetChange(preset, { onMinDateChange: setMinDate, onMaxDateChange: setMaxDate });
  }, [preset]);

  const handleSubmit = () => {
    const data = {
      minDate,
      maxDate,
      hospitals,
      surgeons,
      ref,
      lot,
    };
    onSubmit(data, submittedDate);
  }

  const getHospitalNames = (hospitalIds) => {
    return hospitalIds.map((id) => {
      const hospital = hospitalsList.find((item) => item.id === id);
      return hospital ? hospital.name : '';
    })
  };

  const getSurgeonNames = (surgeonIds) => {
    return surgeonIds.map((id) => {
      const surgeon = surgeonsList.find((item) => item.id === id);
      return surgeon ? `${surgeon.title} ${surgeon.firstName} ${surgeon.lastName}` : '';
    })
  };

  return (
    <div className='usage-reporting__container'>
      <div className='usage-reporting-form'>
        <div>
          <div className='m-b-lg'>
            <div className='field__title'>Hospitals</div>
            <ModalSelectInput
              onClick={() => toggleHospitalsModal(true)}
              values={getHospitalNames(hospitals)}
            />
            <ModalSelect
              title='Filter by Hospital'
              listTitle='Hospitals'
              options={hospitalsList}
              onChange={setHospitals}
              selected={hospitals}
              onClose={() => toggleHospitalsModal(false)}
              isOpen={hospitalsModalOpen}
              extraField='state'
            />
          </div>
          <div>
            <div className='field__title'>Surgeons</div>
            <ModalSelectInput
              onClick={() => toggleSurgeonsModal(true)}
              values={getSurgeonNames(surgeons)}
            />
            <UsersModal
              title='Filter by Surgeon'
              onChange={setSurgeons}
              selected={surgeons}
              onClose={() => toggleSurgeonsModal(false)}
              isOpen={surgeonsModalOpen}
              listTitle='Surgeons'
              users={surgeonsList}
              idField='id'
              searchPlaceholder='Search Surgeons'
            />
          </div>
        </div>
        <div>
          <div className='m-b-lg'>
            <div className='field__title'>Item Reference</div>
            <Input
              type='text'
              placeholder='Filter by Reference'
              value={ref}
              onChange={(e) => setRef(e.target.value)}
            />
          </div>
          <div>
            <div className='field__title'>LOT Code</div>
            <Input
              type='text'
              placeholder='Filter by LOT Code'
              value={lot}
              onChange={(e) => setLot(e.target.value)}
            />
          </div>
        </div>
        <div>
          <div className='field__title'>Date Range</div>
          <div className='case-date-range'>
            <div className='d-flex'>
              <div className='secondary' style={{ width: 40 }}>From</div>
              <DatePicker
                date={minDate}
                onDateChange={(date) => {
                  setMinDate(date);
                  setPreset(null);
                }}
                maxDate={maxDate}
                withoutStyles
              />
            </div>

            <div className='d-flex'>
              <div className='secondary' style={{ width: 40 }}>To</div>
              <DatePicker
                date={maxDate}
                onDateChange={(date) => {
                  setMaxDate(date);
                  setPreset(null);
                }}
                minDate={minDate}
                withoutStyles
              />
            </div>
          </div>

          <div className='m-t-lg'>
            <Select
              value={preset}
              onChange={setPreset}
              options={presetOptions}
            />
          </div>
        </div>
      </div>
      <div className='usage-reporting-actions'>
        <div className='d-flex m-r-md'>
          <span className='secondary'>Report against submitted date</span>
          <Switch
            color='primary'
            disableRipple
            checked={submittedDate}
            onChange={(e) => setSubmittedDate(e.target.checked)}
          />
        </div>

        <Button
          type='submit'
          text='Run Report'
          onClick={handleSubmit}
          disabled={loading}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default ItemReportingForm;
