import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Tooltip } from '@material-ui/core';

import Modal from '../../shared/modal';

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: '12px',
    padding: '4px 8px',
    borderRadius: 8
  }
}));

const SubmitModal = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    sections = [],
    loading,
    tenantColor,
  } = props;

  const classes = useStyles();

  const list = useMemo(() => {
    const result = [];

    sections?.forEach((section) => {
      section?.items?.forEach((item) => {
        if (item?.count !== item?.defaultValue) {
          result?.push(item);
        }
      });
    });

    return result;
  }, [sections]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      size='lg'
      title='Update Count Items'
      fullWidth
      onSubmit={onSubmit}
      closeButton='Close'
      submitButton='Confirm'
      loading={loading}
      disabled={loading}
    >
      <div className='submit-modal__container'>
        <>
          <div className='secondary font-bold m-b-md'>
            Quantity Changes
          </div>

          <div className='m-b-md form__select-users'>
            <table>
              <thead>
              <tr style={{ borderBottom: '1px solid #eeeeee' }}>
                <th className='uppercase secondary p-b-sm'>Code</th>
                <th className='uppercase secondary p-b-sm'>Description</th>
                <th className='uppercase secondary text-center p-b-sm'>From</th>
                <th className='uppercase secondary text-center p-b-sm' style={{ color: tenantColor }}>To</th>
              </tr>
              </thead>
              {!!list?.length ? (
                <tbody>
                {list.map((item) => (
                  <tr className='' key={item.code}>
                    <td className='font-bold p-t-sm'>
                      {item.code}
                    </td>
                    <td className='font-bold p-t-sm'>
                      {item?.description?.length > 50 ? (
                        <Tooltip
                          title={item?.description}
                          aria-label='item-description'
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <div
                            style={{
                              width: 500,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            {item.description}
                          </div>
                        </Tooltip>
                      ) : item.description}
                    </td>
                    <td className='font-bold uppercase text-center p-t-sm'>
                      {item.defaultValue}
                    </td>
                    <td className='font-bold uppercase text-center p-t-sm' style={{ color: tenantColor }}>
                      {item.count}
                    </td>
                  </tr>
                ))}
                </tbody>
              ) : (
                <tbody>
                <tr>
                  <td colSpan={4}>
                    <div className='secondary text-center p-t-md'>No calculated usage</div>
                  </td>
                </tr>
                </tbody>
              )}
            </table>
          </div>
        </>
      </div>
    </Modal>
  );
};

export default SubmitModal;
