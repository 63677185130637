import React, { useMemo } from 'react';

import { UsageImplantsForm } from './form';
import Modal from '../../shared/modal';

const findValue = (usage, label) => {
  if (!usage || !usage.values || !label) {
    return '';
  }

  const value = usage.values.find((v) => v.label === label);
  return value ? value.value : '';
};

const UpdateUsageModal = ({ open, onClose, onSubmit, loading, usage, onDelete, title = 'Update Usage', tags = [] }) => {
  const initialValues = useMemo(() => {
    const gtin = findValue(usage, 'GTIN');
    const ref = findValue(usage, 'REF');
    const lot = findValue(usage, 'LOT');
    const exp = findValue(usage, 'EXP');
    const desc = findValue(usage, 'DESC');

    return { ...usage, ref, lot, exp, gtin, desc };
  }, [open, usage]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      fullWidth
    >
      <div className='case-usage-modal__container'>
        <UsageImplantsForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          loading={loading}
          onClose={onClose}
          onDelete={() => {
            onDelete(usage.id);
            onClose();
          }}
          tags={tags}
      />
      </div>
    </Modal>
  );
};

export default UpdateUsageModal;
