import React, { useMemo } from 'react';

import WarningIcon from '@material-ui/icons/Error';
import { Tooltip } from '@material-ui/core';

import Modal from '../shared/modal';
import Button from '../shared/Button';

import { itemTypeOptions, setAllocationStatuses } from '../../constants/enums';

import '../shared/modal/modal.scss';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: '14px',
    padding: '4px 8px',
    borderRadius: 6,
  },
}));

const ConfirmUploadModal = (props) => {
  const { open, onSubmit, onClose, loading, failedItems = [], items = [] } = props;

  const classes = useStyles();

  const duplicateItems = useMemo(() => {
    const result = [];
    failedItems?.forEach((item) => {
      if (items?.find((i) => item && `${item[0]}` === `${i.code}`)) {
        result?.push(item);
      }
    });
    return result;
  }, [items, failedItems]);

  const itemsWithoutType = useMemo(() => {
    const result = [];
    failedItems?.forEach((item) => {
      if (!item[2]) {
        result?.push(item);
      }
    });
    return result;
  }, [items, failedItems]);

  const itemsWithWrongType = useMemo(() => {
    const result = [];
    failedItems?.forEach((item) => {
      if (!Object.values(itemTypeOptions)?.map((t) => t?.label?.toLowerCase())?.includes(item[2]?.toLowerCase())) {
        result?.push(item);
      }
    });
    return result;
  }, [items, failedItems]);

  return (
    <Modal open={open} onClose={onClose} size="lg">
      <div className="confirmation-modal__container">
        <div>
          <div className="modal-title">
            Some items have issues while uploading
          </div>

          {!!duplicateItems.length && (
            <>
              <div className="modal-content" style={{marginBottom: 16}}>
                <div className="d-flex">
                  <WarningIcon style={{color: setAllocationStatuses.ASSIGNED.color, marginRight: 4}}/>
                  <div style={{color: setAllocationStatuses.ASSIGNED.color, fontWeight: 'bold'}}>
                    You are attempting to add items that already exist
                  </div>
                </div>
                <div>Do you want to proceed with the upload of only non-duplicates or cancel the upload?</div>
              </div>

              <div className="m-t-lg m-b-lg">
                <div className="font-bold">Duplicate Items</div>
                <div className="m-t-md">
                  {duplicateItems?.map((item) => (
                    <div key={Math.random()} className="m-t-sm">
                      {`${item[0]}${item[1] ? ` (${item[1]})` : ''}`}
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

          {!!itemsWithoutType?.length && (
            <>
              <div className="modal-content" style={{marginBottom: 16}}>
                <div className="d-flex">
                  <WarningIcon style={{color: setAllocationStatuses.ASSIGNED.color, marginRight: 4}}/>
                  <div style={{color: setAllocationStatuses.ASSIGNED.color, fontWeight: 'bold'}}>
                    Some items have no type
                  </div>
                </div>
                <div>All items must have a type assigned to them, so please update the sheet accordingly and attempt the
                  upload again.
                </div>
              </div>
              <div className="m-t-md m-b-lg">
                <div className="font-bold">Failed Items</div>
                <div className="m-t-md">
                  {itemsWithoutType?.map((item) => (
                    <div key={Math.random()} className="m-t-sm">
                      {`${item[0]}${item[1] ? ` (${item[1]})` : ''}`}
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

          {!!itemsWithWrongType?.length && (
            <>
              <div className="modal-content" style={{marginBottom: 16}}>
                <div className="d-flex">
                  <WarningIcon style={{color: setAllocationStatuses.ASSIGNED.color, marginRight: 4}}/>
                  <div style={{color: setAllocationStatuses.ASSIGNED.color, fontWeight: 'bold'}}>
                    Some items have wrong type
                  </div>
                </div>
                <div className="d-flex m-b-lg">
                  <span>
                    Item type must be one of the following values  -
                    <Tooltip
                      title={Object.values(itemTypeOptions)?.map((i) => i?.label)?.join(', ')}
                      aria-label="types-tooltip"
                      classes={{tooltip: classes.tooltip}}
                    >
                    <span className="font-bold m-l-sm" style={{fontSize: 14, textDecoration: 'underline'}}>
                      view here
                    </span>
                    </Tooltip>
                  </span>
                </div>
              </div>
              <div className="m-t-md m-b-lg">
                <div className="font-bold">Failed Items</div>
                <div className="m-t-md">
                  {itemsWithWrongType?.map((item) => (
                    <div key={Math.random()} className="m-t-sm">
                      {`${item[0]}${item[1] ? ` (${item[1]})` : ''}`}
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

          <div className="form__actions">
            <Button
              type="submit"
              text="Upload"
              onClick={onSubmit}
              disabled={loading || !!itemsWithoutType.length}
              loading={loading}
            />
            <Button
              type="cancel"
              text="Cancel"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmUploadModal;
