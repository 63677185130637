import {
  GET_SHIPPING_CASES,
  SET_SHIPPING_CASES,
  SET_SHIPPING_ORDER,
  SET_SHIPPING_ORDER_BY,
  SET_SHIPPING_PAGE,
  SET_SHIPPING_ROWS_PER_PAGE,
  SET_SHIPPING_SEARCH,
  SET_SHIPPING_ACTIVE_TAB,
} from '../actions/actionTypes';

const initialState = {
  list: [],
  isLoading: false,
  isLoaded: false,
  order: '',
  orderBy: '',
  page: 0,
  rowsPerPage: 10,
  search: '',
  activeTab: 0,
};

const shippingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SHIPPING_CASES:
      return { ...state, isLoading: true };
    case SET_SHIPPING_CASES:
      return {
        ...state,
        list: action.cases,
        isLoaded: true,
        isLoading: false
      };
    case SET_SHIPPING_ORDER:
      return {
        ...state,
        order: action.value,
      };
    case SET_SHIPPING_ORDER_BY:
      return {
        ...state,
        orderBy: action.value,
      };
    case SET_SHIPPING_PAGE:
      return {
        ...state,
        page: action.value,
      };
    case SET_SHIPPING_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.value,
      };
    case SET_SHIPPING_SEARCH:
      return {
        ...state,
        search: action.value,
      };
    case SET_SHIPPING_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.value,
      };
    default:
      return state;
  }
};

export default shippingReducer;
