import { sortBy, orderBy } from 'lodash';

import { GET_KITS, SET_KITS, GET_SECTIONS, SET_SECTIONS, SET_MANUFACTURERS } from './actionTypes';

import firebase, { collections, storageRefs } from '../firebase';
import { getFile, getFileWithParameters } from '../utils/api';
import urls from '../constants/urls';
import { setPositionOptions } from '../constants/enums';

export const setKits = kits => ({ type: SET_KITS, kits });

export const setManufacturers = manufacturers => ({ type: SET_MANUFACTURERS, manufacturers });

export const getKits = (tenant, withReducer) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = tenant || state?.tenant?.currentTenant?.id;
  const path = collections.KITS_COLLECTION(tenantId);

  dispatch({ type: GET_KITS });

  const snapshot = await firebase.db.collection(path).get();
  const kits = snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));

  if (tenant && !withReducer) {
    return orderBy(kits, 'kitId', 'asc');
  }

  return dispatch(setKits(orderBy(kits, 'kitId', 'asc')));
};

export const getKit = (id) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.KITS_COLLECTION(tenantId);

  const doc = await firebase.db.collection(path).doc(id).get();
  const data = doc?.data();
  return {
    id: doc?.exists ? doc?.id : null,
    ...data,
  };
};

export const createKit = (kitData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.KITS_COLLECTION(tenantId);

  const doc = {
    ...kitData,
    active: true,
    products: kitData.products || [],
  };

  if (tenantId) {
    await firebase.db.collection(path).add(doc);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateKit = (kitId, kitData, logo) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.KITS_COLLECTION(tenantId);
  const storageRef = firebase.storage.ref();

  if (logo) {
    const storagePath = storageRefs.KITS_REF(tenantId, kitId);
    const filePath = `${storagePath}/${logo.name}`;
    const ref = storageRef.child(filePath);
    const snap = await ref.put(logo);
    const downloadUrl = await snap?.ref?.getDownloadURL();
    const logoObj = {
      fileName: logo.name,
      path: filePath,
      downloadUrl
    };

    await firebase.db.collection(path).doc(kitId).set({ logo: logoObj }, { merge: true });
  }

  if (tenantId) {
    await firebase.db.collection(path).doc(kitId).set(kitData, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteKit = (kitId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.KITS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(kitId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const bulkDeleteKits = (kits) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.KITS_COLLECTION(tenantId);
  const batch = firebase.db.batch();

  if (tenantId) {
    kits.forEach((kitId) => {
      const ref = firebase.db.collection(path).doc(kitId);
      batch.delete(ref);
    });

    await batch.commit();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const uploadKits = (kits) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.KITS_COLLECTION(tenantId);

  if (tenantId) {
    const promises = [];

    kits.forEach((kit) => {
      const name = kit[0] || '';
      const kitId = kit[1] || '';
      const type = kit[2] ? kit[2]?.toUpperCase() : '';

      const doc = {
        name,
        kitId,
        type,
        allowQuantity: false,
        active: true,
        products: [],
      };

      promises.push(firebase.db.collection(path).add(doc));
    });

    await Promise.all(promises);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const getManufacturers = (tenant, withReducer) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = tenant || state?.tenant?.currentTenant?.id;
  const path = collections.MANUFACTURERS_COLLECTION(tenantId);

  const snapshot = await firebase.db.collection(path).get();
  const manufacturers = snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));

  return dispatch(setManufacturers(manufacturers));
};

export const addManufacturer = (manufacturer) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.MANUFACTURERS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).add({ name: manufacturer });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateManufacturer = (manufacturerId, manufacturer) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.MANUFACTURERS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(manufacturerId).set({ name: manufacturer }, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteManufacturer = (manufacturerId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.MANUFACTURERS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(manufacturerId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

// Sections

export const setSections = (sections) => ({ type: SET_SECTIONS, sections });

export const getSections = (kitId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.BILL_OF_MATERIAL_COLLECTION(tenantId, kitId);

  dispatch({ type: GET_SECTIONS });

  const snapshot = await firebase.db.collection(path).get();
  const sections = snapshot.docs?.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));

  const sortedSections = sortBy(sections, ['orderNumber']);

  dispatch(setSections(sortedSections));
  return sortedSections;
};

export const createSection = (kitId, sectionData, image) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.BILL_OF_MATERIAL_COLLECTION(tenantId, kitId);
  const storageRef = firebase.storage.ref();

  const doc = {
    ...sectionData,
    active: true,
  };

  if (tenantId) {
    const docRef = await firebase.db.collection(path).add(doc);
    const sectionId = docRef.id;
    let imageObj = null;

    if (image && docRef) {
      const storagePath = storageRefs.BILL_OF_MATERIAL_REF(tenantId, kitId, sectionId);
      const filePath = `${storagePath}/${image.name}`;
      const ref = storageRef.child(filePath);
      const snap = await ref.put(image);
      const downloadUrl = await snap?.ref?.getDownloadURL();
      imageObj = {
        fileName: image.name,
        path: filePath,
        downloadUrl
      };

      await firebase.db.collection(path).doc(sectionId).set({ image: imageObj }, { merge: true });
    }

    return {
      sectionId,
      imageObj,
    };
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateSection = (kitId, sectionId, sectionData, image) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.BILL_OF_MATERIAL_COLLECTION(tenantId, kitId);
  const storageRef = firebase.storage.ref();
  let imageObj = null

  if (tenantId) {
    const doc = { ...sectionData };

    if (!sectionData?.image) {
      doc.image = null;
    }

    if (image) {
      const storagePath = storageRefs.BILL_OF_MATERIAL_REF(tenantId, kitId, sectionId);
      const filePath = `${storagePath}/${image.name}`;
      const ref = storageRef.child(filePath);
      const snap = await ref.put(image);
      const downloadUrl = await snap?.ref?.getDownloadURL();
      imageObj = {
        fileName: image.name,
        path: filePath,
        downloadUrl
      };

      doc.image = imageObj;
    }

    await firebase.db.collection(path).doc(sectionId).set(doc, { merge: true });
    return imageObj;
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteSection = (kitId, sectionId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.BILL_OF_MATERIAL_COLLECTION(tenantId, kitId);

  if (tenantId) {
    await firebase.db.collection(path).doc(sectionId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const getSectionItems = (kitId, sectionId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.SECTION_ITEMS_COLLECTION(tenantId, kitId, sectionId);

  let query = await firebase.db.collection(path);
  const snapshot = await query?.get();
  const items = snapshot?.docs.map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
    };
  });

  return items;
};

export const uploadItems = (kitId, section, items) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.BILL_OF_MATERIAL_COLLECTION(tenantId, kitId);
  const sectionItems = section?.items || [];
  const result = [
    ...sectionItems,
    ...items?.filter((item) => !sectionItems?.find((i) => i.id === item.id))
  ];

  if (tenantId) {
    await firebase.db.collection(path).doc(section.id).set({ items: result }, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const generateBOMPdf = (kitId, name) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();

  try {
    await getFile(urls.kits.exportBOM(kitId, tenantId), idToken, `${name} - BOM`, 'pdf');
  } catch (err) {
    throw new Error(err);
  }
};

export const uploadBOM = (kitId, sections) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const items = state.items.list || [];
  const path = collections.BILL_OF_MATERIAL_COLLECTION(tenantId, kitId);

  if (!tenantId) {
    throw new Error('Invalid tenant')
  }

  for (const section of sections) {
    const doc = {
      active: true,
      ...section,
      items: section?.items?.map((item) => {
        const bomItem = items?.find((i) => i.code === item.code);
        return { id: bomItem.id || null, ref: item.ref || '', quantity: item.quantity || 0 }
      })?.filter((item) => !!item.id)
    };
    await firebase.db.collection(path).add(doc);
  }
};

// Subscriptions
export const subscribeToKits = () => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.KITS_COLLECTION(tenantId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: async (querySnapshot) => {
        let kits = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          kits = [...kits, {
            ...documentSnapshot.data(),
            id,
          }];
        });

        kits = await Promise.all(kits?.map(async (kit) => {
          const snap = await firebase.db.collection(collections.BILL_OF_MATERIAL_COLLECTION(tenantId, kit.id)).get();
          const sections = snap?.docs.map(doc => {
            const data = doc.data();
            return {
              ...data,
              id: doc.id,
            };
          });

          return {
            ...kit,
            sections: sections || [],
          }
        }));

        return dispatch(setKits(kits));
      },
    });
};

export const subscribeToSections = (kitId, cb) => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.BILL_OF_MATERIAL_COLLECTION(tenantId, kitId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let sections = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;

          sections = [...sections, {
            ...documentSnapshot.data(),
            id,
          }];
        });

        return cb(sortBy(sections, ['orderNumber']));
      },
    });
};

export const exportKitsReport = (kits) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();

  const body = {
    kits: orderBy(kits, 'shippingDate', 'desc')
  };

  try {
    await getFileWithParameters(urls.cases.exportKitsReport(tenantId), idToken, 'Kits report', 'xlsx', body);
  } catch (err) {
    throw new Error(err);
  }
};

export const getBOMItemLookupReport = (item) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const kits = state?.kits?.list || [];
  const sets = state?.sets?.list || [];

  const data = [];
  const kitsWithBOM = await Promise.all([...kits]?.map(async (kit) => {
    const path = collections.BILL_OF_MATERIAL_COLLECTION(tenantId, kit.id);

    const snapshot = await firebase.db.collection(path).get();
    const billOfMaterial = snapshot.docs?.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

    return {
      ...kit,
      billOfMaterial
    };
  }));

  sets?.forEach((set) => {
    const kit = kitsWithBOM?.find((k) => k.id === set?.kit);
    if (kit && kit?.billOfMaterial?.length && kit?.billOfMaterial?.find((section) => section?.items?.find((i) => i.id === item.id))) {
      const section = kit?.billOfMaterial?.find((section) => section?.items?.find((i) => i.id === item.id));
      const _item = section?.items?.find((i) => i.id === item.id);
      let quantity = _item.quantity || 1;

      if (!set.caseAllocation && set.itemsQuantity && set.itemsQuantity[item.id] !== undefined) {
        quantity = set.itemsQuantity[item.id];
      }

      let status = setPositionOptions[set.status]?.value || setPositionOptions.AVAILABLE.value;

      if (!!set.caseAllocation) {
        status = setPositionOptions.ALLOCATED.value;
      }
      if (!!set.consigned) {
        status = setPositionOptions.CONSIGNED.value;
      }
      if (!!set.quarantined) {
        status = setPositionOptions.QUARANTINED.value;
      }

      data?.push({
        itemCode: item?.code || '',
        itemDescription: item?.description || '',
        kitName: kit?.name || '',
        kitId: kit?.kitId || '',
        setNumber: set?.number || '',
        quantity,
        status,
      });
    }
  });

  return data;
};

export const subscribeToManufacturers = () => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.MANUFACTURERS_COLLECTION(tenantId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let manufacturers = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          manufacturers = [...manufacturers, {
            ...documentSnapshot.data(),
            id,
          }];
        });

        return dispatch(setManufacturers(orderBy(manufacturers, 'name', 'asc')));
      }
    });
};
