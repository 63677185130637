import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import ExportIcon from '../../../assets/icons/DownloadIcon';
import SetIcon from '../../../assets/icons/SetIcon';

import Table from '../../shared/table/Table';
import ActionButton from '../../shared/ActionButton';

import { exportReporting } from '../../../actions/setsActions';

import { setAllocationStatuses } from '../../../constants/enums';

const columns = [
  { title: 'set number', field: 'setNumber', type: 'default', sort: true },
  { title: 'kit id', field: 'kitId', type: 'default', sort: true },
  { title: 'kit name', field: 'kitName', type: 'default', sort: true },
  { title: 'labels', field: 'labelNames', type: 'default', sort: true },
  { title: 'life-time turns', field: 'turns', type: 'default', sort: true },
  { title: 'avg. turns / month', field: 'average', type: 'default', sort: true },
  {
    title: 'performance',
    field: 'performance',
    type: 'custom',
    sort: true,
    formatter: (value) => {
      return value ? (
        <div style={{ height: 16, width: 16, borderRadius: '50%', background: setAllocationStatuses.SHIPPED.color }} />
      ) : (
        <div style={{ height: 16, width: 16, borderRadius: '50%', background: setAllocationStatuses.QUARANTINED.color }} />
      )
    },
  },
];

const SetTurnsTable = (props) => {
  const { data = [], labels = [] } = props;

  const dispatch = useDispatch();

  const [exportLoading, setExportLoading] = useState(false);

  const getLabelNames = useCallback((labelIds) => {
    if (!labelIds) {
      return [];
    }

    return labelIds.map((id) => {
      const label = labels?.find((item) => item.id === id);
      return label ? label.name : '';
    });
  }, [data, labels]);

  const onExportClick = async () => {
    const body = data?.map((item) => ({
      ...item,
      labelNames: getLabelNames(item.labels),
    }));

    setExportLoading(true);
    await dispatch(exportReporting(body));
    setExportLoading(false);
  };

  return (
    <div className='cases-table__container'>
      <div className='d-flex m-b-lg space-between align-start'>
        <div className='d-flex'>
          <SetIcon />
          <div className='font-size-bg font-bold m-l-md'>
            Set Turns
          </div>
        </div>

        <ActionButton
          onClick={onExportClick}
          bgColor={setAllocationStatuses.SHIPPED.color}
          disabled={exportLoading}
          loading={exportLoading}
        >
          <ExportIcon color="#ffffff" />
          Export
        </ActionButton>
      </div>

      <Table
        rows={data?.map((item) => ({
          ...item,
          labelNames: getLabelNames(item.labels),
        }))}
        columns={columns}
        pagination
        checkboxes={false}
        withoutCheck
        rowColor='#ffffff'
        rowBorderColor='#fafafa'
      />
    </div>
  );
};

export default SetTurnsTable;
