import { enums, countries } from '../constants';
import { roleNames } from '../constants/userRoles';

const { statusOptions } = enums;

const filterUsers = (user, searchState, activeState, activeRole) => {
  let stateFilter = true;
  let roleFilter = true;

  if (activeState === statusOptions.active) {
    stateFilter = !!user.active;
  }
  if (activeState === statusOptions.deactivated) {
    stateFilter = !user.active;
  }

  if (Object.values(roleNames).includes(activeRole)) {
    roleFilter = user.role === activeRole;
  }

  return user.displayName?.toLowerCase().includes(searchState.toLowerCase()) && stateFilter && roleFilter;
};

const filterGroups = (group, searchState, activeStatus) => {
  let stateFilter = true;

  if (activeStatus === statusOptions.active) {
    stateFilter = !!group.active;
  }
  if (activeStatus === statusOptions.deactivated) {
    stateFilter = !group.active;
  }

  return group.name?.toLowerCase().includes(searchState.toLowerCase()) && stateFilter;
};

const filterProcedures = (procedure, searchState, activeStatus, activeOption) => {
  let stateFilter = true;
  let optionFilter = true;

  if (activeStatus === statusOptions.active) {
    stateFilter = !!procedure.active;
  }
  if (activeStatus === statusOptions.deactivated) {
    stateFilter = !procedure.active;
  }

  return procedure.name?.toLowerCase().includes(searchState.toLowerCase()) && stateFilter && optionFilter;
};

const filterHospitals = (hospital, searchState, activeStatus, activeCountry) => {
  let stateFilter = true;
  let countryFilter = true;

  if (activeStatus === statusOptions.active) {
    stateFilter = !!hospital.active;
  }
  if (activeStatus === statusOptions.deactivated) {
    stateFilter = !hospital.active;
  }

  if (countries.map((country) => country.label).includes(activeCountry)) {
    countryFilter = hospital.country === activeCountry;
  }
  return hospital.name?.toLowerCase().includes(searchState.toLowerCase()) && stateFilter && countryFilter;
};

const filterProducts = (product, searchState, activeStatus) => {
  let stateFilter = true;

  if (activeStatus === statusOptions.active) {
    stateFilter = !!product.active;
  }
  if (activeStatus === statusOptions.deactivated) {
    stateFilter = !product.active;
  }

  return product.name?.toLowerCase().includes(searchState.toLowerCase()) && stateFilter;
};

const filterTags = (tag, searchState, activeStatus) => {
  let stateFilter = true;

  if (activeStatus === statusOptions.active) {
    stateFilter = !!tag.active;
  }
  if (activeStatus === statusOptions.deactivated) {
    stateFilter = !tag.active;
  }

  return tag.name?.toLowerCase().includes(searchState.toLowerCase()) && stateFilter;
};

const filterTerritories = (territory, searchState, activeStatus) => {
  let stateFilter = true;

  if (activeStatus === statusOptions.active) {
    stateFilter = !!territory.active;
  }
  if (activeStatus === statusOptions.deactivated) {
    stateFilter = !territory.active;
  }

  return territory.name?.toLowerCase().includes(searchState.toLowerCase()) && stateFilter;
};

const filterKits = (kit, searchState, activeStatus) => {
  let stateFilter = true;

  if (activeStatus === statusOptions.active) {
    stateFilter = !!kit.active;
  }
  if (activeStatus === statusOptions.deactivated) {
    stateFilter = !kit.active;
  }

  return (kit.name?.toLowerCase().includes(searchState.toLowerCase())
    || kit.kitId?.toLowerCase().includes(searchState.toLowerCase()))
    && stateFilter;
};

const filterSurgeons = (surgeon, searchState, activeStatus) => {
  let stateFilter = true;

  if (activeStatus === statusOptions.active) {
    stateFilter = !!surgeon.active;
  }
  if (activeStatus === statusOptions.deactivated) {
    stateFilter = !surgeon.active;
  }

  return (surgeon.firstName?.toLowerCase().includes(searchState.toLowerCase())
    || surgeon.lastName?.toLowerCase().includes(searchState.toLowerCase()))
    && stateFilter;
};

const filterSets = (set, searchState, activeStatus) => {
  let stateFilter = true;

  if (activeStatus === statusOptions.active) {
    stateFilter = !!set.active;
  }
  if (activeStatus === statusOptions.deactivated) {
    stateFilter = !set.active;
  }

  return set.number?.toLowerCase().includes(searchState.toLowerCase()) && stateFilter;
};

const getCommaSeparatedNames = (array, ids) => {
  if (!ids) {
    return '';
  }

  const names = ids.map((id) => array.find((item) => item.id === id)?.name);

  return names?.filter((name) => !!name)?.join(', ');
};

const filterGtins = (gtin, searchState, activeStatus, classificationStatus) => {
  let stateFilter = true;

  if (activeStatus === statusOptions.active) {
    stateFilter = !!gtin.active;
  }
  if (activeStatus === statusOptions.deactivated) {
    stateFilter = !gtin.active;
  }

  return (gtin.gtin?.toLowerCase().includes(searchState.toLowerCase())
    || gtin.reference?.toLowerCase().includes(searchState.toLowerCase()))
    && (gtin.classification === classificationStatus || !classificationStatus)
    && stateFilter;
};

const filterRepeatingCounts = (count, searchState, activeStatus) => {
  let stateFilter = true;

  if (activeStatus === statusOptions.active) {
    stateFilter = !!count.active;
  }
  if (activeStatus === statusOptions.deactivated) {
    stateFilter = !count.active;
  }

  return count.name?.toLowerCase().includes(searchState.toLowerCase()) && stateFilter;
};

const filterProductTriggers = (trigger, searchState, activeStatus) => {
  let stateFilter = true;

  if (activeStatus === statusOptions.active) {
    stateFilter = !!trigger.active;
  }
  if (activeStatus === statusOptions.deactivated) {
    stateFilter = !trigger.active;
  }

  return trigger.emails?.some((email) => email.toLowerCase().includes(searchState.toLowerCase())) && stateFilter;
};

const filterItems = (item, searchState, activeStatus) => {
  let stateFilter = true;

  if (activeStatus === statusOptions.active) {
    stateFilter = !!item.active;
  }
  if (activeStatus === statusOptions.deactivated) {
    stateFilter = !item.active;
  }

  return stateFilter && (item?.code?.toLowerCase().includes(searchState?.toLowerCase()) || item?.description?.toLowerCase().includes(searchState?.toLowerCase()));
};

const filterCustomerSurgeons = (surgeon, searchState, activeProcedure) => {
  let procedureFilter = true;

  if (!!activeProcedure) {
    procedureFilter = surgeon.procedures?.includes(activeProcedure);
  }

  return surgeon?.lastName?.toLowerCase().includes(searchState?.toLowerCase()) && procedureFilter;
};

const filterTasks = (item, searchState, activeSurgeon) => {
  let surgeonFilter = true;

  if (!!activeSurgeon) {
    surgeonFilter = item.surgeonId === activeSurgeon;
  }

  return item?.title?.toLowerCase().includes(searchState?.toLowerCase()) && surgeonFilter;
}

export {
  filterUsers,
  filterGroups,
  filterProcedures,
  filterHospitals,
  filterProducts,
  filterKits,
  filterSurgeons,
  filterSets,
  filterGtins,
  filterProductTriggers,
  filterItems,
  filterTasks,
  filterTags,
  filterCustomerSurgeons,
  getCommaSeparatedNames,
  filterRepeatingCounts,
  filterTerritories,
}
