import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Modal from '../../shared/modal';
import Button from '../../shared/Button';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

const BatchInboundModal = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    loading,
    item,
    batchItems = [],
    batchInbound = [],
    tenantColor
  } = props;

  const [batchState, setBatchState] =  useState({});

  useEffect(() => {
    if (open) {
      const state = { ...batchState };
      batchItems?.forEach((i) => {
        state[i.id] = batchInbound?.includes(i.id) ? 0 : 1;
      });
      setBatchState(state);
    }
  }, [open]);

  const onMinusClick = (id) => {
    setBatchState({ ...batchState, [id]: batchState[id] - 1 });
  };

  const onPlusClick = (id) => {
    setBatchState({ ...batchState, [id]: batchState[id] + 1 });
  };

  const handleSubmit = () => {
    const arr = [];
    Object.keys(batchState).forEach((id) => {
      if (!batchState[id]) {
        arr.push(id);
      }
    })
    onSubmit(arr);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Edit Inbound'
      fullWidth
      submitButton='Save'
      closeButton='Cancel'
      onSubmit={handleSubmit}
      loading={loading}
      disabled={loading}
    >
      <div className='quarantine-modal__container'>
        <div className='secondary'>Select inbound items by batch information:</div>
        <div className='font-bold m-t-md'>{`${item.code} (x${item.quantity})`}</div>
        <div className='m-t-sm'>{item.checklistDescription || item.description}</div>
        <div className='m-t-lg'>
          <div className='d-flex m-b-md'>
            <div className='flex-1 secondary font-bold m-r-md'>BATCH CODE</div>
            <div className='flex-1 secondary font-bold m-l-md m-r-md'>EXPIRY DATE</div>
            <div className='flex-1 secondary font-bold text-right'>
              <span style={{ marginRight: '52px' }}>IN</span>
            </div>
          </div>
          <div className='quarantine-items__container p-t-lg' style={{ minHeight: 'auto' }}>
            {Object.keys(batchState)?.map((id) => (
              <div className='d-flex m-b-md' key={id}>
                <div className='flex-1 m-r-md'>
                  {batchItems?.find((i) => i.id === id)?.code}
                </div>
                <div className='flex-1 m-l-md m-r-md'>
                  {batchItems?.find((i) => i.id === id)?.expDate ? moment(batchItems?.find((i) => i.id === id)?.expDate)?.format('yyyy/MM/DD') : 'TBD'}
                </div>
                <div className='flex-1 m-l-md d-flex flex-end'>
                  <AddButton
                    variant='minus'
                    onClick={() => onMinusClick(id)}
                    disabled={batchState[id] === 0}
                  />
                  <div
                    style={{
                      color: tenantColor,
                      textAlign: 'center',
                      marginLeft: 4,
                      marginRight: 4,
                      width: 25,
                      fontWeight: 'bold'
                    }}
                  >
                    {batchState[id]}
                  </div>
                  <AddButton
                    variant='plus'
                    onClick={() => onPlusClick(id)}
                    disabled={batchState[id] === 1}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const AddButton = ({
  variant,
  disabled,
  onClick,
  tenantColor
}) => (
  <Button
    type='outlined'
    height={25}
    width={25}
    disabled={disabled}
    onClick={onClick}
  >
    {variant === 'minus' ? (
      <RemoveIcon fontSize='small' style={{ fill: disabled ? '#cccccc' : tenantColor }} />
    ) : (
      <AddIcon fontSize='small' style={{ fill: disabled ? '#cccccc' : tenantColor }} />
    )}
  </Button>
);

export default BatchInboundModal;
