import React, { useMemo } from 'react';

import Table from '../shared/table/Table';

const UsersTable = (props) => {
  const {
    onSelectUser,
    users,
    selectedUserId,
    handleCheck,
    checkedUsers,
    handleCheckAll,
    surgeons,
    tenants,
    territories = [],
  } = props;

  const columns = useMemo(() => {
    const arr = [
      { title: 'name', field: 'displayName', type: 'default', sort: true },
      { title: 'email', field: 'email', type: 'default', sort: true },
      { title: 'role', field: 'role', type: 'role', sort: false },
      { title: 'groups', field: 'groupNames', type: 'default', sort: false },
      { title: 'territories', field: 'territoryNames', type: 'default', sort: false },
      { title: 'status', type: 'userStatus', sort: false },
    ];
    if (users?.some((u) => !!u.connected)) {
      arr?.push({
        title: '',
        type: 'custom',
        formatter: (value, row) => {
          if (row.connected) {
            const tenantId = row.tenantIds[0];
            const tenant = tenants?.find((t) => t.id === tenantId);
            if (tenant && tenant?.icon?.downloadUrl) {
              return <img src={tenant?.icon?.downloadUrl} alt="tenant-logo" style={{ width: 30, height: 'auto', maxHeight: 30 }} />
            }
          }
        }
      });
    }

    return arr;
  }, [users, tenants]);

  const getTerritoryNames = (ids) => {
    if (!ids) {
      return '';
    }
    return ids?.map((id) => {
      const territory = territories.find((item) => item.id === id);
      return territory ? territory.name : '';
    })?.join(', ');
  };

  return (
    <div style={{ width: '100%' }}>
      <Table
        rows={users.map((user) => ({
          ...user,
          id: user.uid,
          groupNames: user.groups.map((group) => group.name).join(', '),
          territoryNames: getTerritoryNames(user.assignedTerritories)
        }))}
        columns={columns}
        onRowClick={onSelectUser}
        selected={selectedUserId}
        handleCheck={handleCheck}
        handleCheckAll={handleCheckAll}
        checkedRows={checkedUsers}
        additionalData={{ surgeons }}
        pagination
      />
    </div>
  );
};

export default UsersTable;
