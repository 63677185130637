import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PendingIcon from '../../../assets/icons/PendingCountIcon';
import SubmittedIcon from '../../../assets/icons/CheckIcon';

import CustomerFormsContainer from '../../../components/customers/customer-forms/CustomerFormsContainer';
import LoadScreen from '../../../components/load-screen';
import AddFormModal from '../../../components/cases/forms/AddFormModal';
import ActionButton from '../../../components/shared/ActionButton';

import { getCustomerForms, addCustomerForm } from '../../../actions/customerFormsActions';
import { getForms } from '../../../actions/formsActions';

import routes from '../../../constants/routes';
import { setAllocationStatuses, formStatuses } from '../../../constants/enums';
import Select from '../../../components/shared/Select';

const CustomerFormsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const users = useSelector((state) => state?.users?.list);
  const forms = useSelector((state) => state?.forms?.list);
  const tenantColor = useSelector((state) => state?.tenant?.currentTenant?.colorPrimary);

  const [customerForms, setCustomerForms] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addModalOpen, toggleAddModal] = useState(false);
  const [formName, setFormName] = useState('');

  const [filter, setFilter] = useState({
    pending: false,
    submitted: true,
  });

  useEffect(() => {
    onLoad().catch(console.error);
  }, []);

  const onLoad = async () => {
    setFetching(true);
    try {
      await dispatch(getForms());
      await fetchForms();
    } catch (err) {
      console.error(err);
    } finally {
      setFetching(false);
    }
  };

  const fetchForms = async () => {
    const res = await dispatch(getCustomerForms());
    const data = res || [];
    const mappedForms = data?.map((item) => {
      const form = forms?.find((f) => f.id === item.formId);
      return {
        ...item,
        name: form?.name || '',
        updatedAt: item?.updatedAt || item?.closedAt || item?.submittedAt || item?.createdAt
      }
    });

    setCustomerForms(mappedForms);
  };

  const onSubmit = async (formId) => {
    try {
      setLoading(true);
      const id = await dispatch(addCustomerForm(formId));
      toggleAddModal(false);
      history.push(`${routes.FORMS}/${id}`);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const list = useMemo(() => customerForms?.filter((form) => {
    let persistence = false;

    if (filter.pending && form.status === formStatuses.PENDING) {
      persistence = true;
    }

    if (filter.submitted && form.status === formStatuses.SUBMITTED) {
      persistence = true;
    }

    return persistence && (!formName || form.name === formName);
  }), [customerForms, filter, formName]);

  const formNames = useMemo(() => {
    const result = [];

    customerForms?.forEach((form) => {
      if (form.name && !result?.includes(form.name)) {
        result.push(form.name);
      }
    })

    return result;
  }, [customerForms]);

  return (
    <div className='settings-cmp__main'>
      {fetching && <LoadScreen />}
      <span className='settings-title'>Forms</span>

      <div className='d-flex space-between'>
        <div className='d-flex m-b-md'>
          <div className='m-r-md'>
            <ActionButton
              onClick={() => setFilter((state) => ({ ...state, pending: !state.pending }))}
              bgColor={!!filter.pending ? setAllocationStatuses.TRANSFERRED.color : '#dddddd'}
            >
              <PendingIcon color="#ffffff" />
              Pending
            </ActionButton>
          </div>
          <div className='m-l-md'>
            <ActionButton
              onClick={() => setFilter((state) => ({ ...state, submitted: !state.submitted }))}
              bgColor={!!filter.submitted ? setAllocationStatuses.SHIPPED.color : '#dddddd'}
            >
              <SubmittedIcon color="#ffffff" />
              Submitted
            </ActionButton>
          </div>
        </div>

        <div className='d-flex flex-end' style={{ width: '40%' }}>
          <Select
            value={formName}
            onChange={setFormName}
            options={formNames?.map((name) => ({ label: name, value: name }))}
            defaultLabel={`All forms (${formNames?.length})`}
            width={300}
          />
        </div>
      </div>

      <CustomerFormsContainer
        customerForms={list}
        forms={forms}
        users={users}
        onAddClick={() => toggleAddModal(true)}
        tenantColor={tenantColor}
      />
      <AddFormModal
        open={addModalOpen}
        onClose={() => toggleAddModal(false)}
        forms={forms?.map((form) => ({ value: form.id, label: form.name }))}
        loading={loading}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default CustomerFormsPage;
