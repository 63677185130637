import React from 'react';

import DeleteIcon from '../../assets/icons/DeleteIcon';
import UploadIcon from '../../assets/icons/UploadIcon';

import Button from '../shared/Button';
import Select from '../shared/Select';

const HospitalsFilters = (props) => {
  const {
    onAddHospitalClick,
    status,
    setStatus,
    statuses,
    country,
    setCountry,
    countries,
    onDelete,
    onUploadClick,
    deletePermission,
  } = props;

  return (
    <div className='settings-cmp__filters--main'>
      <div className='settings-cmp__actions'>
        <Button
          text='Add Hospital'
          type='submit'
          onClick={onAddHospitalClick}
        />

        <Button
          type='submit'
          width='60px'
          onClick={onUploadClick}
        >
          <UploadIcon color="#ffffff" />
        </Button>

        <Button
          type='icon'
          onClick={onDelete}
          disabled={!deletePermission}
        >
          <DeleteIcon style={{ color: '#000000' }}/>
        </Button>
      </div>

      <div className='settings-cmp__filters'>
        <Select
          value={country}
          onChange={setCountry}
          options={countries}
          defaultLabel={`All Countries (${countries.length})`}
          width={200}
        />

        <Select
          value={status}
          onChange={setStatus}
          options={statuses}
          defaultLabel={`All Status (${statuses.length})`}
          width={200}
        />
      </div>
    </div>
  )
};

export default HospitalsFilters;
