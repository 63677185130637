import React from 'react';

import Select from '../../shared/Select';
import Button from '../../shared/Button';

import './styles.scss';

const ManagementReportsForm = (props) => {
  const {
    reportingSummary,
    setReportingSummary,
    reportingSummaryOptions,
    options,
    onSubmit,
    loading,
  } = props;

  const renderSummaryList = () => {
    switch (reportingSummary) {
      case reportingSummaryOptions.COMPANY_ACTIVITY:
        return <>
          <div className='m-t-md'>- Total Cases (Shown by State)</div>
          <div className='m-t-sm'>- Total Cases by Type (Loan, Consignment, Combined)</div>
          <div className='m-t-sm'>- Total Cases by Type (inc State Split)</div>
          <div className='m-t-sm'>- Top Hospitals by Case</div>
          <div className='m-t-sm'>- Top Booking Volume (Per User)</div>
          <div className='m-t-sm'>- Top Procedures</div>
        </>;
      case reportingSummaryOptions.SURGEON_ACTIVITY:
        return <>
          <div className='m-t-sm'>- Top Surgeons by Cases</div>
          <div className='m-t-sm'>- Top Surgeons by Case Value</div>
          <div className='m-t-sm'>- Top Surgeons by Procedure</div>
          <div className='m-t-sm'>- Top Surgeons by avg. Procedure Value</div>
        </>;
      case reportingSummaryOptions.KIT_SET_ACTIVITY:
        return <>
          <div className='m-t-sm'>- Total Sets Shipped</div>
          <div className='m-t-sm'>- Top Turning Kits</div>
          <div className='m-t-sm'>- Top Accounts by Shipped Sets</div>
          <div className='m-t-sm'>- Top Accounts Turning Sets</div>
          <div className='m-t-sm'>- Set Volume by Kit ID</div>
        </>;
      case reportingSummaryOptions.FORECASTING:
        return <>
          <div className='m-t-sm'>- Total Cases Booked (Next 90 Days)</div>
          <div className='m-t-sm'>- Total Cases Booked by State</div>
          <div className='m-t-sm'>- Top Surgeons by Booked Cases</div>
          <div className='m-t-sm'>- Top Hospitals by Booked Cases</div>
          <div className='m-t-sm'>- Projected Revenue (Based on avg. $ value)</div>
        </>;
      case reportingSummaryOptions.BILLING:
        return <>
          <div className='m-t-sm'>- Total $ Value of Outstanding</div>
          <div className='m-t-sm'>- Total Outstanding by Account</div>
          <div className='m-t-sm'>- Total Outstanding by State</div>
        </>;
      case reportingSummaryOptions.OPERATIONS:
        return <>
          <div className='m-t-sm'>- Freight Costs by Account (Top 10)</div>
          <div className='m-t-sm'>- Total Freight Costs by State</div>
          <div className='m-t-sm'>- Counts Submitted</div>
          <div className='m-t-sm'>- Number of Expiring Items</div>
        </>;
      default:
        return null;
    }
  };

  return (
    <div className='management-reports-form__container'>
      <div className='d-flex w-100'>
        <div className='management-reports-form__field'>
          <div>Reporting Summary</div>
          <Select
            value={reportingSummary}
            onChange={setReportingSummary}
            options={options}
          />
        </div>
        <div className='management-reports-form__button'>
          <Button
            onClick={onSubmit}
            disabled={loading}
            loading={loading}
            text='Run Report'
          />
        </div>
      </div>

      <div className='m-t-lg'>
        <div className='font-bold'>Reporting summary consisting of the following:</div>
        {renderSummaryList()}
      </div>
    </div>
  )
};

export default ManagementReportsForm;
