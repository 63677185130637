import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function ContactIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M7 6.05121C7.96 6.05121 8.5 5.51121 8.5 4.55121C8.5 3.59121 7.96 3.05121 7 3.05121C6.04 3.05121 5.5 3.59121 5.5 4.55121C5.5 5.51121 6.04 6.05121 7 6.05121Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M4.51978 7.95024C4.67934 7.2461 5.30455 6.30121 6.98251 6.30121C8.66048 6.30121 9.28569 7.2461 9.44525 7.95024" />
    <path stroke={color || defaultColor} fill="none" strokeLinejoin="round"
          d="M12.0185 10.2712C12.1383 7.55231 12.1062 4.92321 11.9223 2.15624C11.8903 1.67529 11.5151 1.28805 11.0351 1.24429C8.26872 0.992137 5.73152 0.992137 2.96518 1.24429C2.48516 1.28805 2.10991 1.67529 2.07794 2.15624C1.86641 5.33907 1.85577 8.33947 2.04602 11.5039C2.0567 11.3345 2.06799 11.1646 2.07988 10.9942C2.11346 10.5129 2.49092 10.127 2.97156 10.0849C5.90494 9.82814 8.59105 9.82814 11.5244 10.0849C11.7088 10.1011 11.8781 10.1678 12.0185 10.2712Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinejoin="round"
          d="M12.0082 10.2637C11.8698 10.1646 11.7043 10.1007 11.5243 10.0849C8.59098 9.82814 5.90486 9.82814 2.97149 10.0849C2.49085 10.127 2.11338 10.5129 2.0798 10.9942C2.06798 11.1636 2.05676 11.3324 2.04614 11.5008C2.05676 11.6692 2.06798 11.838 2.0798 12.0074C2.11338 12.4887 2.49085 12.8746 2.97149 12.9167C5.90486 13.1735 8.59098 13.1735 11.5243 12.9167C11.7029 12.9011 11.8672 12.838 12.005 12.7402C11.7147 12.4104 11.5387 11.9776 11.5387 11.5038C11.5387 11.0282 11.716 10.594 12.0082 10.2637Z" />
  </SvgIcon>
}
