import React, { useCallback } from 'react';
import { orderBy } from 'lodash';

import Table from '../shared/table/Table';

const columns = [
  { title: 'name', field: 'name', type: 'default', sort: true },
  { title: 'location', field: 'hospitalName', type: 'default', sort: true },
  { title: 'sub location', field: 'location', type: 'default', sort: true },
  {
    title: 'repeats',
    field: 'frequency',
    type: 'custom',
    sort: true,
    formatter: (value) => value === 1 ? '1 month' : `${value} months`,
  },
  { title: 'start date', field: 'startDate', type: 'date', sort: true },
  {
    title: 'time frame',
    field: 'dueDays',
    type: 'custom',
    sort: true,
    formatter: (value) => value === 1 ? '1 day' : `${value} days`,
  },
];

const RepeatingCountsTable = (props) => {
  const {
    onSelectCount,
    counts = [],
    selectedCountId,
    handleCheck,
    checkedCounts,
    handleCheckAll,
    hospitals = [],
  } = props;

  const getHospitalName = useCallback((hospitalId) => {
    const hospital = hospitals?.find((hospital) => hospital.id === hospitalId);
    return hospital ? hospital.name : '';
  }, [hospitals]);

  return (
    <div style={{ width: '100%' }}>
      <Table
        rows={orderBy(counts?.map((item) => (
          { ...item, hospitalName: getHospitalName(item.hospitalId) })
        ), 'name', 'asc')}
        columns={columns}
        onRowClick={onSelectCount}
        selected={selectedCountId}
        handleCheck={handleCheck}
        checkedRows={checkedCounts}
        handleCheckAll={handleCheckAll}
        pagination
        noCheckAll
      />
    </div>
  );
};

export default RepeatingCountsTable;
