import React from 'react';

import Modal from '../../shared/modal';
import Button from '../../shared/Button';

import '../../shared/modal/modal.scss';

const ConfirmUploadModal = (props) => {
  const { open, onSubmit, onClose, loading, failedItems = [] } = props;

  return (
    <Modal open={open} onClose={onClose}>
      <div className='confirmation-modal__container'>
        <div>
          <div className='modal-title'>
            Some items missing from Item list!
          </div>

          <div className='modal-content'>
            You are attempting to add items to this BOM that cannot be found in the item list. Proceed and ignore missing items or cancel adding all items.
          </div>

          <div className='m-t-lg m-b-lg'>
            <div className='font-bold'>Missing Items</div>
            <div className='m-t-md'>
              {failedItems?.map((item) => (
                <div key={Math.random()} className='m-t-sm'>
                  {`${item?.code}${item?.description ? ` (${item.description})` : ''}`}
                </div>
              ))}
            </div>
          </div>

          <div className='form__actions'>
            <Button
              type='submit'
              text='Upload'
              onClick={onSubmit}
              disabled={loading}
              loading={loading}
            />
            <Button
              type='cancel'
              text='Cancel'
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmUploadModal;
