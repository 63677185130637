import React, { useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import Modal from '../../shared/modal';
import Input from '../../shared/Input';
import Button from '../../shared/Button';

import DeleteIcon from '../../../assets/icons/DeleteIcon';

const useOutsideClick = (callback) => {
  const ref = React.useRef();

  React.useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [ref]);

  return ref;
};

const SectionNotesModal = ({ open, onClose, onSubmit, onDelete, loading, initialValues, deleting }) => {
  const [title, setTitle] = useState('');
  const [note, setNote] = useState('');
  const [color, setColor] = useState('#F58634');
  const [isOpen, setOpen] = useState(false);

  const ref = useOutsideClick(() => setOpen(false));


  useEffect(() => {
    if (open && initialValues) {
      setTitle(initialValues?.title);
      setNote(initialValues?.note);
      setColor(initialValues?.color);
    }
  }, [open, initialValues]);

  const handleClose = () => {
    onClose();
    setTitle('');
    setNote('');
    setColor('#F58634');
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={initialValues ? 'Edit Note' : 'Add Note'}
      fullWidth
      loading={loading}
    >
      <div className='close-order-modal__container'>
        <div className=''>
          <div className='field__title'>Title</div>
          <Input
            name='title'
            value={title}
            placeholder=''
            type='text'
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>

        <div className='m-b-lg'>
          <div className='field__title'>Colour</div>

          <div
            className='color-picker'
            onClick={() => setOpen(true)}
          >
            <div className='flex-centered-row'>
              <div className='color-picker__color-box' style={{ '--color-background': color }} />
              <span className='color-text'>{color}</span>
              { isOpen && (
                <div className='color-picker__form' ref={ref}>
                  <SketchPicker
                    color={color}
                    onChangeComplete={color => setColor(color.hex)}
                  />
                </div>
              )}
            </div>

            <div className='flex-centered-row'>
              <span className='change-text'>Change</span>
              <KeyboardArrowDownIcon
                color='secondary'
              />
            </div>
          </div>
        </div>

        <div className=''>
          <div className='field__title'>Note (300 characters limit)</div>
          <Input
            name='note'
            value={note}
            placeholder=''
            type='textarea'
            onChange={(e) => setNote(e.target.value)}
            maxLength={300}
          />
        </div>
      </div>

      <div className='width-100 d-flex space-between' style={{ padding: '24px' }}>
        <Button type='cancel' text="Cancel" onClick={onClose} />
        <div className='d-flex'>
          <div className='m-r-lg'>
            <Button
              type='icon'
              onClick={onDelete}
              loading={deleting}
            >
              <DeleteIcon style={{ color: '#000000' }}/>
            </Button>
          </div>
          <Button
            text={initialValues ? 'Save Changes' : 'Add'}
            onClick={() => onSubmit({ id: initialValues?.id, title, note, color })}
            disabled={loading || !title || !note || !color} loading={loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SectionNotesModal;
