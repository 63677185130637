import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function ReportingIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinejoin="round"
          d="M8.95909 13.1147L5.04088 13.1147C4.93628 11.193 4.92331 9.31641 5.00198 7.40594C5.02149 6.93217 5.37687 6.5372 5.84861 6.48931C6.64926 6.40802 7.35071 6.40802 8.15136 6.48931C8.6231 6.5372 8.97848 6.93217 8.99799 7.40594C9.07666 9.31642 9.06369 11.193 8.95909 13.1147Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinejoin="round"
          d="M4.8618 13.1146L1.91427 13.1146C1.37357 13.1146 0.930534 12.6848 0.916992 12.1442C0.831113 8.71638 0.833099 5.35476 0.922947 1.92383C0.934436 1.48513 1.23115 1.09913 1.66168 1.01406C2.53003 0.842481 3.27537 0.842481 4.14371 1.01406C4.57424 1.09913 4.87096 1.48513 4.88245 1.92383C4.98076 5.67788 4.97387 9.34896 4.8618 13.1146Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinejoin="round"
          d="M12.0858 13.1146L9.13824 13.1146C9.02616 9.34896 9.01928 5.67788 9.11759 1.92383C9.12908 1.48513 9.4258 1.09913 9.85632 1.01406C10.7247 0.842481 11.47 0.842481 12.3384 1.01406C12.7689 1.09913 13.0656 1.48513 13.0771 1.92383C13.1669 5.35476 13.1689 8.71638 13.083 12.1442C13.0695 12.6848 12.6265 13.1146 12.0858 13.1146Z" />
  </SvgIcon>
}
