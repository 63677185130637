import React from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import DriveFolder from './DriveFolder';
import DriveFile from './DriveFile';
import KitFile from './KitFile';

import routes from '../../constants/routes';

const DriveBrowse = (props) => {
  const { folders, files, onFolderClick, onFileClick, active, kits = [] } = props;
  const history = useHistory();

  return (
    <div className='drive-browse__container'>
      {active === 'kits' ? (
        <>
          { kits && !!kits.length && (
            <>
              <span className='secondary font-bold font-size-bg m-b-lg'>Kits</span>
              <Grid container spacing={0}>
                {
                  kits.map((kit) => (
                    <Grid key={kit.id} item xs={12} sm={6} md={6} lg={4}>
                      <KitFile
                        {...kit}
                        onClick={() => history.push(`${routes.BILL_OF_MATERIAL}/${kit.id}?redirect=drive`)}
                      />
                    </Grid>
                  ))
                }
              </Grid>
            </>
          )}
        </>
      ) : (
        <>
          { folders && !!folders.length && (
            <>
              <span className='secondary font-bold font-size-bg m-b-lg'>Folders</span>
              <Grid container spacing={0} className='m-b-lg'>
                {
                  folders.map((folder) => (
                    <Grid key={folder.id} item xs={12} sm={6} md={6} lg={4}>
                      <DriveFolder
                        {...folder}
                        onClick={onFolderClick}
                      />
                    </Grid>
                  ))
                }
              </Grid>
            </>
          )}

          { files && !!files.length && (
            <>
              <span className='secondary font-bold font-size-bg m-b-lg'>Files</span>
              <Grid container spacing={0}>
                {
                  files.map((file) => (
                    <Grid key={file.id} item xs={12} sm={6} md={6} lg={4}>
                      <DriveFile
                        {...file}
                        onClick={onFileClick}
                      />
                    </Grid>
                  ))
                }
              </Grid>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default DriveBrowse;
