import React from 'react';

const TermsAndPolicy = () => {
  return(
    <a href="https://help.opologic.com/en/articles/9296131-terms-of-service-privacy-policy" target='_blank' className='policy-text' rel="noreferrer">
      <span className='terms-text'>Terms & Conditions</span>
      <div className='dot'/>
      <span className='terms-text'>Privacy Policy</span>
    </a>
  );
};

export default TermsAndPolicy;
