import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import Modal from '../../shared/modal';
import Input from '../../shared/Input';
import Dropzone from '../../shared/dropzone';

const CloseOrderModal = ({ open, onClose, onSubmit, loading, tenantColor, proformaValue }) => {
  const [reference, setReference] = useState('');
  const [finalOrderValue, setFinalOrderValue] = useState(proformaValue || 0);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (!!open && !!proformaValue) {
      setFinalOrderValue(proformaValue);
    }
  }, [open, proformaValue]);

  const onUpload = (newFiles) => {
    setFiles([
      ...files,
      ...newFiles.filter((file) => !files.map((f) => f.name).includes(file.name)),
    ]);
  };

  const onRemove = (fileName) => {
    setFiles([...files].filter((item) => item.name !== fileName));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Close Order'
      fullWidth
      closeButton='Cancel'
      submitButton='Close Order'
      onSubmit={() => onSubmit(reference, files, finalOrderValue)}
      disabled={loading || !reference}
      loading={loading}
    >
      <div className='close-order-modal__container'>
        <div className='p-b-md'>
          <div>
            With all usage approved you can now set this order to closed.
          </div>
          <div>
            You can provide an order reference below.
          </div>
        </div>

        <div className='m-t-lg'>
          <div className='secondary m-b-sm'>Order Reference</div>
          <Input
            value={reference}
            placeholder='Add order reference'
            type='text'
            onChange={(e) => setReference(e.target.value)}
          />
        </div>

        <div className=''>
          <div className='secondary m-b-sm'>Final Order Value</div>
          <NumberFormat
            value={finalOrderValue}
            onValueChange={(v) => setFinalOrderValue(v.floatValue)}
            thousandSeparator={true}
            prefix={'$'}
            customInput={Input}
            fixedDecimalScale={2}
            decimalScale={2}
          />
        </div>

        <div>
          <div className='secondary m-b-sm'>Order Attachments</div>
          <Dropzone
            files={files}
            onUpload={onUpload}
            onRemove={onRemove}
            tenantColor={tenantColor}
            withoutProgress
          />
        </div>
      </div>
    </Modal>
  );
};

export default CloseOrderModal;
