import React from 'react';

import Radio from '@material-ui/core/Radio';
import MuiRadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const StyledLabel = withStyles(() => ({
  label: {
    fontWeight: 600
  },
}))(FormControlLabel);

const useStyles = makeStyles((theme) => ({
  disabledRadio: {
    color: theme.palette.text.disabled,
    '& .Mui-checked .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
  },
}));

const RadioGroup = ({ options = [], value, onChange, label, disabled }) => {
  const classes = useStyles();

  return (
    <FormControl component="fieldset">
      {label && (
        <FormLabel component="legend">{label}</FormLabel>
      )}
      <MuiRadioGroup
        aria-label={`aria_radio_group_${Math.random()}`}
        name={`radio_group_${Math.random()}`}
        value={value}
        onChange={(e) => onChange(e?.target?.value)}
      >
        {options?.map((opt) => (
          <StyledLabel
            key={opt.value}
            value={opt.value}
            control={<Radio size='small' color='primary' />}
            label={opt.label}
            disabled={disabled}
            classes={{ disabled: classes.disabledRadio }}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
}

export default RadioGroup;
