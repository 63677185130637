import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import moment from 'moment';

import Modal from '../../shared/modal';
import Input from '../../shared/Input';
import DatePicker from '../../shared/date-picker/DatePicker';

import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

const BatchControlModal = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    loading,
    item,
    batchItems = []
  } = props;

  const [batchState, setBatchState] =  useState([]);

  useEffect(() => {
    if (open && item?.quantity) {
      const arr = [];
      for (let i = 0; i < item?.quantity; i++) {
        arr[i] = batchItems[i] ? {
          id: batchItems[i]?.id,
          code: batchItems[i]?.code,
          expDate: batchItems[i]?.expDate ? moment(batchItems[i]?.expDate) : null
        } : {
          id: uuid(),
          code: '',
          expDate: null
        };
      }
      setBatchState(arr);
    }
  }, [open, item]);

  const onCodeChange = (e, batchItem) => {
    const arr = [...batchState];
    const _item = arr?.find((i) => i.id === batchItem.id);
    if (_item) {
      _item.code = e.target.value;
      setBatchState(arr);
    }
  };

  const onClearClick = (batchItem) => {
    const arr = [...batchState];
    const _item = arr?.find((i) => i.id === batchItem.id);
    if (_item) {
      _item.code = '';
      setBatchState(arr);
    }
  }

  const onDateChange = (date, batchItem) => {
    const arr = [...batchState];
    const _item = arr?.find((i) => i.id === batchItem.id);
    if (_item) {
      _item.expDate = date;
      setBatchState(arr);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Edit Batch'
      fullWidth
      submitButton='Submit'
      closeButton='Cancel'
      onSubmit={() => onSubmit(batchState)}
      loading={loading}
      disabled={loading}
    >
      <div className='quarantine-modal__container'>
        <div className='secondary'>Edit batch information for the following item:</div>
        <div className='font-bold m-t-md'>{`${item.code} (x${item.quantity})`}</div>
        <div className='m-t-sm'>{item.checklistDescription || item.description}</div>
        <div className='m-t-lg'>
          <div className='d-flex m-b-md'>
            <div className='flex-1 secondary font-bold m-r-md'>BATCH CODE</div>
            <div className='flex-1 secondary font-bold m-l-md'>EXPIRY DATE</div>
          </div>
          {batchState?.map((batchItem) => (
            <div className='d-flex' key={batchItem.id}>
              <div className='flex-1 m-r-md'>
                <Input
                  type='text'
                  value={batchItem.code}
                  onChange={(e) => onCodeChange(e, batchItem)}
                  warning={!batchItem.code}
                  endAdornment={
                    !!batchItem.code && (
                      <IconButton
                        color='primary'
                        size='small'
                        aria-label='clear'
                        onClick={() => onClearClick(batchItem)}
                        style={{ marginRight: '8px' }}
                      >
                        <ClearIcon color='primary' fontSize='small' />
                      </IconButton>
                    )
                  }
                />
              </div>
              <div className='flex-1 m-l-md m-b-lg'>
                <DatePicker
                  date={batchItem.expDate}
                  onDateChange={(date) => onDateChange(date, batchItem)}
                  placeholder='YYYY/MM/DD'
                  format='yyyy/MM/DD'
                  keyboard
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default BatchControlModal;
