const statusColors = {
  REQUEST: '#F58634',
  BOOKED: '#81B214',
  OVERDUE: '#F53434',
  COMPLETED: '#00AEEF',
};

export {
  statusColors
};
