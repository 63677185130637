import { GET_ITEMS, SET_ITEMS, CREATE_ITEM, UPDATE_ITEM, UPLOAD_ITEMS, BULK_UPDATE_ITEMS } from '../actions/actionTypes';

const initialState = {
  list: [],
  isLoading: false,
  isLoaded: false
};

const itemsReducer = (state = initialState, action) => {
  const temp = [...state?.list];
  switch (action.type) {
    case GET_ITEMS:
      return { ...state, isLoading: true };
    case SET_ITEMS:
      return {
        ...state,
        list: action.items,
        isLoaded: true,
        isLoading: false
      };
    case CREATE_ITEM:
      temp?.push({ ...action?.data });
      return {
        ...state,
        list: temp,
      }
    case UPDATE_ITEM:
      const index = temp?.findIndex((i) => i.id === action?.data?.id);
      temp[index] = action?.data;

      return {
        ...state,
        list: temp,
      }
    case UPLOAD_ITEMS:
      return {
        ...state,
        list: [...action.items, ...state?.list]
      }
    case BULK_UPDATE_ITEMS:
      const arr = [...state.list];
      action?.items?.forEach((item) => {
        const updatedItem = arr?.find((i) => i.id === item.id);
        Object.keys(item)?.forEach((key) => {
          if (key !== 'id') {
            updatedItem[key] = item[key];
          }
        });
      });

      return {
        ...state,
        list: arr
      }
    default:
      return state;
  }
};

export default itemsReducer;
