import React, { useState, useMemo, useCallback } from 'react';
import moment from 'moment';

import DeleteIcon from '../../../assets/icons/DeleteIcon';

import Button from '../../shared/Button';
import Input from '../../shared/Input';
import DatePicker from '../../shared/date-picker/DatePicker';
import Checkbox from '../../shared/Checkbox';
import RadioGroup from '../../shared/RadioGroup';
import FileUpload from '../../shared/FileUpload';
import MultiSelect from '../../shared/MultiSelect';
import Select from '../../shared/Select';
import ConfirmationModal from '../../shared/modal/ConfirmationModal';
import CloseFormModal from './CloseFormModal';

import { formSectionTypes, formDropdownOptions, formStatuses } from '../../../constants/enums';

import '../cases.scss';

const CaseFormForm = (props) => {
  const {
    form,
    values,
    setValue,
    onSave,
    onSubmit,
    saving,
    loading,
    surgeons = [],
    procedures = [],
    products = [],
    kits = [],
    hospitals = [],
    status,
    access,
    onClose = () => {
    },
    onBackClick = () => {
    },
    onDelete = () => {
    },
    deleting,
    formData,
    users = [],
  } = props;

  const { steps = [] } = form;

  const [files, setFiles] = useState({});
  const [open, toggleModal] = useState(false);
  const [deleteModalOpen, toggleDeleteModal] = useState(false);

  const getOptions = (type) => {
    switch (type) {
      case formDropdownOptions.SURGEONS.value:
        return surgeons?.map((surgeon) => ({ value: surgeon.id, label: `${surgeon.firstName} ${surgeon.lastName}` }));
      case formDropdownOptions.HOSPITALS.value:
        return hospitals?.map((hospital) => ({ value: hospital.id, label: hospital.name }));
      case formDropdownOptions.PROCEDURES.value:
        return procedures?.map((procedure) => ({ value: procedure.id, label: procedure.name }));
      case formDropdownOptions.PRODUCTS.value:
        return products?.map((product) => ({ value: product.id, label: product.name }));
      case formDropdownOptions.KITS.value:
        return kits?.map((kit) => ({ value: kit.id, label: kit.kitId }));
      default:
        return [];
    }
  };

  const onCheck = (fieldId, option) => {
    const checked = values[fieldId]?.slice() || [];

    if (checked.includes(option)) {
      const index = checked.indexOf(option);

      checked.splice(index, 1);
      setValue(fieldId, checked);

      return;
    }

    checked.push(option);
    setValue(fieldId, checked);
  };

  const handleClose = async (notes) => {
    await onClose(notes);
    toggleModal(false);
  };

  const renderInput = (step) => {
    const isSubmitted = status === formStatuses.SUBMITTED || status === formStatuses.CLOSED;

    switch (step.type) {
      case formSectionTypes.TEXT.value:
        return (
          <Input
            type='text'
            value={values[step.id] || ''}
            onChange={(e) => setValue(step.id, e.target.value)}
            disabled={isSubmitted}
          />
        );
      case formSectionTypes.TEXT_AREA.value:
        return (
          <Input
            type='textarea'
            value={values[step.id] || ''}
            onChange={(e) => setValue(step.id, e.target.value)}
            disabled={isSubmitted}
          />
        );
      case formSectionTypes.DATE_PICKER.value:
        return (
          <div className='m-b-lg'>
            <DatePicker
              date={values[step.id] || null}
              onDateChange={(date) => setValue(step.id, date)}
              disabled={isSubmitted}
            />
          </div>
        );
      case formSectionTypes.DROPDOWN.value:
        const options = getOptions(step.dropdownValues);
        return (
          <div className='m-b-lg'>
            {step?.isMulti ? (
              <MultiSelect
                value={values[step.id] || []}
                onChange={(values) => setValue(step.id, values)}
                options={options}
                disabled={isSubmitted}
              />
            ) : (
              <Select
                value={values[step.id]}
                onChange={(value) => setValue(step.id, value)}
                options={options}
                disabled={isSubmitted}
              />
            )}
          </div>
        );
      case formSectionTypes.CHECKBOXES.value:
        return (
          <div className='case-form-form__checkbox-container'>
            {step?.options?.map((option) => (
              <div key={`${option}_${Math.random()}`}>
                <Checkbox
                  input={{
                    onClick: () => onCheck(step.id, option),
                    checked: values[step.id]?.includes(option)
                  }}
                  fontSize='small'
                  label={option}
                  disabled={isSubmitted}
                />
              </div>
            ))}
          </div>
        );
      case formSectionTypes.RADIO.value:
        return (
          <div className='case-form-form__checkbox-container'>
            <RadioGroup
              options={step?.options?.map((opt) => ({ label: opt, value: opt }))}
              value={values[step.id]}
              onChange={(v) => setValue(step.id, v)}
              disabled={isSubmitted}
            />
          </div>
        );
      case formSectionTypes.CUSTOM.value:
        return (
          <div className='m-b-lg'>
            {step?.isMulti ? (
              <MultiSelect
                value={values[step.id] || []}
                onChange={(values) => setValue(step.id, values)}
                options={step?.options?.map((opt) => ({ label: opt, value: opt }))}
                disabled={isSubmitted}
              />
            ) : (
              <Select
                value={values[step.id]}
                onChange={(value) => setValue(step.id, value)}
                options={step?.options?.map((opt) => ({ label: opt, value: opt }))}
                disabled={isSubmitted}
              />
            )}
          </div>
        );
      case formSectionTypes.FILE_UPLOAD.value:
        return (
          <div
            className='m-b-md'
            onClick={() => {
              if (isSubmitted && values[step.id]?.downloadUrl) {
                window.open(values[step.id]?.downloadUrl,'_blank');
              }
            }}
          >
            <FileUpload
              file={files[step.id]}
              onUpload={(file) => {
                setFiles((prevState) => ({ ...prevState, [step.id]: file }));
                setValue(step.id, { fileName: file.name });
              }}
              disabled={isSubmitted}
              placeholder={values[step.id]?.fileName}
              actionLabel={isSubmitted ? 'View' : 'Change'}
            />
          </div>
        );
      case formSectionTypes.IMAGE_UPLOAD.value:
        return (
          <div
            className='m-b-md'
            onClick={() => {
              if (isSubmitted && values[step.id]?.downloadUrl) {
                window.open(values[step.id]?.downloadUrl,'_blank');
              }
            }}
          >
            <FileUpload
              accept='image'
              file={files[step.id]}
              onUpload={(file) => {
                setFiles((prevState) => ({ ...prevState, [step.id]: file }));
                setValue(step.id, { fileName: file.name });
              }}
              disabled={isSubmitted}
              placeholder={values[step.id]?.fileName}
              actionLabel={isSubmitted ? 'View' : 'Change'}
            />
          </div>
        );
      case formSectionTypes.DESCRIPTION.value:
        return (
          <div className='m-b-md'>
            {step.description}
          </div>
        );
      default:
        return (
          <Input
            type='text'
            value={values[step.id] || ''}
            onChange={(e) => setValue(step.id, e.target.value)}
            disabled={isSubmitted}
          />
        );
    }
  };

  const disabled = useMemo(() => (
    loading || saving || steps?.filter((step) => step.isRequired)?.some((step) => !values[step.id])
  ), [loading, steps, values]);

  const getUserName = useCallback((uid) => {
    const user = users.find((u) => u.uid === uid);
    return user ? `${user.firstName} ${user.lastName}` : '';
  }, [users]);

  return (
    <div className='d-flex direction-column'>
      {form?.logo?.downloadUrl && (
        <div className='m-b-lg'>
          <img src={form?.logo?.downloadUrl} alt='form-logo' style={{ height: 100, width: 'auto' }} />
        </div>
      )}
      <div className='font-bold font-size-bg'>{form.name}</div>
      <div className='m-t-sm m-b-md'>{form.subtitle}</div>

      {status === formStatuses.CLOSED && (
        <div className='case-form-form__container' style={{ paddingBottom: '8px' }}>
          <div className='font-bold'>Form closed on {moment(formData?.closedAt)?.format('Do MMM YYYY')}{formData?.closedBy ? ` by ${getUserName(formData?.closedBy)}` : ''}</div>
          <div className='secondary m-t-md m-b-sm'>Additional Notes</div>
          <Input
            disabled
            value={formData?.notes || ''}
            type='textarea'
          />
        </div>
      )}

      <div className='case-form-form__container'>
        {steps?.map((step) => (
          step.type === formSectionTypes.DESCRIPTION.value ? (
            <div key={step.id} style={{ marginTop: 0, marginBottom: '24px' }}>
              <div className='font-bold m-b-sm'>{step.title}</div>
              <div>
                {step.description}
              </div>
            </div>
          ) : (
            <div key={step.id} className='m-b-lg'>
              <div className='d-flex align-start'>
                <div className='font-bold m-b-sm'>{step.title}</div>
                {step.isRequired && <div style={{ color: '#F53434', marginLeft: '2px' }}>*</div>}
              </div>
              {step.subtitle && (
                <div className='secondary m-b-md'>{step.subtitle}</div>
              )}
              <div>
                {renderInput(step)}
              </div>
            </div>
          ))
        )}
        {status === formStatuses.PENDING && (
          <div className='d-flex width-100 flex-center'>
            <div className='m-r-md'>
              <Button
                text='Save Changes'
                type='outlined'
                onClick={() => onSave(files)}
                loading={saving}
                disabled={loading || saving}
              />
            </div>
            <div className='m-l-md'>
              <Button
                text='Save & Submit'
                type='submit'
                onClick={() => onSubmit(files)}
                loading={loading}
                disabled={disabled}
              />
            </div>
           <div className='m-l-lg'>
             <Button
               type='icon'
               onClick={() => toggleDeleteModal(true)}
             >
               <DeleteIcon />
             </Button>
           </div>
          </div>
        )}
        {status === formStatuses.SUBMITTED && !!access && (
          <div className='d-flex width-100 flex-center'>
            <div className='m-r-md'>
              <Button
                text='Back'
                type='outlined'
                onClick={onBackClick}
              />
            </div>
            <div className='m-l-md'>
              <Button
                text='Close Form'
                type='submit'
                onClick={() => toggleModal(true)}
                disabled={disabled}
              />
            </div>
          </div>
        )}
        {status === formStatuses.CLOSED && (
          <div className='d-flex width-100 flex-center'>
            <Button
              text='Back'
              type='outlined'
              onClick={onBackClick}
            />
          </div>
        )}
      </div>

      <CloseFormModal
        open={open}
        onClose={() => toggleModal(false)}
        onSubmit={handleClose}
        loading={loading}
      />

      <ConfirmationModal
        open={deleteModalOpen}
        onClose={() => toggleDeleteModal(false)}
        onSubmit={onDelete}
        title='Delete Form'
        text='Are you sure you want to delete this form? Deleted forms cannot be recovered.'
        submitText='Delete'
        loading={deleting}
      />
    </div>
  );
};

export default CaseFormForm;
