import React, { useEffect, useState } from 'react';

import Modal from '../shared/modal';
import Button from '../shared/Button';
import Input from '../shared/Input';

const ConsignmentNoteModal = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    loading,
    initialValue = ''
  } = props;

  const [note, setNote] = useState(initialValue || '');

  useEffect(() => {
    if (open && initialValue) {
      setNote(initialValue);
    }
  }, [open, initialValue]);

  const handleSubmit = () => {
    onSubmit(note);
    setNote('');
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Edit Note'
      fullWidth
    >
      <div className='quarantine-modal__container'>
        <div className=''>
          <div className='secondary m-b-md'>Consignment note</div>
          <Input
            value={note}
            onChange={(e) => setNote(e.target.value)}
            type='textarea'
          />
        </div>


        <div className='form__actions p-t-lg'>
          <Button
            type='submit'
            text='Save Changes'
            onClick={handleSubmit}
            disabled={loading}
            loading={loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConsignmentNoteModal;
