export const shadowColorAmounts = {
  1: [0],
  3: [-40, 0, 50],
  5: [-40, -20, 0, 30, 50],
  7: [-40, -20, -10, 0, 20, 40, 50],
  10: [-40, - 30, -20, -10, 0, 10, 20, 30, 40, 50],
};

export const presets = {
  THIS_MONTH: 'THIS_MONTH',
  THIS_QUARTER: 'THIS_QUARTER',
  THIS_YEAR: 'THIS_YEAR',
  LAST_MONTH: 'LAST_MONTH',
  LAST_QUARTER: 'LAST_QUARTER',
  LAST_YEAR: 'LAST_YEAR',
  PREVIOUS_PERIOD: 'PREVIOUS_PERIOD',
  YEAR_AGO: 'YEAR_AGO'
};
