import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function ProformaIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M6.99999 13.25C8.17305 13.25 9.31932 13.1049 10.4282 12.959C11.3848 12.8331 12.1399 12.0578 12.2347 11.0976C12.3654 9.77509 12.5 8.40449 12.5 7C12.5 6.17419 12.4535 5.36011 12.3886 4.56062C12.2324 2.6372 10.6954 1.03012 8.77458 0.844302C8.19098 0.787842 7.59898 0.75 6.99999 0.75C5.82694 0.75 4.68067 0.89514 3.57179 1.04101C2.61515 1.16686 1.86012 1.94219 1.76526 2.90239"/>
    <path stroke={color || defaultColor} fill="none" strokeLinejoin="round"
          d="M8.04597 2.75V4.93423H10.5001C11.5916 4.93423 12.4789 5.80859 12.4997 6.89509C12.496 6.10523 12.4507 5.32623 12.3885 4.56062C12.2324 2.6372 10.6953 1.03012 8.77456 0.844302C8.19095 0.787842 7.59895 0.75 6.99997 0.75C6.76606 0.75 6.53321 0.755771 6.30151 0.766168C7.28541 0.891649 8.04597 1.732 8.04597 2.75Z"/>
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M7.8125 10.3326L9.79166 10.3326"/>
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M7.8125 7.89513L9.79166 7.89513"/>
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M4.75 7.02659C4.75 7.02659 4.13909 6.32813 3.14824 6.41542C2.15739 6.5027 1.67978 7.12706 1.67978 7.75314C1.67978 9.57916 4.75 8.31371 4.75 10.1862C4.75 11.1516 2.7134 11.833 1.5 10.6973"/>
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M3.26965 5.1875L3.26965 6.40786"/>
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M3.26965 11.2796L3.26965 12.5"/>
  </SvgIcon>
}
