import React from 'react';
import { orderBy } from 'lodash';

import Table from '../shared/table/Table';

const columns = [
  { title: 'gtin', field: 'gtin', type: 'default', sort: true },
  { title: 'reference', field: 'reference', type: 'default', sort: true },
  { title: 'description', field: 'description', type: 'default', sort: true },
  { title: 'status', field: 'active', type: 'status' },
];

const GtinsTable = (props) => {
  const {
    onSelectGtin,
    gtins = [],
    selectedGtinId,
    handleCheck,
    checkedGtins,
    handleCheckAll,
  } = props;

  return (
    <div style={{ width: '100%' }}>
      <Table
        rows={orderBy(gtins, 'gtin', 'asc')}
        columns={columns}
        onRowClick={onSelectGtin}
        selected={selectedGtinId}
        handleCheck={handleCheck}
        checkedRows={checkedGtins}
        handleCheckAll={handleCheckAll}
        pagination
        noCheckAll
      />
    </div>
  );
};

export default GtinsTable;
