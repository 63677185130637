import React, { useCallback } from 'react';

import Table from '../shared/table/Table';

import { getCommaSeparatedNames } from '../../utils/table';

const columns = [
  { title: 'name', field: 'name', type: 'default', sort: true },
  { title: 'emails', field: 'emailsList', type: 'default' },
  { title: 'products', field: 'productNames', type: 'default' },
  { title: 'status', field: 'active', type: 'status' },
];

const ProductTriggersTable = (props) => {
  const {
    onSelect,
    list,
    selected,
    handleCheck,
    checked,
    handleCheckAll,
    products,
  } = props;

  const getProductNames = useCallback((ids) => getCommaSeparatedNames(products, ids), [list, products]);

  return (
    <div style={{ width: '100%' }}>
      <Table
        rows={list.map((trigger) => ({
          ...trigger,
          productNames: trigger.allProducts ? 'All' : getProductNames(trigger.products),
          emailsList: trigger.emails ? trigger.emails.join(', ') : '',
        }))}
        columns={columns}
        onRowClick={onSelect}
        selected={selected}
        handleCheck={handleCheck}
        checkedRows={checked}
        handleCheckAll={handleCheckAll}
        pagination
      />
    </div>
  );
};

export default ProductTriggersTable;
