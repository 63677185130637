import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';

import LoadScreen from '../../../components/load-screen';
import ExpiringItemsForm from '../../../components/reporting/expiring-items/ExpiringItemsForm';
import ExpiringItemsTable from '../../../components/reporting/expiring-items/ExpiringItemsTable';

import { getKits } from '../../../actions/kitsActions';
import { getSets } from '../../../actions/setsActions';

import useLoading from '../../../hooks/useLoading';

import { setPositionOptions } from '../../../constants/enums';

const getStatus = (set) => {
  if (!set.quarantined && !set.consigned && !set.caseAllocation) {
    return setPositionOptions.AVAILABLE.value;
  } else {
    return set.quarantined ? (
      setPositionOptions.QUARANTINED.value
    ) : (
      set.consigned ? (
        setPositionOptions.CONSIGNED.value
      ) : (
        set.caseAllocation ? setPositionOptions.ALLOCATED.value : setPositionOptions.AVAILABLE.value
      )
    );
  }
};

const ExpiringItemsReportPage = () => {
  const dispatch = useDispatch();
  const kits = useSelector((state) => state?.kits?.list);
  const sets = useSelector((state) => state?.sets?.list);
  const items = useSelector((state) => state?.items?.list);
  const itemsLoaded = useSelector((state) => state?.items?.isLoaded);

  const { loading: fetching, startLoading, stopLoading } = useLoading(false);

  const [range, setRange] = useState(1);
  const [date, setDate] = useState(moment().add(1, 'months'));

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    onLoad().catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (range) {
      const newDate = moment().add(range, 'months');
      setDate(newDate);
    }
  }, [range]);

  const onLoad = async () => {
    startLoading()
    try {
      await Promise.all([
        dispatch(getKits()),
        dispatch(getSets()),
      ]);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const dataItems = [];
      sets?.forEach((set) => {
        if (set.batchItems) {
          Object.keys(set.batchItems).forEach((itemId) => {
            const batchItems = set?.batchItems[itemId] || [];
            batchItems.forEach((batchItem, index) => {
              if (batchItem?.expDate) {
                const expDate = moment(batchItem?.expDate);
                if (expDate.isBefore(date)) {
                  const item = items?.find((i) => i.id === itemId);
                  const kit = kits?.find((k) => k.id === set.kit);
                  if (item) {
                    dataItems.push({
                      id: `${item.id}_${batchItem?.code}_${index}`,
                      code: item?.code || '',
                      description: item?.description || '',
                      lot: batchItem?.code || '',
                      expDate,
                      kitName: kit?.name || '',
                      setNumber: set?.number || '',
                      setStatus: getStatus(set) || '',
                    });
                  }
                }
              }
            });
          });
        }
      });
      setData(dataItems);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='page-container reporting-page__container'>
      {(fetching || !itemsLoaded) && <LoadScreen />}
      <div className='page-title'>Expiring Items Report</div>
      <div className='reporting-page__body'>
        <ExpiringItemsForm
          range={range}
          setRange={setRange}
          date={date}
          setDate={setDate}
          onSubmit={onSubmit}
          loading={loading}
        />

        {data && !!data.length && (
          <ExpiringItemsTable
            data={data}
          />
        )}
      </div>
    </div>
  );
};

export default ExpiringItemsReportPage;
