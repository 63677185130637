import React from 'react';

import UsageItemRow from './UsageItemRow';

import '../checklists/checklists.scss';

const ReturnUsageTable = (props) => {
  const {
    checklistDescription,
    description,
    consumables = [],
    onChange,
    tenantColor,
    allocationStatus,
    imageUrl,
  } = props;

  return (
    <div className='checklist-section-item'>
      <table className='section-items-table'>
        <thead>
        <tr>
          <th>Lot/Serial</th>
          <th>Description</th>
          <th>Type</th>
          <th>Image</th>
          <th />
        </tr>
        </thead>
        {
          <tbody>
          {consumables?.map((consumable, index) => (
            <UsageItemRow
              key={`${consumable?.label}_${Math.random()}`}
              consumable={consumable}
              onChange={(status) => onChange(index, status)}
              tenantColor={tenantColor}
              allocationStatus={allocationStatus}
              description={checklistDescription || description}
              imageUrl={imageUrl}
            />
          ))}
          </tbody>
        }
      </table>
    </div>
  );
};

export default ReturnUsageTable;
