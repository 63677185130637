import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import FormIcon from '../../../assets/icons/FormIcon';
import AddIcon from '../../../assets/icons/AddIcon';

import CaseFormsTable from './CaseFormsTable';
import AddFormModal from './AddFormModal';
import ActionButton from '../../shared/ActionButton';

import { addForm } from '../../../actions/casesActions';

import { setAllocationStatuses } from '../../../constants/enums';
import routes from '../../../constants/routes';

import '../cases.scss';

const CaseFormsContainer = (props) => {
  const {
    activeCase,
    caseForms = [],
    forms = [],
    users = [],
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const [addModalOpen, toggleAddModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const onRowClick = (form) => {
    history.push(`${routes.CASES}/${activeCase.id}${routes.FORMS}/${form.id}`);
  };

  const formsList = useMemo(() => {
    if (!activeCase?.products?.length || !forms?.length) {
      return [];
    }

    return forms
      ?.filter((form) => form?.allProducts || form?.products?.some((productId) => activeCase?.products?.includes(productId)))
      ?.map((form) => ({ value: form.id, label: form.name }));
  }, [activeCase, forms]);

  const onSubmit = async (formId) => {
    try {
      setLoading(true);
      const id = await dispatch(addForm(activeCase?.id, formId));
      toggleAddModal(false);
      history.push(`${routes.CASES}/${activeCase.id}${routes.FORMS}/${id}`);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='case-kits__container'>
      <div className='d-flex align-start space-between'>
        <div className='d-flex'>
          <FormIcon />
          <div className='font-size-bg font-bold m-l-md'>
            Forms
          </div>
        </div>

        <ActionButton
          onClick={() => toggleAddModal(true)}
          bgColor={setAllocationStatuses.RETURNED.color}
        >
          <AddIcon color="#ffffff" />
          Add
        </ActionButton>
      </div>

      {caseForms?.length ? (
        <CaseFormsTable
          rows={caseForms?.filter((form) => forms?.map((f) => f.id)?.includes(form.formId))}
          onRowClick={onRowClick}
          users={users}
          forms={forms}
        />
      ) : (
        <div className='d-flex flex-center secondary p-lg'>
          No Forms
        </div>
      )}

      <AddFormModal
        open={addModalOpen}
        onClose={() => toggleAddModal(false)}
        forms={formsList}
        loading={loading}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default CaseFormsContainer;
