import React from 'react';

import SetIcon from '../../../assets/icons/SetIcon';

import { setPositionOptions } from '../../../constants/enums';

const SetInfo = (props) => {
  const { setNumber, position } = props;

  return (
    <div className='case-checklists-info__container d-flex flex-between flex-1 m-r-lg direction-row' style={{ height: '62px' }}>
      <div className='d-flex'>
        <SetIcon color={setPositionOptions[position]?.color || setPositionOptions.AVAILABLE.color} />
        <span className='font-bold font-size-bg m-l-md'>{setNumber}</span>
      </div>
      <div className='font-bold font-size-bg' style={{ color: setPositionOptions[position]?.color || setPositionOptions.AVAILABLE.color }}>
        {setPositionOptions[position]?.label || setPositionOptions.AVAILABLE.label}
      </div>
    </div>
  );
};

export default SetInfo;
