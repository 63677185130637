import { multiTenantRoles, roleNames } from '../constants/userRoles';

const isAssigned = (user, surgeons) => {
  if (!user || !surgeons) {
    return false;
  }

  const { role, assignedSurgeons } = user;

  if (!assignedSurgeons) {
    return false;
  }

  const filteredSurgeons = assignedSurgeons.filter((surgeon) => !!surgeons.find((item) => item.id === surgeon));

  return [...multiTenantRoles, roleNames.SALES_REP].includes(role) && !!filteredSurgeons.length;
};

export {
  isAssigned,
}
