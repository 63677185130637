import React, { useState } from 'react';

import Modal from '../../shared/modal';
import Button from '../../shared/Button';

import Select from '../../shared/Select';
import Input from '../../shared/Input';

const AddFlowModal = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    loading,
    options = [],
    procedure,
  } = props;

  const [flow, setFlow] = useState();

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Add Flow'
      fullWidth
    >
      <div className='case-sets-modal__container'>
        <div>
          <div className='field__title'>Procedure</div>
          <Input
            value={procedure}
            placeholder=''
            type='text'
            disabled
            onChange={() => {}}
          />
        </div>
        <div className='m-b-lg'>
          <div className='field__title'>Connect Flow</div>
          <Select
            value={flow}
            onChange={setFlow}
            options={options}
            endAdornment='Select'
          />
        </div>

        <div className='form__actions'>
          <Button
            type='submit'
            text='Add Flow'
            disabled={loading || !flow}
            loading={loading}
            onClick={() => onSubmit(flow)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddFlowModal;
