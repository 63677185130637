import React, { useCallback } from 'react';

import Table from '../../shared/table/Table';

const columns = [
  { title: 'title', field: 'title', type: 'default', sort: true },
  { title: 'description', field: 'description', type: 'default', sort: true },
  { title: 'due date', field: 'dueDate', type: 'date', sort: true },
  { title: 'surgeon', field: 'surgeonName', type: 'default', sort: true },
  // { title: 'postcode', field: 'postCode', type: 'default', sort: false },
];

const TasksTable = (props) => {
  const {
    onSelectTask,
    tasks,
    selectedTaskId,
    handleCheck,
    checkedTasks,
    handleCheckAll,
    surgeons
  } = props;

  const getSurgeonName = useCallback((surgeonId) => {
    const caseSurgeon = surgeons.find((surgeon) => surgeon.id === surgeonId);
    return caseSurgeon ? `${caseSurgeon.title} ${caseSurgeon.firstName} ${caseSurgeon.lastName}` : '';
  }, [surgeons]);

  return (
    <div style={{ width: '100%' }}>
      <Table
        rows={tasks.map((task) => ({
          ...task,
          surgeonName: getSurgeonName(task.surgeonId),
        }))}
        columns={columns}
        onRowClick={onSelectTask}
        selected={selectedTaskId}
        handleCheck={handleCheck}
        checkedRows={checkedTasks}
        handleCheckAll={handleCheckAll}
        pagination
      />
    </div>
  );
};

export default TasksTable;
