import React from 'react';

import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import FilterIcon from '@material-ui/icons/FilterList';

import '../kits/kits.scss';

const UsageFiltersList = (props) => {
  const {
    onClick,
    hideClosed,
    surgeon,
    hospital,
    onDelete,
  } = props;

  return (
    <div className='kit-filters-list__container'>
      <IconButton onClick={onClick}>
        <FilterIcon />
      </IconButton>

      {hideClosed && (
        <Chip label='Hide All Closed' onDelete={() => onDelete('closed')} />
      )}

      {/*{hideReturned && activeTab === 1 && (*/}
      {/*  <div className='kit-filters-list__item'>*/}
      {/*    Hide All Returned*/}
      {/*  </div>*/}
      {/*)}*/}

      {surgeon && (
        <Chip label={surgeon} onDelete={() => onDelete('surgeon')} />
      )}

      {hospital && (
        <Chip label={hospital} onDelete={() => onDelete('hospital')} />
      )}
    </div>
  );
};

export default UsageFiltersList;
