import React, { useMemo } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import SectionItemRow from './SectionItemRow';
import SectionNotesRow from './SectionNotesRow';

import { billOfMaterialItemTypes, itemTypeOptions } from '../../constants/enums';

const SectionItemsList = (props) => {
  const { loading, items, itemsList, search } = props;

  const list = useMemo(() => {
    return items
      ?.filter((item) => item.type === billOfMaterialItemTypes.NOTES.value || itemsList?.find((i) => i.id === item.id))
      ?.map((item) => {
        if (item.type === billOfMaterialItemTypes.NOTES.value) {
          return item;
        } else {
          const _item = itemsList?.find((i) => i.id === item.id);
          return {
            ...item,
            code: _item?.code || '',
            description: _item?.checklistDescription || _item?.description || '',
            type: _item?.type ? itemTypeOptions[_item?.type]?.label : '',
            batchControl: !!_item.batchControl,
          };
        }
      })
      ?.filter((item) => item.type === billOfMaterialItemTypes.NOTES.value || !search.length || item?.code?.toLowerCase()?.includes(search?.toLowerCase()));
  }, [items, itemsList, search]);

  return (
    <div className='drive-files-list__container'>
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        !!list?.length && (
          <table className='bom-items-table'>
            <thead>
            <tr>
              <th>Item Code</th>
              <th>Description</th>
              <th>Type</th>
              <th>Ref</th>
              <th>QTY</th>
              <th style={{ width: 50 }} />
            </tr>
            </thead>
            <tbody>
            {list.map((item) => (
              item.type === billOfMaterialItemTypes.NOTES.value ? (
                <SectionNotesRow item={item} />
              ) : (
                <SectionItemRow item={item} />
              )
            ))}
            </tbody>
          </table>
        )
      )}
    </div>
  );
};

export default SectionItemsList;
