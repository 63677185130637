import { orderBy } from 'lodash';

import { GET_TAGS, SET_TAGS } from './actionTypes';

import firebase, { collections } from '../firebase';

export const setTags = tags => ({ type: SET_TAGS, tags });

export const getTags = (tenant, withReducer) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = tenant || state?.tenant?.currentTenant?.id;
  const path = collections.TAGS_COLLECTION(tenantId);

  dispatch({ type: GET_TAGS });

  const snapshot = await firebase.db.collection(path).get();
  const tags = snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));

  if (tenant && !withReducer) {
    return orderBy(tags, 'name', 'asc');
  }

  return dispatch(setTags(orderBy(tags, 'name', 'asc')));
};

export const createTag = (tagData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.TAGS_COLLECTION(tenantId);

  const doc = {
    ...tagData,
    active: true,
    procedures: tagData.procedures || [],
  };

  if (tenantId) {
    await firebase.db.collection(path).add(doc);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateTag = (tagId, tagData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.TAGS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(tagId).set(tagData, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteTag = (tagId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.TAGS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(tagId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const bulkDeleteTags = (tags) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.TAGS_COLLECTION(tenantId);
  const batch = firebase.db.batch();

  if (tenantId) {
    tags.forEach((tagId) => {
      const ref = firebase.db.collection(path).doc(tagId);
      batch.delete(ref);
    });

    await batch.commit();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const uploadTags = (tags) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.TAGS_COLLECTION(tenantId);

  if (tenantId) {
    const promises = [];

    tags.forEach((tag) => {
      const name = tag[0] || '';
      const description = tag[1] || '';

      const doc = {
        name,
        description,
        active: true,
        procedures: [],
      };

      promises.push(firebase.db.collection(path).add(doc));
    });

    await Promise.all(promises);
  } else {
    throw new Error('Invalid tenant');
  }
};

// Subscriptions
export const subscribeToTags = () => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.TAGS_COLLECTION(tenantId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let tags = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          tags = [...tags, {
            ...documentSnapshot.data(),
            id,
          }];
        });

        return dispatch(setTags(orderBy(tags, 'name', 'asc')));
      },
    });
};
