import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function GetAppIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinejoin="round"
          d="M6.33073 1.68814C6.19244 1.31474 5.84583 1.05805 5.44806 1.03995C4.01137 0.974562 3.14457 0.992082 1.99183 1.07324C1.51021 1.10715 1.12727 1.48675 1.07216 1.9664C0.734783 4.90279 0.622856 7.84939 0.974299 10.7925C1.09356 11.7912 1.91133 12.5171 2.88965 12.6236C5.65213 12.9244 8.37489 12.9167 11.1372 12.616C12.0971 12.5115 12.8678 11.7513 12.9823 10.7925C13.1995 8.97355 13.2574 7.10899 13.1221 4.82187C13.0661 3.87434 12.3231 3.11183 11.3771 3.03351C10.0327 2.92221 9.09818 2.91583 7.44575 2.88087C7.03627 2.8722 6.67343 2.61353 6.53119 2.22945L6.33073 1.68814Z" />
  </SvgIcon>
}
