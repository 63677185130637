import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { sum } from 'lodash';
import moment from 'moment';

import UsageIcon from '../../assets/icons/UsageIcon';
import ImplantIcon from '../../assets/icons/ImplantIcon';
import ImageIcon from '../../assets/icons/ImageIcon';
import NotesIcon from '../../assets/icons/NotesIcon';

import Tabs from '../shared/Tabs';
import AddButton from '../shared/AddButton';
import Modal, { ConfirmationModal } from '../shared/modal';
import NoteForm from '../customers/forms/NoteForm';

import { createUsageNote, deleteUsageNote, updateUsageNote } from '../../actions/casesActions';

import { useLoading } from '../../hooks';

import { caseUsageTypes } from '../../constants/enums';
import userRoles from '../../constants/userRoles';
import EditIcon from '../../assets/icons/EditIcon';
import DeleteIcon from '../../assets/icons/DeleteIcon';

const CaseUsage = (props) => {
  const {
    caseId,
    usage = [],
    usageNotes = [],
    orderClosed,
    preview,
    onPreviewClick,
    notEditable,
    connectedTenantId,
    userId,
    userRole,
    tenantColor,
  } = props;

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);
  const [isModalOpen, toggleModal] = useState(false);
  const [isEditModalOpen, toggleEditModal] = useState(false);
  const [isDeleteModalOpen, toggleDeleteModal] = useState(false);

  const [selectedNote, setSelectedNote] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const { loading, startLoading, stopLoading } = useLoading(false);

  const implants = useMemo(() => {
    if (!usage) {
      return [];
    }
    return usage.filter((item) => item.type === caseUsageTypes.implants);
  }, [usage]);
  const images = useMemo(() => {
    if (!usage) {
      return [];
    }
    return usage.filter((item) => item.type === caseUsageTypes.images);
  }, [usage]);

  const usageTabs = useMemo(() => ([
    { label: `Inventory${preview ? '' : ` (${implants.length})`}` },
    { label: `Images${preview ? '' : ` (${images.length})`}` },
  ]), [usage]);

  const totalImplants = useMemo(() => sum(implants.map((implant) => +implant.quantity)), [usage]);
  const totalImages = useMemo(() => sum(images.map((image) => +image.quantity)), [usage]);

  const renderPreview = () => {
    switch (activeTab) {
      case 0:
        return (
          <div
            className='case-implants__container pointer'
            onClick={onPreviewClick}
          >
            <div className='case-implant-item'>
              <div className='d-flex direction-column align-start space-between'>
                <ImplantIcon className='m-b-md' style={{ fontSize: 20 }} />
                <div>
                  <div className='font-bold'>Inventory</div>
                  <div className='secondary font-size-sm'>Total inventory recorded</div>
                </div>
              </div>
              <div className='d-flex direction-column space-between'>
                <div />
                <div style={{ fontSize: 30, marginBottom: '-8px' }}>{totalImplants || 0}</div>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div
            className='case-implants__container pointer'
            onClick={onPreviewClick}
          >
            <div className='case-implant-item'>
              <div className='d-flex direction-column align-start space-between'>
                <ImageIcon className='m-b-md' style={{ fontSize: 20 }} />
                <div>
                  <div className='font-bold'>Images</div>
                  <div className='secondary font-size-sm'>Usage images captured</div>
                </div>
              </div>
              <div className='d-flex direction-column space-between'>
                <div />
                <div style={{ fontSize: 30, marginBottom: '-8px' }}>{totalImages || 0}</div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const onSubmit = (formObj) => {
    const noteData = {
      note: formObj.note,
    };

    startLoading();

    try {
      dispatch(createUsageNote(caseId, noteData, connectedTenantId));
      toggleModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onUpdate = async (formObj) => {
    if (selectedNote && selectedNote.id) {
      const noteData = {
        note: formObj.note,
      };

      startLoading();

      try {
        dispatch(updateUsageNote(caseId, selectedNote.id, noteData, connectedTenantId));
        toggleEditModal(false);
      } catch (err) {
        console.error(err);
      } finally {
        stopLoading();
      }
    }
  };

  const onEditClick = (note) => {
    setSelectedNote(note);
    toggleEditModal(true);
  }

  const onDeleteClick = (note) => {
    setSelectedNote(note);
    toggleDeleteModal(true);
  }

  const onDelete = async () => {
    if (selectedNote && selectedNote.id) {
      try {
        setDeleting(true);
        await dispatch(deleteUsageNote(caseId, selectedNote.id, connectedTenantId));
        toggleDeleteModal(false);
      } catch (err) {
        console.error(err);
      } finally {
        setDeleting(false);
      }
    }
  };

  return (
    <div className='case-usage__container'>
      {preview ? (
        <>
          <div className='d-flex space-between'>
            <div className='d-flex'>
              <UsageIcon />
              <div className='font-size-bg font-bold m-l-md'>
                Usage
              </div>
            </div>
          </div>
          <div className='case-usage-tabs__container'>
            <Tabs
              tabs={usageTabs}
              activeTab={activeTab}
              onTabChange={setActiveTab}
            >
              {renderPreview()}
            </Tabs>
          </div>
        </>
      ) : (
        <>
          <div className='d-flex space-between'>
            <div className='d-flex'>
              <NotesIcon />
              <div className='font-size-bg font-bold m-l-md'>
                Usage Notes
              </div>
            </div>

            {!orderClosed && <AddButton onClick={() => toggleModal(true)} disabled={notEditable} />}
          </div>
          <div className='case-notes-list__container m-t-lg'>
            { usageNotes && usageNotes.map((note) => (
              <div key={note.id} className='case-note'>
                <div className='d-flex space-between'>
                  <div className='font-bold'>{note.authorFullName}</div>
                  <div className='d-flex'>
                    <div className='secondary'>{moment(note.createdAt).fromNow()}</div>
                    {(userRole === userRoles.ADMIN.name || userId === note.authorId) && (
                      <div className='case-note-actions'>
                        <div className='pointer' onClick={() => onEditClick(note)}>
                          <EditIcon color={tenantColor} />
                        </div>
                        <div className='pointer m-l-md' onClick={() => onDeleteClick(note)}>
                          <DeleteIcon color="#F53434" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='m-t-md'>
                  {note.note}
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      <Modal
        open={isModalOpen}
        onClose={() => toggleModal(false)}
      >
        <NoteForm
          loading={loading}
          onSubmit={onSubmit}
          onClose={() => toggleModal(false)}
        />
      </Modal>

      {!!selectedNote && (
        <Modal
          open={isEditModalOpen}
          onClose={() => {
            toggleEditModal(false);
            setSelectedNote(null);
          }}
        >
          <NoteForm
            mode='update'
            initialValues={selectedNote}
            loading={loading}
            onSubmit={onUpdate}
            onClose={() => toggleEditModal(false)}
          />
        </Modal>
      )}

      <ConfirmationModal
        open={isDeleteModalOpen}
        onClose={() => {
          toggleDeleteModal(false);
          setSelectedNote(null);
        }}
        onSubmit={onDelete}
        title='Are you sure you want to delete this note?'
        submitText='Delete'
        loading={deleting}
      />
    </div>
  );
};

export default CaseUsage;
