import React from 'react';

import './auth.scss';

const LoginHeader = ({ onHeaderClick }) => {
  return (
    <div className='root pointer' onClick={onHeaderClick}>
      <div className='logo'/>
      <div className='header-text'>Case</div>
    </div>
  )
};

export default LoginHeader;
