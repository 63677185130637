import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import TerritoriesTable from '../../../../components/territories/TerritoriesTable';
import TerritoriesFilters from '../../../../components/territories/TerritoriesFilters';
import TerritoryForm from '../../../../components/territories/TerritoryForm';
import TagHospitalsModal from '../../../../components/territories/TagHospitalsModal';
import Input from '../../../../components/shared/Input';
import Modal, { ConfirmationModal, UploadModal } from '../../../../components/shared/modal';
import Alert from '../../../../components/shared/Alert';

import {
  getTerritories,
  createTerritory,
  updateTerritory,
  subscribeToTerritories,
  bulkDeleteTerritories,
  uploadTerritories,
} from '../../../../actions/territoriesActions';
import { getHospitals, tagTerritories } from '../../../../actions/hospitalsActions';

import { filterTerritories } from '../../../../utils/table';

import { statusOptions } from '../../../../constants/enums';

const statusFilterOptions = [
  { label: 'Active', value: statusOptions.active },
  { label: 'Deactivated', value: statusOptions.deactivated }
];

const TerritoriesPage = (props) => {
  const {
    territories,
    createTerritory,
    updateTerritory,
    subscribeToTerritories,
    bulkDeleteTerritories,
    uploadTerritories,
    tenantColor,
    deletePermission,
    getHospitals,
    hospitals,
    tagTerritories,
  } = props;

  const [isModalCreateOpen, toggleModalCreate] = useState(false);
  const [isModalDeleteOpen, toggleModalDelete] = useState(false);
  const [isUploadModalOpen, toggleUploadModal] = useState(false);
  const [isModalDuplicateOpen, toggleModalDuplicate] = useState(false);
  const [isTagModalOpen, toggleTagModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [tagLoading, setTagLoading] = useState(false);

  const [successMessage, setSuccessMessage] = useState(null);

  const [selectedTerritory, setSelectedTerritory] = useState(null);
  const [checkedTerritories, setCheckedTerritories] = useState([]);

  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  useEffect(() => {
    const unsubscribe = subscribeToTerritories();

    onLoad().catch((err) => console.error(err));

    return () => {
      unsubscribe();
    };
  }, []);

  const onLoad = async () => {
    await getHospitals();
  };

  const onSelectTerritory = (territory) => {
    setSelectedTerritory(territory);
  };

  // Create territory
  const onCreateTerritory = async (formObj) => {
    setLoading(true);
    const territoryObj = {
      name: formObj.name,
      description: formObj.description,
    };

    try {
      await createTerritory(territoryObj);
      setLoading(false);
      toggleModalCreate(false);
      setSuccessMessage('Territory has been successfully created');
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Update territory
  const onUpdateTerritory = async (formObj) => {
    setLoading(true);
    const territoryObj = {
      active: formObj.active,
      name: formObj.name,
      description: formObj.description,
    };

    try {
      await updateTerritory(formObj.id, territoryObj);
      setLoading(false);
      setSuccessMessage('Territory has been successfully updated');
      setSelectedTerritory(formObj);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Delete checked territories
  const onDeleteTerritories = async () => {
    await bulkDeleteTerritories(checkedTerritories);

    toggleModalDelete(false);
    setCheckedTerritories([]);
    setSelectedTerritory(null);
  };

  const handleDeleteClick = () => {
    if (checkedTerritories.length) {
      toggleModalDelete(true);
    }
  };

  // Upload territories
  const onUpload = async (territories) => {
    setLoading(true);

    try {
      await uploadTerritories(territories);
      setLoading(false);
      setSuccessMessage('Territories have been successfully uploaded');
      toggleUploadModal(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Duplicate territory
  const onDuplicateClick = async () => {
    if (!checkedTerritories || checkedTerritories.length !== 1) {
      return;
    }

    const territoryId = checkedTerritories[0];
    const territory = territories.find((p) => p.id === territoryId);
    setSelectedTerritory(territory);
    toggleModalDuplicate(true);
  };

  const onDuplicateTerritory = async (formObj) => {
    setLoading(true);
    const territoryObj = {
      name: formObj.name,
      description: formObj.description,
    };

    try {
      await createTerritory(territoryObj);
      setLoading(false);
      toggleModalDuplicate(false);
      setSuccessMessage('Territory has been successfully duplicated');
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    // setCheckedTerritories([]);
  };

  const territoryList = useMemo(() => {
    return territories.filter((territory) => filterTerritories(territory, search, statusFilter));
  }, [territories, search, statusFilter]);

  const handleCheck = (territoryId) => {
    const checked = checkedTerritories.slice();

    if (checked.includes(territoryId)) {
      const index = checked.indexOf(territoryId);

      checked.splice(index, 1);
      setCheckedTerritories(checked);

      return;
    }

    checked.push(territoryId);
    setCheckedTerritories(checked);
  }

  const handleCheckAll = () => {
    const checked = checkedTerritories.length === territoryList.length;
    const temp = [];

    if (!checked) {
      territoryList.forEach((territory) => {
        temp.push(territory.id);
      });

      setCheckedTerritories(temp);
      return;
    }

    setCheckedTerritories(temp);
  };

  const onTagHospitals = async (ids) => {
    try {
      setTagLoading(true);
      await tagTerritories(ids, checkedTerritories);
      toggleTagModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      setTagLoading(false);
    }
  };

  return (
    <div className='settings-cmp__main'>
      <span className='settings-title'>Territories</span>
      <div className='settings-cmp__body'>
        <div className='filters-container'>
          <TerritoriesFilters
            onAddTerritoryClick={() => toggleModalCreate(true)}
            onDelete={handleDeleteClick}
            status={statusFilter}
            setStatus={setStatusFilter}
            statuses={statusFilterOptions}
            onUploadClick={() => toggleUploadModal(true)}
            onDuplicateClick={onDuplicateClick}
            deletePermission={deletePermission}
            selected={checkedTerritories}
            onTagClick={() => toggleTagModal(true)}
          />
          <TerritoriesTable
            onSelectTerritory={onSelectTerritory}
            territories={territoryList}
            selectedTerritoryId={selectedTerritory ? selectedTerritory.id : null}
            handleCheck={handleCheck}
            checkedTerritories={checkedTerritories}
            handleCheckAll={handleCheckAll}
          />
        </div>
        { territories && !!territories.length && (
          <div className='form-container'>
            <Input
              type='search'
              placeholder='Search Territories'
              value={search}
              onChange={handleSearch}
            />
            { selectedTerritory && (
              <TerritoryForm
                initialValues={selectedTerritory}
                buttonText='Save Changes'
                onSubmit={onUpdateTerritory}
                loading={loading}
                mode='update'
              />
            )}
          </div>
        )}
      </div>

      <ConfirmationModal
        open={isModalDeleteOpen}
        onClose={() => toggleModalDelete(false)}
        onSubmit={onDeleteTerritories}
        title='Are you sure you want to delete these territories?'
        submitText='Delete'
      />

      <Modal
        open={isModalCreateOpen}
        onClose={() => toggleModalCreate(false)}
      >
        <TerritoryForm
          buttonText='Add Territory'
          onSubmit={onCreateTerritory}
          loading={loading}
          mode='create'
          onClose={() => toggleModalCreate(false)}
        />
      </Modal>

      <Modal
        open={isModalDuplicateOpen}
        onClose={() => toggleModalDuplicate(false)}
      >
        <TerritoryForm
          initialValues={selectedTerritory}
          buttonText='Duplicate'
          onSubmit={onDuplicateTerritory}
          loading={loading}
          mode='create'
          onClose={() => toggleModalDuplicate(false)}
        />
      </Modal>

      <UploadModal
        open={isUploadModalOpen}
        onClose={() => toggleUploadModal(false)}
        title='Upload Territories'
        tenantColor={tenantColor}
        onSubmit={onUpload}
        loading={loading}
        fields={['Name', 'Description']}
      />

      <Alert
        variant='success'
        message={successMessage}
        open={!!successMessage}
        onClose={() => setSuccessMessage(null)}
      />

      <TagHospitalsModal
        open={isTagModalOpen}
        onClose={() => toggleTagModal(false)}
        hospitals={hospitals}
        onSubmit={onTagHospitals}
        loading={tagLoading}
        territories={territories}
        selectedTerritories={checkedTerritories}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    territories: state.territories.list,
    hospitals: state.hospitals.list,
    tenantColor: state.tenant.currentTenant.colorPrimary,
    deletePermission: state.user.currentUser?.deletePermission,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTerritories: () => dispatch(getTerritories()),
    getHospitals: () => dispatch(getHospitals()),
    createTerritory: (territory) => dispatch(createTerritory(territory)),
    updateTerritory: (id, territoryData) => dispatch(updateTerritory(id, territoryData)),
    subscribeToTerritories: () => dispatch(subscribeToTerritories()),
    bulkDeleteTerritories: (territoryIds) => dispatch(bulkDeleteTerritories(territoryIds)),
    uploadTerritories: (territories) => dispatch(uploadTerritories(territories)),
    tagTerritories: (hospitalIds, territoryIds) => dispatch(tagTerritories(hospitalIds, territoryIds))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TerritoriesPage);
