import React, { useMemo } from 'react';
import moment from 'moment';

import { SetsPieChart } from '../../charts';

import { setAllocationStatuses, flowStepStatuses } from '../../../constants/enums';
import WarningIcon from '@material-ui/icons/Error';

const { DISABLED } = setAllocationStatuses;
const { COMPLETED, PENDING } = flowStepStatuses;

const CaseFlowChart = (props) => {
  const { activeCase, steps } = props;

  const completed = useMemo(() => {
    return steps ? steps.filter((item) => item.status === COMPLETED.value) : [];
  }, [steps]);

  const pending = useMemo(() => {
    return steps ? steps.filter((item) => item.status === PENDING.value) : [];
  }, [steps]);

  const data = [
    { id: COMPLETED.label, label: COMPLETED.label, value: completed?.length, color: COMPLETED.color },
    { id: 'Remaining', label: 'Remaining', value: pending?.length, color: DISABLED.color },
  ];

  const activeStep = useMemo(() => {
    const completed = steps?.filter((item) => item.status === flowStepStatuses.COMPLETED.value);
    const maxStep = Math.max(...completed.map(({ stepNumber }) => stepNumber));

    if (maxStep === steps?.length) {
      return steps[steps?.length - 1];
    }

    const step = steps?.find((item) => item.stepNumber === maxStep);
    const pending = steps?.find((item) => item?.stepNumber === step?.stepNumber + 1);

    if (pending) {
      return pending;
    } else {
      return steps?.find((item) => item?.stepNumber === 1);
    }
  }, [activeCase, steps]);

  const allCompleted = useMemo(() => {
    return steps?.every((allocation) => allocation.status === COMPLETED.value);
  }, [steps]);

  const dueDate = useMemo(() => moment(activeCase?.date).subtract(activeStep?.config?.dueDate, 'days'), [activeCase, activeStep]);
  const diff = useMemo(() => dueDate.diff(moment().startOf('day'), 'days'), [dueDate]);

  return (
    <div className='sets-chart__container' style={{ maxHeight: '277px' }}>
      <div className='flex-1'>
        <SetsPieChart
          data={data}
          margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
          padAngle={0}
          cornerRadius={0}
          transitionMode='endAngle'
        />
      </div>

      <div className='d-flex direction-column space-between align-end' style={{ maxWidth: '150px' }}>
        <div className='d-flex direction-column align-end width-100'>
          <div className='font-bold d-flex'>
            {diff >= 1 && (
              <div style={{ color: COMPLETED.color }}>
                {!allCompleted ? (
                  `Pending due in ${diff} day(s)`
                ) : (
                  'All Completed'
                )}
              </div>
            )}

            {!allCompleted && diff < 1 && (
              <div className='d-flex' style={{ color: flowStepStatuses.PENDING.color }}>
                <span className='font-bold'>Overdue</span>
                <WarningIcon style={{ color: flowStepStatuses.PENDING.color, marginLeft: 8 }} />
              </div>
            )}
          </div>
          <div className='secondary font-bold'>
            {!allCompleted && dueDate.format('Do MMMM')}
          </div>
        </div>
        <div className='sets-chart__legend'>
          <div>
            <div style={{ background: COMPLETED.color }} />
            <div>{COMPLETED.label}</div>
          </div>
          <div>
            <div style={{ background: DISABLED.color }} />
            <div>Remaining</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseFlowChart;
