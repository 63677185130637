import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';

import Input from '../../shared/Input';
import Button from '../../shared/Button';
import Modal from '../../shared/modal/Modal';
import DeleteIcon from '../../../assets/icons/DeleteIcon';

const CaseProformaModal = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    initialValues,
    loading,
    onDelete,
    deleteLoading
  } = props;

  const [code, setCode] = useState('');
  const [description, setDescription] = useState('');
  const [rebateCode, setRebateCode] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [value, setValue] = useState('');
  const [discount, setDiscount] = useState(0);

  useEffect(() => {
    if (open && initialValues) {
      setCode(initialValues?.code || '');
      setDescription(initialValues?.description || '');
      setRebateCode(initialValues?.rebateCode || '');
      setQuantity(initialValues?.quantity || 1);
      setValue(initialValues?.value || '');
      setDiscount(initialValues?.discount || 0);
    }
  }, [open, initialValues]);

  const handleSubmit = () => {
    if (code) {
      onSubmit({ code, description, rebateCode, quantity, value, discount });
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Update Proforma"
    >
      <div className='hospital-addresses__modal' style={{ paddingLeft: '24px', paddingRight: '24px', paddingBottom: '24px' }}>
        <div className="form__body">
          <div className="m-t-md m-b-md">
            <div>
              <div className='field__title'>Item Code</div>
              <Input
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
            <div>
              <div className='field__title'>Description</div>
              <Input
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className='d-flex'>
              <div className='flex-1 m-r-md'>
                <div className='field__title'>Rebate Code</div>
                <Input
                  type="text"
                  value={rebateCode}
                  onChange={(e) => setRebateCode(e.target.value)}
                />
              </div>
              <div className='flex-1 m-l-md'>
                <div className='field__title'>Quantity</div>
                <Input
                  type="text"
                  numbersOnly
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
              </div>
            </div>
            <div className='d-flex'>
              <div className='flex-1 m-r-md'>
                <div className='field__title'>Unit Price ($)</div>
                <NumberFormat
                  value={value}
                  onValueChange={(v) => setValue(v.floatValue)}
                  thousandSeparator={true}
                  prefix={'$'}
                  customInput={Input}
                  fixedDecimalScale={2}
                  decimalScale={2}
                />
              </div>
              <div className='flex-1 m-l-md'>
                <div className='field__title'>Discount (%)</div>
                <Input
                  type="text"
                  numbersOnly
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="d-flex m-t-md flex-end">
            <div className='m-r-lg'>
              <Button
                type='icon'
                onClick={() => onDelete(initialValues.id)}
                loading={deleteLoading}
                disabled={deleteLoading}
              >
                <DeleteIcon />
              </Button>
            </div>
            <Button
              loading={loading}
              disabled={loading || !code}
              type="submit"
              text="Submit"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CaseProformaModal;
