import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { CountInfo, CountNotes, CountTeams, CountScansContainer } from '../../../components/counts';
import CountModal from '../../../components/counts/count-modal/CountModal';
import LoadScreen from '../../../components/load-screen';

import {
  subscribeToCount,
  subscribeToCountNotes,
  subscribeToCountScans,
  updateCount,
  submitCount,
  clearCount,
  deleteCount,
  exportCount,
} from '../../../actions/countsActions';
import { getHospitals } from '../../../actions/hospitalsActions';
import { getSurgeons } from '../../../actions/surgeonsActions';
import { subscribeToGtins } from '../../../actions/gtinReferenceActions';
import { getKits, getSections } from '../../../actions/kitsActions';
import { getSets } from '../../../actions/setsActions';
import { getTags } from '../../../actions/tagsActions';

import { useAlert, useLoading } from '../../../hooks';

import { routes, alertTypes } from '../../../constants';

import '../customers/customers-page.scss';

const CountPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const countId = id.replace(':', '');

  const dispatch = useDispatch();
  const activeCount = useSelector((state) => state.activeCount.data);
  const countNotes = useSelector((state) => state.activeCount.notes);
  const countScans = useSelector((state) => state.activeCount.scans);
  const hospitals = useSelector((state) => state.hospitals.list);
  const users = useSelector((state) => state.users.list);
  const surgeons = useSelector((state) => state.surgeons.list);
  const gtinReference = useSelector((state) => state.gtinReference.list);
  const kits = useSelector((state) => state.kits.list);
  const sets = useSelector((state) => state?.sets.list?.filter((set) => !!set.active));
  const groups = useSelector((state) => state?.groups.list);
  const tags = useSelector((state) => state.tags.list);

  const { loading, startLoading, stopLoading } = useLoading();
  const { showAlert } = useAlert();

  const [isDeleting, toggleDeleting] = useState(false);
  const [isModalOpen, toggleModal] = useState(false);
  const [isExporting, toggleExporting] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const [billOfMaterial, setBOM] = useState(null);

  useEffect(() => {
    const unsubscribeToCountNotes = dispatch(subscribeToCountNotes(countId));
    const unsubscribeToCountScans = dispatch(subscribeToCountScans(countId));
    const unsubscribeToGtins = dispatch(subscribeToGtins());

    onLoad().catch((err) => console.error(err));

    return () => {
      dispatch(clearCount());
      unsubscribeToCountNotes();
      unsubscribeToCountScans();
      unsubscribeToGtins();
    };
  }, []);

  useEffect(() => {
    const unsubscribeToCount = dispatch(subscribeToCount(countId));
    return () => {
      unsubscribeToCount();
    };
  }, [hospitals]);

  useEffect(() => {
    if (activeCount.kit) {
      dispatch(getSections(activeCount.kit))
        .then((res) => {
          if (res && res.length) {
            setBOM(res);
          }
        });
    }
  }, [activeCount]);

  const onLoad = async () => {
    startLoading()
    try {
      await Promise.all([
        dispatch(getHospitals()),
        dispatch(getSurgeons()),
        dispatch(getKits()),
        dispatch(getSets()),
        dispatch(getTags()),
      ]);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onSubmit = async (formObj) => {
    const countObj = {
      hospitalId: formObj.hospitalId || '',
      location: formObj.location || null,
      dueDate: formObj.dueDate,
      taggedUsers: formObj.taggedUsers || [],
      name: formObj.name || '',
      kit: formObj.kit || null,
      groups: formObj.groups || [],
    };

    try {
      await dispatch(updateCount(formObj.id, countObj));
      toggleModal(false);
      showAlert(alertTypes.SUCCESS, 'Count has been successfully updated');
    } catch (err) {
      stopLoading();
      console.error(err);
    }
  };

  const onDelete = async () => {
    toggleDeleting(true);

    try {
      await dispatch(deleteCount(id));
      history.push(routes.INVENTORY);
    } catch (err) {
      console.error(err);
    } finally {
      toggleDeleting(false);
    }
  };

  const onExportClick = async () => {
    toggleExporting(true);

    try {
      await dispatch(exportCount(id));
    } catch (err) {
      console.error(err);
    } finally {
      toggleExporting(false);
    }
  };

  const onSubmitCount = async () => {
    setSubmitting(true);

    try {
      await dispatch(submitCount(id));
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  const onAddClick = () => {
    history.push(`${routes.INVENTORY}/${activeCount.id}${routes.BILL_OF_MATERIAL}`);
  };

  const members = useMemo(() => (
    activeCount.taggedUsers ? activeCount.taggedUsers.map((userId) => users.find((u) => u.uid === userId) || {}) : []
  ), [users, activeCount]);

  return (
    <div className='page-container count-page'>
      { loading && <LoadScreen /> }
      { activeCount && activeCount.id && (
        <>
          <div className='page-title'>
            Count
            <span className='page-subtitle'>{activeCount.id}</span>
          </div>
          <div className='count-page__body'>
            <div>
              <CountInfo
                activeCount={activeCount}
                hospitals={hospitals}
                onDelete={onDelete}
                isDeleting={isDeleting}
                onEditClick={() => toggleModal(true)}
                countScans={countScans}
                kits={kits}
              />
            </div>

            <CountNotes
              notes={countNotes}
              countId={countId}
              isConsigned={!!activeCount?.kit}
            />

            <CountTeams
              members={members}
              isConsigned={!!activeCount?.kit}
            />
          </div>

          <CountScansContainer
            scans={countScans}
            gtinReference={gtinReference}
            exportCount={onExportClick}
            loading={isExporting}
            users={users}
            status={activeCount?.status}x
            onSubmit={onSubmitCount}
            submitting={submitting}
            billOfMaterial={billOfMaterial}
            onAddClick={onAddClick}
            countId={activeCount?.id}
            tags={tags}
          />
        </>
      )}

      <CountModal
        open={isModalOpen}
        onSubmit={onSubmit}
        onClose={() => toggleModal(false)}
        activeCount={activeCount}
        hospitals={hospitals}
        users={users}
        surgeons={surgeons}
        sets={sets}
        kits={kits}
        groups={groups}
        mode='update'
      />
    </div>
  );
};

export default CountPage;
