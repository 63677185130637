import React, { memo } from 'react';

import MuiButton from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { withStyles } from '@material-ui/core/styles';

const StyledButton = withStyles((theme) => ({
  root: {
    width: (props) => props?.width ? props.width : '25px',
    minWidth: (props) => props?.width ? props.width : '25px',
    height: (props) => props?.height ? props.height : '25px',
    borderRadius: '50%',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 'bold',
    background: (props) => props?.background ? props.background : theme.palette.primary.main,
    color: (props) => props?.color ? props.color : theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: (props) => props?.background ? props.background : theme.palette.primary.main,
    },
    '&:active': {
      backgroundColor: (props) => props?.background ? props.background : theme.palette.primary.main,
    },
    '&:focus': {
      backgroundColor: (props) => props?.background ? props.background : theme.palette.primary.main,
    },
    '&:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      borderColor: 'rgba(0, 0, 0, 0.12)',
      color: 'rgba(0, 0, 0, 0.26)'
    }
  }
}))(MuiButton);

const AddButton = (props) => {
  const {
    onClick,
    disabled,
    width = 25,
    height = 25,
    variant = 'add',
  } = props;

  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      width={width}
      height={height}
    >
      {variant === 'remove' ? (
        <RemoveIcon fontSize='small' />
      ) : (
        <AddIcon fontSize='small' />
      )}
    </StyledButton>
  );
};

export default memo(AddButton);
