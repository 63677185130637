import React, { useCallback, useState, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import WarningIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '../shared/Button';
import Input from '../shared/Input';
import Select from '../shared/Select';
import ModalSelectInput from '../shared/modal-select/ModalSelectInput';
import UsersModal from '../shared/modal-select/UsersModal';
import Checkbox from '../shared/Checkbox';
import ModalSelect from '../shared/modal-select/ModalSelect';
import HospitalAddressesModal from '../hospitals/HospitalAddressesModal';
import HospitalAddressesInput from '../hospitals/HospitalAddressesInput';

import { resendUserInvite } from '../../actions/usersActions';

import { useAlert } from '../../hooks';

import { validators, userUtils } from '../../utils';

import routes from '../../constants/routes';
import { roleNames, multiTenantRoles, getRoleLabel, territoryRoles } from '../../constants/userRoles';
import { statusColors } from '../../constants/cases';
import { setAllocationStatuses } from '../../constants/enums';

import { defaultStaticColor } from '../../styles/theme/palette';
import '../../styles/shared/form.scss';

const { ADMIN, SURGEON, SALES_REP } = roleNames;

const UserForm = (props) => {
  const {
    mode = 'create',
    initialValues,
    buttonText,
    onSubmit,
    roleOptions,
    loading,
    companyColor,
    onClose,
    users = [],
    surgeons = [],
    defaultCountry = 'Australia',
    minPasswordLength = 10,
    onDecline,
    onRemoveUser,
    assignSurgeons,
    territories = []
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const { showAlert } = useAlert();

  const { firstName, lastName, role } = initialValues || {};

  const [isModalOpen, toggleModal] = useState(false);
  const [isAddressesModalOpen, toggleAddressesModal] = useState(false);
  const [territoriesModalOpen, toggleTerritoriesModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [checked, setChecked] = useState(false);
  const [password, setPassword] = useState('');

  const isDisabled = useCallback(() => {
    if (!initialValues) {
      return false;
    }

    return (mode === 'update' && multiTenantRoles.includes(role)) || !!initialValues.connected;
  }, [initialValues]);

  const validate = (values) => {
    const errors = {};

    if (!values.firstName) {
      errors.businessName = 'Required';
    }
    if (!values.lastName) {
      errors.country = 'Required';
    }
    if (!values.email) {
      errors.email = 'Required';
    } else if (!validators.emailValidator.test(values.email)) {
      errors.email = 'Not valid';
    }
    if (!values.role) {
      errors.role = 'Required';
    }

    return errors;
  };

  const getSurgeonNames = (surgeonIds) => {
    if (!surgeonIds) {
      return [];
    }
    return surgeonIds
      .filter((id) => surgeons.find((surgeon) => surgeon.id === id))
      .map((id) => {
      const surgeon = surgeons.find((item) => item.id === id);
      return surgeon ? `${surgeon.title} ${surgeon.firstName} ${surgeon.lastName}` : '';
    })
  };

  const filteredSurgeons = useMemo(() => {
    if (!initialValues) {
      return [];
    }

    const { role, assignedSurgeons = [] } = initialValues;

    if (role !== SURGEON) {
      return surgeons;
    }

    return surgeons.filter((surgeon) => {
      if (assignedSurgeons?.includes(surgeon.id)) {
        return true;
      }

      return !users.find((user) => user.role === SURGEON && user.assignedSurgeons?.includes(surgeon.id));
    });
  }, [users, initialValues]);

  const onResendUserInvite = async () => {
    try {
      setIsLoading(true);

      await dispatch(resendUserInvite(initialValues.email));
      showAlert('success', `You successfully sent invite to ${initialValues.email}`);
    } catch (err) {
      showAlert('error', 'Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (formObj) => {
    if (checked) {
      if (password && password.length >= minPasswordLength) {
        onSubmit({ ...formObj, password });
      } else {
        showAlert('warning', `Password must be minimum of ${minPasswordLength} characters`);
      }
    } else {
      if (initialValues?.connected) {
        assignSurgeons(formObj);
      } else {
        onSubmit(formObj);
      }
    }
  };

  const getTerritoryNames = (ids) => {
    return ids?.map((id) => {
      const territory = territories.find((item) => item.id === id);
      return territory ? territory.name : '';
    });
  };

  return (
    <div className='form__container'>
      <Form
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={{
          ...initialValues,
          role: initialValues ? initialValues.role : roleOptions[0].value,
          group: initialValues ? initialValues.group : '',
        }}
        subscription={{ pristine: true, invalid: true, values: true }}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine, values }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <div className='form__subtitle'>
                { firstName && lastName ? `${firstName} ${lastName}` : 'Add User' }
                { multiTenantRoles.includes(role) && !userUtils.isAssigned(initialValues, surgeons) && (
                  <WarningIcon className='m-l-md' style={{ color: statusColors.REQUEST }} />
                )}
              </div>

              { mode === 'update' && !initialValues.connected && (
                <Field name='active'>
                  {({ input, meta }) => (
                    <Input
                      type='switch'
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      placeholder='Status'
                    />
                  )}
                </Field>
              )}

              <div className='combined-fields__container'>
                <Field name='firstName'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      borderless
                      type='text'
                      onChange={input.onChange}
                      startAdornment='First'
                      disabled={isDisabled()}
                    />
                  )}
                </Field>
                <Field name='lastName'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      borderless
                      type='text'
                      onChange={input.onChange}
                      startAdornment='Last'
                      disabled={isDisabled()}
                    />
                  )}
                </Field>
                <Field name='email'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      borderless
                      type='email'
                      onChange={(e) => input.onChange(e.target.value?.toLowerCase())}
                      disabled={mode === 'update'}
                      startAdornment='Email'
                    />
                  )}
                </Field>
              </div>

              { isDisabled() ? (
                <Field name='role'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={getRoleLabel(input.value)}
                      type='text'
                      onChange={input.onChange}
                      startAdornment='Role'
                      disabled
                    />
                  )}
                </Field>
              ) : (
                <div className='m-b-lg'>
                  <div className='field__title'>Role</div>
                  <Field name='role'>
                    {({ input, meta }) => (
                      <Select
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                        input={input}
                        options={roleOptions}
                        endAdornment='Change Role'
                        disabled={isDisabled()}
                      />
                    )}
                  </Field>
                </div>
              )}

              {role === ADMIN && (
                <Field name='deletePermission'>
                  {({ input, meta }) => (
                    <Input
                      type='switch'
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      placeholder='Delete Functionality'
                    />
                  )}
                </Field>
              )}

              {mode === 'create' && (
                <div className='m-t-sm p-b-md'>
                  <div className='m-l-sm'>
                    <Checkbox
                      input={{
                        checked,
                        onChange: (e) => setChecked(e.target.checked)
                      }}
                      fontSize='small'
                      label='Set default password (minimum length 10 characters)'
                    />
                  </div>

                  {checked && (
                    <div className='m-t-md'>
                      <div className='d-flex align-start m-b-md'>
                        <WarningIcon style={{ color: setAllocationStatuses.TRANSFERRED.color, marginRight: 4 }} />
                        <div className='font-bold' style={{ color: setAllocationStatuses.TRANSFERRED.color }}>
                          User will be forced to update upon first login
                        </div>
                      </div>
                      <div className='field__title'>Password</div>
                      <Input
                        type='text'
                        name='password-input'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  )}
                </div>
              )}

              {!multiTenantRoles.includes(role) && role !== ADMIN && mode === 'update' && !initialValues.connected && (
                <Field name='caseWriteAllowed'>
                  {({ input, meta }) => (
                    <Input
                      type='switch'
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      placeholder='Add or Edit Cases'
                    />
                  )}
                </Field>
              )}

              {mode === 'update' && (
                <>
                  {!initialValues?.connected && (
                    <div className='m-b-lg'>
                      <div className='field__title'>Groups</div>
                      <Field name='groups'>
                        {({ input, meta }) => (
                          <ModalSelectInput
                            onClick={() => history.push(routes.GROUPS)}
                            count={input.value?.length}
                            values={input.value.map((value) => value.name)}
                            label='Manage Groups'
                          />
                        )}
                      </Field>
                    </div>
                  )}

                  {mode === 'update' && role === SALES_REP && !initialValues?.connected && (
                    <Field name='deliveryAddresses'>
                      {({ input, meta }) => (
                        <div className='m-b-lg'>
                          <div className='field__title'>Delivery Addresses</div>
                          <HospitalAddressesInput
                            onClick={() => toggleAddressesModal(true)}
                            addresses={input.value || []}
                          />
                          <HospitalAddressesModal
                            open={isAddressesModalOpen}
                            onClose={() => toggleAddressesModal(false)}
                            initialValues={input.value || []}
                            onSubmit={input.onChange}
                            defaultCountry={defaultCountry}
                          />
                        </div>
                      )}
                    </Field>
                  )}

                  {(multiTenantRoles.includes(role) || role === SALES_REP) && (
                    <div>
                      <div className='field__title'>{`Assigned Surgeon${role === SURGEON ? '' : 's'}`}</div>
                      <Field name='assignedSurgeons'>
                        {({ input, meta }) => (
                          <div className='m-b-lg'>
                            <ModalSelectInput
                              onClick={() => toggleModal(true)}
                              values={getSurgeonNames(input.value)}
                              label={`Assign Surgeon${role === SURGEON ? '' : 's'}`}
                              icon='users'
                            />

                            <UsersModal
                              title={initialValues?.name}
                              onChange={input.onChange}
                              selected={input.value}
                              onClose={() => toggleModal(false)}
                              isOpen={isModalOpen}
                              listTitle='Surgeons'
                              users={filteredSurgeons}
                              idField='id'
                              singleSelect={role === SURGEON}
                              searchPlaceholder='Surgeon Surname'
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                  )}

                  {territoryRoles.includes(values.role) && (
                    <div>
                      <div className='field__title'>Territories</div>
                      <Field name='territoryVisibility'>
                        {({ input, meta }) => (
                          <Input
                            type='switch'
                            name={input.name}
                            value={input.value}
                            onChange={input.onChange}
                            placeholder='Territory Visibility'
                          />
                        )}
                      </Field>

                      {values.territoryVisibility && (
                        <>
                          <div className='field__title'>Assigned Territories</div>
                          <Field name='assignedTerritories'>
                            {({ input, meta }) => (
                              <div className='m-b-lg'>
                                <ModalSelectInput
                                  onClick={() => toggleTerritoriesModal(true)}
                                  values={getTerritoryNames(input.value)}
                                  label='Select Territories'
                                />

                                <ModalSelect
                                  title='Select Territories'
                                  listTitle='Territories'
                                  options={territories}
                                  onChange={input.onChange}
                                  selected={input.value}
                                  onClose={() => toggleTerritoriesModal(false)}
                                  isOpen={territoriesModalOpen}
                                />
                              </div>
                            )}
                          </Field>
                        </>
                      )}
                    </div>
                  )}
                </>
              )}

              <div className='form__actions'>
                <Button
                  type='submit'
                  text={buttonText || 'Save Changes'}
                  disabled={invalid || loading || pristine}
                  loading={loading}
                  onClick={handleSubmit}
                />
                { mode === 'update' ? (
                  initialValues?.connected ? (
                    <div
                      className='pointer p-l-md p-r-md'
                      style={{ color: statusColors.OVERDUE }}
                      onClick={onDecline}
                    >
                      {isLoading ? <CircularProgress /> : 'Decline access'}
                    </div>
                  ) : (
                    multiTenantRoles.includes(role) && !userUtils.isAssigned(initialValues, surgeons) ? (
                      <div
                        className='pointer p-l-md p-r-md'
                        style={{ color: statusColors.OVERDUE }}
                        onClick={onRemoveUser}
                      >
                        {isLoading ? <CircularProgress /> : 'Decline access'}
                      </div>
                    ) : (
                      <div
                        className='pointer p-l-md p-r-md'
                        style={{ color: companyColor || defaultStaticColor }}
                        onClick={onResendUserInvite}
                      >
                        {isLoading ? <CircularProgress /> : 'Resend invite'}
                      </div>
                    )
                  )
                ) : (
                  <Button
                    type='cancel'
                    onClick={onClose}
                  />
                )}
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default UserForm;
