import React, { useState, useMemo, useCallback } from 'react';
import moment from 'moment';
import { sum } from 'lodash';

import DeleteIcon from '../../assets/icons/DeleteIcon';

import Button from '../shared/Button';
import { ConfirmationModal } from '../shared/modal';

import { getStatusIcon, getStatusLabel } from '../../utils/counts';

import { statusColors } from '../../constants/counts';
import { countStatusOptions, countStatuses } from '../../constants/enums';

const CountInfo = (props) => {
  const {
    activeCount,
    countScans = [],
    hospitals,
    onDelete,
    isDeleting,
    onEditClick,
    kits = [],
  } = props;
  const {
    dueDate,
    time = '',
    status,
    hospitalId,
    location,
    kit,
    name = '',
  } = activeCount;

  const [deleteModalOpen, toggleDeleteModal] = useState(false);

  const hospitalName = useMemo(() => {
    const caseHospital = hospitals.find((item) => item.id === hospitalId);
    return caseHospital ? caseHospital.name : '';
  }, [hospitalId, hospitals]);

  const getKitName = useCallback((kitId) => {
    const caseKit = kits.find((kit) => kit.id === kitId);
    return caseKit && caseKit.name ? caseKit.name : '';
  }, [activeCount, kits]);

  const total = useMemo(() => (
    sum(countScans?.map((scan) => Number(scan.quantity) || 0))
  ), [countScans]);

  return (
    <div className='case-info__container'>
      <div className='case-actions'>
        <div>
          <Button
            type='submit'
            text='Edit Info'
            onClick={onEditClick}
            disabled={status === countStatusOptions.completed}
          />
          <Button
            type='icon'
            onClick={() => toggleDeleteModal(true)}
          >
            <DeleteIcon style={{ color: '#000000' }}/>
          </Button>
        </div>
      </div>

      <div className='case-info'>
        <div className='case-info__main'>
          <div>
            <div className='d-flex'>
              {getStatusIcon(status, true)}
              <div className='font-size-bg font-bold m-l-md m-r-sm'>
                {dueDate ? moment(dueDate).local().format('Do MMM YYYY') : ''}
              </div>
              <div className='font-bold secondary m-t-sm'>{time}</div>
            </div>
            <div className='m-t-md'>
              <div className='font-size-bg font-bold'>{hospitalName}</div>
              <div>{kit ? getKitName(kit) : location}</div>
            </div>
            {name && (
              <div className='m-t-md'>
                <div className='secondary font-bold'>{name}</div>
              </div>
            )}
            {kit && (
              <div className='m-t-lg'>
                <div
                  className='case-kit-variant'
                  style={{
                    color: countStatuses.REPEATING.color,
                    borderColor: countStatuses.REPEATING.color,
                  }}
                >
                  Consignment
                </div>
              </div>
            )}
          </div>
          <div>
            <div className='case-status' style={{ background: statusColors[status] }}>
              <div className='d-flex direction-column space-between'>
                {getStatusIcon(status)}
                <div className='case-status-label'>
                  {getStatusLabel(status)}
                </div>
              </div>
            </div>

            <div
              className='case-status'
              style={{ background: '#ffffff', border: `3px solid ${statusColors[status]}`, marginTop: 8 }}
            >
              <div className='d-flex direction-column space-between'>
                <span style={{ fontWeight: 'bold', color: statusColors[status], fontSize: 17 }}>
                  {total}
                </span>
                <div style={{ fontWeight: 'bold', color: statusColors[status], fontSize: 10 }}>
                  Total Items
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmationModal
        open={deleteModalOpen}
        onClose={() => toggleDeleteModal(false)}
        onSubmit={onDelete}
        title='Are you sure you want to delete this count?'
        submitText='Delete'
        loading={isDeleting}
      />
    </div>
  );
};

export default CountInfo;
