import React from 'react';

import KitsIcon from '../../../assets/icons/KitIcon';

import { setPositionOptions } from '../../../constants/enums';

import './checklists.scss';

const KitInfo = (props) => {
  const { kitId, name, type } = props;

  return (
    <div className='case-checklists-info__container flex-1 m-r-lg m-l-lg' style={{ flex: 0.6 }}>
      <div className='d-flex space-between'>
        <div className='d-flex'>
          <KitsIcon style={{ color: setPositionOptions.CONSIGNED.color }} />
          <span className='font-bold font-size-bg m-l-sm'>{kitId}</span>
        </div>
        <div className='font-bold font-size-bg' style={{ color: setPositionOptions.CONSIGNED.color }}>
          {setPositionOptions.CONSIGNED.label}
        </div>
      </div>
      <div className='d-flex space-between'>
        <div>
          <span>{`${name || ''} – ${type || ''}`}</span>
        </div>
      </div>
    </div>
  );
};

export default KitInfo;
