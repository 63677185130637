import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { startCase, toLower, sortBy } from 'lodash';
import moment from 'moment';

import {
  DriveActions,
  DriveBreadcrumbs,
  DriveFileForm,
  DriveFolderForm,
  DriveFilesList,
  TreeViewContainer,
  MoveFileModal,
} from '../../../components/drive';
import Input from '../../../components/shared/Input';
import Modal, { ConfirmationModal } from '../../../components/shared/modal';

import {
  subscribeToDrive,
  createFolder,
  updateFolder,
  deleteFolder,
  getFiles,
  addFile,
  updateFile,
  deleteFile,
  moveFile,
} from '../../../actions/driveActions';
import { getProducts } from '../../../actions/productsActions';
import { getGroups } from '../../../actions/groupsActions';
import { getKits } from '../../../actions/kitsActions';

import { useLoading, useAlert } from '../../../hooks';

import { utils } from '../../../utils';

import { driveConstants, alertTypes, userRoles } from '../../../constants';
import { getFilePath } from '../../../utils/drive';

const roleOptions = Object.values(userRoles).map(({ label, name }) => ({ label, value: name }));

const { driveItemTypes } = driveConstants;

const DriveSettingsPage = () => {
  const dispatch = useDispatch();
  const tenant = useSelector((state) => state.tenant.currentTenant);
  const folders = useSelector((state) => [{ id: 'root', name: 'Drive' }, ...state.drive.folders]);
  const products = useSelector((state) => state.products.list);
  const groups = useSelector((state) => state.groups.list);
  const kits = useSelector((state) => state.kits.list);
  const deletePermission = useSelector((state) => state.user?.currentUser?.deletePermission);

  const { loading, startLoading, stopLoading } = useLoading(false);
  const { showAlert } = useAlert();

  const [activeFolder, setActiveFolder] = useState('root');
  const [activeFiles, setActiveFiles] = useState([]);
  const [activeFile, setActiveFile] = useState(null);
  const [fetchingFiles, setFetchingFiles] = useState(false);

  const [search, setSearch] = useState('');

  const [driveModal, toggleDriveModal] = useState(null);
  const [deleteFolderModal, toggleDeleteFolderModal] = useState(null);
  const [deleteFileModal, toggleDeleteFileModal] = useState(null);
  const [moveFileModal, toggleMoveFileModal] = useState(null);
  const [moving, setMoving] = useState(false);

  useEffect(() => {
    const unsubscribe = dispatch(subscribeToDrive());

    onLoad().catch((err) => console.error(err));

    return () => {
      unsubscribe();
    };
  }, []);

  const onLoad = async () => {
    try {
      await Promise.all([
        dispatch(getProducts()),
        dispatch(getGroups()),
        dispatch(getKits()),
      ]);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (activeFolder) {
      if (activeFolder !== 'root') {
        fetchFolderFiles(activeFolder)
          .catch((err) => console.error(err));
      } else {
        setActiveFiles([]);
      }
    }
  }, [activeFolder]);

  const fetchFolderFiles = async (folderId, withoutLoading) => {
    if (!withoutLoading) {
      setFetchingFiles(true);
    }

    try {
      const files = await dispatch(getFiles(folderId));
      setActiveFiles(sortBy(files, ['title']));
    } catch (err) {
      console.error(err);
    } finally {
      setFetchingFiles(false);
    }
  };

  const tree = useMemo(() => {
    const children = utils.flatArrayToTree(folders);
    return {
      id: 'root',
      name: 'Drive',
      children,
    };
  }, [folders]);

  const onAddClick = (optionId) => {
    setActiveFile(null);
    toggleDriveModal(optionId);
  };

  const onDeleteClick = () => {
    if (activeFolder && activeFolder !== 'root') {
      if (activeFile) {
        return toggleDeleteFileModal(true);
      }
      return toggleDeleteFolderModal(true);
    }
  };

  // Folders

  const onCreateFolder = async (formData) => {
    startLoading();

    const folderObj = {
      name: formData.name,
      parent: activeFolder || 'root',
      allowedRoles: roleOptions.map((role) => role.value),
    };

    try {
      await dispatch(createFolder(folderObj));
      stopLoading();
      toggleDriveModal(null);
      showAlert(alertTypes.SUCCESS, `Folder "${formData.name}" has been successfully created`);
    } catch (err) {
      stopLoading();
      console.error(err);
    }
  };

  const onUpdateFolder = async (formData) => {
    startLoading();

    const folderObj = {
      active: formData.active,
      name: formData.name,
      allowedRoles: formData.allowedRoles,
      allowedGroups: formData?.allowedGroups?.length === groups?.length ? 'ALL' : (formData?.allowedGroups || []),
    };

    try {
      await dispatch(updateFolder(formData.id, folderObj));
      stopLoading();
      showAlert(alertTypes.SUCCESS, 'Folder has been successfully updated');
    } catch (err) {
      stopLoading();
      console.error(err);
    }
  };

  const onDeleteFolder = async () => {
    try {
      setActiveFolder(null);
      setActiveFile(null);
      setActiveFiles([]);
      await dispatch(deleteFolder(activeFolder));
      toggleDeleteFolderModal(false);
    } catch (err) {
      console.error(err);
    }
  };

  // Files

  const onAddFile = async (formData, type, files) => {
    startLoading();

    if (files?.file?.size > 200 * 1024 * 1024) {
      showAlert(
        alertTypes.ERROR,
        'This file is too large to be uploaded directly to the Drive. Please save it elsewhere and add to the Drive via a URL link.'
      );
      stopLoading();
      return;
    }

    const folder = folders.find((item) => item.id === activeFolder);

    const fileObj = {
      type,
      active: moment().isSame(formData.publishAt, 'day') ? true : formData.active,
      title: formData.title,
      subtitle: formData.subtitle || '',
      publishAt: formData.publishAt || moment(),
      expireAt: formData.expireAt || null,
      folder: activeFolder || 'root',
      allowedRoles: folder ? folder.allowedRoles : roleOptions.map((role) => role.value),
      allowedGroups: folder && folder.allowedGroups ? folder.allowedGroups : 'ALL',
      dispatchDocument: false,
      allProducts: false,
    };

    if (type === driveItemTypes.link) {
      fileObj.file = {
        fileName: formData.fileName,
        downloadUrl: formData.fileName,
        path: ''
      };
    }

    try {
      await dispatch(addFile(activeFolder, fileObj, files));
      await fetchFolderFiles(activeFolder, true);
      stopLoading();
      toggleDriveModal(null);
      showAlert(alertTypes.SUCCESS, 'File has been successfully uploaded');
    } catch (err) {
      stopLoading();
      console.error(err);
    }
  };

  const onUpdateFile = async (formData, type, files) => {
    startLoading();

    if (files?.file?.size > 50 * 1024 * 1024) {
      showAlert(
        alertTypes.ERROR,
        'This file is too large to be uploaded directly to the Drive. Please save it elsewhere and add to the Drive via a URL link.'
      );
      stopLoading();
      return;
    }

    const fileObj = {
      active: moment().isSame(formData.publishAt, 'day') ? true : formData.active,
      title: formData.title,
      subtitle: formData.subtitle,
      folder: formData.folder,
      publishAt: formData.publishAt || moment(),
      expireAt: formData.expireAt || null,
      relatedProducts: formData.relatedProducts,
      relatedKits: formData.relatedKits,
      allowedRoles: formData.allowedRoles,
      allowedGroups: !formData.allowedGroups || formData.allowedGroups.length === groups.length ? 'ALL' : formData.allowedGroups,
      allowSharing: formData.allowSharing,
      allProducts: !!formData.allProducts,
    };

    if (type === driveItemTypes.link) {
      fileObj.file = {
        fileName: formData.fileName,
        downloadUrl: formData.fileName,
        path: ''
      };
      fileObj.openInBrowser = !!formData.openInBrowser;
    }

    if (type === driveItemTypes.document) {
      fileObj.dispatchDocument = !!formData.dispatchDocument;
    }

    try {
      await dispatch(updateFile(activeFolder, formData.id, fileObj, files));
      await fetchFolderFiles(activeFolder, true);
      stopLoading();
      showAlert(alertTypes.SUCCESS, 'File has been successfully updated');
    } catch (err) {
      stopLoading();
      console.error(err);
    }
  };

  const onMoveFile = async (folderId) => {
    if (activeFile) {
      try {
        setMoving(true);
        const fileObj = activeFiles?.find((f) => f.id === activeFile);
        if (fileObj && fileObj.file) {
          const oldPath = fileObj.file?.path;
          const newPath = getFilePath(fileObj.id, folders.slice(1, folders.length), folderId, tenant.id, fileObj?.file?.fileName);
          await dispatch(moveFile(fileObj, oldPath, newPath, folderId));
          setActiveFile(null);
          setActiveFolder(null);
          setActiveFiles([]);
          toggleMoveFileModal(false);
        }
      } finally {
        setMoving(false);
      }
    }
  };

  const onDeleteFile = async () => {
    try {
      setActiveFile(null);
      await dispatch(deleteFile(activeFolder, activeFile));
      await fetchFolderFiles(activeFolder, true);
      toggleDeleteFileModal(false);
    } catch (err) {
      console.error(err);
    }
  };

  // Render forms

  const renderCreateForm = () => {
    if (driveModal === driveItemTypes.folder) {
      return <DriveFolderForm
        buttonText='Add Folder'
        onSubmit={onCreateFolder}
        loading={loading}
        mode='create'
        onClose={() => toggleDriveModal(null)}
      />;
    } else {

      return <DriveFileForm
        buttonText={`Add ${startCase(toLower(driveModal))}`}
        onSubmit={onAddFile}
        loading={loading}
        mode='create'
        type={driveModal}
        onClose={() => toggleDriveModal(null)}
      />
    }
  };

  const renderUpdateForm = () => {
    if (activeFile) {
      const initialValues = activeFiles.find((item) => item.id === activeFile);
      return <DriveFileForm
        initialValues={{
          ...initialValues,
          fileName: initialValues.file ? initialValues.file.fileName : '',
          previewImageName: initialValues.previewImage ? initialValues.previewImage.fileName : '',
          allowedGroups: initialValues.allowedGroups && initialValues.allowedGroups === 'ALL' ?
            groups.map((item) => item.id) : initialValues.allowedGroups,
          relatedProducts: initialValues && initialValues.relatedProducts ? initialValues.relatedProducts : [],
          relatedKits: initialValues && initialValues.relatedKits ? initialValues.relatedKits : [],
        }}
        buttonText='Save Changes'
        onSubmit={onUpdateFile}
        loading={loading}
        mode='update'
        type={initialValues.type}
        onClose={() => toggleDriveModal(null)}
        products={products}
        kits={kits}
        roleOptions={roleOptions}
        groups={groups}
      />
    }

    if (activeFolder) {
      const initialValues = folders.find((item) => item.id === activeFolder);
      return <DriveFolderForm
        initialValues={{
          ...initialValues,
          allowedGroups: initialValues.allowedGroups && initialValues.allowedGroups === 'ALL' ?
            groups.map((item) => item.id) : initialValues.allowedGroups,
        }}
        onSubmit={onUpdateFolder}
        loading={loading}
        mode='update'
        onClose={() => toggleDriveModal(null)}
        roleOptions={roleOptions}
        groups={groups}
      />;
    }
  };

  const filesList = useMemo(() => (
    activeFiles.filter((file) => file?.title.toLowerCase().includes(search.toLowerCase()))
  ), [activeFiles, search]);

  return (
    <div className='settings-cmp__main flex-1'>
      <span className='settings-title'>Drive</span>
      <div className='settings-cmp__body flex-1'>
        <div className='settings-block__left'>
          <DriveActions
            activeFolder={activeFolder}
            onAddClick={onAddClick}
            onDeleteClick={onDeleteClick}
            deletePermission={deletePermission}
            onMoveFileClick={() => toggleMoveFileModal(true)}
            activeFile={activeFile}
          />
          <TreeViewContainer
            data={tree}
            colorPrimary={tenant.colorPrimary}
            onClick={(folderId) => {
              setActiveFile(null);
              setActiveFolder(folderId);
            }}
            active={activeFolder}
          />
        </div>
        <div className='settings-block__middle'>
          <DriveBreadcrumbs
            data={folders}
            colorPrimary={tenant.colorPrimary}
            onClick={(folderId) => {
              setActiveFile(null);
              setActiveFolder(folderId);
            }}
            active={activeFolder}
          />
          <DriveFilesList
            loading={fetchingFiles}
            files={filesList}
            onClick={setActiveFile}
            activeFile={activeFile}
            colorPrimary={tenant.colorPrimary}
          />
        </div>
        <div className='settings-block__right'>
          <Input
            type='search'
            placeholder='Search'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          { activeFolder && activeFolder !== 'root' && renderUpdateForm()}
        </div>
      </div>

      <Modal
        open={!!driveModal}
        onClose={() => toggleDriveModal(null)}
      >
        {renderCreateForm()}
      </Modal>

      <ConfirmationModal
        open={!!deleteFolderModal}
        onClose={() => toggleDeleteFolderModal(false)}
        onSubmit={onDeleteFolder}
        title='Are you sure you want to delete this folder?'
        text='By deleting this folder you will also be deleting any files or folders within it!'
        submitText="Delete"
      />
      <ConfirmationModal
        open={!!deleteFileModal}
        onClose={() => toggleDeleteFileModal(false)}
        onSubmit={onDeleteFile}
        title='Are you sure you want to delete this file?'
        submitText="Delete"
      />

      <MoveFileModal
        open={moveFileModal}
        onClose={() => toggleMoveFileModal(false)}
        tree={tree}
        tenantColor={tenant.colorPrimary}
        file={activeFiles?.find((f) => f.id === activeFile)}
        onSubmit={onMoveFile}
        loading={moving}
      />
    </div>
  );
};

export default DriveSettingsPage;
