import React, { useEffect, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';

import { ThemeProvider } from '@material-ui/core/styles';

import AppPage from './pages/app';
import {
  LoginPage,
  SignUpPage,
  ForgotPasswordPage,
} from './pages/auth';

import SetPasswordPage from './pages/auth/SetPasswordPage';

import LoadScreen from './components/load-screen';
import Alert from './components/shared/Alert';

import { useAlert } from './hooks';

import { getCurrentUser, setCurrentUser } from './actions/currentUserActions';
import { getCurrentTenant } from './actions/tenantActions';

import { routes } from './constants';

import theme from './styles/theme/theme';
import { defaultStaticColor } from './styles/theme/palette';

const App = (props) => {
  const { getCurrentUser, currentUser, getCurrentTenant, currentTenant, isLoaded } = props;

  const { isOpen, variant, message, closeAlert } = useAlert();

  useEffect(() => {
    onLoad().catch((err) => console.error(err));
  }, []);

  const onLoad = async () => {
    await getCurrentUser();
    await getCurrentTenant();
  };

  const muiTheme = useMemo(() => {
    return theme(currentTenant.colorPrimary || defaultStaticColor);
  }, [currentTenant]);

  return (
    <ThemeProvider theme={muiTheme}>
      <div className='app'>
        {isLoaded ? (
          <>
            {!currentUser.uid ? (
              <Switch>
                <Route exact path={routes.SIGN_IN} component={LoginPage} />
                <Route exact path={routes.SIGN_UP} component={SignUpPage} />
                <Route exact path={routes.FORGOT_PASSWORD} component={ForgotPasswordPage} />
                <Route exact path={routes.SET_PASSWORD} component={SetPasswordPage} />
                <Redirect to={routes.SIGN_IN} />
              </Switch>
            ) : (
              <Switch>
                <Route component={AppPage} />
              </Switch>
            )}
          </>
        ) : (
          <LoadScreen />
        )}
      </div>
      <Alert
        variant={variant}
        message={message}
        open={isOpen}
        onClose={closeAlert}
      />
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
    currentTenant: state.tenant.currentTenant,
    isLoaded: state.user.isLoaded && state.tenant.isLoaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCurrentUser: () => dispatch(getCurrentUser()),
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
    getCurrentTenant: () => dispatch(getCurrentTenant())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
