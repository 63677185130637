import React, { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import WarningIcon from '@material-ui/icons/Error';
import DriveIcon from '../../../assets/icons/DriveIcon';
import NotesIcon from '../../../assets/icons/NotesIcon';
import DeleteIcon from '../../../assets/icons/DeleteIcon';
import TurnBackIcon from '../../../assets/icons/TurnBackIcon';

import Table from '../../shared/table/Table';
import TableButton from '../../shared/table/TableButton';

import { formatPrice } from '../../../utils/utils';

import { caseChecklistStatuses, kitVariantTypes, setAllocationStatuses, consumableStatuses } from '../../../constants/enums';
import routes from '../../../constants/routes';
import userRoles from '../../../constants/userRoles';

const { INSPECT, COMPLETE, INCOMPLETE } = caseChecklistStatuses;
const { USAGE } = setAllocationStatuses;

const CaseSetsTable = (props) => {
  const {
    caseId,
    setsAllocation,
    checkedSets,
    onSelectAllocation,
    selectedAllocationId,
    handleCheck,
    handleCheckAll,
    kits,
    items,
    sets,
    onButtonClick,
    onKitNotesClick,
    caseChecklists,
    billOfMaterial,
    kitVariant,
    warningKits = [],
    additional,
    onDeleteClick,
    onRevertShipping,
    userRole
  } = props;

  const history = useHistory();

  const columns = useMemo(() => {
    let arr = [
      {
        title: '',
        field: 'kit',
        type: 'custom',
        formatter: (value, row) => {
          return <div className="d-flex">
            {!!value && !row.itemId && !row?.consumables?.length && (
              <div className="set-indicator">
                S
              </div>
            )}
            {warningKits && !!warningKits?.length && warningKits?.includes(row?.kit) && (
              <WarningIcon style={{ color: setAllocationStatuses.QUARANTINED.color, marginLeft: 4 }} />
            )}
          </div>
        },
      },
      { title: 'kit/item', field: 'kitId', type: 'default', sort: true },
      { title: 'name/description', field: 'kitName', type: 'default', sort: true },
      { title: 'qty', field: 'quantity', type: 'default', sort: true },
      {
        title: 'status',
        field: 'status',
        type: 'setAllocationStatus',
        sort: true,
      },
      {
        title: 'set #',
        field: 'setNames',
        type: 'custom',
        sort: false,
        formatter: (value) => {
          return <div>{value?.map((set) => <div key={additional ? `${set}_additional` : set}>{set}</div>)}</div>
        },
      },
    ];

    if (kitVariant === kitVariantTypes.consignment) {
      arr = [
        ...arr,
        { title: 'confirmed', field: 'shippingDate', type: 'date', sort: true },
        {
          title: 'reference', field: 'trackingNumber', type: 'custom', sort: true, formatter: (value, row) => (
            <div>{row?.freightCost ? `${value} ($${formatPrice(row.freightCost)})` : value}</div>
          ),
        },
        {
          title: '',
          field: 'kit',
          type: 'custom',
          sort: false,
          formatter: (value) => (
            <TableButton
              onClick={() => {
                if (userRole !== userRoles.SALES_REP.name) {
                  onButtonClick(value)
                }
              }}
            >
              <DriveIcon />
            </TableButton>
          ),
        },
      ]
    }

    if (kitVariant === kitVariantTypes.loan) {
      arr = [
        ...arr,
        {
          title: 'inspection',
          field: 'inspection',
          type: 'custom',
          sort: false,
          formatter: (value, row) => {
            const checklists = caseChecklists?.filter((item) => row.sets?.includes(item.id));
            const checklist = checklists?.find((item) => item.status === INSPECT.value || item.status === INCOMPLETE.value || item.status === COMPLETE.value)

            if (!billOfMaterial[row.kit] || !checklist) {
              return '';
            }

            const url = `${routes.CASES}/${caseId}${routes.CHECKLISTS}?id=${checklist.id}`;

            if (checklist.status === INSPECT.value) {
              return (
                <div
                  className="p-r-md p-t-md p-b-md"
                  onClick={() => {
                    if (userRole !== userRoles.SALES_REP.name) {
                      history.push(url);
                    }
                  }}
                >
                  <span
                    style={{ color: INSPECT.color }}
                  >
                  {INSPECT.label}
                </span>
                </div>
              );
            } else if (checklist.status === COMPLETE.value) {
              return (
                <span
                  style={{ color: COMPLETE.color }}
                  onClick={() => {
                    if (userRole !== userRoles.SALES_REP.name) {
                      history.push(url);
                    }
                  }}
                >
                  {COMPLETE.label}
                </span>
              );
            } else {
              return (
                <span
                  style={{ color: INCOMPLETE.color }}
                  onClick={() => {
                    if (userRole !== userRoles.SALES_REP.name) {
                      history.push(url);
                    }
                  }}
                >
                  {INCOMPLETE.label}
                </span>
              );
            }
          },
        },
        { title: 'shipped', field: 'shippingDate', type: 'date', sort: true },
        {
          title: 'tracking', field: 'trackingNumber', type: 'custom', sort: true, formatter: (value, row) => (
            row?.freightCost ? `${value} ($${formatPrice(row.freightCost)})` : value
          ),
        },
        { title: 'resolved', field: 'returnDate', type: 'date', sort: true },
        {
          title: '',
          field: 'kit',
          type: 'custom',
          sort: false,
          formatter: (value) => (
            <TableButton
              onClick={() => {
                if (userRole !== userRoles.SALES_REP.name) {
                  onButtonClick(value);
                }
              }}
            >
              <DriveIcon />
            </TableButton>
          ),
        },
      ]
    }

    if (setsAllocation.some((allocation) => !!allocation?.shippingNote)) {
      arr.push({ title: '', field: 'shippingNote', type: 'action', sort: false });
    }

    if (setsAllocation.some((allocation) => allocation?.status === setAllocationStatuses.SHIPPED.value) && userRole === userRoles.ADMIN.name) {
      arr.push({
        title: '',
        field: 'status',
        type: 'custom',
        sort: false,
        formatter: (value, row) => value === setAllocationStatuses.SHIPPED.value ? (
          (
            <TableButton
              onClick={() => {
                if (userRole !== userRoles.SALES_REP.name) {
                  onRevertShipping(row);
                }
              }}
            >
              <TurnBackIcon color="#F97032" />
            </TableButton>
          )
        ) : null,
      });
    }

    if (setsAllocation.some((allocation) => allocation?.status === setAllocationStatuses.AVAILABLE.value)) {
      arr.push({
        title: '',
        field: 'status',
        type: 'custom',
        sort: false,
        formatter: (value, row) => value === setAllocationStatuses.AVAILABLE.value ? (
          (
            <TableButton
              onClick={() => {
                if (userRole !== userRoles.SALES_REP.name) {
                  onDeleteClick(row);
                }
              }}
            >
              <DeleteIcon color="#F53434" />
            </TableButton>
          )
        ) : null,
      });
    }

    if (setsAllocation.some((allocation) => !!allocation?.consumables?.length)) {
      arr.splice(4, 0, {
        title: 'LOT/SERIAL',
        field: 'consumables',
        type: 'custom',
        formatter: (value, row) => {
          if (!value || !value.length) {
            return '';
          } else {
            return (
              <div>
                {value?.map((consumable) => (
                  <div
                    key={consumable?.id ? consumable.id : `${consumable}_${Math.random()}`}
                    style={{
                      color: consumable?.status ?
                        (consumableStatuses[consumable.status]?.color || 'inherit') :
                        (row?.usageConsumables && row?.usageConsumables?.includes(consumable) ? USAGE.color : 'inherit'),
                    }}
                  >
                    {consumable?.id ? consumable?.label : consumable}
                  </div>
                ))}
              </div>
            );
          }
        },
        sort: true,
      });
    }

    return arr;
  }, [setsAllocation, billOfMaterial, caseChecklists]);

  const getKitId = useCallback((kitId) => {
    const kit = kits.find((item) => item.id === kitId);
    return kit ? kit.kitId : '';
  }, [setsAllocation, kits]);
  const getKitName = useCallback((kitId) => {
    const kit = kits.find((item) => item.id === kitId);
    return kit ? kit.name : '';
  }, [setsAllocation, kits]);
  const getSetNames = useCallback((setIds) => {
    return setIds.map((setId) => {
      const set = sets.find((item) => item.id === setId);
      return set ? set.number : '';
    });
  }, [setsAllocation, sets]);
  const getItemCode = useCallback((itemId) => {
    const item = items.find((item) => item.id === itemId);
    return item ? item.code : '';
  }, [setsAllocation, items]);
  const getItemDescription = useCallback((itemId) => {
    const item = items.find((item) => item.id === itemId);
    return item ? (item.checklistDescription || item.description) : '';
  }, [setsAllocation, items]);

  return (
    <div style={{ width: '100%' }}>
      <Table
        rows={setsAllocation.map((item) => ({
          ...item,
          kitId: item.itemId ? getItemCode(item.itemId) : getKitId(item.kit),
          kitName: item.itemId ? getItemDescription(item.itemId) : getKitName(item.kit),
          setNames: getSetNames(item.sets || []),
          kitDocuments: {
            onClick: () => {
              if (userRole !== userRoles.SALES_REP.name) {
                onButtonClick(item.kit);
              }
            },
            icon: <DriveIcon />,
          },
          // deleteSetsAllocation: {
          //   onClick: item.status === setAllocationStatuses.AVAILABLE.value ? () => onDeleteClick(item) : null,
          //   icon: <DeleteIcon color='#F53434' />,
          // },
          shippingNote: {
            onClick: item.shippingNote ? () => {
              if (userRole !== userRoles.SALES_REP.name) {
                onKitNotesClick(item);
              }
            } : null,
            icon: <NotesIcon />,
          },
        }))}
        columns={columns}
        onRowClick={onSelectAllocation}
        selected={selectedAllocationId}
        handleCheck={handleCheck}
        checkedRows={checkedSets}
        handleCheckAll={handleCheckAll}
        rowColor="#ffffff"
        rowBorderColor="#fafafa"
        className="sets-table"
      />
    </div>
  );
};

export default CaseSetsTable;
