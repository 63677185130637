import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function InterfaceEditIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M5.38994 2.41016C4.52064 2.44931 3.66799 2.54449 2.83659 2.64066C1.87707 2.75165 1.10971 3.51864 1.00248 4.47858C0.88194 5.55766 0.765625 6.67261 0.765625 7.81333C0.765625 8.95406 0.88194 10.069 1.00248 11.1481C1.10971 12.108 1.87707 12.875 2.83658 12.986C3.9208 13.1114 5.04115 13.2352 6.18744 13.2352C7.33373 13.2352 8.45407 13.1114 9.53829 12.986C10.4978 12.875 11.2652 12.1081 11.3724 11.1481C11.4591 10.3719 11.5436 9.57711 11.5842 8.76752"/>
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M10.1085 1.30268L6.42359 5.48384L5.91877 7.85816C5.83754 8.2402 6.23577 8.60169 6.60871 8.48445L8.97152 7.74164L12.7776 3.73673C13.4098 3.07156 13.2995 1.95902 12.5343 1.28221C11.787 0.62125 10.701 0.630416 10.1085 1.30268Z"/>
  </SvgIcon>
}

