import React from 'react';
import { Field, Form } from 'react-final-form';

import MuiPhoneNumber from 'material-ui-phone-number';

import Button from '../../shared/Button';
import Input from '../../shared/Input';

const StepThree = ({ step, onStepNext, onStepBack, initialValues }) => {
  const validate = (values) => {
    const errors = {};

    if (!values.address1 || !values.address2) {
      errors.address1 = 'Required';
    }
    if (!values.city) {
      errors.city = 'Required';
    }
    if (!values.state) {
      errors.state = 'Required';
    }
    if (!values.postCode) {
      errors.postCode = 'Required';
    }
    if (!values.phone) {
      errors.phone = 'Required';
    }
    // if (!validators.phoneValidator.test(values.phone)) {
    //   errors.phone = 'Not valid';
    // }

    return errors;
  };

  return (
    <div className='step__container'>
      <span className='second-step__title'>Enter your business information</span>
      <span className='second-step__subtitle'>Enter your business information to set up your environment.</span>
      <div className='step__body'>
        <Form
          onSubmit={(formObj) => onStepNext(step, formObj)}
          initialValues={initialValues}
          validate={validate}
        >
          {
            ({ handleSubmit, submitting, invalid }) => (
              <form
                onSubmit={handleSubmit}
                className='first-step__form'
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    handleSubmit();
                  }
                }}
              >
                <span className='step_subtitle'>Company Address</span>
                <div className='full-name__container'>
                  <Field name='address1'>
                    {({ input, meta }) => (
                      <>
                        <span>
                          {meta.error && meta.touched &&
                          <span className='form__error'>{`${meta.error}*`}</span>}
                        </span>
                        <Input
                          name={input.name}
                          value={input.value}
                          startAdornment={'Street'}
                          borderless
                          type='text'
                          onChange={input.onChange}
                        />
                      </>
                    )}
                  </Field>
                  <Field name='address2'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        startAdornment={'Street'}
                        borderless
                        type='text'
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                  <Field name='city'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        startAdornment={'City'}
                        borderless
                        type='text'
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>

                <Field name='state'>
                  {({ input, meta }) => (
                    <>
                      <span className='step_subtitle'>State
                        {meta.error && meta.touched &&
                        <span className='form__error'>{`${meta.error}*`}</span>}
                      </span>
                      <Input
                        name={input.name}
                        value={input.value}
                        placeholder='State'
                        type='text'
                        onChange={input.onChange}
                      />
                    </>
                  )}
                </Field>

                <Field name='postCode'>
                  {({ input, meta }) => (
                    <>
                      <span className='step_subtitle'>Postcode
                        {meta.error && meta.touched &&
                        <span className='form__error'>{`${meta.error}*`}</span>}
                      </span>
                      <Input
                        name={input.name}
                        value={input.value}
                        placeholder='Postcode'
                        type='text'
                        onChange={input.onChange}
                      />
                    </>
                  )}
                </Field>

                <Field name='phone'>
                  {({ input, meta }) => (
                    <>
                      <span className='step_subtitle'>Business phone number
                        {meta.error && meta.touched &&
                        <span className='form__error'>{`${meta.error}*`}</span>}
                      </span>
                      <MuiPhoneNumber
                        value={input.value}
                        onChange={input.onChange}
                        fullWidth
                        placeholder="Phone"
                        defaultCountry={'au'}
                        className='form__phone p-l-md'
                        InputProps={{ disableUnderline: true }}
                      />
                    </>
                  )}
                </Field>

                <div className='form__next'>
                  <Button
                    type='secondary'
                    text='Back'
                    onClick={() => onStepBack(step)}
                  />
                  <Button
                    type='submit'
                    text='Next'
                    disabled={submitting || invalid}
                    onClick={handleSubmit}
                  />
                </div>
              </form>
            )}
        </Form>
      </div>
    </div>
  );
};

export default StepThree;
