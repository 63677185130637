import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function ImplantIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M6.58492 12.3625C6.44765 12.3463 6.3113 12.3292 6.17597 12.3113C5.74394 12.2542 5.40449 11.9147 5.34733 11.4827C5.32943 11.3474 5.31232 11.211 5.29614 11.0737" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M8.52795 12.5132C8.73321 12.5199 8.93959 12.5235 9.14686 12.5235C9.35413 12.5235 9.56052 12.5199 9.76577 12.5132" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M12.9976 11.0737C12.9814 11.211 12.9643 11.3474 12.9464 11.4827C12.8893 11.9147 12.5498 12.2542 12.1178 12.3113C11.9824 12.3292 11.846 12.3463 11.7087 12.3625" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M13.1482 9.13076C13.1549 8.92551 13.1585 8.71912 13.1585 8.51185C13.1585 8.30458 13.1549 8.0982 13.1482 7.89294" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M11.7089 4.66113C11.8461 4.67731 11.9825 4.69442 12.1178 4.71232C12.5498 4.76948 12.8893 5.10893 12.9465 5.54096C12.9644 5.67629 12.9815 5.81264 12.9976 5.94991" />
    <path stroke={color || defaultColor} fill="none" strokeLinejoin="round"
          d="M2.74919 1.12619C3.47937 1.04879 4.23365 1.00012 5.00012 1.00012C5.7666 1.00012 6.52088 1.04879 7.25105 1.12619C8.11454 1.21772 8.79661 1.89704 8.8839 2.76096C8.95727 3.48715 9.00012 3.78389 9.00012 4.5457C8.23365 4.5457 7.47937 4.59437 6.74919 4.67177C5.88571 4.7633 5.20364 5.44262 5.11635 6.30654C5.04297 7.03273 5.00012 8.23831 5.00012 9.00012C4.23365 9.00012 3.47937 8.95146 2.74919 8.87405C1.88571 8.78252 1.20364 8.10321 1.11635 7.23928C1.04297 6.51309 1.00012 5.76193 1.00012 5.00012C1.00012 4.23831 1.04297 3.48715 1.11635 2.76096C1.20364 1.89704 1.88571 1.21772 2.74919 1.12619Z"
          clipRule="evenodd" />
  </SvgIcon>
}
