import React from 'react';

import BatchControlIcon from '../../assets/icons/BatchControlIcon';

const SectionItemRow = ({ item }) => (
  <tr>
    <td>{item.code}</td>
    <td>{item.checklistDescription || item.description}</td>
    <td>{item.type}</td>
    <td>{item.ref}</td>
    <td>{item.quantity}</td>
    <td style={{ width: 50 }}>{item.batchControl ? <BatchControlIcon color='secondary' /> : null}</td>
  </tr>
);

export default SectionItemRow;
