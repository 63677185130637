import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { CaseDateRange, ReportingCasesTable } from '../../../components/reporting';
import { LineChart } from '../../../components/charts';
import LoadScreen from '../../../components/load-screen';

import { useLoading } from '../../../hooks';

import { getSurgeons } from '../../../actions/surgeonsActions';
import { getHospitals } from '../../../actions/hospitalsActions';
import { getProcedures } from '../../../actions/proceduresActions';

import { prepareCasesDataMonthly, prepareCasesDataDaily, prepareCasesDataQuarterly } from '../../../utils/analytics';

import { presets } from '../../../constants/analytics';
import { caseStatusOptions } from '../../../constants/enums';

import './reporting-page.scss';
import { getCompletedCases } from '../../../actions/casesActions';

const presetOptions = [
  { label: 'This month', value: presets.THIS_MONTH },
  { label: 'This quarter', value: presets.THIS_QUARTER },
  { label: 'This year', value: presets.THIS_YEAR },
  { label: 'Last month', value: presets.LAST_MONTH },
  { label: 'Last quarter', value: presets.LAST_QUARTER },
  { label: 'Last year', value: presets.LAST_YEAR },
];

const filteredFields = ['surgeonName', 'hospitalName', 'procedureName'];
const getInitialFilters = () => {
  const initialFilter = {};

  filteredFields?.forEach((field) => {
    initialFilter[field] = [];
  });

  return initialFilter;
};

const CaseReportingPage = () => {
  const dispatch = useDispatch();
  const tenantPrimaryColor = useSelector((state) => state?.tenant.currentTenant.colorPrimary);
  const activeCases = useSelector((state) => state?.cases.list);
  const completedCases = useSelector((state) => state?.cases.completedCases);
  const surgeons = useSelector((state) => state?.surgeons.list);
  const hospitals = useSelector((state) => state?.hospitals.list);
  const procedures = useSelector((state) => state?.procedures.list);
  const users = useSelector((state) => state?.users.list);

  const cases = useMemo(() => [...activeCases, ...completedCases], [activeCases, completedCases]);

  const { loading, startLoading, stopLoading } = useLoading();

  const [preset, setPreset] = useState(presets.THIS_MONTH);
  const [minDate, setMinDate] = useState(moment().startOf('month'));
  const [maxDate, setMaxDate] = useState(moment().endOf('month'));

  const [presetCompare, setPresetCompare] = useState('');
  const [minDateCompare, setMinDateCompare] = useState(moment().subtract(1, 'month').startOf('month'));
  const [maxDateCompare, setMaxDateCompare] = useState(moment().subtract(1, 'month').endOf('month'));

  const [filter, setFilter] = useState(getInitialFilters());

  useEffect(() => {
    onLoad().catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    setPresetCompare('');
    onPresetChange(preset, { onMinDateChange: setMinDate, onMaxDateChange: setMaxDate });
  }, [preset]);

  useEffect(() => {
    if (!presetCompare) {
      return;
    }

    if (preset === presets.THIS_MONTH) {
      onPresetChange(presets.LAST_MONTH, { onMinDateChange: setMinDateCompare, onMaxDateChange: setMaxDateCompare }, presetCompare === presets.YEAR_AGO);
    }
    if (preset === presets.THIS_QUARTER) {
      onPresetChange(presets.LAST_QUARTER, { onMinDateChange: setMinDateCompare, onMaxDateChange: setMaxDateCompare }, presetCompare === presets.YEAR_AGO);
    }
    if (preset === presets.THIS_YEAR) {
      onPresetChange(presets.LAST_YEAR, { onMinDateChange: setMinDateCompare, onMaxDateChange: setMaxDateCompare });
    }
  }, [presetCompare]);

  const onLoad = async () => {
    startLoading()
    try {
      await Promise.all([
        dispatch(getSurgeons()),
        dispatch(getHospitals()),
        dispatch(getProcedures()),
        dispatch(getCompletedCases()),
      ]);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onPresetChange = (value, { onMinDateChange, onMaxDateChange }, yearAgo = false) => {
    switch (value) {
      case presets.THIS_MONTH:
        onMinDateChange(moment().startOf('month'));
        onMaxDateChange(moment().endOf('month'));
        return;
      case presets.THIS_QUARTER:
        onMinDateChange(moment().startOf('quarter'));
        onMaxDateChange(moment().endOf('quarter'));
        return;
      case presets.THIS_YEAR:
        onMinDateChange(moment().startOf('year'));
        onMaxDateChange(moment().endOf('year'));
        return;
      case presets.LAST_MONTH:
        if (yearAgo) {
          onMinDateChange(moment().subtract(1, 'year').subtract(1, 'month').startOf('month'));
          onMaxDateChange(moment().subtract(1, 'year').subtract(1, 'month').endOf('month'));
          return;
        }
        onMinDateChange(moment().subtract(1, 'month').startOf('month'));
        onMaxDateChange(moment().subtract(1, 'month').endOf('month'));
        return;
      case presets.LAST_QUARTER:
        if (yearAgo) {
          onMinDateChange(moment().subtract(1, 'year').subtract(1, 'quarter').startOf('quarter'));
          onMaxDateChange(moment().subtract(1, 'year').subtract(1, 'quarter').endOf('quarter'));
          return;
        }
        onMinDateChange(moment().subtract(1, 'quarter').startOf('quarter'));
        onMaxDateChange(moment().subtract(1, 'quarter').endOf('quarter'));
        return;
      case presets.LAST_YEAR:
        onMinDateChange(moment().subtract(1, 'year').startOf('year'));
        onMaxDateChange(moment().subtract(1, 'year').endOf('year'));
        return;
      default:
        return;
    }
  };

  const getChartData = (filteredCases, min, max) => {
    if (min.month() === max.month() && min.year() === max.year()) {
      const daysInMonth = preset === presets.LAST_MONTH ? moment().subtract(1, 'month').daysInMonth() : moment().daysInMonth();
      const numberOfDays = filteredCases[0]?.date ? moment(filteredCases[0]?.date).daysInMonth() : daysInMonth;
      return prepareCasesDataDaily(filteredCases, numberOfDays);
    }

    if (min.quarter() === max.quarter() && min.year() === max.year()) {
      const quarter = preset === presets.LAST_QUARTER ? moment().subtract(1, 'quarter').quarter() : moment().quarter();
      const initialQuarter = filteredCases[0]?.date ? quarter : moment(filteredCases[0]?.date).quarter();
      return prepareCasesDataQuarterly(filteredCases, initialQuarter);
    }

    return prepareCasesDataMonthly(filteredCases);
  };

  const handleFilterChange = (field, values) => {
    setFilter({ ...filter, [field]: values });
  };

  const getFilterOptions = () => {
    const hospitalsOptions = [];
    const proceduresOptions = [];
    const surgeonsOptions = [];

    cases?.forEach((item) => {
      const { hospital, procedure, surgeon } = item;

      if (hospital && !hospitalsOptions?.map((item) => item.id)?.includes(hospital)) {
        const hospitalObj = hospitals?.find((item) => item.id === hospital);
        if (hospitalObj) {
          hospitalsOptions?.push(hospitalObj);
        }
      }

      if (procedure && !proceduresOptions?.map((item) => item.id)?.includes(procedure)) {
        const procedureObj = procedures?.find((item) => item.id === procedure);
        if (procedureObj) {
          proceduresOptions?.push(procedureObj);
        }
      }

      if (surgeon && !surgeonsOptions?.map((item) => item.id)?.includes(surgeon)) {
        const surgeonObj = surgeons?.find((item) => item.id === surgeon);
        if (surgeonObj) {
          surgeonsOptions?.push(surgeonObj);
        }
      }
    });

    return { hospitalName: hospitalsOptions, procedureName: proceduresOptions, surgeonName: surgeonsOptions };
  };

  const handleRemoveFilterItem = (key, value) => {
    setFilter({ ...filter, [key]: filter[key].filter((item) => item !== value) });
  };

  // Memoized data

  const filteredCases = useMemo(() => cases.filter((item) => {
    let persistence = true;

    Object.keys(filter)
      .forEach((key) => {
        const field = key.split('Name')[0];
        if (filter[key].length && !filter[key].includes(item[field])) {
          persistence = false;
        }
      });

    return persistence && minDate.isBefore(item.date) && maxDate.isAfter(item.date)
      && !!item.active && item.status !== caseStatusOptions.request;
  }), [cases, minDate, maxDate, filter]);

  const casesChartData = useMemo(() => {
    const data = getChartData(filteredCases, minDate, maxDate);

    if (presetCompare) {
      const comparedCases = cases.filter((item) => minDateCompare.isBefore(item.date) && maxDateCompare.isAfter(item.date));
      const compareData = getChartData(comparedCases, minDateCompare, maxDateCompare);

      if (preset === presets.THIS_QUARTER && presetCompare === presets.PREVIOUS_PERIOD) {
        const previousQuarter = compareData?.map((item) => ({
          data: item.data?.map((i, index) => ({ x: data[0]?.data[index]?.x, y: i.y })),
          id: `${item.id}_compare`
        }));
        return [...previousQuarter, ...data];
      }

      return [...compareData?.map((item) => ({ ...item, id: `${item.id}_compare` })), ...data];
    }

    return data;
  }, [filteredCases, minDate, maxDate, minDateCompare, maxDateCompare, presetCompare]);

  const presetCompareOptions = useMemo(() => {
    const options = [
      { label: 'Don\'t compare', value: '' }
    ];

    if ([presets.THIS_YEAR, presets.THIS_QUARTER, presets.THIS_MONTH].includes(preset)) {
      options?.push({ label: 'Previous period', value: presets.PREVIOUS_PERIOD });
    }

    if ([presets.THIS_QUARTER, presets.THIS_MONTH].includes(preset)) {
      options?.push({ label: 'Year ago', value: presets.YEAR_AGO });
    }

    return options;
  }, [preset]);

  return (
    <div className='page-container reporting-page__container'>
      {loading && <LoadScreen />}
      <div className='page-title'>Cases Report</div>
      <div className='reporting-page__body'>
        <div className='case-reporting-chart__container'>
          <div className='case-reporting-chart'>
            <LineChart
              data={casesChartData}
              colors={casesChartData.length > 1 ? ['#cccccc', tenantPrimaryColor] : tenantPrimaryColor}
              margin={{ top: 20, right: 15, bottom: 30, left: 30 }}
            />
          </div>
          <CaseDateRange
            minDate={minDate}
            onMinDateChange={(date) => {
              setMinDate(date);
              setPreset(null);
            }}
            maxDate={maxDate}
            onMaxDateChange={(date) => {
              setMaxDate(date);
              setPreset(null);
            }}
            preset={preset}
            onPresetChange={setPreset}
            presetCompare={presetCompare}
            onPresetChangeCompare={setPresetCompare}
            options={presetOptions}
            compareOptions={presetCompareOptions}
          />
        </div>
        <ReportingCasesTable
          cases={filteredCases}
          surgeons={surgeons}
          hospitals={hospitals}
          procedures={procedures}
          users={users}
          filter={filter}
          filterOptions={getFilterOptions()}
          onFilterChange={handleFilterChange}
          onRemoveFilter={handleRemoveFilterItem}
        />
      </div>
    </div>
  );
};

export default CaseReportingPage;
