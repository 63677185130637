import React, { useMemo, useState } from 'react';
import moment from 'moment';

import ArrowIconUp from '@material-ui/icons/KeyboardArrowUp';
import ArrowIconDown from '@material-ui/icons/KeyboardArrowDown';
import IconButton from '@material-ui/core/IconButton';
import WarningIcon from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons/Check';
import FlowIcon from '../../../assets/icons/FlowIcon';

import CaseFlowTable from './CaseFlowTable';

import { setAllocationStatuses, flowStepStatuses } from '../../../constants/enums';

const { TRANSFERRED, SHIPPED } = setAllocationStatuses;

const CaseFlowOverview = (props) => {
  const {
    activeCase,
    steps = [],
    flows = [],
    users = [],
    userRole,
  } = props;

  const [isShown, toggleHistory] = useState(false);

  const flowName = useMemo(() => {
    const flow = flows?.find((item) => item.id === activeCase?.flow);
    return flow ? flow.name : '';
  }, [activeCase, flows]);

  const activeStep = useMemo(() => {
    const completed = steps?.filter((item) => item.status === flowStepStatuses.COMPLETED.value);
    const maxStep = Math.max(...completed.map(({ stepNumber }) => stepNumber));

    if (maxStep === steps?.length) {
      return steps[steps?.length - 1];
    }

    const step = steps?.find((item) => item.stepNumber === maxStep);
    const pending = steps?.find((item) => item?.stepNumber === step?.stepNumber + 1);

    if (pending) {
      return pending;
    } else {
      return steps?.find((item) => item?.stepNumber === 1);
    }
  }, [activeCase, steps]);

  const allCompleted = useMemo(() => {
    return steps && !!steps?.length &&steps?.every((allocation) => allocation.status === flowStepStatuses.COMPLETED.value);
  }, [steps]);

  const dueDate = useMemo(() => moment(activeCase?.date).subtract(activeStep?.config?.dueDate, 'days'), [activeCase, activeStep]);
  const diff = useMemo(() => dueDate.diff(moment().startOf('day'), 'days'), [dueDate]);

  return (
    <div className='case-history__container'>
      <div className='d-flex space-between'>
        <div className='d-flex'>
          <FlowIcon />
          <div className='d-flex font-size-bg font-bold m-l-md'>
            {flowName || 'Flow'}
            {activeCase?.flow && !!steps?.length && (
              <div
                className='case-flow__active-step'
                style={{
                  background: steps?.every((item) => item.status === flowStepStatuses.COMPLETED.value) ? SHIPPED.color : TRANSFERRED.color
                }}
              >
                {allCompleted ? (
                  <CheckIcon color="#ffffff" />
                ) : (
                  activeStep?.stepNumber
                )}
              </div>
            )}
          </div>

          <IconButton
            className='m-l-md'
            aria-label='more'
            aria-controls='long-menu'
            aria-haspopup='true'
            onClick={() => toggleHistory(!isShown)}
            size='small'
          >
            { isShown ? <ArrowIconUp fontSize='large' /> : <ArrowIconDown fontSize='large' />}
          </IconButton>
        </div>

        {!allCompleted && diff >= 1 && (
          <div
            className='font-bold d-flex'
            style={{ color: allCompleted || diff > 4 ? flowStepStatuses.COMPLETED.color : flowStepStatuses.PENDING.color }}
          >
          <span>
            {!allCompleted ? (
              `Pending due in ${diff} day(s)`
            ) : (
              'All Completed'
            )}
          </span>
            {diff <=4 && (
              <WarningIcon style={{ color: flowStepStatuses.PENDING.color, marginLeft: 8 }} />
            )}
          </div>
        )}

        {!allCompleted && diff < 1 && (
          <div className='d-flex' style={{ color: flowStepStatuses.PENDING.color }}>
            <span className='font-bold'>Overdue</span>
            <WarningIcon style={{ color: flowStepStatuses.PENDING.color, marginLeft: 8 }} />
          </div>
        )}
      </div>

      {isShown && (
        <div className='m-t-lg'>
          <CaseFlowTable
            rows={steps}
            caseDate={activeCase.date}
            activeStep={activeStep?.stepNumber}
            users={users}
            userRole={userRole}
          />
        </div>
      )}
    </div>
  );
};

export default CaseFlowOverview;
