import { LOAD_USER_DATA, SET_CURRENT_USER, SIGN_OUT } from '../actions/actionTypes';

import userRoles from '../constants/userRoles';

const initialState = {
  currentUser: {
    uid: null,
    email: '',
    firstName: '',
    lastName: '',
    displayName: '',
    role: '',
    tenantId: '',
    phone: '',
    description: '',
    photo: '',
    emailVerified: false,
    passwordSet: false,
    assignedSurgeons: [],
    profilePicture: null,
    caseWriteDisabled: false,
    deliveryAddresses: [],
    adminPassword: false,
    connectedTenants: [],
    deletePermission: false,
    territoryVisibility: false,
    assignedTerritories: [],
    isGlobalAdmin: false,
    disableAppNotifications: false,
  },
  isLoading: true,
  isLoaded: false
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USER_DATA:
      return { ...state, isLoading: true };
    case SET_CURRENT_USER:
      const { user } = action;
      return {
        ...state,
        currentUser: user ? {
          uid: user.uid,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          displayName: user.displayName,
          role: user.role,
          tenantId: user.tenantId,
          emailVerified: user.emailVerified,
          passwordSet: user.passwordSet,
          phone: user.phone,
          photo: user.photo ? user.photo : '',
          assignedSurgeons: user.assignedSurgeons || [],
          profilePicture: user.profilePicture || null,
          caseWriteDisabled: user.caseWriteDisabled || false,
          deliveryAddresses: user.deliveryAddresses || [],
          adminPassword: user.adminPassword || false,
          connectedTenants: user.role === userRoles.SALES_REP.name ? (user.connectedTenants || []) : [],
          deletePermission: user.deletePermission || false,
          territoryVisibility: user.territoryVisibility || false,
          assignedTerritories: user.assignedTerritories || [],
          isGlobalAdmin: !!user.isGlobalAdmin,
          disableAppNotifications: !!user.disableAppNotifications
        } : initialState.currentUser,
        isLoading: false,
        isLoaded: true,
      };
    case SIGN_OUT:
      return { ...initialState, isLoaded: true };
    default:
      return state;
  }
};

export default userReducer;
