import React, { useMemo } from 'react';

import BarChart from '../../../charts/BarChart';

import { kitVariantTypes } from '../../../../constants/enums';
import colorPalette, { lighterColorPalette } from '../colorPalette';

const generateColors = (keys, isPrevious) => {
  const colors = {};
  keys?.forEach((k, index) => {
    colors[k] = isPrevious ? lighterColorPalette[index] : colorPalette[index];
  });
  return colors;
};

const TypesStackedBarChart = ({ cases = [], hospitals = [], compareCases = [], presetCompare }) => {
  const data = useMemo(() => {
    const types = presetCompare ? ['Combined (vs)', 'Combined', 'Consignment (vs)', 'Consignment', 'Loan (vs)', 'Loan'] : ['Combined', 'Consignment', 'Loan'];
    const states = [];
    hospitals?.forEach((hospital) => {
      if (!states?.includes(hospital.state)) {
        states.push(hospital.state);
      }
    });

    const bars = types?.map((type) => {
      const result = { type };

      states?.forEach((state) => {
        const list = type?.includes('vs') ? compareCases : cases;
        const hospitalCases = list.filter((item) => {
          const hospital = hospitals?.find((h) => item.hospital === h.id);
          return hospital.state === state;
        });

        hospitalCases?.forEach((caseItem) => {
          if (type?.includes('Loan')) {
            if (caseItem.kitVariant === kitVariantTypes.loan) {
              result[state] = (result[state] || 0) + 1;
            }
          }
          if (type?.includes('Consignment')) {
            if (caseItem.kitVariant === kitVariantTypes.consignment && !caseItem.additionalKits?.length && !caseItem.additionalItems?.length) {
              result[state] = (result[state] || 0) + 1;
            }
          } if (type?.includes('Combined')) {
            if (caseItem.kitVariant === kitVariantTypes.consignment && (!!caseItem.additionalKits?.length || !!caseItem.additionalItems?.length)) {
              result[state] = (result[state] || 0) + 1;
            }
          }
        });
      });

      return result;
    });

    return { keys: states, data: bars }
  }, [cases, hospitals, presetCompare, compareCases]);

  const colors = useMemo(() => generateColors(data.keys), [data]);
  const previousColors = useMemo(() => generateColors(data.keys, true), [data]);

  return (
    <div className="management-reports-chart__container m-r-md" style={{ width: '50%' }}>
      <div className="font-size-bg font-bold">Cases by Type</div>
      <div className="secondary m-t-sm m-b-lg">Total cases by type inc. state split</div>
      <BarChart
        data={data?.data || []}
        height="300px"
        layout="horizontal"
        enableLabel={true}
        enableTotals={true}
        keys={data?.keys || []}
        indexBy="type"
        colors={(bar) => {
          if (bar.indexValue?.includes('vs')) {
            return previousColors[bar.id];
          }
          return colors[bar.id];
        }}
        margin={{ top: 0, right: 100, bottom: 30, left: presetCompare ? 115 : 85 }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          truncateTickAt: 0,
          format: e => e,
        }}
        labelTextColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              1.6,
            ],
          ],
        }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemTextColor: '#ccc',
            itemDirection: 'right-to-left',
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: { itemTextColor: '#000' },
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export default TypesStackedBarChart;
