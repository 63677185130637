import React, { useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import moment from 'moment';

import Button from '../../shared/Button';
import Checkbox from '../../shared/Checkbox';
import Input from '../../shared/Input';
import DatePicker from '../../shared/date-picker/DatePicker';
import Select from '../../shared/Select';

import { countTypes } from '../../../constants/enums';

import '../../../styles/shared/form.scss';

const frequencyOptions = [
  { label: '1 month', value: 1 },
  { label: '2 months', value: 2 },
  { label: '3 months', value: 3 },
  { label: '4 months', value: 4 },
  { label: '5 months', value: 5 },
  { label: '6 months', value: 6 },
  { label: '7 months', value: 7 },
  { label: '8 months', value: 8 },
  { label: '9 months', value: 9 },
  { label: '10 months', value: 10 },
  { label: '11 months', value: 11 },
  { label: '12 months', value: 12 },
];

const Step2 = (props) => {
  const {
    onClickNext = () => {},
    loading,
    onClickBack,
    initialValues = {},
    kits = [],
    sets = [],
    type,
  } = props;

  const { hospitalId } = initialValues;

  const [search, setSearch] = useState('');

  const validate = (values) => {
    const errors = {};

    if (type === countTypes.CONSIGNMENT && !values.kit) {
      errors.kit = 'Required';
    }

    if (!values.name) {
      errors.name = 'Required';
    }
    if (!values.startDate) {
      errors.startDate = 'Required';
    }
    if (!values.frequency) {
      errors.frequency = 'Required';
    }
    if (!values.dueDays) {
      errors.dueDays = 'Required';
    }

    return errors;
  };

  const list = useMemo(() => {
    let result;

    if (type === countTypes.CONSIGNMENT) {
      const setsList = sets?.filter((set) => !!set.consigned && set?.consignment?.hospital === hospitalId);
      result = kits?.filter((kit) => setsList?.map((s) => s.kit)?.includes(kit.id)) || [];
    }

    return result?.filter((kit) => kit?.name?.toLowerCase()?.includes(search?.toLowerCase()));
  }, [hospitalId, kits, sets, search, type]);

  return (
    <div>
      <Form
        onSubmit={onClickNext}
        validate={validate}
        subscription={{ pristine: true, invalid: true }}
        initialValues={initialValues}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine, form }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              {type === countTypes?.CONSIGNMENT && (
                <>
                  <div>
                    <Input
                      type='search'
                      placeholder='Search Kits'
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>

                  <div className=''>
                    <Field name='kit'>
                      {({ input, meta }) => (
                        <>
                          <div className='field__title'>Kit</div>
                          <div className='m-b-lg form__select-users' style={{ maxHeight: 200 }}>
                            {list?.length ? (
                              list?.map((kit) => (
                                <div className='md-user-info' key={kit.id}>
                                  <Checkbox
                                    input={{
                                      onClick: () => input.onChange(kit.id),
                                      checked: kit.id === input.value
                                    }}
                                    fontSize='small'
                                    label={kit.name}
                                  />
                                </div>
                              ))
                            ) : (
                              <div className='text-center secondary p-md'>
                                No kits
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </Field>
                  </div>
                </>
              )}

              <div className=''>
                <div className='field__title'>Count Name</div>
                <Field name='name'>
                  {(props) => (
                    <Input
                      name={props.input.name}
                      value={props.input.value}
                      placeholder='Type a count name here'
                      type='text'
                      onChange={props.input.onChange}
                    />
                  )}
                </Field>
              </div>

              <div>
                <div className='field__title'>Repeating Frequency (months)</div>
                <Field name='frequency'>
                  {({ input, meta }) => (
                    <div className='m-b-lg'>
                      <Select
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                        options={frequencyOptions}
                      />
                    </div>
                  )}
                </Field>
              </div>

              <div>
                <div className='field__title'>Start Date</div>
                <Field name='startDate'>
                  {({ input, meta }) => (
                    <div className='m-b-lg'>
                      <DatePicker
                        name={input.name}
                        date={input.value}
                        onDateChange={input.onChange}
                        minDate={moment()}
                      />
                    </div>
                  )}
                </Field>
              </div>

              <div>
                <div className='field__title'>Time Frame (due days)</div>
                <Field name='dueDays'>
                  {({ input, meta }) => (
                    <div>
                      <Input
                        name={input.name}
                        value={input.value}
                        type='text'
                        numbersOnly
                        onChange={(e) => {
                          const value = e.target.value
                          if (value > -1 && value <= 30) {
                            input.onChange(value);
                          }
                        }}
                      />
                    </div>
                  )}
                </Field>
              </div>

              <div className='form__actions'>
                <Button
                  type='submit'
                  text='Continue'
                  disabled={invalid || loading}
                  loading={loading}
                  onClick={handleSubmit}
                />
                <Button
                  type='cancel'
                  onClick={onClickBack}
                  text='Back'
                />
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default Step2;
