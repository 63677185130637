import React from 'react';

import { caseChecklistStatuses } from '../../../constants/enums';

import './checklists.scss';

const ChecklistsContainer = (props) => {
  const { checklists = [], selected, onSelect, tenantColor } = props;

  return (
    <div className='case-checklists__container'>
      {checklists?.map((checklist) => (
        <div
          key={checklist.id}
          className={`case-checklist-item${selected?.id === checklist.id ? ' selected' : ''}`}
          onClick={() => onSelect(checklist)}
        >
          <div
            className='font-bold m-b-sm'
            style={{ color: selected?.id === checklist.id ? tenantColor : 'inherit' }}
          >
            {checklist.kitName}
          </div>
          <div className='m-b-sm'>{checklist.setNumber}</div>
          <div className='font-bold' style={{ color: caseChecklistStatuses[checklist.status]?.color }}>
            {caseChecklistStatuses[checklist.status]?.label}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChecklistsContainer;
