import React from 'react';

import Modal from '../../shared/modal';
import moment from 'moment';

const ShipNotesModal = (props) => {
  const { open, onClose, note, title = 'Shipping Notes', author = '', date } = props;

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      fullWidth
    >
      <div className='ship-notes-modal__container'>
        <div className='ship-note'>
          {(author || date) && (
            <div className='m-b-md d-flex space-between'>
              <div className='font-bold'>{author}</div>
              <div className='secondary'>{moment(date).fromNow()}</div>
            </div>
          )}
          {note}
        </div>
      </div>
    </Modal>
  );
};

export default ShipNotesModal;
