import React, { useMemo, useCallback } from 'react';
import moment from 'moment';

import Table from '../shared/table/Table';

import { setPositionOptions } from '../../constants/enums';

const SetsTable = (props) => {
  const {
    onSelectSet,
    sets,
    selectedSetId,
    handleCheck,
    checkedSets,
    handleCheckAll,
    kits,
    onCaseIdClick = () => {},
    tenantColor,
    withoutStatus,
    search,
    hospitals = [],
    cases = [],
    checkboxes,
    labels = [],
  } = props;

  const defaultColumns = [
    { title: 'set number', field: 'number', type: 'default', sort: true },
    { title: 'kit id', field: 'kitId', type: 'default', sort: true },
    { title: 'kit name', field: 'kitName', type: 'default', sort: true },
    { title: 'containers', field: 'containerSize', type: 'default', sort: true },
    { title: 'position', field: 'position', type: 'setPosition', sort: true },
    {
      title: 'allocation', field: 'caseAllocation', type: 'custom', formatter: (value, row) => (
        <span
          style={{color: tenantColor}}
          onClick={() => onCaseIdClick(value)}
        >
          {row.formattedCaseId}
        </span>
      ),
    },
  ];

  const getKitId = useCallback((kitId) => {
    const kit = kits.find((item) => item.id === kitId);
    return kit ? kit.kitId : '';
  }, [sets, kits]);

  const getKitName = useCallback((kitId) => {
    const kit = kits.find((item) => item.id === kitId);
    return kit ? kit.name : '';
  }, [sets, kits]);

  const getHospital = useCallback((caseId, row) => {
    if (row?.position === setPositionOptions.CONSIGNED.value) {
      const hospitalId = row?.consignment?.hospital;
      const caseHospital = hospitals?.find((hospital) => hospital.id === hospitalId);
      return caseHospital && caseHospital.name ? caseHospital.name : '';
    } else {
      const caseAllocation = cases?.find((item) => item.id === caseId);
      if (!caseAllocation) {
        return null;
      }

      const caseHospital = hospitals.find((hospital) => hospital.id === caseAllocation.hospital);

      return caseHospital && caseHospital.name ? caseHospital.name : '';
    }
  }, [sets, cases, kits, hospitals]);

  const getLabelNames = (labelIds = []) => (
    labelIds.map((id) => {
      const label = labels.find((item) => item.id === id);
      return label ? label.name : '';
    })?.join(', ')
  );

  const getPosition = (set) => {
    if (set.quarantined) {
      return setPositionOptions.QUARANTINED.value;
    }
    if (set.consigned) {
      return setPositionOptions.CONSIGNED.value;
    }
    return set.caseAllocation ? setPositionOptions.ALLOCATED.value : setPositionOptions.AVAILABLE.value;
  };

  const formatCaseId = (caseId) => {
    const arr = caseId?.split('-');
    return arr?.length > 1 ? `${arr[0]}-${arr[1]}` : caseId;
  };

  const isTargetReached = (set) => {
    const { kit: kitId, targetTurns } = set;
    const kit = kits?.find((k) => k.id === kitId);
    const target = kit?.target;

    return target && Math.round((targetTurns / target.value) * 100) === 100
  };

  const isReviewSoon = (set) => {
    if (!set.consigned || !set.consignment) {
      return false;
    }
    const reviewDate = moment(set.consignment.reviewDate);
    return reviewDate.diff(moment(), 'weeks') < 8;
  };

  const rows = useMemo(() => (
    sets
      .map((set) => ({
        ...set,
        kitId: getKitId(set.kit),
        kitName: getKitName(set.kit),
        position: getPosition(set),
        caseAllocation: set.caseAllocation,
        formattedCaseId: formatCaseId(set.caseAllocation),
      }))
      .filter((set) => (
        set.kitId.toLowerCase().includes(search.toLowerCase()) ||
        set.kitName.toLowerCase().includes(search.toLowerCase()) ||
        (set.number && set.number.toLowerCase().includes(search.toLowerCase()))
      ))
  ), [sets, cases, kits, search]);

  const columns = withoutStatus ? (
    [
      ...defaultColumns,
      { title: 'hospital', field: 'hospital', type: 'default', sort: true },
      { title: 'labels', field: 'labelNames', type: 'default' },
      { title: '', field: 'warning', type: 'warning', sort: false },
    ]
  ) : (
    [
      ...defaultColumns,
      { title: 'labels', field: 'labelNames', type: 'default' },
      { title: 'status', field: 'active', type: 'status' },
      { title: '', field: 'warning', type: 'warning', sort: false },
    ]
  );

  return (
    <div style={{ width: '100%' }}>
      <Table
        rows={rows.map((row) => ({
          ...row,
          hospital: getHospital(row.caseAllocation, row),
          labelNames: getLabelNames(row.labels || []),
          warning: isTargetReached(row) || isReviewSoon(row)
        }))}
        columns={columns}
        onRowClick={onSelectSet}
        selected={selectedSetId}
        handleCheck={handleCheck}
        checkedRows={checkedSets}
        handleCheckAll={handleCheckAll}
        additionalData={{
          onClick: onCaseIdClick,
          tenantColor,
        }}
        pagination
        checkboxes={checkboxes}
        noCheckAll
      />
    </div>
  );
};

export default SetsTable;
