import React, { useMemo } from 'react';
import moment from 'moment';

import { getStatusIcon } from '../../../utils/counts';

import './checklists.scss';

const CountInfo = (props) => {
  const { count, hospitals = [] } = props;
  const { status, dueDate, name, time, hospitalId } = count;

  const hospitalName = useMemo(() => {
    const countHospital = hospitals?.find((item) => item.id === hospitalId);
    return countHospital ? countHospital.name : '';
  }, [hospitalId, hospitals]);

  return (
    <div className='case-checklists-info__container m-l-lg' style={{ minWidth: 300, height: 'auto', flex: 0.4 }}>
      <div>
        <div className='d-flex'>
          {getStatusIcon(status, true)}
          <div className='font-size-bg font-bold m-l-md m-r-sm'>
            {dueDate ? moment(dueDate).local().format('Do MMM YYYY') : ''}
          </div>
          <div className='font-bold secondary m-t-sm'>{time}</div>
        </div>
        <div className='m-t-md'>
          <div className='font-size-bg font-bold'>{hospitalName}</div>
        </div>
        {name && (
          <div className='m-t-md'>
            <div className='secondary font-bold'>{name}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CountInfo;
