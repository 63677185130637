import React, { useState } from 'react';

import BackupIcon from '../../assets/icons/BackupIcon';

import EmailsModal from './EmailsModal';
import Button from '../shared/Button';
import Input from '../shared/Input';

import './emails.scss';

import { emailTriggers } from '../../constants';

const EmailCaseBackupTriggersContainer = ({ config, onChange, onSubmit }) => {
  const [isModalOpen, toggleModal] = useState(false);

  return (
    <div className='email-triggers__container'>
      <div className='d-flex'>
        <BackupIcon />
        <div className='font-size-bg font-bold m-l-md'>
          Cases Backup
        </div>
      </div>
      <div className='m-t-lg'>
        <Input
          type='switch'
          value={!!config?.active}
          onChange={(value) => onChange(value, emailTriggers.CASES_BACKUP)}
          placeholder='Status'
        />
      </div>
      <div className='m-t-lg secondary font-bold'>Recipients</div>
      <div className='emails-list__container'>
        {config?.list?.map((email, index) => (
          <div
            key={`${email}_${Math.random()}`}
            className='font-bold m-b-sm'
          >
            <span className='m-r-sm'>{`${index + 1}.`}</span>
            <span>{email}</span>
          </div>
        ))}
      </div>
      <div className='d-flex flex-end m-t-lg'>
        <Button
          type='submit'
          text='Edit'
          onClick={() => toggleModal(true)}
        />
      </div>

      <EmailsModal
        isOpen={isModalOpen}
        onClose={() => toggleModal(false)}
        initialList={config?.list || []}
        onSubmit={(list) => onSubmit(list, emailTriggers.CASES_BACKUP)}
      />
    </div>
  );
};

export default EmailCaseBackupTriggersContainer;
