import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function KitIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M3.80811 5.08566C4.94041 4.08064 5.64229 3.74132 6.99994 3.48975C8.35759 3.74132 9.05947 4.08064 10.1918 5.08566L6.99994 6.68158L3.80811 5.08566Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M3.80812 5.08567C3.41457 6.16603 3.41129 7.45392 3.72268 8.53928C3.7739 8.71779 3.88125 8.8743 4.02382 8.99331C5.01075 9.81716 5.6865 10.12 6.78858 10.3608C6.92769 10.3912 7.0723 10.3912 7.21141 10.3608C8.31348 10.12 8.98924 9.81716 9.97617 8.99331C10.1187 8.8743 10.2261 8.71779 10.2773 8.53928C10.5887 7.45389 10.5854 6.16595 10.1918 5.08557L6.99994 6.68158L3.80812 5.08567Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M6.99994 6.67395V10.4053" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M0.75 7C0.75 3.54822 3.54822 0.75 7 0.75C9.37635 0.75 11.4429 2.07622 12.5 4.02888C12.5756 2.92743 12.5534 2.29714 12.3993 1.15356" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M13.25 7C13.25 10.4518 10.4518 13.25 7 13.25C4.63539 13.25 2.57748 11.9368 1.51573 10C1.44014 11.1015 1.46229 11.7317 1.61647 12.8753" />
  </SvgIcon>
}
