import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import FilterIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import ExportIcon from '../../assets/icons/DownloadIcon';
import KitsIcon from '../../assets/icons/KitIcon';

import Table from '../shared/table/Table';
import ActionButton from '../shared/ActionButton';

import { exportKitsReport } from '../../actions/kitsActions';

import { setAllocationStatuses } from '../../constants/enums';

import './reporting.scss';

const columns = [
  { title: 'shipping date', field: 'date', type: 'date', sort: true },
  { title: 'kit name', field: 'kitName', type: 'default', sort: true, filter: true, filterTitle: 'Filter Kits' },
  { title: 'kit id', field: 'kitId', type: 'default', sort: true },
  { title: 'qty', field: 'quantity', type: 'default', sort: true },
  { title: 'hospital', field: 'hospitalName', type: 'default', sort: true, filter: true, filterTitle: 'Filter Hospitals' },
  { title: 'surgeon', field: 'surgeonName', type: 'default', sort: true, filter: true, filterTitle: 'Filter Surgeons' },
];

const ReportingKitsTable = (props) => {
  const {
    sets = [], surgeons = [], hospitals = [], kits = [],
    filter, filterOptions, onFilterChange, onRemoveFilter
  } = props;

  const dispatch = useDispatch();

  const [exportLoading, setExportLoading] = useState(false);

  const getName = (field, id) => {
    let list = [];

    if (field === 'surgeonName') {
      list = surgeons;
    } else if (field === 'hospitalName') {
      list = hospitals;
    } else if (field === 'kitName') {
      list = kits;
    }

    const value = list?.find((item) => item.id === id);

    if (value) {
      return field === 'surgeonName' ? `${value?.firstName} ${value?.lastName}` : value.name;
    }

    return '';
  };

  const getSurgeonName = useCallback((surgeonId) => {
    const caseSurgeon = surgeons.find((surgeon) => surgeon.id === surgeonId);
    return caseSurgeon ? `${caseSurgeon.title} ${caseSurgeon.firstName} ${caseSurgeon.lastName}` : '';
  }, [sets, surgeons]);

  const getHospitalName = useCallback((hospitalId) => {
    const caseHospital = hospitals.find((hospital) => hospital.id === hospitalId);
    return caseHospital && caseHospital.name ? caseHospital.name : '';
  }, [sets, hospitals]);

  const getKitName = useCallback((kitId) => {
    const caseKit = kits.find((kit) => kit.id === kitId);
    return caseKit && caseKit.name ? caseKit.name : '';
  }, [sets, kits]);

  const getKitId = useCallback((kitId) => {
    const caseKit = kits.find((kit) => kit.id === kitId);
    return caseKit && caseKit.kitId ? caseKit.kitId : '';
  }, [sets, kits]);

  const onExportClick = async () => {
    const data = sets?.map((item) => ({
      ...item,
      surgeonName: getSurgeonName(item.surgeon),
      hospitalName: getHospitalName(item.hospital),
      kitName: getKitName(item.kit),
      kitId: getKitId(item.kit),
    }));

    setExportLoading(true);
    await dispatch(exportKitsReport(data));
    setExportLoading(false);
  };

  return (
    <div className='cases-table__container'>
      <div className='d-flex m-b-lg space-between align-start'>
        <div className='d-flex'>
          <KitsIcon />
          <div className='font-size-bg font-bold m-l-md'>
            Kits Shipped (volume)
          </div>
        </div>

        <ActionButton
          onClick={onExportClick}
          bgColor={setAllocationStatuses.SHIPPED.color}
          disabled={exportLoading}
          loading={exportLoading}
        >
          <ExportIcon color="#ffffff" />
          Export
        </ActionButton>
      </div>

      {filter && Object.keys(filter).some((key) => filter[key].length) && (
        <div className='filters-list__container'>
          <FilterIcon />

          {Object.keys(filter).map((key) => (
            <div key={key} className='d-flex'>
              {filter[key].map((value) => (
                <div key={`${key}-${value}`} className='filters-list__item'>
                  <div />
                  <div className='filter-list-item-value'>{getName(key, value)}</div>
                  <CloseIcon color='secondary' onClick={() => onRemoveFilter(key, value)} />
                </div>
              ))}
            </div>
          ))}
        </div>
      )}

      <Table
        rows={sets?.map((item) => ({
          ...item,
          surgeonName: getSurgeonName(item.surgeon),
          hospitalName: getHospitalName(item.hospital),
          kitName: getKitName(item.kit),
          kitId: getKitId(item.kit),
        }))}
        columns={columns}
        pagination
        checkboxes={false}
        withoutCheck
        rowColor='#ffffff'
        rowBorderColor='#fafafa'
        currentFilter={filter}
        filterOptions={filterOptions}
        onFilterChange={onFilterChange}
      />
    </div>
  );
};

export default ReportingKitsTable;
