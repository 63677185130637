import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function UsersIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M9.39697 7.0088C10.7218 7.0088 11.7958 5.93478 11.7958 4.60992C11.7958 3.58506 11.1532 2.71031 10.2488 2.3667" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M5.36061 6.53323C6.79675 6.53323 7.96097 5.36901 7.96097 3.93288C7.96097 2.49674 6.79675 1.33252 5.36061 1.33252C3.92447 1.33252 2.76025 2.49674 2.76025 3.93288C2.76025 5.36901 3.92447 6.53323 5.36061 6.53323Z" />
    <path stroke={color || defaultColor} fill="none"
          d="M9.34365 10.1866C10.6156 11.0338 9.75672 12.6677 8.22846 12.6677H2.48939C0.961125 12.6677 0.10223 11.0338 1.3742 10.1866C2.51512 9.42674 3.8853 8.98389 5.35892 8.98389C6.83254 8.98389 8.20273 9.42674 9.34365 10.1866Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M8.00732 12.6676H11.6449C13.0547 12.6676 13.8471 11.1603 12.6737 10.3787C12.3497 10.1629 12.0056 9.97485 11.6449 9.81787" />
  </SvgIcon>
}
