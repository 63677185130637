import React from 'react';

import DocumentIcon from '../../assets/icons/DocumentIcon';

const SectionsList = ({ kitId = '', sections, onClick, tenantColor, selectedSection }) => {
  return (
    <div className='section-list__container'>
      <div
        className='section-list__item'
        style={{ margin: 0, justifyContent: 'flex-start' }}
        onClick={() => onClick(null)}
      >
        <DocumentIcon />
        <span>{kitId}</span>
      </div>
      <div>
        {sections.map((section) => (
          <div
            className={`section-list__item${section.id === selectedSection?.id ? ' selected' : ''}`}
            onClick={() => onClick(section)}
          >
            <div className='d-flex direction-column align-start'>
              <span
                style={{ color: section.id === selectedSection?.id ? tenantColor : '#000000' }}
                className='font-bold'
              >
                {section.name}
              </span>
              <span className='font-bold secondary'>{section?.subtitle}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionsList;
