import React, { useCallback, useMemo } from 'react';
import { orderBy } from 'lodash';

import BarChart from '../../../charts/BarChart';

import colorPalette, { lighterColorPalette } from '../colorPalette';

export const CustomTooltip = (props) => {
  const { data, value, surgeons } = props;
  const id = data?.id?.includes('compare') ? data?.id?.substring(0, data?.id.indexOf('_compare')) : data?.id;
  const surgeon = surgeons?.find((u) => u.id === id);
  const name = `${surgeon.firstName} ${surgeon.lastName}`;

  return (
    <div className="custom-tooltip">
      <div className="tooltip-label">{surgeon ? (data?.id?.includes('compare') ? `${name} (previous period)` : name) : ''}</div>
      <div className="tooltip-content">
        <div>{`${value} case(s)`}</div>
      </div>
    </div>
  );
};

const generateColors = (keys, isPrevious) => {
  const colors = {};
  keys?.forEach((k, index) => {
    colors[k] = isPrevious ? lighterColorPalette[index] : colorPalette[index];
  });
  return colors;
};

const ForecastingSurgeonsBarChart = ({ cases = [], surgeons = [], compareCases = [], presetCompare }) => {
  const data = useMemo(() => {
    const allSurgeons = surgeons
      .map((surgeon) => {
        const surgeonCases = cases.filter((item) => item.surgeon === surgeon.id);
        return {
          id: surgeon.id,
          label: surgeon.lastName,
          value: surgeonCases.length,
        };
      })
      .filter((item) => item.value !== 0);

    const sorted = orderBy(allSurgeons, 'value', 'desc');
    const top5 = sorted.splice(0, 5);

    let result = [];

    if (presetCompare) {
      top5.forEach((chartItem) => {
        const surgeonCases = compareCases.filter((item) => item.surgeon === chartItem.id);
        const surgeon = surgeons?.find((u) => u.id === chartItem.id);
        result.push({
          id: `${surgeon?.id}_compare`,
          label: surgeon?.lastName ? `${surgeon?.lastName} (vs)` : '',
          value: surgeonCases.length,
        });
        result.push(chartItem);
      });
    } else {
      result = top5;
    }

    return result;
  }, [cases, surgeons, presetCompare, compareCases]);

  const getSurgeonName = useCallback((surgeonId) => {
    const id = surgeonId?.includes('compare') ? surgeonId?.substring(0, surgeonId.indexOf('_compare')) : surgeonId;
    const surgeon = surgeons?.find((u) => u.id === id);
    return surgeon ? (surgeonId?.includes('compare') ? `${surgeon.lastName} (vs)` : surgeon.lastName) : '';
  }, [surgeons]);

  const colors = useMemo(() => generateColors(data?.filter((item) => !item?.id?.includes('_compare'))?.map((item) => item.id)), [data]);
  const previousColors = useMemo(() => generateColors(data?.filter((item) => item?.id?.includes('_compare'))?.map((item) => item.id), true), [data]);

  return (
    <div className="management-reports-chart__container m-r-md" style={{ width: '50%' }}>
      <div className="font-size-bg font-bold">Booked Cases Top Surgeons</div>
      <div className="secondary m-t-sm m-b-lg">Total cases completed by surgeon over the next 90 days</div>
      <BarChart
        data={data || []}
        indexBy="id"
        height="300px"
        layout="horizontal"
        enableLabel={true}
        margin={{ top: 10, right: 25, bottom: 30, left: presetCompare ? 90 : 85 }}
        colors={(bar) => {
          if (bar.indexValue?.includes('_compare')) {
            return previousColors[bar.indexValue];
          }
          return colors[bar.indexValue];
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          truncateTickAt: 0,
          format: id => getSurgeonName(id),
        }}
        labelTextColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              1.6,
            ],
          ],
        }}
        CustomTooltip={(props) => <CustomTooltip {...props} surgeons={surgeons} />}
      />
    </div>
  );
};

export default ForecastingSurgeonsBarChart;
