import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function CountIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none"
          d="M1.23331 3.08692C0.589315 3.4728 0.589519 4.02915 1.23019 4.41629C1.86052 4.79719 2.5178 5.18644 3.26829 5.54649C4.01878 5.90653 4.83015 6.22185 5.62411 6.52425C6.43109 6.83161 7.59076 6.83171 8.3951 6.52276C9.18212 6.22046 9.98511 5.90469 10.7317 5.5465C11.4784 5.18831 12.1366 4.80308 12.7667 4.42551C13.4107 4.03963 13.4105 3.48329 12.7698 3.09614C12.1395 2.71524 11.4822 2.32599 10.7317 1.96595C9.98122 1.60591 9.16985 1.29058 8.37589 0.988183C7.5689 0.680823 6.40924 0.680725 5.6049 0.989677C4.81788 1.29197 4.01489 1.60774 3.26826 1.96593C2.52162 2.32413 1.86343 2.70935 1.23331 3.08692Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M13.2501 7.79474C12.5672 8.17231 11.8539 8.55754 11.0447 8.91573C10.2355 9.27392 9.36523 9.58969 8.51227 9.89199C7.64054 10.2009 6.38371 10.2008 5.50911 9.89348C4.64864 9.59108 3.76929 9.27576 2.95592 8.91571C2.14256 8.55567 1.43021 8.16642 0.74707 7.78552" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M13.2501 10.9197C12.5672 11.2973 11.8539 11.6825 11.0447 12.0407C10.2355 12.3989 9.36523 12.7147 8.51227 13.017C7.64054 13.3259 6.38371 13.3258 5.50911 13.0185C4.64864 12.7161 3.76929 12.4008 2.95592 12.0407C2.14256 11.6807 1.43021 11.2914 0.74707 10.9105" />
  </SvgIcon>
}
