import React, { useState, useCallback } from 'react';

import InventoryIcon from '../../assets/icons/CountIcon';
import UsageIcon from '../../assets/icons/UsageIcon';
import CaseIcon from '../../assets/icons/CaseIcon';

import EmailsModal from './EmailsModal';
import Button from '../shared/Button';

import { emailTriggers } from '../../constants';

import './emails.scss';

const EmailTriggersContainer = (props) => {
  const { emails, type, onSubmit } = props;

  const [isModalOpen, toggleModal] = useState(false);

  const getIcon = useCallback(() => {
    switch (type) {
      case emailTriggers.COUNT_COMPLETED:
        return <InventoryIcon />;
      case emailTriggers.CASE_BOOKED:
        return <CaseIcon />;
      case emailTriggers.USAGE_SUBMITTED:
        return <UsageIcon />;
      default:
        return null;
    }
  }, [type]);

  const getTitle = useCallback(() => {
    switch (type) {
      case emailTriggers.COUNT_COMPLETED:
        return 'Count Completed';
      case emailTriggers.CASE_BOOKED:
        return 'Case Booked';
      case emailTriggers.USAGE_SUBMITTED:
        return 'Usage Submitted';
      default:
        return '';
    }
  }, [type]);

  const getDescription = useCallback(() => {
    switch (type) {
      case emailTriggers.COUNT_COMPLETED:
        return 'Add email addresses below that will receive an email each time a count is completed with the count information attached as a .xls export';
      case emailTriggers.CASE_BOOKED:
        return 'Add email addresses below that will receive an email each time new case is booked';
      case emailTriggers.USAGE_SUBMITTED:
        return 'Add email addresses below that will receive an email each time usage is submitted against a case';
      default:
        return '';
    }
  }, [type]);

  return (
    <div className='email-triggers__container'>
      <div className='d-flex'>
        {getIcon()}
        <div className='font-size-bg font-bold m-l-md'>
          {getTitle()}
        </div>
      </div>
      <div className='m-t-md'>{getDescription()}</div>
      <div className='m-t-lg secondary font-bold'>Recipients</div>
      <div className='emails-list__container'>
        {emails.map((email, index) => (
          <div
            key={`${email}_${Math.random()}`}
            className='font-bold m-b-sm'
          >
            <span className='m-r-sm'>{`${index + 1}.`}</span>
            <span>{email}</span>
          </div>
        ))}
      </div>
      <div className='d-flex flex-end m-t-lg'>
        <Button
          type='submit'
          text='Edit'
          onClick={() => toggleModal(true)}
        />
      </div>

      <EmailsModal
        isOpen={isModalOpen}
        onClose={() => toggleModal(false)}
        title={getTitle()}
        initialList={emails || []}
        onSubmit={(list) => onSubmit(list, type)}
      />
    </div>
  );
};

export default EmailTriggersContainer;
