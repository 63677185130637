import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import { TasksTable, TasksFilters } from '../../../components/customers/tasks';
import TaskForm from '../../../components/customers/forms/TaskForm';
import Input from '../../../components/shared/Input';
import Modal, { ConfirmationModal } from '../../../components/shared/modal';
import Alert from '../../../components/shared/Alert';

import {
  getTasks,
  createTask,
  updateTask,
  subscribeToTasks,
  completeTask,
} from '../../../actions/tasksActions';
import { getSurgeons } from '../../../actions/surgeonsActions';
import { getHospitals } from '../../../actions/hospitalsActions';

import { filterTasks } from '../../../utils/table';

const TasksPage = (props) => {
  const {
    tasks,
    surgeons,
    hospitals,
    createTask,
    updateTask,
    subscribeToTasks,
    getSurgeons,
    getHospitals,
    completeTask,
  } = props;

  const [isModalCreateOpen, toggleModalCreate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalCompleteOpen, toggleModalComplete] = useState(false);

  const [successMessage, setSuccessMessage] = useState(null);

  const [selectedTask, setSelectedTask] = useState(null);
  const [checkedTasks, setCheckedTasks] = useState([]);

  const [search, setSearch] = useState('');
  const [surgeon, setSurgeon] = useState('');

  useEffect(() => {
    const unsubscribe = subscribeToTasks();

    onLoad().catch((err) => console.error(err));

    return () => {
      unsubscribe();
    };
  }, []);

  const onLoad = async () => {
    await getSurgeons();
    await getHospitals();
  };

  const onSelectTask = (task) => {
    setSelectedTask(task);
  };

  // Create task
  const onCreateTask = async (formObj) => {
    setLoading(true);
    const taskObj = {
      title: formObj.title || '',
      description: formObj.description || '',
      dueDate: formObj.dueDate || null,
      surgeonId: formObj.surgeonId || null,
      hospitalId: formObj.hospitalId || null,
    };

    try {
      await createTask(taskObj);
      setLoading(false);
      toggleModalCreate(false);
      setSuccessMessage('Task has been successfully created');
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Update task
  const onUpdateTask = async (formObj) => {
    setLoading(true);
    const taskObj = {
      title: formObj.title || '',
      description: formObj.description || '',
      dueDate: formObj.dueDate || null,
      surgeonId: formObj.surgeonId || null,
      hospitalId: formObj.hospitalId || null,
    };

    try {
      await updateTask(formObj.id, taskObj);
      setLoading(false);
      setSuccessMessage('Task has been successfully updated');
      setSelectedTask(formObj);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const taskList = useMemo(() => {
    return tasks.filter((task) => filterTasks(task, search, surgeon));
  }, [tasks, search, surgeon]);

  const handleCheck = (taskId) => {
    const checked = checkedTasks.slice();

    if (checked.includes(taskId)) {
      const index = checked.indexOf(taskId);

      checked.splice(index, 1);
      setCheckedTasks(checked);

      return;
    }

    checked.push(taskId);
    setCheckedTasks(checked);
  };

  const handleCheckAll = () => {
    const checked = checkedTasks.length === taskList.length;
    const temp = [];

    if (!checked) {
      taskList.forEach((task) => {
        temp.push(task.id);
      });

      setCheckedTasks(temp);
      return;
    }

    setCheckedTasks(temp);
  };

  const onCompleteClick = () => {
    toggleModalComplete(true);
  };

  const handleComplete = () => {
    completeTask(selectedTask.id);
    onCloseCompleteModal();
  };

  const onCloseCompleteModal = () => {
    toggleModalComplete(false);
    setSelectedTask(null)
  };

  return (
    <div className='settings-cmp__main'>
      <span className='settings-title'>Tasks</span>
      <div className='settings-cmp__body'>
        <div className='filters-container'>
          <TasksFilters
            onAddTaskClick={() => toggleModalCreate(true)}
            surgeon={surgeon}
            surgeons={surgeons}
            setSurgeon={setSurgeon}
          />
          <TasksTable
            onSelectTask={onSelectTask}
            tasks={taskList}
            selectedTaskId={selectedTask ? selectedTask.id : null}
            handleCheck={handleCheck}
            checkedTasks={checkedTasks}
            handleCheckAll={handleCheckAll}
            surgeons={surgeons}
          />
        </div>
        {tasks && !!tasks.length && (
          <div className='form-container'>
            <Input
              type='search'
              placeholder='Search Tasks'
              value={search}
              onChange={handleSearch}
            />
            { selectedTask && (
              <TaskForm
                initialValues={selectedTask}
                buttonText='Save Changes'
                onSubmit={onUpdateTask}
                loading={loading}
                surgeons={surgeons}
                hospitals={hospitals}
                mode='update'
                onComplete={onCompleteClick}
              />
            )}
          </div>
        )}
      </div>

      <Modal
        open={isModalCreateOpen}
        onClose={() => toggleModalCreate(false)}
      >
        <TaskForm
          buttonText='Add Task'
          onSubmit={onCreateTask}
          loading={loading}
          mode='create'
          surgeons={surgeons}
          hospitals={hospitals}
          onClose={() => toggleModalCreate(false)}
        />
      </Modal>

      <Alert
        variant='success'
        message={successMessage}
        open={!!successMessage}
        onClose={() => setSuccessMessage(null)}
      />

      <ConfirmationModal
        open={isModalCompleteOpen}
        onClose={() => toggleModalComplete(false)}
        onSubmit={handleComplete}
        title='Are you sure you want to complete this task?'
        text='Completed tasks will automatically be deleted from your task list'
        submitText='Complete'
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tasks: state.tasks.list,
    tenantColor: state.tenant.currentTenant.colorPrimary,
    surgeons: state.surgeons.list,
    hospitals: state.hospitals.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTasks: () => dispatch(getTasks()),
    createTask: (task) => dispatch(createTask(task)),
    updateTask: (id, taskData) => dispatch(updateTask(id, taskData)),
    subscribeToTasks: () => dispatch(subscribeToTasks()),
    getSurgeons: () => dispatch(getSurgeons()),
    getHospitals: () => dispatch(getHospitals()),
    completeTask: (id) => dispatch(completeTask(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksPage);
