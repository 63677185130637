/* eslint-disable no-unused-vars */

const azureDevConfig = {
  CLIENT_ID: '6c4f3f25-1a7f-4943-b22e-563b67a072ff',
  TENANT_ID: '2f63abbc-adba-49fd-9f0e-0041c9a23407',
  REDIRECT_URI: process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://casedev-opologic.web.app/__/auth/handler',
};

const azureStageConfig = {
  CLIENT_ID: '6c4f3f25-1a7f-4943-b22e-563b67a072ff',
  TENANT_ID: '2f63abbc-adba-49fd-9f0e-0041c9a23407',
  REDIRECT_URI: process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://casetest-opologic.web.app/__/auth/handler',
};

const azureProdConfig = {
  CLIENT_ID: '1111a706-c65c-4eb2-a698-105efd31dc87',
  TENANT_ID: 'd9493b8b-af4a-4b65-883a-e6a78cc8f0ad',
  REDIRECT_URI: process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://case.opologic.com/__/auth/handler',
};

export default azureProdConfig;