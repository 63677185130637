import React from 'react';

import DeleteIcon from '../../assets/icons/DeleteIcon';
import FileCopyIcon from '../../assets/icons/CopyIcon';
import UploadIcon from '../../assets/icons/UploadIcon';

import Button from '../shared/Button';
import Select from '../shared/Select';

const ProceduresFilters = (props) => {
  const {
    onAddProcedureClick,
    status,
    setStatus,
    statuses,
    onDelete,
    onUploadClick,
    onDuplicateClick,
    deletePermission
  } = props;

  return (
    <div className='settings-cmp__filters--main'>
      <div className='settings-cmp__actions'>
        <Button
          text='Add Procedure'
          type='submit'
          onClick={onAddProcedureClick}
        />

        <Button
          type='submit'
          width='60px'
          onClick={onUploadClick}
        >
          <UploadIcon color="#ffffff" />
        </Button>

        <Button
          type='icon'
          onClick={onDelete}
          disabled={!deletePermission}
        >
          <DeleteIcon style={{ color: '#000000' }}/>
        </Button>

        <Button
         type='icon'
         onClick={onDuplicateClick}
        >
          <FileCopyIcon style={{ color: '#000000' }}/>
        </Button>
      </div>

      <div className='settings-cmp__filters'>
        <Select
          value={status}
          onChange={setStatus}
          options={statuses}
          defaultLabel={`All Status (${statuses.length})`}
          width={200}
        />
      </div>
    </div>
  )
};

export default ProceduresFilters;
