import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function TurnBackIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path id="Vector 1221" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M5.34635 4.5H9c2.2091 0 4 1.79086 4 4v0c0 2.2091 -1.7909 4 -4 4H4.5" strokeWidth="1"></path>
    <path id="Vector 2593" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M3.94847 1.55449c-1.12132 0.6581 -2.09922 1.7162 -2.64308 2.47416 -0.20346 0.28356 -0.20346 0.65918 0 0.94273 0.54386 0.75797 1.52176 1.81606 2.64308 2.47417 0.45722 0.26834 1.00564 0.01705 1.1248 -0.49954 0.14208 -0.61599 0.27048 -1.46397 0.27048 -2.44599 0 -0.98202 -0.1284 -1.83001 -0.27048 -2.446 -0.11916 -0.51658 -0.66758 -0.76787 -1.1248 -0.49953Z"
          strokeWidth="1"></path>
  </SvgIcon>
}
