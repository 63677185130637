import React, { useMemo } from 'react';
import NumberFormat from 'react-number-format';

import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

import Table from '../../shared/table/Table';

import WarningIcon from '../../../assets/icons/WarningIcon';

import { statusColors } from '../../../constants/cases';

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: '12px',
    padding: '4px 8px',
    borderRadius: 8
  }
}));

const CaseProformaTable = (props) => {
  const {
    list = [],
    onSelect = () => {}
  } = props;

  const classes = useStyles();

  const columns = useMemo(() => [
    { title: 'item code', field: 'code', type: 'default', sort: false },
    { title: 'description', field: 'description', type: 'default', sort: false },
    { title: 'rebate code', field: 'rebateCode', type: 'default', sort: false },
    { title: 'quantity', field: 'quantity', type: 'default', sort: false },
    {
      title: 'unit price',
      field: 'value',
      type: 'custom',
      sort: false,
      formatter: (value) => Number(value) ? (
        <NumberFormat
          value={Number(value)}
          displayType="text"
          thousandSeparator={true}
          fixedDecimalScale={2}
          decimalScale={2}
        />
      ) : ''
    },
    { title: 'disc %', field: 'discount', type: 'custom', sort: false, formatter: (value) => Number(value) || '' },
    { title: 'amount', field: 'amount', type: 'default', sort: false },
    {
      title: '', field: 'itemId', type: 'custom', formatter: (value) => {
        if (!value) {
          return (
            <Tooltip
              title="Item on this line is not contained in item master. Please check product is valid before printing proforma."
              aria-label="item-description"
              classes={{ tooltip: classes.tooltip }}
            >
              <div className="d-flex flex-end">
                <WarningIcon color={statusColors.REQUEST}/>
              </div>
            </Tooltip>
          );
        }
      },
    },
  ], []);

  const getAmount = (item) => {
    if (!Number(item.value)) {
      return 'N/A';
    }

    const quantity = item.quantity || 1;
    const value = quantity * Number(item.value);
    const result = item.discount ? value - (value * item.discount / 100) : value;

    return <NumberFormat
      value={result}
      displayType="text"
      thousandSeparator={true}
      fixedDecimalScale={2}
      decimalScale={2}
    />
  };

  return (
    <div style={{ width: '100%' }}>
      <Table
        rows={list.map((item) => ({
          ...item,
          amount: getAmount(item),
        }))}
        columns={columns}
        onRowClick={onSelect}
        rowColor='#ffffff'
        rowBorderColor='#fafafa'
        checkboxes={false}
        pagination
      />
    </div>
  );
};

export default CaseProformaTable;
