import React, { useState, useCallback } from 'react';
import { sortBy } from 'lodash';

import CustomDecodingItem from './CustomDecodingItem';
import Button from '../shared/Button';

import './scan.scss';

const CustomQRDecoding = ({ initialConfig, onSubmit, loading }) => {
  const [config, setConfig] = useState(sortBy(initialConfig, ['index']) || []);

  const onAdd = () => {
    if (!config.length || config.every((item) => item.index && item.field)) {
      setConfig([...config, { index: '', field: '', edit: true }]);
    }
  };

  const onSave = (index, field, i) => {
    const arr = [...config];
    arr[i] = { index, field, edit: false };
    setConfig(arr)
  };

  const onEdit = (i) => {
    if (config.some((item) => item.edit)) {
      return;
    }

    const arr = [...config];
    arr[i] = { ...config[i], edit: true };
    setConfig(arr);
  };

  const onDelete = (index) => {
    const arr = [...config];
    arr.splice(index, 1);
    setConfig(arr);
  };

  const handleSubmit = () => {
    onSubmit(config.map(({ index, field }) => ({ index, field })));
  };

  const isDisabled = useCallback(() => (
    !!config.length && config.some((item) => !item.index || !item.field)
  ), [config]);

  return (
    <div className='custom-decoding__container'>
      <div className='d-flex direction-column align-start'>
        <span className='custom-decoding__title'>Custom QR Decoding</span>
        { config && !!config.length && (
          <div className='custom-decoding__header'>
            <div>Section</div>
            <div>Field</div>
          </div>
        )}
        {config.map((item, i) => (
          <CustomDecodingItem
            key={`decoding-item_${item.field}_${item.index}_${Math.random()}`}
            initialField={item.field}
            initialIndex={item.index}
            edit={item.edit}
            onSave={(index, field) => onSave(index, field, i)}
            onEdit={() => onEdit(i)}
            onDelete={() => onDelete(i)}
            config={config}
          />
        ))}
        <Button
          type='submit'
          text='Add'
          onClick={onAdd}
          width={100}
          disabled={isDisabled()}
        />
      </div>
      <div className='submit-button__container'>
        <Button
          type='submit'
          text='Save Changes'
          onClick={handleSubmit}
          loading={loading}
          disabled={loading || isDisabled()}
        />
      </div>
    </div>
  );
};

export default CustomQRDecoding;
