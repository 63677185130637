import React from 'react';

import BatchControlIcon from '../../../assets/icons/BatchControlIcon';

import { itemTypeOptions, setPositionOptions } from '../../../constants/enums';
import Button from '../../shared/Button';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

const getBatchCodes = (batchItems) => {
  if (!batchItems) {
    return '';
  }

  return batchItems?.filter((i) => !!i.code).map((i) => i.code).join(', ');
};

const AddButton = ({
  variant,
  disabled,
  onClick,
  color
}) => (
  <Button
    type='outlined'
    height={25}
    width={25}
    disabled={disabled}
    onClick={onClick}
    borderColor={color}
  >
    {variant === 'minus' ? (
      <RemoveIcon fontSize='small' style={{ fill: disabled ? '#cccccc' : color }} />
    ) : (
      <AddIcon fontSize='small' style={{ fill: disabled ? '#cccccc' : color }} />
    )}
  </Button>
);


const BatchSectionItemRow = (props) => {
  const { item, onEditClick, batchItems = [], onQuantityChange, quantity } = props;

  const onImageClick = (e, row) => {
    if (row && row.image && row.image.downloadUrl) {
      window.open(row.image.downloadUrl, '_blank');
    }

    if (row && row.imageUrl) {
      window.open(row.imageUrl, '_blank');
    }

    e.stopPropagation();
  };

  const imageSrc = item?.image?.downloadUrl || item.imageUrl;

  const onMinusClick = () => {
    const itemQuantity = quantity !== undefined ? quantity : item.quantity;
    onQuantityChange(itemQuantity - 1);
  };

  const onPlusClick = () => {
    const itemQuantity = quantity !== undefined ? quantity : item.quantity;
    onQuantityChange(itemQuantity + 1);
  };

  return (
    <tr>
      <td>{item.ref}</td>
      <td>{item.code}</td>
      <td>{item.checklistDescription || item.description}</td>
      <td>{itemTypeOptions[item.type]?.label || item.type}</td>
      <td>
        {imageSrc && (
          <div className='item-image-preview' onClick={(e) => onImageClick(e, item)}>
            <img src={imageSrc} alt='preview' />
          </div>
        )}
      </td>
      <td>{!!item.batchControl ? getBatchCodes(batchItems) : null}</td>
      <td style={{ width: 50 }}>
        {item.batchControl ? <BatchControlIcon color={batchItems?.length === item.quantity ? setPositionOptions.CONSIGNED.color : setPositionOptions.ALLOCATED.color} /> : null}
      </td>
      <td style={{ textAlign: 'center' }}>{item.quantity}</td>
      <td style={{ width: 100 }}>
        {item.batchControl ? (
          <Button
            type='outlined'
            height={25}
            width={80}
            onClick={onEditClick}
            color={batchItems?.length === item.quantity ? setPositionOptions.CONSIGNED.color : setPositionOptions.ALLOCATED.color}
            borderColor={batchItems?.length === item.quantity ? setPositionOptions.CONSIGNED.color : setPositionOptions.ALLOCATED.color}
          >
            {batchItems?.length}
          </Button>
        ) : (
          <div className="d-flex flex-end">
            <AddButton
              variant="minus"
              onClick={onMinusClick}
              disabled={quantity === 0 || item.quantity === 0}
              color={quantity === undefined || quantity === item.quantity ? setPositionOptions.CONSIGNED.color : setPositionOptions.ALLOCATED.color}
            />
            <div
              style={{
                color: quantity === undefined || quantity === item.quantity ? setPositionOptions.CONSIGNED.color : setPositionOptions.ALLOCATED.color,
                textAlign: 'center',
                marginLeft: 4,
                marginRight: 4,
                width: 25,
              }}
            >
              {quantity !== undefined ? quantity : item.quantity}
            </div>
            <AddButton
              variant="plus"
              onClick={onPlusClick}
              disabled={quantity === undefined || quantity === item.quantity}
              color={quantity === undefined || quantity === item.quantity ? setPositionOptions.CONSIGNED.color : setPositionOptions.ALLOCATED.color}
            />
          </div>
        )}
      </td>
    </tr>
  );
};

export default BatchSectionItemRow;
