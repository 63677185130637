import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function ScanIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M1 1.64923L1 9.07905" />
      <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M3.56702 1.64923L3.56702 9.07905" />
      <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M6.13403 1.64923L6.13403 9.07905" />
      <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M13 12.3508L0.999999 12.3508" />
      <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M13 1.64923L13 9.07905" />
      <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M10.4639 1.64923L10.4639 9.07905" />
    </svg>
  </SvgIcon>
}
