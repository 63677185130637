import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';

import FullScreenIcon from '@material-ui/icons/Fullscreen';

import {
  BillOfMaterialActions,
  BillOfMaterialBreadcrumbs,
  SectionsList,
  SectionItemsList,
} from '../../../components/bill-of-material';
import Input from '../../../components/shared/Input';
import LoadScreen from '../../../components/load-screen';
import ChecklistsPDF from '../../../components/cases/checklists/ChecklistsPDF';

import { getKit, getKits, subscribeToSections } from '../../../actions/kitsActions';

import { useLoading } from '../../../hooks';

import { downloadFile } from '../../../utils/utils';

import routes from '../../../constants/routes';
import { billOfMaterialItemTypes } from '../../../constants/enums';

const BOMViewPage = () => {
  const { id } = useParams();
  const kitId = id.replace(':', '');
  const history = useHistory();

  const { search: params } = useLocation();
  const redirect = params ? params?.split('?redirect=')[1] : null;

  const dispatch = useDispatch();
  const tenant = useSelector((state) => state?.tenant.currentTenant);
  const itemsList = useSelector((state) => state?.items.list);
  const itemsLoaded = useSelector((state) => state?.items.isLoaded);
  const kits = useSelector((state) => state?.kits.list);

  const { loading, startLoading, stopLoading } = useLoading(false);
  const { fetching, startLoading: startFetching, stopLoading: stopFetching } = useLoading(false);

  const [kit, setKit] = useState(null);

  const [search, setSearch] = useState('');

  const [sections, setSections] = useState([]);
  const [selectedSection, selectSection] = useState(null);

  const [items, setItems] = useState([]);
  const [selectedItem, selectItem] = useState(null);

  useEffect(() => {
    const unsubscribe = dispatch(subscribeToSections(kitId, setSections));

    onLoad().catch((err) => console.error(err));

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (selectedSection) {
      setItems(selectedSection?.items || []);
    } else {
      setItems([]);
    }
  }, [selectedSection]);

  const onLoad = async () => {
    startFetching();

    try {
      await dispatch(getKits());
      const res = await dispatch(getKit(kitId));
      // await dispatch(getItems());
      if (res && res?.id) {
        setKit(res);
      }
    } catch (err) {
      console.error(err);
    } finally {
      stopFetching();
    }
  };

  const onBackClick = () => {
    if (redirect === 'drive') {
      history.push(routes.DRIVE);
    } else {
      history.push(routes.SETS);
    }
  };

  const onExportClick = async () => {
    startLoading();
    await downloadChecklistsDoc();
    stopLoading();
  };

  const downloadChecklistsDoc = async () => {
    const checklists = [{
      kitId: kit.kitId,
      sections: sections?.map((section) => ({
        ...section,
        items: section?.items?.map((item) => {
          if (item.type === billOfMaterialItemTypes.NOTES.value) {
            return item;
          } else {
            const _item = itemsList?.find((i) => i.id === item.id);
            return { ...item, code: _item.code || '', description: _item.checklistDescription || _item.description || '' };
          }
        })
      })),
    }];

    console.log(checklists);

    const blob = await pdf(
      <ChecklistsPDF
        tenant={tenant}
        checklists={checklists}
        kits={kits}
        sets={[]}
      />
    ).toBlob();
    downloadFile(blob, `${kit.kitId} – BOM.pdf`);
  };

  return (
    <div className='settings-cmp__main flex-1 bom-page__container'>
      {(fetching || !itemsLoaded) && <LoadScreen />}

      <span className='settings-title'>BOM</span>
      <div className='bom-page__body flex-1'>
        <div className='settings-block__left' style={{ flex: 0.25 }}>
          <BillOfMaterialActions
            isDrive={redirect === 'drive'}
            onBackClick={onBackClick}
            onExportClick={onExportClick}
            loading={loading}
          />
          <SectionsList
            kitId={kit?.kitId}
            sections={sections}
            onClick={(section) => {
              selectItem(null);
              selectSection(section);
            }}
            tenantColor={tenant.colorPrimary}
            selectedSection={selectedSection}
          />
        </div>
        <div style={{ flex: 0.75 }}>
          <div>
            <div>
              <BillOfMaterialBreadcrumbs
                kitId={kit?.kitId}
                selectedSection={selectedSection}
                tenantColor={tenant.colorPrimary}
              />
            </div>
            <div>
              <Input
                type='search'
                placeholder='Search'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>

          {selectedSection && selectedSection?.image && (
            <div>
              <div className='image-container' style={{ marginLeft: 0, marginRight: 0, minHeight: 250 }}>
                {selectedSection && selectedSection.image && (
                  <>
                    <img src={selectedSection?.image?.downloadUrl} alt='section-preview' />
                    <div
                      className='full-screen-icon'
                      onClick={() => window.open(selectedSection?.image?.downloadUrl, '_blank')}
                    >
                      <FullScreenIcon color='secondary' fontSize='large' />
                    </div>
                  </>
                )}
              </div>
            </div>
          )}

          {selectedSection && (
            <SectionItemsList
              items={items}
              search={search}
              selectedItem={selectedItem}
              tenantColor={tenant.colorPrimary}
              itemsList={itemsList}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BOMViewPage;
