import React, { useState } from 'react';
import readXlsxFile from 'read-excel-file';

import Modal from '../../shared/modal/Modal';
import Button from '../../shared/Button';
import Dropzone from '../../shared/dropzone';

const BatchImportModal = (props) => {
  const {
    open,
    onSubmit,
    onClose,
    loading,
    tenantColor,
  } = props;

  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);

  const onRemove = () => {
    setFile(null);
  };

  const onFileChange = async (files) => {
    if (files && files.length) {
      setFile(files[0]);
    }
    const rows = await readXlsxFile(files[0]);
    if (rows && rows.length) {
      setData(rows);
    }
  }

  const handleSubmit = () => {
    const items = [...data];
    items.shift();
    onSubmit(items);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Update Batch Items"
      fullWidth
    >
      <div className='upload-modal__container'>
        <div className='m-b-lg'>
          Please provide *.XLSX file for upload
        </div>

        <Dropzone
          files={file ? [file] : []}
          onUpload={onFileChange}
          onRemove={onRemove}
          tenantColor={tenantColor}
          multiple={false}
          withoutProgress
        />
        <div className='upload-modal__actions'>
          <Button
            type='cancel'
            onClick={onClose}
          />
          <Button
            type='submit'
            text='Upload'
            disabled={loading || !data.length}
            loading={loading}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default BatchImportModal;
