import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function HelpIcon(props) {
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={props.color || defaultColor} fill="none"
          d="M0.959867 10.2685C1.114 11.7092 2.2727 12.8679 3.71266 13.0284C4.78221 13.1476 5.88037 13.25 7 13.25C8.11963 13.25 9.21779 13.1476 10.2873 13.0284C11.7273 12.8679 12.886 11.7092 13.0401 10.2685C13.1539 9.20502 13.25 8.11315 13.25 7C13.25 5.88684 13.1539 4.79498 13.0401 3.73147C12.886 2.29082 11.7273 1.13211 10.2873 0.971609C9.21779 0.852392 8.11963 0.75 7 0.75C5.88037 0.75 4.78221 0.852392 3.71266 0.971609C2.2727 1.13211 1.114 2.29082 0.959867 3.73147C0.846083 4.79498 0.75 5.88684 0.75 7C0.75 8.11315 0.846084 9.20502 0.959867 10.2685Z" />
    <path stroke={props.color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M7.00061 7.45422C7.00061 7.09317 7.29426 6.8717 7.81234 6.52553C8.29289 6.20443 8.54637 5.78791 8.43362 5.22107C8.32087 4.65422 7.8525 4.18586 7.28565 4.0731C6.39586 3.89611 5.53955 4.59886 5.53955 5.50608" />
    <path stroke={props.color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M7 9.9541L7 9.7041" />
  </SvgIcon>
}
