import React, { useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';

import Button from '../../shared/Button';
import Checkbox from '../../shared/Checkbox';
import Input from '../../shared/Input';
import MultiSelect from '../../shared/MultiSelect';

import userRoles, { territoryRoles } from '../../../constants/userRoles';

import '../../../styles/shared/form.scss';

const Step3 = (props) => {
  const {
    onSubmit = () => {},
    loading,
    initialValues = {},
    users = [],
    groups = [],
    onClickBack,
    hospitals = []
  } = props;

  const [search, setSearch] = useState('');

  const validate = (values) => {
    const errors = {};
    return errors;
  };

  // const list = useMemo(() => {
  //   if (!initialValues.hospitalId) {
  //     return [];
  //   }
  //
  //   let result = [];
  //
  //   result = users.filter((user) => {
  //     if ([ADMIN, OPERATIONS, CUSTOMER_SERVICE, LOGISTICS, SALES_MANAGER, MARKETING, FINANCE].includes(user.role)) {
  //       return false;
  //     }
  //
  //     const hospitalSurgeons = surgeons.filter((surgeon) => surgeon.hospitals.includes(initialValues.hospitalId));
  //     return !!user.active && user.assignedSurgeons && !user.assignedSurgeons.some((item) => hospitalSurgeons.map((s) => s.id).includes(item));
  //   });
  //
  //   return result?.filter((user) => user?.lastName?.toLowerCase()?.includes(search?.toLowerCase()));
  // }, [users, initialValues.hospitalId, search]);

  const list = useMemo(() => {
    if (!users) {
      return [];
    }

    return users?.filter((user) => {
      let filter = true;

      if (territoryRoles?.includes(user.role) && !!user.territoryVisibility) {
        const hospital = hospitals?.find((h) => h.id === initialValues.hospitalId);
        if (!hospital?.territories.some((territory) => user?.assignedTerritories?.includes(territory))) {
          filter = false;
        }
      }

      return filter && (user?.firstName?.toLowerCase()?.includes(search?.toLowerCase()) || user?.lastName?.toLowerCase()?.includes(search?.toLowerCase()));
    });
  }, [users, search, initialValues.hospitalId]);

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        subscription={{ pristine: true, invalid: true }}
        initialValues={initialValues}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine, form }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <>
                <div>
                  <Input
                    type='search'
                    placeholder='Search Users'
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>

                <div className=''>
                  <Field name='users'>
                    {({ input, meta }) => (
                      <>
                        <div className='field__title'>Assign Users</div>
                        <div className='m-b-lg form__select-users' style={{ maxHeight: 250 }}>
                          {list?.length ? (
                            list?.map((user) => (
                              <div className='md-user-info' key={user.uid}>
                                <Checkbox
                                  input={{
                                    onClick: () => {
                                      if (input?.value?.includes(user.uid)) {
                                        input?.onChange(input?.value?.filter((uid) => uid !== user.uid));
                                      } else {
                                        input?.onChange([...input?.value, user.uid]);
                                      }
                                    },
                                    checked: input.value?.includes(user.uid)
                                  }}
                                  fontSize='small'
                                  label={`${user.firstName} ${user.lastName}`}
                                />
                                <span>{userRoles[user.role]?.label}</span>
                              </div>
                            ))
                          ) : (
                            <div className='text-center secondary p-md'>
                              No users
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </Field>
                </div>

                <div className='m-b-lg'>
                  <div className='field__title'>Assign Groups</div>
                  <Field name='groups'>
                    {({ input, meta }) => (
                      <MultiSelect
                        name={input.name}
                        value={input.value || []}
                        onChange={input.onChange}
                        options={groups?.map((group) => ({ label: group.name, value: group.id }))}
                      />
                    )}
                  </Field>
                </div>
              </>

              <div className='form__actions'>
                <Button
                  type='submit'
                  text='Submit'
                  disabled={invalid || loading}
                  loading={loading}
                  onClick={handleSubmit}
                />
                <Button
                  type='cancel'
                  text='Back'
                  onClick={onClickBack}
                />
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default Step3;
