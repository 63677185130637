import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import SetLabel from './SetLabel';
import Input from '../shared/Input';
import Button from '../shared/Button';
import Modal from '../shared/modal/Modal';

import { addLabel } from '../../actions/setsActions';

import '../shared/modal-select/modal-select.scss';

const SetLabelsModal = (props) => {
  const {
    title,
    labels,
    onChange,
    selected,
    onClose,
    isOpen,
  } = props;

  const dispatch = useDispatch();

  const [labelIds, setLabelIds] = useState(selected);
  const [newLabel, setNewLabel] = useState('');
  const [search] = useState('');

  useEffect(() => {
    setLabelIds(selected);
  }, [selected]);

  const handleNewLabels = (labelId) => {
    const temp = labelIds.slice();
    if (temp.includes(labelId)) {
      const index = temp.indexOf(labelId);

      temp.splice(index, 1);
      setLabelIds(temp);
      return;
    }

    temp.push(labelId);
    setLabelIds(temp);
  };

  const handleAdd = () => {
    dispatch(addLabel(newLabel));
    setNewLabel('');
  };

  const list = useMemo(() => {
    return labels?.filter((item) => item?.name.toLowerCase().includes(search.toLowerCase())) || []
  }, [search, labels]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <div className='form__container'>
        <div className='form__body'>

          <div className='form__subtitle'>
            {title}
          </div>

          <div className='d-flex'>
            <Input
              type='text'
              placeholder='New Label'
              value={newLabel}
              onChange={(e) => setNewLabel(e.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  handleAdd();
                }
              }}
              maxLength={20}
            />
            <div className='m-b-lg m-l-md'>
              <Button
                type='submit'
                text='Add'
                onClick={handleAdd}
                width={100}
                disabled={!newLabel.length}
              />
            </div>
          </div>

          <div className='field__title'>Labels</div>
          <div className='m-b-lg form__select-users'>
            {list.length ? (
              list.map((label) => (
                <SetLabel
                  key={label.id}
                  checked={labelIds.indexOf(label.id) !== -1}
                  onChange={() => handleNewLabels(label.id)}
                  id={label.id}
                  name={label.name}
                />
              ))
            ) : (
              <div className='text-center secondary p-md'>
                No Labels
              </div>
            )}
          </div>

          <div className='form__actions'>
            <Button
              type='submit'
              text='Submit'
              onClick={() => {
                onChange(labelIds);
                onClose();
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SetLabelsModal;
