import { orderBy } from 'lodash';

import { GET_TERRITORIES, SET_TERRITORIES } from './actionTypes';

import firebase, { collections } from '../firebase';

export const setTerritories = territories => ({ type: SET_TERRITORIES, territories });

export const getTerritories = (tenant, withReducer) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = tenant || state?.tenant?.currentTenant?.id;
  const path = collections.TERRITORIES_COLLECTION(tenantId);

  dispatch({ type: GET_TERRITORIES });

  const snapshot = await firebase.db.collection(path).get();
  const territories = snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));

  if (tenant && !withReducer) {
    return orderBy(territories, 'name', 'asc');
  }

  return dispatch(setTerritories(orderBy(territories, 'name', 'asc')));
};

export const createTerritory = (territoryData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.TERRITORIES_COLLECTION(tenantId);

  const doc = {
    ...territoryData,
    active: true,
  };

  if (tenantId) {
    await firebase.db.collection(path).add(doc);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateTerritory = (territoryId, territoryData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.TERRITORIES_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(territoryId).set(territoryData, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteTerritory = (territoryId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.TERRITORIES_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(territoryId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const bulkDeleteTerritories = (territories) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.TERRITORIES_COLLECTION(tenantId);
  const batch = firebase.db.batch();

  if (tenantId) {
    territories.forEach((territoryId) => {
      const ref = firebase.db.collection(path).doc(territoryId);
      batch.delete(ref);
    });

    await batch.commit();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const uploadTerritories = (territories) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.TERRITORIES_COLLECTION(tenantId);

  if (tenantId) {
    const promises = [];

    territories.forEach((territory) => {
      const name = territory[0] || '';
      const description = territory[1] || '';

      const doc = {
        name,
        description,
        active: true,
      };

      promises.push(firebase.db.collection(path).add(doc));
    });

    await Promise.all(promises);
  } else {
    throw new Error('Invalid tenant');
  }
};

// Subscriptions
export const subscribeToTerritories = () => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.TERRITORIES_COLLECTION(tenantId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let territories = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          territories = [...territories, {
            ...documentSnapshot.data(),
            id,
          }];
        });

        return dispatch(setTerritories(orderBy(territories, 'name', 'asc')));
      },
    });
};
