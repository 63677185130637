import React, { useMemo } from 'react';
import { orderBy, sum } from 'lodash';

import { PieChart } from '../../../charts';
import { formatChartPrice } from '../../../../utils/utils';

export const CustomTooltip = ({ datum }) => {
  const { id, formattedValue } = datum;

  return (
    <div className="custom-tooltip">
      <div className="tooltip-label">{id}</div>
      <div className="tooltip-content">
        <div>{`$${formatChartPrice(formattedValue, 0)}`}</div>
      </div>
    </div>
  );
};

const groupSetsBy = (sets, key) => {
  return sets.reduce((acc, item) => {
    const field = item[key];
    if (!acc[field]) {
      acc[field] = [];
    }
    acc[field].push(item);
    return acc;
  }, {});
};

const calculateFreightCost = (sets) => {
  const shipments = groupSetsBy(sets, 'trackingNumber');
  return sum(Object.keys(shipments).map((trackingNumber) => shipments[trackingNumber][0]?.freightCost || 0));
};

const FreightCostPieChart = ({ sets = [], hospitals = [], tenantColor, minDate, maxDate }) => {
  const data = useMemo(() => {
    const states = [];
    hospitals?.forEach((hospital) => {
      if (!states?.includes(hospital.state)) {
        states.push(hospital.state);
      }
    });
    const chartData = states
      .map((state) => {
        const stateCases = sets.filter((item) => {
          const hospital = hospitals?.find((h) => item.hospital === h.id);
          return hospital.state === state && minDate.isBefore(item.shippingDate) && maxDate.isAfter(item.shippingDate);
        });

        const cases = groupSetsBy(stateCases, 'caseId');
        const value = sum(Object.keys(cases).map((caseId) => calculateFreightCost(cases[caseId])));

        return {
          id: state,
          label: state,
          value,
        };
      })

    return orderBy(chartData?.filter((item) => !!item.value), 'value', 'asc');
  }, [sets, hospitals, minDate, maxDate]);

  return (
    <div className="management-reports-chart__container m-r-md" style={{ width: '50%' }}>
      <div className="font-size-bg font-bold">Total Freight Cost</div>
      <div className="secondary m-t-sm m-b-lg">Total freight cost shown by state of hospital</div>
      <PieChart
        data={data}
        margin={{ top: 20, right: 60, bottom: 20, left: -60 }}
        height="300px"
        enableArcLinkLabels={true}
        enableArcLabels={true}
        CustomTooltip={CustomTooltip}
        arcLabel={({ value }) =>`$${formatChartPrice(value, 0)}`}
        isCurrency
      />
    </div>
  );
};

export default FreightCostPieChart;
