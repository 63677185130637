import React from 'react';

import LocationIcon from '../../assets/icons/LocationIcon';

import './hospitals.scss';

const BillingAddressInput = ({ onClick, address }) => {
  return (
    <div
      className='addresses-input__container'
      style={{ alignItems: 'center' }}
      onClick={onClick}
    >
      <div className='addresses-input__list'>
        {address ? `${address?.street}, ${address?.city}` : ''}
      </div>
      <div
        className='addresses-input__label'
      >
        <span>Edit</span>
        <LocationIcon color='secondary' />
      </div>
    </div>
  );
};

export default BillingAddressInput;
