import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import LoginHeader from '../../components/auth/LoginHeader';
import { StepOne, StepTwo, StepThree, StepFour, ContactUs } from '../../components/auth/sign-up-steps';

import { setFormState, signUp, checkEmail } from '../../actions/authActions';

import { withFirebase } from '../../firebase';

import { routes, settings } from '../../constants';

import './auth-page.scss';

const SignUpPage = (props) => {
  const { formState, setFormState, signUp, history, firebase } = props;

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [minLength, setMinLength] = useState(0);

  useEffect(() => {
    firebase.getSettingsConfig(settings.PASSWORD_MIN_LENGTH)
      .then((res) => setMinLength(res && res.value ? res.value : 10))
      .catch((err) => console.error(err));
  }, []);

  const onStepBack = (step) => {
    setStep(step - 1);
  };

  const onStepNext = (step, formObj) => {
    setStep(step + 1);
    setFormState(formObj);
  };

  const onSignUp = async (formObj) => {
    setFormState(formObj);
    setLoading(true);

    const approved = await checkEmail(formObj.email);

    if (!approved) {
      setStep(4);
      return;
    }

    const authData = { ...formState, email: formObj.email, password: formObj.password };

    delete authData.confirmPassword;
    delete authData.notARobot;

    try {
      await signUp(authData);
      setLoading(false);
      history.push(routes.HOME);
    } catch (err) {
      setErrorText(err && err.message ? err.message : 'SignUp error. Please, try again.');
      setLoading(false);
      setError(true);
    }
  };

  const renderStep = (step) => {
    const {
      businessName,
      maxEmployee,
      country,
      firstName,
      lastName,
      businessEmail,
      address1,
      address2,
      city,
      state,
      postCode,
      phone,
      email,
      password,
      confirmPassword
    } = formState;

    switch(step) {
      case 0:
        return <StepOne
          step={0}
          onStepNext={onStepNext}
          initialValues={{ businessName, maxEmployee, country }}
        />;
      case 1:
        return <StepTwo
          step={1}
          onStepBack={onStepBack}
          onStepNext={onStepNext}
          initialValues={{ firstName, lastName, businessEmail }}
        />;
      case 2:
        return <StepThree
          step={2}
          onStepBack={onStepBack}
          onStepNext={onStepNext}
          setFormState={setFormState}
          initialValues={{ address1, address2, city, state, postCode, phone }}
        />;
      case 3:
        return <StepFour
          step={3}
          onStepBack={onStepBack}
          onSignUp={onSignUp}
          setFormState={setFormState}
          initialValues={{ email, password, confirmPassword }}
          loading={loading}
          error={error}
          errorText={errorText}
          setError={setError}
          minLength={minLength}
        />;
      case 4:
        return <ContactUs onClose={() => history.push(routes.HOME)} />;
      default:
        return <StepOne
          step={0}
          onStepNext={onStepNext}
          initialValues={{ businessName, maxEmployee, country }}
        />;
    }
  };

  return (
    <div>
      <LoginHeader />
      <div className='main-body'>
        {renderStep(step)}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    formState: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFormState: (formState) => dispatch(setFormState(formState)),
    signUp: (authData) => dispatch(signUp(authData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withFirebase(withRouter(SignUpPage)));
