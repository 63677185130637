import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

import { BarTooltip } from './chart-tooltips/BarTooltip';
import { formatChartPrice } from '../../utils/utils';

const CustomLayer = ({ bars, xScale, isCurrency }) => {
  const totals = bars.reduce((acc, bar) => {
    acc[bar.data.indexValue] = (acc[bar.data.indexValue] || 0) + (bar.data.value || 0);
    return acc;
  }, {});

  return (
    <g>
      {Object.keys(totals).map(indexValue => {
        const bar = bars.find(bar => bar.data.indexValue === indexValue);
        const y = bar.y + bar.height / 2;
        const x = xScale(totals[indexValue]);

        return (
          <text
            key={indexValue}
            x={x + 10}  // Adjust position to the right of the stack
            y={y}
            textAnchor="start"
            dominantBaseline="central"
            fill="#000"
            style={{ fontWeight: 'bold', fontSize: 12 }}
          >
            {isCurrency ? `$${formatChartPrice(totals[indexValue], 0)}` : totals[indexValue]}
          </text>
        );
      })}
    </g>
  );
};

const BarChart = ({
  data,
  margin,
  height,
  width,
  padding,
  colors,
  colorBy,
  borderColor,
  enableGridX,
  enableGridY,
  keys,
  enableTotals,
  enableLabel,
  label,
  labelTextColor,
  labelSkipWidth,
  labelSkipHeight,
  legends,
  groupMode,
  indexBy,
  axisTop,
  axisBottom,
  axisLeft,
  axisRight,
  defs,
  fill,
  valueScale,
  valueFormat,
  indexScale,
  animate,
  theme,
  layout,
  CustomTooltip,
  entity = 'case',
  isCurrency,
}) => (
  <div style={{ height, width }}>
    <ResponsiveBar
      animate={animate}
      data={data}
      margin={margin}
      groupMode={groupMode}
      keys={keys}
      indexBy={indexBy}
      padding={padding}
      valueScale={valueScale}
      indexScale={indexScale}
      valueFormat={valueFormat}
      colors={colors}
      colorBy={colorBy}
      enableGridX={enableGridX}
      enableGridY={enableGridY}
      enableLabel={enableLabel}
      label={label}
      defs={defs}
      fill={fill}
      borderColor={borderColor}
      axisTop={axisTop}
      axisRight={axisRight}
      axisBottom={axisBottom}
      axisLeft={{
        tickSize: 0,
        tickPadding: 10,
        format: (v) => Math.floor(v) === v ? v : '',
        ...axisLeft,
      }}
      labelSkipWidth={labelSkipWidth}
      labelSkipHeight={labelSkipHeight}
      labelTextColor={labelTextColor}
      legends={legends}
      tooltip={({ data, indexValue, value, color, id }) => {
        if (CustomTooltip) {
          return (
            <CustomTooltip id={id} data={data} indexValue={indexValue} value={value} color={color} />
          );
        }
        return (
          <BarTooltip id={id} data={data} indexValue={indexValue} value={value} color={color} entity={entity} />
        );
      }}
      theme={theme}
      layout={layout}
      enableTotals={enableTotals}
      layers={enableTotals ? [
        'grid',
        'axes',
        'bars',
        'markers',
        'legends',
        (props) => <CustomLayer {...props} isCurrency={isCurrency} />,
      ] : [
        'grid',
        'axes',
        'bars',
        'markers',
        'legends',
      ]}
    />
  </div>
);

BarChart.defaultProps = {
  animate: true,
  padding: 0.3,
  margin: { top: 50, right: 130, bottom: 50, left: 60 },
  height: '100%',
  width: '100%',
  colors: { scheme: 'nivo' },
  borderColor: { from: 'color', modifiers: [['darker', 1.6]] },
  colorBy: 'indexValue',
  groupMode: 'stacked',
  keys: ['value'],
  indexBy: 'label',
  enableLabel: false,
  enableGridX: false,
  enableGridY: true,
  enableTotals: false,
  label: 'value',
  labelTextColor: { from: 'color', modifiers: [['darker', 1.6]] },
  labelSkipWidth: 12,
  labelSkipHeight: 12,
  axisTop: null,
  axisRight: null,
  valueScale: { type: 'linear' },
  indexScale: { type: 'band', round: true },
  valueFormat: { format: '', enabled: false },
  axisBottom: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendPosition: 'middle',
    legendOffset: 32,
  },
  axisLeft: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendPosition: 'middle',
    legendOffset: -40,
  },
  defs: [
    {
      id: 'dots',
      type: 'patternDots',
      background: 'inherit',
      color: '#38bcb2',
      size: 4,
      padding: 1,
      stagger: true,
    },
    {
      id: 'lines',
      type: 'patternLines',
      background: 'inherit',
      color: '#eed312',
      rotation: -45,
      lineWidth: 6,
      spacing: 10,
    },
  ],
  theme: {
    fontSize: '12px',
    fontFamily: 'Inter'
  },
  fill: [
    { match: { id: '' }, id: 'dots' },
    { match: { id: '' }, id: 'lines' },
  ],
  legends: [],
  layout: 'vertical'
};

export default BarChart;
