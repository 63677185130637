import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Input from '../../shared/Input';
import Button from '../../shared/Button';
import Modal from '../../shared/modal/Modal';
import DatePicker from '../../shared/date-picker/DatePicker';

const ProformaPrintModal = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    activeCase,
    caseId,
    loading,
    onDownloadClick,
    readyToDownload,
  } = props;

  const [reference, setReference] = useState('');
  const [date, setDate] = useState(moment());
  const [notes, setNotes] = useState('');

  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (open) {
      setReference(activeCase?.proformaReference || caseId || '');
      setDate(activeCase?.proformaDate ? activeCase?.proformaDate : moment());
      setNotes(activeCase?.proformaNotes || '');
    }
  }, [open, activeCase, caseId]);

  useEffect(() => {
    if (!open) {
      setIsChanged(false);
    }
  }, [open])

  const handleSubmit = () => {
    onSubmit(reference, date, notes);
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Print PDF"
    >
      <div className='hospital-addresses__modal' style={{ paddingLeft: '24px', paddingRight: '24px', paddingBottom: '24px' }}>
        <div className="form__body">
          <div className="m-t-md m-b-md">
            <div>
              <div className='field__title'>Proforma Date</div>
              <DatePicker
                date={date}
                onDateChange={(date) => {
                  setIsChanged(true);
                  setDate(date);
                }}
              />
            </div>
            <div className='m-t-lg'>
              <div className='field__title'>Reference #</div>
              <Input
                type="text"
                value={reference}
                onChange={(e) => {
                  setIsChanged(true);
                  setReference(e.target.value);
                }}
              />
            </div>
            <div>
              <div className='field__title'>Comments or special instructions</div>
              <Input
                type="textarea"
                value={notes}
                onChange={(e) => {
                  setIsChanged(true);
                  setNotes(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="d-flex m-t-md flex-end">
            {isChanged ? (
              readyToDownload ? (
                <Button
                  type="submit"
                  text="Print PDF"
                  onClick={onDownloadClick}
                />
              ) : (
                <Button
                  loading={loading}
                  disabled={loading || !reference || !date}
                  type="submit"
                  text="Save Changes"
                  onClick={handleSubmit}
                />
              )
            ) : (
              <Button
                type="submit"
                text="Print PDF"
                onClick={onDownloadClick}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProformaPrintModal;
