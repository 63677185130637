import { GET_GROUPS, SET_GROUPS } from './actionTypes';

import firebase, { collections } from '../firebase';

import { setUsers } from './usersActions';

export const setGroups = groups => ({ type: SET_GROUPS, groups });

export const getGroups = () => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.GROUPS_COLLECTION(tenantId);
  
  dispatch({ type: GET_GROUPS });
  
  const snapshot = await firebase.db.collection(path).get();
  const groups = snapshot.docs?.map(doc => ({ id: doc.id, ...doc.data() }));
  
  return dispatch(setGroups(groups));
};

export const createGroup = (groupData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.GROUPS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).add({ ...groupData, active: true, members: [] });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateGroup = (groupId, groupData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.GROUPS_COLLECTION(tenantId);
  
  if (tenantId) {
    await firebase.db.collection(path).doc(groupId).set(groupData, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteGroup = (groupId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.GROUPS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(groupId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const bulkDeleteGroups = (groups) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.GROUPS_COLLECTION(tenantId);
  const batch = firebase.db.batch();

  if (tenantId) {
    groups.forEach((groupId) => {
      const ref = firebase.db.collection(path).doc(groupId);
      batch.delete(ref);
    });

    await batch.commit();
  } else {
    throw new Error('Invalid tenant');
  }
};

// Subscriptions
export const subscribeToGroups = () => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.GROUPS_COLLECTION(tenantId);
  const users = state.users.list;
  
  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let groups = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          groups = [...groups, {
            ...documentSnapshot.data(),
            id,
          }];
        });
        
        dispatch(setGroups(groups));
        return dispatch(setUsers(users));
      },
    });
};
