import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function ExpandIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M13.1572 8.03472C13.1572 8.91881 13.0956 9.58768 13.013 10.2187C12.8248 11.6553 11.6422 12.8115 10.2022 12.9713C9.1622 13.0867 8.09487 13.185 7.00694 13.185C5.91901 13.185 4.85168 13.0867 3.81172 12.9713C2.37169 12.8115 1.21239 11.6521 1.05897 10.2114C0.948866 9.1774 0.856689 8.11627 0.856689 7.03472C0.856689 5.95317 0.948866 4.89204 1.05897 3.85803C1.21239 2.41731 2.36852 1.23487 3.80394 1.0379C4.4408 0.950503 5.115 0.884468 6.00694 0.884468" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M6.68042 7.41144L13.0067 1.08515" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M13.0073 4.38618C13.1382 3.06849 13.1383 2.34475 13.0073 1.0846C11.7472 0.953655 11.0234 0.95373 9.70575 1.0846" />
  </SvgIcon>
}
