import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import DriveFile from '../../drive/DriveFile';
import Modal from '../../shared/modal';

const CaseGuidanceModal = (props) => {
  const {
    open,
    onClose,
    files = [],
    fetching,
    title = 'Case Guidance',
  } = props;

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      fullWidth
    >
      <div className='case-guidance-modal__container'>
        { fetching ? (
          <div className='d-flex flex-center p-b-lg'>
            <CircularProgress />
          </div>
        ) : (
          files && files.length ? (
            files.map((file) => (
              <DriveFile
                key={file.id}
                {...file}
              />
            ))
          ) : (
            <div className='text-center font-size-bg secondary font-bold p-b-lg'>NO FILES</div>
          )
        )}
      </div>
    </Modal>
  );
};

export default CaseGuidanceModal;
