import React from 'react';

import FormIcon from '../../assets/icons/FormIcon';

const FormsList = ({ forms, onClick, tenantColor, selectedForm }) => {
  return (
    <div className='forms-list__container'>
      <div className='form-list__item' onClick={() => onClick(null)}>
        <FormIcon />
        <span>Forms</span>
      </div>
      {forms.map((form) => (
        <div
          key={form.id}
          className={`form-list__item${form.id === selectedForm?.id ? ' selected' : ''}`}
          onClick={() => onClick(form)}
        >
          <FormIcon color={form.id === selectedForm?.id ? tenantColor : '#000000'} />
          <span style={{ color: form.id === selectedForm?.id ? tenantColor : '#000000' }}>{form.name}</span>
        </div>
      ))}
    </div>
  );
};

export default FormsList;
