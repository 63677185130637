import React from 'react';

import KitPreferenceIcon from '../../assets/icons/KitPreferenceIcon';

const KitPreferencesList = ({ kitPreferences, onClick, tenantColor, selectedKitPreference }) => {
  return (
    <div className='flows-list__container'>
      <div className='flow-list__item' onClick={() => onClick(null)}>
        <KitPreferenceIcon />
        <span>Kit Preferences</span>
      </div>
      {kitPreferences.map((kitPreference) => (
        <div
          key={kitPreference.id}
          className={`flow-list__item${kitPreference.id === selectedKitPreference?.id ? ' selected' : ''}`}
          onClick={() => onClick(kitPreference)}
        >
          <KitPreferenceIcon color={kitPreference.id === selectedKitPreference?.id ? tenantColor : '#000000'} />
          <span style={{ color: kitPreference.id === selectedKitPreference?.id ? tenantColor : '#000000' }}>{kitPreference.name}</span>
        </div>
      ))}
    </div>
  );
};

export default KitPreferencesList;
