import React, { useMemo } from 'react';

import WarningIcon from '@material-ui/icons/Error';

import Modal from '../shared/modal';
import Button from '../shared/Button';

import { setAllocationStatuses } from '../../constants/enums';

import '../shared/modal/modal.scss';

const ConfirmUploadGtinsModal = (props) => {
  const { open, onSubmit, onClose, loading, failedItems = [], gtins = [] } = props;

  const duplicateItems = useMemo(() => {
    const result = [];
    failedItems?.forEach((item) => {
      if (gtins?.find((i) => item && item[0] === i.gtin)) {
        result?.push(item);
      }
    });
    return result;
  }, [gtins, failedItems]);

  return (
    <Modal open={open} onClose={onClose} size='lg'>
      <div className='confirmation-modal__container'>
        <div>
          <div className='modal-title'>
            Some GTINs have issues while uploading
          </div>

          {!!duplicateItems.length && (
            <>
              <div className='modal-content' style={{ marginBottom: 16 }}>
                <div className='d-flex'>
                  <WarningIcon style={{ color: setAllocationStatuses.ASSIGNED.color, marginRight: 4 }} />
                  <div style={{ color: setAllocationStatuses.ASSIGNED.color, fontWeight: 'bold' }}>
                    You are attempting to add GTINs that already exist
                  </div>
                </div>
                <div>Do you want to proceed with the upload of only non-duplicates or cancel the upload?</div>
              </div>

              <div className='m-t-lg m-b-lg'>
                <div className='font-bold'>Duplicate GTINs</div>
                <div className='m-t-md'>
                  {duplicateItems?.map((item) => (
                    <div key={Math.random()} className='m-t-sm'>
                      {`${item[0]}${item[1] ? ` (${item[1]})` : ''}`}
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

          <div className='form__actions'>
            <Button
              type='submit'
              text='Upload'
              onClick={onSubmit}
              disabled={loading}
              loading={loading}
            />
            <Button
              type='cancel'
              text='Cancel'
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmUploadGtinsModal;
