import { SET_FORM_STATE, RESET_FORM } from '../actions/actionTypes';

const initialState = {
  businessName: '',
  maxEmployee: '',
  country: '',
  firstName: '',
  lastName: '',
  businessEmail: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  postCode: '',
  phone: '',
  email: '',
  password: '',
  confirmPassword: ''
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FORM_STATE:
      return { ...state, ...action.formState };
    case RESET_FORM:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
