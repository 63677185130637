import React, { useState } from 'react';

import Modal from '../../shared/modal';
import Select from '../../shared/Select';

const AddFormModal = ({ open, onClose, onSubmit, loading, forms = [] }) => {
  const [selected, setSelected] = useState(null);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Form'
      fullWidth
      closeButton='Cancel'
      submitButton='Add'
      onSubmit={() => onSubmit(selected)}
      disabled={loading || !selected}
      loading={loading}
    >
      <div className='close-order-modal__container'>
        <div className='p-b-md secondary'>
          Select form
        </div>

        <Select
          value={selected}
          onChange={setSelected}
          options={forms}
        />
      </div>
    </Modal>
  );
};

export default AddFormModal;
