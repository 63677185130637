import React from 'react';

import Table from '../shared/table/Table';

const columns = [
  { title: 'name', field: 'name', type: 'default', sort: true },
  { title: 'description', field: 'description', type: 'default', sort: true },
  { title: 'color', field: 'color', type: 'custom', formatter: (value) => <div style={{ height: 20, width: 20, background: value, borderRadius: '4px' }} /> },
  { title: 'status', field: 'active', type: 'status', sort: false },
];

const TagsTable = (props) => {
  const {
    onSelectTag,
    tags,
    selectedTagId,
    handleCheck,
    checkedTags,
    handleCheckAll,
  } = props;

  return (
    <div style={{ width: '100%' }}>
      <Table
        rows={tags}
        columns={columns}
        onRowClick={onSelectTag}
        selected={selectedTagId}
        handleCheck={handleCheck}
        checkedRows={checkedTags}
        handleCheckAll={handleCheckAll}
        pagination
        noCheckAll
      />
    </div>
  );
};

export default TagsTable;
