import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, Form } from 'react-final-form';

import TermsAndPolicy from './Terms&Conditions';

import Button from '../shared/Button';
import Input from '../shared/Input';

import { forgotPassword } from '../../actions/authActions';

import { useAlert, useLoading } from "../../hooks";

import routes from '../../constants/routes';

const ResetForm = () => {
  const dispatch = useDispatch();

  const { loading, startLoading, stopLoading } = useLoading();
  const { showAlert } = useAlert();

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onForgotPassword = async (email) => {
    try {
      startLoading();

      await dispatch(forgotPassword(email));

      showAlert('success', `A password reset link was sent to ${email}`);
    } catch (err) {
      showAlert('error', 'No matching email found');
    } finally {
      stopLoading();
    }
  };

  return (
    <Form onSubmit={(formObj) => onForgotPassword(formObj.email)}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className='login-form'>
          <div className='logo' />
          <span className='login-text'>Reset password</span>
          <span className='login-subtext'>Provide your email and we'll send you a link to reset your password</span>

          <Field name='email'>
            {props => (
              <Input
                name={props.input.name}
                value={props.input.value}
                placeholder='Email'
                type='text'
                onChange={props.input.onChange}
                handleMouseDownPassword={handleMouseDownPassword}
              />
            )}
          </Field>

          <div className='btn-group'>
            <Link to={routes.SIGN_IN}>
              <div className='flx-cntr'>
                <span className='forgot-pass-text'>Go back to login page?</span>
              </div>
            </Link>
            <Button
              type='submit'
              text='Reset'
              onClick={handleSubmit}
              disabled={loading}
              loading={loading}
            />
          </div>

          <div className='text-group'>
            <TermsAndPolicy />
          </div>
        </form>
      )}
    </Form>
  );
};

export default ResetForm;
