import React from 'react';

import DeleteIcon from '../../assets/icons/DeleteIcon';
import FileCopyIcon from '../../assets/icons/CopyIcon';
import ItemIcon from '../../assets/icons/ItemIcon';
import KitIcon from '../../assets/icons/KitIcon';
import TitleIcon from '../../assets/icons/TitleIcon';

import Button from '../shared/Button';

import { kitPreferenceItemTypes } from '../../constants/enums';
import DropdownButton from '../shared/DropdownButton';
import ExpandIcon from '@material-ui/icons/ExpandMore';

const dropdownOptions = [
  { id: kitPreferenceItemTypes.ITEM.value, label: 'Add Items', icon: <ItemIcon /> },
  { id: kitPreferenceItemTypes.KIT.value, label: 'Add Kits', icon: <KitIcon /> },
  { id: kitPreferenceItemTypes.TITLE.value, label: 'Add Title', icon: <TitleIcon /> },
];

const KitPreferencesActions = ({ onAddClick, onDeleteClick, selectedKitPreference, deletePermission }) => {
  return (
    <div className='settings-cmp__filters--main'>
      <div className='settings-cmp__actions'>
        {selectedKitPreference ? (
          <DropdownButton
            onClick={(option) => onAddClick(option.id)}
            options={dropdownOptions}
          >
            <div className='d-flex m-l-lg'>
              Add
              <ExpandIcon />
            </div>
          </DropdownButton>
        ) : (
          <Button
            text={selectedKitPreference ? 'Add Step' : 'Add Preference'}
            type="submit"
            onClick={onAddClick}
          />
        )}

        <Button
          type='icon'
          onClick={onDeleteClick}
          disabled={!deletePermission}
        >
          <DeleteIcon />
        </Button>

        <Button
          type='icon'
        >
          <FileCopyIcon />
        </Button>
      </div>
    </div>
  );
};

export default KitPreferencesActions;
