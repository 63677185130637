import React, { useState, useMemo } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import WarningIcon from '@material-ui/icons/Error';
import { Tooltip } from '@material-ui/core';

import Modal from '../../shared/modal';
import DatePicker from '../../shared/date-picker/DatePicker';
import Checkbox from '../../shared/Checkbox';
import Button from '../../shared/Button';

import { setAllocationStatuses, kitVariantTypes, setAllocationTypes } from '../../../constants/enums';

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: '12px',
    padding: '4px 8px',
    borderRadius: 8,
  },
}));

const ReturnSubmitModal = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    data = [],
    loading,
    checklists = [],
    tenantColor,
    orderClosed,
    kitVariant,
    selected,
    batchItems = {},
    itemsList = [],
  } = props;

  const classes = useStyles();

  const [returnDate, setReturnDate] = useState(moment());
  const [addToUsage, setAddToUsage] = useState(true);

  const list = useMemo(() => {
    const result = [];

    data?.forEach((item) => {
      const sets = data?.filter((i) => i.allocationId === item.allocationId && item.allocationType === setAllocationTypes.SET);
      if (sets && sets.length && !result?.find((i) => i.value === item.allocationId)) {
        result?.push({
          value: item.allocationId,
          label: sets?.map((i) => i.setNumber)?.join(', '),
        });
      }

      if (item.allocationType === setAllocationTypes.ITEM) {
        result?.push({
          value: item.id,
          label: item.description || '',
        });
      }
    });

    return result;
  }, [data]);

  const usageItems = useMemo(() => {
    const result = [];

    const usageItem = data?.find((item) => item.allocationType === setAllocationTypes.ITEM && selected?.id === item.id);
    const usageConsumables = usageItem?.consumables?.filter((item) => item.status === setAllocationStatuses.USAGE.value);

    if (usageConsumables?.length) {
      usageConsumables?.forEach((item) => {
        result?.push({
          code: usageItem.code || '',
          lot: item.label,
          description: usageItem.description || '',
          quantity: 1,
        });
      });
    }

    return result || [];
  }, [list, selected]);

  const changedValues = useMemo(() => {
    const result = [];

    checklists?.forEach((checklist) => {
      const allocation = data?.find((i) => i.id === checklist.id);
      if (allocation && (allocation.allocationStatus === setAllocationStatuses.SHIPPED.value || allocation.allocationStatus === setAllocationStatuses.CONFIRMED.value) && allocation.allocationId === selected?.allocationId) {
        checklist?.sections?.forEach((section) => {
          section?.items?.forEach((item) => {
            const _item = itemsList?.find((i) => i.id === item.id);

            if (item && !_item?.batchControl && ((Number(item.inbound) === 0 || !!Number(item.inbound)) && Number(item.inbound) < Number(item.outbound))) {
              result?.push({
                itemId: item.id,
                code: item.code,
                lot: '',
                description: item.description,
                quantity: item.outbound - item.inbound,
                outbound: item.outbound,
                inbound: item.inbound,
              });
            }

            if (_item?.batchControl && item?.batchInbound?.length) {
              item?.batchInbound?.forEach((itemId) => {
                const values = batchItems[item.id];
                if (values) {
                  const batchItem = values?.find((i) => i.id === itemId);
                  if (batchItem) {
                    result?.push({
                      batchControl: true,
                      itemId: _item.id,
                      batchId: batchItem.id || '',
                      code: item.code,
                      lot: batchItem.code || '',
                      description: item.description,
                      exp: batchItem.expDate ? moment(batchItem.expDate).format('yyyy-MM-DD') : '',
                      quantity: 1,
                      outbound: 1,
                      inbound: 1,
                    });
                  }
                }
              })
            }
          });
        });
      }
    });

    return result || [];
  }, [data, checklists, selected, itemsList]);

  const usageList = useMemo(() => [...usageItems, ...changedValues], [usageItems, changedValues]);

  const handleSubmit = async (isReplenish = false) => {
    await onSubmit(selected, returnDate, !orderClosed ? addToUsage : false, changedValues, isReplenish);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      size="md"
      title={kitVariant === kitVariantTypes.consignment ? 'Add Set Usage' : 'Return Sets & Items'}
      fullWidth
      loading={loading}
      disabled={loading || !selected}
    >
      <div className="submit-modal__container">
        <div className='m-b-md d-flex space-between'>
          <div>
            <div style={{ color: tenantColor, fontWeight: 600 }}>{selected?.kitName || selected?.code}</div>
            <div className='m-t-md font-bold font-size-bg'>{selected?.setNumber || selected?.description}</div>
          </div>
          <div>
            <div className='field__title'>{kitVariant === kitVariantTypes.consignment ? 'Date' : 'Return Date'}</div>
            <div style={{ width: 300 }}>
              <DatePicker
                date={returnDate}
                onDateChange={setReturnDate}
              />
            </div>
          </div>
        </div>

        <div className="secondary font-bold m-b-md">
          Calculated Usage
        </div>

        <div className="m-b-md form__select-users">
          <table>
            <thead>
            <tr style={{ borderBottom: '1px solid #eeeeee' }}>
              <th className="uppercase secondary p-b-sm">Item code</th>
              <th className="uppercase secondary p-b-sm">Description</th>
              <th className="uppercase secondary p-b-sm">Lot/Serial</th>
              <th className="uppercase secondary text-center p-b-sm">QTY</th>
            </tr>
            </thead>
            {!!usageList?.length ? (
              <tbody>
              {usageList.map((item) => (
                <tr className="" key={item.code}>
                  <td className="font-bold p-t-sm">
                    {item.code}
                  </td>
                  <td className="font-bold p-t-sm">
                    {item?.description?.length > 50 ? (
                      <Tooltip
                        title={item?.description}
                        aria-label="item-description"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <div
                          style={{
                            width: 500,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {item.description}
                        </div>
                      </Tooltip>
                    ) : item.description}
                  </td>
                  <td className="font-bold p-t-sm">
                    {item.lot}
                  </td>
                  <td className="font-bold uppercase text-center p-t-sm" style={{ color: tenantColor }}>
                    {item.quantity}
                  </td>
                </tr>
              ))}
              </tbody>
            ) : (
              <tbody>
              <tr>
                <td colSpan={4}>
                  <div className="secondary text-center p-t-md">No calculated usage</div>
                </td>
              </tr>
              </tbody>
            )}
          </table>
        </div>

        {orderClosed ? (
          <div className="d-flex align-start">
            <WarningIcon style={{ color: setAllocationStatuses.TRANSFERRED.color, marginRight: 4 }}/>
            <div>
              <div className="font-bold" style={{ color: setAllocationStatuses.TRANSFERRED.color }}>
                Usage for this case has already been closed!
              </div>
              <div className="secondary font-bold">
                Please note that none of the calculated usage will be added on return
              </div>
            </div>
          </div>
        ) : (
          <Checkbox
            input={{
              checked: addToUsage,
              onClick: () => setAddToUsage(!addToUsage),
            }}
            disabled={!!orderClosed}
            fontSize="small"
            label="When checked Items above will automatically be added to usage"
          />
        )}
      </div>

      <div className='d-flex space-between' style={{ padding: '24px' }}>
        <Button type='cancel' text='Close' onClick={onClose} />
        <div className='d-flex'>
          {selected?.allocationType === setAllocationTypes.SET && (
            <div className='m-r-lg'>
              <Button
                text="Replenish"
                onClick={() => handleSubmit(true)}
                disabled={loading || !selected}
                loading={loading}
                type='outlined'
              />
            </div>
          )}
          <Button
            text={kitVariant === kitVariantTypes.consignment ? 'Add Usage' : 'Return'}
            onClick={() => handleSubmit(false)}
            disabled={loading || !selected}
            loading={loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ReturnSubmitModal;
