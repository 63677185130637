import React from 'react';

import { ButtonGroup, Button } from '@material-ui/core';

import { setAllocationStatuses } from '../../../constants/enums';

const UsageItemRow = (props) => {
  const { consumable, description, allocationStatus, onChange, imageUrl } = props;

  const onImageClick = (e, imageUrl) => {
    if (imageUrl) {
      window.open(imageUrl, '_blank');
    }

    e.stopPropagation();
  };

  return (
    <tr>
      <td>{consumable.label}</td>
      <td>{description}</td>
      <td>Consumable</td>
      <td>
        {imageUrl && (
          <div className='item-image-preview' onClick={(e) => onImageClick(e, imageUrl)}>
            <img src={imageUrl} alt='preview' />
          </div>
        )}
      </td>
      <td>
        {allocationStatus === setAllocationStatuses.SHIPPED.value ? (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonGroup color='primary' aria-label='outlined primary button group'>
              <Button
                variant={consumable.status === setAllocationStatuses.RETURNED.value ? 'contained' : ''}
                onClick={() => onChange(setAllocationStatuses.RETURNED.value)}
              >
                Return
              </Button>
              <Button
                variant={consumable.status === setAllocationStatuses.USAGE.value ? 'contained' : ''}
                onClick={() => onChange(setAllocationStatuses.USAGE.value)}
              >
                Usage
              </Button>
            </ButtonGroup>
          </div>
        ) : (
          <div style={{ color: setAllocationStatuses[consumable?.status].color, textAlign: 'right' }}>
            {setAllocationStatuses[consumable?.status]?.label || ''}
          </div>
        )}
      </td>
    </tr>
  );
};

export default UsageItemRow;
