import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function SurgeonIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none"
          d="M1.08005 9.82277C1.15336 10.7816 1.93488 11.5247 2.89522 11.5745C5.68157 11.7189 8.31849 11.7189 11.1048 11.5745C12.0652 11.5247 12.8467 10.7816 12.92 9.82278C13.0658 7.91611 13.0658 6.08387 12.92 4.1772C12.8467 3.21836 12.0652 2.47526 11.1048 2.42548C8.31849 2.28103 5.68157 2.28103 2.89522 2.42548C1.93487 2.47526 1.15336 3.21836 1.08005 4.1772C0.934256 6.08386 0.934256 7.91611 1.08005 9.82277Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M7.00655 9.13088C7.00655 8.29423 6.29188 7.65967 5.18479 7.65967C4.0777 7.65967 3.36304 8.29422 3.36304 9.13088" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M5.18401 7.65964C5.98394 7.65964 6.63242 7.01117 6.63242 6.21123C6.63242 5.41129 5.98394 4.76282 5.18401 4.76282C4.38407 4.76282 3.7356 5.41129 3.7356 6.21123C3.7356 7.01117 4.38407 7.65964 5.18401 7.65964Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M9.13672 5.74219H10.6484" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M9.13672 8.25781H10.6484" />
  </SvgIcon>
}
