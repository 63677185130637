/* eslint-disable no-throw-literal */
import React from 'react';
import { useDispatch } from 'react-redux';

import CompanyIcon from '../../../assets/icons/CompanyIcon';

import Button from '../../../components/shared/Button';

import { generateReport } from '../../../actions/tenantActions';

import { withFirebase } from '../../../firebase';

import { useLoading } from '../../../hooks';

import './admin-page.scss';

const AdminPage = () => {
  const dispatch = useDispatch();
  const { loading, startLoading, stopLoading } = useLoading();

  const onGenerateReportClick = async () => {
    try {
      startLoading();
      await dispatch(generateReport());
    } finally {
      stopLoading();
    }
  };

  return (
    <div className='admin-page__container'>
      <div className='d-flex'>
        <span className='admin-page__title'>Admin</span>
      </div>

      <div className='form__container m-r-lg'>
        <div className='d-flex m-b-lg'>
          <CompanyIcon />
          <div className='font-size-bg font-bold m-l-md'>Manage Companies</div>
        </div>
        <div className='m-t-lg'>
          <Button onClick={onGenerateReportClick} loading={loading} disabled={loading}>
            Global User Report
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withFirebase(AdminPage);
