import { useContext } from 'react';

import { AlertContext } from '../providers/AlertProvider';

const useAlert = () => {
  const { isOpen, variant, message, showAlert, closeAlert } = useContext(AlertContext);
  return { isOpen, variant, message, showAlert, closeAlert };
}

export default useAlert;
