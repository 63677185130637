import React, { useMemo } from 'react';

import Button from '../../shared/Button';
import Company from '../../profile/Company';

const Step0 = (props) => {
  const {
    currentTenant,
    tenants,
    connectedTenants = [],
    onClose,
    onClickNext,
    loading,
    selected,
    setSelected,
  } = props;

  const connected = useMemo(() => (
    connectedTenants?.map((tenantId) => {
      const tenant = tenants?.find((item) => item.id === tenantId);
      return tenant || {};
    })
  ), [tenants, connectedTenants]);

  return (
    <div>
      <div className='font-bold m-b-lg'>
        Select which company you want to make this booking with:
      </div>

      <div>
        <div className='secondary m-b-md'>My Company</div>
        <Company
          title={currentTenant?.businessName}
          logo={currentTenant?.icon?.downloadUrl || null}
          color={currentTenant?.colorPrimary}
          selected={currentTenant?.id === selected}
          onClick={() => setSelected(currentTenant?.id)}
          selectable
        />
      </div>

      {!!connected?.length && (
        <div className='m-t-lg m-b-lg'>
          <div className='secondary m-b-md'>Connected</div>
          {connected?.map((tenant) => (
            <div key={tenant.id} className='m-b-md'>
              <Company
                title={tenant?.businessName}
                logo={tenant?.icon?.downloadUrl || null}
                color={tenant?.colorPrimary}
                selected={tenant?.id === selected}
                onClick={() => setSelected(tenant?.id)}
                selectable
              />
            </div>
          ))}
        </div>
      )}

      <div className='form__actions m-t-lg'>
        <Button
          type='submit'
          text='Next'
          onClick={onClickNext}
          disabled={loading}
          loading={loading}
        />
        <Button
          type='cancel'
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default Step0;
