import React from 'react';
import { CSVReader } from 'react-papaparse'

import CloudUploadIcon from '../../assets/icons/CloudUploadIcon';

import { defaultStaticColor } from '../../styles/theme/palette';

const CSVUpload = ({ onDrop, onError, onRemoveFile, tenantColor, text }) => {
  return (
    <CSVReader
      accept='text/csv, .csv'
      onDrop={onDrop}
      onError={onError}
      onRemoveFile={onRemoveFile}
      addRemoveButton
      style={{
        dropArea: {
          marginBottom: '16px',
          padding: '32px',
          background: '#ffffff',
        },
        dropAreaActive: {
        },
        dropFile: {
          height: '100px',
          width: 'auto',
          minWidth: '100px',
          borderRadius: '16px',
          background: '#ffffff',
          border: '2px solid #eeeeee',
        },
        fileSizeInfo: {
          display: 'none',
        },
        fileNameInfo: {
        },
        removeButton: {
          color: tenantColor || defaultStaticColor,
        },
        progressBar: {
          backgroundColor: tenantColor || defaultStaticColor,
        },
      }}
    >
      <div className='d-flex direction-column align-center flex-center'>
        <CloudUploadIcon color='secondary' fontSize="large" />
        <div className='secondary m-t-md text-center'>
          {text || 'Click or drag and drop .csv file to upload'}
        </div>
      </div>
    </CSVReader>
  );
};

export default CSVUpload;
