import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function ConsignedSetIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <circle cx={7} cy={7} r={5.992} stroke={color || defaultColor} fill="none" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M4.8252 5.56018H9.17425" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M4.8252 8.43982H9.17425" />
  </SvgIcon>
}
