import React, { useMemo } from 'react';
import {
  Document,
  Page,
  View,
  Text,
  Font,
  StyleSheet,
  Image
} from '@react-pdf/renderer';
import moment from 'moment/moment';

import InterRegular from '../../../assets/fonts/Inter-Regular.ttf';
import InterBold from '../../../assets/fonts/Inter-Bold.ttf';

const styles = StyleSheet.create({
  container: {
    padding: 36,
    fontFamily: 'Inter',
    fontSize: 10,
    fontWeight: 'normal',
    color: '#202124',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  titleBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  bold: {
    fontWeight: 'bold',
    fontFamily: 'Inter',
  },
  logo: {
    width: 150,
    height: 'auto',
  },
  title: {
    fontSize: 18,
    marginBottom: 4,
    fontWeight: 'bold',
    fontFamily: 'Inter'
  },
  row: {
    marginTop: 2,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  table: {
    marginTop: 32,
    paddingBottom: 8,
    borderBottom: '1px solid #000000',
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    fontFamily: 'Inter',
    paddingTop: 4,
    paddingBottom: 4,
    borderTop: '1px solid #000000',
    borderBottom: '1px solid #000000',
    fontSize: 9
  },
  tableRow: {
    marginTop: 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tableCell: {
    padding: 3,
    fontSize: 8,
    fontFamily: 'Inter'
  },
  contactContainer: {
    marginTop: 32,
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 16,
    borderTop: '1px solid #CCCCCC',
  },
  contactRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 2
  }
});

Font.register({
  family: 'Inter',
  fonts: [
    {
      src: InterRegular,
    },
    {
      src: InterBold,
      fontWeight: 'bold',
    },
  ],
});

const getAmount = (item) => {
  const quantity = item.quantity || 1;
  if (!Number(item.value)) {
    return 'N/A';
  }
  let result = 0;
  const value = quantity * Number(item.value);
  if (item.discount) {
    result = value - (value * item.discount / 100);
  }
  result = quantity * Number(item.value);
  return Number(result).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

const ProformaPDF = ({ activeCase, tenant, proforma, hospital, surgeon, billingAddress, deliveryAddress, formattedId }) => {
  const defaultSalesTax = tenant?.financialDetails?.defaultSalesTax || 0;
  const showPaymentDetails = tenant?.financialDetails?.showPaymentDetails || false;
  const billingAccountName = tenant?.financialDetails?.billingAccountName || tenant?.businessName || '';

  const priceWithoutTax = useMemo(() => (
    proforma
      ?.filter((item) => !!item.value)?.map((item) => {
      const quantity = item.quantity || 1;
      const value = quantity * Number(item.value);
      if (item.discount) {
        return value - (value * item.discount / 100);
      }
      return quantity * Number(item.value);
    })
      ?.reduce((a, b) => a + b, 0)
  ), [proforma]);

  const totalTax = useMemo(() => {
    if (!defaultSalesTax) {
      return 0;
    }
    return priceWithoutTax * defaultSalesTax / 100;
  }, [priceWithoutTax, defaultSalesTax]);

  return (
    <Document>
      <Page size="A4" style={styles.container}>
        <View>
          <View style={styles.titleBlock}>
            <View>
              <Image
                src={tenant?.documentLogo?.downloadUrl}
                style={styles.logo}
              />
              <Text style={{ marginTop: 2 }}>{billingAccountName}</Text>
              <Text style={{ marginTop: 2 }}>{`${tenant?.address1}, ${tenant?.address2}`}</Text>
              <Text style={{ marginTop: 2 }}>{`${tenant?.city}, ${tenant?.state}, ${tenant?.postCode}`}</Text>
              <Text style={{ marginTop: 2 }}>{tenant?.businessNumber}</Text>
            </View>
            <View>
              <Text style={styles.title}>
                PROFORMA INVOICE
              </Text>
              <View style={styles.row}>
                <Text>Date: </Text><Text style={styles.bold}>
                  {activeCase?.proformaDate ? moment(activeCase?.proformaDate).format('Do MMMM YYYY') : moment().format('Do MMMM YYYY')}
                </Text>
              </View>
              <View style={styles.row}>
                <Text>Reference #: </Text><Text style={styles.bold}>{activeCase?.proformaReference || formattedId}</Text>
              </View>
              {hospital?.accountNumber && (
                <View style={styles.row}>
                  <Text>Customer #: </Text><Text style={styles.bold}>{hospital?.accountNumber}</Text>
                </View>
              )}
              <View style={styles.row}>
                <Text>PO Due: </Text><Text style={styles.bold}>
                {moment().add(tenant?.financialDetails?.defaultPaymentTerms || 0, 'days').format('Do MMMM YYYY')}
              </Text>
              </View>
            </View>
          </View>

          <View style={{ marginTop: 32, display: 'flex', flexDirection: 'row' }}>
            <View style={{ flexGrow: 1 }}>
              <Text style={styles.bold}>Billing To:</Text>
              <Text style={{ marginTop: 4 }}>{hospital?.name}</Text>
              {billingAddress?.label && <Text style={{ marginTop: 2 }}>{billingAddress?.label}</Text>}
              {(billingAddress?.apartment || billingAddress?.buildingName) && (
                <Text style={{ marginTop: 2 }}>
                  {`${billingAddress?.apartment || ''}${billingAddress?.apartment && billingAddress?.buildingName ? ' | ' : ''}${billingAddress?.buildingName || ''}`}
                </Text>
              )}
              <Text style={{ marginTop: 2 }}>{billingAddress?.street}</Text>
              <Text style={{ marginTop: 2 }}>{`${billingAddress?.city || ''}, ${billingAddress?.state || ''}, ${billingAddress?.postCode || ''}`}</Text>
              {(billingAddress?.neighborhood || billingAddress?.department) && (
                <Text style={{ marginTop: 2 }}>
                  {`${billingAddress?.neighborhood || ''}${billingAddress?.neighborhood && billingAddress?.department ? ' | ' : ''}${billingAddress?.department || ''}`}
                </Text>
              )}
              {billingAddress?.attn && <Text style={{ marginTop: 2 }}>{`Att: ${billingAddress?.attn}`}</Text>}
            </View>
            <View style={{ flexGrow: 1 }}>
              <Text style={styles.bold}>Shipping To:</Text>
              {deliveryAddress?.label && <Text style={{ marginTop: 4 }}>{deliveryAddress?.label}</Text>}
              {(deliveryAddress?.apartment || deliveryAddress?.buildingName) && (
                <Text style={{ marginTop: 2 }}>
                  {`${deliveryAddress?.apartment || ''}${deliveryAddress?.apartment && deliveryAddress?.buildingName ? ' | ' : ''}${deliveryAddress?.buildingName || ''}`}
                </Text>
              )}
              <Text style={{ marginTop: 2 }}>{deliveryAddress?.street}</Text>
              <Text style={{ marginTop: 2 }}>{`${deliveryAddress.city || ''}, ${deliveryAddress.state || ''}, ${deliveryAddress.postCode || ''}`}</Text>
              {(deliveryAddress?.neighborhood || deliveryAddress?.department) && (
                <Text style={{ marginTop: 2 }}>
                  {`${deliveryAddress?.neighborhood || ''}${deliveryAddress?.neighborhood && deliveryAddress?.department ? ' | ' : ''}${deliveryAddress?.department || ''}`}
                </Text>
              )}
              {deliveryAddress?.attn && <Text style={{ marginTop: 2 }}>{`Att: ${deliveryAddress.attn}`}</Text>}
            </View>
          </View>

          <View style={{ marginTop: 32, display: 'flex', flexDirection: 'row' }}>
            <View style={{ flexGrow: 1 }}>
              <Text style={styles.bold}>Surgery Date</Text>
              <Text style={{ marginTop: 4 }}>{moment(activeCase.date).local().format('Do MMMM YYYY')}</Text>
            </View>
            <View style={{ flexGrow: 1 }}>
              <Text style={styles.bold}>Surgeon</Text>
              <Text style={{ marginTop: 4 }}>{`${surgeon?.title} ${surgeon?.firstName} ${surgeon?.lastName}`}</Text>
            </View>
            <View style={{ flexGrow: 1 }}>
              <Text style={styles.bold}>Patient ID</Text>
              <Text style={{ marginTop: 4 }}>{activeCase.patientReference}</Text>
            </View>
            <View style={{ flexGrow: 1 }}>
              <Text style={styles.bold}>Account No.</Text>
              <Text style={{ marginTop: 4 }}>{activeCase?.proformaReference || formattedId}</Text>
            </View>
          </View>

          <View style={styles.table}>
            <View style={styles.tableHeader}>
              <View style={{ ...styles.tableCell, width: '15%' }}><Text>ITEM CODE</Text></View>
              <View style={{ ...styles.tableCell, width: '46%' }}><Text>DESCRIPTION</Text></View>
              <View style={{ ...styles.tableCell, width: '13%' }}><Text>REBATE CODE</Text></View>
              <View style={{ ...styles.tableCell, width: '11%' }}><Text style={{ textAlign: 'right' }}>UNIT PRICE</Text></View>
              <View style={{ ...styles.tableCell, width: '5%' }}><Text style={{ textAlign: 'center' }}>QTY</Text></View>
              <View style={{ ...styles.tableCell, width: '10%' }}><Text style={{ textAlign: 'right' }}>AMOUNT</Text></View>
            </View>

            {proforma?.map((item) => (
              <View key={item.code} style={styles.tableRow}>
                <View style={{ ...styles.tableCell, width: '15%' }}><Text>{item.code}</Text></View>
                <View style={{ ...styles.tableCell, width: '46%' }}><Text>{item.description}</Text></View>
                <View style={{ ...styles.tableCell, width: '13%' }}><Text>{item.rebateCode}</Text></View>
                <View style={{ ...styles.tableCell, width: '11%' }}>
                  <Text style={{ textAlign: 'right' }}>
                    {item.value ? Number(item.value).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}
                  </Text>
                </View>
                <View style={{ ...styles.tableCell, width: '5%' }}><Text style={{ textAlign: 'center' }}>{item.quantity}</Text></View>
                <View style={{ ...styles.tableCell, width: '10%' }}>
                  <Text style={{ textAlign: 'right' }}>{getAmount(item)}</Text>
                </View>
              </View>
            ))}
          </View>

          <View style={{ marginTop: 32, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={{ width: '50%' }}>
              <Text style={styles.bold}>Comments or Special Instructions:</Text>
              <Text style={{ marginTop: 4, fontSize: 8 }}>
                {activeCase?.proformaNotes || ''}
              </Text>
            </View>

            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flexGrow: 1 }}>
              <View style={{ marginRight: 16 }}>
                <Text style={{ marginBottom: 4, textAlign: 'right' }}>Subtotal</Text>
                <Text style={{ marginBottom: 4, textAlign: 'right' }}>Sales Tax</Text>
                <Text style={{ ...styles.bold, textAlign: 'right', fontSize: 12 }}>{`Total (${tenant?.financialDetails?.currency || 'USD'})`}</Text>
              </View>
              <View style={{ marginLeft: 16 }}>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                  <Text style={{ marginBottom: 4, textAlign: 'right' }}>
                    {Number(priceWithoutTax).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                  <Text style={{ marginBottom: 4, textAlign: 'right' }}>
                    {Number(totalTax).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                  <Text style={{ ...styles.bold, textAlign: 'right', fontSize: 12 }}>
                    {Number(priceWithoutTax + totalTax).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.contactContainer}>
          {showPaymentDetails ? (
            <View style={{ flex: 1 }}>
              <Text style={{ ...styles.bold, marginBottom: 4 }}>Payment Details:</Text>
              <View style={styles.contactRow}>
                <Text>Company: </Text><Text style={styles.bold}>{tenant?.financialDetails?.billingAccountName}</Text>
              </View>
              <View style={styles.contactRow}>
                <Text>Bank Name: </Text><Text style={styles.bold}>{tenant?.financialDetails?.bankName}</Text>
              </View>
              <View style={styles.contactRow}>
                <Text>Branch Details: </Text><Text style={styles.bold}>{tenant?.financialDetails?.bankName}</Text>
              </View>
              <View style={styles.contactRow}>
                <Text>Routing Code: </Text><Text style={styles.bold}>{tenant?.financialDetails?.routingCode}</Text>
              </View>
              <View style={styles.contactRow}>
                <Text>Account Number: </Text><Text style={styles.bold}>{tenant?.financialDetails?.accountNumber}</Text>
              </View>
            </View>
          ) : <View />}
          <View style={{ flex: 1 }}>
            <Text style={{ ...styles.bold, marginBottom: 4 }}>Contact Details:</Text>
            <View style={styles.contactRow}>
              <Text>Terms: </Text><Text style={styles.bold}>
              {tenant?.financialDetails?.defaultPaymentTerms ? `${tenant?.financialDetails?.defaultPaymentTerms} Day Terms` : ''}
            </Text>
            </View>
            <View style={styles.contactRow}>
              <Text>Phone: </Text><Text style={styles.bold}>{tenant?.financialDetails?.proformaContact}</Text>
            </View>
            <View style={styles.contactRow}>
              <Text>Email: </Text><Text style={styles.bold}>{tenant?.financialDetails?.proformaEmail}</Text>
            </View>
          </View>
        </View>

        <Text
          render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
          fixed
          style={{ position: 'absolute', bottom: 16, right: 16, fontWeight: 'bold' }}
        />
      </Page>
    </Document>
  );
};

export default ProformaPDF;