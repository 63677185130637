import React, { useMemo } from 'react';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FolderIcon from '../../assets/icons/FolderIcon';
import KitIcon from '../../assets/icons/KitIcon';

import { utils } from '../../utils';

import './drive.scss';

const DriveBreadcrumbs = ({ onClick, colorPrimary, active, data }) => {
  const breadcrumbs = useMemo(() => utils.buildParentArray(data, active), [data, active]);

  return (
    <div className='drive-breadcrumbs__container'>
      {active === 'kits' ? (
        <div className='d-flex'>
          <KitIcon className='m-r-md' style={{ fontSize: '24px' }} />
          <span className='font-bold'>Kits</span>
        </div>
      ) : (
        <>
          <FolderIcon className='m-r-md' style={{ fontSize: '24px' }} />
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize='small' />}
            aria-label='breadcrumb'
            maxItems={4}
          >
            { breadcrumbs.map((item, index) => (
              <div
                key={item.id}
                className='drive-breadcrumbs__item'
                style={{ color: index === breadcrumbs.length - 1 ? colorPrimary : '#0b0b0b' }}
                onClick={() => onClick(item.id)}
              >
                {item.name}
              </div>
            ))}
          </Breadcrumbs>
        </>
      )}
    </div>
  );
};

export default DriveBreadcrumbs;
