import { GET_REPEATING_COUNTS, SET_REPEATING_COUNTS } from '../actions/actionTypes';

const initialState = {
  list: [],
  isLoading: false,
  isLoaded: false
};

const repeatingCountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPEATING_COUNTS:
      return { ...state, isLoading: true };
    case SET_REPEATING_COUNTS:
      return {
        ...state,
        list: action.counts,
        isLoaded: true,
        isLoading: false
      };
    default:
      return state;
  }
};

export default repeatingCountsReducer;
