import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function ProfileIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M7 7C8.72589 7 10.125 5.60089 10.125 3.875C10.125 2.14911 8.72589 0.75 7 0.75C5.27411 0.75 3.875 2.14911 3.875 3.875C3.875 5.60089 5.27411 7 7 7Z" />
    <path stroke={color || defaultColor} fill="none"
          d="M12.5481 10.5938C13.888 11.4886 13.0477 13.2501 11.4364 13.2501H2.56348C0.952254 13.2501 0.111912 11.4886 1.4518 10.5938C3.03945 9.53345 4.94751 8.91528 6.99995 8.91528C9.05239 8.91528 10.9604 9.53345 12.5481 10.5938Z" />
  </SvgIcon>
}

