import React from 'react';
import { connect } from 'react-redux';

import LoginHeader from '../../components/auth/LoginHeader';
import ResetForm from '../../components/auth/ResetForm';

const ForgotPasswordPage = () => {
  const onResetPassword = async () => {
  };

  return (
    <div>
      <LoginHeader />
      <div className='main-body'>
        <ResetForm onResetPassword={onResetPassword} />
      </div>
    </div>
  );
};

const mapStateToProps = () => {
  return {
  };
};

const mapDispatchToProps = () => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
