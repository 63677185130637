import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function ToolsIcon(props) {
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={props.color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M9.12061 5.03643C8.30163 4.21744 8.25701 3.3407 9.93949 1.65822C10.0058 1.59192 9.99288 1.48046 9.91087 1.43501C8.39873 0.596888 6.92288 0.790403 5.67858 2.0347C4.51064 3.20265 4.26848 4.57459 4.93626 5.98907C4.96086 6.04119 4.94735 6.10361 4.90434 6.14198C4.31992 6.66338 3.72283 7.20397 3.1422 7.7846C2.56157 8.36523 2.02098 8.96232 1.49958 9.54674C0.878328 10.2431 0.744926 11.3273 1.32716 12.0566C1.41832 12.1708 1.51503 12.2812 1.61932 12.3854C1.7236 12.4897 1.83394 12.5864 1.94812 12.6776C2.67742 13.2598 3.76166 13.1264 4.45801 12.5052C5.04243 11.9838 5.63952 11.4432 6.22015 10.8626C6.80078 10.2819 7.34137 9.68483 7.86277 9.10041C7.90114 9.0574 7.96357 9.04389 8.01569 9.06849C9.43016 9.73626 10.8021 9.49409 11.97 8.32616C13.1743 7.12192 13.3943 5.70081 12.6475 4.23983C12.6188 4.18363 12.5435 4.17293 12.4988 4.21756C10.8163 5.90003 9.9396 5.85542 9.12061 5.03643Z" />
  </SvgIcon>
}
