import React, { useMemo } from 'react';

import DateIcon from '../../../assets/icons/CalendarIcon';
import SurgeonIcon from '../../../assets/icons/SurgeonIcon';
import HospitalIcon from '../../../assets/icons/HospitalIcon';
import ProcedureIcon from '../../../assets/icons/ProcedureIcon';
import OptionIcon from '../../../assets/icons/ProcedureOptionIcon';
import PatientIcon from '../../../assets/icons/PatientIcon';
import ProductIcon from '../../../assets/icons/ProductIcon';
import KitsIcon from '../../../assets/icons/KitIcon';
import EditIcon from '../../../assets/icons/EditIcon';
import NotesIcon from '../../../assets/icons/NotesIcon';
import ItemsIcon from '../../../assets/icons/ItemIcon';

import Button from '../../shared/Button';

import { dateUtils } from '../../../utils';
import { getCommaSeparatedNames } from '../../../utils/table';

const Step5 = (props) => {
  const {
    formData,
    onEditClick,
    onClickBack,
    onClose,
    onSubmit,
    surgeons,
    hospitals,
    procedures,
    procedureOptions,
    products: productsList,
    kits: kitsList,
    items: itemsList,
    loading,
    editingBy,
  } = props;
  const {
    date,
    surgeon,
    hospital,
    procedure,
    procedureOption,
    products,
    kits,
    items,
    additionalKits,
    additionalItems,
    patientReference,
    bookingNote = '',
    noEquipmentToShip,
  } = formData;

  const surgeonName = useMemo(() => {
    const caseSurgeon = surgeons.find((item) => item.id === surgeon);
    return caseSurgeon ? `${caseSurgeon.title} ${caseSurgeon.firstName} ${caseSurgeon.lastName}` : '';
  }, [surgeons, surgeon]);

  const hospitalName = useMemo(() => {
    const caseHospital = hospitals.find((item) => item.id === hospital);
    return caseHospital ? caseHospital.name : '';
  }, [hospitals, hospital]);

  const procedureName = useMemo(() => {
    const caseProcedure = procedures.find((item) => item.id === procedure);
    return caseProcedure ? caseProcedure.name : '';
  }, [procedures, procedure]);

  const optionName = useMemo(() => {
    const caseOption = procedureOptions.find((item) => item.id === procedureOption);
    return caseOption ? caseOption.name : '';
  }, [procedureOptions, procedureOption]);

  const productNames = useMemo(() => getCommaSeparatedNames(productsList, products), [productsList, products]);

  const kitNames = useMemo(() => {
    if (!kitsList || !kits) {
      return [];
    }

    return kits.map((item) => {
      const kit = kitsList.find((k) => k.id === item.id);
      return {
        name: kit?.name || '',
        quantity: item?.quantity || 1,
      };
    });
  }, [kitsList, kits]);

  const additionalKitNames = useMemo(() => {
    if (!kitsList || !additionalKits) {
      return [];
    }

    return additionalKits?.map((item) => {
      const kit = kitsList.find((k) => k.id === item.id);
      return {
        name: kit?.name || '',
        quantity: item?.quantity || 1,
      };
    });
  }, [kitsList, additionalKits]);

  const itemNames = useMemo(() => {
    if (!itemsList || !items) {
      return [];
    }

    return items.map((item) => {
      const _item = itemsList.find((i) => i.id === item.id);
      return {
        code: _item?.code || '',
        quantity: item?.quantity || 1,
        description: _item?.checklistDescription || _item?.description || _item?.code || '',
      };
    });
  }, [itemsList, items]);

  const additionalItemNames = useMemo(() => {
    if (!itemsList || !additionalItems) {
      return [];
    }

    return additionalItems?.map((item) => {
      const _item = itemsList.find((i) => i.id === item.id);
      return {
        code: _item?.code || '',
        quantity: item?.quantity || 1,
        description: _item?.description || _item?.code || '',
      };
    });
  }, [itemsList, additionalItems]);

  return (<div className='case-form-preview__container'>
    <div className='case-form-preview__item'>
      <div>
        <div className='d-flex'>
          <DateIcon />
          <div className='m-l-lg font-bold'>{dateUtils.formatDate(date ? date : new Date())}</div>
        </div>
        <div className='d-flex m-t-md'>
          <SurgeonIcon />
          <div className='m-l-lg font-bold'>{surgeonName}</div>
        </div>
      </div>
      <EditIcon
        color='secondary'
        className='pointer'
        onClick={() => onEditClick(0)}
      />
    </div>

    <div className='case-form-preview__item'>
      <div>
        <div className='d-flex align-start'>
          <HospitalIcon />
          <div className='m-l-lg font-bold'>{hospitalName}</div>
        </div>
        <div className='d-flex m-t-md'>
          <ProcedureIcon />
          <div className='m-l-lg font-bold'>{procedureName}</div>
        </div>
        <div className='d-flex m-t-md'>
          <OptionIcon />
          <div className='m-l-lg font-bold'>{optionName}</div>
        </div>
      </div>
      <EditIcon
        color='secondary'
        className='pointer'
        onClick={() => onEditClick(0)}
      />
    </div>

    <div className='case-form-preview__item m-b-lg'>
      <div>
        <div className='d-flex'>
          <PatientIcon />
          <div className='m-l-lg font-bold'>{patientReference}</div>
        </div>
        <div className='d-flex m-t-md'>
          <ProductIcon />
          <div className='m-l-lg font-bold'>{productNames}</div>
        </div>
        <div className='d-flex align-start m-t-md'>
          <KitsIcon />
          <div className='m-l-lg font-bold'>
            {noEquipmentToShip ? (
              <span>No equipment to ship</span>
            ) : (
              kitNames.map((kit) => (
                <div key={kit.name} className='d-flex'>
                  <span>{kit.name}</span>
                  <span className='secondary m-l-md'>{`x${kit.quantity}`}</span>
                </div>)
              )
            )}
          </div>
        </div>
        {items && !!items.length && (
          <div className='d-flex align-start m-t-md'>
            <ItemsIcon />
            <div className='m-l-lg font-bold'>
              {itemNames.map((item) => (
                <div key={item.code} className='d-flex'>
                  <span>{item.description}</span>
                  <span className='secondary m-l-md'>{`x${item.quantity}`}</span>
                </div>
              ))}
            </div>
          </div>
        )}
        {additionalKits && !!additionalKits?.length && (
          <div className='d-flex align-start m-t-md'>
            <KitsIcon />
            <div className='m-l-lg font-bold'>
              {additionalKitNames?.map((kit) => (
                <div key={kit.name} className='d-flex'>
                  <span>{kit.name}</span>
                  <span className='secondary m-l-md'>{`x${kit.quantity}`}</span>
                </div>)
              )}
            </div>
          </div>
        )}
        {additionalItems && !!additionalItems.length && (
          <div className='d-flex align-start m-t-md'>
            <ItemsIcon />
            <div className='m-l-lg font-bold'>
              {additionalItemNames?.map((item) => (
                <div key={item.code} className='d-flex'>
                  <span>{item.description}</span>
                  <span className='secondary m-l-md'>{`x${item.quantity}`}</span>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className='d-flex align-start m-t-md'>
          <NotesIcon />
          <div className='m-l-lg font-bold'>{bookingNote}</div>
        </div>
      </div>
      <EditIcon
        color='secondary'
        className='pointer'
        onClick={() => onEditClick(1)}
      />
    </div>

    <div className='form__actions p-t-lg'>
      <div className='d-flex'>
        <div className='m-r-md'>
          <Button
            type='cancel'
            text='Back'
            onClick={onClickBack}
          />
        </div>
        <Button
          type='submit'
          text='Save'
          disabled={loading || editingBy}
          loading={loading}
          onClick={onSubmit}
        />
      </div>
      <Button
        type='cancel'
        onClick={onClose}
      />
    </div>
  </div>);
};

export default Step5;
