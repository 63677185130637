import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';

import Button from '../shared/Button';
import Input from '../shared/Input';
import ModalSelectInput from '../shared/modal-select/ModalSelectInput';
import RolesModal from '../shared/modal-select/RolesModal';
import GroupsModal from '../shared/modal-select/GroupsModal';

import '../../styles/shared/form.scss';

const DriveFolderForm = (props) => {
  const {
    mode = 'create',
    initialValues,
    buttonText,
    onSubmit,
    loading,
    onClose,
    roleOptions = [],
    groups = [],
  } = props;

  const { name } = initialValues || {};

  const [isModalOpen, toggleModal] = useState(false);
  const [isGroupsModalOpen, toggleGroupsModal] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Required';
    }

    return errors;
  };

  const getRoleNames = (roles) => {
    if (!roles || roles.length === roleOptions.length) {
      return ['All'];
    }

    return roles.map((value) => {
      const role = roleOptions.find((item) => item.value === value);
      return role ? role.label : '';
    })
  };

  const getGroupNames = (groupIds) => {
    if (!groupIds) {
      return [];
    }
    if (groupIds.length === groups.length) {
      return ['All'];
    }

    return groupIds.map((groupId) => {
      const group = groups.find((item) => item.id === groupId);
      return group ? group.name : '';
    })
  };

  return (
    <div className='form__container'>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
        subscription={{ pristine: true, invalid: true }}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <div className='form__subtitle'>
                { name ? name : 'Add Folder' }
              </div>
              { mode === 'update' && (
                <Field name='active'>
                  {({ input, meta }) => (
                    <Input
                      type='switch'
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      placeholder='Status'
                    />
                  )}
                </Field>
              )}

              <div className='m-b-sm'>
                <div className='field__title'>Display Name</div>
                <Field name='name'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      placeholder='Name'
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              { mode === 'update' && (
                <div className='m-b-lg'>
                  <div className='field__title'>Accessibility</div>
                  <Field name='allowedRoles'>
                    {({ input, meta }) => (
                      <>
                        <ModalSelectInput
                          onClick={() => toggleModal(true)}
                          count={input.value.length}
                          values={getRoleNames(input.value)}
                          label='Roles'
                          icon='users'
                        />

                        <RolesModal
                          onChange={input.onChange}
                          selected={input.value}
                          onClose={() => toggleModal(false)}
                          isOpen={isModalOpen}
                          roles={roleOptions}
                        />
                      </>
                    )}
                  </Field>

                  <Field name='allowedGroups'>
                    {({ input, meta }) => (
                      <>
                        <div className='m-t-lg'>
                          <ModalSelectInput
                            onClick={() => toggleGroupsModal(true)}
                            count={input.value.length}
                            values={getGroupNames(input.value)}
                            label='Groups'
                            icon='users'
                          />
                        </div>

                        <GroupsModal
                          onChange={input.onChange}
                          selected={input.value}
                          onClose={() => toggleGroupsModal(false)}
                          isOpen={isGroupsModalOpen}
                          groups={groups}
                        />
                      </>
                    )}
                  </Field>
                </div>
              )}

              <div className='form__actions'>
                <Button
                  type='submit'
                  text={buttonText || 'Save Changes'}
                  disabled={invalid || loading || pristine}
                  loading={loading}
                  onClick={handleSubmit}
                />
                { mode === 'create' && (
                  <Button
                    type='cancel'
                    onClick={onClose}
                  />
                )}
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default DriveFolderForm;
