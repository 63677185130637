import React, { useCallback } from 'react';

import CaseIcon from '../../assets/icons/CaseIcon';

import { emailTriggers } from '../../constants';

import Input from '../shared/Input';

import './emails.scss';

const EmailToggleContainer = (props) => {
  const { type, value, onChange } = props;

  const getIcon = useCallback(() => {
    switch (type) {
      case emailTriggers.CASE_UPDATED:
        return <CaseIcon />;
      default:
        return null;
    }
  }, [type]);

  const getTitle = useCallback(() => {
    switch (type) {
      case emailTriggers.CASE_UPDATED:
        return 'Case Updated';
      default:
        return '';
    }
  }, [type]);

  const getDescription = useCallback(() => {
    switch (type) {
      case emailTriggers.CASE_UPDATED:
        return 'Users associated with a case will receive an email any time a booked case changes date or hospital';
      default:
        return '';
    }
  }, [type]);

  return (
    <div className='email-triggers__container'>
      <div className='d-flex'>
        {getIcon()}
        <div className='font-size-bg font-bold m-l-md'>
          {getTitle()}
        </div>
      </div>
      <div className='m-t-md m-b-md'>{getDescription()}</div>

      <div className='m-t-lg'>
        <Input
          type='switch'
          value={value}
          onChange={(v) => onChange(v, type)}
          placeholder='Status'
        />
      </div>
    </div>
  );
};

export default EmailToggleContainer;
