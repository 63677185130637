import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';

import Button from '../shared/Button';
import Input from '../shared/Input';
import CountrySelect from '../shared/CountrySelect';
import ModalSelectInput from '../shared/modal-select/ModalSelectInput';
import ModalSelect from '../shared/modal-select/ModalSelect';
import HospitalLocationsModal from '../shared/modal-select/HospitalLocationsModal';
import Checkbox from '../shared/Checkbox';

import HospitalAddressesInput from './HospitalAddressesInput';
import HospitalAddressesModal from './HospitalAddressesModal';
import BillingAddressModal from './BillingAddressModal';
import BillingAddressInput from './BillingAddressInput';

import { countries } from '../../constants';

import '../../styles/shared/form.scss';

const HospitalForm = (props) => {
  const {
    mode = 'create',
    initialValues,
    buttonText,
    onSubmit,
    loading,
    onClose,
    territories = [],
    hospitals = [],
  } = props;

  const [isModalOpen, toggleModal] = useState(false);
  const [isAddressesModalOpen, toggleAddressesModal] = useState(false);
  const [businessAddressModalOpen, toggleBusinessAddressModalOpen] = useState(false);
  const [territoriesModalOpen, toggleTerritoriesModal] = useState(false);

  const { name } = initialValues || {};

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Required';
    }

    if (!values.country) {
      errors.country = 'Required';
    }
    if (!values.street) {
      errors.street = 'Required';
    }
    if (!values.city) {
      errors.city = 'Required';
    }
    if (!values.state) {
      errors.state = 'Required';
    }
    if (!values.postCode) {
      errors.postCode = 'Required';
    }

    // if (hospitals?.find((h) => h?.name?.toLowerCase()?.trim() === values?.name?.toLowerCase()?.trim()) && mode === 'create') {
    //   errors.name = 'Duplicate';
    // }

    return errors;
  };

  const getTerritoryNames = (ids) => {
    return ids?.map((id) => {
      const territory = territories.find((item) => item.id === id);
      return territory ? territory.name : '';
    });
  };

  return (
    <div className='form__container'>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{
          ...initialValues,
          territories: initialValues?.territories || []
          // locations: initialValues && initialValues.locations ? initialValues.locations : [],
        }}
        subscription={{ pristine: true, invalid: true, values: true }}
        keepDirtyOnReinitialize={mode === 'create'}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine, values}) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <div className='form__subtitle'>
                { name ? name : 'Add Hospital' }
              </div>
              { mode === 'update' && (
                <Field name='active'>
                  {({ input, meta }) => (
                    <Input
                      type='switch'
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      placeholder='Status'
                    />
                  )}
                </Field>
              )}

              <div>
                <div className='field__title'>Name</div>
                <Field name='name'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      placeholder='Name'
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              <div>
                <div className='field__title'>Country</div>
                <Field name='country'>
                  {({ input, meta }) => (
                    <CountrySelect
                      countries={countries}
                      initialValues={initialValues}
                      input={input}
                    />
                  )}
                </Field>
              </div>

              <div className='combined-fields__container'>
                <Field name='street'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      borderless
                      startAdornment='Street'
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
                <Field name='city'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      startAdornment='City'
                      borderless
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
                <Field name='state'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      startAdornment='State'
                      borderless
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
                <Field name='postCode'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      startAdornment='Postcode'
                      borderless
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              { mode === 'update' && (
                <div className='m-b-lg'>
                  <Field name='defaultBillingAddress' type='checkbox'>
                    {({ input }) => (
                      <Checkbox
                        input={input}
                        fontSize='small'
                        label='Billing Address as above'
                      />
                    )}
                  </Field>
                  {!values.defaultBillingAddress && (
                    <Field name='billingAddress'>
                      {({ input, meta }) => (
                        <div className='m-t-sm'>
                          <div className='field__title'>Billing Address</div>
                          <BillingAddressInput
                            onClick={() => toggleBusinessAddressModalOpen(true)}
                            address={input.value}
                          />

                          <BillingAddressModal
                            open={businessAddressModalOpen}
                            onClose={() => toggleBusinessAddressModalOpen(false)}
                            initialValues={input.value}
                            onSubmit={input.onChange}
                            defaultCountry={values ? values.country : ''}
                          />
                        </div>
                      )}
                    </Field>
                  )}
                </div>
              )}

              { mode === 'update' && (
                <div className=''>
                  <div className='field__title'>Territories</div>
                  <Field name='territories'>
                    {({ input, meta }) => (
                      <div className='m-b-lg'>
                        <ModalSelectInput
                          onClick={() => toggleTerritoriesModal(true)}
                          count={input.value.length}
                          label='Edit Territories'
                          icon='territory'
                          values={getTerritoryNames(input.value)}
                        />

                        <ModalSelect
                          title='Add Territories'
                          listTitle='Territories'
                          options={territories}
                          onChange={input.onChange}
                          selected={input.value}
                          onClose={() => toggleTerritoriesModal(false)}
                          isOpen={territoriesModalOpen}
                        />
                      </div>
                    )}
                  </Field>
                </div>
              )}

              <div>
                <div className='field__title'>Account No.</div>
                <Field name='accountNumber'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      placeholder='Account No.'
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              <div>
                <div className='field__title'>Business No.</div>
                <Field name='businessNumber'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      placeholder='Business No.'
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              <div>
                <div className='field__title'>Shipping Lead Days (3 by default)</div>
                <Field name='leadDays'>
                  {({ input, meta }) => (
                    <Input
                      numbersOnly
                      name={input.name}
                      value={input.value}
                      placeholder='Number of Days'
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              { mode === 'update' && (
                <Field name='locations'>
                  {({ input, meta }) => (
                    <div className='m-b-lg'>
                      <div className='field__title'>Inventory Locations</div>
                      <ModalSelectInput
                        onClick={() => toggleModal(true)}
                        count={input.value.length}
                        label='Edit'
                        values={input.value || []}
                        icon='option'
                      />
                      <HospitalLocationsModal
                        onClose={() => toggleModal(false)}
                        isOpen={isModalOpen}
                        onSubmit={input.onChange}
                        initialValues={initialValues && initialValues.locations ? initialValues.locations : []}
                      />
                    </div>
                  )}
                </Field>
              )}

              { mode === 'update' && (
                <Field name='deliveryAddresses'>
                  {({ input, meta }) => (
                    <div className='m-b-lg'>
                      <div className='field__title'>Delivery Addresses</div>
                      <HospitalAddressesInput
                        onClick={() => toggleAddressesModal(true)}
                        addresses={input.value || []}
                      />
                      <HospitalAddressesModal
                        open={isAddressesModalOpen}
                        onClose={() => toggleAddressesModal(false)}
                        initialValues={input.value || []}
                        onSubmit={input.onChange}
                        defaultCountry={values ? values.country : ''}
                      />
                    </div>
                  )}
                </Field>
              )}

              <div className='form__actions'>
                <Button
                  type='submit'
                  text={buttonText || 'Save Changes'}
                  disabled={invalid || loading || pristine}
                  loading={loading}
                  onClick={handleSubmit}
                />
                { mode === 'create' && (
                  <Button
                    type='cancel'
                    onClick={onClose}
                  />
                )}
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default HospitalForm;
