import React, { useMemo } from 'react';
import { orderBy } from 'lodash';

import { PieChart } from '../../../charts';
import { formatChartPrice } from '../../../../utils/utils';

export const CustomTooltip = ({ datum }) => {
  const { id, formattedValue } = datum;

  return (
    <div className="custom-tooltip">
      <div className="tooltip-label">{id}</div>
      <div className="tooltip-content">
        <div>{`$${formatChartPrice(formattedValue, 0)}`}</div>
      </div>
    </div>
  );
};

const ForecastingProceduresPieChart = ({ cases = [], procedures = [] }) => {
  const data = useMemo(() => {
    const chartData = procedures
      ?.map((procedure) => {
        const procedureCases = cases.filter((caseItem) => caseItem.procedure === procedure.id);
        return {
          id: procedure.name,
          label: procedure.name,
          value: procedureCases.length * (procedure.averageValue || 0),
        };
      })

    return orderBy(chartData?.filter((item) => !!item.value), 'value', 'asc');
  }, [cases, procedures]);

  return (
    <div className="management-reports-chart__container m-l-md" style={{ width: '50%' }}>
      <div className="font-size-bg font-bold">Booked Case Projected Total Revenue</div>
      <div className="secondary m-t-sm m-b-lg">Total revenue based on average procedure value over the next 90 days</div>
      <PieChart
        data={data}
        margin={{ top: 20, right: 60, bottom: 20, left: -60 }}
        height="300px"
        enableArcLinkLabels={true}
        enableArcLabels={true}
        CustomTooltip={CustomTooltip}
        isCurrency
        arcLabel={({ value }) =>`$${formatChartPrice(value, 0)}`}
      />
    </div>
  );
};

export default ForecastingProceduresPieChart;
