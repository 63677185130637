import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import LoadScreen from '../../../components/load-screen';
import { PieChart, BarChart, LineChart } from '../../../components/charts';

import { getSurgeons } from '../../../actions/surgeonsActions';
import { getHospitals } from '../../../actions/hospitalsActions';
import { getProcedures } from '../../../actions/proceduresActions';
import { getCompletedCases } from '../../../actions/casesActions';

import { useLoading } from '../../../hooks';

import {
  prepareCasesDataMonthly,
  prepareSurgeonsData,
  prepareHospitalsData,
  prepareProceduresData,
  getTenantColors,
} from '../../../utils/analytics';

import './analytics-page.scss';

const AnalyticsPage = () => {
  const dispatch = useDispatch();
  const tenantPrimaryColor = useSelector((state) => state.tenant.currentTenant.colorPrimary);
  const activeCases = useSelector((state) => state?.cases.list);
  const completedCases = useSelector((state) => state?.cases.completedCases);
  const surgeons = useSelector((state) => state.surgeons.list?.filter((s) => !!s.active));
  const hospitals = useSelector((state) => state.hospitals.list?.filter((h) => !!h.active));
  const procedures = useSelector((state) => state.procedures.list?.filter((p) => !!p.active));

  const cases = useMemo(() => [...activeCases, ...completedCases], [activeCases, completedCases]);

  const { loading, startLoading, stopLoading } = useLoading();

  useEffect(() => {
    onLoad().catch((err) => console.error(err));
  }, []);

  const onLoad = async () => {
    startLoading();
    try {
      await Promise.all([
        dispatch(getSurgeons()),
        dispatch(getHospitals()),
        dispatch(getProcedures()),
        dispatch(getCompletedCases())
      ]);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const casesChartData = useMemo(() => {
    const thisYearData = prepareCasesDataMonthly(cases?.filter((item) => (
      moment(item.date)?.isSame(moment(), 'year')
    )));
    const previousYearData = prepareCasesDataMonthly(cases?.filter((item) => (
      moment(item.date)?.isSame(moment().subtract(1, 'year'), 'year')
    )));
    return [...thisYearData, ...previousYearData?.map((item) => ({ ...item, id: `${item.id}_previousYear` }))];
  }, [cases]);
  const surgeonsChartData = useMemo(() => prepareSurgeonsData(cases, surgeons), [cases, surgeons]);
  const hospitalsChartData = useMemo(() => prepareHospitalsData(cases, hospitals), [cases, hospitals]);
  const proceduresChartData = useMemo(() => prepareProceduresData(cases, procedures), [cases, procedures]);

  return (
    <div className="page-container flex-1">
      {loading && <LoadScreen />}
      <div className="page-title">Analytics</div>
      <div className="analytics-page__body">
        <div className="m-b-lg">
          <div className="analytics-chart__container">
            <div className="font-size-bg font-bold">Total Cases</div>
            <div className="secondary m-t-sm m-b-lg">Total cases (monthly)</div>
            <LineChart
              data={casesChartData}
              margin={{ top: 20, right: 15, bottom: 30, left: 30 }}
              height='80%'
              colors={[tenantPrimaryColor, '#cccccc']}
              monthlyCompared
            />
          </div>

          <div className="analytics-chart__container">
            <div className="font-size-bg font-bold">Top 10 Surgeons</div>
            <div className="secondary m-t-sm m-b-lg">Total cases shown by Surgeon</div>
            <BarChart
              data={surgeonsChartData}
              colors={getTenantColors(surgeonsChartData, tenantPrimaryColor)}
              margin={{ top: 20, right: 15, bottom: 30, left: 30 }}
              height='80%'
            />
          </div>
        </div>
        <div className="m-t-lg">
          <div className="analytics-chart__container">
            <div className="font-size-bg font-bold">Top 10 Hospitals</div>
            <div className="secondary m-t-sm m-b-lg">Total cases by Hospital</div>
            <BarChart
              data={hospitalsChartData}
              colors={getTenantColors(hospitalsChartData, tenantPrimaryColor)}
              margin={{ top: 10, right: 15, bottom: 30, left: 30 }}
              height='80%'
            />
          </div>

          <div className="analytics-chart__container">
            <div className="font-size-bg font-bold">Top 10 Procedures</div>
            <div className="secondary m-t-sm m-b-lg">Total cases by Procedure</div>
            <PieChart
              data={proceduresChartData}
              colors={getTenantColors(proceduresChartData, tenantPrimaryColor)}
              margin={{ top: 20, right: 60, bottom: 20, left: -60 }}
              height='80%'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsPage;
