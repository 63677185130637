const setLogTypes = {
  SET_TURNS: 'SET_TURNS',
};

const targetOptions = [];
for (let i = 1; i <= 20; i++) {
  targetOptions?.push({ label: i, value: i });
}

const rangeOptions = [
  { label: 'Last 3 months', value: 3 },
  { label: 'Last 6 months', value: 6 },
  { label: 'Last 12 months', value: 12 },
  { label: 'Last 18 months', value: 18 },
  { label: 'Last 24 months', value: 24 },
];

export {
  targetOptions,
  rangeOptions,
  setLogTypes,
};
