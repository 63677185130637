import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';

import { CompanyForm, FinancialForm } from '../../../components/company';
import Alert from '../../../components/shared/Alert';
import Tabs from '../../../components/shared/Tabs';

import { updateTenant } from '../../../actions/tenantActions';

const tabs = [
  { label: 'General' },
  { label: 'Financial' },
];

const CompanyPage = (props) => {
  const {
    tenant,
    storage,
    updateTenant
  } = props;

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  const onSubmit = async (formObj, files) => {
    setLoading(true);
    const tenantObj = {
      businessName: formObj.businessName,
      country: formObj.country,
      address1: formObj.address1,
      address2: formObj.address2,
      city: formObj.city,
      state: formObj.state,
      postCode: formObj.postCode,
      colorPrimary: formObj.colorPrimary,
      maxEmployee: formObj.maxEmployee,
      externalUsersAllowed: !!formObj.externalUsersAllowed,
      timeZone: formObj.timeZone,
      businessNumber: formObj.businessNumber || '',
      phone: formObj.phone || ''
    };

    try {
      await updateTenant(tenantObj, files);
      setLoading(false);
      setSuccessMessage('Company has been successfully updated');
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const onFinancialSubmit = async (formObj) => {
    setLoading(true);
    const financialDetails = {
      billingAccountName: formObj.billingAccountName || '',
      bankName: formObj.bankName || '',
      branchDetails: formObj.branchDetails || '',
      routingCode: formObj.routingCode || '',
      accountNumber: formObj.accountNumber || '',
      defaultPaymentTerms: formObj.defaultPaymentTerms ? Number(formObj.defaultPaymentTerms) : 0,
      defaultSalesTax: formObj.defaultSalesTax ? Number(formObj.defaultSalesTax) : 0,
      currency: formObj.currency || '',
      proformaContact: formObj.proformaContact || '',
      proformaEmail: formObj.proformaEmail || '',
      showPaymentDetails: formObj.showPaymentDetails || false,
    };

    try {
      await updateTenant({ financialDetails });
      setLoading(false);
      setSuccessMessage('Financial details have been successfully updated');
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const initialValues = useMemo(() => {
    return {
      ...tenant,
      logoName: tenant.logo ? tenant.logo.fileName : '',
      iconName: tenant.icon ? tenant.icon.fileName : '',
      documentLogo: tenant.documentLogo ? tenant.documentLogo.fileName : '',
    };
  }, [tenant]);

  return (
    <div className='settings-cmp__main company-page__container'>
      <span className='settings-title'>Company</span>

      <div className="form__container" style={{ paddingTop: 8 }}>
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          onTabChange={setActiveTab}
        >
          <div className='m-t-md'>
            {activeTab === 0 ? (
              <CompanyForm
                initialValues={initialValues}
                loading={loading}
                onSubmit={onSubmit}
                storage={storage}
              />
            ) : (
              <FinancialForm
                initialValues={tenant?.financialDetails}
                loading={loading}
                onSubmit={onFinancialSubmit}
              />
            )}
          </div>
        </Tabs>
      </div>

      <Alert
        variant='success'
        message={successMessage}
        open={!!successMessage}
        onClose={() => setSuccessMessage(null)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.currentTenant,
    storage: state.tenant.storage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTenant: (tenantData, files) => dispatch(updateTenant(tenantData, files)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPage);
