import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import AddIcon from '../../../assets/icons/AddIcon';
import CheckIcon from '../../../assets/icons/CheckIcon';
import FlowIcon from '../../../assets/icons/FlowIcon';

import AddFlowModal from './AddFlowModal';
import CaseFlowTable from './CaseFlowTable';
import CompleteStepModal from './CompleteStepModal';
import ActionButton from '../../shared/ActionButton';

import { addFlow, completeStep } from '../../../actions/casesActions';

import { useLoading, useAlert } from '../../../hooks';
import { nowTimestampUTC } from '../../../utils/date';

import { setAllocationStatuses, caseStatusOptions, flowStepStatuses } from '../../../constants/enums';

import '../cases.scss';

const { TRANSFERRED, SHIPPED } = setAllocationStatuses;

const CaseFlowContainer = (props) => {
  const {
    activeCase,
    steps = [],
    flows = [],
    procedures = [],
    tenantColor,
    tenantId,
    users = [],
    userRole,
  } = props;

  const dispatch = useDispatch();

  const { loading, startLoading, stopLoading } = useLoading(false);
  const { showAlert } = useAlert();

  const [addModalOpen, toggleAddModal] = useState(false);
  const [completeModalOpen, toggleCompleteModal] = useState(false);

  const onAddFlow = async (flowId) => {
    startLoading();
    try {
      const previousSteps = activeCase?.flow && steps && steps?.length ? steps?.map((step) => step.id) : [];
      await dispatch(addFlow(activeCase.id, flowId, previousSteps));
      toggleAddModal(false);
      showAlert('success', 'You successfully added a flow');
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onCompleteStep = async (formObj, files) => {
    startLoading();
    try {
      const documents = files?.documents?.map((file) => ({
        active: true,
        fileName: file.name,
        path: file.path,
        downloadUrl: file.downloadUrl,
        size: file.size,
        createdAt: nowTimestampUTC(),
      }));
      const images = files?.images?.map((file) => ({
        active: true,
        fileName: file.name,
        path: file.path,
        downloadUrl: file.downloadUrl,
        size: file.size,
        createdAt: nowTimestampUTC(),
      }));

      const data = {
        notes: formObj.notes || '',
        link: formObj.link || '',
        documents: documents || [],
        images: images || [],
      };

      await dispatch(completeStep(activeCase.id, formObj.id, data));
      toggleCompleteModal(false);
      showAlert('success', 'Step has been completed');
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onRowClick = (step) => {
    if (activeStep.stepNumber === step.stepNumber && !allCompleted) {
      toggleCompleteModal(true);
    }
  };

  // Memoized

  const flowOptions = useMemo(() => (
    flows?.filter((item) => item?.procedures?.includes(activeCase?.procedure)).map((item) => ({ label: item.name, value: item.id }))
  ), [activeCase, flows]);

  const procedureName = useMemo(() => {
    const procedure = procedures?.find((item) => item.id === activeCase?.procedure);
    return procedure ? procedure.name : '';
  }, [activeCase, procedures]);

  const flowName = useMemo(() => {
    const flow = flows?.find((item) => item.id === activeCase?.flow);
    return flow ? flow.name : '';
  }, [activeCase, flows]);

  const activeStep = useMemo(() => {
    const completed = steps?.filter((item) => item.status === flowStepStatuses.COMPLETED.value);
    const maxStep = Math.max(...completed.map(({ stepNumber }) => stepNumber));

    if (maxStep === steps?.length) {
      return steps[steps?.length - 1];
    }

    const step = steps?.find((item) => item.stepNumber === maxStep);
    const pending = steps?.find((item) => item?.stepNumber === step?.stepNumber + 1);

    if (pending) {
      return pending;
    } else {
      return steps?.find((item) => item?.stepNumber === 1);
    }
  }, [activeCase, steps]);

  const allCompleted = useMemo(() => {
    return steps && !!steps?.length &&steps?.every((allocation) => allocation.status === flowStepStatuses.COMPLETED.value);
  }, [steps]);

  return (
    <div className='case-kits__container'>
      <div className='d-flex align-start space-between'>
        <div className='d-flex'>
          <FlowIcon />
          <div className='font-size-bg font-bold m-l-md'>
            {flowName || 'Flow'}
          </div>
          {activeCase?.flow && !!steps?.length && (
            <div
              className='case-flow__active-step'
              style={{
                background: steps?.every((item) => item.status === flowStepStatuses.COMPLETED.value) ? SHIPPED.color : TRANSFERRED.color
              }}
            >
              {allCompleted ? (
                <CheckIcon color="#ffffff" />
              ) : (
                activeStep?.stepNumber
              )}
            </div>
          )}
        </div>

        {!allCompleted && (
          <div className='case-sets-actions__container'>
            <ActionButton
              disabled={
                ![caseStatusOptions.request, caseStatusOptions.booked].includes(activeCase?.status)
                || !flowOptions.length
                || (!!activeCase.flow && steps?.some((step) => step?.status === flowStepStatuses.COMPLETED.value))
              }
              onClick={() => toggleAddModal(true)}
              bgColor={setAllocationStatuses.RETURNED.color}
            >
              <AddIcon color="#ffffff" />
              Add
            </ActionButton>

            {activeCase.flow && (
              <ActionButton
                disabled={steps?.every((item) => item.status === flowStepStatuses.COMPLETED.value) || !activeStep}
                onClick={() => toggleCompleteModal(true)}
                bgColor={setAllocationStatuses.SHIPPED.color}
              >
                <CheckIcon color="#ffffff" />
                Complete
              </ActionButton>
            )}
          </div>
        )}
      </div>

      <CaseFlowTable
        rows={steps}
        onRowClick={onRowClick}
        caseDate={activeCase.date}
        activeStep={activeStep?.stepNumber}
        users={users}
        userRole={userRole}
        tenantColor={tenantColor}
      />

      <AddFlowModal
        open={addModalOpen}
        onClose={() => toggleAddModal(false)}
        loading={loading}
        onSubmit={onAddFlow}
        procedure={procedureName}
        options={flowOptions}
      />

      <CompleteStepModal
        open={completeModalOpen}
        onClose={() => toggleCompleteModal(false)}
        loading={loading}
        onSubmit={onCompleteStep}
        title={activeStep?.title}
        step={activeStep}
        tenantColor={tenantColor}
        tenantId={tenantId}
        caseId={activeCase?.id}
        stepId={activeStep?.id}
        userRole={userRole}
      />
    </div>
  );
};

export default CaseFlowContainer;
