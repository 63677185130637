import React, { useState } from 'react';

import Button from '../../shared/Button';
import Modal from '../../shared/modal';
import Input from '../../shared/Input';

const CloseFormModal = (props) => {
  const { open, onSubmit, onClose, loading } = props;

  const [notes, setNotes] = useState('');

  return (
    <Modal open={open} onClose={onClose}>
      <div className='confirmation-modal__container'>
        <div>
          <div className='modal-title'>
            Close Form
          </div>

          <div className='modal-content' style={{ marginTop: '24px', marginBottom: '16px' }}>
            Are you sure you want to close this form? This will updated the status to closed and move the form to the closed list.
          </div>

          <div className='m-b-lg'>
            <div className='m-b-sm font-bold'>Notes</div>
            <Input
              value={notes}
              type='textarea'
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>

          <div className='form__actions'>
            <Button
              type='submit'
              text="Yes"
              onClick={() => onSubmit(notes)}
              disabled={loading}
              loading={loading}
            />
            <Button
              type='cancel'
              text='Cancel'
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CloseFormModal;
