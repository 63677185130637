import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function NotesIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M5.0293 13.0294C4.43511 12.9989 3.86632 12.957 3.3475 12.9028C3.09788 12.9028 2.85849 12.8055 2.68198 12.6324C1.77509 11.3163 1.77026 2.50298 2.68198 1.17313C2.85849 1.00002 3.09788 0.902771 3.3475 0.902771C5.39071 0.68917 8.20894 0.667028 10.3016 0.779808C10.8208 0.807789 11.2187 1.24175 11.2187 1.76169L11.2186 5.02167" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M0.75 3.90271H2.75" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" d="M0.75 9.90271H2.75" />
    <path stroke={color || defaultColor} fill="none" strokeLinejoin="round"
          d="M9.18396 13.2294L7.45245 13.3647L7.59297 11.6384L11.3574 7.72464C11.8335 7.22957 12.6234 7.22189 13.1091 7.7076V7.7076C13.5943 8.1928 13.5872 8.98163 13.0934 9.45804L9.18396 13.2294Z" />
  </SvgIcon>
}
