import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { SurgeonsFilters, SurgeonsTable, PreferencesList } from '../../../components/customers/surgeons';
import Input from '../../../components/shared/Input';

import { subscribeToSurgeons, subscribeToQualifications, updateSurgeon } from '../../../actions/surgeonsActions';
import { getHospitals } from '../../../actions/hospitalsActions';
import { getProcedures } from '../../../actions/proceduresActions';

import { useLoading } from '../../../hooks';

import { filterCustomerSurgeons } from '../../../utils/table';

const CustomerSurgeonsPage = () => {
  const dispatch = useDispatch();
  const surgeons = useSelector((state) => state?.surgeons.list);
  const hospitals = useSelector((state) => state?.hospitals.list);
  const procedures = useSelector((state) => state?.procedures.list);
  const qualifications = useSelector((state) => state?.surgeons.qualifications);
  const userRole = useSelector((state) => state?.user?.currentUser?.role);
  const tenantColor = useSelector((state) => state.tenant.currentTenant.colorPrimary);

  const [selectedSurgeon, setSelectedSurgeon] = useState(null);
  const [procedure, setProcedure] = useState('');
  const [search, setSearch] = useState('');
  const [editing, setEditing] = useState(false);

  const { startLoading, stopLoading } = useLoading();

  useEffect(() => {
    const unsubscribeToSurgeons = dispatch(subscribeToSurgeons());
    const unsubscribeToQualifications = dispatch(subscribeToQualifications());

    onLoad().catch((err) => console.error(err));

    return () => {
      if (unsubscribeToSurgeons) {
        unsubscribeToSurgeons();
      }
      if (unsubscribeToQualifications) {
        unsubscribeToQualifications();
      }
    };
  }, []);

  const onLoad = async () => {
    startLoading()
    try {
      await Promise.all([
        dispatch(getHospitals()),
        dispatch(getProcedures()),
      ]);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onContactUpdate = async (formObj) => {
    if (selectedSurgeon) {
      try {
        setEditing(true);
        await dispatch(updateSurgeon(selectedSurgeon.id, { email: formObj?.email || '', phone: formObj?.phone || '' }));
        setSelectedSurgeon({
          ...selectedSurgeon,
          email: formObj?.email || '',
          phone: formObj?.phone || '',
        });
      } finally {
        setEditing(false);
      }
    }
  };

  const surgeonList = useMemo(() => {
    return surgeons.filter((surgeon) => filterCustomerSurgeons(surgeon, search, procedure));
  }, [surgeons, search, procedure]);

  return (
    <div className='settings-cmp__main'>
      <span className='settings-title'>Surgeons</span>
      <div className='settings-cmp__body'>
        <div className='filters-container'>
          <SurgeonsFilters
            procedure={procedure}
            procedures={procedures}
            setProcedure={setProcedure}
          />
          <SurgeonsTable
            onSelectSurgeon={setSelectedSurgeon}
            surgeons={surgeonList}
            selectedSurgeonId={selectedSurgeon ? selectedSurgeon.id : null}
            procedures={procedures}
            hospitals={hospitals}
            qualifications={qualifications}
          />
        </div>
        { surgeons && !!surgeons.length && (
          <div className='form-container'>
            <Input
              type='search'
              placeholder='Search Surgeons'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {selectedSurgeon && (
              <PreferencesList
                surgeon={selectedSurgeon}
                hospitals={hospitals}
                procedures={procedures}
                userRole={userRole}
                tenantColor={tenantColor}
                onContactUpdate={onContactUpdate}
                editing={editing}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerSurgeonsPage;
