import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import LoadScreen from '../../../components/load-screen';
import Button from '../../../components/shared/Button';
import ConfirmationModal from '../../../components/shared/modal/ConfirmationModal';
import { CountInfo, CountSection, KitInfo, SubmitModal } from '../../../components/counts/bill-of-material';

import { getHospitals } from '../../../actions/hospitalsActions';
import {
  getCount,
  updateCount,
  addBOMItems,
  getCountScans,
  deleteBOMItems,
  updateBOMItems,
} from '../../../actions/countsActions';
import { getKits, getSections } from '../../../actions/kitsActions';
import { getItems } from '../../../actions/itemsActions';

import { useLoading } from '../../../hooks';

import routes from '../../../constants/routes';
import { billOfMaterialItemTypes, implantScanTypes } from '../../../constants/enums';

const CountBOMPage = () => {
  const history = useHistory();
  const { id } = useParams();

  const countId = id.replace(':', '');

  const dispatch = useDispatch();
  const kits = useSelector((state) => state.kits.list);
  const hospitals = useSelector((state) => state.hospitals.list);
  const tenantColor = useSelector((state) => state.tenant.currentTenant.colorPrimary);

  const [sections, setSections] = useState([]);
  const [open, toggleModal] = useState(false);
  const [submitModalOpen, toggleSubmitModal] = useState(false);
  const [count, setCount] = useState(null);

  const { loading, startLoading, stopLoading } = useLoading(false);
  const { loading: submitting, startLoading: startSubmit, stopLoading: stopSubmit } = useLoading(false);

  useEffect(() => {
    onLoad().catch((err) => console.error(err));
  }, []);

  const onLoad = async () => {
    startLoading()
    try {
      const count = await dispatch(getCount(countId, true));
      const scans = await dispatch(getCountScans(countId, true));

      setCount(count);

      if (count && count.kit) {
        const items = await dispatch(getItems(true));
        const sections = await dispatch(getSections(count.kit));
        setSections(sections?.map((section) => ({
          ...section,
          items: section?.items?.map((item) => {
            if (item.type === billOfMaterialItemTypes.NOTES.value) {
              return item;
            } else {
              const _item = items?.find((i) => i.id === item.id);
              const result = {
                ..._item,
                quantity: item.quantity,
                ref: item.ref || 0,
                count: item.quantity || 0,
                itemId: item.id,
              };

              if (count.addedBOM) {
                const scan = scans?.find((scan) => scan?.scanType === implantScanTypes.billOfMaterial && scan?.itemId === item.id);

                if (scan) {
                  result.scanId = scan.id;
                }

                if (scan && scan.quantity) {
                  result.count = scan.quantity;
                  result.defaultValue = scan.quantity;
                } else {
                  result.count = 0;
                  result.defaultValue = 0;
                }
              }

              return result;
            }
          })
        })));
      }

      await Promise.all([
        dispatch(getHospitals()),
        dispatch(getKits()),
      ]);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onItemsChange = (sectionId, items) => {
    const temp = [...sections];
    sections[sectionId].items = items;
    setSections(temp);
  };

  const onAddClick = () => {
    const result = [];

    sections?.forEach((section) => {
      section?.items?.forEach((item) => {
        if (item?.count > 0) {
          result?.push(item);
        }
      });
    });

    if (!!result?.length) {
      toggleModal(true);
    }
  };

  const onUpdateClick = () => {
    const result = [];

    sections?.forEach((section) => {
      section?.items?.forEach((item) => {
        if (item?.count !== item?.defaultValue) {
          result?.push(item);
        }
      });
    });

    if (!!result?.length) {
      toggleSubmitModal(true);
    }
  };

  const onSubmit = async () => {
    startSubmit();

    try {
      const result = [];

      sections?.forEach((section) => {
        section?.items?.forEach((item) => {
          if (item?.count > 0) {
            result?.push(item);
          }
        });
      });

      await dispatch(addBOMItems(countId, result));
      await dispatch(updateCount(countId, { addedBOM: true }));

      toggleModal(false);
      history.push(`${routes.INVENTORY}/${countId}`);
    } catch (err) {
      console.error(err);
    } finally {
      stopSubmit();
    }
  };

  const onUpdate = async () => {
    startSubmit();

    try {
      const updated = [];
      const deleted = [];
      const created = [];

      sections?.forEach((section) => {
        section?.items?.forEach((item) => {
          if (item?.count > 0 && item?.defaultValue > 0 && item?.count !== item?.defaultValue) {
            updated?.push(item);
          }
          if (item?.count === 0 && item?.defaultValue > 0) {
            deleted?.push(item);
          }
          if (item?.count > 0 && item?.defaultValue === 0) {
            created?.push(item);
          }
        });
      });

      await dispatch(addBOMItems(countId, created));
      await dispatch(updateBOMItems(countId, updated));
      await dispatch(deleteBOMItems(countId, deleted));

      toggleModal(false);
      history.push(`${routes.INVENTORY}/${countId}`);
    } catch (err) {
      console.error(err);
    } finally {
      stopSubmit();
    }
  };

  const kit = useMemo(() => {
    if (count && kits) {
      return kits?.find((item) => item.id === count.kit);
    }
    return {};
  }, [count, kits]);

  const onViewClick = () => {
    history.push(`${routes.INVENTORY}/${countId}`);
  };

  return (
    <div className='page-container case-sections-page__container'>
      {loading && <LoadScreen />}
      {count && count.id && (
        <>
          <div className='page-title'>
            Count
            <span className='page-subtitle'>{count.id}</span>
          </div>

          <div className='d-flex space-between'>
            <div className='d-flex direction-column m-r-lg' style={{ width: 300 }}>
              <div className='width-100 m-b-md'>
                <Button
                  width='100%'
                  type='outlined'
                  text='View Count'
                  onClick={onViewClick}
                />
              </div>
              <div className='width-100 m-t-md'>
                <Button
                  width='100%'
                  type='submit'
                  text={count?.addedBOM ? 'Update' : 'Add Items'}
                  onClick={count?.addedBOM ? onUpdateClick : onAddClick}
                  disabled={submitting}
                  loading={submitting}
                />
              </div>
            </div>
            {kit ? <KitInfo {...kit} /> : <div />}
            <CountInfo
              count={count}
              kits={kits}
              hospitals={hospitals}
            />
          </div>

          <div style={{ marginTop: 32 }}>
            {sections && !!sections.length && (
              sections.map((section, index) => (
                <CountSection
                  key={`${section.name}_${section.subtitle}`}
                  sectionIndex={index}
                  onChange={onItemsChange}
                  tenantColor={tenantColor}
                  {...section}
                />
              ))
            )}
          </div>
        </>
      )}

      <ConfirmationModal
        open={open}
        onClose={() => toggleModal(false)}
        onSubmit={onSubmit}
        title='Are you sure you want to add the items to this count?'
        submitText='Confirm'
        loading={submitting}
      />

      <SubmitModal
        open={submitModalOpen}
        onClose={() => toggleModal(false)}
        onSubmit={onUpdate}
        loading={submitting}
        sections={sections}
        tenantColor={tenantColor}
      />
    </div>
  );
};

export default CountBOMPage;
