import { LOAD_TENANT_DATA, SET_TENANTS, SET_TENANT, SET_TENANT_STORAGE } from './actionTypes';

import firebase, { collections, storageRefs } from '../firebase';

import { get, getFileWithParameters } from '../utils/api';

import urls from '../constants/urls';
import { orderBy } from 'lodash';
import moment from 'moment';

export const setCurrentTenant = (tenant) => ({ type: SET_TENANT, tenant });

export const getCurrentTenant = () => async (dispatch) => {
  dispatch({ type: LOAD_TENANT_DATA });
  const tenant = await firebase.getCurrentTenant();

  if (tenant) {
    return dispatch(setCurrentTenant(tenant));
  }

  return dispatch(setCurrentTenant(null));
};

export const setTenants = (tenants) => ({ type: SET_TENANTS, tenants });

export const getTenants = () => async (dispatch) => {
  const path = collections.TENANTS_COLLECTION;
  const snapshot = await firebase.db.collection(path).get();
  const tenants = snapshot.docs?.map((doc) => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
    };
  });

  return dispatch(setTenants(tenants));
};

export const updateTenant = (tenantData, files = {}) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.TENANTS_COLLECTION;
  const storageRef = firebase.storage.ref();
  const { logo, icon, documentLogo } = files;

  if (tenantId) {
    await firebase.db.collection(path).doc(tenantId).set(tenantData, { merge: true });

    if (logo) {
      const storagePath = storageRefs.TENANT_FILES_REF(tenantId);
      const filePath = `${storagePath}/logo_${logo.name}`;
      const ref = storageRef.child(filePath);
      const snap = await ref.put(logo);
      const downloadUrl = await snap.ref.getDownloadURL();
      const logoObj = {
        fileName: logo.name,
        path: filePath,
        downloadUrl
      };

      await firebase.db.collection(path).doc(tenantId).set({ logo: logoObj }, { merge: true });
    }
    if (icon) {
      const storagePath = storageRefs.TENANT_FILES_REF(tenantId);
      const filePath = `${storagePath}/icon_${icon.name}`;
      const ref = storageRef.child(filePath);
      const snap = await ref.put(icon);
      const downloadUrl = await snap.ref.getDownloadURL();
      const iconObj = {
        fileName: icon.name,
        path: filePath,
        downloadUrl
      };

      await firebase.db.collection(path).doc(tenantId).set({ icon: iconObj }, { merge: true });
    }
    if (documentLogo) {
      const storagePath = storageRefs.TENANT_FILES_REF(tenantId);
      const filePath = `${storagePath}/document_logo_${documentLogo.name}`;
      const ref = storageRef.child(filePath);
      const snap = await ref.put(documentLogo);
      const downloadUrl = await snap.ref.getDownloadURL();
      const documentLogoObj = {
        fileName: documentLogo.name,
        path: filePath,
        downloadUrl
      };

      await firebase.db.collection(path).doc(tenantId).set({ documentLogo: documentLogoObj }, { merge: true });
    }
  } else {
    throw new Error('Invalid tenant');
  }
};

export const getStorageData = () => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const res = await get(urls.tenant.getStorageData(tenantId));
  return dispatch({ type: SET_TENANT_STORAGE, data: res || null });
};

// Subscriptions
export const subscribeToCurrentTenant = () => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;

  if (tenantId) {
    return firebase
      .db
      .collection(collections.TENANTS_COLLECTION)
      .doc(tenantId)
      .onSnapshot({
        error: (e) => console.error(e),
        next: (doc) => dispatch(setCurrentTenant(doc.data())),
      });
  }
};

// Report

export const generateReport = () => async () => {
  const idToken = await firebase.auth.currentUser.getIdToken();

  try {
    const date = moment().local().format('DD-MM-YYYY');
    await getFileWithParameters(urls.tenant.internalReport(), idToken, `Global User Report ${date}`, 'xlsx');
  } catch (err) {
    throw new Error(err);
  }
};
