import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sortBy } from 'lodash';

import {
  FormsActions,
  FormsList,
  FormForm,
  FormStepForm,
  FormStepsList,
  FormBreadcrumbs,
} from '../../../components/forms';
import Input from '../../../components/shared/Input';
import Modal, { ConfirmationModal } from '../../../components/shared/modal';

import {
  subscribeToForms,
  createForm,
  updateForm,
  deleteForm,
  getSteps,
  createStep,
  updateStep,
  deleteStep,
  reorderSteps,
} from '../../../actions/formsActions';
import { getProducts } from '../../../actions/productsActions';

import { useLoading, useAlert } from '../../../hooks';

import { formSectionTypes } from '../../../constants/enums';

const FormsPage = () => {
  const dispatch = useDispatch();
  const tenant = useSelector((state) => state.tenant.currentTenant);
  const forms = useSelector((state) => state.forms.list);
  const products = useSelector((state) => state.products.list);
  const deletePermission = useSelector((state) => state.user?.currentUser?.deletePermission);

  const { loading, startLoading, stopLoading } = useLoading(false);
  const { loading: deleting, startLoading: startDeleting, stopLoading: stopDeleting } = useLoading(false);
  const { showAlert } = useAlert();

  const [search, setSearch] = useState('');

  const [selectedForm, selectForm] = useState(null);
  const [selectedStep, selectStep] = useState(null);
  const [steps, setSteps] = useState([]);

  const [formModal, toggleFormModal] = useState(false);
  const [stepModal, toggleStepModal] = useState(false);
  const [deleteFormModal, toggleDeleteFormModal] = useState(false);
  const [deleteStepModal, toggleDeleteStepModal] = useState(false);

  const [fetchingSteps, setFetchingSteps] = useState(false);

  useEffect(() => {
    const unsubscribe = dispatch(subscribeToForms());

    onLoad().catch((err) => console.error(err));

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (selectedForm) {
      fetchFormSteps(selectedForm?.id).catch((err) => console.error(err));
    } else {
      setSteps([]);
    }
  }, [selectedForm]);

  const fetchFormSteps = async (formId, withoutLoading) => {
    if (!withoutLoading) {
      setFetchingSteps(true);
    }

    try {
      const steps = await dispatch(getSteps(formId));
      setSteps(sortBy(steps, ['stepNumber']));
    } catch (err) {
      console.error(err);
    } finally {
      setFetchingSteps(false);
    }
  };

  const onLoad = async () => {
    try {
      await Promise.all([
        dispatch(getProducts()),
      ]);
    } catch (err) {
      console.error(err);
    }
  };

  const onAddClick = () => {
    if (selectedForm) {
      toggleStepModal(true);
    } else {
      toggleFormModal(true);
    }
  };

  const onSubmitForm = async (formObj, mode, logo, emails) => {
    startLoading();

    const doc = {
      name: formObj.name || '',
      subtitle: formObj.subtitle || '',
      products: [],
      allProducts: false,
      roles: formObj.roles || [],
      notificationEmails: [],
    };

    if (mode === 'update') {
      doc.active = !!formObj.active;
      doc.products = formObj.products || [];
      doc.allProducts = !!formObj.allProducts;
      doc.notificationEmails = emails || [];
    }

    try {
      if (mode === 'update') {
        await dispatch(updateForm(formObj.id, doc, logo));
        selectForm(formObj)
      } else {
        await dispatch(createForm(doc, logo));
      }
      stopLoading();
      toggleFormModal(false);
      showAlert('success', `Form has been successfully ${mode === 'update' ? 'updated' : 'created'}`);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onDeleteForm = async () => {
    startDeleting();

    try {
      await Promise.all(steps.map((step) => dispatch(deleteStep(selectedForm.id, step.id))));
      await dispatch(deleteForm(selectedForm.id));
      toggleDeleteFormModal(false);
      selectForm(null);
      selectStep(null);
      showAlert('success', 'Form has been successfully deleted');
    } catch (err) {
      console.error(err);
    } finally {
      stopDeleting();
    }
  };

  // Steps

  const onAddStep = async (formObj) => {
    startLoading();

    const stepObj = {
      stepNumber: steps?.length + 1,
      title: formObj.title || '',
      subtitle: formObj.type !== formSectionTypes.DESCRIPTION.value ? (formObj.subtitle || '') : '',
      isRequired: formObj.type !== formSectionTypes.DESCRIPTION ? (formObj.isRequired || false) : false,
      type: formObj.type || formSectionTypes.TEXT.value,
      options: [],
      dropdownValues: formObj.dropdownValues && formObj.type === formSectionTypes.DROPDOWN.value ? formObj.dropdownValues : null,
      isMulti: formObj.type === formSectionTypes.DROPDOWN.value || formObj.type === formSectionTypes.CUSTOM ? !!formObj.isMulti : false,
      description: formObj.type === formSectionTypes.DESCRIPTION.value ? formObj.description : '',
    };

    try {
      await dispatch(createStep(selectedForm?.id, stepObj));
      await fetchFormSteps(selectedForm?.id, true);
      stopLoading();
      toggleStepModal(false);
      showAlert('success', 'Field has been successfully created');
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onUpdateStep = async (formObj) => {
    startLoading();

    const stepObj = {
      active: !!formObj.active,
      title: formObj.title || '',
      subtitle: formObj.type !== formSectionTypes.DESCRIPTION.value ? (formObj.subtitle || '') : '',
      isRequired: formObj.type !== formSectionTypes.DESCRIPTION.value ? (formObj.isRequired || false) : false,
      type: formObj.type || formSectionTypes.TEXT.value,
      options: formObj.options || [],
      dropdownValues: formObj.dropdownValues && formObj.type === formSectionTypes.DROPDOWN.value ? formObj.dropdownValues : null,
      isMulti: formObj.type === formSectionTypes.DROPDOWN.value || formObj.type === formSectionTypes.CUSTOM ? !!formObj.isMulti : false,
      description: formObj.type === formSectionTypes.DESCRIPTION.value ? formObj.description : '',
    };

    try {
      await dispatch(updateStep(selectedForm?.id, formObj.id, stepObj));
      await fetchFormSteps(selectedForm?.id, true);
      selectStep({
        ...selectedStep,
        ...stepObj,
      });
      stopLoading();
      showAlert('success', 'Field has been successfully updated');
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onDeleteStep = async () => {
    startDeleting();

    try {
      await dispatch(deleteStep(selectedForm.id, selectedStep.id));
      await Promise.all(
        steps
          .filter((step) => step.stepNumber > selectedStep.stepNumber)
          .map((step) => dispatch(updateStep(selectedForm.id, step.id, { stepNumber: step.stepNumber - 1 })))
      );
      await fetchFormSteps(selectedForm.id, true);
      toggleDeleteStepModal(false);
      selectStep(null);
      showAlert('success', 'Field has been successfully deleted');
    } catch (err) {
      console.error(err);
    } finally {
      stopDeleting();
    }
  };

  const onDeleteClick = () => {
    if (selectedStep) {
      return toggleDeleteStepModal(true);
    }

    if (selectedForm) {
      return toggleDeleteFormModal(true);
    }
  };

  const renderUpdateForm = () => {
    if (selectedStep) {
      return (
        <FormStepForm
          initialValues={selectedStep}
          onSubmit={onUpdateStep}
          mode='update'
          buttonText='Save Changes'
          loading={loading}
        />
      );
    }

    if (selectedForm) {
      return (
        <FormForm
          initialValues={{ ...selectedForm, logoName: selectedForm.logo ? selectedForm.logo.fileName : '' }}
          onSubmit={onSubmitForm}
          mode='update'
          loading={loading}
          products={products}
        />
      );
    }

    return null;
  };

  const onReorder = async (steps) => {
    dispatch(reorderSteps(selectedForm.id, steps));
    setSteps(steps);
  };

  return (
    <div className='settings-cmp__main flex-1'>
      <span className='settings-title'>Forms</span>
      <div className='settings-cmp__body flex-1'>
        <div className='settings-block__left'>
          <FormsActions
            onAddClick={onAddClick}
            onDeleteClick={onDeleteClick}
            selectedForm={selectedForm}
            deletePermission={deletePermission}
          />
          <FormsList
            forms={forms}
            onClick={(form) => {
              selectForm(form);
              selectStep(null);
            }}
            tenantColor={tenant.colorPrimary}
            selectedForm={selectedForm}
          />
        </div>
        <div className='settings-block__middle'>
          <FormBreadcrumbs
            selectedForm={selectedForm}
            selectedStep={selectedStep}
            tenantColor={tenant.colorPrimary}
          />
          <FormStepsList
            loading={fetchingSteps}
            steps={steps}
            onClick={selectStep}
            selectedStep={selectedStep}
            tenantColor={tenant.colorPrimary}
            onReorder={onReorder}
          />
        </div>
        <div className='settings-block__right'>
          <Input
            type='search'
            placeholder='Search'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {renderUpdateForm()}
        </div>
      </div>

      <Modal
        open={!!formModal}
        onClose={() => toggleFormModal(null)}
      >
        <FormForm
          onSubmit={onSubmitForm}
          onClose={() => toggleFormModal(null)}
          mode='create'
          buttonText='Add Form'
          loading={loading}
        />
      </Modal>

      <Modal
        open={!!stepModal}
        onClose={() => toggleStepModal(null)}
      >
        <FormStepForm
          onSubmit={onAddStep}
          onClose={() => toggleStepModal(null)}
          mode='create'
          buttonText='Add'
          loading={loading}
        />
      </Modal>

      <ConfirmationModal
        open={!!deleteFormModal}
        onClose={() => toggleDeleteFormModal(false)}
        onSubmit={onDeleteForm}
        title='Are you sure you want to delete this form?'
        text='If you delete the form you will lose all submitted data across all cases related to this form. This process cannot be undone.'
        submitText='Delete'
        loading={deleting}
      />

      <ConfirmationModal
        open={!!deleteStepModal}
        onClose={() => toggleDeleteStepModal(false)}
        onSubmit={onDeleteStep}
        title='Are you sure you want to delete this field?'
        submitText='Delete'
        loading={deleting}
      />
    </div>
  );
};

export default FormsPage;
