import React, { useState, useMemo } from 'react';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import Select from '../../shared/Select';
import Button from '../../shared/Button';
import ModalSelect from '../../shared/modal-select/ModalSelect';

import { targetOptions, rangeOptions } from '../../../constants/setTurnsReporting';

import './styles.scss';

const SetTurnsForm = (props) => {
  const {
    kits = [],
    kit,
    setKit,
    target,
    setTarget,
    range,
    setRange,
    onSubmit,
    loading,
  } = props;

  const [isOpen, toggleModal] = useState(false);

  const kitId = useMemo(() => {
    const _kit = kits?.find((k) => k.id === kit);
    return _kit?.kitId || '';
  }, [kit, kits]);

  return (
    <div className='set-turns-form__container'>
      <div className='set-turns-form__field'>
        <div>Kit ID</div>
        <div className='set-turns-form__select-box' onClick={() => toggleModal(true)}>
          {kitId || 'Select'}
          <KeyboardArrowDownIcon style={{ fill: '#c6c8c9' }} />
        </div>

        <ModalSelect
          title='Select Kit'
          listTitle='Kits'
          options={kits}
          onChange={(value) => {
            if (value && value.length) {
              setKit(value[0]);
            } else {
              setKit(null);
            }
          }}
          selected={[kit]}
          onClose={() => toggleModal(false)}
          isOpen={isOpen}
          singleSelect={true}
        />
      </div>
      <div className='set-turns-form__field'>
        <div>Performance Target (turns/month)</div>
        <Select
          value={target}
          onChange={setTarget}
          options={targetOptions}
        />
      </div>
      <div className='set-turns-form__field'>
        <div>Date Range</div>
        <Select
          value={range}
          onChange={setRange}
          options={rangeOptions}
        />
      </div>

      <div className='set-turns-form__button'>
        <Button
          onClick={onSubmit}
          disabled={loading || !kit}
          loading={loading}
          text='Run Report'
        />
      </div>
    </div>
  )
};

export default SetTurnsForm;
