import React from 'react';

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import ArrowIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    background: '#ffffff',
    border: 'none',
    borderRadius: '16px',
    outline: 'none',
    minWidth: 120,
    height: '60px',
    '&:active': {
      outline: 'none',
      border: 'none',
      borderRadius: '16px',
    },
    '&:focus': {
      outline: 'none !important',
      border: 'none',
      borderRadius: '16px',
    },
    '&:before': {
      outline: 'none',
      border: 'none',
      borderRadius: '16px',
    }
  },
  select: {
    padding: '0 !important',
    // zIndex: 30,
  },
  endAdornmentContainer: {
    position: 'absolute',
    right: '28px',
    top: 'calc(50% - 2px)',
  },
  endAdornment: {
    color: '#a7a7a7',
    cursor: 'pointer',
  }
}));

const StyledSelect = withStyles((theme) => ({
  root: {
    paddingLeft: '16px !important',
    paddingRight: '0 !important',
    height: '100% !important',
    lineHeight: '58px !important',
    zIndex: '10',
    '&.Mui-disabled': {
      color: '#c6c8c9 !important'
    }
  },
  icon: {
    color: '#c6c8c9',
  },
  filled: {
    paddingRight: '24px !important',
    fontWeight: 'bold !important',
    transition: 'none !important',
    background: 'none !important',
    outline: 'none',
    '&:active': {
      outline: 'none',
      borderRadius: '16px',
    },
    '&:focus': {
      outline: 'none !important',
      borderRadius: '16px',
    },
    '&:before': {
      outline: 'none',
      borderRadius: '16px',
    }
  },
  selectMenu: {
    borderRadius: '16px',
  }
}))(Select);

const StyledMenuItem = withStyles((theme) => ({
  root: {
    paddingTop: '12px',
    paddingBottom: '12px',
    width: props => props?.width ? props.width : '500px'
  }
}))(MenuItem);

const SelectComponent = (props) => {
  const {
    value,
    onChange,
    options = [],
    label,
    defaultLabel,
    name,
    width,
    endAdornment = '',
    disabled = false,
  } = props;

  const classes = useStyles();

  return (
    <FormControl variant='filled' className={classes.formControl} style={{ maxWidth: width || '100%' }}>
      <StyledSelect
        labelId='select-id'
        id='select-id'
        name={name}
        className={classes.select}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        label={label}
        displayEmpty
        disabled={disabled}
        IconComponent={ArrowIcon}
        endAdornment={endAdornment && (
          <InputAdornment position='end' className={classes.endAdornmentContainer}>
            <span className={classes.endAdornment}>{endAdornment}</span>
          </InputAdornment>
        )}
      >
        {defaultLabel && (<StyledMenuItem value=''>{defaultLabel}</StyledMenuItem>)}
        {options.map((option) => (
          <StyledMenuItem key={option.value} width={width} value={option.value}>
            { option.icon ? (
              <div className='d-flex'>
                {option.icon}
                <span className='m-l-md'>{option.label}</span>
              </div>
            ) : option.label}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default SelectComponent;
