import React, { memo } from 'react';

import logo from '../../assets/case-loading-logo.gif';

import './load-screen.scss';

const LoadScreen = () => {
  return <div className='load-screen'>
    <img src={logo} alt='loading-logo' />
  </div>;
};

export default memo(LoadScreen);
