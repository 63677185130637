import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function CopyIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none"
          d="M11.8511 11.3133C11.901 10.3533 11.9271 9.36311 11.9271 8.35105C11.9271 7.92193 11.9224 7.49674 11.9132 7.07612C11.9052 6.70944 11.7941 6.35166 11.5869 6.04905C10.9923 5.18087 10.4994 4.60961 9.74365 3.9615C9.42114 3.68493 9.00931 3.53626 8.58452 3.52853C8.34565 3.52417 8.0976 3.52197 7.83648 3.52197C6.99736 3.52197 6.29313 3.54471 5.59533 3.58841C4.63172 3.64875 3.87201 4.42462 3.82188 5.38882C3.77195 6.34885 3.74585 7.339 3.74585 8.35105C3.74585 9.36311 3.77195 10.3533 3.82188 11.3133C3.87201 12.2775 4.63172 13.0534 5.59533 13.1137C6.29313 13.1574 6.99736 13.1801 7.83648 13.1801C8.67559 13.1801 9.37982 13.1574 10.0776 13.1137C11.0412 13.0534 11.8009 12.2775 11.8511 11.3133Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M1.2854 8.82507C1.28849 9.12774 1.29051 9.43256 1.29051 9.73784" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M1.26845 4.90015C1.25131 5.46024 1.25151 6.07216 1.25788 6.71603" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M1.50513 2.77821C1.59433 2.44427 1.7135 2.18199 1.87174 2.0076C2.03014 1.83302 2.25124 1.68856 2.52143 1.5697" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M4.33496 1.15486C4.82921 1.10236 5.36154 1.07663 5.90646 1.06921" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M7.80469 1.10376C8.07194 1.11543 8.33508 1.1292 8.591 1.14403" />
  </SvgIcon>
}
