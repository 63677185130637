import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import logoSrc from '../../assets/microsoft_logo.png';

const StyledOutlinedButton = withStyles(theme => ({
  root: {
    height: (props) => '60px',
    width: '215px',
    borderRadius: '16px',
    border: '2px solid #EEEEEE',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 'bold',
    background: '#ffffff',
    color: '#737373',
    '&:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      borderColor: 'rgba(0, 0, 0, 0.12)',
      color: 'rgba(0, 0, 0, 0.26)'
    },
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  }
}))(MuiButton);

const MicrosoftAuthButton = (props) => {
  const {
    onClick,
    disabled,
    loading,
  } = props;

  return (
    <StyledOutlinedButton
      onClick={onClick}
      disabled={disabled}
    >
      { loading ? <CircularProgress size={24} /> : (
        <div className='d-flex'>
          <img src={logoSrc} alt="microsoft-logo" height={24} width={24} style={{ marginRight: '8px' }} />
          Sign in with Microsoft
        </div>
      )}
    </StyledOutlinedButton>
  )
};

export default MicrosoftAuthButton;