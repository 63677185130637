import React from 'react';

import AddButton from '../../shared/AddButton';

const CaseUsageSetRow = (props) => {
  const { code, description, type, outbound, qty, onChange, tenantColor } = props;

  return (
    <div className='d-flex m-b-md align-start'>
      <div style={{ flex: 0.2 }} className='font-bold'>{code}</div>
      <div style={{ flex: 0.45 }} className='font-bold'>{description}</div>
      <div style={{ flex: 0.1 }} className='font-bold'>{type}</div>
      <div style={{ flex: 0.1, color: tenantColor }} className='font-bold text-center'>{outbound}</div>
      <div
        style={{ flex: 0.15 }}
        className='font-bold d-flex flex-end'
      >
        <AddButton
          variant='remove'
          disabled={qty === 0}
          onClick={() => onChange(qty - 1)}
        />
        <div className='m-l-md m-r-md text-center' style={{ width: 12 }}>{qty}</div>
        <AddButton onClick={() => onChange(qty +1)} />
      </div>
    </div>
  );
};

export default CaseUsageSetRow;
