import React, { memo } from 'react';

import { getStatusIcon, getStatusLabel } from '../../utils/cases';

import { statusColors } from '../../constants/cases';

const CasesNumberItem = ({ status, number = 0, isCompleted, isCompletedLoaded, className }) => {
  return (
    <div className={`cases-number-item cases-number-item--${status.toLowerCase()}${className ? ` ${className}` : ''}`}>
      <div />
      <div className='cases-number-item__number'>{isCompleted && !isCompletedLoaded ? '...' : number}</div>
      <div className='cases-number-item__text'>Number of cases</div>

      <div className='cases-number-item__status' style={{ background: statusColors[status] }}>
        <div className='d-flex direction-column space-between'>
          {getStatusIcon(status)}
          <div className='case-status-label'>
            {getStatusLabel(status)}
          </div>
        </div>
      </div>
    </div>
  )
};

export default memo(CasesNumberItem);
