import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ProductTriggersTable, ProductTriggersFilters, ProductTriggerForm } from '../../../../components/product-triggers';
import Input from '../../../../components/shared/Input';
import Modal, { ConfirmationModal } from '../../../../components/shared/modal';
import Alert from '../../../../components/shared/Alert';

import { getProducts } from '../../../../actions/productsActions';

import { withFirebase, collections } from '../../../../firebase';

import { filterProductTriggers } from '../../../../utils/table';

import { statusOptions } from '../../../../constants/enums';

const statusFilterOptions = [
  { label: 'Active', value: statusOptions.active },
  { label: 'Deactivated', value: statusOptions.deactivated }
];

const UsageTriggersPage = ({ firebase }) => {
  const dispatch = useDispatch();

  const products = useSelector((state) => state.products.list);
  const tenantId = useSelector((state) => state.tenant.currentTenant.id);

  const [triggers, setTriggers] = useState([]);

  const [isModalCreateOpen, toggleModalCreate] = useState(false);
  const [isModalDeleteOpen, toggleModalDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const [successMessage, setSuccessMessage] = useState(null);

  const [selectedTrigger, setSelectedTrigger] = useState(null);
  const [checkedTriggers, setCheckedTriggers] = useState([]);

  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  useEffect(() => {
    const unsubscribe = subscribeToProductTriggers();

    onLoad().catch((err) => console.error(err));

    return () => {
      unsubscribe();
    };
  }, []);

  const subscribeToProductTriggers = () => (
    firebase
      .db
      .collection(collections.USAGE_TRIGGERS_COLLECTION(tenantId))
      .onSnapshot({
        error: (e) => console.error(e),
        next: (querySnapshot) => {
          let list = [];
          querySnapshot.forEach((documentSnapshot) => {
            const id = documentSnapshot.id;
            list = [...list, {
              ...documentSnapshot.data(),
              id,
            }];
          });
          setTriggers(list);
        },
      })
  );

  const onLoad = async () => {
    await dispatch(getProducts());
  };

  const onSelect = (trigger) => {
    setSelectedTrigger(trigger);
  };

  // Create trigger
  const onCreate = async (formObj, emails) => {
    setLoading(true);
    const doc = {
      active: true,
      name: formObj.name,
      products: formObj.products || [],
      emails: emails || [],
      allProducts: false,
      onApproveUsage: !!formObj.onApproveUsage,
      onAllResolved: !!formObj.onAllResolved,
    };

    try {
      await firebase.db.collection(collections.USAGE_TRIGGERS_COLLECTION(tenantId)).add(doc);
      setLoading(false);
      toggleModalCreate(false);
      setSuccessMessage('Trigger has been successfully created');
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Update trigger
  const onUpdate = async (formObj, emails) => {
    setLoading(true);
    const doc = {
      active: formObj.active,
      name: formObj.name,
      products: formObj.products || [],
      emails: emails || [],
      allProducts: !!formObj.allProducts,
      onApproveUsage: !!formObj.onApproveUsage,
      onAllResolved: !!formObj.onAllResolved,
    };

    try {
      await firebase
        .db
        .collection(collections.USAGE_TRIGGERS_COLLECTION(tenantId))
        .doc(formObj.id)
        .set(doc, { merge: true });
      setLoading(false);
      setSuccessMessage('Trigger has been successfully updated');
      setSelectedTrigger({ ...doc, id: formObj.id });
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Delete checked triggers
  const onDelete = async () => {
    const batch = firebase.db.batch();

    if (tenantId) {
      checkedTriggers.forEach((triggerId) => {
        const ref = firebase.db.collection(collections.USAGE_TRIGGERS_COLLECTION(tenantId)).doc(triggerId);
        batch.delete(ref);
      });

      await batch.commit();
    }

    toggleModalDelete(false);
    setCheckedTriggers([]);
    setSelectedTrigger(null);
  };

  const handleDeleteClick = () => {
    if (checkedTriggers.length) {
      toggleModalDelete(true);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const list = useMemo(() => {
    return triggers.filter((trigger) => filterProductTriggers(trigger, search, statusFilter));
  }, [triggers, search, statusFilter]);

  const handleCheck = (triggerId) => {
    const checked = checkedTriggers.slice();

    if (checked.includes(triggerId)) {
      const index = checked.indexOf(triggerId);

      checked.splice(index, 1);
      setCheckedTriggers(checked);

      return;
    }

    checked.push(triggerId);
    setCheckedTriggers(checked);
  };

  const handleCheckAll = () => {
    const checked = checkedTriggers.length === list.length;
    const temp = [];

    if (!checked) {
      list.forEach((trigger) => {
        temp.push(trigger.id);
      });

      setCheckedTriggers(temp);
      return;
    }

    setCheckedTriggers(temp);
  };

  return (
    <div className='settings-cmp__main'>
      <span className='settings-title'>Usage</span>
      <div className='settings-cmp__body'>
        <div className='filters-container'>
          <ProductTriggersFilters
            onAddClick={() => toggleModalCreate(true)}
            onDelete={handleDeleteClick}
            status={statusFilter}
            setStatus={setStatusFilter}
            statuses={statusFilterOptions}
          />
          <ProductTriggersTable
            onSelect={onSelect}
            list={list}
            selected={selectedTrigger ? selectedTrigger.id : null}
            handleCheck={handleCheck}
            checked={checkedTriggers}
            handleCheckAll={handleCheckAll}
            products={products}
          />
        </div>
        { triggers && !!triggers.length && (
          <div className='form-container'>
            <Input
              type='search'
              placeholder='Search Email'
              value={search}
              onChange={handleSearch}
            />
            { selectedTrigger && (
              <ProductTriggerForm
                initialValues={selectedTrigger}
                buttonText='Save Changes'
                onSubmit={onUpdate}
                loading={loading}
                mode='update'
                products={products}
              />
            )}
          </div>
        )}
      </div>

      <ConfirmationModal
        open={isModalDeleteOpen}
        onClose={() => toggleModalDelete(false)}
        onSubmit={onDelete}
        title='Are you sure you want to delete these triggers?'
        submitText='Delete'
      />

      <Modal
        open={isModalCreateOpen}
        onClose={() => toggleModalCreate(false)}
      >
        <ProductTriggerForm
          buttonText='Add Trigger'
          onSubmit={onCreate}
          loading={loading}
          mode='create'
          onClose={() => toggleModalCreate(false)}
        />
      </Modal>

      <Alert
        variant='success'
        message={successMessage}
        open={!!successMessage}
        onClose={() => setSuccessMessage(null)}
      />
    </div>
  );
};

export default withFirebase(UsageTriggersPage);
