import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';

import { NotificationItem } from '../notifications';

import { notificationTypes } from '../../constants/enums';
import routes from '../../constants/routes';

import './home.scss';

const {
  caseBooked,
  caseDateUpdated,
  caseNoteAdded,
  caseOverdue,
  caseRequested,
  caseUsageSubmitted,
  countAdded,
  countCompleted,
  preferenceAdded,
  taskDue,
  transferRequested,
  transferAccepted,
  transferDeclined,
  caseCancelled,
  formSubmitted,
} = notificationTypes;

const NotificationsBlock = (props) => {
  const { notifications, tenantColor } = props;

  const history = useHistory();

  const list = useMemo(() => {
    const types = [
      caseOverdue, caseRequested, caseBooked, caseUsageSubmitted, caseCancelled,
      caseDateUpdated, transferDeclined, transferAccepted, transferRequested, formSubmitted,
    ];
    return notifications.filter((notification) => types.includes(notification.type)).slice(0, 5);
  }, [notifications]);

  // Refactor

  const onNotificationClick = (type, args) => {
    if ([
      caseOverdue, caseRequested, caseBooked, caseDateUpdated, caseNoteAdded, caseUsageSubmitted,
      transferRequested, transferAccepted, transferDeclined
    ].includes(type)) {
      const { caseId } = args;
      if (caseId) {
        history.push(`${routes.CASES}/${caseId}`);
      }
    } else if ([countAdded, countCompleted].includes(type)) {
      const { countId } = args;
      if (countId) {
        history.push(`${routes.INVENTORY}/${countId}`);
      }
    } else if (type === taskDue) {
      history.push(routes.TOOLS_TASKS);
    } else if (type === preferenceAdded) {
      history.push(routes.TOOLS_SURGEONS);
    } else if (type === formSubmitted) {
      const { formId } = args;
      history.push(`${routes.FORMS}/${formId}`);
    }
  };

  return (
    <div className='home-notifications__container'>
      <div className='d-flex align-start direction-column width-100'>
        <div className='d-flex m-b-lg space-between'>
          <div className='d-flex'>
            <NotificationsOutlinedIcon />
            <div className='font-size-bg font-bold m-l-md'>
              Latest Activity
            </div>
          </div>
        </div>

        <div className='d-flex direction-column width-100'>
          {list.map((notification) => (
            <NotificationItem
              key={notification.id}
              tenantColor={tenantColor}
              onClick={onNotificationClick}
              {...notification}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default NotificationsBlock;
