import { orderBy } from 'lodash';

import { GET_PRODUCTS, SET_PRODUCTS } from './actionTypes';

import firebase, { collections } from '../firebase';

export const setProducts = products => ({ type: SET_PRODUCTS, products });

export const getProducts = (tenant, withReducer) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = tenant || state?.tenant?.currentTenant?.id;
  const path = collections.PRODUCTS_COLLECTION(tenantId);

  dispatch({ type: GET_PRODUCTS });

  const snapshot = await firebase.db.collection(path).get();
  const products = snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));

  if (tenant && !withReducer) {
    return orderBy(products, 'name', 'asc');
  }

  return dispatch(setProducts(orderBy(products, 'name', 'asc')));
};

export const createProduct = (productData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.PRODUCTS_COLLECTION(tenantId);

  const doc = {
    ...productData,
    active: true,
    procedures: productData.procedures || [],
  };

  if (tenantId) {
    await firebase.db.collection(path).add(doc);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateProduct = (productId, productData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.PRODUCTS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(productId).set(productData, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteProduct = (productId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.PRODUCTS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(productId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const bulkDeleteProducts = (products) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.PRODUCTS_COLLECTION(tenantId);
  const batch = firebase.db.batch();

  if (tenantId) {
    products.forEach((productId) => {
      const ref = firebase.db.collection(path).doc(productId);
      batch.delete(ref);
    });

    await batch.commit();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const uploadProducts = (products) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.PRODUCTS_COLLECTION(tenantId);

  if (tenantId) {
    const promises = [];

    products.forEach((product) => {
      const name = product[0] || '';
      const description = product[1] || '';

      const doc = {
        name,
        description,
        active: true,
        procedures: [],
      };

      promises.push(firebase.db.collection(path).add(doc));
    });

    await Promise.all(promises);
  } else {
    throw new Error('Invalid tenant');
  }
};

// Subscriptions
export const subscribeToProducts = () => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.PRODUCTS_COLLECTION(tenantId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let products = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          products = [...products, {
            ...documentSnapshot.data(),
            id,
          }];
        });

        return dispatch(setProducts(orderBy(products, 'name', 'asc')));
      },
    });
};
