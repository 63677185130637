import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';

import DeleteIcon from '../../../assets/icons/DeleteIcon';

import Input from '../Input';
import Checkbox from '../Checkbox';
import Button from '../Button';
import Modal from '../modal/Modal';
import CSVUpload from '../CSVUpload';

import { addProcedureOption, deleteProcedureOption, uploadProcedureOptions } from '../../../actions/proceduresActions';

import './modal-select.scss';

const ProcedureOptionsModal = (props) => {
  const {
    title,
    procedureOptions,
    onChange,
    selected,
    onClose,
    isOpen,
    addProcedureOption,
    deleteProcedureOption,
    uploadProcedureOptions,
    tenantColor,
  } = props;

  const [optionsIds, setOptionsIds] = useState(selected);
  const [newOption, setNewOption] = useState('');
  const [search] = useState('');

  useEffect(() => {
    setOptionsIds(selected);
  }, [selected]);

  const handleNewOptions = (optionId) => {
    const temp = optionsIds.slice();
    if (temp.includes(optionId)) {
      const index = temp.indexOf(optionId);

      temp.splice(index, 1);
      setOptionsIds(temp);
      return;
    }

    temp.push(optionId);
    setOptionsIds(temp);
  };

  const handleAdd = () => {
    addProcedureOption(newOption);
    setNewOption('');
  };

  const handleOnDrop = (file) => {
    const rows = file
      .filter((item) => item.data && item.data.length && !!item.data[0])
      .map((item) => item.data.map((i) => i.trim()));

    if (rows && rows?.length) {
      uploadProcedureOptions(rows).catch(console.error);
    }
  }

  const handleOnError = (err) => {
    console.error(err)
  }

  const handleOnRemove = () => {
  };

  const list = useMemo(() => {
    return procedureOptions.filter((item) => item?.name.toLowerCase().includes(search.toLowerCase()))
  }, [search, procedureOptions]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <div className='form__container'>
        <div className='form__body'>

          <div className='form__subtitle'>
            {title}
          </div>

          <div className='d-flex'>
            <Input
              type='text'
              placeholder='New Option'
              value={newOption}
              onChange={(e) => setNewOption(e.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  handleAdd();
                }
              }}
            />
            <div className='m-b-lg m-l-md'>
              <Button
                type='submit'
                text='Add'
                onClick={handleAdd}
                width={100}
                disabled={!newOption.length}
              />
            </div>
          </div>

          <CSVUpload
            onDrop={handleOnDrop}
            onError={handleOnError}
            onRemoveFile={handleOnRemove}
            tenantColor={tenantColor}
          />

          <div className='field__title'>Options</div>
          <div className='m-b-lg form__select-users' style={{ maxHeight: 300 }}>
            {list.length ? (
              list.map((option) => (
                <div className='md-user-info' key={option.id}>
                  <div>
                    <Checkbox
                      input={{
                        onClick: () => {handleNewOptions(option.id)},
                        checked: optionsIds.indexOf(option.id) !== -1
                      }}
                      fontSize='small'
                      label={option.name}
                    />
                    <span>{option.email}</span>
                  </div>
                  <div className='md-user-info__delete' onClick={() => deleteProcedureOption(option.id)}>
                    <DeleteIcon fontSize='small' color="#d32f2f" />
                  </div>
                </div>
              ))
            ) : (
              <div className='text-center secondary p-md'>
                No Options
              </div>
            )}
          </div>

          <div className='form__actions'>
            <Button
              type='submit'
              text='Submit'
              onClick={() => {
                onChange(optionsIds);
                onClose()
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    procedureOptions: state.procedures.procedureOptions,
    tenantColor: state.tenant.currentTenant.colorPrimary
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addProcedureOption: (option) => dispatch(addProcedureOption(option)),
    deleteProcedureOption: (optionId) => dispatch(deleteProcedureOption(optionId)),
    uploadProcedureOptions: (options) => dispatch(uploadProcedureOptions(options)),
  };
};

ProcedureOptionsModal.propTypes = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcedureOptionsModal);
