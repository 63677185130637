import React, { useState, useRef } from 'react';

import MuiButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { withStyles } from '@material-ui/core/styles';

const StyledButton = withStyles((theme) => ({
  root: {
    minWidth: props => props?.width ? props.width : '200px',
    height: '60px',
    borderRadius: '16px',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 'bold',
    background: props =>  props?.background ? props.background : theme.palette.primary.main,
    color: props => props?.color ? props.color : theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: props =>  props?.background ? props.background : theme.palette.primary.main,
    },
    '&:active': {
      backgroundColor: props =>  props?.background ? props.background : theme.palette.primary.main,
    },
    '&:focus': {
      backgroundColor: props =>  props?.background ? props.background : theme.palette.primary.main,
    },
    '&:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      borderColor: 'rgba(0, 0, 0, 0.12)',
      color: 'rgba(0, 0, 0, 0.26)'
    }
  }
}))(MuiButton);

const StyledMenuItem = withStyles((theme) => ({
  root: {
    paddingTop: '12px',
    paddingBottom: '12px',
  }
}))(MenuItem);

const DropdownButton = (props) => {
  const {
    text,
    background,
    color,
    onClick,
    disabled,
    loading,
    children,
    width,
    options,
  } = props;

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleMenuItemClick = (option) => {
    onClick(option);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <StyledButton
        ref={anchorRef}
        background={background}
        disabled={disabled}
        width={width}
        color={color}
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label='select merge strategy'
        aria-haspopup='menu'
        onClick={handleToggle}
      >
        { loading ? <CircularProgress size={24} /> : text}
        { children }
      </StyledButton>
      <Popper
        style={{ zIndex: '10', width: '200px', marginTop: '8px' }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu'>
                  {options.map((option) => (
                    <StyledMenuItem
                      key={option.id}
                      onClick={() => handleMenuItemClick(option)}
                    >
                      {option.icon}
                      <span className='m-l-md'>{option.label}</span>
                    </StyledMenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default DropdownButton;
