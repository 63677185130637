import React, { useCallback } from 'react';

import Table from '../shared/table/TableRemote';

const columns = [
  { title: '', field: 'caseStatus', type: 'caseStatus', sort: false },
  { title: 'case date', field: 'date', type: 'date', sort: true },
  { title: 'surgeon', field: 'surgeonName', type: 'default', sort: true },
  { title: 'hospital', field: 'hospitalName', type: 'default', sort: true },
  { title: 'state', field: 'hospitalState', type: 'default', sort: true },
  { title: 'patient ref', field: 'patientReference', type: 'default', sort: true },
  { title: 'procedure', field: 'procedureName', type: 'default', sort: true },
];

const reportColumns = [
  { title: '', field: 'caseStatus', type: 'caseStatus', sort: false },
  { title: 'case date', field: 'date', type: 'date', sort: true },
  { title: 'surgeon', field: 'surgeonName', type: 'default', sort: true, filter: true, filterTitle: 'Filter Surgeons' },
  {
    title: 'hospital',
    field: 'hospitalName',
    type: 'default',
    sort: true,
    filter: true,
    filterTitle: 'Filter Hospitals',
  },
  { title: 'state', field: 'hospitalState', type: 'default', sort: true },
  { title: 'created by', field: 'userName', type: 'default', sort: true },
  {
    title: 'procedure',
    field: 'procedureName',
    type: 'default',
    sort: true,
    filter: true,
    filterTitle: 'Filter Procedures',
  },
];

const CasesTable = (props) => {
  const {
    onSelectCase,
    cases,
    selectedCaseId,
    handleCheck,
    checkedCases,
    handleCheckAll,
    surgeons,
    hospitals,
    procedures,
    users = [],
    withoutCheck,
    rowColor,
    rowBorderColor,
    pagination,
    checkboxes,
    isReport,
    filter,
    filterOptions,
    onFilterChange,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
  } = props;

  const getSurgeonName = useCallback((surgeonId) => {
    const surgeon = surgeons?.find((surgeon) => surgeon.id === surgeonId);
    return surgeon ? `${surgeon.title} ${surgeon.firstName} ${surgeon.lastName}` : '';
  }, [surgeons]);

  const getHospitalName = useCallback((hospitalId) => {
    const hospital = hospitals?.find((hospital) => hospital.id === hospitalId);
    return hospital ? hospital.name : '';
  }, [hospitals]);

  const getHospitalState = useCallback((hospitalId) => {
    const hospital = hospitals?.find((hospital) => hospital.id === hospitalId);
    return hospital ? hospital.state : '';
  }, [hospitals]);

  const getProcedureName = useCallback((procedureId) => {
    const procedure = procedures?.find((procedure) => procedure.id === procedureId);
    return procedure ? procedure.name : '';
  }, [procedures]);

  const getUserName = useCallback((uid) => {
    const user = users?.find((u) => u.uid === uid);
    return user ? `${user.firstName} ${user.lastName}` : '';
  }, [users]);

  return (
    <div style={{ width: '100%' }}>
      <Table
        className="cases-table"
        rows={cases.map((item) => ({
          ...item,
          surgeonName: getSurgeonName(item.surgeon),
          hospitalName: getHospitalName(item.hospital),
          hospitalState: getHospitalState(item.hospital),
          procedureName: getProcedureName(item.procedure),
          caseStatus: {
            status: item.status,
            canceled: !item.active,
            kitVariant: item.kitVariant,
            additional: !!item.additionalKits?.length || !!item.additionalItems?.length,
          },
          userName: getUserName(item.createdBy),
        }))}
        columns={isReport ? reportColumns : columns}
        onRowClick={onSelectCase}
        selected={selectedCaseId}
        handleCheck={handleCheck}
        checkedcases={checkedCases}
        handleCheckAll={handleCheckAll}
        withoutCheck={withoutCheck}
        rowColor={rowColor}
        rowBorderColor={rowBorderColor}
        pagination={pagination}
        checkboxes={checkboxes}
        currentFilter={filter}
        filterOptions={filterOptions}
        onFilterChange={onFilterChange}
        noCheckAll
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
};

export default CasesTable;
