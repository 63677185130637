import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function CustomersIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none"
          d="M3.57666 3.50578C3.59082 3.02243 3.6095 2.53529 3.6327 2.04195C3.65503 1.56723 4.01146 1.17743 4.48512 1.13855C6.51056 0.97229 10.0682 0.97229 12.0936 1.13855C12.5673 1.17743 12.9237 1.56733 12.946 2.04205C13.0651 4.57504 13.0651 6.94467 12.946 9.47765C12.9237 9.95237 12.566 10.3424 12.0923 10.3813C11.6616 10.4166 11.1618 10.4444 10.6221 10.4647" />
    <path stroke={color || defaultColor} fill="none"
          d="M1.25095 11.9297C1.27328 12.4044 1.62972 12.7943 2.10337 12.8332C4.12882 12.9994 7.68642 12.9994 9.71187 12.8332C10.1855 12.7943 10.542 12.4044 10.5643 11.9297C10.6834 9.3967 10.6834 7.02706 10.5643 4.49408C10.542 4.01936 10.1855 3.62946 9.71187 3.59058C7.68642 3.42432 4.12882 3.42432 2.10337 3.59058C1.62972 3.62946 1.27328 4.01936 1.25095 4.49408C1.13184 7.02706 1.13184 9.3967 1.25095 11.9297Z" />
    <path stroke={color || defaultColor} fill="none"
          d="M8.85108 12.92V12.0316C8.85108 10.9596 7.69641 10.1465 5.90772 10.1465C4.11902 10.1465 2.96436 10.9596 2.96436 12.0316V12.92" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M5.90741 8.81848C6.80646 8.81848 7.53528 8.08966 7.53528 7.19061C7.53528 6.29157 6.80646 5.56274 5.90741 5.56274C5.00836 5.56274 4.27954 6.29157 4.27954 7.19061C4.27954 8.08966 5.00836 8.81848 5.90741 8.81848Z" />
  </SvgIcon>
}
