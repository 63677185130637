import React from 'react';
import { useHistory } from 'react-router-dom';

import CaseIcon from '../../assets/icons/CaseIcon';
import CustomersIcon from '../../assets/icons/CustomersIcon';
import CountIcon from '../../assets/icons/CountIcon';

import NotificationItem from './NotificationItem';

import { notificationTypes } from '../../constants/enums';
import routes from '../../constants/routes';

import './notifications.scss';

const {
  caseBooked,
  caseDateUpdated,
  caseNoteAdded,
  caseOverdue,
  caseRequested,
  caseUsageSubmitted,
  countAdded,
  countCompleted,
  preferenceAdded,
  taskDue,
  transferRequested,
  transferAccepted,
  transferDeclined,
  formSubmitted,
  flowStepCompleted,
} = notificationTypes;

const types = {
  CASES: 'cases',
  CUSTOMERS: 'customers',
  INVENTORY: 'inventory',
};

const NotificationsBlock = (props) => {
  const { notifications, tenantColor, type } = props;

  const history = useHistory();

  const onNotificationClick = (type, args) => {
    if ([
      caseOverdue, caseRequested, caseBooked, caseDateUpdated, caseNoteAdded, caseUsageSubmitted,
      transferRequested, transferAccepted, transferDeclined, flowStepCompleted
    ].includes(type)) {
      const { caseId } = args;
      if (caseId) {
        history.push(`${routes.CASES}/${caseId}`);
      }
    } else if ([countAdded, countCompleted].includes(type)) {
      const { countId } = args;
      if (countId) {
        history.push(`${routes.INVENTORY}/${countId}`);
      }
    } else if (type === taskDue) {
      history.push(routes.TOOLS_TASKS);
    } else if (type === preferenceAdded) {
      history.push(routes.TOOLS_SURGEONS);
    } else if (type === formSubmitted) {
      const { formId } = args;
      history.push(`${routes.FORMS}/${formId}`);
    }
  };

  const getIcon = () => {
    switch (type) {
      case types.CASES:
        return <CaseIcon />;
      case types.CUSTOMERS:
        return <CustomersIcon />;
      case types.INVENTORY:
        return <CountIcon />;
      default:
        return null;
    }
  };

  const getTitle = () => {
    switch (type) {
      case types.CASES:
        return 'Cases';
      case types.CUSTOMERS:
        return 'Customers';
      case types.INVENTORY:
        return 'Counts';
      default:
        return '';
    }
  };

  return (
    <div className='notifications__container'>
      <div className='d-flex align-start direction-column width-100'>
        <div className='d-flex m-b-lg space-between'>
          <div className='d-flex'>
            {getIcon()}
            <div className='font-size-bg font-bold m-l-md'>
              {getTitle()}
            </div>
          </div>
        </div>

        <div className='d-flex direction-column width-100'>
          {notifications.map((notification) => (
            <NotificationItem
              key={notification.id}
              tenantColor={tenantColor}
              onClick={onNotificationClick}
              {...notification}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default NotificationsBlock;
