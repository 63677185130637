const userRoles = {
  ADMIN: { name: 'ADMIN', label: 'Admin', tenant: true },
  SALES_REP: { name: 'SALES_REP', label: 'Sales Rep', tenant: true },
  EXTERNAL_SALES_REP: { name: 'EXTERNAL_SALES_REP', label: 'External Sales Rep', tenant: false },
  SURGEON: { name: 'SURGEON', label: 'Surgeon', tenant: false },
  PRACTICE_MANAGER: { name: 'PRACTICE_MANAGER', label: 'Practice Manager', tenant: false },
  OPERATIONS: { name: 'OPERATIONS', label: 'Operations', tenant: true },
  CUSTOMER_SERVICE: { name: 'CUSTOMER_SERVICE', label: 'Customer Service', tenant: true },
  LOGISTICS: { name: 'LOGISTICS', label: 'Logistics', tenant: true },
  SALES_MANAGER: { name: 'SALES_MANAGER', label: 'Sales Manager', tenant: true },
  MARKETING: { name: 'MARKETING', label: 'Marketing', tenant: true },
  FINANCE: { name: 'FINANCE', label: 'Finance', tenant: true },
};

const roleNames = {};
Object.keys(userRoles).forEach(key => roleNames[key] = userRoles[key].name);

const singleTenantRoles = Object.values(userRoles).filter((role) => role.tenant).map((role) => role.name);
const multiTenantRoles = Object.values(userRoles).filter((role) => !role.tenant).map((role) => role.name);
const territoryRoles = [userRoles.OPERATIONS.name, userRoles.CUSTOMER_SERVICE.name, userRoles.LOGISTICS.name, userRoles.SALES_MANAGER.name, userRoles.MARKETING.name, userRoles.FINANCE.name];

const getRoleLabel = (value) => {
  const role = userRoles[value];

  if (!value || !role) {
    return '';
  }

  return role.label;
};

export {
  roleNames,
  singleTenantRoles,
  multiTenantRoles,
  territoryRoles,
  getRoleLabel,
};
export default userRoles;
