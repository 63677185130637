import React, { useState, useMemo } from 'react';
import { Field, Form } from 'react-final-form';

import Button from '../../shared/Button';
import Input from '../../shared/Input';
import Select from '../../shared/Select';
import FileUpload from '../../shared/FileUpload';
import DeleteIcon from '../../../assets/icons/DeleteIcon';
import AddIcon from '../../../assets/icons/AddIcon';

import '../../../styles/shared/form.scss';

const PreferenceForm = (props) => {
  const {
    onSubmit = () => {},
    loading,
    procedures = [],
    hospitals = [],
    onClose,
    mode = 'create',
    initialValues,
    onDelete,
    deleting,
  } = props;

  const [image, setImage] = useState(null);

  const validate = (values) => {
    const errors = {};

    if (!values.procedureId) {
      errors.procedureId = 'Required';
    }
    if (!values.hospitalId) {
      errors.hospitalId = 'Required';
    }
    if (!values.preference) {
      errors.preference = 'Required';
    }

    return errors;
  };

  const procedureOptions = useMemo(() => procedures.map((procedure) =>
    ({ value: procedure.id, label: procedure.name })), []);

  const hospitalOptions = useMemo(() => hospitals.map((hospital) =>
    ({ value: hospital.id, label: hospital.name })), []);

  return (
    <div className='form__container'>
      <Form
        onSubmit={(formObj) => onSubmit(formObj, image)}
        initialValues={initialValues}
        validate={validate}
        subscription={{ pristine: true, invalid: true }}
        mutators={{
          setLinks: ([value], state, utils) => {
            utils.changeValue(state, 'links', () => value)
          },
        }}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine, form }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <div className='form__subtitle'>
                {mode === 'update' ? 'Update Preference' : 'Add Preference'}
              </div>

              <div className='m-b-lg'>
                <div className='field__title'>Procedure</div>
                <Field name='procedureId'>
                  {({ input, meta }) => (
                    <Select
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      options={procedureOptions}
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-lg'>
                <div className='field__title'>Hospital</div>
                <Field name='hospitalId'>
                  {({ input, meta }) => (
                    <Select
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      options={hospitalOptions}
                    />
                  )}
                </Field>
              </div>

              <div className=''>
                <div className='field__title'>Preference</div>
                <Field name='preference'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      placeholder='Type preference here'
                      type='text'
                      onChange={input.onChange}
                      multiline
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-lg'>
                <div className='field__title'>Image</div>
                <Field name='image'>
                  {({ input, meta }) => (
                    <FileUpload
                      id='tenant-logo'
                      file={image}
                      onUpload={(file) => {
                        setImage(file);
                        input.onChange(file.name);
                      }}
                      placeholder={input.value}
                      accept='image'
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-lg'>
                <div className='field__title'>Links</div>
                <Field name='links'>
                  {({ input, meta }) => (
                    <div>
                      {(input?.value || []).map((url, index) => (
                        <div key={index} className='d-flex'>
                          <Input
                            name={input.name}
                            value={input.value[index]}
                            placeholder='Type preference here'
                            type='text'
                            onChange={(e) => {
                              const values = [...input?.value];
                              values[index] = e.target.value;
                              input.onChange(values);
                            }}
                          />
                          <DeleteIcon
                            color="#d32f2f"
                            className="m-b-lg m-l-md pointer"
                            onClick={() => {
                              const values = [...input?.value];
                              values?.splice(index, 1);
                              input.onChange(values);
                            }}
                          />
                        </div>
                      ))}
                      <Button
                        className='m-t-sm'
                        type='icon'
                        onClick={() => {
                          input.onChange([...input?.value, '']);
                        }}
                        disabled={input.value?.length && input.value?.some((v) => !v)}
                      >
                        <AddIcon color={input.value?.length && input.value?.some((v) => !v) ? 'secondary' : '#b1b1b1'} />
                      </Button>
                    </div>
                  )}
                </Field>
              </div>

              <div className='form__actions'>
                <div className='d-flex'>
                  {mode === 'update' && (
                    <div className='m-r-md'>
                      <Button
                        type='icon'
                        onClick={onDelete}
                        disabled={deleting}
                        loading={deleting}
                      >
                        <DeleteIcon color="#000000" />
                      </Button>
                    </div>
                  )}
                  <Button
                    type='submit'
                    text={mode === 'update' ? 'Save Changes' : 'Add Preference'}
                    disabled={invalid || loading || pristine}
                    loading={loading}
                    onClick={handleSubmit}
                  />
                </div>
                <Button
                  type='cancel'
                  onClick={onClose}
                />
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default PreferenceForm;
