import React from 'react';

import Button from '../../shared/Button';
import Select from '../../shared/Select';

const TasksFilters = (props) => {
  const {
    onAddTaskClick,
    surgeon,
    setSurgeon,
    surgeons,
  } = props;

  return (
    <div className='settings-cmp__filters--main'>
      <div className='settings-cmp__actions'>
        <Button
          text='Add Task'
          type='submit'
          onClick={onAddTaskClick}
        />
      </div>

      <div className='settings-cmp__filters'>
        <Select
          value={surgeon}
          onChange={setSurgeon}
          options={surgeons?.map((s) => ({ label: `${s.title} ${s.firstName} ${s.lastName}`, value: s.id }))}
          defaultLabel={`All surgeons (${surgeons.length})`}
          width={300}
        />
      </div>
    </div>
  )
};

export default TasksFilters;
