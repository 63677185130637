import React, { useState, useEffect, useMemo } from 'react';

import ItemsIcon from '../../../assets/icons/ItemIcon';

import Modal from '../../shared/modal';
import Button from '../../shared/Button';
import Input from '../../shared/Input';

const CaseConsumablesModal = (props) => {
  const {
    open,
    onClose,
    onAnassignClick,
    onSubmit,
    loading,
    itemId,
    items = [],
    initialConsumables,
    quantity,
  } = props;

  const [consumables, setConsumables] = useState(initialConsumables || []);

  useEffect(() => {
    if (initialConsumables && initialConsumables.length) {
      setConsumables(initialConsumables);
    } else {
      setConsumables(Array(quantity).fill(''));
    }
  }, [open, initialConsumables, quantity]);

  const handleSubmit = () => {
    onSubmit(consumables);
    onClose();
  };

  const onChangeConsumable = (index, value) => {
    const temp = [...consumables];
    temp[index] = value;
    setConsumables(temp);
  };

  const item = useMemo(() => {
    const allocationKit = items.find((i) => i.id === itemId);
    return allocationKit || {};
  }, [itemId, items]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Item Allocation'
      fullWidth
    >
      <div className='case-sets-modal__container'>
        <div className='d-flex'>
          <ItemsIcon fontSize='small' />
          <span className='font-bold m-l-md'>{item.checklistDescription || item.description}</span>
          <span className='font-bold m-l-md secondary'>{item.code}</span>
        </div>

        <div className='m-t-lg m-b-sm'>
          <div className='field__title m-b-md'>Consumables</div>
          {consumables?.map((consumable, index) => (
            <Input
              key={index}
              value={consumable}
              type='text'
              onChange={(e) => onChangeConsumable(index, e.target.value)}
              placeholder={`${index + 1}. Add LOT or Serial No.`}
            />
          ))}
        </div>

        <div className='form__actions p-t-lg'>
          <Button
            type='submit'
            text='Confirm'
            onClick={handleSubmit}
            disabled={loading || consumables?.some((item) => !item)}
            loading={loading}
          />

          <Button
            type='outlined'
            text='Unassign'
            onClick={onAnassignClick}
            disabled={loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CaseConsumablesModal;
