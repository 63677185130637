import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import DocumentIcon from '../../../assets/icons/DocumentIcon';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

const SectionsList = ({ kitId = '', kitName = '', sections, onClick, tenantColor, selectedSection, onReorder }) => {

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = [...sections];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    onReorder(newItems);
  }

  return (
    <div className='section-list__container'>
      <div
        className='section-list__item'
        style={{ margin: 0, justifyContent: 'flex-start', minHeight: '55px' }}
        onClick={() => onClick(null)}
      >
        <DocumentIcon />
        <div className='m-l-md'>
          <div className='font-bold'>{kitId}</div>
          <div className='font-size-sm'>{kitName}</div>
        </div>
      </div>
      {
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable'>
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
              {sections.map((section, index) => (
                <Draggable key={section.id} draggableId={section.id} index={index} style={{ width: '100%' }}>
                  {(provided) => (
                    <div
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                      className={`section-list__item${section.id === selectedSection?.id ? ' selected' : ''}`}
                      onClick={() => onClick(section)}
                    >
                      <div className='d-flex direction-column align-start'>
                        <span
                          style={{ color: section.id === selectedSection?.id ? tenantColor : '#000000' }}
                          className='font-bold'
                        >
                          {section.name}
                        </span>
                        <span className='font-bold secondary'>{section?.subtitle}</span>
                      </div>

                      <div {...provided.dragHandleProps} style={{ width: 24, paddingTop: 4 }}>
                        <DragIndicatorIcon />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              </div>)}
          </Droppable>
        </DragDropContext>
      }
    </div>
  );
};

export default SectionsList;
