import { orderBy } from 'lodash';

import { GET_NOTIFICATIONS, SET_NOTIFICATIONS, SHOW_NOTIFICATION, CLOSE_NOTIFICATION, SET_LAST_DOC_TIMESTAMP } from './actionTypes';

import firebase, { collections } from '../firebase';

import { roleNames } from '../constants/userRoles';

export const setNotifications = (notifications) => ({ type: SET_NOTIFICATIONS, notifications });

export const getNotifications = () => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const user = state.user.currentUser;
  const path = collections.NOTIFICATIONS_COLLECTION(tenantId);

  dispatch({ type: GET_NOTIFICATIONS });

  let snapshot;

  if (user.role === roleNames.ADMIN) {
    snapshot = await firebase.db.collection(path).get();
  } else {
    snapshot = await firebase.db.collection(path)
      .where('visibleTo', 'array-contains', user.uid)
      .get();
  }

  const notifications = snapshot.docs.map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      createdAt: data.createdAt ? data.createdAt.toDate() : null,
    };
  });

  return dispatch(setNotifications(orderBy(notifications, 'createdAt', 'desc')));
};

export const showNotification = (notification) => ({ type: SHOW_NOTIFICATION, notification });

export const closeNotification = () => ({ type: CLOSE_NOTIFICATION });

export const setLastDocTimestamp = (timestamp) => ({ type: SET_LAST_DOC_TIMESTAMP, timestamp });

// Subscriptions
export const subscribeToNotifications = (lastDocTimestamp) => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const user = state.user.currentUser;
  const path = collections.NOTIFICATIONS_COLLECTION(tenantId);

  let query;

  if (user.role === roleNames.ADMIN) {
    query = firebase.db.collection(path);
  } else {
    query = firebase.db.collection(path)
      .where('visibleTo', 'array-contains', user.uid);
  }

  return query
    .orderBy('createdAt')
    .startAfter(lastDocTimestamp || 0)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let notifications = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          const data = documentSnapshot.data();
          notifications = [...notifications, {
            ...data,
            id,
            createdAt: data.createdAt ? data.createdAt.toDate() : null,
          }];
        });

        const newDocuments = [];
        const removedDocuments = [];

        querySnapshot?.docChanges()?.forEach((change) => {
          if (change.type === 'added') {
            const data = change?.doc?.data();
            newDocuments.push({
              id: change.doc.id,
              createdAt: data.createdAt ? data.createdAt.toDate() : null,
              ...data,
            });
          }
          if (change.type === 'removed') {
            removedDocuments.push(change.doc.id);
          }
        });

        if (newDocuments?.length) {
          const lastNotification = newDocuments[newDocuments.length - 1];
          if (!!lastDocTimestamp && lastNotification && lastNotification.visibleTo?.includes(user.uid) && !user.disableAppNotifications) {
            dispatch(showNotification(lastNotification));
          }
          dispatch(setLastDocTimestamp(lastNotification.createdAt));
        }

        return dispatch(setNotifications(orderBy(notifications, 'createdAt', 'desc')));
      },
    });
};
