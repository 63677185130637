import React, { useState, useEffect } from 'react';

import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import DeleteIcon from '@material-ui/icons/Close';
import PauseIcon from '@material-ui/icons/Pause';
import ResumeIcon from '@material-ui/icons/PlayArrow';
import SuccessIcon from '@material-ui/icons/Check';
import FileIcon from '../../../assets/icons/FileIcon';

import { withFirebase } from '../../../firebase';

const FileItem = (props) => {
  const { firebase, path, onUploadComplete, file, onRemove, withoutProgress } = props;

  const [status, setStatus] = useState('');
  const [progress, setProgress] = useState(0);
  const [uploadTask, setUploadTask] = useState(null);

  useEffect(() => {
    if (!withoutProgress && path) {
      const storageRef = firebase.storage.ref();
      const child = `${path}/${file.name}`;
      const task = storageRef.child(child).put(file);

      setUploadTask(task);
    }
  }, []);

  useEffect(() => {
    if (uploadTask) {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const value = ( snapshot.bytesTransferred / snapshot.totalBytes ) * 100;
          setProgress(value);

          switch (snapshot.state) {
            case 'paused':
              setStatus('paused');
              break;
            case 'running':
              setStatus('running');
              break;
            default:
              setStatus('running');
              break;
          }
        },
        (error) => {
          console.error(error);
        },
        () => {
          setStatus('success');
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then((downloadUrl) => onUploadComplete(file.name, downloadUrl));
        },
      );
    }
  }, [uploadTask]);

  const onPause = () => {
    uploadTask.pause();
  };

  const onResume = () => {
    uploadTask.resume();
  };

  const onCancel = () => {
    if (uploadTask) {
      if (status === 'success') {
        uploadTask.snapshot.ref.delete();
      } else {
        uploadTask.cancel();
      }
    }

    onRemove(file.name);
  };

  const renderButton = () => {
    switch (status) {
      case 'running':
        return (
          <IconButton aria-label="pause" color="secondary" onClick={onPause} size="small">
            <PauseIcon fontSize="small"/>
          </IconButton>
        );
      case 'paused':
        return (
          <IconButton aria-label="resume" color="secondary" onClick={onResume} size="small">
            <ResumeIcon fontSize="small"/>
          </IconButton>
        );
      case 'success':
        return (
          <IconButton aria-label="success" color="primary" size="small">
            <SuccessIcon fontSize="small"/>
          </IconButton>
        );
      default:
        return (
          <IconButton aria-label="resume" color="secondary" onClick={onResume} size="small">
            <ResumeIcon fontSize="small"/>
          </IconButton>
        );
    }
  };

  const { name } = file;

  return withoutProgress ? (
    <div className="file-item">
      <div key={name} className="d-flex space-between m-t-lg">
        <div className="flex-1">
          <div className="d-flex space-between align-end">
            <div className="d-flex flex-1">
              <FileIcon fontSize="small" className="m-r-sm"/>
              {name}
            </div>
          </div>
        </div>
        <div className="d-flex m-l-md">
          <IconButton aria-label="delete" color="secondary" onClick={onCancel} size="small">
            <DeleteIcon fontSize="small"/>
          </IconButton>
        </div>
      </div>
    </div>
  ) : (
    <div className="file-item">
      <div key={name} className="d-flex space-between m-t-lg">
        <div className="flex-1">
          <div className="d-flex space-between align-end">
            <div className="d-flex flex-1">
              <FileIcon fontSize="small" className="m-r-sm"/>
              {name}
            </div>
            <div>
              <div className="d-flex flex-end w-100">{Math.round(progress)}%</div>
            </div>
          </div>
          <div className="d-flex align-center flex-center flex-1 m-t-sm">
            <LinearProgress variant="determinate" value={progress} style={{ width: '100%' }}/>
          </div>
        </div>
        <div className="d-flex m-l-md">
          {renderButton()}
          <IconButton aria-label="delete" color="secondary" onClick={onCancel} size="small">
            <DeleteIcon fontSize="small"/>
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default withFirebase(FileItem);
