import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

import CircularProgress from '@material-ui/core/CircularProgress';

const FormStepsList = (props) => {
  const { loading, steps, onClick, selectedStep, tenantColor, onReorder } = props;

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = [...steps];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    onReorder(newItems);
  }

  return (
    <div className='drive-files-list__container'>
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <div className='width-100'>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='droppable'>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {steps.map((step, index) => (
                    <Draggable key={step.id} draggableId={step.id} index={index} style={{ width: '100%' }}>
                      {(provided) => (
                        <div
                          className='drive-files-item'
                          onClick={() => onClick(step)}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div
                            className='form-step-index'
                            style={{ background: selectedStep?.id === step.id ? tenantColor : '#333333' }}
                          >
                            {index + 1}
                          </div>
                          <div className='drive-files-item__name'>
                            <div style={{ color: selectedStep?.id === step.id ? tenantColor : 'inherit' }}>
                              {step.title}
                            </div>
                            <div className='secondary font-size-xs'>{step.subtitle}</div>
                          </div>

                          <div className='p-sm d-flex flex-center' {...provided.dragHandleProps}>
                            <DragIndicatorIcon />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </div>
  );
};

export default FormStepsList;
