import React, { useMemo } from 'react';
import { orderBy, sum } from 'lodash';

import BarChart from '../../../charts/BarChart';

const SetTurnsBarChart = ({ sets = [], kits = [], minDate, maxDate }) => {
  const data = useMemo(() => {
    const filteredSets = sets?.filter((set) => minDate.isBefore(set.date) && maxDate.isAfter(set.date));
    const allKits = kits
      .map((kit) => {
        const kitSets = filteredSets.filter((item) => item.kit === kit.id);
        return {
          id: kit.kitId,
          label: `${kit?.kitId?.substring(0, 10)}${kit?.kitId?.length > 10 ? '...' : ''}`,
          value: sum(kitSets?.map((set) => set.quantity)),
        };
      })
      .filter((kit) => kit.value !== 0);

    const sortedKits = orderBy(allKits, 'value', 'desc');
    const top10 = sortedKits.splice(0, 10);

    return top10;
  }, [sets, kits, minDate, maxDate]);

  return (
    <div className="management-reports-chart__container m-l-md" style={{ width: '50%' }}>
      <div className="font-size-bg font-bold">Top Kits by Set Turns</div>
      <div className="secondary m-t-sm m-b-lg">Total loan set turns by top 10 kits in period</div>
      <BarChart
        data={data || []}
        indexBy="id"
        height="300px"
        layout="horizontal"
        entity='set'
        enableLabel={true}
        margin={{ top: 10, right: 25, bottom: 30, left: 90 }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          truncateTickAt: 0,
          format: (label) => `${label?.substring(0, 10)}${label?.length > 10 ? '...' : ''}`,
        }}
        labelTextColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              1.6,
            ],
          ],
        }}
      />
    </div>
  );
};

export default SetTurnsBarChart;
