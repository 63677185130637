import React from 'react';

import Table from '../shared/table/Table';

const columns = [
  { title: 'name', field: 'name', type: 'default', sort: true },
  { title: 'description', field: 'description', type: 'default', sort: true },
  { title: 'members', field: 'membersCount', type: 'members', sort: false },
  { title: 'status', field: 'active', type: 'status', sort: false }
];

const GroupsTable = ({ onSelectGroup, groups, selectedGroupId, handleCheck, checkedGroups, handleCheckAll }) => {
  return (
    <div style={{ width: '100%' }}>
      <Table
        rows={groups.map((group) => ({ ...group, membersCount: group.members.length }))}
        columns={columns}
        onRowClick={onSelectGroup}
        selected={selectedGroupId}
        handleCheck={handleCheck}
        handleCheckAll={handleCheckAll}
        checkedRows={checkedGroups}
        pagination
        noCheckAll
      />
    </div>
  );
};

export default GroupsTable;
