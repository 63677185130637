import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { pdf } from '@react-pdf/renderer';

import FullScreenIcon from '@material-ui/icons/Fullscreen';

import {
  BillOfMaterialActions,
  BillOfMaterialBreadcrumbs,
  SectionsList,
  SectionForm,
  SectionItemsList,
  SectionItemsModal,
  ConfirmUploadModal,
  DataGrid,
  SectionNotesModal
} from '../../../components/kits/bill-of-material';
import Input from '../../../components/shared/Input';
import Modal, { ConfirmationModal, UploadModal } from '../../../components/shared/modal';
import LoadScreen from '../../../components/load-screen';
import ChecklistsPDF from '../../../components/cases/checklists/ChecklistsPDF';

import {
  getKit, subscribeToSections, createSection,
  updateSection, deleteSection, uploadItems, uploadBOM
} from '../../../actions/kitsActions';

import { useLoading, useAlert } from '../../../hooks';

import { downloadFile } from '../../../utils/utils';

import { billOfMaterialItemTypes } from '../../../constants/enums';
import Dropzone from '../../../components/shared/dropzone';

const BillOfMaterialPage = () => {
  const { id } = useParams();
  const kitId = id.replace(':', '');

  const dispatch = useDispatch();
  const tenant = useSelector((state) => state.tenant.currentTenant);
  const itemsList = useSelector((state) => state.items.list);
  const itemsLoaded = useSelector((state) => state?.items?.isLoaded);
  const kits = useSelector((state) => state?.kits.list);
  const deletePermission = useSelector((state) => state.user.currentUser?.deletePermission);

  const { loading, startLoading, stopLoading } = useLoading(false);
  const { fetching, startLoading: startFetching, stopLoading: stopFetching } = useLoading(false);
  const { loading: deleting, startLoading: startDeleting, stopLoading: stopDeleting } = useLoading(false);
  const { showAlert } = useAlert();

  const [kit, setKit] = useState(null);

  const [search, setSearch] = useState('');

  const [sections, setSections] = useState([]);
  const [selectedSection, selectSection] = useState(null);

  const [items, setItems] = useState([]);
  const [selectedItem, selectItem] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [deletingNote, setDeletingNote] = useState(false);
  const [exporting, setExporting] = useState(false);

  const [sectionModal, toggleSectionModal] = useState(false);
  const [deleteSectionModal, toggleDeleteSectionModal] = useState(false);
  const [sectionItemsModal, toggleSectionItemsModal] = useState(false);
  const [notesModal, toggleNotesModal] = useState(false);
  const [isUploadModalOpen, toggleUploadModal] = useState(false);

  const [confirmModalOpen, toggleConfirmModal] = useState(false);
  const [confirmModalItems, setConfirmModalItems] = useState([]);
  const [failedModalItems, setFailedModalItems] = useState([]);

  const [checkedItems, setCheckedItems] = useState([]);

  useEffect(() => {
    const unsubscribe = dispatch(subscribeToSections(kitId, setSections));

    onLoad().catch((err) => console.error(err));

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (selectedSection) {
      setItems(selectedSection?.items || []);
    } else {
      setItems([]);
    }
  }, [selectedSection]);

  const onLoad = async () => {
    startFetching();

    try {
      const res = await dispatch(getKit(kitId));
      // await dispatch(getItems());
      if (res && res?.id) {
        setKit(res);
      }
    } catch (err) {
      console.error(err);
    } finally {
      stopFetching();
    }
  };

  const onAddClick = (option) => {
    if (option === billOfMaterialItemTypes.NOTES.value) {
      toggleNotesModal(true);
    } else if (option === billOfMaterialItemTypes.ITEM.value) {
      toggleSectionItemsModal(true);
    } else {
      toggleSectionModal(true);
    }
  };

  const onSubmitSection = async (formObj, image, mode, withoutAlert = false) => {
    startLoading();

    const sectionObj = {
      name: formObj.name || '',
      subtitle: formObj.subtitle || '',
      description: formObj.description || '',
      items: mode !== 'create' && formObj.items ? formObj.items : [],
      active: mode !== 'create' ? formObj.active : true,
      printOnNewPage: formObj.printOnNewPage || false,
      image: formObj?.image || null
    };

    try {
      if (mode !== 'create' && formObj.id) {
        const imageObj = await dispatch(updateSection(kitId, formObj.id, sectionObj, image));
        setItems(formObj.items);
        const updatedSection = { ...selectedSection, ...sectionObj };

        if (!formObj?.image) {
          updatedSection.image = null;
        }
        if (imageObj) {
          updatedSection.image = imageObj;
        }

        selectSection(updatedSection);
      } else {
        sectionObj.orderNumber = sections.length + 1;
        const res = await dispatch(createSection(kitId, sectionObj, image));
        const { sectionId, imageObj } = res;
        const newSection = { id: sectionId, ...sectionObj };

        if (imageObj) {
          newSection.image = imageObj;
        }

        selectSection(newSection);
      }

      stopLoading();
      toggleSectionModal(false);

      if (!withoutAlert) {
        showAlert('success', `Section has been successfully ${mode !== 'create' ? 'updated' : 'created'}`);
      }
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  // const onUpdateItem = async (formObj) => {
  //   const items = [...selectedSection?.items];
  //   const updatedItem = items?.find((item) => item.id === formObj.id);
  //
  //   if (updatedItem) {
  //     startLoading();
  //     updatedItem.quantity = formObj.quantity || 0;
  //     updatedItem.ref = formObj.ref || '';
  //
  //     try {
  //       dispatch(updateSection(kitId, selectedSection.id,{ items }));
  //       selectSection({ ...selectedSection, items });
  //       stopLoading();
  //       showAlert('success', 'Item has been successfully updated');
  //     } catch (err) {
  //       console.error(err);
  //     } finally {
  //       stopLoading();
  //     }
  //   }
  // };

  const onDeleteSection = async () => {
    startDeleting();

    try {
      await dispatch(deleteSection(kitId, selectedSection.id));
      toggleDeleteSectionModal(false);
      selectSection(null);
      showAlert('success', 'Section has been successfully deleted');
    } catch (err) {
      console.error(err);
    } finally {
      stopDeleting();
    }
  };

  const onSectionItemsChange = async (items) => {
    const newSection = {
      ...selectedSection,
      items: items.map((item) => {
        if (item.type === billOfMaterialItemTypes.NOTES.value) {
          return {
            id: item.id,
            note: item.note || '',
            title: item.title || '',
            color: item.color || '',
            type: billOfMaterialItemTypes.NOTES.value,
          };
        } else {
          return {
            id: item.id,
            ref: item.ref,
            quantity: item.quantity ? Number(item.quantity) : 0,
            type: billOfMaterialItemTypes.ITEM.value,
          };
        }
      })
    };
    selectSection(newSection);
    await onSubmitSection(newSection, null, 'update', true);
  };

  const onSubmitNotes = async (formObj) => {
    if (selectedSection) {
      try {
        setUpdating(true);

        const updatedSection = { ...selectedSection };

        if (formObj.id) {
          const updatedItem = updatedSection?.items?.find((i) => i.type === billOfMaterialItemTypes.NOTES.value && i.id === formObj.id);
          updatedItem.title = formObj.title || '';
          updatedItem.note = formObj.note || '';
          updatedItem.color = formObj.color || '';
        } else {
          updatedSection.items = [
            ...updatedSection.items,
            {
              id: uuid().substring(0, 20),
              title: formObj.title || '',
              note: formObj.note || '',
              color: formObj.color || '',
              type: billOfMaterialItemTypes.NOTES.value
            }
          ];
        }

        await onSubmitSection(updatedSection, null, 'update', true);
        selectSection(updatedSection);
        toggleNotesModal(false);
      } catch (err) {
        console.error(err);
      } finally {
        setUpdating(false);
      }
    }
  };

  const onDeleteNote = async (noteId) => {
    if (selectedSection) {
      try {
        setDeletingNote(true);
        const updatedSection = { ...selectedSection };
        updatedSection.items = [...updatedSection.items].filter((item) => item.id !== noteId);
        await onSubmitSection(updatedSection, null, 'update', true);
        selectSection(updatedSection);
        toggleNotesModal(false);
      } catch (err) {
        console.error(err);
      } finally {
        setDeletingNote(false);
      }
    }
  };

  const onUpload = async (items) => {
    setUploading(true);

    try {
      const successful = [];
      const failed = [];

      items.forEach((item) => {
        const ref = item[0] === '-' ? '' : item[0];
        const code = item[1] || '';
        const description = item[2] || '';
        const quantity = item[3] ? Number(item[3]) : 0;
        const _item = itemsList?.find((i) => i.code === code);

        if (_item && _item.id) {
          const doc = {
            id: _item.id || '',
            quantity,
            ref,
            type: billOfMaterialItemTypes.ITEM.value,
        };
          successful?.push(doc);
        } else {
          failed?.push({ code, description });
        }
      });

      if (!!failed?.length) {
        setUploading(false);
        toggleUploadModal(false);
        toggleConfirmModal(true);
        setConfirmModalItems(successful);
        setFailedModalItems(failed);
        return;
      }

      await dispatch(uploadItems(kitId, selectedSection, successful));
      setUploading(false);
      toggleUploadModal(false);
      selectSection(null);
      showAlert('success', 'Items have been successfully uploaded');
    } catch (err) {
      setUploading(false);
      console.error(err);
    }
  };

  const finishUpload = async () => {
    setUploading(true);

    try {
      await dispatch(uploadItems(kitId, selectedSection, confirmModalItems));
      setConfirmModalItems([]);
      setFailedModalItems([]);
      setUploading(false);
      toggleConfirmModal(false);
      selectSection(null);
      showAlert('success', 'Items have been successfully uploaded');
    } catch (err) {
      setUploading(false);
      console.error(err);
    }
  };

  const onBOMUpload = async (data) => {
    setUploading(true);

    try {
      await dispatch(uploadBOM(kitId, data));
    } catch (err) {
      console.error(err);
    } finally {
      setUploading(false);
    }
  };

  const handleCheck = (itemId) => {
    const checked = checkedItems.slice();

    if (checked.includes(itemId)) {
      const index = checked.indexOf(itemId);

      checked.splice(index, 1);
      setCheckedItems(checked);

      return;
    }

    checked.push(itemId);
    setCheckedItems(checked);
  };

  const handleCheckAll = () => {
    const checked = checkedItems.length === items?.length;
    const temp = [];

    if (!checked) {
      items?.forEach((item) => {
        temp?.push(item.id);
      });

      setCheckedItems(temp);
      return;
    }

    setCheckedItems(temp);
  };

  const onDeleteClick = async () => {
    if (!!checkedItems.length) {
      const updatedItems = [...items].filter((item) => !checkedItems?.includes(item.id));
      await onSectionItemsChange(updatedItems);
      setCheckedItems([]);
    } else {
      toggleDeleteSectionModal(true);
    }
  };

  const onReorder = async (reorderedSections) => {
    setSections(reorderedSections);
    await Promise.all(reorderedSections?.map((section, index) => (
      dispatch(updateSection(kitId, section.id, { orderNumber: index + 1, image: section.image || null }))
    )))
  };

  const onUploadFromDataGrid = async (data) => {
    const formattedData = data?.map(({ ref, code, description, quantity }) => ([
      ref ? ref?.replace(/(\r\n|\n|\r)/gm, "") : '-',
      code ? code?.replace(/(\r\n|\n|\r)/gm, "") : '',
      description ? description?.replace(/(\r\n|\n|\r)/gm, "") : '',
      quantity ? Number(quantity) : 1
    ]));
    await onUpload(formattedData);
  };

  const onExportPdfClick = async () => {
    try {
      setExporting(true);
      const checklists = [{
        kitId: kit.kitId,
        sections: sections?.map((section) => ({
          ...section,
          items: section?.items?.map((item) => {
            if (item.type === billOfMaterialItemTypes.NOTES.value) {
              return item;
            } else {
              const _item = itemsList?.find((i) => i.id === item.id);
              return { ...item, code: _item.code || '', description: _item.checklistDescription || _item.description || '' };
            }
          })
        })),
      }];

      const blob = await pdf(
        <ChecklistsPDF
          tenant={tenant}
          checklists={checklists}
          kits={kits}
          sets={[]}
        />
      ).toBlob();
      downloadFile(blob, `${kit.kitId} – BOM.pdf`);
    } finally {
      setExporting(false);
    }
  };

  const onImageUpload = async (files) => {
    try {
      startLoading()
      const imageObj = await dispatch(updateSection(kitId, selectedSection.id, {}, files[0]));
      const updatedSection = { ...selectedSection };

      if (imageObj) {
        updatedSection.image = imageObj;
      }

      selectSection(updatedSection);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  return (
    <div className='settings-cmp__main flex-1 bom-page__container'>
      { (fetching || !itemsLoaded) && <LoadScreen /> }

      <span className='settings-title'>BOM</span>
      <div className='bom-page__body flex-1'>
        <div className='settings-block__left'>
          <BillOfMaterialActions
            onAddClick={onAddClick}
            onDeleteClick={onDeleteClick}
            selectedSection={selectedSection}
            onUploadClick={() => toggleUploadModal(true)}
            uploading={uploading}
            sections={sections}
            items={itemsList}
            onBOMUpload={onBOMUpload}
            kitId={kit?.kitId}
            deletePermission={deletePermission}
            onExportPdfClick={onExportPdfClick}
            exporting={exporting}
          />
          <SectionsList
            kitId={kit?.kitId}
            kitName={kit?.name}
            sections={sections}
            onClick={(section) => {
              selectItem(null);
              selectSection(section);
            }}
            tenantColor={tenant.colorPrimary}
            selectedSection={selectedSection}
            onReorder={onReorder}
          />
        </div>
        <div className='d-flex direction-column' style={{ alignItems: 'normal' }}>
          <div>
            <div>
              <BillOfMaterialBreadcrumbs
                kitId={kit?.kitId}
                selectedSection={selectedSection}
                tenantColor={tenant.colorPrimary}
              />
            </div>
            <div>
              <Input
                type='search'
                placeholder='Search'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>

          {selectedSection && (
            <div>
              <div className='image-container'>
                {selectedSection && selectedSection.image ? (
                  <>
                    <img src={selectedSection?.image?.downloadUrl} alt='section-preview' />
                    <div
                      className='full-screen-icon'
                      onClick={() => window.open(selectedSection?.image?.downloadUrl, '_blank')}
                    >
                      <FullScreenIcon color='secondary' fontSize='large' />
                    </div>
                  </>
                ) : (
                  <Dropzone
                    files={[]}
                    onUpload={onImageUpload}
                    onRemove={() => {}}
                    tenantColor={tenant.colorPrimary}
                    multiple={false}
                    text="Drag & drop a file or click to upload a section image"
                  />
                )}
              </div>
              <div>
                <SectionForm
                  initialValues={{
                    ...selectedSection,
                    imageName: selectedSection.image ? selectedSection?.image?.fileName : ''
                  }}
                  onSubmit={onSubmitSection}
                  onClose={() => toggleSectionModal(null)}
                  mode='short'
                  buttonText='Save Changes'
                  loading={loading}
                  itemsList={itemsList}
                  tenantColor={tenant.colorPrimary}
                  onUpdateClick={() => toggleSectionModal(true)}
                />
              </div>
            </div>
          )}

          {selectedSection && (
            items?.length ? (
              <SectionItemsList
                items={items}
                // onClick={selectItem}
                selectedItem={selectedItem}
                tenantColor={tenant.colorPrimary}
                onReorder={onSectionItemsChange}
                itemsList={itemsList}
                onChange={onSectionItemsChange}
                onCheck={handleCheck}
                onCheckAll={handleCheckAll}
                checked={checkedItems}
                search={search}
                onEditNotesClick={(item) => {
                  selectItem(item);
                  toggleNotesModal(true);
                }}
              />
            ) : (
              <DataGrid onUpload={onUploadFromDataGrid} loading={uploading} />
            )
          )}
        </div>
      </div>

      <Modal
        open={!!sectionModal}
        onClose={() => toggleSectionModal(null)}
      >
        <SectionForm
          onSubmit={onSubmitSection}
          onClose={() => toggleSectionModal(null)}
          mode={selectedSection ? 'update' : 'create'}
          buttonText={selectedSection ? 'Save Changes' : 'Add Section'}
          loading={loading}
          itemsList={itemsList}
          initialValues={
            selectedSection ? {
              ...selectedSection,
              imageName: selectedSection.image ? selectedSection?.image?.fileName : ''
            } : {}
          }
        />
      </Modal>

      <ConfirmationModal
        open={!!deleteSectionModal}
        onClose={() => toggleDeleteSectionModal(false)}
        onSubmit={onDeleteSection}
        title='Are you sure you want to delete this section?'
        submitText='Delete'
        loading={deleting}
      />

      {selectedSection && (
        <SectionItemsModal
          isOpen={sectionItemsModal}
          onClose={() => toggleSectionItemsModal(false)}
          selected={selectedSection?.items}
          onSubmit={onSectionItemsChange}
          options={itemsList}
        />
      )}

      {selectedSection && (
        <SectionNotesModal
          open={notesModal}
          onClose={() => {
            toggleNotesModal(false);
            selectItem(null);
          }}
          onSubmit={onSubmitNotes}
          onDelete={() => onDeleteNote(selectedItem?.id)}
          initialValues={selectedItem}
          loading={updating}
          deleting={deletingNote}
        />
      )}

      {selectedSection && (
        <UploadModal
          open={isUploadModalOpen}
          onClose={() => toggleUploadModal(false)}
          title='Upload Items'
          tenantColor={tenant?.colorPrimary}
          onSubmit={onUpload}
          loading={uploading}
          fields={['Ref', 'Item Code', 'Description', 'Quantity']}
          warningText='Note: If item has no Ref please use "-" symbol. It cannot be left empty!'
        />
      )}

      <ConfirmUploadModal
        open={!!confirmModalOpen}
        onClose={() => {
          toggleConfirmModal(false);
          setConfirmModalItems([]);
          setFailedModalItems([]);
        }}
        onSubmit={finishUpload}
        loading={uploading}
        failedItems={failedModalItems}
      />
    </div>
  );
};

export default BillOfMaterialPage;
