import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function GroupsIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M2.37434 9.9071C2.38157 10.0502 2.38945 10.1937 2.39798 10.3377C2.4551 11.3019 3.2218 12.0686 4.18604 12.1256C4.65066 12.153 5.11087 12.1737 5.56885 12.1877" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M8.60145 1.85376C9.08606 1.8677 9.57303 1.88919 10.065 1.91825C11.0292 1.9752 11.7959 2.74189 11.853 3.70612C11.872 4.02719 11.8878 4.34598 11.9004 4.66325" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M10.47 10.2622C11.9392 10.2622 12.8877 11.4164 12.8877 12.9382H8.05225C8.05225 11.4164 9.00071 10.2622 10.47 10.2622Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M3.83032 4.58533C5.29959 4.58533 6.24805 5.73954 6.24805 7.26136H1.4126C1.4126 5.73954 2.36106 4.58533 3.83032 4.58533Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M10.47 10.2623C11.3709 10.2623 12.1011 9.53199 12.1011 8.63114C12.1011 7.73029 11.3709 7 10.47 7C9.56915 7 8.83887 7.73029 8.83887 8.63114C8.83887 9.53199 9.56915 10.2623 10.47 10.2623Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M3.83036 4.5854C4.73121 4.5854 5.46149 3.85511 5.46149 2.95426C5.46149 2.05341 4.73121 1.32312 3.83036 1.32312C2.9295 1.32312 2.19922 2.05341 2.19922 2.95426C2.19922 3.85511 2.9295 4.5854 3.83036 4.5854Z" />
  </SvgIcon>
}
