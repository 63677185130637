import React, { useCallback } from 'react';

import Table from '../shared/table/Table';

const ConnectedCasesTable = (props) => {
  const {
    onSelectCase,
    cases,
    selectedCaseId,
    handleCheck,
    checkedCases,
    handleCheckAll,
    withoutCheck,
    rowColor,
    rowBorderColor,
    pagination,
    checkboxes,
    filter,
    filterOptions,
    onFilterChange,
    tenants = [],
  } = props;

  const columns = [
    { title: '', field: 'caseStatus', type: 'caseStatus', sort: false },
    { title: 'case id', field: 'caseId', type: 'default' },
    { title: 'created at', field: 'createdAt', type: 'date', sort: true },
    { title: 'surgery date', field: 'date', type: 'date', sort: true },
    { title: 'patient ref', field: 'patientReference', type: 'default', sort: true },
    {
      title: 'company',
      field: 'tenantId',
      type: 'custom',
      formatter: (value) => {
        const tenant = tenants?.find((t) => t.id === value);
        return (
          <div className='d-flex'>
            {tenant?.icon?.downloadUrl && (
              <img src={tenant?.icon?.downloadUrl} alt='company-icon' style={{ width: 'auto', height: 30, marginRight: 8 }} />
            )}
            <span className='font-bold'>{tenant?.businessName}</span>
          </div>
        );
      }
    }
  ];

  const getFormattedId = useCallback((caseId) => {
    if (!caseId) {
      return ''
    }
    const arr = caseId?.split('-');
    return arr?.length > 1 ? `${arr[0]}-${arr[1]}` : caseId;
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <Table
        className='cases-table'
        rows={cases.map((item) => ({
          ...item,
          caseStatus: { status: item.status, canceled: !item.active, kitVariant: item.kitVariant },
          caseId: getFormattedId(item.id)
        }))}
        columns={columns}
        onRowClick={onSelectCase}
        selected={selectedCaseId}
        handleCheck={handleCheck}
        checkedRows={checkedCases}
        handleCheckAll={handleCheckAll}
        withoutCheck={withoutCheck}
        rowColor={rowColor}
        rowBorderColor={rowBorderColor}
        pagination={pagination}
        checkboxes={checkboxes}
        currentFilter={filter}
        filterOptions={filterOptions}
        onFilterChange={onFilterChange}
        noCheckAll
      />
    </div>
  );
};

export default ConnectedCasesTable;
