import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function GetAppIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M0.819092 10.3972V11.3481C0.819092 11.8525 1.01946 12.3363 1.37613 12.693C1.7328 13.0496 2.21654 13.25 2.72094 13.25H11.2793C11.7837 13.25 12.2674 13.0496 12.6241 12.693C12.9808 12.3363 13.1811 11.8525 13.1811 11.3481V10.3972" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M3.69629 6.11487C4.34423 7.30737 5.30927 8.3941 6.55249 9.14572C6.82782 9.31218 7.17219 9.31217 7.44751 9.14569C8.69067 8.39402 9.6557 7.30734 10.3037 6.11487" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M7 9.27051L7 1" />
  </SvgIcon>
}
