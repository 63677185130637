import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import LaunchIcon from '../../assets/icons/ExpandIcon';

import { driveUtils } from '../../utils';

const { getFileIcon } = driveUtils;

const DriveFilesList = (props) => {
  const { loading, files, onClick, activeFile, colorPrimary } = props;

  return (
    <div className='drive-files-list__container'>
      { loading ? (
        <CircularProgress size={24} />
      ): (
        files.map((file) => (
          <div
            key={file.id}
            className='drive-files-item'
            onClick={() => onClick(file.id)}
          >
            <div
              className='m-l-md d-flex align-center'
              style={{ color: activeFile === file.id ? colorPrimary : 'inherit' }}
            >
              {getFileIcon(file.type, 24, false, activeFile === file.id ? colorPrimary : '#000000')}
            </div>
            <div className='drive-files-item__name'>
              <div style={{ color: activeFile === file.id ? colorPrimary : 'inherit' }}>
                {file.title}
              </div>
              <div className='secondary font-size-xs'>{file.subtitle}</div>
            </div>
            <IconButton
              aria-label='launch'
              onClick={(e) => {
                e.stopPropagation();
                window.open(file.file.downloadUrl, '_blank');
              }}
            >
              <LaunchIcon color="secondary" />
            </IconButton>
          </div>
        ))
      )}
    </div>
  );
};

export default DriveFilesList;
