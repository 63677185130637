import React, { useMemo, memo } from 'react';

import CasesNumberItem from './CasesNumberItem';

import { caseStatusOptions } from '../../constants/enums';

import './home.scss';

const CasesNumberBlock = ({ cases, completedLoaded, completedCases }) => {
  const requestLength = useMemo(() => cases.filter((item) => item.status === caseStatusOptions.request).length, [cases]);
  const overdueLength = useMemo(() => cases.filter((item) => item.status === caseStatusOptions.overdue).length, [cases]);
  const bookedLength = useMemo(() => cases.filter((item) => item.status === caseStatusOptions.booked).length, [cases]);
  const completedLength = useMemo(() => completedCases.length, [completedCases]);

  return (
    <div className='cases-number-block'>
      <div className='m-b-lg'>
        <CasesNumberItem
          status={caseStatusOptions.request}
          number={requestLength}
          className='m-r-lg'
        />
        <CasesNumberItem
          status={caseStatusOptions.overdue}
          number={overdueLength}
          className='m-l-lg'
        />
      </div>
      <div className='m-t-lg'>
        <CasesNumberItem
          status={caseStatusOptions.booked}
          number={bookedLength}
          className='m-r-lg'
        />
        <CasesNumberItem
          status={caseStatusOptions.completed}
          number={completedLength}
          isCompleted
          isCompletedLoaded={completedLoaded}
          className='m-l-lg'
        />
      </div>
    </div>
  )
};

export default memo(CasesNumberBlock);
