import React, { useCallback } from 'react';

import Table from '../../shared/table/Table';

import { getCommaSeparatedNames } from '../../../utils/table';

const columns = [
  { title: 'name', field: 'name', type: 'default', sort: true },
  { title: 'procedures', field: 'procedureNames', type: 'default', sort: true },
  { title: 'hospitals', field: 'hospitalNames', type: 'default', sort: true },
  { title: 'qualifications', field: 'qualificationNames', type: 'default', sort: true },
];

const SurgeonsTable = (props) => {
  const {
    onSelectSurgeon,
    surgeons,
    selectedSurgeonId,
    procedures,
    hospitals,
    qualifications = [],
  } = props;

  const getProcedureNames = useCallback((ids) => getCommaSeparatedNames(procedures, ids), [surgeons, procedures]);
  const getHospitalNames = useCallback((ids) => getCommaSeparatedNames(hospitals, ids), [surgeons, hospitals]);
  const getQualificationNames = useCallback((ids) => getCommaSeparatedNames(qualifications, ids), [surgeons, qualifications]);

  return (
    <div style={{ width: '100%' }}>
      <Table
        rows={surgeons.map((surgeon) => ({
          ...surgeon,
          name: `${surgeon.title} ${surgeon.firstName} ${surgeon.lastName}`,
          procedureNames: getProcedureNames(surgeon.procedures),
          hospitalNames: getHospitalNames(surgeon.hospitals),
          qualificationNames: getQualificationNames(surgeon.qualifications),
        }))}
        columns={columns}
        onRowClick={onSelectSurgeon}
        selected={selectedSurgeonId}
        pagination
        checkboxes={false}
      />
    </div>
  );
};

export default SurgeonsTable;
