// const colorPalette = [
//   '#1f77b4', // Blue
//   '#ff7f0e', // Orange
//   '#2ca02c', // Green
//   '#d62728', // Red
//   '#9467bd', // Purple
//   '#8c564b', // Brown
//   '#e377c2', // Pink
//   '#7f7f7f', // Gray
//   '#bcbd22', // Yellow-Green
//   '#17becf',  // Turquoise
// ];

const colorPalette = [
  '#e8c1a0',
  '#f47560',
  '#f1e15b',
  '#e8a838',
  '#61cdbb',
  '#59a14f',
  '#af7aa1',
  '#ff9da7',
  '#9c755f',
  '#97e3d5',
];

const lighterColorPalette = [
  '#f4e2d1', // Much lighter version of #e8c1a0
  '#fab8a7', // Much lighter version of #f47560
  '#f9f2aa', // Much lighter version of #f1e15b
  '#f2c486', // Much lighter version of #e8a838
  '#a4e5db', // Much lighter version of #61cdbb
  '#a1cf97', // Much lighter version of #59a14f
  '#dabbd4', // Much lighter version of #af7aa1
  '#ffd6db', // Much lighter version of #ff9da7
  '#c8b3a8', // Much lighter version of #9c755f
  '#d2f3eb', // Much lighter version of #97e3d5
];


export default colorPalette;
export { lighterColorPalette };