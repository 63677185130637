import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function FormIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none"
          d="M7.5118 12.803L7 12.8049C5.60031 12.8049 4.23433 12.7614 2.91616 12.6787C1.95214 12.6182 1.18673 11.8528 1.12622 10.8888C1.04349 9.5706 1 8.20462 1 6.80493C1 5.40524 1.04349 4.03926 1.12622 2.72109C1.18673 1.75707 1.95214 0.991661 2.91616 0.931155C4.23433 0.84842 5.60031 0.804932 7 0.804932C8.39969 0.804932 9.76567 0.84842 11.0838 0.931155C12.0479 0.991661 12.8133 1.75707 12.8738 2.72109C12.9565 4.03926 13 5.40524 13 6.80493L12.9893 8.00379" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M13 8C12.5175 10.9783 9.65374 12.5857 7.5 12.8122C8.32109 11.718 9.44576 10.604 9.85676 8.9187C9.981 8.40925 10.4061 8 10.9305 8H13Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M4 5H8" />
  </SvgIcon>
}
