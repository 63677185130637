import React, { useState, useEffect, useCallback, useMemo } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import PreferenceIcon from '../../../assets/icons/PreferenceIcon';
import NotesIcon from '../../../assets/icons/NotesIcon';
import TaskIcon from '../../../assets/icons/TaskIcon';
import LinkIcon from '../../../assets/icons/LinkIcon';
import ContactIcon from '../../../assets/icons/ContactIcon';
import EditIcon from '../../../assets/icons/EditIcon';

import Modal from '../../shared/modal';
import AddButton from '../../shared/AddButton';
import PreferenceForm from '../forms/PreferenceForm';
import NoteForm from '../forms/NoteForm';
import EditContactModal from './EditContactModal';
import Input from '../../shared/Input';

import {
  getSurgeonPreferences, createPreference, deletePreference,
  getSurgeonNotes, createNote, getSurgeonTasks, updatePreference
} from '../../../actions/surgeonsActions';

import { useAlert } from '../../../hooks';

import userRoles from '../../../constants/userRoles';

const PreferencesList = (props) => {
  const {
    surgeon,
    procedures,
    hospitals,
    userRole,
    tenantColor,
    onContactUpdate,
    editing,
  } = props;

  const dispatch = useDispatch();
  const { showAlert } = useAlert();

  const [preferences, setPreferences] = useState([]);
  const [notes, setNotes] = useState([]);
  const [tasks, setTasks] = useState([]);

  const [isModalOpen, toggleModal] = useState(false);
  const [noteModalOpen, toggleNoteModal] = useState(false);
  const [selectedPreference, setSelectedPreference] = useState(null);
  const [editContactModal, toggleEditContactModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    onLoad(surgeon.id)
      .catch((err) => console.error(err));
  }, [surgeon]);

  const onLoad = async (surgeonId) => {
    setFetching(true);
    try {
      await fetchData(surgeonId);
    } catch (err) {
      console.error(err);
    } finally {
      setFetching(false);
    }
  };

  const fetchData = async (surgeonId) => {
    const surgeonPreferences = await dispatch(getSurgeonPreferences(surgeonId));
    const surgeonNotes = await dispatch(getSurgeonNotes(surgeonId));
    const surgeonTasks = await dispatch(getSurgeonTasks(surgeonId));
    setPreferences(surgeonPreferences || []);
    setNotes(surgeonNotes || []);
    setTasks(surgeonTasks || []);
  };

  const onCreatePreference = async (formObj, image) => {
    setLoading(true);
    const preferenceObj = {
      surgeonId: surgeon.id,
      procedureId: formObj.procedureId,
      hospitalId: formObj.hospitalId,
      preference: formObj.preference,
      links: formObj.links ? formObj.links.filter((v) => !!v) : []
    };

    try {
      await dispatch(createPreference(surgeon.id, preferenceObj, image));
      await fetchData(surgeon.id);

      setLoading(false);
      toggleModal(false);
      showAlert('success', 'Preference has been successfully created');
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const onUpdatePreference = async (formObj, image) => {
    if (selectedPreference) {
      setLoading(true);
      const preferenceObj = {
        surgeonId: surgeon.id,
        procedureId: formObj.procedureId,
        hospitalId: formObj.hospitalId,
        preference: formObj.preference,
        links: formObj.links ? formObj.links.filter((v) => !!v) : []
      };

      try {
        await dispatch(updatePreference(surgeon.id, selectedPreference.id, preferenceObj, image));
        await fetchData(surgeon.id);

        setLoading(false);
        setSelectedPreference(null);
        showAlert('success', 'Preference has been successfully updated');
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    }
  };

  const onPreferenceClick = (preference) => {
    if (userRole === userRoles.ADMIN.name) {
      setSelectedPreference(preference);
    }
  };

  const onCreateNote = async (formObj) => {
    const noteData = {
      note: formObj.note,
    };

    setLoading(true);

    try {
      dispatch(createNote(surgeon.id, noteData));
      const surgeonNotes = await dispatch(getSurgeonNotes(surgeon.id));
      setNotes(surgeonNotes || []);
      toggleNoteModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onImageClick = (url) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  const onDelete = async () => {
    try {
      setDeleting(true);
      await dispatch(deletePreference(surgeon.id, selectedPreference.id));
      await fetchData(surgeon.id);
      setSelectedPreference(null);
    } finally {
      setDeleting(false);
    }
  };

  const handleContactUpdate = async (formObj) => {
    await onContactUpdate(formObj);
    toggleEditContactModal(false);
  };

  // Memoized data

  const getProcedureName = useCallback((procedureId) => {
    const preferenceProcedure = procedures.find((procedure) => procedure.id === procedureId);
    return preferenceProcedure && preferenceProcedure.name ? preferenceProcedure.name : '';
  }, [preferences, procedures]);

  const getHospitalName = useCallback((hospitalId) => {
    const preferenceHospital = hospitals.find((hospital) => hospital.id === hospitalId);
    return preferenceHospital && preferenceHospital.name ? preferenceHospital.name : '';
  }, [preferences, hospitals]);

  const hospitalsList = useMemo(() => hospitals.filter((hospital) => {
    return surgeon.hospitals.includes(hospital.id);
  }), [hospitals, surgeon]);

  const proceduresList = useMemo(() => procedures.filter((procedure) => {
    return surgeon.procedures.includes(procedure.id);
  }), [procedures, surgeon]);

  return (
    <div className='block__container flex-1'>
      <div className='d-flex direction-column flex-1'>
        <div className='block-header'>
          <div className='d-flex'>
            <ContactIcon className='m-t-sm' />
            <div className='flex-1 font-bold font-size-bg m-l-md'>
              Contact
            </div>
          </div>
          <EditIcon color={tenantColor} onClick={() => toggleEditContactModal(true)} />
        </div>

        <div className='width-100 p-t-lg p-b-lg'>
          <div className='combined-fields__container'>
            <Input
              value={surgeon?.email}
              startAdornment='Email'
              borderless
              disabled
            />
            <Input
              value={surgeon?.phone}
              startAdornment='Phone'
              borderless
              disabled
            />
          </div>
        </div>

        <div className='block-header'>
          <div className='d-flex'>
            <PreferenceIcon className='m-t-sm' />
            <div className='flex-1 font-bold font-size-bg m-l-md'>
              Preferences
            </div>
          </div>
          <AddButton onClick={() => toggleModal(true)} />
        </div>

        {fetching ? (
          <div style={{ marginTop: 24 }} className='d-flex flex-center'>
            <CircularProgress />
          </div>
        ) : (
          preferences.map((preference) => (
            <div key={preference.id} className='m-t-lg block-body pointer' style={{ flex: 0 }} onClick={() => onPreferenceClick(preference)}>
              <div className='d-flex space-between'>
                <div className='font-bold'>{preference.authorFullName}</div>
                <div className='secondary'>{moment(preference.createdAt).fromNow()}</div>
              </div>
              <div className='m-t-sm secondary'>
                {`${getProcedureName(preference.procedureId)}, ${getHospitalName(preference.hospitalId)}`}
              </div>
              <div className='m-t-sm'>
                {preference.preference}
              </div>
              <div className='d-flex'>
                {preference.images && !!preference.images.length && (
                  <div className='d-flex m-t-md'>
                    {preference.images?.map((image) => (
                      <div
                        key={`${preference.id}_${image.fileName}`}
                        style={{ width: 50, height: 50, borderRadius: 8 }}
                        className='m-r-lg pointer'
                        onClick={() => onImageClick(image.downloadUrl)}
                      >
                        <img
                          src={image.downloadUrl}
                          alt='preference-preview'
                          style={{ width: 50, height: 50, borderRadius: 8 }}
                        />
                      </div>
                    ))}
                  </div>
                )}
                {preference.links && !!preference.links.length && (
                  <div className='d-flex m-t-md'>
                    {preference.links?.map((url) => (
                      <div
                        key={`${preference.id}_${url}`}
                        style={{ width: 50, height: 50, borderRadius: 8, border: '1px solid #eee' }}
                        className='m-r-lg pointer d-flex flex-center'
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(url, '_blank');
                        }}
                      >
                        <LinkIcon />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))
        )}

        <div className='block-header' style={{ marginTop: 24 }}>
          <div className='d-flex'>
            <NotesIcon className='m-t-sm' />
            <div className='flex-1 font-bold font-size-bg m-l-md'>
              Notes
            </div>
          </div>
          <AddButton onClick={() => toggleNoteModal(true)} />
        </div>

        {fetching ? (
          <div style={{ marginTop: 24 }} className='d-flex flex-center'>
            <CircularProgress />
          </div>
        ) : (
          notes.map((note) => (
            <div key={note.id} className='m-t-lg block-body' style={{ flex: 0 }}>
              <div className='d-flex space-between'>
                <div className='font-bold'>{note.authorFullName}</div>
                <div className='secondary'>{moment(note.createdAt).fromNow()}</div>
              </div>
              <div className='m-t-sm'>
                {note.note}
              </div>
            </div>
          ))
        )}

        <div className='block-header' style={{ marginTop: 24 }}>
          <div className='d-flex'>
            <TaskIcon className='m-t-sm' />
            <div className='flex-1 font-bold font-size-bg m-l-md'>
              Tasks
            </div>
          </div>
        </div>
        {fetching ? (
          <div style={{ marginTop: 24 }} className='d-flex flex-center'>
            <CircularProgress />
          </div>
        ) : (
          tasks.map((task) => (
            <div key={task.id} className='m-t-lg block-body' style={{ flex: 0 }}>
              <div className='d-flex space-between'>
                <div className='font-bold'>{task.title}</div>
                <div className='secondary'>{moment(task.createdAt).fromNow()}</div>
              </div>
              <div className='m-t-sm'>
                {task.description}
              </div>
            </div>
          ))
        )}
      </div>

      <Modal
        open={isModalOpen}
        onClose={() => toggleModal(false)}
      >
        <PreferenceForm
          procedures={proceduresList}
          hospitals={hospitalsList}
          onSubmit={onCreatePreference}
          onClose={() => toggleModal(false)}
          loading={loading}
          onDelete={onDelete}
          deleting={deleting}
        />
      </Modal>

      <Modal
        open={noteModalOpen}
        onClose={() => toggleNoteModal(false)}
      >
        <NoteForm
          loading={loading}
          onSubmit={onCreateNote}
          onClose={() => toggleNoteModal(false)}
          withoutText
        />
      </Modal>

      <Modal
        open={!!selectedPreference}
        onClose={() => setSelectedPreference(null)}
      >
        <PreferenceForm
          procedures={proceduresList}
          hospitals={hospitalsList}
          onSubmit={onUpdatePreference}
          onClose={() => setSelectedPreference(null)}
          loading={loading}
          mode="update"
          initialValues={selectedPreference}
          onDelete={onDelete}
          deleting={deleting}
        />
      </Modal>

      <EditContactModal
        open={editContactModal}
        onClose={() => toggleEditContactModal(false)}
        initialValues={surgeon}
        onSubmit={handleContactUpdate}
        loading={editing}
      />
    </div>
  );
};

export default PreferencesList;
