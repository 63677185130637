import { GET_NOTIFICATIONS, SET_NOTIFICATIONS, SHOW_NOTIFICATION, CLOSE_NOTIFICATION, SET_LAST_DOC_TIMESTAMP } from '../actions/actionTypes';

const initialState = {
  list: [],
  isLoading: false,
  isLoaded: false,
  open: false,
  notification: null,
  lastDocTimestamp: 0,
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return { ...state, isLoading: true };
    case SET_NOTIFICATIONS:
      return {
        ...state,
        list: [...state.list, ...action.notifications],
        isLoaded: true,
        isLoading: false
      };
    case SHOW_NOTIFICATION:
      return {
        ...state,
        open: true,
        notification: action.notification,
      };
    case CLOSE_NOTIFICATION:
      return {
        ...state,
        open: false,
        message: null,
      };
    case SET_LAST_DOC_TIMESTAMP:
      return {
        ...state,
        lastDocTimestamp: action.timestamp,
      };
    default:
      return state;
  }
};

export default notificationsReducer;
