import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function OverdueIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M1.52681 5.55187C2.7234 4.07021 4.01776 2.77222 5.4947 1.57309C6.39274 0.843969 7.46945 0.848763 8.36833 1.57309C9.8528 2.76928 11.1443 4.06361 12.3385 5.55187C13.0261 6.40869 13.0308 7.48232 12.3385 8.33834C11.1295 9.83357 9.82036 11.1417 8.32393 12.3502C7.48223 13.0299 6.40902 13.0253 5.56653 12.3502C4.05288 11.1372 2.7398 9.82418 1.52681 8.31059C0.851108 7.46744 0.84652 6.39423 1.52681 5.55187Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M6.93823 4.4382V6.9382" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M6.93823 9.1882L6.93823 9.4382" />
  </SvgIcon>
}
