import { GET_TASKS, SET_TASKS } from '../actions/actionTypes';

const initialState = {
  list: [],
  isLoading: false,
  isLoaded: false
};

const tasksReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TASKS:
      return { ...state, isLoading: true };
    case SET_TASKS:
      return {
        ...state,
        list: action.tasks,
        isLoaded: true,
        isLoading: false
      };
    default:
      return state;
  }
};

export default tasksReducer;
