import React, { useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import UploadIcon from '../../assets/icons/UploadIcon';
import DeleteIcon from '../../assets/icons/DeleteIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

const FileUpload = (props) => {
  const {
    file,
    onUpload,
    placeholder,
    id,
    accept,
    actionLabel = 'Change',
    preview,
    downloadUrl,
    disabled,
    onRemove,
  } = props;
  const classes = useStyles();

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      onUpload(e.target.files[0]);
      e.target.value = '';
    }
  };

  const getFileAcceptability = useCallback(() => {
    switch (accept) {
      case 'image':
        return 'image/*';
      case 'document':
        return 'application/msword, application/pdf, application/vnd.ms-powerpoint, .doc, .docx, .ppt, .pptx, .csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case 'pdf':
        return 'application/pdf';
      case 'video':
        return 'video/*';
      case 'pdfAndDoc':
        return 'application/msword, application/pdf, .doc, .docx';
      default:
        return '*';
    }
  }, []);

  const onClick = (e) => {
    if (downloadUrl) {
      e.preventDefault();
      window.open(downloadUrl, '_blank');
    }
  }

  return (
    <div className={classes.root}>
      <input
        accept={getFileAcceptability()}
        className={classes.input}
        id={`file-upload_${id}`}
        type='file'
        onChange={handleFileChange}
        disabled={disabled}
      />
      <label htmlFor={`file-upload_${id}`} style={{ margin: 0, width: '100%' }}>
        <div className='image-upload pointer align-center '>
          <div className='d-flex space-between width-100'>
            <div className='modal-select__count' onClick={onClick}>{placeholder || 'Upload'}</div>
            {(file || placeholder) && !!onRemove ? (
              <div
                className='modal-select__placeholder'
                onClick={(e) => {
                  e.preventDefault();
                  onRemove();
                }}
              >
                <span>Remove</span>
                <DeleteIcon color='secondary' />
              </div>
            ) : (
              <div className='modal-select__placeholder'>
                <span>{actionLabel || (file ? 'Change' : 'Add')}</span>
                <UploadIcon color='secondary' />
              </div>
            )}
          </div>

          {preview && (
            <div className='img-preview__container'>
              <img src={preview} alt='upload-preview' />
            </div>
          )}
        </div>
      </label>
    </div>
  );
};

export default FileUpload;
