import React, { Fragment } from 'react';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';

import TermsAndPolicy from './Terms&Conditions';
import MicrosoftAuthButton from './MicrosoftAuthButton';
import Button from '../shared/Button';
import Input from '../shared/Input';
import Alert from '../shared/Alert';

import routes from '../../constants/routes';

const LoginForm = ({ onSignIn, loading, error, setError, errorText, onMicrosoftSignIn, microsoftLoading }) => {
  return (
    <Fragment>
      <Form onSubmit={onSignIn}>
        {
          ({ handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              className='login-form'
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  handleSubmit();
                }
              }}
            >
              <div className='logo' />
              <span className='login-text'>Login to Case</span>
              <span className='login-subtext'>Simple Surgical Case Management</span>

              <Field name='email'>
                {({ input }) => (
                  <Input
                    name={input.name}
                    value={input.value}
                    placeholder='Email'
                    type='email'
                    onChange={input.onChange}
                  />
                )}
              </Field>

              <Field name='password'>
                {({ input }) => (
                  <Input
                    name={input.name}
                    value={input.value}
                    showPassword={false}
                    placeholder='Password'
                    type='password'
                    onChange={input.onChange}
                  />
                )}
              </Field>

              <div className='btn-group'>
                <Link to={routes.SIGN_UP}>
                  <Button
                    type='submit'
                    text='Sign Up'
                    background='#0B0B0B'
                  >
                    <div className='right-arrow' />
                  </Button>
                </Link>
                <Button
                  type='submit'
                  text='Login'
                  onClick={handleSubmit}
                  disabled={loading}
                  loading={loading}
                />
              </div>

              <div className='d-flex flex-center width-100' style={{ marginBottom: '32px' }}>
                <MicrosoftAuthButton
                  onClick={onMicrosoftSignIn}
                  disabled={microsoftLoading}
                  loading={microsoftLoading}
                />
              </div>

              <div className='text-group'>
                <Link to={routes.FORGOT_PASSWORD}>
                  <span className='forgot-pass-text'>Forgot your password?</span>
                </Link>
                <TermsAndPolicy />
              </div>
            </form>
          )}
      </Form>
      <Alert
        variant="error"
        message={errorText}
        open={error}
        onClose={() => setError(false)}
      />
    </Fragment>
  );
};

export default LoginForm;
