import React, { useState } from 'react';

import Modal from '../shared/modal';
import { TreeViewContainer } from './index';

const MoveFileModal = (props) => {
  const {
    open,
    onClose,
    tree,
    tenantColor,
    onSubmit,
    loading,
    file,
  } = props;

  const [activeFolder, setActiveFolder] = useState('root');

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Move Item'
      onSubmit={() => onSubmit(activeFolder)}
      closeButton="Cancel"
      submitButton="Move"
      disabled={loading || activeFolder === 'root' || activeFolder === file?.folder}
      loading={loading}
      fullWidth
    >
      <div style={{ paddingLeft: '24px', paddingRight: '24px', paddingBottom: '16', paddingTop: '8px', minHeight: '350px' }}>
        <div>Please select the folder you wish to move the following item to</div>
        <div className='m-t-lg m-b-lg font-bold font-size-bg'>{file?.title || ''}</div>
        <div className='secondary m-b-sm'>Folder Tree</div>
        <TreeViewContainer
          data={tree}
          colorPrimary={tenantColor}
          onClick={(folderId) => {
            setActiveFolder(folderId);
          }}
          active={activeFolder}
        />
      </div>
    </Modal>
  );
};

export default MoveFileModal;
