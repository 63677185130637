import React, { useState, useMemo } from 'react';
import { orderBy as sortBy } from 'lodash';
import { Column, Table, SortDirection } from 'react-virtualized';
import 'react-virtualized/styles.css';

import Checkbox from '../../shared/Checkbox';
import Button from '../../shared/Button';
import Modal from '../../shared/modal/Modal';
import Input from '../../shared/Input';
import Select from '../../shared/Select';

import { itemTypeOptions, setPositionOptions } from '../../../constants/enums';

import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

const AddButton = ({
  variant,
  disabled,
  onClick,
  color,
}) => (
  <Button
    type="outlined"
    height={25}
    width={25}
    disabled={disabled}
    onClick={onClick}
    borderColor={color}
  >
    {variant === 'minus' ? (
      <RemoveIcon fontSize="small" style={{ fill: disabled ? '#cccccc' : color }} />
    ) : (
      <AddIcon fontSize="small" style={{ fill: disabled ? '#cccccc' : color }} />
    )}
  </Button>
);

const AddItemsModal = (props) => {
  const {
    options,
    onSubmit,
    onClose,
    open,
    selectedItems,
    loading,
    withManual,
    onManualClick,
    title,
  } = props;

  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState('');
  const [type, setType] = useState('');

  const handleCheck = (itemId) => {
    let temp = selected.slice();

    if (temp?.map((i) => i.id)?.includes(itemId)) {
      temp = temp.filter((i) => i.id !== itemId);
    } else {
      temp = [...temp, { id: itemId, quantity: 1 }];
    }

    setSelected(temp);
  };

  const onMinusClick = (row) => {
    const temp = selected.slice();
    const item = temp?.find((i) => i.id === row.id);
    if (!item || item.quantity <= 1) {
      return;
    }

    item.quantity = item.quantity - 1;
    setSelected(temp);
  };

  const onPlusClick = (row) => {
    const temp = selected.slice();
    const item = temp?.find((i) => i.id === row.id);
    if (!item) {
      return;
    }

    item.quantity = item.quantity + 1;
    setSelected(temp);
  };

  const list = useMemo(() => (
    sortBy(
      options.filter((item) => {
        let filter = true;

        if (!!type) {
          filter = item?.type === itemTypeOptions[type]?.value;
        }

        return item?.code?.toLowerCase().includes(search?.toLowerCase()) && filter && !selectedItems?.includes(item.id);
      }),
      'code',
    )
  ), [options, search, type]);

  const handleClose = () => {
    onClose();
    setSelected([]);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      size="lg"
    >
      <div className="form__container section-items-modal__container">
        <div className="form__body">
          <div className="form__subtitle">
            {title || 'Add Items'}
          </div>

          <div className="section-items-modal__filters">
            <div className="m-r-md">
              <Input
                type="search"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>

            <div className="m-l-md">
              <Select
                value={type}
                onChange={setType}
                options={[{ label: 'All', value: '' }, ...Object.values(itemTypeOptions)]}
              />
            </div>
          </div>

          <div className="secondary font-size-sm m-b-sm">Items</div>
          <div className="m-b-lg form__select-users" style={{ minWidth: 860, minHeight: 540 }}>
            {list?.length ? (
              <VirtualizedTable
                data={list?.map((item) => ({
                  ...item,
                  description: item.checklistDescription || item.description || '',
                  type: itemTypeOptions[item.type]?.label
                }))}
                checked={selected}
                onCheck={handleCheck}
                onMinusClick={onMinusClick}
                onPlusClick={onPlusClick}
              />
            ) : (
              <div className="text-center secondary p-md">
                No Items
              </div>
            )}
          </div>

          <div className="form__actions">
            <div className='d-flex'>
              {withManual && (
                <div className='m-r-lg'>
                  <Button
                    type="outlined"
                    text="Manual"
                    onClick={onManualClick}
                  />
                </div>
              )}
              <Button
                type="submit"
                text={title || "Add Items"}
                loading={loading}
                disabled={!selected || loading}
                onClick={() => {
                  onSubmit(selected);
                  handleClose();
                  setSelected([]);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const VirtualizedTable = ({ data, checked, onCheck, onMinusClick, onPlusClick }) => {
  const [sortBy, setSortBy] = useState(''); // State to track the currently sorted column
  const [sortDirection, setSortDirection] = useState(SortDirection.ASC); // State to track the sort direction

  const sort = ({ sortBy, sortDirection }) => {
    return data.slice().sort((a, b) => {
      const valueA = a[sortBy] || '';
      const valueB = b[sortBy] || '';

      if (sortDirection === SortDirection.ASC) {
        return valueA?.localeCompare(valueB);
      } else {
        return valueB?.localeCompare(valueA);
      }
    });
  };

  const sortedData = sort({ sortBy, sortDirection });

  const handleSort = ({ sortBy, sortDirection }) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection);
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Table
        width={860} // Width of the table
        height={500} // Height of the table
        headerHeight={36} // Height of the table header
        rowHeight={36} // Height of each table row
        rowCount={sortedData.length} // Total number of rows
        rowGetter={({ index }) => sortedData[index]} // Function to get the data for each row
        sort={handleSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
      >
        <Column
          label=""
          disableSort
          dataKey="id"
          width={50}
          headerRenderer={({ label, sortBy, sortDirection }) => (
            <div>
              {/*<Checkbox*/}
              {/*  input={{*/}
              {/*    onClick: (e) => onCheckAll(e.target.checked),*/}
              {/*    checked: data.every((item) => checked?.includes(item.id)),*/}
              {/*  }}*/}
              {/*  fontSize="small"*/}
              {/*/>*/}
            </div>
          )}
          cellRenderer={({ rowIndex, rowData }) => {
            return (
              <div className='p-l-md'>
                <Checkbox
                  input={{
                    onClick: () => onCheck(rowData.id),
                    checked: checked?.map((i) => i.id)?.includes(rowData.id),
                  }}
                  index={rowIndex}
                  fontSize="small"
                />
              </div>
            )
          }}
        />
        <Column
          label="Code"
          dataKey="code"
          width={200}
          headerRenderer={({ label, sortBy, sortDirection }) => (
            <div>
              {label}
              {sortBy === 'code' && (
                <SortCaret direction={sortDirection}/>
              )}
            </div>
          )}
        />
        <Column
          label="Description"
          dataKey="description"
          width={500}
          headerRenderer={({ label, sortBy, sortDirection }) => (
            <div>
              {label}
              {sortBy === 'description' && (
                <SortCaret direction={sortDirection}/>
              )}
            </div>
          )}
        />
        <Column
          label="Type"
          dataKey="type"
          width={150}
          headerRenderer={({ label, sortBy, sortDirection }) => (
            <div>
              {label}
              {sortBy === 'type' && (
                <SortCaret direction={sortDirection}/>
              )}
            </div>
          )}
        />
        <Column
          label=""
          dataKey="quantity"
          width={150}
          cellRenderer={({ rowData }) => (
            !rowData?.allowQuantity && checked?.map((i) => i.id)?.includes(rowData.id) ? (
              <div className="d-flex flex-end">
                <AddButton
                  variant="minus"
                  onClick={() => onMinusClick(rowData)}
                  color={setPositionOptions.CONSIGNED.color}
                />
                <div
                  style={{
                    color: setPositionOptions.CONSIGNED.color,
                    textAlign: 'center',
                    marginLeft: 4,
                    marginRight: 4,
                    width: 25,
                    fontWeight: 'bold'
                  }}
                >
                  {checked?.find((i) => i.id === rowData.id)?.quantity || 1}
                </div>
                <AddButton
                  variant="plus"
                  onClick={() => onPlusClick(rowData)}
                  color={setPositionOptions.CONSIGNED.color}
                />
              </div>
            ) : null
          )}
        />
      </Table>
    </div>
  );
};

const SortCaret = ({ direction }) => {
  return (
    <span style={{ marginLeft: '5px' }}>
      {direction === SortDirection.ASC ? '▲' : '▼'}
    </span>
  );
};

export default AddItemsModal;
