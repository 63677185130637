import React from 'react';

import CompletedIcon from '@material-ui/icons/CheckCircleOutline';
import OverdueIcon from '@material-ui/icons/Schedule';
import PendingIcon from '../assets/PendingIcon';

import { countStatusOptions } from '../constants/enums';
import { statusColors } from '../constants/counts';

const getStatusIcon = (status, filled) => {
  switch (status) {
    case countStatusOptions.pending:
      return <PendingIcon fill={filled ? statusColors.PENDING : '#ffffff'} />;
    case countStatusOptions.completed:
      return <CompletedIcon style={{ fill: filled ? statusColors.COMPLETED : '#ffffff' }} />;
    case countStatusOptions.overdue:
      return <OverdueIcon style={{ fill: filled ? statusColors.OVERDUE : '#ffffff' }}  />;
    default:
      return <PendingIcon fill={filled ? statusColors.PENDING : '#ffffff'} />;
  }
};

const getStatusLabel = (status) => {
  switch (status) {
    case countStatusOptions.pending:
      return 'Pending';
    case countStatusOptions.completed:
      return 'Completed';
    case countStatusOptions.overdue:
      return 'Overdue';
    default:
      return 'Pending';
  }
};

export {
  getStatusIcon,
  getStatusLabel,
};
