import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function QRIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none"
          d="M3.32081 5.95558C3.36986 6.43604 3.75796 6.82429 4.23819 6.87553C4.52094 6.9057 4.81025 6.92981 5.10461 6.92981C5.39898 6.92981 5.68829 6.9057 5.97104 6.87553C6.45127 6.82429 6.83936 6.43604 6.88842 5.95558C6.91711 5.67463 6.9397 5.38718 6.9397 5.09473C6.9397 4.80227 6.91711 4.51483 6.88842 4.23387C6.83936 3.75341 6.45127 3.36517 5.97104 3.31392C5.68829 3.28375 5.39898 3.25964 5.10461 3.25964C4.81025 3.25964 4.52094 3.28375 4.23819 3.31392C3.75796 3.36517 3.36986 3.75341 3.32081 4.23387C3.29212 4.51483 3.26953 4.80227 3.26953 5.09473C3.26953 5.38718 3.29212 5.67463 3.32081 5.95558Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M10.0135 0.941589C10.3843 0.981537 10.7518 1.02436 11.1157 1.06653C12.0752 1.17774 12.8408 1.94283 12.9486 2.90271C12.9892 3.26425 13.0301 3.62938 13.0681 3.99782" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M10.0135 13.0562C10.3843 13.0163 10.7518 12.9734 11.1157 12.9313C12.0752 12.8201 12.8408 12.055 12.9486 11.0951C12.9892 10.7335 13.0301 10.3684 13.0681 9.99998" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M4 0.941589C3.62915 0.981537 3.26164 1.02436 2.89776 1.06653C1.93827 1.17774 1.17267 1.94283 1.06491 2.90271C1.02432 3.26425 0.983393 3.62938 0.945352 3.99782" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M4 13.0562C3.62915 13.0163 3.26164 12.9734 2.89776 12.9313C1.93827 12.8201 1.17267 12.055 1.06491 11.0951C1.02432 10.7335 0.983393 10.3684 0.945352 9.99998" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M3.60461 9.21228L3.60461 10.7404L5.10461 10.7404" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M10.7032 10.7404L10.7032 9.21225L9.20319 9.21225" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M10.7305 6.97632L9.23047 6.97632" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M6.9397 10.7263L6.9397 9.22632" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M10.7032 5.09473L10.7032 3.56662L9.20319 3.56662" />
  </SvgIcon>
}

