/* eslint-disable  */
import React, { useMemo, useState } from 'react';
import { sum } from 'lodash';
import moment from 'moment';

import WarningIcon from '@material-ui/icons/Error';

import ShippingIcon from '../../../assets/icons/ShippingIcon';
import ShipIcon from '../../../assets/icons/ShipIcon';
import ReturnIcon from '../../../assets/icons/ReturnIcon';
import ConsignmentIcon from '../../../assets/icons/ConsignedSetIcon';
import SetIcon from '../../../assets/icons/SetIcon';

import Tabs from '../../shared/Tabs';

import { getDiffDays } from '../../../utils/date';

import { setAllocationStatuses, kitVariantTypes } from '../../../constants/enums';

const { SHIPPED, RETURNED, USAGE, CONFIRMED } = setAllocationStatuses;

const CaseSetsPreview = ({ sets = [], onPreviewClick, shipDate, datePassed, kitVariant, isWarning }) => {
  const [activeTab, setActiveTab] = useState(datePassed ? 1 : 0);

  const shipped = useMemo(() => {
    if (!sets) {
      return [];
    }
    return sets.filter((item) => [SHIPPED.value, CONFIRMED.value, RETURNED.value, USAGE.value]?.includes(item.status));
  }, [sets]);

  const resolved = useMemo(() => {
    if (!sets) {
      return [];
    }
    return sets.filter((item) => (
      item.status === setAllocationStatuses.RETURNED.value || item.status === setAllocationStatuses.USAGE.value
    ));
  }, [sets]);

  const returned = useMemo(() => {
    if (!sets) {
      return [];
    }
    return sets.filter((item) => item.status === setAllocationStatuses.RETURNED.value);
  }, [sets]);

  const usage = useMemo(() => {
    if (!sets) {
      return [];
    }
    return sets.filter((item) => item.status === setAllocationStatuses.USAGE.value);
  }, [sets]);

  const setsTabs = useMemo(() => ([
    { label: kitVariant === kitVariantTypes.consignment ? CONFIRMED.label : SHIPPED.label },
    { label: 'Resolved' },
  ]), [sets]);

  const totalShipped = useMemo(() => sum(shipped.map((item) => item.quantity)), [sets]);
  const totalResolved = useMemo(() => sum(resolved.map((item) => item.quantity)), [sets]);

  // const getReturnedConsumables = (array = []) => {
  //   return sum(array.map((item) => {
  //     if (item?.consumables?.length && item?.usageConsumables) {
  //       return item.consumables?.filter((i) => !item?.usageConsumables?.includes(i))?.length;
  //     }
  //
  //     return 0;
  //   }));
  // };

  // const totalReturned = useMemo(() => {
  //   let result = sum(returned.map((item) => item.quantity));
  //
  //   if (usage && usage.length) {
  //     let returnedConsumables = getReturnedConsumables(usage);
  //     result = result + returnedConsumables;
  //   }
  //
  //   return result;
  // }, [sets]);
  //
  // const totalUsage = useMemo(() => (
  //   sum(usage.map((item) => {
  //     if (item?.consumables?.length && item?.usageConsumables?.length) {
  //       return item.usageConsumables?.length;
  //     }
  //
  //     return 0;
  //   }))
  // ), [sets]);

  const shipDiff = useMemo(() => getDiffDays(shipDate, moment()), [shipDate]);
  const showWarning = useMemo(() => (
    sets.some((item) => (
      item.status === setAllocationStatuses.ASSIGNED.value || item.status === setAllocationStatuses.AVAILABLE.value
    )) && shipDiff < 4
  ), [shipDiff]);

  const renderPreview = () => {
    switch (activeTab) {
      case 0:
        return (
          <div
            className='case-implants__container pointer'
            onClick={onPreviewClick}
          >
            <div className='case-implant-item'>
              <div className='d-flex direction-column align-start space-between'>
                {kitVariant === kitVariantTypes.consignment ? (
                  <ConsignmentIcon className='m-b-md' style={{ fontSize: 20 }} />
                ) : (
                  <ShipIcon className='m-b-md' style={{ fontSize: 20 }} />
                )}
                <div>
                  <div className='font-bold'>
                    {kitVariant === kitVariantTypes.consignment ? 'Confirmed' : 'Shipped'}
                  </div>
                  <div className='secondary font-size-sm'>
                    {kitVariant === kitVariantTypes.consignment ? 'Total confirmed' : 'Total shipped'}
                  </div>
                </div>
              </div>
              <div className='d-flex direction-column space-between'>
                <div />
                <div style={{ fontSize: 30, marginBottom: '-8px' }}>{totalShipped || 0}</div>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div
            className='case-implants__container pointer'
            onClick={onPreviewClick}
          >
            <div className='case-implant-item'>
              <div className='d-flex direction-column align-start space-between'>
                <ReturnIcon className='m-b-md' style={{ fontSize: 20 }} />
                <div>
                  <div className='font-bold'>Resolved</div>
                  <div className='secondary font-size-sm'>Total resolved</div>
                </div>
              </div>
              <div className='d-flex direction-column space-between'>
                <div />
                <div style={{ fontSize: 30, marginBottom: '-8px' }}>{totalResolved || 0}</div>
              </div>
            </div>
          </div>
        );
      // case 2:
      //   return (
      //     <div
      //       className='case-implants__container pointer'
      //       onClick={onPreviewClick}
      //     >
      //       <div className='case-implant-item'>
      //         <div className='d-flex direction-column align-start space-between'>
      //           <UsageIcon className='m-b-md' style={{ fontSize: 20 }} />
      //           <div>
      //             <div className='font-bold'>Usage</div>
      //             <div className='secondary font-size-sm'>Total usage</div>
      //           </div>
      //         </div>
      //         <div className='d-flex direction-column space-between'>
      //           <div />
      //           <div style={{ fontSize: 30, marginBottom: '-8px' }}>{totalUsage || 0}</div>
      //         </div>
      //       </div>
      //     </div>
      //   );
      default:
        return null;
    }
  };

  return (
    <div className='case-usage__container' style={{ marginTop: 16 }}>
      <div className='d-flex space-between'>
        <div className='d-flex'>
          {kitVariant === kitVariantTypes.consignment ? (
            <SetIcon />
          ) : (
            <ShippingIcon />
          )}
          <div className='font-size-bg font-bold m-l-md'>
            {kitVariant === kitVariantTypes.consignment ? 'Sets' : 'Shipping'}
          </div>
        </div>
        {showWarning && (
          <div className='d-flex'>
            <WarningIcon style={{ color: setAllocationStatuses.ASSIGNED.color, marginRight: 4 }} />
            <span className='font-bold' style={{ color: setAllocationStatuses.ASSIGNED.color }}>
              {shipDiff > 0 ? `${shipDiff} day(s) to ship` : 'Overdue'}
            </span>
          </div>
        )}
      </div>

      <div className='case-usage-tabs__container' style={{ display: 'flex', flexDirection: 'column' }}>
        <Tabs
          tabs={setsTabs}
          activeTab={activeTab}
          onTabChange={setActiveTab}
        >
          {renderPreview()}
        </Tabs>

        {isWarning && (
          <div className='d-flex flex-end'>
            <span className='font-bold' style={{ color: setAllocationStatuses.QUARANTINED.color }}>
              Set Availability Warning
            </span>
            <ShippingIcon style={{ marginLeft: 4 }} color={setAllocationStatuses.QUARANTINED.color} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CaseSetsPreview;
