import React from 'react';
import { Field, Form } from 'react-final-form';

import Button from '../../shared/Button';
import Input from '../../shared/Input';
import Modal from '../../shared/modal';

const EditContactModal = (props) => {
  const {
    initialValues,
    onSubmit,
    loading,
    onClose,
    open,
  } = props;

  const { name } = initialValues || {};

  const validate = (values) => {
    const errors = {};
    return errors;
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <div className='form__container'>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={{
            ...initialValues,
          }}
          subscription={{ pristine: true, invalid: true }}
        >
          {
            ({ handleSubmit, submitting, invalid, pristine}) => (
              <form onSubmit={handleSubmit} className='form__body'>
                <div className='form__subtitle'>
                  { name ? name : 'Add Surgeon' }
                </div>
                <div className='combined-fields__container'>
                  <Field name='email'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        startAdornment='Email'
                        borderless
                        type='text'
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                  <Field name='phone'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        startAdornment='Phone'
                        borderless
                        type='text'
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>

                <div className='form__actions'>
                  <Button
                    type='submit'
                    text={'Save Changes'}
                    disabled={invalid || loading || pristine}
                    loading={loading}
                    onClick={handleSubmit}
                  />
                  <Button
                    type='cancel'
                    onClick={onClose}
                  />
                </div>
              </form>
            )}
        </Form>
      </div>
    </Modal>
  );
};

export default EditContactModal;
