import React, { useMemo, useState } from 'react';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import Select from '../../shared/Select';
import Button from '../../shared/Button';
import ModalSelect from '../../shared/modal-select/ModalSelect';

import { targetOptions, rangeOptions } from '../../../constants/setTurnsReporting';

import './styles.scss';

const ConsignmentForm = (props) => {
  const {
    hospitals = [],
    hospital,
    setHospital,
    target,
    setTarget,
    range,
    setRange,
    onSubmit,
    loading,
  } = props;

  const [isOpen, toggleModal] = useState(false);

  const hospitalName = useMemo(() => {
    const _hospital = hospitals?.find((h) => h.id === hospital);
    return _hospital?.name || '';
  }, [hospital, hospitals]);

  return (
    <div className='set-turns-form__container'>
      <div className='set-turns-form__field'>
        <div>Hospital</div>
        <div className='set-turns-form__select-box' onClick={() => toggleModal(true)}>
          {hospitalName || 'Select'}
          <KeyboardArrowDownIcon style={{ fill: '#c6c8c9' }} />
        </div>

        <ModalSelect
          title='Select Hospital'
          listTitle='Hospitals'
          options={hospitals}
          onChange={(value) => {
            if (value && value.length) {
              setHospital(value[0]);
            } else {
              setHospital(null);
            }
          }}
          selected={[hospital]}
          onClose={() => toggleModal(false)}
          isOpen={isOpen}
          singleSelect={true}
        />
      </div>
      <div className='set-turns-form__field'>
        <div>Performance Target (turns/month)</div>
        <Select
          value={target}
          onChange={setTarget}
          options={targetOptions}
        />
      </div>
      <div className='set-turns-form__field'>
        <div>Date Range</div>
        <Select
          value={range}
          onChange={setRange}
          options={rangeOptions}
        />
      </div>

      <div className='set-turns-form__button'>
        <Button
          onClick={onSubmit}
          disabled={loading || !hospital}
          loading={loading}
          text='Run Report'
        />
      </div>
    </div>
  )
};

export default ConsignmentForm;
