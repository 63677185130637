import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

import CloudUploadIcon from '../../../assets/icons/CloudUploadIcon';

import FileItem from './FileItem';

import { defaultStaticColor } from '../../../styles/theme/palette';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '32px 20px',
  cursor: 'pointer',
  borderWidth: 2,
  borderRadius: 20,
  borderColor: '#cccccc',
  borderStyle: 'dashed',
  backgroundColor: '#ffffff',
  color: '#b1b1b1',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const rejectStyle = {
  borderColor: '#F53434',
};

const Dropzone = (props) => {
  const {
    files,
    type,
    disabled,
    multiple = true,
    text,
    path,
    onUpload,
    onRemove,
    onUploadComplete,
    hideFilesList,
    tenantColor,
    withoutProgress
  } = props;

  const options = {
    onDrop: (acceptedFiles) => {
      onUpload(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    multiple,
    disabled,
  };

  if (type === 'image') {
    options.accept = 'image/jpeg, image/png';
  }
  if (type === 'pdf') {
    options.accept = 'application/pdf';
  }
  if (type === 'pdfAndDoc') {
    options.accept = 'application/msword, application/pdf, .doc, .docx';
  }

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } =
    useDropzone(options);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? { borderColor: tenantColor || defaultStaticColor } : {}),
      ...(isDragAccept ? { borderColor: tenantColor || defaultStaticColor } : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <div className='d-flex direction-column align-center flex-center'>
          <CloudUploadIcon color='secondary' fontSize="large" />
          <div className='secondary m-t-md'>
            {text || 'Drag and drop some files here or click to upload'}
          </div>
        </div>
      </div>
      {!hideFilesList && (
        <aside>
          {files?.map((file) => (
            <FileItem
              key={file.name}
              file={file}
              onRemove={onRemove}
              path={path}
              onUploadComplete={onUploadComplete}
              withoutProgress={withoutProgress}
            />
          ))}
        </aside>
      )}
    </div>
  );
};

export default Dropzone;
