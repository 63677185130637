import React, { useState, useEffect, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';

import SurgeonsTable from '../../../components/surgeons/SurgeonsTable';
import SurgeonsFilters from '../../../components/surgeons/SurgeonsFilters';
import SurgeonForm from '../../../components/surgeons/SurgeonForm';
import Input from '../../../components/shared/Input';
import Modal, { ConfirmationModal, UploadModal } from '../../../components/shared/modal';
import Alert from '../../../components/shared/Alert';

import {
  getSurgeons,
  createSurgeon,
  updateSurgeon,
  subscribeToSurgeons,
  bulkDeleteSurgeons,
  uploadSurgeons,
  subscribeToQualifications,
  exportSurgeonsData,
} from '../../../actions/surgeonsActions';
import { getProcedures } from '../../../actions/proceduresActions';
import { getHospitals } from '../../../actions/hospitalsActions';

import { filterSurgeons } from '../../../utils/table';

import { statusOptions } from '../../../constants/enums';

const statusFilterOptions = [
  { label: 'Active', value: statusOptions.active },
  { label: 'Deactivated', value: statusOptions.deactivated }
];

const SurgeonsPage = (props) => {
  const {
    surgeons,
    createSurgeon,
    updateSurgeon,
    subscribeToSurgeons,
    subscribeToQualifications,
    bulkDeleteSurgeons,
    getProcedures,
    getHospitals,
    uploadSurgeons,
    procedures,
    hospitals,
    users,
    tenantColor,
    qualifications = [],
    deletePermission
  } = props;

  const dispatch = useDispatch();

  const [isModalCreateOpen, toggleModalCreate] = useState(false);
  const [isModalDeleteOpen, toggleModalDelete] = useState(false);
  const [isUploadModalOpen, toggleUploadModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDeleting, toggleDeleting] = useState(false);

  const [successMessage, setSuccessMessage] = useState(null);

  const [selectedSurgeon, setSelectedSurgeon] = useState(null);
  const [checkedSurgeons, setCheckedSurgeons] = useState([]);

  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [exportLoading, setExportLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = subscribeToSurgeons();
    const unsubscribeToQualifications = subscribeToQualifications();

    onLoad().catch((err) => console.error(err));

    return () => {
      unsubscribe();
      unsubscribeToQualifications();
    };
  }, []);

  const onLoad = async () => {
    await getProcedures();
    await getHospitals();
  };

  const onSelectSurgeon = (surgeon) => {
    setSelectedSurgeon(surgeon);
  };

  // Create surgeon
  const onCreateSurgeon = async (formObj) => {
    setLoading(true);
    const surgeonObj = {
      title: formObj.title || '',
      firstName: formObj.firstName,
      lastName: formObj.lastName,
      hospitals: [],
      procedures: [],
      qualifications: [],
    };

    try {
      await createSurgeon(surgeonObj);
      setLoading(false);
      toggleModalCreate(false);
      setSuccessMessage('Surgeon has been successfully created');
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Update surgeon
  const onUpdateSurgeon = async (formObj) => {
    setLoading(true);
    const surgeonObj = {
      active: formObj.active,
      title: formObj.title || '',
      firstName: formObj.firstName,
      lastName: formObj.lastName,
      hospitals: formObj.hospitals || [],
      procedures: formObj.procedures || [],
      qualifications: formObj.qualifications || [],
    };

    try {
      await updateSurgeon(formObj.id, surgeonObj);
      setLoading(false);
      setSuccessMessage('Surgeon has been successfully updated');
      setSelectedSurgeon(formObj);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Delete checked surgeons
  const onDeleteSurgeons = async () => {
    toggleDeleting(true);

    try {
      await bulkDeleteSurgeons(checkedSurgeons);

      toggleModalDelete(false);
      setCheckedSurgeons([]);
      setSelectedSurgeon(null);
    } catch (err) {
      console.error(err);
    } finally {
      toggleDeleting(false);
    }
  };

  // Upload surgeons
  const onUpload = async (surgeons) => {
    setLoading(true);

    try {
      await uploadSurgeons(surgeons);
      setLoading(false);
      setSuccessMessage('Surgeons have been successfully uploaded');
      toggleUploadModal(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const handleDeleteClick = () => {
    if (checkedSurgeons.length) {
      toggleModalDelete(true);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    // setCheckedSurgeons([]);
  };

  const surgeonList = useMemo(() => {
    return surgeons.filter((surgeon) => filterSurgeons(surgeon, search, statusFilter));
  }, [surgeons, search, statusFilter]);

  const handleCheck = (surgeonId) => {
    const checked = checkedSurgeons.slice();

    if (checked.includes(surgeonId)) {
      const index = checked.indexOf(surgeonId);

      checked.splice(index, 1);
      setCheckedSurgeons(checked);

      return;
    }

    checked.push(surgeonId);
    setCheckedSurgeons(checked);
  };

  const handleCheckAll = () => {
    const checked = checkedSurgeons.length === surgeonList.length;
    const temp = [];

    if (!checked) {
      surgeonList.forEach((surgeon) => {
        temp.push(surgeon.id);
      });

      setCheckedSurgeons(temp);
      return;
    }

    setCheckedSurgeons(temp);
  };

  const exportData = useMemo(() => ([
    ...surgeons?.map((surgeon) => {
      const name = `${surgeon.title} ${surgeon.firstName} ${surgeon.lastName}`;
      const procedureNames = surgeon?.procedures?.map((procedureId) => {
        const procedure = procedures?.find((p) => p.id === procedureId);
        return procedure?.name || '';
      })?.join(', ');
      const hospitalNames = surgeon?.hospitals?.map((hospitalId) => {
        const hospital = hospitals?.find((h) => h.id === hospitalId);
        return hospital?.name || '';
      })?.join(', ');
      const qualificationNames = surgeon?.qualifications?.map((qualificationId) => {
        const qualification = qualifications?.find((q) => q.id === qualificationId);
        return qualification?.name || '';
      })?.join(', ');
      const status = surgeon.active ? 'Active' : 'Deactivated';

      return {
        name,
        procedures: procedureNames,
        hospitals: hospitalNames,
        qualifications: qualificationNames,
        status,
      };
    })
  ]), [surgeons, hospitals, procedures, qualifications]);

  const onExportClick = async () => {
    try {
      setExportLoading(true);
      await dispatch(exportSurgeonsData(exportData));
    } finally {
      setExportLoading(false);
    }
  };

  return (
    <div className='settings-cmp__main'>
      <span className='settings-title'>Surgeons</span>
      <div className='settings-cmp__body'>
        <div className='filters-container'>
          <SurgeonsFilters
            onAddSurgeonClick={() => toggleModalCreate(true)}
            onDelete={handleDeleteClick}
            status={statusFilter}
            setStatus={setStatusFilter}
            statuses={statusFilterOptions}
            onUploadClick={() => toggleUploadModal(true)}
            deletePermission={deletePermission}
            onExportClick={onExportClick}
            exportData={exportData}
            loading={exportLoading}
          />
          <SurgeonsTable
            onSelectSurgeon={onSelectSurgeon}
            surgeons={surgeonList}
            selectedSurgeonId={selectedSurgeon ? selectedSurgeon.id : null}
            handleCheck={handleCheck}
            checkedSurgeons={checkedSurgeons}
            handleCheckAll={handleCheckAll}
            procedures={procedures}
            hospitals={hospitals}
            qualifications={qualifications}
          />
        </div>
        { surgeons && !!surgeons.length && (
          <div className='form-container'>
            <Input
              type='search'
              placeholder='Search Surgeons'
              value={search}
              onChange={handleSearch}
            />
            { selectedSurgeon && (
              <SurgeonForm
                initialValues={selectedSurgeon}
                buttonText='Save Changes'
                onSubmit={onUpdateSurgeon}
                loading={loading}
                mode='update'
                procedures={procedures}
                hospitals={hospitals}
                users={users}
                qualifications={qualifications}
              />
            )}
          </div>
        )}
      </div>

      <ConfirmationModal
        open={isModalDeleteOpen}
        onClose={() => toggleModalDelete(false)}
        onSubmit={onDeleteSurgeons}
        title='Are you sure you want to delete these surgeons?'
        submitText='Delete'
        loading={isDeleting}
      />

      <Modal
        open={isModalCreateOpen}
        onClose={() => toggleModalCreate(false)}
      >
        <SurgeonForm
          buttonText='Add Surgeon'
          onSubmit={onCreateSurgeon}
          loading={loading}
          mode='create'
          onClose={() => toggleModalCreate(false)}
          qualifications={qualifications}
        />
      </Modal>

      <UploadModal
        open={isUploadModalOpen}
        onClose={() => toggleUploadModal(false)}
        title='Upload Surgeons'
        tenantColor={tenantColor}
        onSubmit={onUpload}
        loading={loading}
        fields={['Title', 'First name', 'Last name']}
      />

      <Alert
        variant='success'
        message={successMessage}
        open={!!successMessage}
        onClose={() => setSuccessMessage(null)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    surgeons: state.surgeons.list,
    qualifications: state.surgeons.qualifications || [],
    procedures: state.procedures.list,
    hospitals: state.hospitals.list,
    users: state.users.list,
    tenantColor: state.tenant.currentTenant.colorPrimary,
    deletePermission: state.user.currentUser?.deletePermission,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSurgeons: () => dispatch(getSurgeons()),
    createSurgeon: (surgeon) => dispatch(createSurgeon(surgeon)),
    updateSurgeon: (id, surgeonData) => dispatch(updateSurgeon(id, surgeonData)),
    subscribeToSurgeons: () => dispatch(subscribeToSurgeons()),
    subscribeToQualifications: () => dispatch(subscribeToQualifications()),
    bulkDeleteSurgeons: (surgeonIds) => dispatch(bulkDeleteSurgeons(surgeonIds)),
    getProcedures: () => dispatch(getProcedures()),
    getHospitals: () => dispatch(getHospitals()),
    uploadSurgeons: (surgeons) => dispatch(uploadSurgeons(surgeons)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurgeonsPage);
