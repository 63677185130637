import React from 'react';
import { Field, Form } from 'react-final-form';

import Button from '../shared/Button';
import Input from '../shared/Input';

import '../../styles/shared/form.scss';

const GtinForm = (props) => {
  const {
    mode = 'create',
    initialValues,
    buttonText,
    onSubmit,
    loading,
    onClose,
    gtins = [],
  } = props;

  const { gtin } = initialValues || {};

  const validate = (values) => {
    const errors = {};

    if (!values.gtin) {
      errors.gtin = 'Required';
    }
    if (gtins?.map((g) => g.gtin)?.includes(values?.gtin) && mode === 'create') {
      errors.gtin = 'Error';
    }
    if (!values.reference) {
      errors.reference = 'Required';
    }

    return errors;
  };

  return (
    <div className='form__container'>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{
          ...initialValues,
          products: initialValues?.products || []
        }}
        subscription={{ pristine: true, invalid: true }}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <div className='form__subtitle'>
                { gtin ? gtin : 'Add GTIN' }
              </div>
              { mode === 'update' && (
                <Field name='active'>
                  {({ input, meta }) => (
                    <Input
                      type='switch'
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      placeholder='Status'
                    />
                  )}
                </Field>
              )}

              <div className='m-b-sm'>
                <div className='field__title'>GTIN</div>
                <Field name='gtin'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      type='text'
                      onChange={input.onChange}
                      error={meta.error && meta.error === 'Error'}
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-sm'>
                <div className='field__title'>Reference</div>
                <Field name='reference'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-sm'>
                <div className='field__title'>Description</div>
                <Field name='description'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              <div className='form__actions'>
                <Button
                  type='submit'
                  text={buttonText || 'Save Changes'}
                  disabled={invalid || loading || pristine}
                  loading={loading}
                  onClick={handleSubmit}
                />
                { mode === 'create' && (
                  <Button
                    type='cancel'
                    onClick={onClose}
                  />
                )}
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default GtinForm;
