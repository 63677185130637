import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function InfoIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path id="Vector" stroke={color || defaultColor} fill="none" stroke-linecap="round" stroke-linejoin="round"
          d="M1.9639285714285717 10.532785714285714c2.083714285714286 2.447714285714286 6.988428571428572 2.447714285714286 9.072142857142858 0 1.9202857142857144 -2.2545714285714284 1.7745000000000002 -6.667142857142857 -0.5357857142857143 -8.596714285714285C8.4305 0.20707142857142857 4.5685714285714285 0.20707142857142857 2.499714285714286 1.9360714285714287 0.18942857142857142 3.8656428571428574 0.04364285714285714 8.278214285714284 1.9639285714285717 10.532785714285714Z"
          stroke-width="1"></path>
    <path id="Vector 1187" stroke={color || defaultColor} fill="none" stroke-linecap="round" stroke-linejoin="round"
          d="M5.335571428571429 5.571428571428571h0.4615a0.9285714285714286 0.9285714285714286 0 0 1 0.9285714285714286 0.9285714285714286l0 2.5396428571428573"
          stroke-width="1"></path>
    <path id="Vector 1188" stroke={color || defaultColor} fill="none" stroke-linecap="round" stroke-linejoin="round"
          d="M5.348571428571429 9.039642857142857h2.7671428571428573" stroke-width="1"></path>
    <path id="Vector 1189" stroke={color || defaultColor} fill="none" stroke-linecap="round" stroke-linejoin="round"
          d="M6.738642857142857 3.4542857142857146v0.29992857142857143" stroke-width="1"></path>
  </SvgIcon>
}
