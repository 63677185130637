import React, { useState, useEffect } from 'react';

import MuiPhoneNumber from 'material-ui-phone-number';

import Input from '../shared/Input';
import Button from '../shared/Button';
import Modal from '../shared/modal/Modal';

import { countryAlphaCodes } from '../../constants/countries';

import './hospitals.scss';

const BillingAddressModal = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    initialValues,
    defaultCountry,
  } = props;

  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postCode, setPostCode] = useState('');
  const [apartment, setApartment] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const [attn, setAttn] = useState('');
  const [phone, setPhone] = useState('');
  const [department, setDepartment] = useState('');
  const [neighborhood , setNeighborhood] = useState('');

  useEffect(() => {
    if (open && initialValues) {
      setStreet(initialValues?.street || '');
      setCity(initialValues?.city || '');
      setState(initialValues?.state || '');
      setPostCode(initialValues?.postCode || '');
      setApartment(initialValues?.apartment || '');
      setBuildingName(initialValues?.buildingName || '');
      setAttn(initialValues?.attn || '');
      setPhone(initialValues?.phone || '');
      setDepartment(initialValues?.department || '');
      setNeighborhood(initialValues?.neighborhood || '');
    }
  }, [open, initialValues]);

  const handleSubmit = () => {
    const address = {
      street: street || '',
      city: city || '',
      state: state || '',
      postCode: postCode || '',
      apartment: apartment || '',
      buildingName: buildingName || '',
      attn: attn || '',
      phone: phone || '',
      department: department || '',
      neighborhood: neighborhood || '',
    };
    onSubmit(address);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <div className='form__container hospital-addresses__modal'>
        <div className='form__body'>

          <div className='form__subtitle'>
            Billing Address
          </div>

          <div className='m-t-lg m-b-md'>
            <div className='d-flex'>
              <div className='m-r-sm flex-1'>
                <Input
                  type='text'
                  placeholder='Street'
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                />
              </div>
              <div className='m-l-sm flex-1'>
                <Input
                  type='text'
                  placeholder='City'
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
            </div>

            <div className='d-flex'>
              <div className='m-r-sm flex-1'>
                <Input
                  type='text'
                  placeholder='State'
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </div>
              <div className='m-l-sm flex-1'>
                <Input
                  type='text'
                  placeholder='Postcode'
                  value={postCode}
                  onChange={(e) => setPostCode(e.target.value)}
                />
              </div>
            </div>

            <div className='d-flex'>
              <div className='m-r-sm flex-1'>
                <Input
                  type='text'
                  placeholder='Apartment (optional)'
                  value={apartment}
                  onChange={(e) => setApartment(e.target.value)}
                />
              </div>
              <div className='m-l-sm flex-1'>
                <Input
                  type='text'
                  placeholder='Building name (optional)'
                  value={buildingName}
                  onChange={(e) => setBuildingName(e.target.value)}
                />
              </div>
            </div>

            <div className='d-flex'>
              <div className='m-r-sm flex-1'>
                <Input
                  type='text'
                  placeholder='Neighborhood (optional)'
                  value={neighborhood}
                  onChange={(e) => setNeighborhood(e.target.value)}
                />
              </div>
              <div className='m-l-sm flex-1'>
                <Input
                  type='text'
                  placeholder='Department (optional)'
                  value={department}
                  onChange={(e) => setDepartment(e.target.value)}
                />
              </div>
            </div>

            <div className='d-flex'>
              <div className='m-r-sm flex-1'>
                <Input
                  type='text'
                  placeholder='Attention to (optional)'
                  value={attn}
                  onChange={(e) => setAttn(e.target.value)}
                />
              </div>
              <div className='m-l-sm flex-1'>
                <MuiPhoneNumber
                  value={phone}
                  onChange={setPhone}
                  fullWidth
                  defaultCountry={countryAlphaCodes[defaultCountry]}
                  placeholder='Phone (optional)'
                  className='form__phone p-l-md'
                  InputProps={{ disableUnderline: true }}
                />
              </div>
            </div>
          </div>

          <div className='form__actions m-t-md'>
            <Button
              disabled={!street || !city || !state || !postCode}
              type='submit'
              text='Submit'
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BillingAddressModal;
