import React, { useMemo } from 'react';
import moment from 'moment';

import { getStatusIcon } from '../../../utils/cases';

import './checklists.scss';

const CaseInfo = (props) => {
  const { activeCase, surgeons, hospitals } = props;
  const { status, active, date, time, surgeon, hospital } = activeCase;

  const surgeonName = useMemo(() => {
    const caseSurgeon = surgeons.find((item) => item.id === surgeon);
    return caseSurgeon ? `${caseSurgeon.title} ${caseSurgeon.firstName} ${caseSurgeon.lastName}` : '';
  }, [surgeon, surgeons]);

  const hospitalName = useMemo(() => {
    const caseHospital = hospitals.find((item) => item.id === hospital);
    return caseHospital ? caseHospital.name : '';
  }, [hospital, hospitals]);

  return (
    <div className='case-checklists-info__container m-l-lg' style={{ minWidth: 300 }}>
      <div className='d-flex'>
        {getStatusIcon(status, true, !active)}
        <div className='font-size-bg font-bold m-l-md m-r-sm'>
          {date ? moment(date).local().format('Do MMM YYYY') : ''}
        </div>
        <div className='font-bold secondary m-t-sm'>{time}</div>
      </div>
      <div className='m-t-md'>
        <div className='font-size-bg font-bold'>{surgeonName}</div>
        <div>{hospitalName}</div>
        <div>{activeCase.patientReference}</div>
      </div>
    </div>
  );
};

export default CaseInfo;
