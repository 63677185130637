import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function MoveIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path  stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M1.71004 9.94921C1.10137 9.90768 0.617947 9.42674 0.579728 8.82236 0.527469 7.99596 0.5 7.13959 0.5 6.26207c0 -0.87751 0.027469 -1.73388 0.079728 -2.56028 0.038219 -0.60437 0.521642 -1.08532 1.130312 -1.12685 0.7155 -0.04883 1.4069 -0.07445 2.26401 -0.07445 0.28313 0 0.54818 0.00279 0.80199 0.0083 0.25311 0.00549 0.49906 0.08879 0.69949 0.24232 0.62748 0.48067 1.0427 0.89345 1.50019 1.4777 0.03861 0.04931 0.07485 0.10011 0.1087 0.15225"
          strokeWidth="1"></path>
    <path  stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M5.14951 13.4209c0.83262 0.0518 1.69543 0.0791 2.57954 0.0791 0.23006 0 0.45867 -0.0018 0.68568 -0.0055 0.49255 -0.0079 0.97393 -0.1633 1.36288 -0.4633 0.58869 -0.4541 1.00449 -0.8662 1.48879 -1.489 0.1547 -0.199 0.2386 -0.4431 0.2442 -0.6943 0.0055 -0.2519 0.0083 -0.515 0.0083 -0.796 0 -0.85073 -0.0258 -1.53697 -0.075 -2.24713 -0.0418 -0.60413 -0.5264 -1.08394 -1.1353 -1.12188 -0.83263 -0.05187 -1.69544 -0.07913 -2.57955 -0.07913 -0.88411 0 -1.74692 0.02726 -2.57953 0.07913 -0.60892 0.03794 -1.09348 0.51775 -1.13533 1.12188 -0.04919 0.71016 -0.07501 1.3964 -0.07501 2.24713 0 0.8507 0.02582 1.5369 0.07501 2.2471 0.04185 0.6041 0.52641 1.0839 1.13532 1.1219Z"
          strokeWidth="1"></path>
    <path  stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M9.65292 0.5c1.40628 0 2.54628 1.14002 2.54628 2.5463 0 0.24425 -0.0344 0.48046 -0.0986 0.70407"
          strokeWidth="1"></path>
    <path  stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M13.5 3.3487c-0.2601 0.52029 -0.7804 1.04057 -1.3007 1.30071 -0.5203 -0.26014 -1.0406 -0.78042 -1.3007 -1.30071"
          strokeWidth="1"></path>
  </SvgIcon>
}
