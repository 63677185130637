import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import Input from '../../../../components/shared/Input';
import { ConfirmationModal, UploadModal } from '../../../../components/shared/modal';
import { RepeatingCountsTable, RepeatingCountForm, RepeatingCountsFilters } from '../../../../components/scan';
import CountModal from '../../../../components/scan/repeating-count-modal/CountModal';
import Alert from '../../../../components/shared/Alert';

import {
  getRepeatingCounts,
  createRepeatingCount,
  updateRepeatingCount,
  subscribeToRepeatingCounts,
  bulkDeleteRepeatingCounts,
  uploadRepeatingCounts,
} from '../../../../actions/repeatingCountsActions';
import { getHospitals } from '../../../../actions/hospitalsActions';
import { getGroups } from '../../../../actions/groupsActions';
import { getKits } from '../../../../actions/kitsActions';
import { getSurgeons } from '../../../../actions/surgeonsActions';
import { getSets } from '../../../../actions/setsActions';

import { filterRepeatingCounts } from '../../../../utils/table';

import { statusOptions } from '../../../../constants/enums';

const statusFilterOptions = [
  { label: 'Active', value: statusOptions.active },
  { label: 'Deactivated', value: statusOptions.deactivated }
];

const RepeatingCountsPage = (props) => {
  const {
    repeatingCounts,
    tenantColor,
    createRepeatingCount,
    updateRepeatingCount,
    subscribeToRepeatingCounts,
    bulkDeleteRepeatingCounts,
    uploadRepeatingCounts,
    getHospitals,
    getGroups,
    getKits,
    getSurgeons,
    getSets,
    hospitals,
    groups,
    users,
    surgeons,
    sets,
    kits,
    deletePermission,
  } = props;

  const [isModalCreateOpen, toggleModalCreate] = useState(false);
  const [isModalDeleteOpen, toggleModalDelete] = useState(false);
  const [isUploadModalOpen, toggleUploadModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [successMessage, setSuccessMessage] = useState(null);

  const [selectedCount, setSelectedCount] = useState(null);
  const [checkedCounts, setCheckedCounts] = useState([]);

  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  useEffect(() => {
    const unsubscribe = subscribeToRepeatingCounts();

    onLoad().catch(console.error);

    return () => {
      unsubscribe();
    };
  }, []);

  const onLoad = async () => {
    await getHospitals();
    await getGroups();
    await getKits();
    await getSurgeons();
    await getSets();
  };

  const onSelectCount = (count) => {
    setSelectedCount(count);
  };

  // Create count
  const onCreateCount = async (formObj) => {
    setLoading(true);
    const countObj = {
      name: formObj.name || '',
      hospitalId: formObj.hospitalId || null,
      location: formObj.location || '',
      frequency: formObj.frequency || 1,
      startDate: formObj.startDate || new Date(),
      dueDays: formObj.dueDays || 1,
      kit: formObj.kit || null,
      users: formObj.users || [],
      groups: formObj.groups || [],
    };

    try {
      await createRepeatingCount(countObj);
      setLoading(false);
      toggleModalCreate(false);
      setSuccessMessage('Repeating count has been successfully added');
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Update count
  const onUpdateCount = async (formObj) => {
    setLoading(true);
    const countObj = {
      active: formObj.active,
      name: formObj.name || '',
      hospitalId: formObj.hospitalId || null,
      location: formObj.location || '',
      frequency: formObj.frequency || 1,
      startDate: formObj.startDate || new Date(),
      dueDays: formObj.dueDays || 1,
      groups: formObj.groups || [],
      users: formObj.users || [],
      kit: formObj.kit || null,
    };

    try {
      await updateRepeatingCount(formObj.id, countObj);
      setLoading(false);
      setSuccessMessage('Repeating count has been successfully updated');
      setSelectedCount(formObj);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Delete checked counts
  const onDeleteCounts = async () => {
    await bulkDeleteRepeatingCounts(checkedCounts);

    toggleModalDelete(false);
    setCheckedCounts([]);
    setSelectedCount(null);
  };

  const handleDeleteClick = () => {
    if (checkedCounts.length) {
      toggleModalDelete(true);
    }
  };

  // Upload counts
  const onUpload = async (counts) => {
    setLoading(true);

    try {
      await uploadRepeatingCounts(counts);
      setLoading(false);
      setSuccessMessage('Repeating counts has been successfully uploaded');
      toggleUploadModal(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    // setCheckedGtins([]);
  };

  const countsList = useMemo(() => {
    return repeatingCounts?.filter((count) => filterRepeatingCounts(count, search, statusFilter));
  }, [repeatingCounts, search, statusFilter]);

  const handleCheck = (countId) => {
    const checked = checkedCounts.slice();

    if (checked.includes(countId)) {
      const index = checked.indexOf(countId);

      checked.splice(index, 1);
      setCheckedCounts(checked);

      return;
    }

    checked.push(countId);
    setCheckedCounts(checked);
  };

  const handleCheckAll = () => {
    const checked = checkedCounts.length === countsList.length;
    const temp = [];

    if (!checked) {
      countsList.forEach((count) => {
        temp.push(count.id);
      });

      setCheckedCounts(temp);
      return;
    }

    setCheckedCounts(temp);
  };

  return (
    <div className='settings-cmp__main'>
      <span className='settings-title'>Repeating Counts</span>
      <div className='settings-cmp__body'>
        <div className='filters-container'>
          <RepeatingCountsFilters
            onAddClick={() => toggleModalCreate(true)}
            onDelete={handleDeleteClick}
            status={statusFilter}
            setStatus={setStatusFilter}
            statuses={statusFilterOptions}
            onUploadClick={() => {}}
            deletePermission={deletePermission}
          />
          <RepeatingCountsTable
            onSelectCount={onSelectCount}
            counts={countsList}
            selectedCountId={selectedCount ? selectedCount.id : null}
            handleCheck={handleCheck}
            checkedCounts={checkedCounts}
            handleCheckAll={handleCheckAll}
            hospitals={hospitals}
          />
        </div>
        {repeatingCounts && !!repeatingCounts.length && (
          <div className='form-container'>
            <Input
              type='search'
              placeholder='Search Counts'
              value={search}
              onChange={handleSearch}
            />
            {selectedCount && (
              <RepeatingCountForm
                initialValues={selectedCount}
                buttonText='Save Changes'
                onSubmit={onUpdateCount}
                loading={loading}
                mode='update'
                hospitals={hospitals}
                groups={groups}
                users={users}
                kits={kits}
              />
            )}
          </div>
        )}
      </div>

      <ConfirmationModal
        open={isModalDeleteOpen}
        onClose={() => toggleModalDelete(false)}
        onSubmit={onDeleteCounts}
        title='Are you sure you want to delete these counts?'
        submitText='Delete'
      />

      <UploadModal
        open={isUploadModalOpen}
        onClose={() => toggleUploadModal(false)}
        title='Upload Repeating Counts'
        tenantColor={tenantColor}
        onSubmit={onUpload}
        loading={loading}
        fields={[]}
      />

      <CountModal
        open={isModalCreateOpen}
        onClose={() => toggleModalCreate(false)}
        onSubmit={onCreateCount}
        users={users}
        surgeons={surgeons}
        hospitals={hospitals}
        kits={kits}
        sets={sets}
        groups={groups}
        mode='create'
      />

      <Alert
        variant='success'
        message={successMessage}
        open={!!successMessage}
        onClose={() => setSuccessMessage(null)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    repeatingCounts: state.repeatingCounts.list,
    tenantColor: state.tenant.currentTenant.colorPrimary,
    hospitals: state.hospitals.list,
    groups: state.groups.list,
    users: state.users.list,
    kits: state.kits.list,
    surgeons: state.surgeons.list,
    sets: state.sets.list?.filter((set) => !!set.active),
    deletePermission: state.user.currentUser?.deletePermission,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRepeatingCounts: () => dispatch(getRepeatingCounts()),
    createRepeatingCount: (gtin) => dispatch(createRepeatingCount(gtin)),
    updateRepeatingCount: (id, gtinData) => dispatch(updateRepeatingCount(id, gtinData)),
    subscribeToRepeatingCounts: () => dispatch(subscribeToRepeatingCounts()),
    bulkDeleteRepeatingCounts: (gtinIds) => dispatch(bulkDeleteRepeatingCounts(gtinIds)),
    uploadRepeatingCounts: (gtins) => dispatch(uploadRepeatingCounts(gtins)),
    getHospitals: () => dispatch(getHospitals()),
    getGroups: () => dispatch(getGroups()),
    getKits: () => dispatch(getKits()),
    getSurgeons: () => dispatch(getSurgeons()),
    getSets: () => dispatch(getSets()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RepeatingCountsPage);
