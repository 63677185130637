import React, { useState, useMemo } from 'react';
import NumberFormat from 'react-number-format';

import ArrowIconDown from '@material-ui/icons/KeyboardArrowDown';
import ReceiptIcon from '../../../assets/icons/ReceiptIcon';
import CheckIcon from '../../../assets/icons/CheckIcon';

import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';

import { setAllocationStatuses } from '../../../constants/enums';

const StyledMenuItem = withStyles((theme) => ({
  root: {
    paddingTop: '8px',
    paddingBottom: '8px',
  }
}))(MenuItem);

const ProformaReceiptContainer = (props) => {
  const {
    proforma = [],
    defaultSalesTax,
    proformaApproved,
    currency = 'USD'
  } = props;

  const [taxInclusive, setTaxInclusive] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const priceWithoutTax = useMemo(() => (
    proforma
      ?.filter((item) => !!item.value)?.map((item) => {
      const quantity = item.quantity || 1;
      const value = quantity * Number(item.value);

      return item.discount ? value - (value * item.discount / 100) : value;
    })
      ?.reduce((a, b) => a + b, 0)
  ), [proforma]);

  const subtotal = useMemo(() => {
    if (!defaultSalesTax) {
      return priceWithoutTax;
    }
    if (taxInclusive) {
      return priceWithoutTax - (priceWithoutTax * defaultSalesTax / 100);
    }
    return priceWithoutTax;
  }, [priceWithoutTax, taxInclusive, defaultSalesTax]);

  const totalTax = useMemo(() => {
    if (!defaultSalesTax) {
      return 0;
    }
    return priceWithoutTax * defaultSalesTax / 100;
  }, [priceWithoutTax, defaultSalesTax]);

  return (
    <div className='case-usage__container'>
      <>
        <div className='d-flex space-between'>
          <div className='d-flex'>
            <ReceiptIcon />
            <div className='font-size-bg font-bold m-l-md'>
              Value
            </div>
          </div>
          {proformaApproved && (
            <div className="font-bold d-flex" style={{ color: setAllocationStatuses.SHIPPED.color }}>
              <CheckIcon color={setAllocationStatuses.SHIPPED.color} />
              <span className='m-l-sm'>Approved</span>
            </div>
          )}
        </div>
        <div className='case-notes-list__container m-t-lg d-flex direction-column space-between' style={{ alignItems: 'inherit' }}>
          <div className='d-flex space-between font-bold'>
            <div>Amounts Are</div>
            <div
              className='d-flex pointer'
              aria-label='more'
              aria-controls='long-menu'
              aria-haspopup='true'
              onClick={handleClick}
            >
              Tax {`${taxInclusive ? 'Inclusive' : 'Exclusive'}`} <ArrowIconDown color='primary' fontSize="small" />
            </div>
            <Menu
              id='long-menu'
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  width: 150,
                  marginTop: '40px'
                },
                elevation: 3,
              }}
            >
              <StyledMenuItem
                onClick={() => {
                  setTaxInclusive(false);
                  handleClose();
                }}
              >
                <span>Tax Exclusive</span>
              </StyledMenuItem>
              <StyledMenuItem
                onClick={() => {
                  setTaxInclusive(true);
                  handleClose();
                }}
              >
                <span>Tax Inclusive</span>
              </StyledMenuItem>
            </Menu>
          </div>
          <div>
            <div className='d-flex space-between p-b-md'>
              <div>Subtotal</div>
              <div>
                <NumberFormat
                  value={Number(subtotal)}
                  displayType="text"
                  thousandSeparator={true}
                  fixedDecimalScale={2}
                  decimalScale={2}
                />
              </div>
            </div>
            <div className='d-flex space-between p-b-md border-bottom' style={{ borderColor: '#eee' }}>
              <div>Total Tax</div>
              <div>
                <NumberFormat
                  value={Number(totalTax)}
                  displayType="text"
                  thousandSeparator={true}
                  fixedDecimalScale={2}
                  decimalScale={2}
                />
              </div>
            </div>
            <div className='d-flex space-between p-t-md'>
              <div>Total</div>
              <div className='font-size-lg font-bold'>
                <NumberFormat
                  value={subtotal + totalTax}
                  displayType="text"
                  thousandSeparator={true}
                  prefix={`${currency} `}
                  fixedDecimalScale={2}
                  decimalScale={2}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default ProformaReceiptContainer;
