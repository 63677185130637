import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function LocationIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M7.57764 12.7762C8.85295 11.671 11.7971 8.7707 11.7971 5.54704C11.7971 2.89771 9.64937 0.750001 7.00004 0.750001C4.35071 0.750001 2.203 2.89771 2.203 5.54704C2.203 8.7707 5.14712 11.671 6.42243 12.7762C6.75759 13.0666 7.24249 13.0666 7.57764 12.7762Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M7.00006 7.23014C8.08517 7.23014 8.96484 6.35048 8.96484 5.26536C8.96484 4.18024 8.08517 3.30058 7.00006 3.30058C5.91494 3.30058 5.03528 4.18024 5.03528 5.26536C5.03528 6.35048 5.91494 7.23014 7.00006 7.23014Z" />
  </SvgIcon>
}
