import React, { useState, useEffect, useMemo } from 'react';

import Checkbox from '../Checkbox';
import Button from '../Button';
import Modal from '../modal/Modal';

import './modal-select.scss';

const GroupsModal = (props) => {
  const {
    groups,
    onChange,
    selected,
    onClose,
    isOpen,
    singleSelect = false,
  } = props;

  const [selectedGroups, setSelectedGroups] = useState(selected || []);

  useEffect(() => {
    setSelectedGroups(selected || []);
  }, [selected]);

  const handleClick = (group) => {
    if (singleSelect) {
      if (selectedGroups.includes(group)) {
        return setSelectedGroups([]);
      }
      return setSelectedGroups([group]);
    }

    const temp = selectedGroups.slice();
    if (temp.includes(group)) {
      const index = temp.indexOf(group);

      temp.splice(index, 1);
      setSelectedGroups(temp);
      return;
    }

    temp.push(group);
    setSelectedGroups(temp);
  };

  const list = useMemo(() => {
    return groups;
  }, [groups]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <div className='form__container'>
        <div className='form__body'>

          <div className='form__subtitle'>
            Groups
          </div>

          <div className='m-b-lg form__select-users'>
            {
              list.map((group) => (
                <div className='md-user-info' key={group.id}>
                  <Checkbox
                    input={{
                      onClick: () => handleClick(group.id),
                      checked: selectedGroups.indexOf(group.id) !== -1
                    }}
                    fontSize='small'
                    label={group.name}
                  />
                </div>
              ))
            }
          </div>

          <div className='form__actions'>
            <Button
              type='submit'
              text='Submit'
              onClick={() => {
                onChange(selectedGroups);
                onClose();
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GroupsModal;
