import React, { useState, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import moment from 'moment';

import DeleteIcon from '../../assets/icons/DeleteIcon';

import Button from '../shared/Button';
import Input from '../shared/Input';
import ModalSelectInput from '../shared/modal-select/ModalSelectInput';
import ModalSelect from '../shared/modal-select/ModalSelect';
import Checkbox from '../shared/Checkbox';
import TimePicker from '../shared/time-picker/TimePicker';

import { validateEmail } from '../../utils/validators';

import '../../styles/shared/form.scss';

const OverdueShippingTriggersForm = (props) => {
  const {
    mode = 'create',
    initialValues,
    buttonText,
    onSubmit,
    loading,
    products,
    onClose,
    withToggle = true,
  } = props;

  const { name } = initialValues || {};

  const [isModalOpen, toggleModal] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [emails, setEmails] = useState([]);
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    if (initialValues && initialValues.emails) {
      setEmails(initialValues.emails);
    }
  }, [initialValues]);

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Required';
    }

    if (mode === 'update' && !values?.allProducts && !values?.products?.length) {
      errors.products = 'Required';
    }

    return errors;
  };

  const getProductNames = (productIds) => {
    return productIds.map((id) => {
      const product = products.find((item) => item.id === id);
      return product ? product.name : '';
    })
  };

  const onDeleteEmail = (email) => {
    const filterEmails = emails.filter((item) => item !== email);
    setEmails(filterEmails);
  }

  const onAddNewEmail = () => {
    const value = newEmail?.trim();
    if (value && !emails.includes(value) && validateEmail(value)) {
      setEmails([...emails, value]);
      setNewEmail('');
    } else {
      setEmailError(true);
    }
  };

  return (
    <div className='form__container'>
      <Form
        onSubmit={(formObj) => onSubmit(formObj, emails)}
        validate={validate}
        initialValues={mode === 'update' ? {
          ...initialValues,
          products: initialValues?.products || [],
        } : null}
        subscription={{ pristine: true, invalid: true, values: true }}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine, values }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <div className='form__subtitle'>
                { name ? name : 'Add Trigger' }
              </div>
              { mode === 'update' && (
                <Field name='active'>
                  {({ input, meta }) => (
                    <Input
                      type='switch'
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      placeholder='Status'
                    />
                  )}
                </Field>
              )}

              <div className='m-b-sm'>
                <div className='field__title'>Name</div>
                <Field name='name'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      placeholder='Name'
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              <div className=''>
                <div className='field__title'>Emails</div>
                <div>
                  <div className='d-flex'>
                    <Input
                      type='email'
                      error={emailError}
                      placeholder='Email'
                      value={newEmail}
                      onChange={(e) => {
                        setNewEmail(e.target.value);
                        setEmailError(false);
                      }}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          onAddNewEmail();
                        }
                      }}
                    />
                    <div className='m-b-lg m-l-md'>
                      <Button
                        type='submit'
                        text='Add'
                        onClick={onAddNewEmail}
                        width={100}
                        disabled={!newEmail.length}
                      />
                    </div>
                  </div>

                  <div className='m-b-lg form__select-users' style={{ minWidth: 'auto' }}>
                    {emails.length ? (
                      emails.map((email) => (
                        <div className='md-user-info' key={email}>
                          <div>
                            {email}
                          </div>
                          <div
                            className='md-user-info__delete'
                            onClick={() => onDeleteEmail(email)}
                          >
                            <DeleteIcon fontSize='small' color='#d32f2f' />
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className='text-center secondary p-md'>
                        No Emails
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='m-b-md'>
                <div className='field__title'>Time</div>
                <Field name='time'>
                  {({ input }) => (
                    <div className='m-b-md'>
                      <TimePicker
                        name={input.name}
                        date={input.value}
                        onDateChange={input.onChange}
                        initDate={moment({ hours: 10, minutes: 0 })}
                      />
                    </div>
                  )}
                </Field>
              </div>

              { mode === 'update' && (
                <Field name='allProducts' type='checkbox'>
                  {({ input }) => (
                    <div className='m-b-md'>
                      <Checkbox
                        input={input}
                        fontSize='small'
                        label='All brands'
                      />
                    </div>
                  )}
                </Field>
              )}

              { mode === 'update' && !values?.allProducts && (
                <div className=''>
                  <div className='field__title'>Brands</div>
                  <Field name='products'>
                    {({ input, meta }) => (
                      <div className='m-b-lg'>
                        <ModalSelectInput
                          onClick={() => toggleModal(true)}
                          count={input.value.length}
                          label='Edit Brands'
                          values={getProductNames(input.value)}
                          icon='product'
                        />

                        <ModalSelect
                          title='Add Brands'
                          listTitle='Brands'
                          options={products}
                          onChange={input.onChange}
                          selected={input.value}
                          onClose={() => toggleModal(false)}
                          isOpen={isModalOpen}
                        />
                      </div>
                    )}
                  </Field>
                </div>
              )}

              {withToggle && (
                <Field name='onApproveUsage'>
                  {({ input, meta }) => (
                    <Input
                      type='switch'
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      placeholder='Trigger email on approved usage'
                    />
                  )}
                </Field>
              )}

              <div className='form__actions'>
                <Button
                  type='submit'
                  text={buttonText || 'Save Changes'}
                  disabled={invalid || loading || !emails.length}
                  loading={loading}
                  onClick={handleSubmit}
                />
                { mode === 'create' && (
                  <Button
                    type='cancel'
                    onClick={onClose}
                  />
                )}
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default OverdueShippingTriggersForm;
