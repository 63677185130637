import React, { useState, useEffect } from 'react';

import DeleteIcon from '../../assets/icons/DeleteIcon';

import Input from '../shared/Input';
import Button from '../shared/Button';
import Modal from '../shared/modal/Modal';

import '../shared/modal-select/modal-select.scss';

const AppOptionsModal = (props) => {
  const {
    initialValues,
    onChange,
    onClose,
    isOpen,
  } = props;

  const [options, setOptions] = useState(initialValues || []);
  const [newOption, setNewOption] = useState('');

  useEffect(() => {
    setOptions(initialValues);
  }, [initialValues]);

  const handleAdd = () => {
    const temp = [...options];
    if (!temp?.includes(newOption)) {
      setOptions([...temp, newOption]);
      setNewOption('');
    }
  };

  const onDelete = (option) => {
    setOptions([...options]?.filter((opt) => opt !== option));
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <div className='form__container'>
        <div className='form__body'>

          <div className='form__subtitle'>
            Checkbox Options
          </div>

          <div className='d-flex'>
            <Input
              type='text'
              placeholder='Type here'
              value={newOption}
              onChange={(e) => setNewOption(e.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  handleAdd();
                }
              }}
              maxLength={20}
            />
            <div className='m-b-lg m-l-md'>
              <Button
                type='submit'
                text='Add'
                onClick={handleAdd}
                width={100}
                disabled={!newOption.length}
              />
            </div>
          </div>

          <div className='field__title'>Options</div>
          <div className='m-b-lg form__select-users'>
            {options.length ? (
              options?.map((option) => (
                <div key={option} className='d-flex space-between'>
                  <span className='font-bold'>{option}</span>
                  <div className='md-user-info__delete pointer' onClick={() => onDelete(option)}>
                    <DeleteIcon fontSize='small' color='#d32f2f' />
                  </div>
                </div>
              ))
            ) : (
              <div className='text-center secondary p-md'>
                No Options
              </div>
            )}
          </div>

          <div className='form__actions'>
            <Button
              type='submit'
              text='Submit'
              onClick={() => {
                onChange(options);
                onClose();
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AppOptionsModal;
