import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function TitleIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" stroke-linecap="round" stroke-linejoin="round"
          d="M2.8372500000000005 11.735750000000001a0.5416666666666667 0.5416666666666667 0 0 1 -0.5357083333333335 -0.4587916666666667L0.8450000000000002 1.8887916666666669a0.5416666666666667 0.5416666666666667 0 0 1 0.5357083333333335 -0.6245416666666668l10.239666666666668 0a0.5416666666666667 0.5416666666666667 0 0 1 0.5351666666666667 0.6245416666666668L10.697916666666668 11.277500000000002a0.5416666666666667 0.5416666666666667 0 0 1 -0.5351666666666667 0.4587916666666667l-7.325500000000001 0Z"
          stroke-width="1"></path>
    <path stroke={color || defaultColor} fill="none" stroke-linecap="round" stroke-linejoin="round"
          d="m6.500000000000001 3.248916666666667 0 6.500000000000001M3.814958333333334 4.4465416666666675l0 -1.1970833333333335 5.3700833333333335 0 0 1.1970833333333335"
          stroke-width="1"></path>
    <path stroke={color || defaultColor} fill="none" stroke-linecap="round" stroke-linejoin="round"
          d="M5.321333333333334 9.748916666666668h2.358416666666667" stroke-width="1"></path>
  </SvgIcon>
}

