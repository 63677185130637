import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import AnalyticsIcon from '../../assets/icons/AnalyticsIcon';
import NotificationsIcon from '../../assets/icons/NotificationsIcon';
import SettingsIcon from '../../assets/icons/SettingsIcon';
import AdminIcon from '../../assets/icons/AdminIcon';

import UserInfo from './UserInfo';
import RestrictComponent from '../shared/RestrictComponent';

import { routes } from '../../constants';
import { roleNames } from '../../constants/userRoles';

import defaultIcon from '../../assets/logo.svg';

import './header.scss';

const { ADMIN, MARKETING } = roleNames;

const Header = (props) => {
  const {
    user,
    signOut,
    currentTenant,
    setCompressed,
    compressed,
    history,
  } = props;

  const userName = user ? `${user.firstName} ${user.lastName}` : '';
  const avatar = user && user.profilePicture ? user.profilePicture.downloadUrl : null;

  const handleSignOut = async () => {
    history.push('/');
    await signOut();
    window.location = '/';
  };

  return (
    <div className='main-header'>
      <div className='logo-user'>
        <div
          className='title-container'
          style={compressed ? {'--compressed-width' : '100px'} : {'--compressed-width' : '250px'}}
        >
          <div className='tenant-name-container'>
            <div className='logo-case pointer' onClick={() => history.push(routes.HOME)}>
              <img src={currentTenant.icon?.downloadUrl || defaultIcon} alt='tenant-icon' />
            </div>

            {!compressed && (
              <span className='tenant-name'>{currentTenant.businessName || 'Case'}</span>
            )}
          </div>

          <div
            className='navigation-arrow'
            style={{
              background: currentTenant.colorPrimary
            }}
            onClick={() => setCompressed(!compressed)}
          >
            { compressed ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon /> }
          </div>
        </div>

        <UserInfo
          userName={userName}
          avatar={avatar}
          signOut={handleSignOut}
          userRole={user.role}
          tenantColor={currentTenant.colorPrimary}
        />
      </div>
      <div className='user-info'>
        <Link
          to={routes.ANALYTICS}
          style={{ textDecoration: 'none' }}
        >
          <AnalyticsIcon
            className='header-icon'
            color={window.location.pathname.includes(routes.ANALYTICS) ? currentTenant.colorPrimary : '#202124'}

          />
        </Link>

        <Link
          to={routes.NOTIFICATIONS}
          style={{ textDecoration: 'none' }}
        >
          <NotificationsIcon
            className='header-icon'
            color={window.location.pathname === routes.NOTIFICATIONS ? currentTenant.colorPrimary : '#202124'}
          />
        </Link>

        <RestrictComponent roles={[ADMIN, MARKETING]}>
          <Link
            to={routes.SETTINGS}
            style={{ textDecoration: 'none' }}
          >
            <SettingsIcon
              className='header-icon'
              color={window.location.pathname.includes(routes.SETTINGS) ? currentTenant.colorPrimary : '#202124'}
            />
          </Link>
        </RestrictComponent>
        {!!user.isGlobalAdmin && (
          <Link
            to={routes.ADMIN}
            style={{ textDecoration: 'none' }}
          >
            <AdminIcon
              className='header-icon'
              color={window.location.pathname.includes(routes.ADMIN) ? currentTenant.colorPrimary : '#202124'}
            />
          </Link>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentTenant: state.tenant.currentTenant,
  };
};

export default connect(mapStateToProps)(withRouter(Header));
