import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function FullScreenIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M10.0068 0.954346C10.3777 0.994293 10.7452 1.03711 11.1091 1.07929C12.0686 1.19049 12.8342 1.95559 12.9419 2.91547C12.9825 3.27701 13.0234 3.64214 13.0615 4.01058" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M10.0068 13.0691C10.3777 13.0291 10.7452 12.9863 11.1091 12.9441C12.0686 12.8329 12.8342 12.0679 12.9419 11.108C12.9825 10.7464 13.0234 10.3813 13.0615 10.0129" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M3.99316 0.954346C3.62232 0.994293 3.2548 1.03711 2.89092 1.07929C1.93143 1.19049 1.16584 1.95559 1.05807 2.91547C1.01748 3.27701 0.976557 3.64214 0.938516 4.01058" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M3.99316 13.0691C3.62232 13.0291 3.2548 12.9863 2.89092 12.9441C1.93143 12.8329 1.16584 12.0679 1.05807 11.108C1.01748 10.7464 0.976557 10.3813 0.938516 10.0129" />
  </SvgIcon>
}
