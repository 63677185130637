import { orderBy } from 'lodash';

import { GET_TASKS, SET_TASKS } from './actionTypes';

import firebase, { collections } from '../firebase';

import { nowTimestampUTC, fromMomentToTimestampDay } from '../utils/date';

export const setTasks = (tasks) => ({ type: SET_TASKS, tasks });

export const getTasks = () => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.TASKS_COLLECTION(tenantId);

  dispatch({ type: GET_TASKS });

  const snapshot = await firebase.db.collection(path)
    .where('active', '==', true)
    .get();
  const tasks = snapshot.docs.map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      createdAt: data.createdAt ? data.createdAt.toDate() : null,
      updatedAt: data.updatedAt ? data.updatedAt.toDate() : null,
      dueDate: data.dueDate ? data.dueDate.toDate() : null,
    };
  });

  return dispatch(setTasks(orderBy(tasks, 'dueDate', 'asc')));
};

export const createTask = (taskData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.TASKS_COLLECTION(tenantId);

  if (tenantId) {
    const doc = {
      ...taskData,
      active: true,
      createdAt: nowTimestampUTC(),
      updatedAt: nowTimestampUTC(),
      dueDate: taskData.dueDate ? fromMomentToTimestampDay(taskData.dueDate) : null,
    };
    await firebase.db.collection(path).add(doc);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateTask = (taskId, taskData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.TASKS_COLLECTION(tenantId);

  if (tenantId) {
    const doc = {
      ...taskData,
      updatedAt: nowTimestampUTC(),
      dueDate: taskData.dueDate ? fromMomentToTimestampDay(taskData.dueDate) : null,
    };
    await firebase.db.collection(path).doc(taskId).set(doc, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const completeTask = (taskId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.TASKS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(taskId).set({ active: false }, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteTask = (taskId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.TASKS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(taskId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

// Subscriptions
export const subscribeToTasks = () => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.TASKS_COLLECTION(tenantId);

  return firebase
    .db
    .collection(path)
    .where('active', '==', true)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let tasks = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          const data = documentSnapshot.data();
          tasks = [...tasks, {
            ...data,
            id,
            createdAt: data.createdAt ? data.createdAt.toDate() : null,
            updatedAt: data.updatedAt ? data.updatedAt.toDate() : null,
            dueDate: data.dueDate ? data.dueDate.toDate() : null,
          }];
        });

        return dispatch(setTasks(orderBy(tasks, 'dueDate', 'asc')));
      },
    });
};
