import React from 'react';

import TeamsIcon from '../../assets/icons/GroupsIcon';

import userRoles from '../../constants/userRoles';

const CountTeams = ({ members = [], isConsigned }) => {
  return (
    <div className='case-notes__container'>
      <div className='d-flex m-b-lg space-between'>
        <div className='d-flex'>
          <TeamsIcon />
          <div className='font-size-bg font-bold m-l-md'>
            Team
          </div>
        </div>
      </div>

      <div
        className='case-notes-list__container count-teams-list__container'
        style={{ maxHeight: isConsigned ? '188px' : '156px' }}
      >
        { members && members.map((member) => (
          <div key={member.uid} className='case-note'>
            <div className='d-flex space-between'>
              <div className='font-bold'>{`${member.firstName} ${member.lastName}`}</div>
              <div className='secondary'>{userRoles[member.role] ? userRoles[member.role].label : ''}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CountTeams;
