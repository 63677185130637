import React, { useState, useMemo } from 'react';

import Company from './Company';
import Modal from '../shared/modal';
import Input from '../shared/Input';

const ConnectionModal = (props) => {
  const { open, onClose, tenants = [], onSubmit, loading } = props;

  const [selected, setSelected] = useState(null);
  const [search, setSearch] = useState(null);

  const list = useMemo(() => (
    tenants?.filter((tenant) => !search || tenant?.businessName?.toLowerCase()?.includes(search?.toLowerCase()))
  ), [tenants, search]);

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        setSelected(null);
      }}
      title='Add Connection'
      fullWidth
      submitButton='Submit'
      closeButton='Close'
      onSubmit={() => {
        onSubmit(selected);
        setSelected(null);
      }}
      disabled={!selected || loading}
      loading={loading}
    >
      <div className='p-lg'>
        <Input
          type='search'
          placeholder='Search Companies'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        <div className='m-t-lg'>
          {list?.map((tenant) => (
            <div key={tenant.id} className='m-b-md'>
              <Company
                title={tenant.businessName}
                logo={tenant?.icon?.downloadUrl || null}
                color={tenant?.colorPrimary}
                selected={tenant.id === selected}
                onClick={() => setSelected(tenant.id)}
                selectable
              />
            </div>
          ))}
        </div>
      </div>
    </Modal>
  )
};

export default ConnectionModal;
