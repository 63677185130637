import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function SetIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none"
          d="M11.7053 4.96368C11.9553 6.17717 12.1082 7.4843 12.1082 8.82765C12.1082 9.91252 11.9974 10.9616 11.832 11.9741C11.762 12.4027 11.4133 12.7292 10.9817 12.7771C9.64314 12.9256 8.25749 13.0234 6.81971 13.0234C5.39493 13.0234 4.01125 12.868 2.68921 12.7524C2.24289 12.7134 1.86923 12.3888 1.79524 11.947C1.62692 10.9419 1.53125 9.90318 1.53125 8.82765C1.53125 7.48429 1.67054 6.17206 1.93414 4.96368" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M5.37744 8.1106H8.26206" />
    <path stroke={color || defaultColor} fill="none"
          d="M0.973728 4.6319C1.10403 4.98681 1.46468 5.18864 1.8427 5.18216C5.21829 5.12435 8.63946 5.1835 12.1484 5.19295C12.531 5.19398 12.8895 4.97911 13.0202 4.6195C13.161 4.23198 13.25 3.83311 13.25 3.42308C13.25 2.99361 13.1621 2.57222 13.0087 2.16783C12.8839 1.83899 12.5588 1.64086 12.2075 1.62456C10.5288 1.54668 8.80388 1.5 7 1.5C5.1931 1.5 3.43418 1.5439 1.77469 1.62407C1.43299 1.64058 1.11502 1.82923 0.991412 2.14822C0.832567 2.55812 0.75 2.98702 0.75 3.42308C0.75 3.83766 0.829971 4.24032 0.973728 4.6319Z" />
  </SvgIcon>
}
