import React from 'react';

import Table from '../shared/table/Table';

const columns = [
  { title: 'name', field: 'name', type: 'default', sort: true },
  { title: 'country', field: 'country', type: 'default', sort: true },
  { title: 'street', field: 'street', type: 'default', sort: true },
  { title: 'state', field: 'state', type: 'default', sort: true },
  { title: 'postcode', field: 'postCode', type: 'default', sort: false },
  { title: 'account no.', field: 'accountNumber', type: 'default', sort: false },
  { title: 'business no.', field: 'businessNumber', type: 'default', sort: false },
];

const HospitalsTable = (props) => {
  const {
    onSelectHospital,
    hospitals,
    selectedHospitalId,
    handleCheck,
    checkedHospitals,
    handleCheckAll,
  } = props;

  return (
    <div style={{ width: '100%' }}>
      <Table
        rows={hospitals}
        columns={columns}
        onRowClick={onSelectHospital}
        selected={selectedHospitalId}
        handleCheck={handleCheck}
        checkedRows={checkedHospitals}
        handleCheckAll={handleCheckAll}
        pagination
        noCheckAll
      />
    </div>
  );
};

export default HospitalsTable;
