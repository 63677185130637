import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import moment from 'moment';

import LoadScreen from '../../../components/load-screen';
import Button from '../../../components/shared/Button';
import { CaseInfo } from '../../../components/cases/checklists';

import {
  ReturnSetsList, ReturnSetInfo, ReturnSetSection, ReturnUsageTable, ReturnSubmitModal
} from '../../../components/cases/return-sets';

import { useLoading } from '../../../hooks';
import {
  getCase, setCaseView, subscribeToCase, subscribeToSetsAllocation, addReturnSetsActivity,
  subscribeToCaseChecklists, updateChecklist, updateSetsAllocation, addUsageImplants,
} from '../../../actions/casesActions';
import { getSurgeons } from '../../../actions/surgeonsActions';
import { getKits } from '../../../actions/kitsActions';
import { getSets, updateItemQuantity, updateSet } from '../../../actions/setsActions';

import { fromMomentToTimestampDay } from '../../../utils/date';

import routes from '../../../constants/routes';
import { setAllocationStatuses, kitVariantTypes, setAllocationTypes } from '../../../constants/enums';

const { SHIPPED, RETURNED, USAGE, CONFIRMED } = setAllocationStatuses;

const CaseReturnSetsPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const { search } = useLocation();

  const caseId = id.replace(':', '');
  const setId = search ? search.replace('?id=', '') : '';

  const dispatch = useDispatch();
  const activeCase = useSelector((state) => state?.activeCase.data);
  const caseChecklists = useSelector((state) => state?.activeCase.checklists);
  const setsAllocation = useSelector((state) => state?.activeCase.setsAllocation);
  const surgeons = useSelector((state) => state?.surgeons.list);
  const hospitals = useSelector((state) => state?.hospitals.list);
  const items = useSelector((state) => state?.items.list);
  const itemsLoaded = useSelector((state) => state?.items.isLoaded);
  const kits = useSelector((state) => state?.kits.list);
  const sets = useSelector((state) => state?.sets.list);
  const tenantColor = useSelector((state) => state?.tenant.currentTenant.colorPrimary);

  const [checklists, setChecklists] = useState([]);
  const [consumables, setConsumables] = useState([]);
  const [selected, setSelected] = useState(null);
  const [open, toggleModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const { loading, startLoading, stopLoading } = useLoading(false);
  const { loading: submitting, startLoading: startSubmit, stopLoading: stopSubmit } = useLoading(false);

  useEffect(() => {
    const unsubscribeToCase = dispatch(subscribeToCase(caseId));
    const unsubscribeToCaseChecklists = dispatch(subscribeToCaseChecklists(caseId));
    const unsubscribeToSetsAllocation = dispatch(subscribeToSetsAllocation(caseId));

    onLoad().catch((err) => console.error(err));

    return () => {
      if (unsubscribeToCase && typeof unsubscribeToCase === 'function') {
        unsubscribeToCase();
      }
      unsubscribeToCaseChecklists();
      unsubscribeToSetsAllocation();
    };
  }, []);

  useEffect(() => {
    if (caseChecklists && caseChecklists.length) {
      setChecklists(caseChecklists?.filter((item) => !item.additional));
    }
  }, [caseChecklists]);

  useEffect(() => {
    if (setsAllocation && setsAllocation.length) {
      const arr = setsAllocation
        ?.filter((allocation) => [SHIPPED.value, RETURNED.value, USAGE.value, CONFIRMED.value].includes(allocation.status) && allocation?.consumables?.length)
        ?.map((allocation) => {
          const item = allocation.kit ? kits?.find((k) => k.id === allocation.kit) : items?.find((i) => i.id === allocation.itemId);
          return ({
            additional: !!allocation?.additional,
            id: item?.id || '',
            code: item?.code || item?.kitId || '',
            description: item?.checklistDescription || item?.description || item?.name || '',
            imageUrl: item?.image?.downloadUrl || item?.imageUrl || '',
            allocationType: setAllocationTypes.ITEM,
            allocationStatus: allocation?.status,
            allocationId: allocation?.id,
            quantity: allocation?.consumables?.length || 1,
            consumables: allocation?.consumables?.map((cons) => {
              if (cons?.id) {
                return {
                  id: cons?.id || '',
                  label: cons?.label || '',
                  status: cons?.status || RETURNED.value
                };
              } else {
                return {
                  label: cons,
                  status: allocation?.usageConsumables?.includes(cons) ? USAGE.value : RETURNED.value
                };
              }
            }),
          });
        });

      setConsumables(arr?.filter((item) => !item.additional));
    }
  }, [setsAllocation, items]);

  const onLoad = async () => {
    startLoading()
    try {
      await Promise.all([
        dispatch(getCase(caseId)),
        dispatch(getSurgeons()),
        dispatch(getKits()),
        dispatch(getSets()),
      ]);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onItemsChange = (checklistId, sectionId, items) => {
    const temp = [...checklists];
    const checklist = temp?.find((item) => item.id === checklistId);
    if (checklist && checklist.sections) {
      const sections = [...checklist.sections];
      sections[sectionId].items = items;
      checklist.sections = sections;
      setChecklists(temp);
      setSelected({ ...selected, sections });
      dispatch(updateChecklist(caseId, checklistId, { sections }));
    }
  };

  const onBatchInboundItemsChange = async (checklistId, sectionIndex, itemId, inboundItems) => {
    const temp = [...checklists];
    const checklist = temp?.find((item) => item.id === checklistId);
    if (checklist && checklist.sections) {
      const sections = [...checklist.sections];
      const section = sections[sectionIndex];
      const item = section?.items?.find((i) => i.id === itemId);
      if (item) {
        item.inbound = item.outbound - inboundItems?.length;
        item.batchInbound = inboundItems;
        checklist.sections = sections;
        setChecklists(temp);
        setSelected({ ...selected, sections });
        dispatch(updateChecklist(caseId, checklistId, { sections }));
      }
    }
  };

  const onUsageStatusChange = (id, index, status) => {
    const temp = [...consumables];
    const consumableItem = consumables?.find((item) => item.id === id);

    if (consumableItem && consumableItem.consumables && consumableItem.consumables.length) {
      const consumableItems = [...consumableItem.consumables];
      consumableItems[index] = { ...consumableItems[index], status };
      consumableItem.consumables = consumableItems;
      setConsumables(temp)
      setSelected(consumableItem);

      const allocation = list?.find((item) => item.allocationId === selected.allocationId || item.allocationId === `${selected.allocationId}_additional`);
      if (allocation && allocation?.consumables[0]?.id) {
        dispatch(updateSetsAllocation(
          caseId,
          consumableItem.allocationId,
          {
            consumables: allocation.consumables?.map((c) => ({ id: c.id, status: c.status, label: c.label })),
            usageConsumables: allocation.consumables?.filter((c) => c.status === USAGE.value)?.map((c) => c?.label || ''),
            returnedConsumables: allocation.consumables?.filter((c) => c.status === RETURNED.value)?.map((c) => c?.label || ''),
          }),
        );
      } else {
        const usageConsumables = consumableItem.consumables?.filter((item) => item.status === USAGE.value)?.map((item) => item.label);
        const returnedConsumables = consumableItem.consumables?.filter((item) => item.status === RETURNED.value)?.map((item) => item.label);

        if (usageConsumables && usageConsumables?.length) {
          dispatch(updateSetsAllocation(caseId, consumableItem.allocationId, { usageConsumables }));
        }
        if (returnedConsumables && returnedConsumables?.length) {
          dispatch(updateSetsAllocation(caseId, consumableItem.allocationId, { returnedConsumables }));
        }
      }
    }
  };

  const onSubmit = async (selected, returnDate, addToUsage, updatedItems, isReplenish) => {
    startSubmit();

    try {
      const promises = [];
      const allocation = list?.find((item) => item.allocationId === selected.allocationId);

      if (allocation && allocation.allocationType === setAllocationTypes.SET) {
        const setAllocation = setsAllocation?.find((a) => a.id === allocation.allocationId);
        if (setAllocation.sets && setAllocation.sets.length) {
          setAllocation?.sets?.forEach((setId) => {
            const checklist = checklists?.find((c) => c.id === setId);
            if (checklist) {
              promises?.push(dispatch(updateChecklist(caseId, checklist.id, { sections: checklist.sections })));
            }
          });
        }

        const doc = {
          id: setAllocation.id,
          status: activeCase?.kitVariant === kitVariantTypes.consignment ? USAGE.value : RETURNED.value,
          returnDate: fromMomentToTimestampDay(returnDate ? returnDate : moment())
        };

        promises?.push(dispatch(updateSetsAllocation(caseId, setAllocation.id, doc)));
      }

      if (allocation && allocation.allocationType === setAllocationTypes.ITEM) {
        const usageConsumables = allocation?.consumables?.filter((c) => c.status === USAGE.value)?.map((c) => c.label) || [];
        const returnedConsumables = allocation?.consumables?.filter((c) => c.status === RETURNED.value)?.map((c) => c.label) || [];
        const doc = {
          id: allocation.id,
          status: usageConsumables?.length ? USAGE.value : RETURNED.value,
          returnDate: fromMomentToTimestampDay(returnDate ? returnDate : moment()),
          usageConsumables,
          returnedConsumables,
        };
        promises?.push(dispatch(updateSetsAllocation(caseId, allocation.id, doc)));

        if (allocation?.consumables[0]?.id) {
          doc.consumables = allocation?.consumables?.map((c) => ({
            id: c?.id || '',
            status: c?.status || '',
            label: c?.label || ''
          }));
        }

        if (addToUsage) {
          usageConsumables?.forEach((cons) => {
            const usageData = {
              lot: cons,
              ref: allocation.code,
              desc: allocation.description,
              quantity: 1,
              scanType: 'USAGE',
            };
            promises?.push(dispatch(addUsageImplants(caseId, usageData)));
          });
        }
      }

      const set = sets?.find(((s) => s.id === selected?.id));
      if (!!updatedItems?.length) {
        if (addToUsage) {
          updatedItems?.forEach((item) => {
            const usageData = {
              batchControl: !!item.batchControl,
              ref: item.code,
              quantity: item.quantity,
              scanType: 'USAGE',
              desc: item.description || '',
              exp: item.exp || '',
              lot: item.lot || ''
            };
            promises?.push(dispatch(addUsageImplants(caseId, usageData)));
          });
        }

        if (set) {
          let batchItems = { ...set?.batchItems } || {};
          updatedItems?.forEach((item) => {
            if (!!item.batchControl) {
              batchItems = { ...batchItems, [item.itemId]: batchItems[item.itemId]?.filter((i) => i.id !== item.batchId) }
            } else {
              promises?.push(dispatch(updateItemQuantity(set, item.itemId, item.inbound)));
            }
          });
          promises?.push(dispatch(updateSet(set.id, { batchItems })));
        }
      }

      await Promise.all(promises);

      const allocations = [allocation];
      await dispatch(addReturnSetsActivity(caseId, allocations));

      toggleModal(false);
      setSelected(null);

      if (isReplenish && set) {
        await dispatch(updateSet(set.id, { caseAllocation: null }));
        history.push(`${routes.SETS}/${set.id}${routes.BATCH_CONTROL}`);
      }
    } catch (err) {
      console.error(err);
    } finally {
      stopSubmit();
    }
  };

  const onViewClick = () => {
    dispatch(setCaseView('SETS'));
    history.push(`${routes.CASES}/${caseId}`);
  };

  const list = useMemo(() => {
    const result = [];

    setsAllocation
      ?.filter((allocation) => [SHIPPED.value, CONFIRMED.value, RETURNED.value, USAGE.value].includes(allocation.status) && !allocation.additional)
      ?.forEach((allocation) => {
        if (allocation.sets && !!allocation.sets.length) {
          allocation.sets.forEach((setId) => {
            const checklist = checklists?.find((c) => c.id === setId);
            if (checklist) {
              result?.push({
                ...checklist,
                allocationId: allocation.id,
                allocationType: setAllocationTypes.SET,
                allocationStatus: allocation.status,
              });
            } else {
              const kit = kits?.find((k) => k.id === allocation.kit);
              result?.push({
                id: allocation.id,
                kitId: kit?.kitId || '',
                kitName: kit?.name || '',
                allocationId: allocation.id,
                allocationType: setAllocationTypes.SET,
                allocationStatus: allocation.status,
                containerSize: allocation?.sets?.length || 1,
                quantity: allocation.quantity || 1,
                setNumber: kit?.kitId || '',
              });
            }
          });
        }
        if (allocation.consumables && allocation.consumables.length) {
          const consumable = consumables?.find((i) => i.id === allocation.itemId || i.id === allocation.kit);
          if (!!consumable) {
            result?.push(consumable);
          }
        }
      });

    return result;
  }, [checklists, consumables]);

  useEffect(() => {
    if (!isLoaded && !!checklists?.length && !!list?.length) {
      const checklist = setId ? list.find((item) => item.id === setId) : null;

      setSelected(checklist || list[0]);
      setIsLoaded(true);
    }
  }, [list]);

  const formattedId = useMemo(() => {
    if (!activeCase) {
      return ''
    }
    const arr = activeCase?.id?.split('-');
    return arr?.length > 1 ? `${arr[0]}-${arr[1]}` : activeCase?.id;
  }, [activeCase]);

  return (
    <div className='page-container case-checklists-page__container'>
      {(loading || !itemsLoaded) && <LoadScreen />}
      {activeCase && activeCase.id && (
        <>
          <div className='page-title'>
            Case
            <span className='page-subtitle'>{formattedId}</span>
          </div>

          <div className='case-checklists-page__body'>
            <div className='d-flex direction-column'>
              <div className='width-100'>
                <Button
                  type='outlined'
                  text='View Case'
                  onClick={onViewClick}
                />
                <Button
                  type='submit'
                  text={activeCase?.kitVariant === kitVariantTypes.consignment ? 'Add Usage' : 'Return'}
                  onClick={() => toggleModal(true)}
                  loading={submitting}
                  disabled={submitting || !list?.filter((item) => item.allocationStatus === SHIPPED.value || item.allocationStatus === CONFIRMED.value)?.length || selected?.allocationStatus === RETURNED.value || selected?.allocationStatus === USAGE.value || !selected}
                />
              </div>
              <ReturnSetsList
                list={list}
                selected={selected}
                onSelect={setSelected}
                tenantColor={tenantColor}
              />
            </div>
            <div>
              <div className='d-flex space-between'>
                {selected ? (
                  <ReturnSetInfo
                    {...selected}
                  />
                ) : (
                  <div />
                )}
                <CaseInfo
                  activeCase={activeCase}
                  surgeons={surgeons}
                  hospitals={hospitals}
                />
              </div>
              <div style={{ marginTop: 32 }}>
                {selected && selected.allocationType === setAllocationTypes.SET && selected.sections && (
                  selected.sections.map((section, index) => (
                    <ReturnSetSection
                      key={`${section.name}_${section.subtitle}`}
                      sectionIndex={index}
                      checklistId={selected.id}
                      allocationStatus={selected.allocationStatus}
                      onChange={onItemsChange}
                      tenantColor={tenantColor}
                      itemsList={items}
                      batchItems={sets?.find(((s) => s.id === selected?.id))?.batchItems || {}}
                      onBatchInboundItemsChange={onBatchInboundItemsChange}
                      {...section}
                    />
                  ))
                )}

                {selected && selected.allocationType === setAllocationTypes.ITEM && (
                  <ReturnUsageTable
                    {...selected}
                    tenantColor={tenantColor}
                    onChange={(index, status) => onUsageStatusChange(selected.id, index, status)}
                  />
                )}

                {selected && selected.allocationType === setAllocationTypes.SET && !selected?.sections?.length && (
                  <div className='d-flex flex-center secondary font-bold font-size-bg' style={{ paddingTop: '48px' }}>
                    No Checklist Available for Set
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {!!selected && (
        <ReturnSubmitModal
          open={open && !!selected}
          onClose={() => toggleModal(false)}
          onSubmit={onSubmit}
          data={list?.filter((item) => item.allocationStatus === SHIPPED.value || item.allocationStatus === CONFIRMED.value)}
          tenantColor={tenantColor}
          loading={submitting}
          checklists={checklists}
          orderClosed={activeCase?.orderClosed}
          kitVariant={activeCase?.kitVariant}
          selected={selected}
          batchItems={sets?.find(((s) => s.id === selected?.id))?.batchItems || {}}
          itemsList={items}
        />
      )}
    </div>
  );
};

export default CaseReturnSetsPage;
