import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function UploadIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none"
          d="M0.959867 3.73147C1.114 2.29082 2.2727 1.13211 3.71266 0.971609C4.78221 0.852392 5.88037 0.75 7 0.75C8.11963 0.75 9.21779 0.852392 10.2873 0.971609C11.7273 1.13211 12.886 2.29082 13.0401 3.73147C13.1539 4.79498 13.25 5.88685 13.25 7C13.25 8.11316 13.1539 9.20502 13.0401 10.2685C12.886 11.7092 11.7273 12.8679 10.2873 13.0284C9.21779 13.1476 8.11963 13.25 7 13.25C5.88037 13.25 4.78221 13.1476 3.71266 13.0284C2.2727 12.8679 1.114 11.7092 0.959867 10.2685C0.846083 9.20502 0.75 8.11316 0.75 7C0.75 5.88685 0.846084 4.79498 0.959867 3.73147Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M7 9.81494L7 4.18506" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M9.35498 6.20605C8.63942 5.30195 8.15204 4.88121 7.42572 4.33094C7.16872 4.13624 6.83156 4.13624 6.57455 4.33094C5.84823 4.88121 5.36085 5.30195 4.64529 6.20606" />
  </SvgIcon>
}
