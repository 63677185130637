import moment from 'moment';

import { GET_REPEATING_COUNTS, SET_REPEATING_COUNTS } from './actionTypes';

import firebase, { collections } from '../firebase';
import { fromMomentToTimestampDay } from '../utils/date';

import { createCount } from './countsActions';

export const setRepeatingCounts = counts => ({ type: SET_REPEATING_COUNTS, counts });

export const getRepeatingCounts = () => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.REPEATING_COUNTS_COLLECTION(tenantId);

  dispatch({ type: GET_REPEATING_COUNTS });

  const snapshot = await firebase.db.collection(path).get();
  const counts = snapshot.docs?.map(doc => ({
    id: doc.id,
    ...doc.data(),
    startDate: doc.data()?.startDate ? doc.data()?.startDate.toDate() : null,
  }));

  return dispatch(setRepeatingCounts(counts));
};

export const createRepeatingCount = (count) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.REPEATING_COUNTS_COLLECTION(tenantId);

  const doc = {
    active: true,
    ...count,
    startDate: count.startDate ? fromMomentToTimestampDay(count.startDate) : fromMomentToTimestampDay(moment()),
  }

  if (tenantId) {
    await firebase.db.collection(path).add(doc);

    if (count.startDate && count.startDate?.isSame(moment(), 'day')) {
      // const groups = state.tenant.groups?.list || [];
      // const taggedUsers = [];
      //
      // groups?.forEach((group) => {
      //   if (count?.groups && count?.groups?.find((groupId) => groupId === group.id) && group?.members) {
      //     group?.members?.forEach((member) => {
      //       if (!taggedUsers?.includes(member)) {
      //         taggedUsers?.push(member);
      //       }
      //     })
      //   }
      // });
      //
      // if (count?.users) {
      //   count?.users?.forEach((user) => {
      //     if (!taggedUsers?.includes(user)) {
      //       taggedUsers?.push(user);
      //     }
      //   });
      // }

      const countObj = {
        name: count.name || '',
        hospitalId: count.hospitalId || '',
        location: count.location || null,
        dueDate: moment().add(count.dueDays, 'days'),
        taggedUsers: count.users || [],
        groups: count.groups || [],
        kit: count.kit || null,
        repeating: true,
      };

      await dispatch(createCount(countObj));
    }

  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateRepeatingCount = (countId, count) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.REPEATING_COUNTS_COLLECTION(tenantId);

  const doc = {
    ...count,
    startDate: count.startDate ? fromMomentToTimestampDay(count.startDate) : fromMomentToTimestampDay(moment()),
  }

  if (tenantId) {
    await firebase.db.collection(path).doc(countId).set(doc, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteRepeatingCount = (countId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.REPEATING_COUNTS_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(countId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const bulkDeleteRepeatingCounts = (counts) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.REPEATING_COUNTS_COLLECTION(tenantId);
  const batch = firebase.db.batch();

  if (tenantId) {
    counts.forEach((countId) => {
      const ref = firebase.db.collection(path).doc(countId);
      batch.delete(ref);
    });

    await batch.commit();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const uploadRepeatingCounts = (counts) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.GTIN_REFERENCE_COLLECTION(tenantId);

  if (tenantId) {
    const promises = [];

    counts.forEach((item) => {
      const doc = {
        active: true,
      };

      promises.push(firebase.db.collection(path).add(doc));
    });

    await Promise.all(promises);
  } else {
    throw new Error('Invalid tenant');
  }
};

// Subscriptions
export const subscribeToRepeatingCounts = () => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.REPEATING_COUNTS_COLLECTION(tenantId);

  return firebase
    .db
    .collection(path)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let counts = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          const data = documentSnapshot.data();
          counts = [...counts, {
            ...data,
            id,
            startDate: data.startDate ? data.startDate.toDate() : null,
          }];
        });

        return dispatch(setRepeatingCounts(counts));
      },
    });
};
