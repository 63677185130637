import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sortBy } from 'lodash';

import {
  KitPreferencesActions,
  KitPreferencesList,
  KitPreferenceForm,
  KitPreferenceItemForm,
  PreferenceItemsList,
  KitPreferencesBreadcrumbs,
  AddKitsModal,
  AddItemsModal,
  TitleForm,
} from '../../../components/kit-preferences';
import Input from '../../../components/shared/Input';
import Modal, { ConfirmationModal } from '../../../components/shared/modal';

import {
  subscribeToKitPreferences,
  createKitPreference,
  updateKitPreference,
  deleteKitPreference,
  getItems,
  createItem,
  updateItem,
  deleteItem,
  reorderItems,
} from '../../../actions/kitPreferencesActions';
import { getProcedures } from '../../../actions/proceduresActions';
import { getSurgeons } from '../../../actions/surgeonsActions';
import { getHospitals } from '../../../actions/hospitalsActions';
import { getKits } from '../../../actions/kitsActions';

import { useLoading, useAlert } from '../../../hooks';

import { kitPreferenceItemTypes } from '../../../constants/enums';
import LoadScreen from '../../../components/load-screen';

const KitPreferencesPage = () => {
  const dispatch = useDispatch();
  const tenant = useSelector((state) => state.tenant.currentTenant);
  const kitPreferences = useSelector((state) => state.kitPreferences.list);
  const procedures = useSelector((state) => state.procedures.list);
  const surgeons = useSelector((state) => state.surgeons.list);
  const hospitals = useSelector((state) => state.hospitals.list);
  const kits = useSelector((state) => state.kits.list);
  const itemsList = useSelector((state) => state.items.list);
  const deletePermission = useSelector((state) => state.user?.currentUser?.deletePermission);
  const itemsLoaded = useSelector((state) => state?.items?.isLoaded);

  const { loading, startLoading, stopLoading } = useLoading(false);
  const { loading: deleting, startLoading: startDeleting, stopLoading: stopDeleting } = useLoading(false);
  const { showAlert } = useAlert();

  const [search, setSearch] = useState('');

  const [selectedKitPreference, selectKitPreference] = useState(null);
  const [selectedItem, selectItem] = useState(null);
  const [items, setItems] = useState([]);

  const [kitPreferenceModal, toggleKitPreferenceModal] = useState(false);
  const [deleteKitPreferenceModal, toggleDeleteKitPreferenceModal] = useState(false);
  const [deleteItemModal, toggleDeleteItemModal] = useState(false);

  const [fetchingItems, setFetchingItems] = useState(false);

  const [kitsModalOpen, toggleKitsModal] = useState(false);
  const [itemsModalOpen, toggleItemModal] = useState(false);
  const [titleModalOpen, toggleTitleModal] = useState(false);

  useEffect(() => {
    const unsubscribe = dispatch(subscribeToKitPreferences());

    onLoad().catch((err) => console.error(err));

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (selectedKitPreference) {
      fetchKitPreferenceItems(selectedKitPreference?.id).catch((err) => console.error(err));
    } else {
      setItems([]);
    }
  }, [selectedKitPreference]);

  const fetchKitPreferenceItems = async (kitPreferenceId, withoutLoading) => {
    if (!withoutLoading) {
      setFetchingItems(true);
    }

    try {
      const items = await dispatch(getItems(kitPreferenceId));
      setItems(sortBy(items, ['orderNumber']));
    } catch (err) {
      console.error(err);
    } finally {
      setFetchingItems(false);
    }
  };

  const onLoad = async () => {
    try {
      await Promise.all([
        dispatch(getProcedures()),
        dispatch(getSurgeons()),
        dispatch(getHospitals()),
        dispatch(getKits())
      ]);
    } catch (err) {
      console.error(err);
    }
  };

  const onAddClick = (type) => {
    if (type === kitPreferenceItemTypes.ITEM.value) {
      toggleItemModal(true);
    } else if (type === kitPreferenceItemTypes.KIT.value) {
      toggleKitsModal(true);
    } else if (type === kitPreferenceItemTypes.TITLE.value) {
      toggleTitleModal(true);
    } else {
      toggleKitPreferenceModal(true);
    }
  };

  const onSubmitKitPreference = async (formObj, mode) => {
    startLoading();

    const kitPreferenceObj = {
      name: formObj.name || '',
      subtitle: formObj.subtitle || '',
      description: '',
      surgeon: '',
      allProcedures: false,
      procedures: [],
      hospitals: [],
    };

    if (mode === 'update') {
      kitPreferenceObj.active = !!formObj.active;
      kitPreferenceObj.surgeon = formObj.surgeon || '';
      kitPreferenceObj.hospitals = formObj.hospitals || [];
      kitPreferenceObj.allProcedures = formObj.allProcedures || false;
      kitPreferenceObj.procedures = formObj.procedures || [];
      kitPreferenceObj.description = formObj.description || '';
    }

    try {
      if (mode === 'update') {
        await dispatch(updateKitPreference(formObj.id, kitPreferenceObj));
        selectKitPreference(formObj)
      } else {
        await dispatch(createKitPreference(kitPreferenceObj));
      }
      stopLoading();
      toggleKitPreferenceModal(false);
      showAlert('success', `Kit preference has been successfully ${mode === 'update' ? 'updated' : 'created'}`);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onDeleteKitPreference = async () => {
    startDeleting();

    try {
      await Promise.all(items.map((step) => dispatch(deleteItem(selectedKitPreference.id, step.id))));
      await dispatch(deleteKitPreference(selectedKitPreference.id));
      toggleDeleteKitPreferenceModal(false);
      selectKitPreference(null);
      selectItem(null);
      showAlert('success', 'Kit preference has been successfully deleted');
    } catch (err) {
      console.error(err);
    } finally {
      stopDeleting();
    }
  };

  // Items

  const onAddItems = async (ids, type) => {
    startLoading();

    try {
      for (const [index, id] of ids?.entries()) {
        const doc = {
          orderNumber: items?.length + index + 1,
          type,
          value: id,
          preSelect: true,
        };
        await dispatch(createItem(selectedKitPreference?.id, doc));
      }
      await fetchKitPreferenceItems(selectedKitPreference?.id, true);
      stopLoading();
      toggleKitsModal(false);
      toggleItemModal(false);
      showAlert('success', `${kitPreferenceItemTypes[type]?.label}s have been successfully added`);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onAddTitle = async (formObj) => {
    startLoading();

    const doc = {
      orderNumber: items?.length + 1,
      title: formObj.title || '',
      subtitle: formObj.subtitle || '',
      type: kitPreferenceItemTypes.TITLE.value,
    };

    try {
      await dispatch(createItem(selectedKitPreference?.id, doc));
      await fetchKitPreferenceItems(selectedKitPreference?.id, true);
      stopLoading();
      toggleTitleModal(false);
      showAlert('success', 'Title has been successfully added');
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onUpdateTitle = async (formObj) => {
    startLoading();

    const doc = {
      title: formObj.title || '',
      subtitle: formObj.subtitle || '',
    };

    try {
      await dispatch(updateItem(selectedKitPreference?.id, selectedItem.id, doc));
      await fetchKitPreferenceItems(selectedKitPreference?.id, true);
      selectItem({ ...selectedItem, ...doc });
      stopLoading();
      showAlert('success', 'Title has been successfully updated');
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onUpdateItem = async (formObj) => {
    startLoading();

    const doc = {
      preSelect: !!formObj.preSelect,
    };

    if (formObj?.quantity && Number(formObj?.quantity)) {
      doc.quantity = Number(formObj.quantity)
    }

    try {
      await dispatch(updateItem(selectedKitPreference?.id, formObj.id, doc));
      await fetchKitPreferenceItems(selectedKitPreference?.id, true);
      selectItem({ ...selectedItem, ...doc });
      stopLoading();
      showAlert('success', 'Successfully updated');
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onDeleteItem = async () => {
    startDeleting();

    try {
      await dispatch(deleteItem(selectedKitPreference.id, selectedItem.id));
      await Promise.all(
        items
          .filter((step) => step.orderNumber > selectedItem.orderNumber)
          .map((step) => dispatch(updateItem(selectedKitPreference.id, step.id, { orderNumber: step.orderNumber - 1 })))
      );
      await fetchKitPreferenceItems(selectedKitPreference.id, true);
      toggleDeleteItemModal(false);
      selectItem(null);
      showAlert('success', 'Item has been successfully deleted');
    } catch (err) {
      console.error(err);
    } finally {
      stopDeleting();
    }
  };
  
  const onDeleteClick = () => {
    if (selectedItem) {
      return toggleDeleteItemModal(true);
    }

    if (selectedKitPreference) {
      return toggleDeleteKitPreferenceModal(true);
    }
  };

  const renderUpdateForm = () => {
    if (selectedItem) {
      if (selectedItem.type === kitPreferenceItemTypes.TITLE.value) {
        return (
          <TitleForm
            initialValues={selectedItem}
            onSubmit={onUpdateTitle}
            onClose={() => toggleTitleModal(null)}
            mode='update'
            buttonText='Save Changes'
            loading={loading}
          />
        )
      }

      return (
        <KitPreferenceItemForm
          initialValues={{ ...selectedItem, quantity: selectedItem?.quantity || 1 }}
          onSubmit={onUpdateItem}
          onDeleteClick={onDeleteClick}
          loading={loading}
          itemsList={itemsList}
          kitsList={kits}
        />
      );
    }

    if (selectedKitPreference) {
      return (
        <KitPreferenceForm
          initialValues={selectedKitPreference}
          onSubmit={onSubmitKitPreference}
          mode='update'
          loading={loading}
          procedures={procedures}
          surgeons={surgeons}
          hospitals={hospitals}
        />
      );
    }

    return null;
  };

  const onReorder = async (items) => {
    dispatch(reorderItems(selectedKitPreference.id, items));
    setItems(items);
  };

  return (
    <div className='settings-cmp__main flex-1'>
      {!itemsLoaded && <LoadScreen />}
      <span className='settings-title'>Kit Preferences</span>
      <div className='settings-cmp__body flex-1'>
        <div className='settings-block__left'>
          <KitPreferencesActions
            onAddClick={onAddClick}
            onDeleteClick={onDeleteClick}
            selectedKitPreference={selectedKitPreference}
            deletePermission={deletePermission}
          />
          <KitPreferencesList
            kitPreferences={sortBy(kitPreferences, 'name')}
            onClick={(kitPreference) => {
              selectKitPreference(kitPreference);
              selectItem(null);
            }}
            tenantColor={tenant.colorPrimary}
            selectedKitPreference={selectedKitPreference}
          />
        </div>
        <div className='settings-block__middle'>
          <KitPreferencesBreadcrumbs
            selectedPreference={selectedKitPreference}
            selectedItem={selectedItem}
            tenantColor={tenant.colorPrimary}
          />
          <PreferenceItemsList
            loading={fetchingItems}
            items={items}
            onClick={selectItem}
            selectedItem={selectedItem}
            tenantColor={tenant.colorPrimary}
            onReorder={onReorder}
            itemsList={itemsList}
            kitsList={kits}
          />
        </div>
        <div className='settings-block__right'>
          <Input
            type='search'
            placeholder='Search'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {renderUpdateForm()}
        </div>
      </div>

      <Modal
        open={!!kitPreferenceModal}
        onClose={() => toggleKitPreferenceModal(null)}
      >
        <KitPreferenceForm
          onSubmit={onSubmitKitPreference}
          onClose={() => toggleKitPreferenceModal(null)}
          mode='create'
          buttonText='Add Preference'
          loading={loading}
        />
      </Modal>

      <AddKitsModal
        open={kitsModalOpen}
        onClose={() => toggleKitsModal(false)}
        options={kits}
        onSubmit={(kitIds) => onAddItems(kitIds, kitPreferenceItemTypes.KIT.value)}
        loading={loading}
      />

      <AddItemsModal
        open={itemsModalOpen}
        onClose={() => toggleItemModal(false)}
        options={itemsList}
        onSubmit={(itemIds) => onAddItems(itemIds, kitPreferenceItemTypes.ITEM.value)}
        loading={loading}
      />

      <Modal
        open={!!titleModalOpen}
        onClose={() => toggleTitleModal(null)}
      >
        <TitleForm
          onSubmit={onAddTitle}
          onClose={() => toggleTitleModal(null)}
          mode='create'
          buttonText='Submit'
          loading={loading}
        />
      </Modal>

      <ConfirmationModal
        open={!!deleteKitPreferenceModal}
        onClose={() => toggleDeleteKitPreferenceModal(false)}
        onSubmit={onDeleteKitPreference}
        title='Are you sure you want to delete this kit preference?'
        submitText='Delete'
        loading={deleting}
      />

      <ConfirmationModal
        open={!!deleteItemModal}
        onClose={() => toggleDeleteItemModal(false)}
        onSubmit={onDeleteItem}
        title='Are you sure you want to delete this preference item?'
        submitText='Delete'
        loading={deleting}
      />
    </div>
  );
};

export default KitPreferencesPage;
