import React, { useState, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import moment from 'moment';

import Select from '../../shared/Select';
import Autocomplete from '../../shared/autocomplete/Autocomplete';
import Button from '../../shared/Button';
import DatePicker from '../../shared/date-picker/DatePicker';
import ConfirmationModal from '../../shared/modal/ConfirmationModal';

import { useAlert } from '../../../hooks';

import { caseStatusOptions } from '../../../constants/enums';
import { territoryRoles } from '../../../constants/userRoles';

const Step1 = (props) => {
  const {
    initialValues,
    onClickNext,
    onClose,
    surgeons,
    hospitals,
    procedures,
    procedureOptions,
    status,
    flows = [],
    mode = 'create',
    currentUser
  } = props;

  const { showAlert } = useAlert();

  const [selectedSurgeon, setSelectedSurgeon] = useState(initialValues.surgeon || null);
  const [selectedProcedure, setSelectedProcedure] = useState(initialValues.procedure || null);
  const [isOpen, toggleModal] = useState(false);
  const [data, setData] = useState({});
  const [mandatoryFlow, setMandatoryFlow] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.surgeon) {
      errors.surgeon = 'Required';
    }
    if (!values.hospital) {
      errors.hospital = 'Required';
    }
    if (!values.procedure) {
      errors.procedure = 'Required';
    }

    return errors;
  };

  const surgeonsList = useMemo(() => {
    if (!surgeons) {
      return [];
    }

    let arr = surgeons;

    if (territoryRoles.includes(currentUser.role) && !!currentUser.territoryVisibility) {
      arr = arr?.filter((surgeon) => {
        return surgeon.hospitals?.some((hospitalId) => {
          const hospital = hospitals?.find((h) => h.id === hospitalId);
          return hospital && hospital?.territories?.some((territory) => currentUser?.assignedTerritories?.includes(territory))
        });
      });
    }

    return arr.map((surgeon) => ({ label: `${surgeon.title} ${surgeon.firstName} ${surgeon.lastName}`, value: surgeon.id }));
  }, [surgeons, hospitals, currentUser]);

  const hospitalsList = useMemo(() => {
    if (!hospitals || !selectedSurgeon) {
      return [];
    }

    let arr = hospitals
      .filter((hospital) => {
        const surgeon = surgeons.find((item) => item.id === selectedSurgeon);
        return surgeon && surgeon.hospitals.includes(hospital.id);
      });

    if (territoryRoles.includes(currentUser.role) && !!currentUser.territoryVisibility) {
      arr = arr?.filter((hospital) => {
        return hospital.territories?.some((territory) => currentUser?.assignedTerritories?.includes(territory));
      });
    }

    return arr.map((hospital) => ({ label: hospital.name, value: hospital.id }));
  }, [hospitals, selectedSurgeon, currentUser]);

  const proceduresList = useMemo(() => {
    if (!procedures || !selectedSurgeon) {
      return [];
    }
    return procedures
      .filter((procedure) => {
        const surgeon = surgeons.find((item) => item.id === selectedSurgeon);
        return surgeon && surgeon.procedures.includes(procedure.id);
      })
      .map((procedure) => ({ label: procedure.name, value: procedure.id }));
  }, [procedures, selectedSurgeon]);

  const optionsList = useMemo(() => {
    if (!procedureOptions || !selectedProcedure) {
      return [];
    }

    return procedureOptions
      .filter((option) => {
        const procedure = procedures.find((item) => item.id === selectedProcedure);
        return procedure && procedure.options.includes(option.id);
      })
      .map((option) => ({ label: option.name, value: option.id }));
  }, [procedureOptions, selectedProcedure]);

  const onSubmit = (formObj) => {
    const today = moment();


    if (!formObj.date || !(moment(formObj.date)?.isValid())) {
      showAlert('warning', 'Invalid Date');
      return;
    }

    if (today.isAfter(formObj.date, 'day')) {
      toggleModal(true);
      setData(formObj);
    } else {
      onClickNext(formObj);
    }
  };

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
        subscription={{ pristine: true, invalid: true, values: true }}
        mutators={{
          resetHospital: (args, state, utils) => {
            utils.changeValue(state, 'hospital', () => null)
          },
          resetProcedure: (args, state, utils) => {
            utils.changeValue(state, 'procedure', () => null)
          },
          resetProcedureOption: (args, state, utils) => {
            utils.changeValue(state, 'procedureOption', () => null)
          },
          setDefaultFlow: ([value], state, utils) => {
            utils.changeValue(state, 'flow', () => value)
          },
        }}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine, form, values }) => (
            <form onSubmit={handleSubmit} className='form__body'>

              <div className='m-b-lg'>
                <div className='field__title'>Case Date</div>
                <Field name='date'>
                  {({ input, meta }) => (
                    <DatePicker
                      name={input.name}
                      date={input.value}
                      onDateChange={input.onChange}
                      disabled={status && status === caseStatusOptions.completed}
                      keyboard
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-lg'>
                <div className='field__title'>Surgeon</div>
                <Field name='surgeon'>
                  {({ input, meta }) => (
                    <Autocomplete
                      name={input.name}
                      value={input.value}
                      onChange={(value) => {
                        input.onChange(value);
                        setSelectedSurgeon(value);
                        form.mutators?.resetHospital();
                        form.mutators?.resetProcedure();
                        form.mutators?.resetProcedureOption();
                      }}
                      options={surgeonsList}
                      disabled={status && status !== caseStatusOptions.request}
                      placeholder="Start typing to search surgeons"
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-lg'>
                <div className='field__title'>Hospital</div>
                <Field name='hospital'>
                  {({ input, meta }) => (
                    <Autocomplete
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      options={hospitalsList}
                      disabled={status && (status === caseStatusOptions.overdue || status === caseStatusOptions.completed)}
                      placeholder="Start typing to search hospitals"
                    />
                  )}
                </Field>
              </div>
              <div className='m-b-lg'>
                <div className='field__title'>Procedure</div>
                <Field name='procedure'>
                  {({ input, meta }) => (
                    <Autocomplete
                      name={input.name}
                      value={input.value}
                      onChange={(value) => {
                        input.onChange(value);
                        setSelectedProcedure(value);
                        form.mutators?.resetProcedureOption();

                        const procedure = procedures?.find((p) => p.id === value);
                        if (procedure) {
                          const list = flows?.filter((item) => item?.procedures?.includes(value));
                          const flow = list?.find((f) => procedure?.mandatoryFlow === f.id);
                          if (flow) {
                            form.mutators?.setDefaultFlow(flow.id);
                            setMandatoryFlow(true);
                          } else {
                            form.mutators?.setDefaultFlow(null);
                            setMandatoryFlow(false);
                          }
                        }
                      }}
                      options={proceduresList}
                      disabled={status && status !== caseStatusOptions.request}
                      placeholder="Start typing to search procedures"
                    />
                  )}
                </Field>
              </div>

              {values.procedure && !!optionsList?.length && (
                <div className='m-b-lg'>
                  <div className='field__title'>Procedure option</div>
                  <Field name='procedureOption'>
                    {({ input, meta }) => (
                      <Select
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                        options={optionsList}
                        disabled={status && (status === caseStatusOptions.overdue || status === caseStatusOptions.completed)}
                      />
                    )}
                  </Field>
                </div>
              )}

              {values.procedure && mode === 'create' &&
              !!flows?.filter((item) => item?.procedures?.includes(values?.procedure)).map((item) => ({ label: item.name, value: item.id }))?.length && (
                <div className='m-b-lg'>
                  <div className='field__title'>Flow</div>
                  <Field name='flow'>
                    {({ input, meta }) => (
                      <Select
                        disabled={mandatoryFlow}
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                        options={flows?.filter((item) => item?.procedures?.includes(values?.procedure)).map((item) => ({ label: item.name, value: item.id }))}
                      />
                    )}
                  </Field>
                </div>
              )}

              <div className='form__actions m-t-lg'>
                <Button
                  type='submit'
                  text='Next'
                  disabled={invalid}
                  onClick={handleSubmit}
                />
                <Button
                  type='cancel'
                  onClick={onClose}
                />
              </div>
            </form>
          )}
      </Form>

      <ConfirmationModal
        open={isOpen}
        onClose={() => toggleModal(false)}
        onSubmit={() => onClickNext(data)}
        title='Historical Case Date!'
        text='You have selected a historical case date, are you sure you want to proceed?'
        submitText='Proceed'
      />
    </div>
  );
};

export default Step1;
