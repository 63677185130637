import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadScreen from '../../../components/load-screen';
import ConsignmentForm from '../../../components/reporting/set-turns/ConsignmentForm';
import SetTurnsTable from '../../../components/reporting/set-turns/SetTurnsTable';

import { getHospitals } from '../../../actions/hospitalsActions';
import { getKits } from '../../../actions/kitsActions';
import { getSetLogs, getSets, getLabels } from '../../../actions/setsActions';

import useLoading from '../../../hooks/useLoading';

import { setLogTypes } from '../../../constants/setTurnsReporting';

const ConsignmentTurnsReportingPage = () => {
  const dispatch = useDispatch();
  const hospitals = useSelector((state) => state?.hospitals?.list);
  const sets = useSelector((state) => state?.sets?.list?.filter((set) => !!set.active));
  const labels = useSelector((state) => state?.sets?.labels);
  const kits = useSelector((state) => state?.kits?.list);

  const { loading: fetching, startLoading, stopLoading } = useLoading(false);

  const [hospital, setHospital] = useState(null);
  const [target, setTarget] = useState(5);
  const [range, setRange] = useState(3);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    onLoad().catch((err) => console.error(err));
  }, []);

  const onLoad = async () => {
    startLoading()
    try {
      await Promise.all([
        dispatch(getHospitals()),
        dispatch(getSets()),
        dispatch(getLabels()),
        dispatch(getKits()),
      ]);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const list = sets?.filter((item) => item.consignment && item?.consignment?.hospital === hospital);
      const result = [];

      for (const set of list) {
        const logs = await dispatch(getSetLogs(set.id, range));
        const turns = logs?.filter((log) => log.type === setLogTypes.SET_TURNS)?.length;
        const average = Math.round(turns / range);
        const performance = average >= target;

        const kit = kits?.find((item) => item.id === set.kit);
        const kitId = kit?.kitId || '';
        const kitName = kit?.name || '';

        result?.push({
          id: set.id,
          kitId,
          kitName,
          setId: set.id,
          setNumber: set.number,
          labels: set.labels || [],
          turns,
          average,
          performance
        });
      }

      setData(result);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const hospitalsList = useMemo(() => (
    hospitals?.filter((hospital) => (
      !!sets?.find((set) => set?.consignment && set?.consignment?.hospital === hospital.id)
    ))
  ), [sets, hospitals])

  return (
    <div className='page-container reporting-page__container'>
      {fetching && <LoadScreen />}
      <div className='page-title'>Consignment Turns Report</div>
      <div className='reporting-page__body'>
        <ConsignmentForm
          hospitals={hospitalsList}
          hospital={hospital}
          setHospital={setHospital}
          target={target}
          setTarget={setTarget}
          range={range}
          setRange={setRange}
          onSubmit={onSubmit}
          loading={loading}
        />

        {data && !!data.length && (
          <SetTurnsTable
            data={data}
            labels={labels}
          />
        )}
      </div>
    </div>
  );
};

export default ConsignmentTurnsReportingPage;
