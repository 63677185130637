import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';

import { getStatusIcon, getStatusLabel } from '../../utils/cases';

import { caseStatusOptions } from '../../constants/enums';
import { statusColors } from '../../constants/cases';

const StyledButton = withStyles((theme) => ({
  root: {
    height: '60px',
    width: '80px',
    borderRadius: '16px',
  }
}))(MuiButton);

const FilterButton = ({
  status,
  value,
  onClick,
}) => {
  return (
    <StyledButton
      style={{
        background: value ? statusColors[caseStatusOptions[status]] : '#dddddd',
      }}
      value={value}
      onClick={() => onClick(status)}
    >
      <div className='d-flex direction-column space-between'>
        {getStatusIcon(caseStatusOptions[status])}
        <div className='filter-button__label'>
          {getStatusLabel(caseStatusOptions[status])}
        </div>
      </div>
    </StyledButton>
  )
};

export default FilterButton;
