import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function NotificationsIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M5.67725 12.4579C5.93009 12.9294 6.42761 13.25 7 13.25C7.5724 13.25 8.06991 12.9294 8.32276 12.4579" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M4.2624 1.88396C4.98846 1.1579 5.97321 0.75 7.00002 0.75C8.02682 0.75 9.01156 1.1579 9.73763 1.88396C10.4637 2.61002 10.8716 3.59477 10.8716 4.62158C10.8716 5.22574 10.9713 5.79344 11.1213 6.37411C11.1633 6.50556 11.2096 6.6296 11.2594 6.74662C11.4914 7.29239 12.1302 7.47862 12.6069 7.83149C13.3184 8.35821 13.1808 9.48523 12.589 9.92281C12.589 9.92281 11.6335 10.75 7.00002 10.75C2.36648 10.75 1.411 9.92281 1.411 9.92281C0.819217 9.48523 0.681663 8.35821 1.39314 7.83149C1.86979 7.47862 2.50859 7.29243 2.74066 6.74667C2.97198 6.20267 3.12844 5.50677 3.12844 4.62158C3.12844 3.59477 3.53634 2.61002 4.2624 1.88396Z" />
  </SvgIcon>
}
