import React, { useMemo } from 'react';
import { orderBy } from 'lodash';

import BarChart from '../../../charts/BarChart';

const ProceduresBarChart = ({ cases = [], procedures = [] }) => {
  const data = useMemo(() => {
    const allProcedures = procedures
      .map((procedure) => {
        const procedureCases = cases.filter((item) => item.procedure === procedure.id);
        return {
          id: procedure.name,
          label: `${procedure?.name?.substring(0, 10)}${procedure?.name?.length > 10 ? '...' : ''}`,
          value: procedureCases.length,
        };
      })
      .filter((procedure) => procedure.value !== 0);

    const sortedProcedures = orderBy(allProcedures, 'label', 'desc');
    const top10 = sortedProcedures.splice(0, 10);

    return top10;
  }, [cases, procedures]);

  return (
    <div className="management-reports-chart__container m-l-md" style={{ width: '50%' }}>
      <div className="font-size-bg font-bold">Top Procedures</div>
      <div className="secondary m-t-sm m-b-lg">Total cases for top 10 procedures</div>
      <BarChart
        data={data || []}
        height="300px"
        layout="horizontal"
        enableLabel={true}
        margin={{ top: 10, right: 30, bottom: 30, left: 90 }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          truncateTickAt: 0,
          format: e => e,
        }}
        labelTextColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              1.6,
            ],
          ],
        }}
      />
    </div>
  );
};

export default ProceduresBarChart;
