import { orderBy } from 'lodash';

import { GET_USERS, SET_USERS, SET_CONNECTED_USERS } from './actionTypes';

import firebase, { collections } from '../firebase';

import { post } from '../utils/api';

import urls from '../constants/urls';
import { multiTenantRoles } from '../constants/userRoles';

export const setUsers = (users) => async (dispatch, getState) => {
  const state = getState();
  const groups = state.groups.list;

  const usersWithGroup = users.map((user) => {
    const userGroups = groups.filter(item => item.members.includes(user.uid));
    return { ...user, groups: userGroups || [] };
  });

  return dispatch({ type: SET_USERS, users: usersWithGroup });
};

export const setConnectedUsers = users => ({ type: SET_CONNECTED_USERS, users });

export const getUsers = () => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;

  dispatch({ type: GET_USERS });

  const snapshot = await firebase
    .db
    .collection(collections.USERS_COLLECTION)
    .where('tenantIds', 'array-contains', tenantId)
    .get();
  const users = snapshot.docs?.map((doc) => doc.data());

  return dispatch(setUsers(orderBy(users, 'lastName', 'asc')));
};

export const createUser = (userData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;

  if (tenantId) {
    await post(urls.users.create(), { userData, tenantId });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const resendUserInvite = (email) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;

  if (tenantId) {
    await post(urls.users.sendInvite(), { email, tenantId });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateUser = (userData, simpleUpdate) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;

  if (tenantId) {
    const { role } = userData;

    if (multiTenantRoles.includes(role) || simpleUpdate) {
      await firebase.db.collection(collections.USERS_COLLECTION).doc(userData.uid).set(userData, { merge: true });
    } else {
      await post(urls.users.update(), { userData, tenantId });
    }
  } else {
    throw new Error('Invalid tenant');
  }
};

export const declineAccess = (userId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  await firebase.db.collection(collections.USERS_COLLECTION)
    .doc(userId)
    .set({
      connectedTenants: firebase.fieldValue.arrayRemove(tenantId)
    }, { merge: true });
}

export const removeUserFromTenant = (userId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  await firebase.db.collection(collections.USERS_COLLECTION)
    .doc(userId)
    .set({
      tenantIds: firebase.fieldValue.arrayRemove(tenantId)
    }, { merge: true });
}

export const getConnectedUsers = (withReducer) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;

  const snapshot = await firebase
    .db
    .collection(collections.USERS_COLLECTION)
    .where('connectedTenants', 'array-contains', tenantId)
    .get();
  const users = snapshot.docs?.map((doc) => ({ ...doc.data(), groups: [] }));

  if (withReducer) {
    return dispatch(setConnectedUsers(orderBy(users, 'lastName', 'asc')));
  }

  return orderBy(users, 'lastName', 'asc')
};

// Subscriptions
export const subscribeToUsers = () => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;

  return firebase
    .db
    .collection(collections.USERS_COLLECTION)
    .where('tenantIds', 'array-contains', tenantId)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let users = [];
        querySnapshot.forEach((documentSnapshot) => {
          users = [...users, {
            ...documentSnapshot.data(),
          }];
        });

        return dispatch(setUsers(orderBy(users, 'lastName', 'asc')));
      },
    });
};

// Subscriptions
export const subscribeToConnectedUsers = () => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;

  return firebase
    .db
    .collection(collections.USERS_COLLECTION)
    .where('connectedTenants', 'array-contains', tenantId)
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        let users = [];
        querySnapshot.forEach((documentSnapshot) => {
          users = [...users, {
            ...documentSnapshot.data(),
            groups: [],
          }];
        });

        return dispatch(setConnectedUsers(orderBy(users, 'lastName', 'asc')));
      },
    });
};
