import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import HospitalsTable from '../../../../components/hospitals/HospitalsTable';
import HospitalsFilters from '../../../../components/hospitals/HospitalsFilters';
import HospitalForm from '../../../../components/hospitals/HospitalForm';
import UploadHospitalsModal from '../../../../components/hospitals/UploadHospitalsModal';
import ConfirmUploadModal from '../../../../components/hospitals/ConfirmUploadModal';
import Input from '../../../../components/shared/Input';
import Modal, { ConfirmationModal } from '../../../../components/shared/modal';
import Alert from '../../../../components/shared/Alert';

import {
  getHospitals,
  createHospital,
  updateHospital,
  subscribeToHospitals,
  bulkDeleteHospitals,
  uploadHospitals,
} from '../../../../actions/hospitalsActions';
import { getTerritories } from '../../../../actions/territoriesActions';

import { filterHospitals } from '../../../../utils/table';

import { statusOptions } from '../../../../constants/enums';
import { countries } from '../../../../constants';

const statusFilterOptions = [
  { label: 'Active', value: statusOptions.active },
  { label: 'Deactivated', value: statusOptions.deactivated }
];
const countryFilterOptions = countries.map(({ label }) => ({ label, value: label }));

const HospitalsPage = (props) => {
  const {
    hospitals,
    createHospital,
    updateHospital,
    subscribeToHospitals,
    bulkDeleteHospitals,
    uploadHospitals,
    tenantColor,
    getTerritories,
    territories = [],
    deletePermission,
  } = props;

  const [isModalCreateOpen, toggleModalCreate] = useState(false);
  const [isModalDeleteOpen, toggleModalDelete] = useState(false);
  const [isUploadModalOpen, toggleUploadModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDeleting, toggleDeleting] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const [selectedHospital, setSelectedHospital] = useState(null);
  const [checkedHospitals, setCheckedHospitals] = useState([]);

  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [countryFilter, setCountryFilter] = useState('');

  const [successHospitals, setSuccessHospitals] = useState([]);
  const [failedHospitals, setFailedHospitals] = useState([]);
  const [confirmationModalOpen, toggleConfirmationModal] = useState(false);

  useEffect(() => {
    const unsubscribe = subscribeToHospitals();

    onLoad().catch((err) => console.error(err));

    return () => {
      unsubscribe();
    };
  }, []);

  const onLoad = async () => {
    await getTerritories();
  };

  const onSelectHospital = (hospital) => {
    setSelectedHospital(hospital);
  };

  // Create hospital
  const onCreateHospital = async (formObj) => {
    if (hospitals?.map((i) => i.name)?.includes(formObj?.name?.trim())) {
      setErrorMessage('You already have a hospital with this name');
      return;
    }

    setLoading(true);
    const hospitalObj = {
      name: formObj.name,
      country: formObj.country,
      street: formObj.street,
      city: formObj.city,
      state: formObj.state,
      postCode: formObj.postCode,
      accountNumber: formObj.accountNumber || '',
      businessNumber: formObj.businessNumber || '',
      leadDays: formObj.leadDays || null,
      customBillingAddress: false,
      billingAddress: null,
      territories: []
    };

    try {
      await createHospital(hospitalObj);
      setLoading(false);
      toggleModalCreate(false);
      setSuccessMessage('Hospital has been successfully created');
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Update hospital
  const onUpdateHospital = async (formObj) => {
    setLoading(true);
    const hospitalObj = {
      active: formObj.active,
      name: formObj.name,
      country: formObj.country,
      street: formObj.street,
      city: formObj.city,
      state: formObj.state,
      postCode: formObj.postCode,
      locations: formObj.locations || [],
      territories: formObj.territories || [],
      accountNumber: formObj.accountNumber || '',
      businessNumber: formObj.businessNumber || '',
      customBillingAddress: !formObj.defaultBillingAddress,
      billingAddress: !formObj.defaultBillingAddress && formObj.billingAddress ? formObj.billingAddress : null,
      leadDays: formObj.leadDays || null
    };

    const deliveryAddresses = [...formObj?.deliveryAddresses] || [];
    const defaultAddress = deliveryAddresses?.find((address) => address.id === 'default_address');
    if (defaultAddress) {
      defaultAddress.country = formObj.country;
      defaultAddress.city = formObj.city;
      defaultAddress.state = formObj.state;
      defaultAddress.postCode = formObj.postCode;
      defaultAddress.street = formObj.street;
    }
    hospitalObj.deliveryAddresses = deliveryAddresses;

    try {
      await updateHospital(formObj.id, hospitalObj);
      setLoading(false);
      setSuccessMessage('Hospital has been successfully updated');
      setSelectedHospital(formObj);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Delete checked hospitals
  const onDeleteHospitals = async () => {
    toggleDeleting(true);

    try {
      await bulkDeleteHospitals(checkedHospitals);

      toggleModalDelete(false);
      setCheckedHospitals([]);
      setSelectedHospital(null);
    } catch (err) {
      console.error(err);
    } finally {
      toggleDeleting(false);
    }
  };

  const handleDeleteClick = () => {
    if (checkedHospitals.length) {
      toggleModalDelete(true);
    }
  };

  // Upload hospitals

  const onUpload = async (newHospitals) => {
    setLoading(true);

    try {
      if (newHospitals?.some((hospital) => hospitals?.find((h) => h?.name?.toLowerCase()?.trim() === hospital[0]?.toLowerCase()?.trim()))) {
        setSuccessHospitals(newHospitals?.filter((hospital) => !hospitals?.find((h) => h?.name?.toLowerCase()?.trim() === hospital[0]?.toLowerCase()?.trim())));
        setFailedHospitals(newHospitals?.filter((hospital) => hospitals?.find((h) => h?.name?.toLowerCase()?.trim() === hospital[0]?.toLowerCase()?.trim())));
        setLoading(false);
        toggleUploadModal(false);
        toggleConfirmationModal(true);
      } else {
        await uploadHospitals(newHospitals);
        setLoading(false);
        setSuccessMessage('Hospitals have been successfully uploaded');
        toggleUploadModal(false);
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const continueUpload = async () => {
    try {
      setLoading(true);
      await uploadHospitals(successHospitals);
      setLoading(false);
      toggleConfirmationModal(false);
      setSuccessMessage('Hospitals have been successfully uploaded');
      setSuccessHospitals([]);
      setFailedHospitals([]);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    // setCheckedHospitals([]);
  };

  const hospitalList = useMemo(() => {
    return hospitals.filter((hospital) => filterHospitals(hospital, search, statusFilter, countryFilter));
  }, [hospitals, search, statusFilter, countryFilter]);

  const handleCheck = (hospitalId) => {
    const checked = checkedHospitals.slice();

    if (checked.includes(hospitalId)) {
      const index = checked.indexOf(hospitalId);

      checked.splice(index, 1);
      setCheckedHospitals(checked);

      return;
    }

    checked.push(hospitalId);
    setCheckedHospitals(checked);
  };

  const handleCheckAll = () => {
    const checked = checkedHospitals.length === hospitalList.length;
    const temp = [];

    if (!checked) {
      hospitalList.forEach((hospital) => {
        temp.push(hospital.id);
      });

      setCheckedHospitals(temp);
      return;
    }

    setCheckedHospitals(temp);
  };

  return (
    <div className='settings-cmp__main'>
      <span className='settings-title'>Hospitals</span>
      <div className='settings-cmp__body'>
        <div className='filters-container'>
          <HospitalsFilters
            onAddHospitalClick={() => toggleModalCreate(true)}
            onDelete={handleDeleteClick}
            status={statusFilter}
            setStatus={setStatusFilter}
            statuses={statusFilterOptions}
            country={countryFilter}
            setCountry={setCountryFilter}
            countries={countryFilterOptions}
            onUploadClick={() => toggleUploadModal(true)}
            deletePermission={deletePermission}
          />
          <HospitalsTable
            onSelectHospital={onSelectHospital}
            hospitals={hospitalList}
            selectedHospitalId={selectedHospital ? selectedHospital.id : null}
            handleCheck={handleCheck}
            checkedHospitals={checkedHospitals}
            handleCheckAll={handleCheckAll}
          />
        </div>
        { hospitals && !!hospitals.length && (
          <div className='form-container'>
            <Input
              type='search'
              placeholder='Search Hospitals'
              value={search}
              onChange={handleSearch}
            />
            { selectedHospital && (
              <HospitalForm
                initialValues={{
                  ...selectedHospital,
                  defaultBillingAddress: !selectedHospital.customBillingAddress,
                  territories: selectedHospital?.territories || []
                }}
                buttonText='Save Changes'
                onSubmit={onUpdateHospital}
                loading={loading}
                mode='update'
                territories={territories}
                hospitals={hospitals}
              />
            )}
          </div>
        )}
      </div>

      <ConfirmationModal
        open={isModalDeleteOpen}
        onClose={() => toggleModalDelete(false)}
        onSubmit={onDeleteHospitals}
        title='Are you sure you want to delete these hospitals?'
        submitText='Delete'
        loading={isDeleting}
      />

      <Modal
        open={isModalCreateOpen}
        onClose={() => toggleModalCreate(false)}
      >
        <HospitalForm
          buttonText='Add Hospital'
          onSubmit={onCreateHospital}
          loading={loading}
          mode='create'
          onClose={() => toggleModalCreate(false)}
          territories={territories}
          hospitals={hospitals}
        />
      </Modal>

      <UploadHospitalsModal
        open={isUploadModalOpen}
        onClose={() => toggleUploadModal(false)}
        tenantColor={tenantColor}
        onSubmit={onUpload}
        loading={loading}
      />

      <ConfirmUploadModal
        open={confirmationModalOpen}
        onClose={() => {
          toggleConfirmationModal(false);
          setSuccessHospitals([]);
          setFailedHospitals([]);
        }}
        duplicates={failedHospitals}
        loading={loading}
        onSubmit={continueUpload}
      />

      <Alert
        variant='success'
        message={successMessage}
        open={!!successMessage}
        onClose={() => setSuccessMessage(null)}
      />

      <Alert
        variant='warning'
        message={errorMessage}
        open={!!errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    hospitals: state.hospitals.list,
    tenantColor: state.tenant.currentTenant.colorPrimary,
    territories: state?.territories?.list || [],
    deletePermission: state.user.currentUser?.deletePermission,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHospitals: () => dispatch(getHospitals()),
    getTerritories: () => dispatch(getTerritories()),
    createHospital: (hospital) => dispatch(createHospital(hospital)),
    updateHospital: (id, hospitalData) => dispatch(updateHospital(id, hospitalData)),
    subscribeToHospitals: () => dispatch(subscribeToHospitals()),
    bulkDeleteHospitals: (hospitalIds) => dispatch(bulkDeleteHospitals(hospitalIds)),
    uploadHospitals: (hospitals) => dispatch(uploadHospitals(hospitals)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HospitalsPage);
