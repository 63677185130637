import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function GetAppIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none"
          d="M1.18233 11.887C1.23387 12.3672 1.61486 12.7479 2.09496 12.8004C3.66389 12.9719 5.30725 13.2076 7.00003 13.2076C8.69281 13.2076 10.3362 12.9719 11.9051 12.8004C12.3852 12.7479 12.7662 12.3672 12.8177 11.887C12.9855 10.3238 13.2076 8.68651 13.2076 7C13.2076 5.31349 12.9855 3.67619 12.8177 2.11299C12.7662 1.63279 12.3852 1.25212 11.9051 1.19963C10.3362 1.02811 8.69281 0.79245 7.00003 0.79245C5.30725 0.79245 3.66389 1.0281 2.09496 1.19963C1.61486 1.25212 1.23387 1.63279 1.18233 2.11299C1.01454 3.67619 0.79248 5.31349 0.79248 7C0.79248 8.68651 1.01454 10.3238 1.18233 11.887Z" />
    <path stroke={color || defaultColor} fill="none"
          d="M13.0492 4.2369C13.0046 3.78864 12.9548 3.34519 12.9056 2.90707C12.8757 2.64036 12.8459 2.37563 12.8177 2.11299C12.7662 1.63279 12.3852 1.25212 11.9051 1.19963C11.6118 1.16757 11.3159 1.13326 11.0176 1.09868C9.72006 0.948236 8.37639 0.79245 7.00004 0.79245C5.6237 0.79245 4.28002 0.948236 2.98246 1.09868C2.68414 1.13326 2.38825 1.16757 2.09497 1.19963C1.61487 1.25212 1.23388 1.63279 1.18234 2.11299C1.15415 2.37563 1.12442 2.64036 1.09448 2.90706C1.04529 3.34519 0.995503 3.78864 0.950928 4.2369H13.0492Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M5.22546 8.71759C5.22546 9.06488 5.27962 9.53915 5.34091 9.96465C5.42187 10.5267 5.97557 10.8884 6.51173 10.7014C7.43554 10.379 8.29377 9.90922 8.92506 9.23697C9.20057 8.94359 9.20059 8.49159 8.92509 8.19819C8.29384 7.52593 7.43558 7.05611 6.51176 6.73382C5.97563 6.54677 5.42194 6.90844 5.34096 7.47047C5.27965 7.89598 5.22546 8.37028 5.22546 8.71759Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M5.38674 0.855476L4.46121 4.23689" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M9.49678 0.929377L8.59485 4.23688" />
  </SvgIcon>
}
