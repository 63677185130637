import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function OverdueCountIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M2.11463 11.3428C4.35913 13.9785 9.64108 13.9785 11.8856 11.3428C13.9529 8.9151 13.796 4.16332 11.3078 2.0849C9.079 0.223245 4.92121 0.223245 2.69245 2.0849C0.204203 4.16332 0.0472987 8.9151 2.11463 11.3428Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M7 4.5V7" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M7 9.25L7 9.5" />
  </SvgIcon>
}
