import React from 'react';

import Select from '../../shared/Select';
import Button from '../../shared/Button';
import DatePicker from '../../shared/date-picker/DatePicker';

import './styles.scss';

const targetOptions = [];
for (let i = 1; i <= 20; i++) {
  targetOptions?.push({ label: i, value: i });
}

const rangeOptions = [
  { label: '1 month', value: 1 },
  { label: '3 months', value: 3 },
  { label: '6 months', value: 6 },
  { label: '12 months', value: 12 },
];

const ExpiringItemsForm = (props) => {
  const {
    range,
    setRange,
    date,
    setDate,
    onSubmit,
    loading,
  } = props;

  return (
    <div className='expiry-items-form__container'>
      <div className='expiry-items-form__field'>
        <div>Items Expiring Within</div>
        <Select
          value={range}
          onChange={setRange}
          options={rangeOptions}
        />
      </div>

      <div className='expiry-items-form__field' style={{ flex: 2 }}>
        <div className='field__title'>Expiring Before</div>
        <div className='reporting-date-range__container'>
          <div className='reporting-date-range__picker'>
            <div className='secondary' style={{ width: 40 }}>Date</div>
            <DatePicker
              date={date}
              onDateChange={(date) => {
                setDate(date);
                setRange(null);
              }}
              // minDate={moment()}
              withoutStyles
            />
          </div>

          <div className='flex-1' />
        </div>
      </div>

      <div className='expiry-items-form__button'>
        <Button
          onClick={onSubmit}
          disabled={loading}
          loading={loading}
          text='Run Report'
        />
      </div>
    </div>
  )
};

export default ExpiringItemsForm;
