import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';

import DeleteIcon from '../../../../assets/icons/DeleteIcon';
import TagIcon from '../../../../assets/icons/TagIcon';

import Button from '../../../shared/Button';
import Input from '../../../shared/Input';
import { ConfirmationModal } from '../../../shared/modal';
import Select from '../../../shared/Select';

import '../../../../styles/shared/form.scss';

const UsageImplantsForm = (props) => {
  const {
    initialValues = {},
    onSubmit = () => {
    },
    loading,
    onClose,
    onDelete,
    tags = []
  } = props;

  const [deleteModalOpen, toggleDeleteModal] = useState(false);

  const validate = (values) => {
    const errors = {};

    // if (!values.ref) {
    //   errors.ref = 'Required';
    // }
    //
    // if (!values.lot) {
    //   errors.lot = 'Required';
    // }

    if (!values.quantity) {
      errors.quantity = 'Required';
    }

    return errors;
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        subscription={{ pristine: true, invalid: true }}
        initialValues={initialValues}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <div className='combined-fields__container'>
                <Field name='gtin'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      borderless
                      type='text'
                      onChange={input.onChange}
                      startAdornment='GTIN'
                      placeholder='Type GTIN here'
                    />
                  )}
                </Field>
                <Field name='ref'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      borderless
                      type='text'
                      onChange={input.onChange}
                      startAdornment='REF'
                      placeholder='Type REF here'
                    />
                  )}
                </Field>
                <Field name='lot'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      borderless
                      type='text'
                      onChange={input.onChange}
                      startAdornment='LOT'
                      placeholder='Type LOT number here'
                    />
                  )}
                </Field>
                <Field name='exp'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      borderless
                      onChange={input.onChange}
                      startAdornment='EXP'
                      placeholder='YYYY-MM-DD'
                    />
                  )}
                </Field>
                <Field name='desc'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      borderless
                      type='text'
                      onChange={input.onChange}
                      startAdornment='DESC'
                      placeholder='Type description here'
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-lg'>
                <div className='field__title'>Item Tag</div>
                <Field name='tag'>
                  {({ input, meta }) => (
                    <Select
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      options={[{ key: 'empty', label: 'No Tags', value: '' }, ...tags?.map((tag) => ({ key: tag.id, value: tag.id, label: tag.name, icon: <TagIcon color={tag.color} /> }))]}
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-lg'>
                <div className='field__title'>Quantity</div>
                <Field name='quantity'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      type='text'
                      onChange={input.onChange}
                      numbersOnly
                    />
                  )}
                </Field>
              </div>

              <div className='form__actions'>
                <div className='d-flex'>
                  {initialValues && Object.keys(initialValues).length > 1 && (
                    <div className='m-r-lg'>
                      <Button
                        type='icon'
                        onClick={() => toggleDeleteModal(true)}
                      >
                        <DeleteIcon color="#000000" />
                      </Button>
                    </div>
                  )}
                  <Button
                    type='submit'
                    text={initialValues && Object.keys(initialValues).length > 1 ? 'Save' : 'Add Usage'}
                    disabled={invalid || loading || pristine}
                    loading={loading}
                    onClick={handleSubmit}
                  />
                </div>
                <Button
                  type='cancel'
                  onClick={onClose}
                />
              </div>
            </form>
          )}
      </Form>
      <ConfirmationModal
        open={!!deleteModalOpen}
        onClose={() => toggleDeleteModal(false)}
        onSubmit={() => {
          toggleDeleteModal(false);
          onDelete();
        }}
        title='Are you sure you want to delete this usage? This action cannot be reversed.'
        submitText='Delete'
      />
    </>
  );
};

export default UsageImplantsForm;
