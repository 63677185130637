import React, { useState } from 'react';

import { SketchPicker } from 'react-color';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import './color-picker.scss';

const ColorPicker = ({ value, onChange }) => {
  const [open, setOpen] = useState(false);
  
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className='color-picker' onClick={() => setOpen(true)}>
        <div className='flex-centered-row'>
          <div className='color-picker__color-box' style={{ '--color-background': value }} />
          <span className='color-text'>{value}</span>
          { open && (
            <div className='color-picker__form'>
              <SketchPicker
                color={value}
                onChangeComplete={color => onChange(color.hex)}
              />
            </div>
          )}
        </div>
        
        <div className='flex-centered-row'>
          <span className='change-text'>Change</span>
          <KeyboardArrowDownIcon
            color='secondary'
          />
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default ColorPicker;
