import { LOAD_TENANT_DATA, SET_TENANT, SET_TENANT_STORAGE, SET_TENANTS } from '../actions/actionTypes';

import { defaultStaticColor } from '../styles/theme/palette';

const initialState = {
  currentTenant: {
    id: '',
    businessName: '',
    businessEmail: '',
    address1: '',
    address2: '',
    country: '',
    state: '',
    city: '',
    logo: {},
    icon: {},
    documentLogo: {},
    maxEmployee: '',
    maxUsers: 100,
    phone: '',
    postCode: '',
    colorPrimary: '',
    customDecodingConfig: [],
    caseCount: 0,
    externalUsersAllowed: false,
    timeZone: '+1000',
    businessNumber: '',
    financialDetails: {}
  },
  isLoading: true,
  isLoaded: false,
  storage: null,
  list: [],
};

const tenantReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TENANT_DATA:
      return { ...state, isLoading: true };
    case SET_TENANT:
      const { tenant } = action;
      return {
        ...state,
        currentTenant: tenant ? {
          id: tenant.id,
          businessName: tenant.businessName,
          businessEmail: tenant.businessEmail,
          address1: tenant.address1,
          address2: tenant.address2,
          country: tenant.country,
          state: tenant.state,
          city: tenant.city,
          logo: tenant.logo || {},
          icon: tenant.icon || {},
          documentLogo: tenant.documentLogo || {},
          maxEmployee: tenant.maxEmployee,
          phone: tenant.phone,
          postCode: tenant.postCode,
          colorPrimary: tenant.colorPrimary || defaultStaticColor,
          customDecodingConfig: tenant.customDecodingConfig || [],
          caseCount: tenant.caseCount || 0,
          externalUsersAllowed: tenant.externalUsersAllowed || false,
          timeZone: tenant.timeZone || '+1000',
          maxUsers: tenant.maxUsers || 100,
          businessNumber: tenant.businessNumber || '',
          financialDetails: tenant.financialDetails || {},
        } : initialState.currentTenant,
        isLoading: false,
        isLoaded: true,
      };
    case SET_TENANT_STORAGE:
      const { data } = action;
      return { ...state, storage: data };
    case SET_TENANTS:
      const { tenants } = action;
      return { ...state, list: tenants || [] };
    default:
      return state;
  }
};

export default tenantReducer;
