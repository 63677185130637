import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function AnalyticsIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M0.75 0.75V11.25C0.75 12.3546 1.64543 13.25 2.75 13.25H13.25" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M0.75 10.75L11.75 10.75C12.3023 10.75 12.75 10.3023 12.75 9.75L12.75 8.75C12.75 8.19772 12.3023 7.75 11.75 7.75L0.75 7.75L0.75 10.75Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M0.75 7.75L7.25 7.75L7.25 4.75L0.75 4.75L0.75 7.75Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M0.75 4.75L9.25 4.75C9.80228 4.75 10.25 4.30228 10.25 3.75L10.25 2.75C10.25 2.19772 9.80228 1.75 9.25 1.75L0.75 1.75L0.75 4.75Z" />
  </SvgIcon>
}
