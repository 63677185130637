import React, { useCallback } from 'react';

import Table from '../shared/table/Table';

import { getCommaSeparatedNames } from '../../utils/table';

const columns = [
  { title: 'name', field: 'name', type: 'default', sort: true },
  { title: 'description', field: 'description', type: 'default', sort: true },
  { title: 'procedures', field: 'procedureNames', type: 'default', sort: true },
  { title: 'status', field: 'active', type: 'status', sort: false },
];

const ProductsTable = (props) => {
  const {
    onSelectProduct,
    products,
    selectedProductId,
    handleCheck,
    checkedProducts,
    handleCheckAll,
    procedures,
  } = props;

  const getProcedureNames = useCallback((ids) => getCommaSeparatedNames(procedures, ids), [products, procedures]);

  return (
    <div style={{ width: '100%' }}>
      <Table
        rows={products.map((product) => ({ ...product, procedureNames: getProcedureNames(product.procedures) }))}
        columns={columns}
        onRowClick={onSelectProduct}
        selected={selectedProductId}
        handleCheck={handleCheck}
        checkedRows={checkedProducts}
        handleCheckAll={handleCheckAll}
        pagination
        noCheckAll
      />
    </div>
  );
};

export default ProductsTable;
