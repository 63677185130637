import React, { useMemo } from 'react';
import moment from 'moment/moment';

import { LineChart } from '../../../charts';

import {
  prepareCasesDataDaily,
  prepareCasesDataMonthly,
  prepareCasesDataQuarterly,
} from '../../../../utils/analytics';

import { presets } from '../../../../constants/analytics';

const SetsShippedLineChart = ({ sets = [], tenantColor, minDate, maxDate, preset, minDateCompare, maxDateCompare, presetCompare }) => {
  const getChartData = (filteredSets, min, max) => {
    if (min.month() === max.month() && min.year() === max.year()) {
      const daysInMonth = preset === presets.LAST_MONTH ? moment().subtract(1, 'month').daysInMonth() : moment().daysInMonth();
      const numberOfDays = filteredSets[0]?.date ? moment(filteredSets[0]?.date).daysInMonth() : daysInMonth;
      return prepareCasesDataDaily(filteredSets, numberOfDays);
    }

    if (min.quarter() === max.quarter() && min.year() === max.year()) {
      const quarter = preset === presets.LAST_QUARTER ? moment().subtract(1, 'quarter').quarter() : moment().quarter();
      const initialQuarter = filteredSets[0]?.date ? quarter : moment(filteredSets[0]?.date).quarter();
      return prepareCasesDataQuarterly(filteredSets, initialQuarter);
    }

    return prepareCasesDataMonthly(filteredSets);
  };

  const chartData = useMemo(() => {
    const data = getChartData(sets.filter((item) => minDate.isBefore(item.date) && maxDate.isAfter(item.date)), minDate, maxDate);

    if (presetCompare) {
      const comparedSets = sets.filter((item) => minDateCompare.isBefore(item.date) && maxDateCompare.isAfter(item.date));
      const compareData = getChartData(comparedSets, minDateCompare, maxDateCompare);

      if (preset === presets.THIS_QUARTER && presetCompare === presets.PREVIOUS_PERIOD) {
        const previousQuarter = compareData?.map((item) => ({
          data: item.data?.map((i, index) => ({ x: data[0]?.data[index]?.x, y: i.y })),
          id: `${item.id}_compare`
        }));
        return [...previousQuarter, ...data];
      }

      return [...compareData?.map((item) => ({ ...item, id: `${item.id}_compare` })), ...data];
    }

    return data;
  }, [sets, minDate, maxDate, minDateCompare, maxDateCompare, presetCompare]);

  return (
    <div className="management-reports-chart__container m-r-md" style={{ width: '50%' }}>
      <div className="font-size-bg font-bold">Total Sets</div>
      <div className="secondary m-t-sm m-b-lg">Total sets shipped in period</div>
      <LineChart
        height='300px'
        data={chartData}
        colors={chartData.length > 1 ? ['#cccccc', tenantColor] : tenantColor}
        margin={{ top: 20, right: 15, bottom: 30, left: 30 }}
        entity='set'
      />
    </div>
  );
};

export default SetsShippedLineChart;
