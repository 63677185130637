import React from 'react';

import WarningIcon from '../../assets/icons/WarningIcon';

const CaseReasonForCancellation = ({ text = '' }) => {
  return (
    <div className='case-cancelled__container'>
      <WarningIcon color="#F53434" />
      <div className='m-l-md'>
        <span className='font-bold' style={{ color: '#F53434' }}>Reason for Cancellation</span>
        <span className='m-l-md'>{text}</span>
      </div>
    </div>
  );
};

export default CaseReasonForCancellation;
