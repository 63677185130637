import React from 'react';

import KitIcon from '../../assets/icons/KitIcon';

const KitFile = (props) => {
  const {
    id,
    name,
    kitId,
    onClick,
  } = props;

  return (
    <div className='drive-folder__container' onClick={() => onClick(id)}>
      <div className='drive-folder-icon'>
        <KitIcon fontSize='large' />
      </div>
      <div className='drive-folder-name__container'>
        <div className='font-size-bg font-bold'>
          {name}
        </div>
        <div className='d-flex secondary font-size-sm'>
          <span className='m-l-sm'>{kitId}</span>
        </div>
      </div>
    </div>
  );
};

export default KitFile;
