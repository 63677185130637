import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function CaseReportingIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none"
          d="M1.15687 10.992C1.26461 11.9519 2.03036 12.7172 2.98985 12.8284C4.28448 12.9785 5.62576 13.1356 6.99999 13.1356C8.37422 13.1356 9.7155 12.9785 11.0101 12.8284C11.9696 12.7172 12.7354 11.9519 12.8431 10.992C12.9878 9.70316 13.1356 8.36795 13.1356 6.99999C13.1356 5.63203 12.9878 4.29682 12.8431 3.00797C12.7354 2.04808 11.9696 1.28282 11.0101 1.17159C9.7155 1.02151 8.37422 0.86438 6.99999 0.86438C5.62576 0.86438 4.28448 1.02151 2.98985 1.17159C2.03036 1.28282 1.26461 2.04808 1.15687 3.00797C1.01219 4.29682 0.86438 5.63203 0.86438 6.99999C0.86438 8.36795 1.01219 9.70316 1.15687 10.992Z" />
    <path stroke={color || defaultColor} fill="none"
          d="M3.45609 9.40624C3.48953 9.88735 3.87806 10.2766 4.36013 10.2902C4.84535 10.3039 5.33209 10.2856 5.81324 10.2352C5.81324 10.2352 5.81324 9.40895 5.81324 8.96859C5.81324 8.82089 5.80661 8.67489 5.7966 8.53093C5.76317 8.04983 5.3747 7.65928 4.89262 7.6462C4.71472 7.64137 4.53797 7.64137 4.36008 7.6462C3.87799 7.65928 3.48953 8.04983 3.45609 8.53093C3.44609 8.67489 3.43945 8.82089 3.43945 8.96859C3.43945 9.11629 3.44609 9.26229 3.45609 9.40624Z" />
    <path stroke={color || defaultColor} fill="none"
          d="M5.81219 10.2001C6.56872 10.3261 7.42975 10.3261 8.18627 10.2001C8.18627 10.2001 8.18627 8.88544 8.18627 8.18478C8.18627 7.77179 8.16579 7.36717 8.14252 6.97386C8.11475 6.50434 7.76507 6.10458 7.29527 6.08206C7.09748 6.07258 6.90128 6.07258 6.70349 6.08206C6.23369 6.10458 5.88401 6.50434 5.85624 6.97386C5.83297 7.36717 5.81249 7.77179 5.81249 8.18478C5.81249 8.88544 5.81219 10.2001 5.81219 10.2001Z" />
    <path stroke={color || defaultColor} fill="none"
          d="M8.18697 10.1548C8.69591 10.2802 9.2109 10.3213 9.72379 10.2779C10.1699 10.2402 10.4805 9.84578 10.4988 9.39843C10.5283 8.68157 10.5603 7.93685 10.5603 7.17276C10.5603 6.40867 10.5283 5.66394 10.4988 4.94709C10.4805 4.49973 10.1703 4.10308 9.72411 4.06566C9.48944 4.04598 9.25739 4.04598 9.02272 4.06566C8.57656 4.10308 8.26636 4.49973 8.24799 4.94709C8.21855 5.66394 8.18652 6.40867 8.18652 7.17276C8.18652 8.2095 8.18697 10.1548 8.18697 10.1548Z" />
  </SvgIcon>
}
