import React, { useMemo } from 'react';
import moment from 'moment';
import { orderBy } from 'lodash';
import { useHistory } from 'react-router-dom';

import CaseIcon from '../../assets/icons/CaseIcon';

import CasesTableContainer from '../cases/CasesTableContainer';
import AddButton from '../shared/AddButton';

import { caseStatusOptions } from '../../constants/enums';
import routes from '../../constants/routes';

import './home.scss';

const DashboardCasesTable = (props) => {
  const { cases, surgeons, hospitals, procedures } = props;
  const history = useHistory();

  const list = useMemo(() => {
    const filteredCases = cases.filter((item) => (
      (item.status === caseStatusOptions.request || item.status === caseStatusOptions.booked) &&
      moment(item.date).isAfter(moment()) && !!item.active
    ));
    const orderedCases = orderBy(filteredCases, 'date');

    return orderedCases.slice(0, 4);
  }, [cases]);

  return (
    <div className='cases-table__container'>
      <div className='d-flex m-b-lg space-between'>
        <div className='d-flex'>
          <CaseIcon />
          <div className='font-size-bg font-bold m-l-md'>
            Upcoming Cases
          </div>
        </div>

        <AddButton onClick={() => history.push(routes.CASES)} />
      </div>
      <CasesTableContainer
        cases={list}
        surgeons={surgeons}
        hospitals={hospitals}
        procedures={procedures}
        withoutCheck
        rowColor='#ffffff'
        rowBorderColor='#fafafa'
        onSelectCase={(item) => history.push(`${routes.CASES}/${item.id}`)}
        checkboxes={false}
      />
    </div>
  );
};

export default DashboardCasesTable;
