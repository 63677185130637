import React from 'react';

const CaseUsageNotesRow = ({ item }) => {
  const { title, note } = item;
  const color = item.color || '#F58634';

  return (
    <div className='d-flex m-b-md align-start'>
      <div className='flex-1 p-md' style={{ border: `1px solid ${color}`, color, borderRadius: '6px' }}>
        <span className='font-bold m-r-md'>{title}</span>
        <span style={{ fontWeight: 400 }}>{note}</span>
      </div>
    </div>
  );
};

export default CaseUsageNotesRow;
