import React from 'react';

const SectionNotesRow = (props) => {
  const { item } = props;

  return (
    <tr>
      <td colSpan={6} style={{ color: item.color || '#F58634' }}>
        <span className='font-bold m-r-md'>{item.title}</span>
        <span style={{ fontWeight: 400 }}>{item.note}</span>
      </td>
      <td />
    </tr>
  );
};

export default SectionNotesRow;
