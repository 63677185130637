import { createTheme } from '@material-ui/core';

import palette from './palette';

const theme = (colorMain) => {
  const themePalette = palette(colorMain);
  const theme = {
    palette: themePalette,
    typography: {
      fontFamily: `'Inter', 'Montserrat', sans-serif`,
      fontSize: 14,
      button: {
        fontWeight: 600,
      },
    },
    shape: {
      borderRadius: 16
    },
    overrides: {
    },
  };

  return createTheme(theme);
};

export default theme;
