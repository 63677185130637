import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Modal from '../../shared/modal';
import Input from '../../shared/Input';
import DatePicker from '../../shared/date-picker/DatePicker';

const CaseShippingInfoModal = ({ open, onClose, onSubmit, loading, initialValues }) => {
  const [date, setDate] = useState(moment());
  const [notes, setNotes] = useState('');

  useEffect(() => {
    if (initialValues && open) {
      setDate(initialValues?.collectionDate);
      setNotes(initialValues?.collectionNote || '');
    }
    if (!open) {
      setNotes('');
    }
  }, [open, initialValues]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Confirm Collection'
      fullWidth
      closeButton='Cancel'
      submitButton='Confirm'
      onSubmit={() => onSubmit(date, notes)}
      disabled={loading || !date}
      loading={loading}
    >
      <div className='close-order-modal__container'>
        <div className='p-b-md secondary font-bold'>
          Select a collection date & add any supporting notes
        </div>

        <div className='m-t-md'>
          <div className='field__title'>Collection Date</div>
          <DatePicker
            date={date}
            onDateChange={setDate}
          />
        </div>

        <div className='m-t-lg'>
          <div className='field__title'>Collection Note</div>
          <Input
            name='collectionNote'
            value={notes}
            type='textarea'
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CaseShippingInfoModal;
