import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import CompanyIcon from '../../assets/icons/CompanyIcon';

import Company from './Company';
import ConnectionModal from './ConnectionModal';
import AddButton from '../shared/AddButton';

import { getCompanySurgeons } from '../../actions/surgeonsActions';

import userRoles from '../../constants/userRoles';

import '../../styles/shared/form.scss';

const CompanyConnections = (props) => {
  const { currentTenant, tenants, connectedTenants = [], userRole, onAddConnection, assignedSurgeons = [] } = props;
  const dispatch = useDispatch();

  const [open, toggleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [connectedSurgeons, setConnectedSurgeons] = useState({});

  const connected = useMemo(() => (
    connectedTenants?.map((tenantId) => {
      const tenant = tenants?.find((item) => item.id === tenantId);
      return tenant || {};
    })
  ), [tenants, connectedTenants]);

  useEffect(() => {
    if (connected?.length) {
      fetchConnectedSurgeons(connected?.map((t) => t.id)).catch(console.error);
    }
  }, [connected]);

  const fetchConnectedSurgeons = async (tenantIds) => {
    const result = {};

    for (const tenantId of tenantIds) {
      if (tenantId) {
        const res = await dispatch(getCompanySurgeons(tenantId));
        result[tenantId] = res?.map((surgeon) => surgeon.id) || [];
      }
    }

    setConnectedSurgeons(result);
  }

  const availableTenants = useMemo(() => (
    tenants?.filter((tenant) => ![currentTenant?.id, ...connectedTenants]?.includes(tenant.id) && !!tenant.externalUsersAllowed)
  ), [tenants, connectedTenants, currentTenant]);

  const onSubmit = async (tenantId) => {
    setLoading(true);
    try {
      await onAddConnection(tenantId);
      toggleModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='form__container height-100 m-l-lg' style={{ width: '40%' }}>
      <div className='d-flex space-between align-start'>
        <div className='d-flex m-b-lg'>
          <CompanyIcon />
          <div className='font-size-bg font-bold m-l-md'>Connections</div>
        </div>

        {userRole === userRoles.SALES_REP.name && (
          <AddButton onClick={() => toggleModal(true)} />
        )}
      </div>

      <div>
        <div className='secondary m-b-md'>My Company</div>
        <Company
          title={currentTenant.businessName}
          logo={currentTenant?.icon?.downloadUrl || null}
          color={currentTenant?.colorPrimary}
        />
      </div>

      {userRole === userRoles.SALES_REP.name && !!connected?.length && (
        <div style={{ marginTop: 32 }}>
          <div className='secondary m-b-md'>Connections</div>
          {connected?.map((tenant) => (
            <div key={tenant.id} className='m-b-md'>
              <Company
                title={tenant.businessName}
                logo={tenant?.icon?.downloadUrl || null}
                color={tenant?.colorPrimary}
                inReview={connectedSurgeons[tenant.id] && !assignedSurgeons?.some((surgeonId) => connectedSurgeons[tenant.id]?.includes(surgeonId))}
              />
            </div>
          ))}
        </div>
      )}

      <ConnectionModal
        open={open}
        onClose={() => toggleModal(false)}
        tenants={availableTenants}
        onSubmit={onSubmit}
        loading={loading}
      />
    </div>
  )
};

export default CompanyConnections;
