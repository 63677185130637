import { sortBy } from 'lodash';
import { defaultStaticColor } from '../styles/theme/palette';

const mergeArrayObjectsByField = (field, arrays) => {
  let result = arrays[0];
  for (let i = 1; i < arrays.length; i++) {
    result = result.map(item1 => ({ ...item1, ...arrays[i].find(item2 => item2[field] === item1[field]) }));
  }
  return result;
};

const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

const buildParentArray = (arr, nodeId) => {
  const result = [];
  const active = arr.find((i) => i.id === nodeId);

  const findParent = (item) => {
    result.unshift(item);
    const parent = arr.find((i) => i.id === item.parent);
    if (parent) {
      findParent(parent);
    }
  };

  if (active) {
    findParent(active);
  }

  return result;
};

const flatArrayToTree = function(array, parent) {
  let tree = [];
  parent = parent || { id: 'root' };

  const children = sortBy(array.filter((item) => item.parent === parent.id), ['name', 'asc']);

  if (!!children.length) {
    if (parent.id === 'root') {
      tree = children;
    } else {
      parent.children = children;
    }
    children.forEach((item) => flatArrayToTree(array, item));
  }

  return tree;
}

const hexToRgb = (hex) => {
  if (hex.charAt(0) !== 'r') {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? 'rgb(' + parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16) + ')'
      : null;
  }

  return hex;
};

const lightenDarkenColor = (color, amt) => {
  const code = hexToRgb(color || defaultStaticColor)?.slice(4, -1);
  const numbers = code?.split(',');
  let r = (parseInt(numbers[0]) + parseInt(amt) > 255) ? 255 : parseInt(numbers[0]) + parseInt(amt);
  r = (parseInt(numbers[0]) + parseInt(amt) < 0) ? 0 : parseInt(numbers[0]) + parseInt(amt);
  let g = (parseInt(numbers[1]) + parseInt(amt) > 255) ? 255 : parseInt(numbers[1]) + parseInt(amt);
  g = (parseInt(numbers[1]) + parseInt(amt) < 0) ? 0 : parseInt(numbers[1]) + parseInt(amt);
  let b = (parseInt(numbers[2]) + parseInt(amt) > 255) ? 255 : parseInt(numbers[2]) + parseInt(amt);
  b = (parseInt(numbers[2]) + parseInt(amt) < 0) ? 0 : parseInt(numbers[2]) + parseInt(amt);

  return 'rgb(' + r + ',' + g + ',' + b + ')';
}

const chunkArray = (list, chunk = 10) => {
  const result = [];

  for (let i = 0; i < list.length; i += chunk) {
    result?.push(list.slice(i, i + chunk));
  }

  return result;
};

const formatPrice = (value, digits = 2) => {
  if (!value || !Number(value)) {
    return '';
  }
  return Number(value).toLocaleString('en', { minimumFractionDigits: digits, maximumFractionDigits: digits });
}

const formatChartPrice = (value) => {
  if (!value || !Number(value)) {
    return '';
  }

  if (value > 99999) {
    const kValue = value / 1000;
    return `${Number(kValue).toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}K`
  }

  return Number(value).toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
}

const downloadPdf = (file, fileName) => {
  fetch(file.url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf',
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        fileName
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
};

const downloadFile = (blob, fileName) => {
  const url = window.URL.createObjectURL(
    new Blob([blob]),
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    fileName
  );
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}

const componentToHex = (c) => {
  const hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

const rgbToHex = (rgb) => {
  const regex = /(\d{1,3}),(\d{1,3}),(\d{1,3})/;
  const matches = rgb.match(regex);
  const [r, g, b] = matches.slice(1).map(Number);
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export {
  mergeArrayObjectsByField,
  getKeyByValue,
  buildParentArray,
  flatArrayToTree,
  lightenDarkenColor,
  chunkArray,
  formatPrice,
  formatChartPrice,
  downloadPdf,
  downloadFile,
  rgbToHex,
}
