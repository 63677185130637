import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { orderBy } from 'lodash';

import Table from '../../shared/table/Table';

import { formStatuses, setAllocationStatuses } from '../../../constants/enums';
import routes from '../../../constants/routes';

const CaseFormsTable = (props) => {
  const {
    rows,
    onRowClick = () => {
    },
    users = [],
    forms = [],
    withCaseId = false,
    tenantColor,
    checkboxes = false,
    handleCheck,
    checkedForms,
    handleCheckAll,
  } = props;

  const history = useHistory();

  const columns = useMemo(() => {
    const result = [
      { title: 'created', field: 'createdAt', type: 'date', sort: true },
      { title: 'submitted', field: 'submittedAt', type: 'date', sort: true },
      { title: 'name', field: 'name', type: 'default', sort: true },
      { title: 'subtitle', field: 'subtitle', type: 'default', sort: true },
      { title: 'user', field: 'user', type: 'default', sort: true },
      {
        title: 'status',
        field: 'status',
        type: 'custom',
        formatter: (value) => {
          if (value === formStatuses.SUBMITTED) {
            return <div style={{ color: setAllocationStatuses.SHIPPED.color }}>Submitted</div>;
          } else if (value === formStatuses.CLOSED) {
            return <div style={{ color: setAllocationStatuses.RETURNED.color }}>Closed</div>;
          } else {
            return <div style={{ color: setAllocationStatuses.ASSIGNED.color }}>Pending</div>
          }
        },
      },
    ];

    if (withCaseId) {
      result?.push({
        title: 'case id',
        field: 'caseId',
        type: 'custom',
        formatter: (value) => {
          const arr = value?.split('-');
          const formattedId = arr?.length > 1 ? `${arr[0]}-${arr[1]}` : value;
          return (
            <div
              style={{ color: tenantColor }}
              className="pointer"
              onClick={(e) => {
                e.stopPropagation();
                history.push(`${routes.CASES}/${value}`);
              }}
            >
              {formattedId}
            </div>
          )
        }
      })
    }

    return result;
  }, [rows, withCaseId]);

  const getUserName = (userId) => {
    const user = users?.find((user) => user.uid === userId);
    return user ? `${user.firstName} ${user.lastName}` : '';
  };

  const getFormName = (formId) => {
    const form = forms?.find((f) => f.id === formId);
    return form ? form.name : '';
  };

  const getFormSubtitle = (formId) => {
    const form = forms?.find((f) => f.id === formId);
    return form ? form.subtitle : '';
  };

  return (
    <div style={{ width: '100%' }}>
      <Table
        rows={orderBy(rows, 'updatedAt', 'desc')?.map((row) => ({
          ...row,
          name: getFormName(row?.formId),
          subtitle: getFormSubtitle(row?.formId),
          user: getUserName(row?.submittedBy || row?.createdBy),
        }))}
        columns={columns}
        onRowClick={onRowClick}
        rowColor='#ffffff'
        rowBorderColor='#fafafa'
        handleCheck={handleCheck}
        checkedRows={checkedForms}
        handleCheckAll={handleCheckAll}
        checkboxes={checkboxes}
      />
    </div>
  );
};

export default CaseFormsTable;
