import React, { useCallback } from 'react';

import Table from '../shared/table/Table';

import { getCommaSeparatedNames } from '../../utils/table';

import { kitTypeOptions } from '../../constants/enums';

const columns = [
  { title: 'name', field: 'name', type: 'default', sort: true },
  { title: 'kit id', field: 'kitId', type: 'default', sort: true },
  { title: 'type', field: 'typeName', type: 'default', sort: true },
  { title: 'brands', field: 'productNames', type: 'default', sort: true },
  { title: 'manufacturers', field: 'manufacturerNames', type: 'default', sort: true },
  {
    title: '',
    field: 'preview',
    type: 'custom',
    formatter: (value) => {
      if (value) {
        return (
          <div className='d-flex flex-center' style={{ maxHeight: '40px', maxWidth: '60px', borderRadius: 4 }}>
            <img src={value} alt='preview' style={{ maxHeight: '40px', maxWidth: '60px', height: 'fit-content', borderRadius: 4 }} />
          </div>
        );
      }
    }
  },
  { title: 'status', field: 'active', type: 'status' },
];

const KitsTable = (props) => {
  const {
    onSelectKit,
    kits,
    selectedKitId,
    handleCheck,
    checkedKits,
    handleCheckAll,
    products,
    manufacturers = [],
  } = props;

  const getProductNames = useCallback((ids) => getCommaSeparatedNames(products, ids), [kits, products]);

  const getManufacturerNames = useCallback((ids) => getCommaSeparatedNames(manufacturers, ids), [kits, manufacturers]);

  return (
    <div style={{ width: '100%' }}>
      <Table
        rows={kits.map((kit) => ({
          ...kit,
          typeName: kitTypeOptions[kit.type] ? kitTypeOptions[kit.type].label : '',
          productNames: getProductNames(kit.products),
          manufacturerNames: getManufacturerNames(kit.manufacturers),
          preview: kit.logo && kit.logo.downloadUrl ? kit.logo.downloadUrl : null,
        }))}
        columns={columns}
        onRowClick={onSelectKit}
        selected={selectedKitId}
        handleCheck={handleCheck}
        checkedRows={checkedKits}
        handleCheckAll={handleCheckAll}
        pagination
        noCheckAll
      />
    </div>
  );
};

export default KitsTable;
