import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';
import { routerReducer as routing } from 'react-router-redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import App from './App';

import { AlertProvider } from './providers';

import reducers from './reducers';
import Firebase, { FirebaseContext } from './firebase';

import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

import './index.scss';

const combinedReducers = combineReducers({ ...reducers, routing });
const store = createStore(combinedReducers, composeWithDevTools(applyMiddleware(thunk)));

ReactDOM.render(
  <Provider store={store}>
    <FirebaseContext.Provider value={Firebase}>
      <AlertProvider>
        <BrowserRouter>
          <Route component={App} />
        </BrowserRouter>
      </AlertProvider>
    </FirebaseContext.Provider>
  </Provider>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
