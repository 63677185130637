import moment from 'moment';

import {
  GET_DRIVE_FOLDERS,
  SET_DRIVE_FOLDERS,
} from './actionTypes';

import firebase, { collections } from '../firebase';

import { getFilePath } from '../utils/drive';
import { fromMomentToTimestamp, fromMomentToTimestampDay, nowTimestampUTC } from '../utils/date';
import { get } from '../utils/api';

import { urls, userRoles, driveConstants } from '../constants';

const { ADMIN } = userRoles;

export const setDriveFolders = folders => ({ type: SET_DRIVE_FOLDERS, folders });

export const getDriveFolders = () => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.DRIVE_COLLECTION(tenantId);

  dispatch({ type: GET_DRIVE_FOLDERS });

  const snapshot = await firebase.db.collection(path).get();
  const folders = snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));

  return dispatch(setDriveFolders(folders));
};

export const createFolder = (folderData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.DRIVE_COLLECTION(tenantId);

  if (tenantId) {
    const folderDoc = {
      ...folderData,
      tenantId,
      active: true,
      createdAt: nowTimestampUTC(),
      updatedAt: nowTimestampUTC(),
      allowedGroups: 'ALL',
    };
    await firebase.db.collection(path).add(folderDoc);
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateFolder = (folderId, folderData) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.DRIVE_COLLECTION(tenantId);

  if (tenantId) {
    const folderDoc = {
      ...folderData,
      updatedAt: nowTimestampUTC(),
    }
    await firebase.db.collection(path).doc(folderId).set(folderDoc, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteFolder = (folderId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.DRIVE_COLLECTION(tenantId);

  if (tenantId) {
    await firebase.db.collection(path).doc(folderId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

export const getFiles = (folderId, onlyActive) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const userId = state.user.currentUser.uid;
  const groups = state.groups.list;
  const userGroups = groups.filter((group) => group.members.includes(userId));
  const role = state.user.currentUser.role;
  const path = collections.DRIVE_FILES_COLLECTION(tenantId, folderId);

  let query = await firebase.db.collection(path);

  if (onlyActive && role !== ADMIN.name) {
    query = await firebase.db.collection(path)
      .where('active', '==', true)
  }

  const snapshot = await query.get();
  const files = snapshot.docs.map(doc => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      publishAt: data.publishAt ? moment(data.publishAt.toDate()) : moment(),
      expireAt: data.expireAt ? moment(data.expireAt.toDate()) : null,
      createdAt: data.createdAt ? data.createdAt.toDate() : null,
      updatedAt: data.updatedAt ? data.updatedAt.toDate() : null,
    };
  });

  if (role === ADMIN.name) {
    return files;
  }

  return files.filter((file) => {
    let allowed = false;

    if (file.allowedRoles && !!file.allowedRoles.length && file.allowedRoles.includes(role)) {
      allowed = true;
    } else {
      if (file.allowedGroups) {
        if (file.allowedGroups === 'ALL') {
          allowed = groups.some((group) => group.members.includes(userId));
        } else {
          allowed = userGroups.some((group) => file.allowedGroups.includes(group.id));
        }
      }
    }

    return allowed;
  });
};

export const searchFiles = (query) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const idToken = await firebase.auth.currentUser.getIdToken();
  const url = urls.drive.search(query, tenantId);

  const { files = [] } = await get(url, idToken);

  return files.map((file) => ({
    ...file,
    publishAt: file.publishAt ? moment(file.publishAt) : moment(),
    expireAt: file.expireAt ? moment(file.expireAt) : null,
    createdAt: file.createdAt ? moment(file.createdAt) : null,
    updatedAt: file.updatedAt ? moment(file.updatedAt) : null,
  }));
};

export const addFile = (folderId, fileData, files) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const folders = state.drive.folders || [];
  const path = collections.DRIVE_FILES_COLLECTION(tenantId, folderId);
  const { file, previewImage } = files;

  if (tenantId) {
    const fileDoc = {
      ...fileData,
      tenantId,
      relatedProducts: [],
      relatedKits: [],
      createdAt: nowTimestampUTC(),
      updatedAt: nowTimestampUTC(),
      publishAt: fromMomentToTimestampDay(fileData.publishAt),
      expireAt: fileData.expireAt ? fromMomentToTimestampDay(fileData.expireAt) : null,
      allowSharing: true,
    };
    const docRef = await firebase.db.collection(path).add(fileDoc);

    if (file) {
      const fileObj = await uploadFile(docRef.id, folders, fileData.folder, tenantId, file);
      await firebase.db.collection(path).doc(docRef.id).set({ file: fileObj }, { merge: true });
    }
    if (previewImage) {
      const previewImageObj = await uploadFile(docRef.id, folders, fileData.folder, tenantId, previewImage);
      await firebase.db.collection(path).doc(docRef.id).set({ previewImage: previewImageObj }, { merge: true });
    }
  } else {
    throw new Error('Invalid tenant');
  }
};

export const updateFile = (folderId, fileId, fileData, files) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const folders = state.drive.folders || [];
  const path = collections.DRIVE_FILES_COLLECTION(tenantId, folderId);
  const { file, previewImage } = files;

  if (tenantId) {
    const fileDoc = {
      ...fileData,
      updatedAt: nowTimestampUTC(),
      publishAt: fromMomentToTimestampDay(fileData.publishAt),
      expireAt: fileData.expireAt ? fromMomentToTimestampDay(fileData.expireAt) : null,
    };

    if (file) {
      const fileObj = await uploadFile(fileId, folders, fileData.folder, tenantId, file);
      fileDoc.file = fileObj;
    }
    if (previewImage) {
      const previewImageObj = await uploadFile(fileId, folders, fileData.folder, tenantId, previewImage);
      fileDoc.previewImage = previewImageObj;
    }

    await firebase.db.collection(path).doc(fileId).set(fileDoc, { merge: true });
  } else {
    throw new Error('Invalid tenant');
  }
};

const uploadFile = async (fileId, folders, parent, tenantId, file) => {
  const storageRef = firebase.storage.ref();
  const filePath = getFilePath(fileId, folders, parent, tenantId, file.name);
  const ref = storageRef.child(filePath);
  const snap = await ref.put(file);
  const downloadUrl = await snap.ref.getDownloadURL();
  const fileObj = {
    fileName: file.name,
    path: filePath,
    downloadUrl
  };

  return fileObj;
};

export const moveFile = (fileObj, oldPath, newPath, folderId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;

  if (tenantId) {
    let downloadUrl = '';
    if (fileObj.type !== driveConstants.driveItemTypes.link) {
      // Download file
      const storageRef = firebase.storage.ref(oldPath);
      const blob = await storageRef.getDownloadURL()
        .then(url => {
          return fetch(url);
        })
        .then(response => response.blob())
        .then(blob => {
          console.log('File downloaded as blob successfully');
          return blob;
        })
        .catch(error => {
          console.error('Error downloading the file:', error);
          throw error;
        });

      // Upload file
      const newFileRef = firebase.storage.ref(newPath);
      const snapshot = await newFileRef.put(blob)
        .then(snapshot => {
          console.log('File uploaded to the new path successfully!');
          return snapshot;
        })
        .catch(error => {
          console.error('Error uploading the file:', error);
          throw error;
        });
      downloadUrl = await snapshot?.ref.getDownloadURL() || '';
    }

    // Add new file to Firestore
    const path = collections.DRIVE_FILES_COLLECTION(tenantId, folderId);
    const fileDoc = {
      ...fileObj,
      createdAt: fromMomentToTimestamp(fileObj.createdAt),
      updatedAt: nowTimestampUTC(),
      publishAt: fileObj.publishAt ? fromMomentToTimestamp(fileObj.publishAt) : null,
      expireAt: fileObj.expireAt ? fromMomentToTimestamp(fileObj.expireAt) : null,
      file: { ...fileObj.file, path: newPath, downloadUrl },
      folder: folderId,
    };
    await firebase.db.collection(path).doc(fileObj.id).set(fileDoc, { merge: true });

    // Delete old file
    await dispatch(deleteFile(fileObj.folder, fileObj.id));
  } else {
    throw new Error('Invalid tenant');
  }
};

export const deleteFile = (folderId, fileId) => async (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const path = collections.DRIVE_FILES_COLLECTION(tenantId, folderId);

  if (tenantId) {
    await firebase.db.collection(path).doc(fileId).delete();
  } else {
    throw new Error('Invalid tenant');
  }
};

// Subscriptions
export const subscribeToDrive = (onlyActive) => (dispatch, getState) => {
  const state = getState();
  const tenantId = state.tenant.currentTenant ? state.tenant.currentTenant.id : null;
  const userId = state.user.currentUser.uid;
  const groups = state.groups.list;
  const userGroups = state.groups.list.filter((group) => group.members.includes(userId));
  const role = state.user.currentUser.role;
  const path = collections.DRIVE_COLLECTION(tenantId);

  let query = firebase.db.collection(path);

  if (onlyActive && role !== ADMIN.name) {
    query = firebase.db.collection(path)
      .where('active', '==', true)
  }

  return query
    .onSnapshot({
      error: (e) => console.error(e),
      next: (querySnapshot) => {
        const folders = [];
        querySnapshot.forEach((documentSnapshot) => {
          const id = documentSnapshot.id;
          const data = documentSnapshot.data();
          const folder = {
            ...data,
            id,
            createdAt: data.createdAt ? data.createdAt.toDate() : null,
          };
          folders.push(folder);
        });

        if (role === ADMIN.name) {
          return dispatch(setDriveFolders(folders));
        }

        const filteredFolders = folders?.filter((item) => {
          let allowed = false;

          if (item.allowedRoles && !!item.allowedRoles.length && item.allowedRoles.includes(role)) {
            allowed = true;
          } else {
            if (item.allowedGroups) {
              if (item.allowedGroups === 'ALL') {
                allowed = groups.some((group) => group.members.includes(userId));
              } else {
                allowed = userGroups.some((group) => item.allowedGroups.includes(group.id));
              }
            }
          }

          return allowed;
        });

        return dispatch(setDriveFolders(filteredFolders));
      },
    });
};
