import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

import { defaultStaticColor } from '../../styles/theme/palette';

const Progress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: (props) => props.bgcolor ? props.bgcolor : defaultStaticColor,
  },
}))(LinearProgress);

export default Progress;
