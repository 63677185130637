/* eslint-disable no-unused-vars */

export const BarTooltip = (props) => {
  const { data, indexValue, value, color, id, entity = 'case' } = props;

  return (
    <div className="custom-tooltip">
      <div className="tooltip-label">{data.id || id}</div>
      <div className="tooltip-content">
        <div>{`${value} ${entity}(s)`}</div>
      </div>
    </div>
  );
};
