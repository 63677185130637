import React from 'react';

import DatePicker from '../shared/date-picker/DatePicker';
import Select from '../shared/Select';

import './reporting.scss';

const CaseDateRange = (props) => {
  const {
    minDate, onMinDateChange,
    maxDate, onMaxDateChange,
    preset, onPresetChange,
    presetCompare, onPresetChangeCompare,
    options, compareOptions, disabled, compareDisabled,
  } = props;

  return (
    <div className='case-date-range__container'>
      <div className='case-date-range'>
        <div className='d-flex'>
          <div className='secondary' style={{ width: 40 }}>From</div>
          <DatePicker
            date={minDate}
            onDateChange={onMinDateChange}
            maxDate={maxDate}
            withoutStyles
            disabled={disabled}
          />
        </div>

        <div className='d-flex'>
          <div className='secondary' style={{ width: 40 }}>To</div>
          <DatePicker
            date={maxDate}
            onDateChange={onMaxDateChange}
            minDate={minDate}
            withoutStyles
            disabled={disabled}
          />
        </div>
      </div>

      <div className='m-t-lg'>
        <div className='secondary m-b-sm'>Preset Range</div>
        <Select
          value={preset}
          onChange={onPresetChange}
          options={options}
          disabled={disabled}
        />
      </div>

      <div className='m-t-lg'>
        <div className='secondary m-b-sm'>Compare To</div>
        <Select
          value={presetCompare}
          onChange={onPresetChangeCompare}
          options={compareOptions}
          disabled={compareDisabled}
        />
      </div>
    </div>
  );
};

export default CaseDateRange;
