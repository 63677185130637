import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { orderBy } from 'lodash';

import { CaseDateRange, ReportingCountsTable } from '../../../components/reporting';
import { LineChart } from '../../../components/charts';
import LoadScreen from '../../../components/load-screen';

import { useLoading } from '../../../hooks';

import { getCounts } from '../../../actions/countsActions';
import { getHospitals } from '../../../actions/hospitalsActions';

import { prepareCountsDataMonthly, prepareCountsDataDaily, prepareCountsDataQuarterly } from '../../../utils/analytics';

import { presets } from '../../../constants/analytics';
import { countStatuses } from '../../../constants/enums';

import './reporting-page.scss';

const presetOptions = [
  { label: 'This month', value: presets.THIS_MONTH },
  { label: 'This quarter', value: presets.THIS_QUARTER },
  { label: 'This year', value: presets.THIS_YEAR },
  { label: 'Last month', value: presets.LAST_MONTH },
  { label: 'Last quarter', value: presets.LAST_QUARTER },
  { label: 'Last year', value: presets.LAST_YEAR },
];

const filteredFields = ['hospitalName', 'location', 'userName'];
const getInitialFilters = () => {
  const initialFilter = {};

  filteredFields?.forEach((field) => {
    initialFilter[field] = [];
  });

  return initialFilter;
};

const CountReportingPage = () => {
  const dispatch = useDispatch();
  const tenantPrimaryColor = useSelector((state) => state?.tenant.currentTenant.colorPrimary);
  const counts = useSelector((state) => state?.counts?.list?.filter((count) => count.status === countStatuses.COMPLETED.value));
  const hospitals = useSelector((state) => state?.hospitals.list);
  const users = useSelector((state) => state?.users.list);

  const { loading, startLoading, stopLoading } = useLoading();

  const [preset, setPreset] = useState(presets.THIS_MONTH);
  const [minDate, setMinDate] = useState(moment().startOf('month'));
  const [maxDate, setMaxDate] = useState(moment().endOf('month'));

  const [presetCompare, setPresetCompare] = useState('');
  const [minDateCompare, setMinDateCompare] = useState(moment().subtract(1, 'month').startOf('month'));
  const [maxDateCompare, setMaxDateCompare] = useState(moment().subtract(1, 'month').endOf('month'));

  const [filter, setFilter] = useState(getInitialFilters());

  useEffect(() => {
    onLoad().catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    setPresetCompare('');
    onPresetChange(preset, { onMinDateChange: setMinDate, onMaxDateChange: setMaxDate });
  }, [preset]);

  useEffect(() => {
    if (!presetCompare) {
      return;
    }

    if (preset === presets.THIS_MONTH) {
      onPresetChange(presets.LAST_MONTH, { onMinDateChange: setMinDateCompare, onMaxDateChange: setMaxDateCompare }, presetCompare === presets.YEAR_AGO);
    }
    if (preset === presets.THIS_QUARTER) {
      onPresetChange(presets.LAST_QUARTER, { onMinDateChange: setMinDateCompare, onMaxDateChange: setMaxDateCompare }, presetCompare === presets.YEAR_AGO);
    }
    if (preset === presets.THIS_YEAR) {
      onPresetChange(presets.LAST_YEAR, { onMinDateChange: setMinDateCompare, onMaxDateChange: setMaxDateCompare });
    }
  }, [presetCompare]);

  const onLoad = async () => {
    startLoading()
    try {
      await dispatch(getHospitals());
      await dispatch(getCounts());
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onPresetChange = (value, { onMinDateChange, onMaxDateChange }, yearAgo = false) => {
    switch (value) {
      case presets.THIS_MONTH:
        onMinDateChange(moment().startOf('month'));
        onMaxDateChange(moment().endOf('month'));
        return;
      case presets.THIS_QUARTER:
        onMinDateChange(moment().startOf('quarter'));
        onMaxDateChange(moment().endOf('quarter'));
        return;
      case presets.THIS_YEAR:
        onMinDateChange(moment().startOf('year'));
        onMaxDateChange(moment().endOf('year'));
        return;
      case presets.LAST_MONTH:
        if (yearAgo) {
          onMinDateChange(moment().subtract(1, 'year').subtract(1, 'month').startOf('month'));
          onMaxDateChange(moment().subtract(1, 'year').subtract(1, 'month').endOf('month'));
          return;
        }
        onMinDateChange(moment().subtract(1, 'month').startOf('month'));
        onMaxDateChange(moment().subtract(1, 'month').endOf('month'));
        return;
      case presets.LAST_QUARTER:
        if (yearAgo) {
          onMinDateChange(moment().subtract(1, 'year').subtract(1, 'quarter').startOf('quarter'));
          onMaxDateChange(moment().subtract(1, 'year').subtract(1, 'quarter').endOf('quarter'));
          return;
        }
        onMinDateChange(moment().subtract(1, 'quarter').startOf('quarter'));
        onMaxDateChange(moment().subtract(1, 'quarter').endOf('quarter'));
        return;
      case presets.LAST_YEAR:
        onMinDateChange(moment().subtract(1, 'year').startOf('year'));
        onMaxDateChange(moment().subtract(1, 'year').endOf('year'));
        return;
      default:
        return;
    }
  };

  const getChartData = (filteredCounts, min, max) => {
    if (min.month() === max.month() && min.year() === max.year()) {
      const daysInMonth = preset === presets.LAST_MONTH ? moment().subtract(1, 'month').daysInMonth() : moment().daysInMonth();
      const numberOfDays = filteredCounts[0]?.completedAt ? moment(filteredCounts[0]?.completedAt).daysInMonth() : daysInMonth;
      return prepareCountsDataDaily(filteredCounts, numberOfDays);
    }

    if (min.quarter() === max.quarter() && min.year() === max.year()) {
      const quarter = preset === presets.LAST_QUARTER ? moment().subtract(1, 'quarter').quarter() : moment().quarter();
      const initialQuarter = filteredCounts[0]?.completedAt ? quarter : moment(filteredCounts[0]?.completedAt).quarter();
      return prepareCountsDataQuarterly(filteredCounts, initialQuarter);
    }

    return prepareCountsDataMonthly(filteredCounts);
  };

  const handleFilterChange = (field, values) => {
    setFilter({ ...filter, [field]: values });
  };

  const getFilterOptions = () => {
    const hospitalsOptions = [];
    const locationsOptions = [];
    const usersOptions = [];

    counts?.forEach((item) => {
      const { hospitalId, location, completedBy } = item;

      if (hospitalId && !hospitalsOptions?.map((item) => item.id)?.includes(hospitalId)) {
        const hospitalObj = hospitals?.find((item) => item.id === hospitalId);
        if (hospitalObj) {
          hospitalsOptions?.push(hospitalObj);
        }
      }

      if (location && !locationsOptions?.map((item) => item.name).includes(location)) {
        locationsOptions?.push({ id: location, name: location });
      }

      if (completedBy && !usersOptions?.map((item) => item.uid)?.includes(completedBy)) {
        const userObj = users?.find((user) => user.uid === completedBy);
        if (userObj) {
          usersOptions?.push({ ...userObj, id: userObj.uid });
        }
      }
    });

    return { hospitalName: hospitalsOptions, location: locationsOptions, userName: usersOptions };
  };

  const handleRemoveFilterItem = (key, value) => {
    setFilter({ ...filter, [key]: filter[key].filter((item) => item !== value) });
  };

  // Memoized data

  const filteredCounts = useMemo(() => {
    const sortedCounts = counts ? orderBy(counts, 'completedAt', 'desc') : [];
    return (
      sortedCounts.filter((item) => {
        let persistence = true;

        if (filter?.hospitalName?.length && !filter?.hospitalName?.includes(item.hospitalId)) {
          persistence = false;
        }

        if (filter?.location?.length && !filter?.location?.includes(item.location)) {
          persistence = false;
        }

        if (filter?.userName?.length && !filter?.userName?.includes(item.completedBy)) {
          persistence = false;
        }

        return persistence && minDate.isBefore(item.completedAt) && maxDate.isAfter(item.completedAt) && !!item.active;
      })
    );
  }, [counts, minDate, maxDate, filter]);

  const countsChartData = useMemo(() => {
    const data = getChartData(filteredCounts, minDate, maxDate);

    if (presetCompare) {
      const comparedCounts = counts?.filter((item) => minDateCompare.isBefore(item.completedAt) && maxDateCompare.isAfter(item.completedAt));
      const compareData = getChartData(comparedCounts, minDateCompare, maxDateCompare);

      if (preset === presets.THIS_QUARTER && presetCompare === presets.PREVIOUS_PERIOD) {
        const previousQuarter = compareData?.map((item) => ({
          data: item.data?.map((i, index) => ({ x: data[0]?.data[index]?.x, y: i.y })),
          id: `${item.id}_compare`
        }));
        return [...previousQuarter, ...data];
      }

      return [...compareData?.map((item) => ({ ...item, id: `${item.id}_compare` })), ...data];
    }

    return data;
  }, [filteredCounts, minDate, maxDate, minDateCompare, maxDateCompare, presetCompare]);

  const presetCompareOptions = useMemo(() => {
    const options = [
      { label: 'Don\'t compare', value: '' }
    ];

    if ([presets.THIS_YEAR, presets.THIS_QUARTER, presets.THIS_MONTH].includes(preset)) {
      options?.push({ label: 'Previous period', value: presets.PREVIOUS_PERIOD });
    }

    if ([presets.THIS_QUARTER, presets.THIS_MONTH].includes(preset)) {
      options?.push({ label: 'Year ago', value: presets.YEAR_AGO });
    }

    return options;
  }, [preset]);

  return (
    <div className='page-container reporting-page__container'>
      {loading && <LoadScreen />}
      <div className='page-title'>Counts Report</div>
      <div className='reporting-page__body'>
        <div className='case-reporting-chart__container'>
          <div className='case-reporting-chart'>
            <LineChart
              data={countsChartData}
              colors={countsChartData.length > 1 ? ['#cccccc', tenantPrimaryColor] : tenantPrimaryColor}
              margin={{ top: 20, right: 15, bottom: 30, left: 30 }}
              entity='count'
            />
          </div>
          <CaseDateRange
            minDate={minDate}
            onMinDateChange={(date) => {
              setMinDate(date);
              setPreset(null);
            }}
            maxDate={maxDate}
            onMaxDateChange={(date) => {
              setMaxDate(date);
              setPreset(null);
            }}
            preset={preset}
            onPresetChange={setPreset}
            presetCompare={presetCompare}
            onPresetChangeCompare={setPresetCompare}
            options={presetOptions}
            compareOptions={presetCompareOptions}
          />
        </div>
        <ReportingCountsTable
          counts={filteredCounts}
          hospitals={hospitals}
          users={users}
          filter={filter}
          filterOptions={getFilterOptions()}
          onFilterChange={handleFilterChange}
          onRemoveFilter={handleRemoveFilterItem}
        />
      </div>
    </div>
  );
};

export default CountReportingPage;
