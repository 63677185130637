import React, { useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';

import Button from '../shared/Button';
import Input from '../shared/Input';
import ModalSelectInput from '../shared/modal-select/ModalSelectInput';
import ModalSelect from '../shared/modal-select/ModalSelect';
import Checkbox from '../shared/Checkbox';
import Select from '../shared/Select';

import '../../styles/shared/form.scss';

const KitPreferenceForm = (props) => {
  const {
    mode = 'create',
    initialValues,
    buttonText,
    onSubmit,
    loading,
    onClose,
    procedures = [],
    surgeons = [],
    hospitals = [],
  } = props;

  const { name } = initialValues || {};

  const [isModalOpen, toggleModal] = useState(false);
  const [isHospitalModalOpen, toggleHospitalModal] = useState(false);
  const [selectedSurgeon, setSelectedSurgeon] = useState(initialValues?.surgeon || null);

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Required';
    }

    if (mode === 'update' && !values.surgeon) {
      errors.surgeon = 'Required';
    }
    if (mode === 'update' && !values.hospitals?.length) {
      errors.hospitals = 'Required';
    }
    if (mode === 'update' && !values.procedures?.length && !values?.allProcedures) {
      errors.procedures = 'Required';
    }

    return errors;
  };

  const getProcedureNames = (procedureIds) => {
    if (!procedureIds) {
      return '';
    }
    return procedureIds?.map((id) => {
      const procedure = procedures.find((item) => item.id === id);
      return procedure ? procedure.name : '';
    });
  };

  const getHospitalName = (hospitalIds) => {
    if (!hospitalIds) {
      return '';
    }
    return hospitalIds?.map((id) => {
      const hospital = hospitals.find((item) => item.id === id);
      return hospital ? hospital.name : '';
    });
  };

  const surgeonsList = useMemo(() => {
    if (!surgeons) {
      return [];
    }

    return surgeons.map((surgeon) => ({ label: `${surgeon.title} ${surgeon.firstName} ${surgeon.lastName}`, value: surgeon.id }));
  }, [surgeons]);

  const hospitalsList = useMemo(() => {
    if (!hospitals || !selectedSurgeon) {
      return [];
    }

    return hospitals
      .filter((hospital) => {
        const surgeon = surgeons.find((item) => item.id === selectedSurgeon);
        return surgeon && surgeon.hospitals?.includes(hospital.id);
      });
  }, [hospitals, selectedSurgeon]);

  const proceduresList = useMemo(() => {
    if (!procedures || !selectedSurgeon) {
      return [];
    }

    return procedures
      .filter((procedure) => {
        const surgeon = surgeons.find((item) => item.id === selectedSurgeon);
        return surgeon && surgeon.procedures?.includes(procedure.id);
      });
  }, [procedures, selectedSurgeon]);

  return (
    <div className='form__container'>
      <Form
        onSubmit={(formObj) => onSubmit(formObj, mode)}
        validate={validate}
        initialValues={{
          ...initialValues,
          procedures: initialValues?.procedures || []
        }}
        subscription={{ pristine: true, invalid: true, values: true }}
        mutators={{
          resetHospitals: (args, state, utils) => {
            utils.changeValue(state, 'hospitals', () => [])
          },
          resetProcedures: (args, state, utils) => {
            utils.changeValue(state, 'procedures', () => [])
          },
        }}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine, values, form }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <div className='form__subtitle'>
                { name ? name : 'Add Kit Preference' }
              </div>
              { mode === 'update' && (
                <Field name='active'>
                  {({ input, meta }) => (
                    <Input
                      type='switch'
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      placeholder='Status'
                    />
                  )}
                </Field>
              )}

              <div className='m-b-sm'>
                <div className='field__title'>Name</div>
                <Field name='name'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      placeholder='Name'
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-sm'>
                <div className='field__title'>Subtitle</div>
                <Field name='subtitle'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      placeholder=''
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              { mode === 'update' && (
                <>
                  <div className='m-b-lg'>
                    <div className='field__title'>Surgeon</div>
                    <Field name='surgeon'>
                      {({ input, meta }) => (
                        <Select
                          name={input.name}
                          value={input.value}
                          onChange={(value) => {
                            input.onChange(value);
                            setSelectedSurgeon(value);
                            form.mutators?.resetHospitals();
                            form.mutators?.resetProcedures();
                          }}
                          options={surgeonsList}
                        />
                      )}
                    </Field>
                  </div>

                  <div className='m-b-md'>
                    <div className='field__title'>Hospitals</div>
                    <Field name='hospitals'>
                      {({ input, meta }) => (
                        <div className='m-b-md'>
                          <ModalSelectInput
                            onClick={() => toggleHospitalModal(true)}
                            count={input.value.length}
                            label='Select Hospitals'
                            icon='hospital'
                            values={getHospitalName(input.value)}
                          />

                          <ModalSelect
                            title='Select Hospitals'
                            listTitle='Hospitals'
                            options={hospitalsList}
                            onChange={input.onChange}
                            selected={input.value}
                            onClose={() => toggleHospitalModal(false)}
                            isOpen={isHospitalModalOpen}
                          />
                        </div>
                      )}
                    </Field>
                  </div>

                  <div className='m-b-md'>
                    <Field name='allProcedures' type='checkbox'>
                      {({ input }) => (
                        <div className='m-b-sm'>
                          <Checkbox
                            input={input}
                            fontSize='small'
                            label='All procedures'
                          />
                        </div>
                      )}
                    </Field>

                    {!values?.allProcedures && (
                      <div className='m-b-sm'>
                        <div className='field__title'>Procedures</div>
                        <Field name='procedures'>
                          {({ input, meta }) => (
                            <div className='m-b-lg'>
                              <ModalSelectInput
                                onClick={() => toggleModal(true)}
                                count={input.value.length}
                                label='Select Procedures'
                                icon='procedure'
                                values={getProcedureNames(input.value)}
                              />

                              <ModalSelect
                                title='Select Procedures'
                                listTitle='Procedures'
                                options={proceduresList}
                                onChange={input.onChange}
                                selected={input.value}
                                onClose={() => toggleModal(false)}
                                isOpen={isModalOpen}
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                    )}
                  </div>

                  <div className='m-b-sm'>
                    <div className='field__title'>Preference Notes</div>
                    <Field name='description'>
                      {({ input, meta }) => (
                        <Input
                          name={input.name}
                          value={input.value}
                          placeholder=''
                          type='text'
                          multiline
                          onChange={input.onChange}
                        />
                      )}
                    </Field>
                  </div>
                </>
              )}

              <div className='form__actions'>
                <Button
                  type='submit'
                  text={buttonText || 'Save Changes'}
                  disabled={invalid || loading || pristine}
                  loading={loading}
                  onClick={handleSubmit}
                />
                {mode === 'create' && (
                  <Button
                    type='cancel'
                    onClick={onClose}
                  />
                )}
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default KitPreferenceForm;
