import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';

import PendingIcon from '../../assets/icons/PendingCountIcon';
import RepeatIcon from '../../assets/icons/RepeatingCountIcon';
import OverdueIcon from '../../assets/icons/OverdueCountIcon';
import CompletedIcon from '../../assets/icons/CompletedCountIcon';

import { countStatuses } from '../../constants/enums';

const StyledButton = withStyles((theme) => ({
  root: {
    height: '60px',
    width: '80px',
    borderRadius: '16px',
  }
}))(MuiButton);

const getStatusIcon = (status) => {
  switch (status) {
    case countStatuses.PENDING.value:
      return <PendingIcon color='#ffffff' />;
    case countStatuses.COMPLETED.value:
      return <CompletedIcon color='#ffffff' />;
    case countStatuses.OVERDUE.value:
      return <OverdueIcon color='#ffffff' />;
    case countStatuses.REPEATING.value:
      return <RepeatIcon color='#ffffff' />;
    default:
      return null;
  }
};

const FilterButton = ({
  status,
  value,
  onClick,
}) => {
  return (
    <StyledButton
      style={{
        background: value ? countStatuses[status]?.color : '#dddddd',
      }}
      value={value}
      onClick={() => onClick(status)}
    >
      <div className='d-flex direction-column space-between'>
        {getStatusIcon(status)}
        <div className='filter-button__label'>
          {countStatuses[status]?.label}
        </div>
      </div>
    </StyledButton>
  )
};

export default FilterButton;
