import * as Msal from 'msal';

import { SET_FORM_STATE, SIGN_OUT, SET_CURRENT_USER, RESET_FORM } from './actionTypes';
import { getCurrentTenant } from './tenantActions';
import { getCurrentUser } from './currentUserActions';

import firebase, { collections } from '../firebase';

import { post } from '../utils/api';

import urls from '../constants/urls';

import azureConfig from '../config/azure';

const { USERS_COLLECTION, APPROVED_EMAILS } = collections;

export const setFormState = (formState) => (dispatch) => {
  return dispatch({
    type: SET_FORM_STATE,
    formState
  })
};

export const resetForm = () => (dispatch) => {
  return dispatch({ type: RESET_FORM });
};

export const signIn = (email, password) => async (dispatch) => {
  const userSnap = await firebase.db.collection(USERS_COLLECTION).where('email', '==', email).get();
  const user = userSnap.docs && userSnap.docs[0] ? userSnap.docs[0].data() : null;

  if (!user) {
    throw new Error('Wrong email');
  }

  const { tenantIds } = user;
  firebase.auth.tenantId = tenantIds[0];

  const signInResp = await firebase.doSignInWithEmailAndPassword(email, password);

  if (signInResp) {
    await dispatch(getCurrentTenant());
    return dispatch({ type: SET_CURRENT_USER, user });
  } else {
    throw new Error('Login error. Please, try again.');
  }
};

export const microsoftSignIn = () => async (dispatch) => {
  const msalConfig = {
    auth: {
      clientId: azureConfig.CLIENT_ID,
      // authority: `https://login.microsoftonline.com/${azureConfig.TENANT_ID}`,
      authority: `https://login.microsoftonline.com/common`,
      redirectUri: azureConfig.REDIRECT_URI,
    },
  };

  const msalInstance = new Msal.UserAgentApplication(msalConfig);

  const authResult = await msalInstance.loginPopup();
  const { idToken } = authResult;

  const res = await post(urls.auth.microsoftSignIn(), { idToken: idToken?.rawIdToken });

  if (res && res.token && res.tenantId) {
    const signInResp = await firebase.doSignInWithCustomToken(res.token, res.tenantId);

    if (signInResp) {
      await dispatch(getCurrentTenant());
      await dispatch(getCurrentUser());
    } else {
      throw new Error('Login error. Please, try again.');
    }
  } else {
    throw new Error('Invalid Credentials');
  }
};

export const signUp = (authData) => async (dispatch) => {
  const resp = await post(urls.auth.signUp(), { authData });

  if (resp && resp.user) {
    return dispatch(signIn(authData.email, authData.password));
  } else {
    throw new Error('Sign up error. Please, try again.');
  }
};

export const checkEmail = async (email) => {
  const snap = await firebase
    .db
    .collection(APPROVED_EMAILS)
    .where('email', '==', email)
    .get();
  const docs = snap.docs.map(doc => doc.data());
  const doc = docs && docs[0];

  if (!doc) {
    await firebase
      .db
      .collection(APPROVED_EMAILS)
      .add({ active: true, approved: false, email });
  }

  return !!(doc && doc.active && doc.approved);
};

export const signOut = () => async (dispatch) => {
  await firebase.doSignOut();
  return dispatch({ type: SIGN_OUT });
};

export const resetPassword = (code, password, tenantId) => async (dispatch) => {
  try {
    if (tenantId) {
      firebase.auth.tenantId = tenantId;
    }
    await firebase.doConfirmPasswordReset(code, password);
  } catch (err) {
    throw new Error(err);
  }
};

export const forgotPassword = (email) => async (dispatch) => {
  try {
    await post(urls.auth.resetPassword(), { email });
  } catch (err) {
    throw new Error(err);
  }
};
