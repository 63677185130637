import React, { useMemo } from 'react';
import { Field, Form } from 'react-final-form';

import Input from '../../shared/Input';
import Button from '../../shared/Button';
import Checkbox from '../../shared/Checkbox';

import { caseStatusOptions } from '../../../constants/enums';

const Step2 = (props) => {
  const {
    initialValues,
    onClickNext,
    onClickBack,
    onClose,
    products,
    status,
  } = props;
  const { procedure } = initialValues;

  const validate = (values) => {
    const errors = {};

    if (!values.patientReference) {
      errors.patientReference = 'Required';
    }

    if (!values.products || !values.products.length) {
      errors.products = 'Required';
    }

    return errors;
  };

  const productsList = useMemo(() => {
    if (!products) {
      return [];
    }
    if (!procedure) {
      return products;
    }
    return products.filter((product) => product.procedures.includes(procedure));
  }, [procedure, products]);

  return (<div>
    <Form
      onSubmit={onClickNext}
      validate={validate}
      initialValues={initialValues}
      subscription={{ pristine: true, invalid: true }}
    >
      {
        ({ handleSubmit, submitting, invalid, pristine }) => (
          <form onSubmit={handleSubmit} className='form__body'>

            <div>
              <div className='field__title'>Patient Reference</div>
              <Field name='patientReference'>
                {({ input, meta }) => (
                  <Input
                    name={input.name}
                    value={input.value}
                    placeholder=''
                    type='text'
                    onChange={(e) => input.onChange(e.target.value?.toUpperCase())}
                    disabled={status && status === caseStatusOptions.completed}
                  />
                )}
              </Field>
            </div>

            <div className='m-b-lg'>
              <div className='field__title'>Brands</div>
              <Field name='products'>
                {({ input, meta }) => (
                  <div className='m-b-lg form__select-users'>
                    {!!productsList.length && (
                      productsList.map((product) => (
                        <div className='md-user-info' key={product.id}>
                          <Checkbox
                            disabled={status && (status === caseStatusOptions.overdue || status === caseStatusOptions.completed)}
                            input={{
                              ...input,
                              checked: input.value.includes(product.id),
                              onChange: (e) => {
                                if (e.target.checked) {
                                  input.onChange([...input.value, product.id]);
                                } else {
                                  if (status === caseStatusOptions.booked) {
                                    return;
                                  }
                                  input.onChange(input.value.filter((item) => item !== product.id));
                                }
                              }
                            }}
                            fontSize='small'
                            label={product.name}
                          />
                        </div>
                      ))
                    )}
                  </div>
                )}
              </Field>
            </div>

            <div className='form__actions'>
              <div className='d-flex'>
                <div className='m-r-md'>
                  <Button
                    type='cancel'
                    text='Back'
                    onClick={onClickBack}
                  />
                </div>
                <Button
                  type='submit'
                  text='Next'
                  disabled={invalid}
                  onClick={handleSubmit}
                />
              </div>
              <Button
                type='cancel'
                onClick={onClose}
              />
            </div>
          </form>
        )}
    </Form>
  </div>);
};

export default Step2;
