import React, { useState } from 'react';

import Modal from '../../shared/modal/Modal';
import Button from '../../shared/Button';
import Input from '../../shared/Input';

import './modal.scss';

const CancelCaseModal = (props) => {
  const { open, onSubmit, onClose, loading } = props;

  const [reason, setReason] = useState('');

  return (
    <Modal open={open} onClose={onClose}>
      <div className='confirmation-modal__container'>
        <div>
          <div className='modal-title'>
            Are you sure you want to cancel this case?
          </div>

          <div>
            <div className='field__title'>Reason for Cancellation</div>
            <Input
              type='textarea'
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>

          <div className='form__actions'>
            <Button
              type='submit'
              text='Confirm'
              onClick={() => onSubmit(reason)}
              disabled={loading || !reason}
              loading={loading}
            />
            <Button
              type='cancel'
              text='Cancel'
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CancelCaseModal;
