import React from 'react';

import DeleteIcon from '../../assets/icons/DeleteIcon';
import FileCopyIcon from '../../assets/icons/CopyIcon';

import Button from '../shared/Button';
import Select from '../shared/Select';

const GroupsFilters = (props) => {
  const { onAddGroupClick, status, setStatus, statuses, onDelete, deletePermission } = props;

  return (
    <div className='settings-cmp__filters--main'>
      <div className='settings-cmp__actions'>
        <Button
          text='Add Group'
          type='submit'
          onClick={onAddGroupClick}
        />

        <Button
          type='icon'
          onClick={onDelete}
          disabled={!deletePermission}
        >
          <DeleteIcon style={{ color: '#000000' }}/>
        </Button>

        <Button
         type='icon'
        >
          <FileCopyIcon style={{ color: '#000000' }}/>
        </Button>
      </div>

      <Select
        value={status}
        onChange={setStatus}
        options={statuses}
        defaultLabel={`All Status (${statuses.length})`}
        width={200}
      />
    </div>
  )
};

export default GroupsFilters;
