import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import InventoryIcon from '../../assets/icons/CountIcon';
import ExportIcon from '../../assets/icons/DownloadIcon';
import FilterIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';

import ActionButton from '../shared/ActionButton';
import Table from '../shared/table/Table';

import { exportCounts } from '../../actions/countsActions';

import { setAllocationStatuses } from '../../constants/enums';

const columns = [
  { title: 'date', field: 'completedAt', type: 'date', sort: true },
  { title: 'count id', field: 'id', type: 'default', sort: true },
  { title: 'location', field: 'hospitalName', type: 'default', sort: true, filter: true },
  { title: 'sub-location', field: 'location', type: 'default', sort: true, filter: true },
  { title: 'count name', field: 'name', type: 'default', sort: true },
  { title: 'completed by', field: 'userName', type: 'default', sort: true, filter: true },
];

const ReportingCountsTable = (props) => {
  const {
    counts,
    hospitals,
    users = [],
    filter,
    filterOptions,
    onFilterChange,
    onRemoveFilter,
  } = props;

  const dispatch = useDispatch();

  const [exportLoading, setExportLoading] = useState(false);

  const getName = (field, id) => {
    if (field === 'location') {
      return id;
    }

    let list = [];

    if (field === 'userName') {
      list = users?.map((user) => ({ ...user, id: user.uid }));
    } else if (field === 'hospitalName') {
      list = hospitals;
    }

    const value = list?.find((item) => item.id === id);

    if (value) {
      return field === 'userName' ? `${value?.firstName} ${value?.lastName}` : value.name;
    }

    return '';
  };

  const onExportClick = async () => {
    const data = counts?.map((item) => ({
      ...item,
      hospitalName: getHospitalName(item.hospitalId),
      userName: getUserName(item.completedBy)
    }));
    setExportLoading(true);
    await dispatch(exportCounts(data));
    setExportLoading(false);
  };

  const getHospitalName = useCallback((hospitalId) => {
    const hospital = hospitals.find((hospital) => hospital.id === hospitalId);
    return hospital ? hospital.name : '';
  }, [counts, hospitals]);

  const getUserName = useCallback((userId) => {
    const user = users?.find((u) => u.uid === userId);
    return user ? `${user.firstName} ${user.lastName}` : '';
  }, [counts, users]);

  return (
    <div className='cases-table__container'>
      <div className='d-flex m-b-lg space-between align-start'>
        <div className='d-flex'>
          <InventoryIcon />
          <div className='font-size-bg font-bold m-l-md'>
            Counts Completed
          </div>
        </div>

        <ActionButton
          onClick={onExportClick}
          bgColor={setAllocationStatuses.SHIPPED.color}
          disabled={exportLoading}
          loading={exportLoading}
        >
          <ExportIcon color="#ffffff" />
          Export
        </ActionButton>
      </div>

      {filter && Object.keys(filter).some((key) => filter[key].length) && (
        <div className='filters-list__container'>
          <FilterIcon />

          {Object.keys(filter).map((key) => (
            <div key={key} className='d-flex'>
              {filter[key].map((value) => (
                <div key={`${key}-${value}`} className='filters-list__item'>
                  <div />
                  <div className='filter-list-item-value'>{getName(key, value)}</div>
                  <CloseIcon color='secondary' onClick={() => onRemoveFilter(key, value)} />
                </div>
              ))}
            </div>
          ))}
        </div>
      )}

      <Table
        rows={counts?.map((item) => ({
          ...item,
          hospitalName: getHospitalName(item.hospitalId),
          userName: getUserName(item.completedBy)
        }))}
        columns={columns}
        pagination
        checkboxes={false}
        withoutCheck
        rowColor='#ffffff'
        rowBorderColor='#fafafa'
        currentFilter={filter}
        filterOptions={filterOptions}
        onFilterChange={onFilterChange}
      />
    </div>
  );
};

export default ReportingCountsTable;
