import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function ResupplyIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M13.25 3.17249C12.1156 2.74449 11.4756 2.57291 10.3301 2.3901C9.91566 3.47354 9.74422 4.11363 9.5477 5.31001" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round"
          d="M4.70336 1.52595C3.55513 1.89807 2.55418 2.62416 1.84397 3.60013C1.13376 4.5761 0.750759 5.75185 0.749851 6.95888C0.748942 8.16591 1.13018 9.34224 1.83891 10.3193C2.54765 11.2963 3.54751 12.0239 4.69518 12.3978C5.84285 12.7716 7.07941 12.7725 8.22764 12.4004C9.37588 12.0283 10.3768 11.3022 11.087 10.3262C11.7972 9.35026 12.1802 8.17451 12.1812 6.96748C12.1821 5.76045 11.8008 4.58412 11.0921 3.60708L10.3364 2.39659" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M6.41076 4.91024L6.39111 7.14277L4.99224 7.75345" />
  </SvgIcon>
}
