import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { linearGradientDef } from '@nivo/core';

import { LineTooltip } from './chart-tooltips/LineTooltip';

const LineChart = (props) => {
  const {
    data,
    margin,
    colors,
    height,
    width,
    entity = 'case',
    yFormat,
    yAxisFormat,
    monthlyCompared,
    CustomTooltip,
    enableSlices,
  } = props;

  return (
    <div style={{ height, width }}>
      <ResponsiveLine
        data={data}
        colors={colors}
        margin={margin}
        yFormat={yFormat || ''}
        axisBottom={{
          tickRotation: 0,
          tickSize: 5,
          tickPadding: 5,
          format: (value) => value?.substring(0, 3)
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 10,
          format: (v) => yAxisFormat ? yAxisFormat(v) : (Math.floor(v) === v ? v : '')
        }}
        enableArea={true}
        curve="monotoneX"
        enablePointLabel={false}
        pointSize={8}
        pointBorderWidth={2}
        pointBorderColor="#fff"
        useMesh={true}
        areaOpacity={0.2}
        enableGridX={true}
        enableGridY={true}
        isInteractive={true}
        // layers={['grid', 'markers', 'axes', 'areas', 'lines', 'points', 'mesh', 'legends']}
        tooltip={({ point }) => {
          if (CustomTooltip) {
            return <CustomTooltip point={point} />;
          }
          return <LineTooltip point={point} entity={entity} monthlyCompared={monthlyCompared} />;
        }}
        sliceTooltip={(props) => {
          if (CustomTooltip) {
            return <CustomTooltip {...props} />
          }
        }}
        theme={{
          fontSize: '12px',
          fontFamily: 'Inter',
        }}
        defs={[
          linearGradientDef('gradientA', [
            { offset: 0, color: 'inherit' },
            { offset: 100, color: 'inherit', opacity: 0 },
          ]),
        ]}
        fill={[{ match: '*', id: 'gradientA' }]}
        enableSlices={enableSlices}
      />
    </div>
  );
};

LineChart.defaultProps = {
  width: '100%',
  height: '100%',
}

export default LineChart;
