import React, { useEffect, useState, useMemo } from 'react';

import Modal from '../modal/Modal';
import Button from '../Button';
import Input from '../Input';
import Checkbox from '../Checkbox';

import './table.scss';

const FilterModal = (props) => {
  const { open, title, onSubmit, onClose, options = [], selectedValues = [], field } = props;

  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState(selectedValues);

  useEffect(() => {
    setSelected(selectedValues);
  }, [selectedValues]);

  const handleSelect = (checked, id) => {
    if (checked) {
      setSelected([...selected, id]);
    } else {
      setSelected([...selected].filter((item) => item !== id));
    }
  };

  const handleSubmit = () => {
    onSubmit(selected);
    onClose();
  };

  const list = useMemo(() => (
    options?.filter((option) => !search || option[field === 'surgeonName' || field === 'userName' ? 'lastName' : 'name']?.toLowerCase()?.includes(search?.toLowerCase()))
  ), [search, options]);

  return (
    <Modal open={open} onClose={handleSubmit} title={title || 'Filter'}>
      <div className='filter-modal__container'>
        <div>
          <Input
            type='search'
            placeholder='Search'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <div className='filter-modal__options'>
            {list?.map((option) => (
              <div key={option.id}>
                <Checkbox
                  input={{
                    checked: selected.includes(option.id),
                    onChange: (e) => handleSelect(e.target.checked, option.id)
                  }}
                  fontSize='small'
                  label={field === 'surgeonName' || field === 'userName' ? `${option.firstName} ${option.lastName}` : option.name}
                />
              </div>
            ))}
          </div>

          <div className='form__actions'>
            <Button
              type='submit'
              text='Submit'
              onClick={handleSubmit}
            />
            <Button
              type='cancel'
              text='Cancel'
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FilterModal;
