import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function ShippingIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M9.24646 4.65161H11.2628C11.7693 4.65161 12.2551 4.85282 12.6132 5.21099C12.9714 5.56915 13.1726 6.05492 13.1726 6.56144V9.38109C13.1726 9.93337 12.7249 10.3811 12.1726 10.3811H11.0497" />
    <path stroke={color || defaultColor} fill="none"
          d="M5.08094 10.6025C5.97348 10.6025 6.84613 10.5131 7.69121 10.4211M1.74166 10.3202C1.36201 10.1976 1.07704 9.86232 1.02886 9.45435C0.918998 8.52409 0.793091 7.55547 0.793091 6.56056C0.793091 5.56564 0.918998 4.59703 1.02886 3.66677C1.08548 3.18727 1.4692 2.8082 1.94928 2.75673C2.95518 2.64888 4.00357 2.51862 5.08094 2.51862C6.15831 2.51862 7.2067 2.64888 8.2126 2.75673C8.69268 2.8082 9.0764 3.18727 9.13302 3.66677C9.24288 4.59703 9.36879 5.56564 9.36879 6.56056C9.36879 7.36777 9.28591 8.15768 9.19596 8.92383" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M9.36892 12.291C8.43644 12.291 7.68053 11.5351 7.68053 10.6026C7.68053 9.6701 8.43644 8.91418 9.36892 8.91418C10.3014 8.91418 11.0573 9.6701 11.0573 10.6026C11.0573 11.5351 10.3014 12.291 9.36892 12.291Z" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M3.40206 12.291C2.46959 12.291 1.71367 11.5351 1.71367 10.6026C1.71367 9.6701 2.46959 8.91418 3.40206 8.91418C4.33453 8.91418 5.09045 9.6701 5.09045 10.6026C5.09045 11.5351 4.33453 12.291 3.40206 12.291Z" />
  </SvgIcon>
}

