import React from 'react';
import { Field, Form } from 'react-final-form';

import Button from '../shared/Button';
import Input from '../shared/Input';

import { kitPreferenceItemTypes } from '../../constants/enums';

import '../../styles/shared/form.scss';

const KitPreferenceItemForm = (props) => {
  const {
    initialValues,
    onSubmit,
    loading,
    itemsList = [],
    kitsList = [],
    // onDeleteClick,
  } = props;

  const validate = (values) => {
    const errors = {};

    if (initialValues.type === kitPreferenceItemTypes.ITEM.value && (!values.quantity || !Number(values.quantity))) {
      errors.quantity = 'Required';
    }

    return errors;
  };

  const getTitle = (item) => {
    if (item.type === kitPreferenceItemTypes.ITEM.value) {
      const _item = itemsList?.find((i) => i.id === item.value);
      return _item.code || '';
    } else if (item.type === kitPreferenceItemTypes.KIT.value) {
      const _kit = kitsList?.find((k) => k.id === item.value);
      return _kit?.kitId || '';
    }
  };

  const getDescription = (item) => {
    if (item.type === kitPreferenceItemTypes.ITEM.value) {
      const _item = itemsList?.find((i) => i.id === item.value);
      return _item.checklistDescription || _item.description || '';
    } else if (item.type === kitPreferenceItemTypes.KIT.value) {
      const _kit = kitsList?.find((k) => k.id === item.value);
      return _kit.name || '';
    } else {
      return item?.subtitle || '';
    }
  };

  return (
    <div className='form__container'>
      <Form
        onSubmit={(formObj) => onSubmit(formObj)}
        validate={validate}
        initialValues={{
          ...initialValues,
          steps: initialValues?.steps || []
        }}
        subscription={{ pristine: true, invalid: true, values: true }}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine, values }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <div className='form__subtitle'>
                {getTitle(initialValues)}
              </div>

              {initialValues?.type === kitPreferenceItemTypes.KIT.value && (
                <>
                  <div>
                    <div className='field__title'>Kit ID</div>
                    <Input
                      disabled
                      value={getTitle(initialValues)}
                      type='text'
                    />
                  </div>

                  <div className='m-b-sm'>
                    <div className='field__title'>Kit Name</div>
                    <Input
                      disabled
                      value={getDescription(initialValues)}
                      type='text'
                      textColor='#CCCCCC'
                    />
                  </div>
                </>
              )}

              {initialValues?.type === kitPreferenceItemTypes.ITEM.value && (
                <>
                  <div>
                    <div className='field__title'>Item Code</div>
                    <Input
                      disabled
                      value={getTitle(initialValues)}
                      type='text'
                    />
                  </div>

                  <div className='m-b-sm'>
                    <div className='field__title'>Item Description</div>
                    <Input
                      disabled
                      value={getDescription(initialValues)}
                      type='text'
                    />
                  </div>

                  <div className='m-b-sm'>
                    <div className='field__title'>Item Quantity</div>
                    <Field name='quantity'>
                      {({ input, meta }) => (
                        <Input
                          type='text'
                          name={input.name}
                          value={input.value}
                          onChange={input.onChange}
                          numbersOnly
                        />
                      )}
                    </Field>
                  </div>
                </>
              )}

              <div className='m-b-sm'>
                <div className='d-flex'>
                  <Field name='preSelect'>
                    {({ input, meta }) => (
                      <Input
                        type='switch'
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                        placeholder='Preselected'
                      />
                    )}
                  </Field>
                </div>
              </div>

              <div className='form__actions'>
                <Button
                  type='submit'
                  text='Save Changes'
                  disabled={invalid || loading || pristine}
                  loading={loading}
                  onClick={handleSubmit}
                />
                {/*<Button*/}
                {/*  type='outlined'*/}
                {/*  color='#F53434'*/}
                {/*  borderColor='#F53434'*/}
                {/*  onClick={onDeleteClick}*/}
                {/*  text='Delete'*/}
                {/*/>*/}
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default KitPreferenceItemForm;
