import React from 'react';

import ItemsIcon from '../../../assets/icons/ItemIcon';
import SetIcon from '../../../assets/icons/SetIcon';

import { setAllocationStatuses, setAllocationTypes } from '../../../constants/enums';

import '../checklists/checklists.scss';

const ReturnSetInfo = (props) => {
  const {
    kitId,
    kitName,
    type,
    setNumber,
    quantity,
    containerSize,
    code,
    description,
    allocationType,
    allocationStatus,
  } = props;

  return (
    <div className='case-checklists-info__container flex-1 m-r-lg'>
      <div className='d-flex space-between'>
        <div className='d-flex'>
          {allocationType === setAllocationTypes.ITEM ? (
            <ItemsIcon color={setAllocationStatuses[allocationStatus]?.color} />
          ) : (
            <SetIcon color={setAllocationStatuses[allocationStatus]?.color} />
          )}
          <span className='font-bold font-size-bg m-l-md'>
            {allocationType === setAllocationTypes.ITEM ? code : setNumber}
          </span>
        </div>
        <div className='font-bold font-size-bg' style={{ color: setAllocationStatuses[allocationStatus]?.color }}>
          {setAllocationStatuses[allocationStatus]?.label}
        </div>
      </div>
      <div className='d-flex space-between'>
        <div>
          <span className='font-bold m-r-sm'>
            {allocationType === setAllocationTypes.ITEM ? description : kitId}
          </span>
          {allocationType !== setAllocationTypes.ITEM ? (
            <span>{`${kitName} – ${type || 'Instruments'}`}</span>
          ) : <span />}
        </div>
        <div>QTY {quantity}</div>
      </div>
      {allocationType !== setAllocationTypes.ITEM ? (
        <div>
          Container size {containerSize}
        </div>
      ) : <div style={{ height: 21 }} />}
    </div>
  );
};

export default ReturnSetInfo;
