import React from 'react';
import { Field, Form } from 'react-final-form';

import Grid from '@material-ui/core/Grid';
import MuiPhoneNumber from 'material-ui-phone-number';

import Button from '../shared/Button';
import Select from '../shared/Select';
import Input from '../shared/Input';

import '../../styles/shared/form.scss';
import Checkbox from '../shared/Checkbox';

const currencies = [
  { label: 'AUD', value: 'AUD' },
  { label: 'USD', value: 'USD' },
  { label: 'NZD', value: 'NZD' },
  { label: 'EUR', value: 'EUR' },
  { label: 'GBP', value: 'GBP' },
  { label: 'SGD', value: 'SGD' },
];

const FinancialForm = (props) => {
  const {
    initialValues,
    onSubmit,
    loading,
  } = props;

  const validate = (values) => {
    const errors = {};

    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={{
        ...initialValues,
      }}
      subscription={{ pristine: true, invalid: true }}
    >
      {
        ({ handleSubmit, submitting, invalid, pristine }) => (
          <form onSubmit={handleSubmit} className=''>
            <Grid container spacing={3} style={{ width: 'auto', margin: 0 }}>
              <Grid item xs={12} sm={6}>
                <div>
                  <div className='field__title'>Billing Account Name</div>
                  <Field name='billingAccountName'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        type='text'
                        onChange={input.onChange}
                        placeholder="Enter the company name on account"
                      />
                    )}
                  </Field>
                </div>

                <div>
                  <div className='field__title'>Bank Name</div>
                  <Field name='bankName'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        type='text'
                        onChange={input.onChange}
                        placeholder="Enter name of bank"
                      />
                    )}
                  </Field>
                </div>

                <div>
                  <div className='field__title'>Branch Details</div>
                  <Field name='branchDetails'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        type='text'
                        onChange={input.onChange}
                        placeholder="Enter additional branch info"
                      />
                    )}
                  </Field>
                </div>

                <div>
                  <div className='field__title'>Routing Code (BSB or Sort Code)</div>
                  <Field name='routingCode'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        type='text'
                        onChange={input.onChange}
                        placeholder="Enter routing code for payments"
                      />
                    )}
                  </Field>
                </div>

                <div>
                  <div className='field__title'>Account Number</div>
                  <Field name='accountNumber'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        type='text'
                        onChange={input.onChange}
                        placeholder="Enter the bank account number"
                      />
                    )}
                  </Field>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <div className='field__title'>Default Payment Terms (days)</div>
                  <Field name='defaultPaymentTerms'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        type='text'
                        onChange={input.onChange}
                        numbersOnly
                        placeholder="Enter number of days"
                      />
                    )}
                  </Field>
                </div>

                <div>
                  <div className='field__title'>Default Sales Tax (%)</div>
                  <Field name='defaultSalesTax'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        type='text'
                        onChange={input.onChange}
                        numbersOnly
                        placeholder="Enter country sales tax %"
                      />
                    )}
                  </Field>
                </div>

                <div style={{ marginBottom: '18px' }}>
                  <div className='field__title'>Currency</div>
                  <Field name='currency'>
                    {({ input, meta }) => (
                      <Select
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                        options={currencies}
                      />
                    )}
                  </Field>
                </div>

                <div>
                  <div className='field__title'>Proforma Contact Number</div>
                  <Field name='proformaContact'>
                    {({ input, meta }) => (
                      <MuiPhoneNumber
                        value={input.value}
                        onChange={input.onChange}
                        fullWidth
                        placeholder='Enter contact number for Proforma'
                        defaultCountry='au'
                        className='form__phone p-l-md'
                        InputProps={{ disableUnderline: true }}
                      />
                    )}
                  </Field>
                </div>

                <div>
                  <div className='field__title'>Proforma Email Address</div>
                  <Field name='proformaEmail'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        type='text'
                        onChange={input.onChange}
                        placeholder="Enter contact email address for Proforma"
                      />
                    )}
                  </Field>
                </div>

                <div>
                  <Field name='showPaymentDetails' type='checkbox'>
                    {({ input }) => (
                      <Checkbox
                        input={input}
                        fontSize='small'
                        label='Show Payment Details (Proforma)'
                      />
                    )}
                  </Field>
                </div>
              </Grid>
              <div className='form__actions' style={{ padding: '0 12px 12px' }}>
                <Button
                  type='submit'
                  text='Save Changes'
                  disabled={invalid || loading || pristine}
                  loading={loading}
                  onClick={handleSubmit}
                />
              </div>
            </Grid>
          </form>
        )}
    </Form>
  );
};

export default FinancialForm;
