import React from 'react';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import { MuiPickersUtilsProvider, TimePicker as MuiTimePicker } from '@material-ui/pickers';

import './time-picker.scss';

const TimePicker = (props) => {
  const {
    date,
    onDateChange,
    initDate,
    withoutStyles = false,
  } = props;

  return (
    <div className={`date-picker__container${withoutStyles ? ' date-picker__without-styles' : ''}`}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiTimePicker value={date || initDate || moment()} onChange={onDateChange} views={['hours']} />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default TimePicker;
