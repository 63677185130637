import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function CaseIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} strokeLinejoin="round" fill="none"
          d="M7 3.25C5.44729 3.25 3.82348 3.25 2.46842 3.50467C1.84973 3.62094 1.36695 4.09818 1.23226 4.71312C1 5.77353 1 6.74895 1 8.25C1 9.75105 1 10.7265 1.23226 11.7869C1.36695 12.4018 1.84973 12.8791 2.46842 12.9953C3.82348 13.25 5.44729 13.25 7 13.25C8.55271 13.25 10.1765 13.25 11.5316 12.9953C12.1503 12.8791 12.633 12.4018 12.7677 11.7869C13 10.7265 13 9.75105 13 8.25C13 6.74895 13 5.77353 12.7677 4.71312C12.633 4.09818 12.1503 3.62094 11.5316 3.50467C10.1765 3.25 8.55271 3.25 7 3.25Z" />
    <path stroke={color || defaultColor} strokeLinejoin="round" fill="none"
          d="M4.5 3.28906V2.75C4.5 1.64543 5.39543 0.75 6.5 0.75H7.5C8.60457 0.75 9.5 1.64543 9.5 2.75V3.28906" />
    <path stroke={color || defaultColor} strokeLinecap="round" strokeLinejoin="round" d="M7 6.25V10.25" fill="none" />
    <path stroke={color || defaultColor} strokeLinecap="round" strokeLinejoin="round" d="M5 8.25H9" fill="none" />
  </SvgIcon>
}

