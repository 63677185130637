import React from 'react';
import moment from 'moment';

import RequestIcon from '../assets/icons/RequestIcon';
import BookedIcon from '../assets/icons/BookedIcon';
import OverdueIcon from '../assets/icons/OverdueIcon';
import CompletedIcon from '../assets/icons/CompletedIcon';
import CancelIcon from '../assets/icons/CancelIcon';

import { getKeyByValue } from './utils';

import { caseStatusOptions } from '../constants/enums';
import { statusColors } from '../constants/cases';

const filterCases = (item, statusFilter, searchState, date, a) => {
  const statusKey = getKeyByValue(caseStatusOptions, item.status);

  return (item.id?.toLowerCase().includes(searchState.toLowerCase())
    || item.patientReference?.toLowerCase().includes(searchState.toLowerCase()))
    && !!statusFilter[statusKey]
    && (moment(item.date).isSame(date, 'day') || !date);
};

const getStatusIcon = (status, filled, canceled) => {
  if (canceled) {
    return <CancelIcon color={filled ? statusColors.OVERDUE : '#ffffff'} />;
  }

  switch (status) {
    case caseStatusOptions.request:
      return <RequestIcon color={filled ? statusColors.REQUEST : '#ffffff'} />;
    case caseStatusOptions.booked:
      return <BookedIcon color={filled ? statusColors.BOOKED : '#ffffff'} />;
    case caseStatusOptions.overdue:
      return <OverdueIcon color={filled ? statusColors.OVERDUE : '#ffffff'} />;
    case caseStatusOptions.completed:
      return <CompletedIcon color={filled ? statusColors.COMPLETED : '#ffffff'} />;
    default:
      return <RequestIcon color={filled ? statusColors.REQUEST : '#ffffff'} />;
  }
};

const getStatusLabel = (status, canceled) => {
  if (canceled) {
    return 'Canceled';
  }

  switch (status) {
    case caseStatusOptions.request:
      return 'Request';
    case caseStatusOptions.booked:
      return 'Booked';
    case caseStatusOptions.overdue:
      return 'Overdue';
    case caseStatusOptions.completed:
      return 'Completed';
    default:
      return 'Request';
  }
};

export {
  filterCases,
  getStatusIcon,
  getStatusLabel,
};
