import React from 'react';
import { useHistory } from 'react-router-dom';

import FormIcon from '../../../assets/icons/FormIcon';
import ExportIcon from '../../../assets/icons/DownloadIcon';

import CaseFormsTable from '../../cases/forms/CaseFormsTable';
import ActionButton from '../../shared/ActionButton';

import routes from '../../../constants/routes';
import { setAllocationStatuses } from '../../../constants/enums';

const SubmittedFormsContainer = (props) => {
  const {
    submittedForms = [],
    forms = [],
    users = [],
    tenantColor,
    onExportClick = () => {
    },
    exportDisabled,
    handleCheck,
    checkedForms,
    handleCheckAll,
    exporting
  } = props;

  const history = useHistory();

  const onRowClick = (form) => {
    if (form.caseId) {
      history.push(`${routes.CASES}/${form.caseId}${routes.FORMS}/${form.id}`);
    } else {
      history.push(`${routes.FORMS}/${form.id}`);
    }
  };

  return (
    <div className='case-kits__container'>
      <div className='d-flex align-start space-between'>
        <div className='d-flex'>
          <FormIcon />
          <div className='font-size-bg font-bold m-l-md'>
            Forms
          </div>
        </div>

        <ActionButton
          onClick={onExportClick}
          bgColor={setAllocationStatuses.SHIPPED.color}
          disabled={exportDisabled || exporting}
          loading={exporting}
        >
          <ExportIcon color="#ffffff" />
          Export
        </ActionButton>
      </div>

      {submittedForms?.length ? (
        <CaseFormsTable
          rows={submittedForms?.filter((form) => forms?.map((f) => f.id)?.includes(form.formId))}
          onRowClick={onRowClick}
          users={users}
          forms={forms}
          withCaseId
          tenantColor={tenantColor}
          checkboxes
          handleCheck={handleCheck}
          checkedForms={checkedForms}
          handleCheckAll={handleCheckAll}
        />
      ) : (
        <div className='d-flex flex-center secondary p-lg'>
          No Forms
        </div>
      )}
    </div>
  );
};

export default SubmittedFormsContainer;
