import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function FlowIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M7.7457 11.4172L1.55347 11.4172" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M2.9917 12.8062L1.55349 11.4172L2.9917 10.0283" />
    <circle cx={9.106} cy={2.343} r={1.36} stroke={color || defaultColor} fill="none" />
    <circle cx={5.275} cy={7} r={1.36} stroke={color || defaultColor} fill="none" />
    <circle cx={9.106} cy={11.456} r={1.36} stroke={color || defaultColor} fill="none" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M12.6116 2.34317L10.4487 2.34317" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M7.74558 2.34369H3.75952C2.65495 2.34369 1.75952 3.23912 1.75952 4.34369V4.88281C1.75952 5.98738 2.65495 6.88281 3.75952 6.88281H3.9126" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M6.63528 6.87808H10.6213C11.7259 6.87808 12.6213 7.77351 12.6213 8.87808V9.41721C12.6213 10.5218 11.7259 11.4172 10.6213 11.4172H10.4683" />
  </SvgIcon>
}
