/* eslint-disable no-unused-vars */

// Development environment

const dev = 'https://us-central1-casedev-opologic.cloudfunctions.net';
const devLocal = 'http://localhost:5000/casedev-opologic/us-central1';

// Staging environment

const stage = 'https://us-central1-casetest-opologic.cloudfunctions.net';
const stageLocal = 'http://localhost:5000/casetest-opologic/us-central1';

// Production environment

const prod = 'https://us-central1-case-opologic.cloudfunctions.net';
const prodLocal = 'http://localhost:5000/case-opologic/us-central1';

const apiUrl = prod;

export default apiUrl;
