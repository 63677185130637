import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function VisibilityOffIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path stroke={color || defaultColor} fill="none" strokeLinejoin="round"
          d="M3.43086 3.52952C4.44278 2.79674 5.6731 2.25 7 2.25C10.4518 2.25 13.25 5.9499 13.25 7C13.25 7.66835 12.1165 9.41009 10.4024 10.5881" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M1.70316 5.17493C1.09927 5.92734 0.750051 6.62901 0.750051 6.99998C0.750051 8.05008 3.54827 11.75 7.00005 11.75C7.4383 11.75 7.86602 11.6903 8.27881 11.5841" />
    <path stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M1.25 0.75C5.3075 5.94753 7.94106 8.55298 13.25 12.75" />
  </SvgIcon>
}
